import React from "react";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>

            <section class="policyledrAdvn">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="AdvantegCnt">
                                <h1>Policy Leader Advantages</h1>
                                <p>
                                    Leading you to more than just financial safety.
                                    We at Policy Leader believe in simplifying complex insurance terms &amp; conditions for you, provide customized solutions, hassle-free claims, quick comparisons and instant quotes from top insurers. We are there for you in the toughest of the times.
                    </p>

                            </div>
                        </div>
                    </div>
                    <div class="AdvantageRow">
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/LowestPrice.png")} />
                                <h3>Guaranteed</h3>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/unbiased.svg")} />
                                <h3>
                                    Tailored Insurance Plans
                    </h3>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/dependable.png")} />
                                <h3>Regulated by IRDAI</h3>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/Survey.png")} />
                                <h3>
                                    Timely Surveyor Appointment
                    </h3>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/Documentation.png")} />
                                <h3>
                                    Complete Claims Documentation
                    </h3>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-6">
                            <div class="AdvantageBox">
                                <img src={require("../assets/img/PolicyLeader/help.svg")} />
                                <h3>
                                    Dedicated Claims Helpline
                    </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
};
export default Services;
