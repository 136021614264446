/* eslint-disable */
import React, { useState } from 'react';
import ButtonClass from '../ButtonClass';
import '../Contents/css/QuoteStyle.css'
const SideModel = (props) => {
    let adultAge = [];
    let childAge = [];
    for (let i = 18; i <= 100; i++) {
        adultAge.push(i)

    }
    for (let i = 0; i <= 24; i++) {
        childAge.push(i)

    }
    console.log(props)
    return (

        <div className="modal fade editModal ui-draggable show" id="myModalEdit" role="dialog" style={{ paddingRight: "17px", display: "block" }}>
            <div className="modal-dialog modal-lg editModal001">
                <div className="modal-content">
                    <div className="modal-header text-center ui-draggable-handle">
                        WHOM DO YOU WANT TO INSURE?
                    <button type="button" className="close" data-dismiss="modal" onClick={props.close}>×</button>
                    </div>
                    <div className="modal-body editModal002">


                        <div className="new genderRDDD_Edit" id="bike-Neww" style={{ display: "block" }}>
                            <div className="row EditBoxHealth">
                                <div className="col-md-6">
                                    <label>
                                        <span>Gender</span>
                                    </label>
                                </div>
                                <div className="genderRDDD col-md-6">
                                    <label><input type="radio" id="rblMale" value="1" name="Gender" onChange={props.onChange} defaultChecked={props.Gender === 1 ? true : false} /> Male</label>
                                    <label><input type="radio" id="rblFemale" onChange={props.onChange} name="Gender" value="2" defaultChecked={props.Gender !== 1 ? true : false} /> Female</label>
                                </div>
                            </div>

                            <div className="row EditBoxHealth">
                                <div className="inptBoxRDDD gender-dd col-md-12">
                                    <label className="styleForm">
                                        <small>PIN Code</small>
                                        <input className="form-control style-inpt" onChange={props.onChange} name="PinCode" id="HealthPinCode" type="text" placeholder="PIN Code" maxLength="6" defaultValue={props.pincode} />
                                    </label>
                                </div>
                            </div>
                            <div className="row EditBoxHealth">
                                <div className="inptBoxRDDD gender-dd col-md-12">
                                    <label className="styleForm">
                                        <small>Mobile Number</small>
                                        <input className="form-control style-inpt" onChange={props.onChange} id="HealthMobileNo" name="MobileNo" type="tel" placeholder="Enter Mobile..." defaultValue={props.mobile} maxLength="10" />
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="choose-relative">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="trvlPrsnBox">
                                        <label className="">
                                            <input type="CheckBox" name="IsYou" onChange={props.onChange} id="ChkYou" checked={props.IsYou } />
                                            <span>You</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {props.IsYou ?
                                        <select className="form-control inpFLD g_right_HH" name="YouAge" onChange={props.onChange} id="YourAge" defaultValue={props.UserAge} >
                                            <option value="0">Select Age</option>
                                            {adultAge.map((r) => {
                                                return <option value={r}>{r} Year</option>
                                            })}

                                        </select>
                                        : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="trvlPrsnBox">
                                        <label className="">
                                            <input type="checkbox" name="IsSpouse" onChange={props.onChange} id="chkSpouse" checked={props.IsSpouse} />
                                            <span>Spouse</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {props.IsSpouse ?
                                        <select onChange={props.onChange} name="SpouseAge" className="form-control inpFLD g_right_HH" id="SpouseAge" defaultValue={props.SpouseAge} >
                                            <option value="0">Select Age</option>
                                            {adultAge.map((r) => {
                                                return <option value={r}>{r} Year</option>
                                            })}
                                        </select> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="trvlPrsnBox">
                                        <label className="">
                                            <input type="checkbox" name="IsFather" onChange={props.onChange} id="chkfather" checked={props.IsFather} />
                                            <span>Father</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {props.IsFather?
                                        <select name="FatherAge" onChange={props.onChange} defaultValue={props.FatherAge} className="form-control inpFLD g_right_HH" id="FatherAge" >
                                            <option value="0">Select Age</option>
                                            {adultAge.map((r) => {
                                                return <option value={r}>{r} Year</option>
                                            })}
                                        </select> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="trvlPrsnBox">
                                        <label className="">
                                            <input type="checkbox" name="IsMother" onChange={props.onChange} checked={props.IsMother} id="chkmother" />
                                            <span>Mother</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {props.IsMother ?
                                        <select onChange={props.onChange} defaultValue={props.MotherAge} className="form-control inpFLD g_right_HH" name="MotherAge" id="MotherAge" >
                                            <option value="0">Select Age</option>
                                            {adultAge.map((r) => {
                                                return <option value={r}>{r} Year</option>
                                            })}
                                        </select> : null}
                                </div>
                            </div>


                        </div>

                        <div className="choose-relative">
                            <div className="col-12 p-0 mb-2">
                                <button
                                    className="btn btn-secondary btn-block"
                                    onClick={props.addMore}
                                    name="AddMoreChild">Add Children +
                                </button>
                            </div>





                            {props.IsShowInsuredChildren && props.TblChild.length > 0 ? (
                                <div>
                                    {props.TblChild.map((row, ind) => (

                                        <div className="row">
                                            <div className="col-md-5"> <select
                                                name="Gender"
                                                className="packageForm"
                                                defaultValue={row.ChildGender}
                                                onChange={(e) =>
                                                    props.manageChildList(
                                                        ind,
                                                        e.target.name,
                                                        e.target.value
                                                    )
                                                }
                                            >

                                                <option value="B">Son</option>
                                                <option value="G">Doughter</option>
                                            </select></div>
                                            <div className="col-md-6">
                                                <select name="Age"
                                                    className="packageForm"
                                                    value={row.Age}
                                                    onChange={(e) =>
                                                        props.manageChildList(
                                                            ind,
                                                            e.target.name,
                                                            e.target.value
                                                        )
                                                    }>
                                                    <option value="0">Select Age</option>
                                                    <option value="0.5">{"<"}1 Year</option>

                                                    {childAge.map((r) => {
                                                        return <option value={r}>{r} Year</option>
                                                    })}
                                                </select>

                                                {/* <input
                        type="text"
                        name="Age"
                        className="packageForm"
                        value={props.Age}
                        onChange={(e) =>
                    props.manageChildList(
                      ind,
                      e.target.name,
                      e.target.value
                    )
                        }
                        placeholder="Age"
                    />*/}

                                            </div>
                                            <div className="ageWithdel">
                                                <i className="fa fa-times" onClick={props.removeChild} id={ind} aria-hidden="true"></i></div>
                                        </div>



                                    ))}



                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="btnHealthSubmit" onClick={props.onSubmit} className={ButtonClass()} > Update Result</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SideModel;