/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
import { NavbarBrand } from "reactstrap";
const Footer = () => {
    return (
        <React.Fragment>

            <div class="Pratifooter pdt80 pdb20">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 text-left">
                            <NavbarBrand tag={Link} to="/">
                                <img
                                    src={require("../assets/img/Mandi/logo.png")}

                                />
                            </NavbarBrand>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6">
                            <div class="mt20">
                                <h3 class="text-white mb-3">Site</h3>
                                <ul class="listnone">
                                    <li><a href="#!" class="text-base">Privacy Policy </a></li>
                                    <li><a href="#!" class="text-base">Do Not Sell My Info</a></li>
                                    <li><a href="#!" class="text-base">Terms of Use</a></li>
                                    <li><a href="#!" class="text-base">Site Map</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6">
                            <div class="mt20">
                                <h3 class="text-white mb-3">About</h3>
                                <ul class="listnone">
                                    <li><a href="#!" class="text-base">Overview </a></li>
                                    <li><a href="#!" class="text-base">Media Center</a></li>
                                    <li><a href="#!" class="text-base">Career</a></li>
                                    <li><a href="#!" class="text-base">Blog</a></li>
                                    <li><a href="#!" class="text-base">Team</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                            <div class="mt20">
                                <h3 class="text-white mb-3">Contact</h3>
                                <ul class="listnone">
                                    <li><a href="#!" class="text-base">Customer Support </a></li>
                                    <li><a href="#!" class="text-base">Partnership </a></li>
                                    <li>
                                        <a href="#!" class="text-base">Business Development</a>
                                    </li>
                                    <li><a href="#!" class="text-base">Media Relations</a></li>
                                    <li><a href="#!" class="text-base">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                            <div class="mt20">
                                <h3 class="text-white mb-3">Follow us</h3>
                                <ul class="listnone">
                                    <li>
                                        <a href="#!" class="text-base text-uppercase">FACEBOOK </a>
                                    </li>
                                    <li>
                                        <a href="#!" class="text-base text-uppercase">GOOGLE PLUS</a>
                                    </li>
                                    <li>
                                        <a href="#!" class="text-base text-uppercase">TWITTER</a>
                                    </li>
                                    <li>
                                        <a href="#!" class="text-base text-uppercase">LINKED IN</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Pratifooter02">
                
                <div class="container">
                    <div class="footerBottom">
                        <img src={require("../assets/img/Mandi/logo2.png")} style={{ width: "150px", margin: "auto", display: "block" }} />
                       
                        <a href="http://www.sanyoginsurancebrokers.com/" target="_blank" style={{ color: "#2b308b", fontSize: "14px", fontWeight: "600" }}>www.sanyoginsurancebrokers.com/</a>
                        
                        <p class="text-center">
                            <strong>
                                Sanyog Insurance Brokers India Private Limited<br />Registration Code: IRDA / DB 840 / 20, Category : Direct (Life & Genral), License Validity: 10/June/2024<br />
                    Member: Insurance Brokers Association of India, CIN - U66000PN2020PTC193356<br />
                    Principal Officer: Dharmesh Sharma, 9172252676, Principalofficer@insurancemandi.com
                </strong><br/>
                Registered Office Address and Corporate Office Address: Neeta Towers, 1st Floor, Above Amantran Hotel, Opposite Sandvik Asia, Dapodi, Pune 411012.<br/>

                            Service@insurancemandi.com, contactus@insurancemandi.com,website:<a href="https://www.insurancemandi.com" style={{ color: "#2b308b", fontSize: "14px", fontWeight: "600" }}>insurancemandi.com</a>
                            <br />

            </p>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <li><a href="#">Connect With Us</a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Pratifooter03">
                <div className="container">
                        <p>
                            Copyright 2020 All
                            Rights Reserved.
                    </p>
                </div>
            </div>

        </React.Fragment>
    );
};
export default Footer;
