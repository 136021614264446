import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { NavbarBrand } from "reactstrap";
import { MasterGlobal } from "../../App";
import MaskedInput from 'react-text-mask'
import { createBrowserHistory } from 'history';
import { withRouter } from 'react-router-dom';
import ButtonClass, { ChildBtn } from "../../ButtonClass";
import axios from "axios";
export class InsureHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Gender: 1,
        MobileNo: null,
        PinCode: null,
        IsShowInsuredPerson: false,
        IsShowInsuredChildren: false,
        TblChild: [],
        IsYou: true,
        YouAge: null,
        IsSpouse: false,
        SpouseAge: null,
        IsFather: false,
        FatherAge: null,
        IsMother: false,
        MotherAge: null,
        APIUrl: null,
        CRMAPI: null,
        ClientID: null,
        UserID: 0,
        adult: 0,
        children: 0,
        fields: {},
        validity: '',
        errors: {},
        Req: [],
        you: false,
        spouse: false,
        father: false,
        mother: false,
        insured: {},
        nextComponent: false,
        HistoryRes: null,
        isValid: false,
        adultAgeList: [],
        childAgeList: [],
        redirectToQuote:false,
        BTNValue:"VIEW QUOTES"
    };
    this.state.APIUrl = window.localStorage.getItem("portal");
    this.state.CRMAPI = window.localStorage.getItem("crm")
    this.removeChild = this.removeChild.bind(this)
    this.LoadFirst = this.LoadFirst.bind(this)
    for (let i = 18; i <= 100; i++) {
        this.state.adultAgeList.push(i)

    }
    for (let i = 1; i <= 24; i++) {
        this.state.childAgeList.push(i)

    }
    this.handle = this.handle.bind(this);
    this.handleRedirect=this.handleRedirect.bind(this);
    this.LoadFirst();
    let healthReq=window.sessionStorage.getItem("HealthQuoteReq");
    let healthRes=window.sessionStorage.getItem("HealthQuoteRes");
    if(healthReq!== null || healthRes!== null){
        window.sessionStorage.removeItem("HealthQuoteReq");
        window.sessionStorage.removeItem("HealthQuoteRes");
    }
}
LoadFirst() {
    let url = this.state.APIUrl==null?window.location.hostname:this.state.APIUrl;
    if (url.match("www")) {
        url = url.replace("www.", "");
    }
    url=url.replace("https://", "");
    let rq = {
        URL: url,

    }
    
    let Model={
        URL:this.state.CRMAPI + "/api/Account/DomainLogo",
        PostString:JSON.stringify(rq)
    };
    try {
        axios.post("/bpi/WeatherForecast/CallCheck",Model)
        .then((Res) => {
            this.setState({ ClientID: Res.data.id })

        });
    } catch (err) {
        console.log("Client Id"+err)
    }
    let Token = window.localStorage.getItem("token");
    if (Token != null) {
        //Token = JSON.parse(Token);
        let parseToken = false;
        if (Token.charAt(0) === "{")
            parseToken = true;
        console.log(Token)
        let data = {
            Token: parseToken ? JSON.parse(Token).token : Token,
        };
        let md = {
            URL: this.state.CRMAPI + "/api/User/GetUserInfo",
            PostString: JSON.stringify(data)
        }
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", md)
            .then((Res) => {
                this.setState({ UserID: Res.data.userID });
                this.state.UserID = Res.data.userID;

            });
        }catch (err) {
            console.log("userID"+err)
        }
    }
}





AddEnquiry(adultCount, childCount) {
    this.state.BTNValue="Wait..."
    let EnqReq = {
        MobileNo: this.state.fields["MobileNo"],
        EnquiryDate: "0001-01-01T00:00:00",
        EnquiryType: "HLT",
        Status: false,
        EnquiryNo: null,
        TotalEnquiry: 0,
        HealthEnquiry: {
            ID: 0,
            TblChild: this.state.TblChild,
            UserID: this.state.UserID,
            PinCode: this.state.fields["PinCode"],
            AdultCount: adultCount,
            ChildCount: childCount,
            ResultId: "0532cecd-12e9-41d4-8a80-b859981f76f2",
            PolicyType: adultCount == 1 ? "Individual" : "Family Floter",
            Gender: this.state.Gender,
            UserAge: this.state.YouAge,
            SpouseAge: this.state.IsSpouse ? this.state.SpouseAge : null,
            FatherAge: this.state.IsFather ? this.state.FatherAge : null,
            MotherAge: this.state.IsMother ? this.state.MotherAge : null,
            SonAge1:
                childCount > 0
                    ? this.state.TblChild[0].ChildGender == "B"
                        ? this.state.TblChild[0].Age
                        : null
                    : null,
            SonAge2:
                childCount > 1
                    ? this.state.TblChild[1].ChildGender == "B"
                        ? this.state.TblChild[1].Age
                        : null
                    : null,
            SonAge3:
                childCount > 2
                    ? this.state.TblChild[2].ChildGender == "B"
                        ? this.state.TblChild[2].Age
                        : null
                    : null,
            SonAge4:
                childCount > 3
                    ? this.state.TblChild[3].ChildGender == "B"
                        ? this.state.TblChild[3].Age
                        : null
                    : null,
            DoughterAge1:
                childCount > 0
                    ? this.state.TblChild[0].ChildGender == "G"
                        ? this.state.TblChild[0].Age
                        : null
                    : null,
            DoughterAge2:
                childCount > 1
                    ? this.state.TblChild[1].ChildGender == "G"
                        ? this.state.TblChild[1].Age
                        : null
                    : null,
            DoughterAge3:
                childCount > 2
                    ? this.state.TblChild[2].ChildGender == "G"
                        ? this.state.TblChild[2].Age
                        : null
                    : null,
            DoughterAge4:
                childCount > 3
                    ? this.state.TblChild[3].ChildGender == "G"
                        ? this.state.TblChild[3].Age
                        : null
                    : null,
        },
        UserID: this.state.UserID,
        LeadSource: "online",
        ClientID: this.state.ClientID,
    };

    console.log(EnqReq)
    let Model={
        URL:this.state.APIUrl + "/healthapi/api/Enquiry/AddEnquiry",
        PostString:JSON.stringify(EnqReq)
    }
    try{
        axios.post("/bpi/WeatherForecast/CallCheck",Model)
        .then((data) => {

            EnqReq.EnquiryNo = data.data.split("~")[0].replace('"', "");
            this.state.HistoryRes=EnqReq;
            this.setState({ HistoryRes: EnqReq,redirectToQuote:true });
        });}catch(err){}
}
handleRedirect(){
    let isValid = true;
    let errors = {};
    var pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    if (this.state.Gender === 0) {
        isValid = false;
        errors["Gender"] = "Please Select Gender First"
    }
    if (this.state.PinCode===null) {
        isValid = false

        errors["PinCode"] = "Please Enter Pin Code"
    }
    else if (this.state.PinCode !== null) {

        if (this.state.PinCode.length < 6) {
            isValid = false

            errors["PinCode"] = "Please Enter Pin Code"
        }
    }
    if (this.state.MobileNo === null) {
        isValid = false;

        errors["MobileNo"] = "please Enter Mobile Number"
    }
    else if (this.state.MobileNo !== null) {
        if (!pattern.test(this.state.MobileNo)) {
            isValid = false;

            errors["MobileNo"] = "please Enter Mobile Number"
        }
        else if (this.state.MobileNo.length < 10) {
            isValid = false;

            errors["MobileNo"] = "please Enter Mobile Number"
        }
    }
    if ((this.state.IsYou === true && (this.state.YouAge == 0 || this.state.YouAge === null))) {
        isValid = false
        errors["insured"]="Select Age";
    }
    if ((this.state.IsSpouse === true && (this.state.SpouseAge == 0 || this.state.SpouseAge === null))) {
        isValid = false
        errors["insured"]="Select Age";
    }
    if ((this.state.IsFather === true && (this.state.FatherAge == 0 || this.state.FatherAge === null))) {
        isValid = false
        errors["insured"]="Select Age";
    }
    if ((this.state.IsMother === true && (this.state.MotherAge == 0 || this.state.MotherAge === null))) {
        isValid = false
        errors["insured"]="Select Age";
    }


    this.setState({ isValid,errors })
    if(isValid){
    let adultCount = 0;
    let childCount = this.state.TblChild.filter((row) => row.Age > 0).length;
    if (this.state.IsYou) adultCount += 1;
    if (this.state.IsSpouse) adultCount += 1;
    if (this.state.IsFather) adultCount += 1;
    if (this.state.IsMother) adultCount += 1;
    if (adultCount > 0 || childCount > 0)
        this.AddEnquiry(adultCount, childCount);
    }
}
handle = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields })
    if (e.target.type == "checkbox") {
        this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.type == "radio") {
      
        this.setState({ [e.target.name]: e.target.value });
    } else {
        this.setState({ [e.target.name]: e.target.value });
    }
   
};

chkInput(e) {
    const validity = (e.target.validity.valid) ? e.target.value : this.state.validity;
    this.setState({ validity });
}

insure = (e) => {
    switch (e.target.name) {
        case "Member":
            this.setState({ IsShowInsuredPerson: !this.state.IsShowInsuredPerson });
            break;
        case "Children":
            this.setState({
                IsShowInsuredChildren: !this.state.IsShowInsuredChildren,
            });
            break;
        case "AddMoreChild":
            if (this.state.TblChild.length < 4) {
                let child = {
                    index: this.state.TblChild.length,
                    ChildGender: "B",
                    Age: null,
                }
                this.state.TblChild.push(child);
                this.setState({ TblChild: this.state.TblChild, IsShowInsuredChildren: true });

            } else return alert("Child can't Exceed")
            break;
    }
};
manageChildList(index, name, value) {
    let data = this.state.TblChild.filter((row) => row.index == index)[0];
    if (name == "Gender") {
        data.ChildGender = value;
    } else if (name == "Age") {
        data.Age = value;
    }
    this.state.TblChild[index] = data;
    this.setState({ TblChild: this.state.TblChild });
}
close = (e) => {
    this.setState({ IsShowInsuredPerson: false });
};
removeChild(e) {
    let id = e.target.id;
    let TblChild = this.state.TblChild;
    let index = TblChild.indexOf(id)
    if (id > -1) {
        TblChild.splice(index, 1);

    }
    this.setState({ TblChild })

}
render() {
  return (
      this.state.redirectToQuote? <Redirect to={{
          pathname: "/HealthQuotes",
          state: { Req: this.state.HistoryRes }
      }} />:        <div className="row  pt-3 d-flex justify-content-center">
                <div className="col-sm-9">
                    <div className="packagesMainHealth text-center">
                        <div className="productTabsMainHealth productTabsMainHelath text-center">
                            <h3 className="heading">No medical needed</h3>
                            <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                               
                                    <div className="PF0012">
                                        <div className="row">
                                            <div className="col-md-3 NoPaddingRight">
                                                <label>
                                                    <select id="SlectGender" onChange={this.handle} name="Gender">
                                                        <option value="0">Select Gender</option>
                                                        <option id="rblMale" value="1">Male</option>
                                                        <option id="rblFemale" value="2">Female</option>
                                                    </select>
                                                </label>
                                                <span className="error">{this.state.errors["Gender"]}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="healthAge">
                                                    <input type="text" placeholder=" " onClick={this.insure} />
                                                    <a href="#" data-toggle="modal" data-target="#MTB-Insured"
                                                        name="Member" onClick={this.insure} >

                                                        <span id="MTB-TlHide" style={{ pointerEvents: "none" }}>
                                                        {this.state.IsYou ? "You, " : null}{this.state.IsSpouse ? "Spouse, " : null}{this.state.IsFather ? "Father, " : null}{this.state.IsMother ? "Mother, " : null}
                                                        {this.state.IsYou && this.state.IsSpouse && this.state.IsFather && this.state.IsMother ? "Insured.." : "Members to be Insured"} </span>
                                                </a>
                                                </label>
                                                <span className="error">{this.state.errors["insured"]}</span>
                                            </div>

                                            <div className="col-md-3 NoPaddingLeft">
                                                <label>

                                                    <MaskedInput onChange={this.handle} value={this.state.PinCode} name="PinCode" value={this.state.fields["pincode"]} placeholder="Enter PIN Code" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                                </label>
                                                <span className="error">{this.state.errors["PinCode"]}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 offset-md-4">
                                                <label>
                                                    <MaskedInput onChange={this.handle} type="tel" id="HealthMobileNo" name="MobileNo" value={this.state.MobileNo} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                                   
                                                </label>
                                                <span className="error">{this.state.errors["MobileNo"]}</span>
                                            </div>

                                            <div className="col-md-4 offset-md-4">

                                               <button className={ButtonClass()} id="quotesBtn" onClick={this.handleRedirect} >{this.state.BTNValue}</button>
                                               </div>
                                        </div>

                                    </div>
                              
                            </div>

                            { /* <div className="ProductFormIn pt-2">
                <form onSubmit={this.Submit}>
                  <div className="row d-flex justify-content-center pl-5">
                    <div className="col-sm-4 formInput">
                      <select
                        className="selectBox"
                        aria-label="form-select-lg example"
                        onChange={this.handle}
                      >
                       
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                    </div>
                    <div className="col-sm-4 text-center">
                      <a
                        onClick={this.insure}
                        name="Member"
                        className="linkTab text-center"
                      >
                       {this.state.adult>0?this.state.adult+" Adult "+this.state.children+" Children" :"Members to be Insured"}
                      </a>
                      <label>
                      <input
                        type="text"
                        name="MobileNo"
                        className="mobileTab"
                        value={this.state.MobileNo}
                        onChange={this.handle}
                        placeholder="+91"
                        pattern="^\+?(?:[0-9]??).{5,14}[0-9]$"
                        maxLength="10"
                        required
                      />
                    </label>
                    <label className=" text-center">
                        <input
                          className="btn QouteMNBtnnew text-center"
                          type="submit"
                          value="Get Quote"
                          id="quotesBtn"
                        />
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="PinCode"
                        className="inputBox"
                        value={this.state.PinCode}
                        onChange={this.handle}
                        placeholder="Enter Pin Code"
                        maxLength="6"
                        required
                      />
                    </div>
                  </div>
                  {/*<div className="productTabsHealth">
                    <label
                      style={{
                        border: "1px solid white",
                        borderRadius: "100%",
                        boxShadow: "1px 1px 1px 1px white",
                      }}
                    >
                      <input
                        type="radio"
                        onChange={this.handle}
                        className={this.state.Gender == 2 ? "active" : ""}
                        checked={this.state.Gender == 2}
                        value="2"
                        name="Gender"
                      />
                      <img src="https://www.riskoveryinsurance.com/Content/PolicyMart/images/Riskovery/woman.png" />
                    </label>
                  </div>
                  <div className="productTabsHealth">
                    <label
                      style={{
                        border: "1px solid white",
                        borderRadius: "100%",
                        boxShadow: "1px 1px 1px 1px white",
                      }}
                    >
                      <input
                        type="radio"
                        onChange={this.handle}
                        checked={this.state.Gender == 1}
                        className={this.state.Gender == 1 ? "active" : ""}
                        value="1"
                        name="Gender"
                      />
                      <img src="https://www.riskoveryinsurance.com/Content/PolicyMart/images/Riskovery/boy.png" />
                    </label>
                  </div>

                  {/*<select name="Gender" className="packageForm"
                                    onChange={this.handle}>
                                    <option>Select Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                </select>*/}

                            {/*<label>
                    <input
                      type="text"
                      name="MobileNo"
                      className="packageForm"
                      value={this.state.MobileNo}
                      onChange={this.handle}
                      placeholder="Mobile Number"
                      maxLength="10"
                      required
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      name="PinCode"
                      className="packageForm"
                      value={this.state.PinCode}
                      onChange={this.handle}
                      placeholder="PinCode"
                      maxLength="6"
                      required
                    />
                              </label>*}
                 
                
                </form>
              </div>
                            */  } </div>
                    </div>
                    {this.state.IsShowInsuredPerson ? (
                      <div className="HealthPopUpBg">
                              <div className="packagesMainPopUpH">
                                  <div className="productTabsMainHealth">
                                      <div className="text-center">
                                          <h5 className="memberInsure" style={{textTransform:"uppercase"}}>Who Do You Want to Insure ?</h5>
                                          <button type="button" onClick={this.close} className="close">
                                              &times;
                            </button>
                                      </div>
                                      <hr/>
                                      <div className="ProductFormIn ProductFormInQuote">
                                          <label>
                                              <div className="FormRow">
                                                  <div className="col-50">
                                                      <input
                                                          type="CheckBox"
                                                          name="IsYou"
                                                          checked={this.state.IsYou}
                                                          onChange={this.handle}
                                                      />
                                 &nbsp; You
                                </div>
                                                  <div className="col-50">
                                                  {this.state.IsYou?
                                                    <select className="form-control input-lg"
                                                     onChange={this.handle} name="YouAge"
                                                       value={this.state.YouAge}>
                                                   <option value="0">Select Age</option>
                                                    {this.state.adultAgeList.map((v)=>{ 
                                                      return <option key={v} value={v}>{v} Year</option>
                                                    
                                                    
                                                    })}
                                                    </select>
                                                      // <MaskedInput className="form-control input-lg"
                                                      //  onChange={this.handle} name="YouAge"
                                                      //   value={this.state.YouAge} placeholder="Your Age" 
                                                      //   mask={[/\d/, /\d/]} guide={false} showMask={false} />
                                                    :null }
                                                  </div>
                                              </div>
                                          </label>
                                          <label>
                                              <div className="FormRow">
                                                  <div className="col-50">
                                                      <input
                                                          type="CheckBox"
                                                          name="IsSpouse"
                                                          checked={this.state.IsSpouse}
                                                          onChange={this.handle}
                                                      />{" "}
                                  Spouse
                                </div>
                                                  <div className="col-50">
                                                    { this.state.IsSpouse?
                                                      <select className="form-control input-lg"
                                                      onChange={this.handle} name="SpouseAge"
                                                          value={this.state.SpouseAge}
                                                    >
                                                    <option value="0">Select Age</option>
                                                     {this.state.adultAgeList.map((v)=>{ 
                                                       return <option key={v} value={v}>{v} Year</option>
                                                     
                                                     
                                                     })}
                                                     </select>
                                                      // <MaskedInput className="form-control input-lg"
                                                      //     onChange={this.handle} name="SpouseAge"
                                                      //     value={this.state.SpouseAge}
                                                      //     placeholder="Spouse Age"
                                                      //     mask={[/\d/, /\d/]}
                                                      //     guide={false} showMask={false} />
                                                          :null}
                                                  </div>
                                              </div>
                                          </label>
                                          <label>
                                              <div className="FormRow">
                                                  <div className="col-50">
                                                      <input
                                                          type="CheckBox"
                                                          name="IsFather"
                                                          checked={this.state.IsFather}
                                                          onChange={this.handle}
                                                      />{" "}
                                  Father
                                </div>
                                                  <div className="col-50">
                                                     {this.state.IsFather? 
                                                      <select className="form-control input-lg"
                                                      onChange={this.handle} name="FatherAge"
                                                          value={this.state.FatherAge}
                                                    >
                                                    <option value="0">Select Age</option>
                                                     {this.state.adultAgeList.map((v)=>{ 
                                                       return <option key={v} value={v}>{v} Year</option>
                                                     
                                                     
                                                     })}
                                                     </select>
                                                      // <MaskedInput className="form-control input-lg"
                                                      //     onChange={this.handle} name="FatherAge"
                                                      //     value={this.state.FatherAge}
                                                      //     placeholder="Father Age"
                                                      //     mask={[/\d/, /\d/]}
                                                      //     guide={false} showMask={false} />
                                                          :null}
                                                  </div>
                                              </div>
                                          </label>
                                          <label>
                                              <div className="FormRow">
                                                  <div className="col-50">
                                                      <input
                                                          type="CheckBox"
                                                          name="IsMother"
                                                          checked={this.state.IsMother}
                                                          onChange={this.handle}
                                                      />{" "}
                                  Mother
                                </div>
                                                  <div className="col-50">
                                                     {this.state.IsMother? 
                                                      <select className="form-control input-lg"
                                                      onChange={this.handle} name="MotherAge"
                                                          value={this.state.MotherAge}
                                                    >
                                                    <option value="0">Select Age</option>
                                                     {this.state.adultAgeList.map((v)=>{ 
                                                       return <option key={v} value={v}>{v} Year</option>
                                                     
                                                     
                                                     })}
                                                     </select>
                                                     
                                                          :null}
                                                  </div>
                                              </div>
                                          </label>
                                              <div className="FormRow">
                                              <div className="col-50"> <button
                                                  className="btn btn-secondary mt-2"
                                                  onClick={this.insure}
                                                  name="AddMoreChild"
                                                  style={{ float: "left" }}
                                              >
                                                  Add Children
                          </button></div>
                                              <div className="col-50 text-right">
                                                  <input type="button" value="Submit" onClick={this.close} className={"mt-2 "+ChildBtn()}  />
                                              </div>
                                          </div>
          
          
          
                                          {this.state.IsShowInsuredChildren ? (
                                              <div>
                                                  {this.state.TblChild.map((row, ind) => (
                                                      <label>
                                                          <div className="FormRow FormRowHealth">
                                                              <div className="col-40 mr-2">
                                                                  <select
                                                                      name="Gender"
                                                                      className="packageForm"
                                                                      onChange={(e) =>
                                                                          this.manageChildList(
                                                                              ind,
                                                                              e.target.name,
                                                                              e.target.value
                                                                          )
                                                                      }
                                                                  >
                                                                      <option value="B">Son</option>
                                                                      <option value="G">Daughter</option>
                                                                  </select>
                                                              </div>
                                                              <div className="col-40">
                                                              <select className="packageForm"
                                                              name="Age"
                                                              value={this.state.fields["Age"]}
                                                              onChange={(e) =>
                                                                  this.manageChildList(
                                                                      ind,
                                                                      e.target.name,
                                                                      e.target.value
                                                                  )
                                                              }>
                                                              <option value="0">Select Age</option>
                                                              <option value="0.5">{'<'}1 Year</option>
                                                              {this.state.childAgeList.map((v)=>{
                                                                return <option key={v} value={v}>{v} Year</option>
                                                              })}
                                                              </select>
                                                             
                                                              </div>
                                                              <div className="ageWithdel"> 
                                                              <i className="fa fa-times" onClick={this.removeChild} id={ind} aria-hidden="true"></i></div>
                                                          </div>
                                                      </label>
                                                  ))}
                                                 
          
                                              </div>
                                          ) : null}
                                      </div>
                                  </div>
                                  </div>
                              </div>
                    ) : null}
                          </div>
            </div>
        );
    }
}
