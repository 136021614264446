const DateFormatter=(date,devider,splt,formate,type)=>{
    let element=date.split(splt);
    let dd=element[2];
    let mm=element[1];
    let yy=element[0];
    let newDate;

    let month=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    if(type==="st"){
       
        mm=month[parseInt(mm,10)-1];
    }
    switch(formate){
        case "dd/mm/yy" : newDate=dd+devider+mm+devider+yy;
        break;
        case "mm/dd/yy" : newDate=mm+devider+dd+devider+yy;
        break;
        case "yy/mm/dd" : newDate=yy+devider+mm+devider+dd;
        break;
    }
   return newDate;
}
export default DateFormatter;