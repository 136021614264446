/* eslint-disable */
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import MaskedInput from 'react-text-mask';
import axios from "axios";
import ButtonClass, { ChildBtn } from "../../ButtonClass";
import ContinueToHealth from "../../components/ContinueToHealth"
export class InsureHealth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Gender: 1,
            MobileNo: null,
            PinCode: null,
            IsShowInsuredPerson: false,
            IsShowInsuredChildren: false,
            TblChild: [],
            IsYou: true,
            YouAge: null,
            IsSpouse: false,
            SpouseAge: null,
            IsFather: false,
            FatherAge: null,
            IsMother: false,
            MotherAge: null,
            APIUrl: null,
            CRMAPI: null,
            ClientID: null,
            UserID: 0,
            adult: 0,
            children: 0,
            fields: {},
            validity: '',
            errors: {},
            Req: [],
            you: false,
            spouse: false,
            father: false,
            mother: false,
            insured: {},
            nextComponent: false,
            HistoryRes: null,
            isValid: false,
            adultAgeList: [],
            childAgeList: [],
            redirectToQuote: false,
            BTNValue: "View Quotes",
            IsFound: false,
            IsFirstSec: true,
        };
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm")
        this.removeChild = this.removeChild.bind(this)
        this.LoadFirst = this.LoadFirst.bind(this)
        for (let i = 18; i <= 100; i++) {
            this.state.adultAgeList.push(i)

        }
        for (let i = 1; i <= 24; i++) {
            this.state.childAgeList.push(i)

        }
        this.handle = this.handle.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.LoadFirst();
        let healthReq = window.sessionStorage.getItem("HealthQuoteReq");
        let healthRes = window.sessionStorage.getItem("HealthQuoteRes");
        if (healthReq !== null || healthRes !== null) {
            window.sessionStorage.removeItem("HealthQuoteReq");
            window.sessionStorage.removeItem("HealthQuoteRes");
        }
    }
    componentDidMount() {
        let HealthReq = window.localStorage.getItem("HealthReq");
        if (HealthReq !== null) {
            HealthReq = JSON.parse(HealthReq);
            console.log(HealthReq);
            this.setState({
                HistoryRes: HealthReq,

                IsFound: true
            });
        }
    }
    LoadFirst() {
        let url = this.state.APIUrl == null ? window.location.hostname : this.state.APIUrl;
        if (url.match("www")) {
            url = url.replace("www.", "");
        }
        url = url.replace("https://", "");
        let rq = {
            URL: url,

        }

        let Model = {
            URL: this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq)
        };
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", Model)
                .then((Res) => {
                    this.setState({ ClientID: Res.data.id })

                });
        } catch (err) {
            console.log("Client Id" + err)
        }
        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            //Token = JSON.parse(Token);
            let parseToken = false;
            if (Token.charAt(0) === "{")
                parseToken = true;
            console.log(Token)
            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                PostString: JSON.stringify(data)
            }
            try {
                axios.post("/bpi/WeatherForecast/CallCheck", md)
                    .then((Res) => {
                        this.setState({ UserID: Res.data.userID });
                        this.state.UserID = Res.data.userID;

                    });
            } catch (err) {
                console.log("userID" + err)
            }
        }
    }





    AddEnquiry(adultCount, childCount) {
        this.state.BTNValue = "Wait..."
        let EnqReq = {
            MobileNo: this.state.fields["MobileNo"],
            EnquiryDate: "0001-01-01T00:00:00",
            EnquiryType: "HLT",
            Status: false,
            EnquiryNo: null,
            TotalEnquiry: 0,
            HealthEnquiry: {
                ID: 0,
                TblChild: this.state.TblChild,
                UserID: this.state.UserID,
                PinCode: this.state.fields["PinCode"],
                AdultCount: adultCount,
                ChildCount: childCount,
                ResultId: "0532cecd-12e9-41d4-8a80-b859981f76f2",
                PolicyType: adultCount == 1 ? "Individual" : "Family Floter",
                Gender: this.state.Gender,
                UserAge: this.state.YouAge,
                SpouseAge: this.state.IsSpouse ? this.state.SpouseAge : null,
                FatherAge: this.state.IsFather ? this.state.FatherAge : null,
                MotherAge: this.state.IsMother ? this.state.MotherAge : null,
                SonAge1:
                    childCount > 0
                        ? this.state.TblChild[0].ChildGender == "B"
                            ? this.state.TblChild[0].Age
                            : null
                        : null,
                SonAge2:
                    childCount > 1
                        ? this.state.TblChild[1].ChildGender == "B"
                            ? this.state.TblChild[1].Age
                            : null
                        : null,
                SonAge3:
                    childCount > 2
                        ? this.state.TblChild[2].ChildGender == "B"
                            ? this.state.TblChild[2].Age
                            : null
                        : null,
                SonAge4:
                    childCount > 3
                        ? this.state.TblChild[3].ChildGender == "B"
                            ? this.state.TblChild[3].Age
                            : null
                        : null,
                DoughterAge1:
                    childCount > 0
                        ? this.state.TblChild[0].ChildGender == "G"
                            ? this.state.TblChild[0].Age
                            : null
                        : null,
                DoughterAge2:
                    childCount > 1
                        ? this.state.TblChild[1].ChildGender == "G"
                            ? this.state.TblChild[1].Age
                            : null
                        : null,
                DoughterAge3:
                    childCount > 2
                        ? this.state.TblChild[2].ChildGender == "G"
                            ? this.state.TblChild[2].Age
                            : null
                        : null,
                DoughterAge4:
                    childCount > 3
                        ? this.state.TblChild[3].ChildGender == "G"
                            ? this.state.TblChild[3].Age
                            : null
                        : null,
            },
            UserID: this.state.UserID,
            LeadSource: "online",
            ClientID: this.state.ClientID,
        };

        console.log(EnqReq)
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/Enquiry/AddEnquiry",
            PostString: JSON.stringify(EnqReq)
        }
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", Model)
                .then((data) => {

                    EnqReq.EnquiryNo = data.data.split("~")[0].replace('"', "");
                    this.state.HistoryRes = EnqReq;
                    window.localStorage.setItem("HealthReq", JSON.stringify(this.state.HistoryRes))
                    this.setState({ HistoryRes: EnqReq, redirectToQuote: true });
                });
        } catch (err) { }
    }
    handleRedirect() {
        let isValid = true;
        let errors = {};

        if ((this.state.IsYou === true && (this.state.YouAge < 18))) {
            isValid = false
            errors["YouAgeError"] = "Select Your Age";
        }
        if ((this.state.IsSpouse === true && (this.state.SpouseAge < 18))) {
            isValid = false
            errors["SpouseAgeError"] = "Select Spouse Age";
        }
        if ((this.state.IsFather === true && (this.state.FatherAge < 18))) {
            isValid = false
            errors["FatherAgeError"] = "Select Father Age";
        }
        if ((this.state.IsMother === true && (this.state.MotherAge < 18))) {
            isValid = false
            errors["MotherAgeError"] = "Select Mother Age";
        }
        for (let i = 0; i < this.state.TblChild.length; i++) {
            if (this.state.TblChild[i].Age === null) {
                isValid = false;
                errors["childError"] = "Select Child Age";
                break
            }
        }

        if (isValid)
            this.setState({ IsShowInsuredPerson: true, IsFirstSec: false, errors })
        else this.setState({ errors })

    }
   
    handle = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        if (e.target.type == "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        } else if (e.target.type == "radio") {

            this.setState({ [e.target.name]: e.target.checked, [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        switch (e.target.name) {
            case "YouAge": if ((this.state.IsYou === true && (e.target.value >= 18))) {

                this.state.errors["YouAgeError"] = "";
            }
                break;
            case "SpouseAge": if ((this.state.IsSpouse === true && ((e.target.value >= 18))))
                this.state.errors["SpouseAgeError"] = "";
                break;
            case "FatherAge": if ((this.state.IsFather === true && (e.target.value >=18)))
                this.state.errors["FatherAgeError"] = "";
                break;
            case "MotherAge":
                if ((this.state.IsMother === true && (e.target.value >=  18)))
                    this.state.errors["MotherAgeError"] = "";
                break;
         
        }
        console.log([e.target.name] + "=" + e.target.value)
       
    };

    chkInput(e) {
        const validity = (e.target.validity.valid) ? e.target.value : this.state.validity;
        this.setState({ validity });
    }

    insure = (e) => {
        switch (e.target.name) {
            case "Member":
                let isValid = true;
                let errors = {};
                var pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

                if (this.state.PinCode === null) {
                    isValid = false

                    errors["PinCode"] = "Please Enter Pin Code"
                }
                else if (this.state.PinCode !== null) {

                    if (this.state.PinCode.length < 6) {
                        isValid = false

                        errors["PinCode"] = "Please Enter Pin Code"
                    }
                }
                if (this.state.MobileNo === null) {
                    isValid = false;

                    errors["MobileNo"] = "please Enter Mobile Number"
                }
                else if (this.state.MobileNo !== null) {
                    if (!pattern.test(this.state.MobileNo)) {
                        isValid = false;

                        errors["MobileNo"] = "please Enter Mobile Number"
                    }
                    else if (this.state.MobileNo.length < 10) {
                        isValid = false;

                        errors["MobileNo"] = "please Enter Mobile Number"
                    }
                }
                if (isValid) {
                    let adultCount = 0;
                    let childCount = this.state.TblChild.filter((row) => row.Age > 0).length;
                    if (this.state.IsYou) adultCount += 1;
                    if (this.state.IsSpouse) adultCount += 1;
                    if (this.state.IsFather) adultCount += 1;
                    if (this.state.IsMother) adultCount += 1;
                    if (adultCount > 0 || childCount > 0)
                        this.AddEnquiry(adultCount, childCount);
                }
                this.setState({ errors });
                break;
            case "Children":
                this.setState({
                    IsShowInsuredChildren: !this.state.IsShowInsuredChildren,
                });
                break;
            case "AddMoreChild":
                if (this.state.TblChild.length < 4) {
                    let child = {
                        index: this.state.TblChild.length,
                        ChildGender: "B",
                        Age: null,
                    }
                    this.state.TblChild.push(child);
                    this.setState({ TblChild: this.state.TblChild, IsShowInsuredChildren: true });

                } else return alert("Child can't Exceed")
                break;
        }
    };
    manageChildList(index, name, value) {
        let data = this.state.TblChild.filter((row) => row.index == index)[0];
        if (name == "Gender") {
            data.ChildGender = value;
        } else if (name == "Age") {
            data.Age = value;
        }
        this.state.TblChild[index] = data;
        this.setState({ TblChild: this.state.TblChild });
 
                if (this.state.TblChild[index].Age !== null) {
                
                    this.state.errors["childError"] = "";
                  
                
            
        }
    }
    close = (e) => {
        this.setState({ IsShowInsuredPerson: false });
    };
    removeChild(e) {
        let id = e.target.id;
        let TblChild = this.state.TblChild;
        let index = TblChild.indexOf(id)
        if (id > -1) {
            TblChild.splice(index, 1);

        }
        this.setState({ TblChild })

    }
    render() {
        const HealthReq = this.state.HistoryRes;
        return (


            <div>
                { this.state.redirectToQuote && <Redirect to={{
                    pathname: "/HealthQuotes",
                    state: { Req: this.state.HistoryRes }
                }} />}
                {this.state.IsFirstSec &&
                    <section class="section wizard-section">
                        <div class="container">
                            <div class="row g-3">
                                <div class="col-3 col-md-2 col-lg-1">
                                    <a onClick={this.props.close} class="arw-btn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                        </svg>
                                    </a>
                                </div>
                                <div class="col-9 col-md-10 col-lg-3 position-relative">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <h3 class="f-32 font-regular text-secondary text-capitalize">Please Enter Your Family Details</h3>
                                        </div>
                                    </div>
                                    <div class="pageno d-none d-lg-flex"><span>01</span> &nbsp;of 02</div>
                                </div>
                                <div class="col-lg-8">

                                    <div class="wizard-box box d-flex flex-column">
                                        {/*{this.state.IsFound ?*/}
                                        {/*    <ContinueToHealth gender={HealthReq.HealthEnquiry.Gender} adult={HealthReq.HealthEnquiry.AdultCount} age={HealthReq.HealthEnquiry.UserAge} pincode={HealthReq.HealthEnquiry.PinCode} mobile={HealthReq.MobileNo} closeContinue={() => this.setState({ IsFound: false })} proceed={() => this.setState({ redirectToQuote: true })} /> :*/}
                                        {/*    <div>*/}

                                                <div>
                                                    <label className="ForDesktop">
                                                        <select id="SlectGender" onChange={this.handle} name="Gender">
                                                            <option value="0">Select Gender</option>
                                                            <option id="rblMale" value="1">Male</option>
                                                            <option id="rblFemale" value="2">Female</option>
                                                        </select>
                                                    </label>
                                                    <span className="error">{this.state.errors["Gender"]}</span>
                                                    <label className="ForMobileApp">
                                                        <p className="HeadingTitleBima">Select Your Gender</p>
                                                    <div className="GenderApp Male">
                                                        <input type="radio" id="rblMale" value="1" className={this.state.Gender == "1" ? "active" : ""} checked={this.state.Gender == "1" } name="Gender" onChange={this.handle}  />
                                                            <span><small>Male</small></span>
                                                        </div>
                                                    <div className="GenderApp FeMale">
                                                        <input type="radio" id="rblFemale" value="2" className={this.state.Gender == "2" ? "active" : ""} checked={this.state.Gender == "2"} name="Gender" onChange={this.handle} />
                                                            <span><small>Female</small></span>
                                                        </div>
                                                    </label>
                                                </div>

                                                <div className="ProductFormIn ProductFormInQuote">
                                                    <div className="HealthCustomAgeBox">
                                                        <p className="HeadingTitleBima">Select Members</p>
                                                        <div className="FormRow">
                                                            <div className="col-50">
                                                                <div className="form-control">
                                                                    <label>
                                                                        <span>
                                                                            <input type="CheckBox" name="IsYou" checked={this.state.IsYou} checked />
                                                                            <span class="geekmark"></span>
                                                                            You
                                                                        </span>
                                                                        {this.state.IsYou ?
                                                                            <div>
                                                                                <select
                                                                                    onChange={this.handle} name="YouAge"
                                                                                    value={this.state.YouAge}>
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["YouAgeError"]}</small>
                                                                            </div>
                                                                            : null}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-50">
                                                                <div className="form-control">
                                                                    <label>
                                                                        <span>
                                                                            <input type="CheckBox" name="IsSpouse" checked={this.state.IsSpouse} onChange={this.handle} />
                                                                            <span class="geekmark"></span>
                                                                        Spouse
                                                                    </span>
                                                                        {this.state.IsSpouse ?
                                                                            <div>
                                                                                <select
                                                                                    onChange={this.handle} name="SpouseAge"
                                                                                    value={this.state.SpouseAge}
                                                                                >
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["SpouseAgeError"]}</small>
                                                                            </div>
                                                                            : null}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="FormRow">
                                                            <div className="col-50">
                                                                <div className="form-control">
                                                                    <label>
                                                                        <span>
                                                                            <input type="CheckBox" name="IsFather" checked={this.state.IsFather} onChange={this.handle} />
                                                                            <span class="geekmark"></span> Father
                                                                        </span>
                                                                        {this.state.IsFather ?
                                                                            <div>
                                                                                <select
                                                                                    onChange={this.handle} name="FatherAge"
                                                                                    value={this.state.FatherAge}
                                                                                >
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return v >= (parseInt(this.state.YouAge) + 18) && <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["FatherAgeError"]}</small>
                                                                            </div>
                                                                            : null}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-50">
                                                                <div className="form-control">
                                                                    <label>
                                                                        <span>
                                                                            <input type="CheckBox" name="IsMother" checked={this.state.IsMother} onChange={this.handle} />
                                                                            <span class="geekmark"></span>
                                                                        Mother
                                                                        </span>
                                                                        {this.state.IsMother ?
                                                                            <div>
                                                                                <select
                                                                                    onChange={this.handle} name="MotherAge"
                                                                                    value={this.state.MotherAge}
                                                                                >
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return v >= (parseInt(this.state.YouAge) + 14) && <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["MotherAgeError"]}</small>
                                                                            </div>
                                                                            : null}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        {this.state.IsShowInsuredChildren ? (
                                                        <div className="FormRow">
                                                                {this.state.TblChild.map((row, ind) => (
                                                                        <div className="col-50">
                                                                            <div className="form-control">
                                                                                <label>
                                                                                    <select
                                                                                        name="Gender"
                                                                                        onChange={(e) =>
                                                                                            this.manageChildList(
                                                                                                ind,
                                                                                                e.target.name,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="B">Son</option>
                                                                                        <option value="G">Doughter</option>
                                                                                    </select>

                                                                                    <select
                                                                                        name="Age"
                                                                                        value={this.state.fields["Age"]}
                                                                                        onChange={(e) =>
                                                                                            this.manageChildList(
                                                                                                ind,
                                                                                                e.target.name,
                                                                                                e.target.value
                                                                                            )
                                                                                        }>
                                                                                        <option value="0">Select Age</option>
                                                                                        <option value="0.5">{'<'}1 Year</option>
                                                                                        {this.state.childAgeList.map((v) => {
                                                                                            return <option key={v} value={v}>{v} Year</option>
                                                                                        })}
                                                                                    </select>
                                                                                    <small>{this.state.errors["childError"]}</small>
                                                                                </label>
                                                                            </div>
                                                                            <div className="ageWithdel" onClick={this.removeChild} id={ind}>
                                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                                            </div>
                                                                        </div>
                                                                ))}


                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="FormRow">
                                                        <button className="btn btn-primary form-btn w-auto" onClick={this.insure} name="AddMoreChild">+ Add Children</button>
                                                    </div>

                                                </div>

                                        {/*    </div>*/}
                                        {/*}*/}
                                    </div>
                                    <div class="wizard-bottom">


                                        <button className="btn btn-blue w-100" onClick={this.handleRedirect} >
                                            Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                            </svg>
                                        </button>

                                    </div>
                                </div>
                            </div>



                            <section class="f-14">
                                <div class="row mt-5">
                                    <div class="col-md-11 offset-lg-1">
                                        Copyright 2020 - All right Reserved
                </div>
                                </div>

                            </section>

                        </div>
                    </section>
                }

                {this.state.IsShowInsuredPerson &&
                    <section class="section wizard-section">
                        <div class="container">
                            <div class="row g-3">
                                <div class="col-3 col-md-2 col-lg-1">
                                    <a onClick={() => this.setState({ IsFirstSec: true })} class="arw-btn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                        </svg>
                                    </a>
                                </div>
                                <div class="col-9 col-md-10 col-lg-3 position-relative">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <h3 class="f-32 font-regular text-secondary text-capitalize">Please Enter Your Family Details</h3>
                                        </div>
                                    </div>
                                    <div class="pageno d-none d-lg-flex"><span>02</span> &nbsp;of 02</div>
                                </div>
                                <div class="col-lg-8">

                                    <div class="wizard-box box d-flex flex-column">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <MaskedInput type="tel" className="form-control" onChange={this.handle} value={this.state.PinCode} name="PinCode" value={this.state.PinCode} placeholder="Enter PIN Code" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                <span className="error">{this.state.errors["PinCode"]}</span>
                                            </div>

                                            <div className="col-md-6">
                                                <MaskedInput type="tel" className="form-control" onChange={this.handle} name="MobileNo" value={this.state.MobileNo} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                <span className="error">{this.state.errors["MobileNo"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-bottom">

                                        <button className="btn btn-blue w-100" id="quotesBtn" onClick={this.insure} name="Member" >
                                            {this.state.BTNValue}
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <section class="f-14">
                                <div class="row mt-5">
                                    <div class="col-md-11 offset-lg-1">
                                        Copyright 2020 - All right Reserved
                </div>
                                </div>

                            </section>

                        </div>
                    </section>
                }

            </div>


        );
    }
}
