import React from 'react';
import ButtonClass, { ChildBtn } from '../ButtonClass';
import Loader from '../Health/Loader';
const UrlF = (url) => {
    if (url === null || url === "")
        return url;
    if (url.match("http"))
        return url;
    return url.replace("https://", "").replace("http://", "").replace("//", "https://")
}
const LifeQuoteCard = (props) => {
    return (

        <div className="col-md-4 col-sm-4 col-xs-6 ng-scope mb-3">
            <div className="quotesBoxesMain HealthQuoteUni text-left">
                <div className="row">
                    {/*<div className="IconContainer">*/}
                    {/*    <small className="hideIcon EyeInfo">*/}
                    {/*        {*/}
                    {/*            <i*/}
                    {/*                className="fas fa-eye-slash"*/}
                    {/*                style={{ color: "rgb(183, 180, 180)" }}*/}
                    {/*                */}{/*onClick={() => this.props.hideQuote(this.props.ID)}*/}
                    {/*            ></i>*/}
                    {/*        }*/}
                    {/*        <span>Hide Quote</span>*/}
                    {/*    </small>*/}
                    {/*    <small className="hideIcon IconInfo">*/}
                    {/*        <i*/}
                    {/*            className="fa fa-star"*/}
                    {/*            style={{*/}
                    {/*                color:*/}
                    {/*                    this.props.WishList !== null &&*/}
                    {/*                        this.props.WishList !== undefined &&*/}
                    {/*                        this.props.WishList.filter(*/}
                    {/*                            (row) => row === this.props.ID*/}
                    {/*                        ).length == 1*/}
                    {/*                        ? "#ffc107"*/}
                    {/*                        : "rgb(183, 180, 180)",*/}
                    {/*            }}*/}
                    {/*            onClick={(e) =>*/}
                    {/*                this.props.createWishlist(e, this.props.ID)*/}
                    {/*            }*/}
                    {/*        ></i>*/}
                    {/*        <span id="info">Add to WishList</span>*/}
                    {/*    </small>*/}
                    {/*</div>*/}

                    <div className="col-6">
                        <div className="qouteLogo HealthqouteLogo">
                            {props.IsLoader ?
                                <span class="LoaderImg"></span>
                                :
                                <img src={UrlF(props.logo)} />
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <h4 className="sumInsuredTTL">
                            <strong>
                                Cover Value
                  <br />
                                <b className="ng-binding">{"₹" + Math.round(props.coverValue)}</b>
                            </strong>
                        </h4>
                    </div>


                    <div className="col-12">
                        <div className="featurDetails featurDetailsLife">
                            <p className="lifePlanName">{props.IsLoader ? "" : props.planName}</p>
                            <p>Claim Settlement Ratio <span><i class="fas fa-trophy"></i> {props.claimRatio}%</span></p>
                        </div>

                    </div>

                </div>
                <div className=" ">
                    <div className="QuotesbottomIn pl-3">
                        {/*<div className="col-md-3 no-padding">*/}
                        {/*    <label className="CompareCheck">*/}
                        {/*        <input*/}
                        {/*            className="checkCompare ng-pristine ng-untouched ng-valid ng-empty"*/}
                        {/*            value={this.props.ID}*/}
                        {/*            type="checkbox"*/}
                        {/*            onClick={this.props.compare}*/}
                        {/*        />*/}
                        {/*        <span className="checkmarkCom"></span> <b>Compare</b>*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        <div className="col-md-7 no-padding">
                            <a href={props.brochur} className="seeDetails">Download Brochure <i class="far fa-arrow-alt-circle-down"></i></a>
                        </div>
                        <div className="col-md-5 no-padding">
                            <a className={ChildBtn() + " btn-block"} onClick={props.BuyNow}>
                                {props.IsLoader ?
                                    <Loader bgColor="white" />
                                    :
                                    <>
                                        <span className="buyme">₹{Math.round(props.lifePremium)}</span>
                                        <span><small className="PremiumBuyNow">Buy Now/Year</small></span>
                                    </>
                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}


export default LifeQuoteCard;