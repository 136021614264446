import React from "react";
const Product = [
  {
    id: 1,
    name: "Health ",
    onMouseOver:require("./assets/img/icon/HealthHover.png"),
    src:
      require("./assets/img/icon/Health.png"),
   
  },
  {
    id: 2,
    name: "Car",
    onMouseOver:require("./assets/img/icon/carHover.png"),
    src:
    require("./assets/img/icon/car.png"),
   
  },
  {
    id: 3,
    name: "Two Wheeler ",
  
    onMouseOver:require("./assets/img/icon/bikeHover.png"),
    src:
    require("./assets/img/icon/bike.png"),
   
  },
  {
    id: 4,
    name: "Life",
    onMouseOver:require("./assets/img/icon/LifeHover.png"),
    src:
    require("./assets/img/icon/Life.png"),
  },
  {
    id: 5,
    name: "Commercial",
    onMouseOver:require("./assets/img/icon/truckHover.png"),
    src:
        require( "./assets/img/icon/truck.png"),
   
  },
];
export default Product;
