import React from "react";
import Claims from "../Claims/Claims";
const ClaimsPage = (props) => {
    return (
        <div className="ClaimsPage">
            <div className="ClaimsPageSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="ClaimsPageCnt">
                                <h1>Our team to give you best of the insurance products and Other Services</h1>
                                <div class="row counter-area-small">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/RFL/HlthIcon1.png")} /> <br /> <span class="counter">9.3</span><span>+ Cr</span>
                                            <p>Premium</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/RFL/HlthIcon2.png")} /><br /><span class="counter">9634</span><span>+</span>
                                            <p>Lives Covered</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/RFL/HlthIcon3.png")} /><br /> <span class="counter">13095</span><span>+</span>
                                            <p>Policies Sold</p>
                                        </div>
                                    </div>
                                </div>

                                <small>
                                    *Standard Terms and Conditions Apply.<br />

                                    **Tax benefits are subject to changes in Income Tax Act.
                                </small>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <Claims />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ClaimsPageSec02 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="ClaimsPageSecCNTBox">
                                <h2>Claims Pages</h2>
                                <p>At RFL, our in-house team of experts provide handhold throughout the claims process to our customers and intervene whenever necessary to settle your claims.
                                We ensure that your claims are settled hassle free and without any worries.</p>
                            </div>
                            <div className="ClaimsPageSecCNTBox">
                                <h2>Types of Health Insurance Claims</h2>
                                <div><h3>Cashless Claims:</h3>
                                    <ul>
                                        <li>Approach the insurance desk of hospital.</li>
                                        <li>Show the ID card for identification purpose.</li>
                                        <li>Network hospital would verify your identity and submit pre authorization form to Insurers.</li>
                                        <li>The hospital will assess and process the claim as per terms and conditions of the policy.</li>
                                        <li>The claim will be settled as per policy terms & conditions with the hospital after completion of all formalities.</li>
                                    </ul>

                                    <h3>Reimbursement Claim Process:</h3>
                                    <ul type="disc">
                                        <li>Intimation is mandatory at the time of hospitalization within 24 hrs. </li>
                                        <li>Upon discharge, pay all hospital bills and collect all original documents of treatment undergone and expenses incurred.</li>
                                        <li>All original claim documents are to be submitted with 07 days from the date of discharge or as per policy terms &amp; conditions.</li>
                                        <li>Claim form (Will be provided by RFL Ins. Brokers) has to be filled in and along with, all the relevant original documents have to be submitted to the       nearest RFL office.</li>
                                        <li>The documents will be scrutinized by our in-house Doctor. After verification, the documents will be forwarded to the concerned TPA / Insurers.</li>
                                        <li>The claim will be settled as per policy &amp; conditions of the policy.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <h3>The following are the basic  documents required for filing a claim:</h3>
                                    <ul type="disc">
                                        <li>Duly       completed claim form</li>
                                        <li>Cancelled       cheque / any bank NEFT details </li>
                                        <li>Patients       &amp; Primary Insured&rsquo;s photo id&rsquo;s and address proof. (PANCARD, AADHAR,       ETC) </li>
                                        <li>Original       bills, receipts and discharge certificate/ card from the hospital.</li>
                                        <li>Original       bills from chemists supported by proper prescription.</li>
                                        <li>Receipt       and&nbsp;investigation&nbsp;test reports from a pathologist supported by       the note from attending medical practitioner/ surgeon prescribing the test.</li>
                                        <li>Nature       of operation performed and surgeon's bill and receipt.(In case of       surgery).</li>
                                        <li>Self-declaration/MLC/FIR       in case of accident cases.</li>
                                        <li>Treating       doctor's certificate.</li>
                                        <li>If less       than 15 BED&nbsp;to provide hospital registration certificate.</li>
                                    </ul>
                                    
                                    <h3>Steps For Making Car Insurance  Claim After an Accident</h3>
                                    <ul><li><strong>Step 1:</strong> Inform the Insurance Company</li>
                                        <li><strong>Step 2:</strong> Lodge an FIR in the Nearest Police Station</li>
                                        <li><strong>Step 3:</strong> Capture Photographs as Valid Proof</li>
                                        <li><strong>Step 4:</strong> Submit All the Documents to the Insurer</li>
                                        <li><strong>Step 5:</strong> Ask the Insurance Company to Send a Surveyor</li>
                                        <li><strong>Step 6:</strong> Car Repairs</li>
                                    </ul>
                                    
                                    <p><strong>Repair your car and initiate the  claim process.</strong></p>
                                    <h3>There are two ways or methods of  claims process. </h3>
                                        <ul>
                                            <li>Cashless  Claim Method wherein the customers doesn&rsquo;t want to bear the cost of repair. In  such cases the owner has to take the vehicle to the list of network garages  prescribed by the insurance company. The customer will only have to pay the  deductibles and the rest will taken care by the insurer.</li>
                                            <li>Reimbursement  Claim Method is the process where the customer pays for the damages and gets  the vehicle repaired out of his own pockets and then gets it reimbursed from  the insurance company by submitting original bills, receipts, medical reports,  photographs, etc, to the insurance company. The insurer will reimburse the entire  repair amount after subtracting all the deductibles. In this method the policy  holder gets to repair his car at his preferred Garage.</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ClaimsPage;
