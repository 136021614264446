import React, { Component } from "react";
import {
   
    NavbarBrand,
    
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomerLogin from "./CustomerLogin";
export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            Login: "Login",
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', () => {
            let activeClass = 'normal';
            if (window.scrollY > 10) {
                activeClass = 'FixedHead';
            }
            this.setState({ activeClass });
        });
    }
    
    render() {
        return (
            <header className={"myNav " + this.state.activeClass} id="myNavHead">
                <nav className="mainHeader navbar">
                    <div className="container">
                        <div className="row MainMenu align-items-center">
                            <div className="col-sm-3">
                                <NavbarBrand tag={Link} to="/">
                                    <img
                                        src={require("./assets/img/Vedant/logo.png")}

                                    />
                                </NavbarBrand>
                            </div>
                            <div className="col-sm-9">
                            
                                <ul className="MN_Menu navMenu navbar-right">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/About"  >
                                            About Us
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/claims">
                                            Claims
                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/ePartner">
                                            ePartner
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-dark"
                                            to="/contact-us"
                                        >
                                            Contact Us
                </NavLink>
                                    </NavItem>
                                    <li>
                                        <CustomerLogin />
                                    </li>

                                    <li>
                                        <a href="/myaccount">
                                            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
      &nbsp; My Account
    </a>
                                    </li>

                                    <li id="talkToExperts" className="talkToExperts">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}
