import React from "react";
const TrustUs = (props) => {
  return (
    <div className="trust-us">
      <img
        src={require(`../Herocomponents/assets/img/${props.name}`)}
        alt={props.alt}
        className={props.className}
      />
    </div>
  );
};
export default TrustUs;
