import React from "react";
const WhyCompare = (props) => {
    return (
        <div class="section-full content-inner-1 about-service">
            <div class="container">
                <h1 class="wc-heading">Why Compare With <span class="span">InshoraCover</span><br /><small>More than just comparison</small></h1>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-save"></i>
                                <h5>Save money</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-clock"></i>
                                <h5>Fast and Easy</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-comparison"></i>
                                <h5>More than 30 companies to choose from</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-money-bag"></i>
                                <h5>It's 100% free</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-social-care"></i>
                                <h5>Always Impartial </h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-target"></i>
                                <h5>Subject matter experts</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-reliability"></i>
                                <h5>Trustworthy</h5>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col-12 why-compare wow fadeInUp">
                                <i class="flaticon-user"></i>
                                <h5>We're not owned by insurer.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default WhyCompare;
