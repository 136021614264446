import React from 'react';
import MaskedInput from 'react-text-mask'
import ButtonClass from '../../ButtonClass';
import CustomFact from '../../Life/CustomFact';
const ReligareLife = (props) => {
    let errors = props.errors
    let date = new Date();
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let yy = date.getFullYear();
    let MaxDate = yy - 18 + "-" + mm + "-" + dd;
    let currDate = yy + "-" + mm + "-" + dd;
    return (
        <React.Fragment>

            <div className="packagesMainHealth text-center">
                <div className="productTabsMainHealth productTabsMainHelath text-center">

                    <h3 className="heading text-center">Term Life Plans at Lowest Rates</h3>

                    <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                        <div className="PF0012">
                            <form>
                                <div className="row">
                                    <div className="col-md-2 offset-md-1">
                                        <label>
                                            <select name="Gender" id="drpGender" value={props.Gender} onChange={props.handle}>
                                                <option value="Select">Select Gender</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select>
                                        </label>
                                        <span className="error">{errors.Gender}</span>

                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            <MaskedInput id="txtFirstName" value={props.FullName} onChange={props.handle} name="FullName" placeholder="Enter Full Name"
                                                mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} />

                                        </label>
                                        <span className="error">{errors.FullName}</span>

                                    </div>
                                    <div className="col-md-2">
                                        <label>



                                            <input type="date" max={MaxDate} defaultValue={currDate} name="DOB" onChange={props.handle} value={props.DOB} id="textDOB" required="required" placeholder="DD/MM/YYYY" className="hasDatepicker" />
                                        </label>
                                        <span className="error">{errors.DOB}</span>

                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <MaskedInput name="Mobile" value={props.Mobile} onChange={props.handle} mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" id="txtMobile" required="required" placeholder="+91"
                                                guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                        </label>
                                        <span className="error">{errors.Mobile}</span>

                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-2 offset-md-5 mt-3">
                                        <button type="button" className={ButtonClass()} id="FirstForm" onClick={props.CallPopUp}>Next</button>
                                        <p className="Term_CNT"><input type="checkbox" /> I Agree to<a target="_blank"> <span>Terms and Conditions</span></a></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {
                props.showPopUp && <CustomFact handle={props.handle}
                    SmokeStatus={props.SmokeStatus} close={props.close}
                    AnnualIncome={props.AnnualIncome} SumInsured={props.SumInsured} btnValue={props.btnValue}
                    Coverage={props.Coverage} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList} />
            }
        </React.Fragment>
    );
}


export default ReligareLife;