import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const Individualcoverforfamily = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg IndividualBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Individual Plan</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">What is a Individual cover floater policy?</h3>
                            <p class="padR_160">When every family member in a family is having separate SA in the mediclaim policy and one member cannot use the SA of other member, such policy and cover is known as Individual cover.</p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>

            <section class="ProductPageSectionThird ProductPageSectionThirdFeature">
                <div class="container">
                    <div class="SecondSectionHeadingBox">
                        <h3 class="SecondSectionHeading">Features of a Mediclaim Policy</h3>
                        <p class="text-center">
                            A mediclaim policy whether it is Individual cover or Floater cover have below mentioned possible covers
            </p>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Inpatient Care & Day Care.svg")} />
                                <p>
                                    <strong>Inpatient    Care &amp; Day Care </strong><br />
                        Upto    SA
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Room rent.svg")} />
                                <p>
                                    <strong>
                                        Room    rent
                        </strong><br />
                        Converge    available on <br />customer choice
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Ambulance cover.svg")} />

                                <p>
                                    <strong>
                                        Ambulance    cover
                        </strong><br />
                        Road    and Air ambulance
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Organ Donor Cover.svg")} />
                                <p>
                                    <strong>
                                        Organ    Donor Cover
                        </strong><br />
                        Covered    in maximum policies <br />up to SA or up to some limit
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Domiciliary  Hospitalisation.svg")} />
                                <p>
                                    <strong>
                                        Domiciliary� Hospitalisation
                        </strong><br />
                        Maximum    policy covers
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Recharge or refill in SA.svg")} />
                                <p>
                                    <strong>
                                        Recharge/refill    in SA
                        </strong><br />
                        SA    refill without any extra<br /> charge if existing SA exhausted completed in a Year
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/No claim bonus.svg")} />
                                <p>
                                    <strong>
                                        No    claim bonus
                        </strong><br />
                        Available    in different <br />percentage in plans
                    </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Annual health check up.svg")} />
                                <p>
                                    <strong>
                                        Annual    health check up
                        </strong><br />
                        Available
                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="ProductPageSectionFirst">
                <div class="container">
                    <h3 class="SecondSectionHeading mb-3">Pre-existing condition</h3>

                    <p class="text-center">
                        Pre-existing disease is a medical condition or healthcare problem that existed before enrolling in a healthcare plan. As a customer we all need to disclose all the related information of any treatment taken for any illness surgery or injury.
        </p>
                </div>
            </section>
            <section class="ProductPageSectionThird">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="SecondSectionHeadingBox">
                                <h3 class="SecondSectionHeading mb-3">Exclusions and Waiting Periods</h3>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p>
                                The below aspects are out of the coverage of a health insurance
                </p>
                            <ul class="DoNotNeed">
                                <li>Non-medical expenses</li>
                                <li>30 day waiting periods for any claim other than an accident.</li>
                                <li>Sexually transmitted diseases</li>
                                <li>Cosmetic or plastic surgery</li>
                                <li>Any condition that occurs because of war, nuclear attack, or terrorist attack</li>
                                <li>Health issues related to alcohol or drug abuse</li>
                                <li>Most companies have specific waiting periods for diseases like cataract, hernia etc.</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/Religare/Floater-cover-for-family_Exclusions-and-Waiting-Periods.jpg")} />
                        </div>
                    </div>
                </div>
            </section>







        </React.Fragment>
    );
};
export default Individualcoverforfamily;
