import React, { Component } from "react";
import Social from "./Social";
const Bottom = () => {
  return (
      <div class="footer-copyright">
          <p>Copyright &copy;  2020 Providence India Insurance Broking. All rights reserved.</p>
      </div>
  );
};
export default Bottom;
