import React, { useEffect } from 'react';
/*import { Beforeunload } from 'react-beforeunload';*/
const PaymentSuccessOrFailDumy = (props) => {

    
        return (
            <section className="PayThanksPage">
               {/* {props.IsLoader && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}*/}
                <div className="container">
                    <div className="PayThanksPageMainBox text-center">
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="PayThanksPageMain">
                           

                            <div className="PayThanksPageBox PayThanksPageBox1">
                                {props.IsLoader ? "Loading.." :props.PaymentStatus ? <React.Fragment><i class="fas fa-thumbs-up"></i>
                                    <div className="alert alert-success w-50 m-auto">
                                        Payment Successfully Processed.
                            </div>
                                </React.Fragment>:
                                <React.Fragment><i class="fas fa-thumbs-down"></i>
                                        <div className="alert alert-danger w-50 m-auto">
                                            Ooops! Payment Failed
                            </div>
                                </React.Fragment>}

                                
                            </div>

                            <div className="PayThanksPageBox border-success border rounded alert-success PayThanksPageBox2">
                                <div className="innerBox rounded border-success border">
                                    <h2>{props.IsLoader ? "Loading.." : props.name}</h2>
                                    <p>{props.IsLoader ? "Loading.." : props.InsurerLogo.CompanyName} {props.PaymentStatus && <a href={ props.path}><i style={{ right: "0px" }} className="fas fa-file-download"></i></a>}</p>
                                   
                                </div>
                                <h3>Your online policy Purchase ID - <span>{props.IsLoader ? "Loading.." :props.transectionNo}</span></h3>
                                <div className="innerBoxOf">
                                    <div className="innerBox rounded border-success border d-flex align-items-center justify-content-center">
                                        {props.IsLoader ? "Loading.." : <img src={props.InsurerLogo.Logo} alt="Payment Logo" />}
                                    </div>
                                    <div className="innerBox rounded border-success border text-center">
                                        <h5>Total Premium</h5>
                                        <h4>{props.IsLoader ? "Loading.." : props.premium ? ("₹ " + props.premium):"Not Available"}</h4>
                                    </div>
                                </div>
                            </div>

                            {props.PaymentStatus && <small>If you are not able to download policy documents from above link. You will get policy documents on your registerd Email ID. </small>}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

export default PaymentSuccessOrFailDumy