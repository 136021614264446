/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import Social from "./Social";
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerDiv: false,
        }
        this.addClass = this.addClass.bind(this)

    }
    addClass(e) {
        let divID = document.getElementById("f001")
        let bID = document.getElementById("toggle001");
        if (this.state.footerDiv) {
            divID.classList.remove("HideBox");
            bID.classList.remove("HideBtn");
        } else {
            divID.classList.add("HideBox");
            bID.classList.add("HideBtn");
        }
        this.setState({ footerDiv: !this.state.footerDiv })
    }
    render() {


            return (
                <React.Fragment>
                    <section>
                        <div class="row m-0 justify-content-center btntgle"> <a href="javascript:void(0)" onClick={this.addClass} id="toggle001" class="ftr-arrow"><b></b></a> </div>
                        <div className="foot-bg" >
                            <div className="container">
                                <div className="row gap-sect" id="f001">
                                    <div className="col-sm-3">
                                        <ul class="list-sect">
                                            <li class="visible-medium">
                                                <p>Health Insurance</p>
                                            </li>
                                            <li><a href="">About Health Insurance</a></li>
                                            <li> <a href="">Individual Health Insurance</a></li>
                                            <li> <a href="">Family Health Insurance</a></li>
                                            <li> <a href="">Mediclaim Policy</a></li>
                                            <li> <a href="">Senior Citizen Health Insurance</a></li>
                                            <li> <a href="">Critical Illness Health Insurance</a></li>
                                            <li> <a href="">Top up Health Insurance</a> </li>
                                            <li> <a href="">Health Insurance Calculator</a> </li>
                                            <li> <a href="">Hospital Cash</a></li>
                                            <li> <a href="">Personal Accident Policy</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-3">
                                        <ul class="list-sect">
                                            <li class="visible-medium">
                                                <p>Motor Insurance</p>
                                            </li>
                                            <li><a href="motor-insurance-category.html">About Motor Insurance</a></li>
                                            <li> <a href="car-insurance.html">Car Insurance</a></li>
                                            <li> <a href="two-wheeler-insurance.html">Two Wheeler Insurance</a></li>
                                            <li><a href="car-insurance-calculator.html">Car Insurance Calculator</a></li>
                                            <li> <a href="bike-insurance-calculator.html">Two Wheeler Insurance Calculator</a></li>
                                            <li> <a href="third-partycar-insurance.html">Third Party Car Insurance</a></li>
                                            <li> <a href="third-party-two-wheeler-insurance.html">Third Party Two Wheeler Insurance</a></li>
                                            <li> <a href="commercial-vehicle-insurance.html">Commercial Vehicle Insurance</a></li>
                                            <li> <a href="commercial-car-taxi-insurance.html">Commercial Car/Taxi Insurance</a></li>
                                            <li> <a href="no-claim-bonus.html">No Claim Bonus (Car Insurance)</a></li>
                                            <li> <a href="insured-declared-value.html">Insured Declared Value (Car Insurance)</a></li>
                                            <li> <a href="add-on-covers.html">Add on Covers (Car Insurance)</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-3">
                                        <ul class="list-sect">
                                            <li class="visible-medium">
                                                <p>Quick Links</p>
                                            </li>
                                            <li> <a href="corporate-insurance.html">Corporate Insurance</a> </li>
                                            <li> <a href="about-us.html">About Us</a> </li>
                                            <li> <a href="careers.html">Careers</a></li>
                                            <li> <a href="contact-us.html">Contact Us</a> </li>
                                            <li> <a href="#">Sitemap</a> </li>
                                            <li> <a href="health-insurnace-faqs.html">Health Insurance FAQs</a> </li>
                                            <li> <a href="car-insurance-faqs.html">Car Insurance FAQs</a> </li>
                                            <li> <a href="bike-insurance-faqs.html">Bike Insurance FAQs</a> </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-3">
                                        <ul class="list-sect">
                                            <li class="visible-medium">
                                                <p>Renewal</p>
                                            </li>
                                            <li> <a href="health-insurnace-renewal.html">Health Insurance Renewal</a> </li>
                                            <li> <a href="car-insurance-renewal.html">Car Insurance Renewal</a> </li>
                                            <li> <a href="bike-insurance-renewal.html">Two Wheeler Insurance Renewal</a> </li>
                                        </ul>
                                        <ul class="list-sect visible-medium d-none d-md-block">
                                            <li class="visible-medium1">
                                                <p>Claim</p>
                                            </li>
                                            <li> <a href="health-Insurance-claim.html">Health Insurance Claim</a> </li>
                                            <li> <a href="car-insurance-claim.html">Car Insurance Claim</a> </li>
                                            <li> <a href="two-wheeler-insurance-claim.html">Two Wheeler Insurance Claim</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="footer sclbgftr row">
                                <Social />
                            </div>
                            <Bottom />
                        </div>
                    </section>
                </React.Fragment>
            );
        };
    }
export default Footer;
