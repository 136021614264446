import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const EndowmentPlans = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg EndowPlanBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Endowment Plans</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">What is Endowment Plans?</h3>
                            <p class="padR_160">
                                Endowment Plans. Endowment plan is another type of life insurance plan, which is a combination of fixed bonus and death cover.
                                Typically, there are two types of endowment plans. Traditional endowment plans are those plans that offer insurance plus investment under a single policy. Such plans are a long term life insurance contract where the policyholder has to pay premium throughout the tenure of the policy or may opt for single pay or limited payment option. Endowment plans are designed for the long term benefits and can be known as child education plans or weddings.
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>



            <section class="ProductPageSectionThird">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="SecondSectionHeading SecondSectionHeadingZeroDep">Features of endowment Plans</h3>
                        </div>
                        <div class="col-md-6">
                            <ol class="DoNotNeed">
                                <li>Low-risk plans as the maturity benefits  are guaranteed and well defined.</li>
                                <li>Financial security </li>
                                <li>Tax benefits under section 80  (C) under Income tax  Act.</li>
                                <li>Monthly, quarterly, half yearly and annual  premium paying options. </li>
                                <li>
                                    Different types of bonuses are given by the insurance  providers. Bonus is the extra money which is additional to the proceeds that  are distributed to the policyholders. Only policyholders taking up the with  profit plans are eligible for this benefit.
                    </li>
                            </ol>
                        </div>
                        <div class="col-md-6 text-right">
                            <img src={require("../assets/img/Religare/lifeinsurance/Endowment-plan_1.jpg")} className="EndowmentPlans mb-4" />
                        </div>
                        <div class="col-md-12">
                            <ul class="DoNotNeed" type="disc">
                                <li><strong>Revisionary Bonus:</strong> It is the additional money to be paid       to the nominee in the event of death of policyholder or added to maturity       amount in case of with-profits policy. Once this option is taken it cannot       be changed if the policy is in force till maturity or death of the       insured.</li>
                                <li><strong>Terminal Bonus:</strong> It is the discretionary amount of money       that is added to the payments to be made at end of the policy or on the       death of the policyholder.</li>
                                <li>
                                    <strong>Available Riders:</strong> There are additional riders for  the policyholders to choose from as per requirements of policyholders
                        <ul class="DoNotNeed" type="disc">
                                        <li>Accidental Death Benefit</li>
                                        <li>Family Income Benefit</li>
                                        <li>Critical Illness Benefit</li>
                                        <li>Hospital Cash Benefit</li>
                                        <li>Waiver of Premium Benefit</li>
                                        <li>Accidental Permanent Total/Partial Disability  Benefit</li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default EndowmentPlans;
