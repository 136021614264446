import React from "react";
const Dedicated = (props) => {
  return (
	  <div class="features-area in-section section-padding-md bg-white">
		  <div class="container">
			  <div class="row features-wrapper">
				  <div class="col-lg-4 col-md-6 col-12">
					  <div class="in-feature">
						  <span class="in-feature-icon">
							 <img src={require("../assets/img/DemoTemp/Lock.png")} />
						  </span>
						  <h4>Secure Account With Gurantee</h4>
						  <p>Do eiusmod tempor incididunt ut labore et dolore masit amet.</p>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-12">
					  <div class="in-feature">
						  <span class="in-feature-icon">
							  <img src={require("../assets/img/DemoTemp/Lab.png")} />
						  </span>
						  <h4>10 Years Of Experience</h4>
						  <p>Do eiusmod tempor incididunt ut labore et dolore masit amet.</p>
					  </div>
				  </div>
				  <div class="col-lg-4 col-md-6 col-12">
					  <div class="in-feature">
						  <span class="in-feature-icon">
							  <img src={require("../assets/img/DemoTemp/Partner.png")} />
						  </span>
						  <h4>Dedicated Team With Strong Skill</h4>
						  <p>Do eiusmod tempor incididunt ut labore et dolore masit amet.</p>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
    );
};
export default Dedicated;
