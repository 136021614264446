import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const TermPlans = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg TermPlanBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Term Plans</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">Benefits of Term Insurance With Maturity Benefit</h3>
                            <p class="padR_160">
                                The term life insurance plans with maturity benefits offer a number of attractive benefits which include
                </p>
                            <div class="ProductPageSectionThirdP_Car">

                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/It Can Help You Deal with Debts.svg")} />

                                    </div>
                                    <div class="col-md-11">
                                        <p>
                                            <strong>
                                                Death Benefits:
                                </strong><br />  Term insurance plans offer death benefits to designated nominees. The nominees will receive these death benefits, if the life assured dies within the policy tenure.
                            </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Your Family Will Be Taken Care Of.svg")} />

                                    </div>
                                    <div class="col-md-11">
                                        <p><strong>Maturity Benefits: </strong><br />Normally, traditional life insurance policies don�t offer maturity benefits. But term return of premium life insurance policies offer maturity benefits by returning the total amount of premiums paid so far, provided a policy is continued till the end of term.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Life Insurance Can Supplement Your Retirement Goals.svg")} />

                                    </div>
                                    <div class="col-md-11">
                                        <p><strong>Tax Benefits: </strong><br />A policyholder can enjoy tax benefits over the premiums paid for term life insurance plans with maturity benefits. The premiums paid and the amount received are exempted from income tax assessment under section 80C and 10 (10D) of the Indian Income Tax Act, 1961.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/It Can Help You Achieve Your Long Term Goals.svg")} />
                                    </div>
                                    <div class="col-md-11">
                                        <p><strong>Term life insurance:</strong><br /> Term life insurance plans with maturity benefits also offer additional riders such as Critical Illness and Accidental Death or Disability riders.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>



            <section class="ProductPageSectionThird">
                <div class="container">
                    <h3 class="SecondSectionHeading SecondSectionHeadingZeroDep">Benefits of Term Insurance With Maturity Benefit</h3>
                    <table border="1" cellspacing="0" cellpadding="0" class="table table-bordered">
                        <tr>
                            <td width="591"><p>Entry    Age</p></td>
                            <td width="591">
                                <p>
                                    Minimum    entry age:18 years<br />
                                        Maximum    entry age: 65 years
                    </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="591"><p>Plan type</p></td>
                            <td width="591"><p>Term    insurance plans offers flexibility in terms of choosing a plan. One can    choose a plan on single life basis or joint life basis.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Premium    paying term</p></td>
                            <td width="591"><p>Single pay, Limited pay and Regular pay.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Age    at maturity</p></td>
                            <td width="591"><p>25    year/ 65 years/ 75 years to whole life. Differ from policy to policy.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Premiums</p></td>
                            <td width="591"><p>Based    on sum assured and age of the applicant.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Nomination</p></td>
                            <td width="591"><p>Term    life insurance policies with maturity benefits accept nomination.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Sum    Assured</p></td>
                            <td width="591"><p>Vary    from policy to policy offered by various life insurance companies.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Policy    coverage</p></td>
                            <td width="591"><p>Death    benefits and maturity benefits.</p></td>
                        </tr>
                        <tr>
                            <td width="591"><p>Policy    term</p></td>
                            <td width="591">
                                <p>
                                    Minimum-    5 years<br />
                                            Maximum    - 30-35 years
                    </p>
                            </td>
                        </tr>
                    </table>

                </div>
            </section>
        </React.Fragment>
    );
};
export default TermPlans;
