import React from "react";
import TrustUs from "./TrustUs";
const Trust = (props) => {
  return (
    <React.Fragment>
      <div className="container pt-5 pb-5">
        <div className="row d-flex justify-content-center">
          <div className="col-sm--12 ">
            <h1 className="heading-trust">Here's Why You Can Trust Us</h1>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="member-container ">
                <TrustUs name="Total-Customers-02.svg" alt="member" className="trust-img" />
                <span>Over 15 Million Customer</span>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="member-container ">
                <TrustUs
                                  name="certified-01.svg"
                  alt="member"
                  className="trust-img"
                />
                <span>PCI DSS Certified Payment Gateway</span>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="member-container">
                <TrustUs name="ISO-02.svg" alt="member" className="trust-img" />
                <span>ISO 9001:2015 <br></br>ISO 27001:2013</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Trust;
