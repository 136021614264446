import React from "react";
const Product = [
    {
        id: 1,
        name: "Car",
        to: "/Car",
        className: "nav-item nav-link",
        src:
            require("./assets/img/Sansom/car.svg"),
    },
    {
        id: 2,
        name: "2 Wheeler",
        to: "/Bike",
        className: "productTabsRisk",
        src:
            require("./assets/img/Sansom/Bike.svg"),
    },
    {
        id: 3,
        name: "Commercial",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/Commercial.svg"),
    },
    {
        id: 4,
        name: "Health",
        className: "productTabsRisk",
        to: "/Health",
        src:
            require("./assets/img/Sansom/Health.svg"),
    },
    {
        id: 5,
        name: "Life",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/Life.png"),
    },
    {
        id: 6,
        name: "Travel",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/travel.svg"),
    },
    {
        id: 7,
        name: "Home",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/home.svg"),
    },
    {
        id: 8,
        name: "Personal Accident",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/PersonalAccident.svg"),
    },
    {
        id: 9,
        name: "Coronavirus",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/coronavirus.png"),
    },
    {
        id: 10,
        name: "Fire Earthquake",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Sansom/fire.svg"),
    },
];
export default Product;
