import React from 'react';
import AdvanceLife from '../AdvanceComponents/HomeTab/AdvanceLife';
import BimaPlannerLife from '../BimaPlannerComponents/HomeTab/BimaPlannerLife';
import InsureasyLife from '../InsureeasyComponents/HomeTab/InsureasyLife';
import LifeMartLife from '../LifeMartComponents/HomeTab/LifeMartLife';
import RiskLife from '../RiskFreeLifeComponents/HomeTab/RiskLife';
import SansomLife from '../SansomComponents/HomeTab/SansomLife';
import ReligareLife from '../ReligareComponents/HomeTab/ReligareLife';
import Life from './Life';
import BimastreetLife from '../BimaStreetComponents/HomeTab/BimastreetLife';
import PratirakshaLife from '../PratirakshaComponents/HomeTab/PratirakshaLife';
import RiskoveryLife from '../Riskoverycomponents/HomeTab/RiskoveryLife';
const LifeUIManager = (props) => {
    let url = window.localStorage.getItem("portal") == null ? window.location.hostname : window.localStorage.getItem("portal");
    if (url.match("hero")) {

        return <Life close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    } else if (url.match("insureasy")) {

        return <InsureasyLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />

    } else if (url.match("myinsurancekart")) {
        return <LifeMartLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    } else if (url.match("bimaplanner")) {
        return <BimaPlannerLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    } else if (url.match("sansominsure"))
        return <SansomLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    else if (url.match("riskfreelife"))
        return <RiskLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    else if (url.match("advancelifeinsurance")) {
        return <AdvanceLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    }
    else if (url.match("religareonline")) {
        return <ReligareLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    }
    else if (url.match("riskoveryinsurance")) {
        return <RiskoveryLife close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    }
    else if (url.match("bimastreet")) {
        return <BimastreetLife bimaClose={props.bimaClose} close={props.close} errors={props.errors} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    }
    else {
        return <InsureasyLife close={props.close} btnValue={props.btnValue}
            CallPopUp={props.CallPopUp} showPopUp={props.showPopUp} errors={props.errors}
            handle={props.handle} DOB={props.DOB} FullName={props.FullName} Gender={props.Gender}
            Mobile={props.Mobile} AnnualIncome={props.AnnualIncome} Coverage={props.Coverage} SmokeStatus={props.SmokeStatus}
            SumInsured={props.SumInsured} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList}
            BtnValue={props.BtnValue} />
    }
}
export default LifeUIManager