import React from 'react'
import "./Brand.css"
const Brand = (props) => {
    return (
        <React.Fragment>
            <div className="brandBox">
                <img src={window.localStorage.getItem("portal") + "/insurer logo/Brand/" + props.ManufacturerName + ".png"} />
                <span>{props.ManufacturerName}</span>
            </div>
        </React.Fragment>
    );
};
export default Brand;