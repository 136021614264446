import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import WhyChoose from "./WhyChoose";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section>
                <div className="services-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 align-items-center">
                                <div className="section-heading-2 section-heading-2-p">
                                    <h4>Our Services</h4>
                                    <h3>Services We provides</h3>
                                    <p>Kare India is a customer oriented organization which aspires to meet the highest standards of services expected by its clients. We work in partnership with our clients to help them protect their insurable interests so that they can focus on their respective productive areas. At Kare India, our goal is to safeguard our client's utmost interest. In doing so we build relationship by involving ourselves in all the aspects of the creation of the insurance philosophy and ensure that client's insurance position is adequately protected at all times.</p>
                                </div>
                                <div className="row section-heading-2Left">
                                    <div className="col-md-4">
                                        <div className="single-service">
                                            <div className="service-icon">
                                                <i className="fas fa-car"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Four Wheeler Insurance</h3>
                                                <p>Car insurance known as auto or motor insurance protects the value of the vehicle in cases where due to uninvited incident like accident.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="single-service aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                            <div className="service-icon">
                                                <i className="fas fa-motorcycle"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Two Wheeler Insurance</h3>
                                                <p>Bike insurance known as auto or motor insurance protects the value of the vehicle in cases where due to uninvited incident like accident</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="single-service">
                                            <div className="service-icon">
                                                <i className="fas fa-heartbeat"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Life Insurance</h3>
                                                <p>
                                                    Term Life Insurance Plan This is the most sought after Life Insurance Plan today for those who understand the value of Life.
                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                            <div className="service-icon">
                                                <i className="fas fa-heart"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Health Insurance</h3>
                                                <p>The Insurance pays towards the expenses made by an individual or any of his family members when hospitalized as an inpatient</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                            <div className="service-icon">
                                                <i className="fas fa-truck"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Commercial Insurance</h3>
                                                <p>Commercial Auto insurance and Aircraft insurance There is ahuge number of various policies available under this head primarily.</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                            <div className="service-icon">
                                                <i className="fas fa-plane"></i>
                                            </div>
                                            <div className="service-text">
                                                <h3>Travel Insurance</h3>
                                                <p>For frequent travelers both International as well as domestic, we recommend the multi-trip policy that will cover travelers for an entire year.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>
            <Zoom>
                <WhyChoose />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
