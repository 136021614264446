import React from "react";
const SocialConnect = (props) => {
    return (
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 mx-auto text-center home-social-media">
                        <h3>Connect with us</h3>
                        <p>Follow , Post and Like . We would love to hear from you</p>
                        <ul class="mt-4 flex home-sm-ul">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/iNSHORA/" target="_blank" class="social_icon">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="social_icon">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/company/inshora-risk-advisory-and-insurance-broking-pvt-ltd/?originalSubdomain=in" target="_blank" class="social_icon">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="social_icon">
                                    <i class="fab fa-google-plus-g"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="social_icon">
                                    <i class="fab fa-youtube"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default SocialConnect;
