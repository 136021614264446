import React, { useState } from 'react';
import { useEffect } from 'react';
const RedirectForm=async (props)=>{
    console.log(props)

    useEffect(() => {
        try {
            document.getElementsByTagName("form")[0].submit();
        } catch (err) { }
        
    }
        , [props.url]);
    
    return (
        props.url
       /* <div id="paymentForm" dangerouslySetInnerHTML={{ __html:props.url }} >
           
        </div>*/
    )
}
export default RedirectForm;