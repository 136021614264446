import React, { Component } from "react";
import Riskovery from "./Riskoverycomponents/Riskovery";
import './Contents/css/MainStyle.css';
import './Contents/css/NewMainStyle.css';
import './Contents/css/NeUIStyle.css';
import './App.css'
import ComponentManager from "./ComponentManager"
import ApiUrl from "./Login/ApiUrl";
import ManageUrl from "./components/ManageURL";
import AppGetAlert from "./components/AppPreview/AppGetAlert";
import AppPreview from "./components/AppNotification/AppPreview";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
     let url=window.location.href;
    // if(url.match("www")){
    //   url=url.replace("www.","");
    //   window.location.href=url;
    //   }
    if(url.match("/api/Policy")){
      if(url.match("www")){
        url=url.replace("www.","");
      }else{
        url=url.replace("https://","https://www.")
      }
      window.location.href=url
    }
     let Data=ManageUrl(window.location.href);
      window.localStorage.setItem("portal",Data.PortalAPI);
      window.localStorage.setItem("crm", Data.CRMAPI);
      
    }
    componentDidMount() {
        let prm = new URLSearchParams(window.location.search);
        if (prm.get("user") != null && prm.get("user") != undefined) {
          let d={
            token:prm.get("user")
          }
            window.localStorage.setItem("token",JSON.stringify(d) );
        }
    }
  static displayName = App.name;
    getDomain(hostname) { return hostname.substr(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1) + 1) }
   
    render() {
       
    return <React.Fragment> 
    <ApiUrl />
        <ComponentManager />
        <AppGetAlert />
       {/* <AppPreview/>*/}
    </React.Fragment>;
  }
}

export class MasterGlobal extends Component {
  static btn = "";
  static CRMAPI = "";
  static PortalAPI = "";
  static CommonDomain = "https://heroinsurance.com/centralapi/";

  //CompanyAPI() {
  //    var CurrentContext = window.location.hostname.includes("smc");
  //    if (CurrentContext == true) {
  //        var opp="ok"
  //    }
  //    if (window.location.hostname.includes("smc")) {
  //        return "http://crmapi.smcinsurance.com:8081";
  //    }
  //    else if (window.location.hostname.includes("m.riskovery")) {
  //        return "http://corecrmapi.stariengineering.com";
  //    }
  //    else {
  //        return "http://crmapi.smcinsurance.com:8081";
  //    }
  //}
  //static PortalAPI(url) {
  //    var CurrentContext = window.location.hostname;
  //    if (window.location.hostname.includes("m.smc")) {
  //        return "https://smc.smcinsurance.com";
  //    }
  //    else if (window.location.hostname.includes("m.riskovery")) {
  //        return "https://www.riskoveryinsurance.com";
  //    }
  //    else {
  //        return "https://smc.smcinsurance.com";
  //    }
  //}
}
