import React from "react";
import TabData from "./TabData";
const data = TabData;
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <div
            onClick={props.onClick}
            className="productTabsRisk productTabsRisk--active"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>

    ) : (
            <div
                onClick={props.onClick}
                className="productTabsRisk"
                data-tab={props.dataTab}
            >
                {props.title}
            </div>
        );
}

function TabContent(props) {
    return (
        <div style={props.style} data-tab={props.dataTab}>
            {props.content}
        </div>
    );
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isActive: "1", data: data };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.getAttribute("data-tab") });
        console.log(ev.target);
    }

    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                dataTab={item.id}
                title={item.tabTitle}
                key={item.id}
                to={item.to}
            />
        ));
        var listContent = this.state.data.map((item, i) =>
            this.state.isActive === item.id ? (
                <TabContent dataTab={item.id} content={item.tabContent} key={i} />
            ) : (
                    <TabContent
                        style={{ display: "none" }}
                        dataTab={item.id}
                        content={item.tabContent}
                        key={i}
                    />
                )
        );
        return (
            <section className="homeSection">
                <div class="blur-bg-blocks">
                    <aside class="blur-bg-set">
                        <div class="blur-bg blur-bg-a"></div>
                        <div class="blur-bg blur-bg-b"></div>
                        <div class="blur-bg blur-bg-c"></div>
                    </aside>
                </div>
                <div className="container">
                    <div className="tabs">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ProHeadCNT">
                                    <h1><strong>Buy Insurance</strong>, The Smart Way.</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus, risus sit amet auctor sodales, justo erat tempor eros.
                                    </p>
                                    <div class="hero-feature aos-init aos-animate" data-aos="zoom-out-up" data-aos-delay="800">
                                        <div class="media v-center">
                                            <div class="icon-pora"><img src={require("../assets/img/Vedant/fast-time.png")} alt="icon" class="w-100" /></div>
                                            <div class="media-body">Quick, Easy &amp;	Hassle Free</div>
                                        </div>
                                        <div class="media v-center">
                                            <div class="icon-pora"><img src={require("../assets/img/Vedant/customer-services.png")} alt="icon" class="w-100" /></div>
                                            <div class="media-body">100% Claims Support</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tab-content">{listContent}</div>
                            </div>
                        </div>
                        <div className="productTabsMainRisk" id="mainTab">
                            {listTitle}
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
export default Tab;
