import React from "react";
const Expertise = (props) => {
    return (
        <section class="space bg-image">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 expertise-image">
                        <div class="expertise-image">
                            <img src={require("../assets/img/KareIndia/banner2.png")} />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="insurance-title_wrap insurance-title_wrap2">
                            <div class="section-heading-2 section-heading-2-p">
                                <h4>FAQs</h4>
                                <div class="news_block">
                                    <h5>Why use an insurance broker?</h5><p><span>An insurance broker acts as&nbsp;</span> your representative<span>, not that of the insurance company. Our job is to&nbsp;</span>look after your interests<span>and find the insurance policy best suited to your needs. When you deal directly with an insurer, or through their agent, you are generally only presented with one product and it may not be the best one for you. Brokers are&nbsp;</span>experts<span>in insurance and can arrange the appropiate cover for you from any of the Insurance Companies in India</span></p><h5> What does a broker do?</h5><p class="style23" align="justify">Broker's job is to look after your interests. We will:</p>
                                    <div align="justify">
                                        <ul>
                                            <li class="style23">Look at your current insurance and&nbsp;make recommendations on any changesor additions necessary.</li>
                                            <li class="style23">Identify a range of products&nbsp;suitable for your needs.</li>
                                            <li class="style23">Contact insurers with appropriate products and get the&nbsp;best deal for you.</li>
                                        </ul>
                                    </div>
                                    <p class="style23" align="justify">When selecting a product for you, we take into account:-</p>
                                    <div align="justify">
                                        <ul class="style23">
                                            <li>The&nbsp;quality and extentof the cover.</li>
                                            <li>The&nbsp;reputationof the insurer for paying claims properly.</li>
                                            <li>The&nbsp;service standardsof the insurer.</li>
                                            <li>Whether the premium represents&nbsp;good valuefor the cover given</li>
                                        </ul>
                                    </div>
                                    <p align="justify">&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
