import React from "react";
const Expertise = (props) => {
    return (
        <React.Fragment>
            <section class="callNowBoxMain">
                <div class="callNowBoxMain01">
                    <a href="#">
                        <span>
                            <i class="fa fa-phone"></i>
                            <br />
                                Call now <br />
                                    0120-4300180
                        </span>
                    </a>
                </div>
            </section>
        </React.Fragment>

    );
};
export default Expertise;
