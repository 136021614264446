import React from "react";
import Dedicated from "./Dedicated";
import HowWork from "./HowWork";
import WhyChooseUs from "./WhyChooseUs";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
import GetStarted from "./GetStarted";
import Accordian from "./FAQAccordian/Accordian";
import SecurityPriority from "./SecurityPriority";
import Testimonial from "../Testimonial";
const Services = (props) => {
    return (
        <React.Fragment>
            <Zoom>
                <HowWork/>
            </Zoom>
            <Zoom>
                <Dedicated />
			</Zoom>
			<Zoom>
				<WhyChooseUs />
			</Zoom>






            <section class="services-area pt-100 pb-70">
                <div class="container">
                    <div class="section-title">
                        <span>Knowledge of the market</span>
                        <h2>Only the best professional services</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-agriculture"></i>
                                </div>
                                <h3>Car Insurance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-loan-1"></i>
                                </div>
                                <h3>Bike Insurance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-loan-2"></i>
                                </div>
                                <h3>Health Insurance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-personal"></i>
                                </div>
                                <h3>Life Insurance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-scholarship"></i>
                                </div>
                                <h3>GCV</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="flaticon-loan-3"></i>
                                </div>
                                <h3>PCV</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                                <a href="services-details.html" class="learn-btn">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <GetStarted />

            <Accordian />
            <Testimonial/>
            <SecurityPriority />
        </React.Fragment>
    );
};
export default Services;
