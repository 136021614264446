import axios from 'axios';
import React from 'react';
import DummyCardData, { DummyCardDataForRFL } from './DummyCardData';
import LifeQuoteCard from "./LifeQuoteCard";
import LifeFormModel from "./LifeFormModel";
import ShareButton from '../Car/ShareButton';
import SideModel from './SideModel';
import ProgressBarBox from '../components/ProgressBarBox';
class LifeQuote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Req: {},
            Res: [],
            APIUrl: null,
            CRMAPI: null,
            IsLoader: true,
            AnnualIncomeList: [],
            CoverDurationList: [],
            LifeFormModelOpen: false,
            ProceedReq: {},
            IsLifeEdit: false,
            Gender: null,
            Name: null,
            DOB: null,
            SmokeStatus: null,
            AnnualIncome: null,
            InsurerAge: null,
            ViewOption: "1",
        }

        this.state.Req = this.props.location.state.Req;
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm");
        this.ManageReq = this.ManageReq.bind(this);
        this.AnnualIncomeListMaker = this.AnnualIncomeListMaker.bind(this);
        this.BuyNow = this.BuyNow.bind(this);
        this.handle = this.handle.bind(this);
        this.updateResult = this.updateResult.bind(this);
        console.log(JSON.stringify(this.props.location.state.Req))
        let quote = window.sessionStorage.getItem("LifeQuoteResponse");
        let rq = window.sessionStorage.getItem("LifeQuoteReq");
        // if(rq!==null){
        //     this.state.Req=JSON.parse(rq)
        // }
        //  if(quote===null)
        this.GetQuote()
        // else {
        //     this.state.Res=JSON.parse(quote);
        //     this.state.IsLoader=false;
        //     this.setState({Res:JSON.parse(quote),IsLoader:false})
        // }
        this.AnnualIncomeListMaker()
    }
    componentDidMount() {
        this.setState({
            ViewOption: window.localStorage.getItem("ViewOption"),
            Gender: this.state.Req.Gender,
            Name: this.state.Req.FullName,
            SmokeStatus: this.state.Req.SmokeStatus,
            AnnualIncome: this.state.Req.AnnualIncome,
            DOB: this.state.Req.DOB,
            InsurerAge: this.state.Req.InsurerAge
        })
    }
    AnnualIncomeListMaker() {
        let AnnualIncome = [];
        for (let i = 2; i <= 99; i++) {
            AnnualIncome.push(i + "00000")
        }
        this.state.AnnualIncomeList = AnnualIncome
        this.setState({ AnnualIncomeList: AnnualIncome })
        this.CoverDurationListMaker()
    }
    CoverDurationListMaker() {
        let duration = [];
        for (let i = 5; i <= 85; i++) {
            duration.push(i)
        }
        this.state.CoverDurationList = duration;
    }

    GetQuote() {
        this.setState({ IsLoader: true });
        let Model = {
            URL: this.state.APIUrl + "/lifeapi/api/InsurerTerm/GetALLTermLifeV2",
            PostString: JSON.stringify(this.state.Req)
        }
        axios.post("/bpi/WeatherForecast/CallCheck", Model)
            .then((Res) => {
                console.log(JSON.stringify(this.state.Req))
                Res.data = Res.data.filter(res => res.NetPremium > 0)
                this.setState({ Res: Res.data, IsLoader: false });
                console.log(Res.data)
                window.sessionStorage.setItem("LifeQuoteResponse", JSON.stringify(Res.data));
                window.sessionStorage.setItem("LifeQuoteReq", JSON.stringify(this.state.Req))
            })
            .catch((err) => {
                console.error(err);
            })
    }
    BuyNow(buyObj) {
        let Req = {
            EnquiryNo: this.state.Req.EnquiryNo,
            LogoURL: buyObj.LogoUrlLink,
            FullName: this.state.Req.FullName,
            AgentCode: "",
            AgentSource: "",
            Email: null,
            FatherName: null,
            MotherName: null,
            Mobile: this.state.Req.Mobile,
            DOB: this.state.Req.DOB,
            City: null,
            State: null,
            Gender: this.state.Req.Gender,
            PlanName: buyObj.PlanName,
            SumIssured: this.state.Req.SumInsured,
            ProductOption: null,
            ProductCode: null,
            QuoteID: buyObj.QuoteID,
            Pincode: null,
            TotalPremium: buyObj.TotalPremium,
            AnnualPremium: buyObj.AnnualPremium,
            SumInsured: buyObj.SumInsured,
            PolicyTerm: buyObj.PolicyTerm,
            PremiumFrequency: buyObj.PremiumFrequency,
            AnnualIncome: this.state.Req.AnnualIncome,
            SmokeStatus: this.state.Req.SmokeStatus,
            InsurerAge: this.state.Req.InsurerAge,
            NetPremium: buyObj.NetPremium,
            QuotationNumber: buyObj.QuotationNumber,
            PlanCode: buyObj.PlanCode,
            PlanOption: buyObj.PlanOption,
            CompanyName: buyObj.CompanyName,
            ClientName: this.state.Req.ClientName,
            Environment: buyObj.Environment,
            OrderByPremium: buyObj.OrderByPremium,
            SampleUrlRediration: buyObj.SampleUrlRediration,
            PayoutOption: buyObj.PayoutOption,
            LumpsumPercentage: buyObj.LumpsumPercentage,
            Premiumpayoutoption: buyObj.Premiumpayoutoption,
            PayoutMonth: buyObj.PayoutMonth,
            PayTerm: buyObj.PayTerm,
            ErrorMessage: buyObj.ErrorMessage,
        }
        console.log(Req)
        if (buyObj.CompanyName === "IciciPruLife") {
            Req.SelectedData = buyObj;
            Req.PayoutOption = "Lump-Sum";
            Req.LumpsumPercentage = buyObj.LumpsumPercentage;
            this.props.history.push("/LifeProposal", { Req })
        } else {
            this.setState({ ProceedReq: Req, LifeFormModelOpen: !this.state.LifeFormModelOpen });
        }
    }
    handle(e) {
        if (e.target.type === "radio") {
            this.setState({ [e.target.name]: e.target.checked, [e.target.name]: e.target.value });
        }
        else this.setState({ [e.target.name]: e.target.value });

        switch (e.target.name) {
            case "ViewOption": window.localStorage.setItem("ViewOption", e.target.value);
                break;
        }
    }
    updateResult() {
        const date1 = new Date(this.state.DOB.replace("-", "/"));
        //const date2 = new Date();
        const diffTime = (Date.now() - date1.getTime());
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        //var ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(diffTime);

        let date = Math.abs(ageDate.getUTCFullYear() - 1970);
        this.state.Req.FullName = this.state.Name;
        this.state.Req.Gender = this.state.Gender;
        this.state.Req.SmokeStatus = this.state.SmokeStatus;
        this.state.Req.AnnualIncome = this.state.AnnualIncome;
        this.state.Req.InsurerAge = date;
        this.setState({ IsLifeEdit: false, InsurerAge: date });
        this.GetQuote();
    }
    ManageReq(e) {
        switch (e.target.id) {
            case "OrderBy":
                this.state.Req.OrderByPremium = e.target.value;
                break;
            case "CoverFilter":
                this.state.Req.SumInsured = e.target.value;
                break;
            case "CoverDuration":
                this.state.Req.PolicyTerm = e.target.value;
                break;
            case "SmokeStatus":
                this.state.Req.SmokeStatus = e.target.value;
                break;
            case "Frequency":
                this.state.Req.PremiumFrequency = e.target.value;
                break;
            case "PremiumOption":
                this.state.Req.Premiumpayoutoption = e.target.value;
                break;
            case "AnnualIncome":
                this.state.Req.AnnualIncome = e.target.value;
                break;
        }
        this.GetQuote()
    }
    scrollOverflow(e) {
        let id = document.getElementById("scrollable_y");
        switch (e.target.id) {
            case "scroll_right":
                id.scrollTo(0, 400);
                break;
            case "scroll_left":
                id.scrollTo(400, 0);
                break;

        }

    }
    mobileView(e) {
        let id;
        switch (e.target.id) {
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "block";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "block";
                break;
        }
    }
    hideFilter(e) {
        let id;
        switch (e.target.id) {
            case "hidefilter_1": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "hidefilter_2": id = document.getElementById("filter_2");
                id.style.display = "none";
                break;
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "none";
                break;
        }
    }

    render() {

        return (
            <React.Fragment>

                {this.state.LifeFormModelOpen ? <LifeFormModel EnquiryNo={this.state.Req.EnquiryNo} Req={this.state.ProceedReq} closeModel={() => this.setState({ LifeFormModelOpen: !this.state.LifeFormModelOpen })} />
                    : <div className="QuotePage">

                        {/*<ShareButton ShareFor="Health_Quote" SharedEmail={this.state.Req.Mobile} share={true} shareButton={false} Quotedata={this.state.Req} quoteRes={this.state.QuoteResponse} EnquiryNo={this.state.Req.EnquiryNo} />*/}
                        <div className="quotesDetails">
                            <ProgressBarBox Progress="60%" />
                            <ul className="mo_flt_main HealthFilter" id="mo_flt_ul">
                                <li id="opn_one"><span className="btn_filter" data-target="#filter_1" data-toggle="tab" aria-expanded="true" id="editDetails" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-edit" aria-hidden="true"></i> Edit Details</span></li>

                                <li id="opn_two"><span className="btn_filter" data-target="#filter_2" data-toggle="tab" aria-expanded="true" id="applyFilter" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-edit" aria-hidden="true"></i> Apply Filter</span></li>
                            </ul>
                            <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetailsRowHealth quotesDetailsRow003Grid m-0" : "row quotesDetailsRow quotesDetailsRowHealth m-0"} id="filter_1">
                                <button type="button" className="close btn-success" id="hidefilter_1" onClick={this.hideFilter}>×</button>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Basic Details: </p>
                                    <span><strong>{this.state.Req.FullName}</strong></span>
                                    <span><strong>{this.state.Req.Gender.match("M") ? "Male" : "Female"}</strong></span>
                                    <span><strong>{this.state.Req.InsurerAge} Year</strong></span>
                                    <span><strong>{this.state.Req.SmokeStatus.match("N") ? "Non-Smoker" : "Smoker"}</strong></span>
                                    <span><strong>{this.state.Req.AnnualIncome}</strong></span>
                                    <span onClick={() => { this.setState({ IsLifeEdit: true }) }} className="EditHealth"><i className="far fa-edit" aria-hidden="true"></i> Edit</span>

                                </div>
                            </div>

                            <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetails002Row quotesDetailsRowLife quotesDetails002RowGrid" : "row quotesDetailsRow quotesDetailsRowLife quotesDetails002Row m-0"} id="filter_2" onClick={() => this.setState({ BasePlanOpen: false })}>                                <button type="button" className="close btn-success" id="hidefilter_2" onClick={this.hideFilter}>×</button>
                                {/*<span className="buttonContainer rightScrollButton" id="scroll_left" onClick={this.scrollOverflow}> <i   className="fas fa-angle-right " ></i></span>
                    <span className="buttonContainer leftScrollButton" id="scroll_right" onClick={this.scrollOverflow}><i   className="fas fa-angle-left " ></i></span>
                        */}

                                <div className="quotesDetailsIn pl-0">
                                    <p className="quoteP">Sort By</p>
                                    <select onChange={this.ManageReq} id="OrderBy" defaultValue={this.state.Req.OrderByPremium}>
                                        <option value="0">Low to High</option>
                                        <option value="1">High to Low</option>
                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Income</p>
                                    <select onChange={this.ManageReq} id="AnnualIncome" value={this.state.Req.AnnualIncome}>
                                        {this.state.AnnualIncomeList.map((v, i) => <option value={v} key={i}>{i + 2} Lakhs</option>)
                                        }
                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Preferred Cover</p>
                                    <select onChange={this.ManageReq} id="CoverFilter" defaultValue={this.state.Req.SumInsured} >

                                        <option value="300000000">30 Crores</option>
                                        <option value="290000000">29 Crores</option>
                                        <option value="280000000">28 Crores</option>
                                        <option value="270000000">27 Crores</option>
                                        <option value="260000000">26 Crores</option>
                                        <option value="250000000">25 Crores</option>
                                        <option value="240000000">24 Crores</option>
                                        <option value="230000000">23 Crores</option>
                                        <option value="220000000">22 Crores</option>
                                        <option value="210000000">21 Crores</option>
                                        <option value="200000000">20 Crores</option>
                                        <option value="190000000">19 Crores</option>
                                        <option value="180000000">18 Crores</option>
                                        <option value="170000000">17 Crores</option>
                                        <option value="160000000">16 Crores</option>
                                        <option value="150000000">15 Crores</option>
                                        <option value="140000000">14 Crores</option>
                                        <option value="130000000">13 Crores</option>
                                        <option value="120000000">12 Crores</option>
                                        <option value="110000000">11 Crores</option>
                                        <option value="100000000">10 Crores</option>
                                        <option value="90000000">9 Crores</option>
                                        <option value="80000000">8 Crores</option>
                                        <option value="70000000">7 Crores</option>
                                        <option value="60000000">6 Crores</option>
                                        <option value="50000000">5 Crores</option>
                                        <option value="40000000">4 Crores</option>
                                        <option value="30000000">3 Crores</option>
                                        <option value="20000000">2 Crores</option>
                                        <option value="10000000">1 Crore</option>
                                        <option value="9000000">90 Lakhs</option>
                                        <option value="8000000">80 Lakhs</option>
                                        <option value="7000000">70 Lakhs</option>
                                        <option value="6000000">60 Lakhs</option>
                                        <option value="5000000">50 Lakhs</option>
                                        <option value="4000000">40 Lakhs</option>
                                        <option value="3000000">30 Lakhs</option>
                                        <option value="2500000">25 Lakhs</option>
                                        <option value="2000000">20 Lakhs</option>
                                        <option value="1000000">10 Lakhs</option>
                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Cover Duration</p>
                                    <select onChange={this.ManageReq} id="CoverDuration" defaultValue={this.state.Req.PolicyTerm}>
                                        {this.state.CoverDurationList.map((v) => <option value={v} key={v}>{v} Year</option>)}

                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Smoke Status</p>
                                    <select onChange={this.ManageReq} id="SmokeStatus" defaultValue={this.state.Req.SmokeStatus} >
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Frequency</p>
                                    <select onChange={this.ManageReq} id="Frequency" defaultValue={this.state.Req.PremiumFrequency} >
                                        <option value="Y">Yearly</option>
                                        <option value="M">Monthly</option>
                                    </select>
                                </div>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Premium Option</p>
                                    <select onChange={this.ManageReq} id="PremiumOption" defaultValue={this.state.Req.Premiumpayoutoption}>
                                        <option value="Regular Pay">Regular Pay</option>
                                        <option value="Limited Pay">Limited Pay</option>
                                    </select>
                                </div>
                                {/*<div className="LifeQuoteFilter">
                            <a><i class="fas fa-pencil-alt"></i> Edit</a>
                            </div>*/}
                            </div>
                        </div>
                        <div className={this.state.ViewOption === "2" ? "quotesDetails quotesDetailsGrid" : "quotesDetails"} id="carResult" onClick={() => this.setState({ BasePlanOpen: false })}>
                            <div className="ExclusiveGSTRow">
                                <h3>
                                    {!this.state.IsLoader ? <span>{this.state.Res.length}</span> :
                                        <i class="fas fa-spinner fa-spin"></i>} Plans Found</h3>

                                <div className="row">
                                    <div className="col-6">
                                        <p>Prices Exclusive of GST</p>
                                    </div>

                                    <div className="PTC_BoxMarTop PTC_BoxMarTopHealth">
                                        <div className="PolicyTypeCondition PolicyTypeConditionHealth">
                                            <div className="PTC_Box">
                                                <i class="fas fa-exclamation-triangle"></i>
                                            </div>
                                            <div className="PTC_Box">
                                                <p>As the pandemic <b>Covid expenses</b> are covered in <b>all plans</b> during Hospitalization</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 text-right">
                                        <div className="ncbBox EnuquryNumber">
                                            <p>Lead ID :<span className="ng-binding"> {this.state.Req.EnquiryNo}</span></p>
                                            <select name="ViewOption" onChange={this.handle} value={this.state.ViewOption} className="form-control ViewQuotes w-auto">
                                                <option value="1">Grid View</option>
                                                <option value="2">List View</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.ViewOption === "2" ? "row quotesDetailsGridRow" : "row"}>
                                {this.state.IsLoader ? this.state.Req.ClientID == 50 ? DummyCardDataForRFL.map((row) => {
                                    return <LifeQuoteCard claimRatio={row.claimRatio}
                                        logo={row.logo} coverValue={row.coverValue}
                                        planName={row.planName} IsLoader={this.state.IsLoader}
                                    />
                                }) : DummyCardData.map((row) => {
                                    return <LifeQuoteCard claimRatio={row.claimRatio}
                                        logo={row.logo} coverValue={row.coverValue}
                                        planName={row.planName} IsLoader={this.state.IsLoader}
                                    />
                                }) :
                                    this.state.Res.map((row) => {
                                        return <LifeQuoteCard claimRatio={row.ClaimSettlementRatio}
                                            logo={row.LogoUrlLink} coverValue={row.SumInsured} lifePremium={row.NetPremium}
                                            brochur={row.Brochure} planName={row.PlanName} BuyNow={() => this.BuyNow(row)}
                                        />
                                    })}
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.IsLifeEdit && <SideModel close={() => this.setState({ IsLifeEdit: false })} SmokeStatus={this.state.SmokeStatus} Gender={this.state.Gender} AnnualIncome={this.state.AnnualIncome} Name={this.state.Name} DOB={this.state.DOB} updateResult={this.updateResult} handle={this.handle} />
                }

            </React.Fragment>
        );
    }
}


export default LifeQuote;