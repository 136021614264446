/* eslint-disable */ 
import React, { Component } from "react";
import { MasterGlobal } from "../App";
import {
 
  NavbarBrand,
 
} from "reactstrap";
import { Link } from "react-router-dom";
import "./login.css";
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      Logo: null,
      UserID: null,
      Origin: null,
      BtnValue: "Next",
      Password: null,
    };
    //var obj = new MasterGlobal()
    //this.state.URL = obj.CompanyAPI();
    //this.CheckAuthentication();
    //this.loadLogo(this.state.URL);
    this.CallAuth();
  }
  CallAuth() {
    let Api = MasterGlobal.CRMAPI;
    if (Api !== "") {
      this.state.URL = MasterGlobal.CRMAPI;
      this.setState({
        URL: MasterGlobal.CRMAPI,
      });
      this.CheckAuthentication();
    } else {
      let model = {
        CurrentUrl: window.location.hostname,
      };
      fetch("/api/WeatherForecast/GetAPIUrls", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(model),
      })
        .then((response) => response.json())
        .then((data) => {
          this.state.URL = data.crmapi;
          this.setState({
            URL: data.crmapi,
          });
          this.CheckAuthentication();
        });
    }
  }
  CheckAuthentication() {
    let Token = window.localStorage.getItem("token");
    if (Token != null) {
      Token = JSON.parse(Token);
      var body = {
        Token: Token.token,
      };
      fetch(this.state.URL + "/api/Account/TokeExist", {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
        .then((Response) => Response.text())
        .then((data) => {
          if (data == "Success") {
            MasterGlobal.btn = "LogOut";
            this.props.history.push("/Home");
          } else {
            window.localStorage.removeItem("token");
          }
        });
    }
  }
  loadLogo(ss) {
    let splitUrl = ss.split(".")[1];
    this.setState({ Origin: splitUrl });
    var Body = {
      URL: splitUrl, // == undefined ? "smcinsurance" : splitUrl
    };
    fetch(this.state.URL + "/api/Account/DomainLogo", {
      method: "POST",
      body: JSON.stringify(Body),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Logo: data.companyLogo,
        });
      });
  }
  handle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  Login = (e) => {
    let splitUrl = window.location.hostname.split(".")[1]; //"smc"//
    this.setState({ Origin: splitUrl });

    var Body = {
      UserID: this.state.UserID,
      Password: this.state.Password,
      ClientURL: this.state.Origin,
    };
    if (this.state.BtnValue == "Next") {
      fetch(this.state.URL + "/api/Account/CheckExistance", {
        method: "POST",
        body: JSON.stringify(Body),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((data) => {
          if (data == "Exist") {
            this.setState({
              BtnValue: "Login",
            });
          }
        });
    } else if (this.state.BtnValue == "Login") {
      fetch(this.state.URL + "/api/Account/LoginUser", {
        method: "POST",
        body: JSON.stringify(Body),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "Success") {
            MasterGlobal.btn = "LogOut";
            window.localStorage.setItem("token", JSON.stringify(data));
            this.props.history.push("/Home");
          }
        });
    }
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="packagesMainLogin proposalInfoMain">
          <div className="LoginInfo">
            <div className="ProductFormIn">
              <NavbarBrand tag={Link} to="/Home" className="SkipBtn">
                Skip
              </NavbarBrand>
              {/*<div className="Logo">
                                <img src={this.state.Logo} />
                            </div>*/}
              <label>
                <input
                  type="text"
                  className="packageForm"
                  placeholder="Enter mobile/email"
                  name="UserID"
                  value={this.state.UserID}
                  onChange={this.handle}
                />
              </label>
              {this.state.BtnValue != "Next" ? (
                <label>
                  <input
                    type="password"
                    className="packageForm"
                    placeholder="Password"
                    name="Password"
                    value={this.state.Password}
                    onChange={this.handle}
                  />
                </label>
              ) : null}
              <label>
                <button className="QouteMNBtn" onClick={this.Login}>
                  {this.state.BtnValue}
                </button>
              </label>
            </div>
          </div>
        </div>
        {/*
                <div className="packagesMain proposalInfoMain">
                    <div className="LoginInfo">
                        <div className="ProductFormIn">
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter First Name" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter Last Name" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter Email ID" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter Mobile Number" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter Aadhaar Card Number" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter PAN Card Number" />
                            </label>
                            <label>
                                <input type="date" className="packageForm" placeholder="Enter Date of Birth" />
                            </label>
                            <label>
                                <input type="text" className="packageForm" placeholder="Enter Address" />
                            </label>

                            <label>
                                <button className="QouteMNBtn" ng-click="GetQuotes()">Register</button>
                            </label>

                            <p className="LoginInfoCNT">Already have an account? <br /><a href="#">Login</a></p>
                        </div>
                    </div>
                </div>
                */}
      </div>
    );
  }
}
