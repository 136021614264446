import React from "react";
const Product = [
    {
        id: 1,
        name: "Life Insurance",
        to: "/Life",
        className: "productTabsRisk",
        src: require("./assets/img/Sarsa/family-care.png"),
    },
    {
        id: 2,
        name: "Home Insurance",
        to: "/LifeDemo",
        className: "productTabsRisk",
        src: require("./assets/img/Sarsa/house.png"),
    },
    {
        id: 3,
        name: "Bike Insurance",
        className: "productTabsRisk",
        to: "/Bike",
        src: require("./assets/img/Sarsa/bike.png"),
    },
    {
        id: 4,
        name: "Car Insurance",
        className: "productTabsRisk--active",
        to: "/Car",
        src: require("./assets/img/Sarsa/car.png"),
    },
    {
        id: 5,
        name: "Transit Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/delivery-truck.png"),
    },
    {
        id: 6,
        name: "Shopkeeper's Policy",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/gift.png"),
    },
    {
        id: 7,
        name: "Health Insurance",
        className: "productTabsRisk",
        to: "/Health",
        src: require("./assets/img/Sarsa/Health.png"),
    },
    {
        id: 8,
        name: "Professional indemnity",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/engineer.png"),
    },
    {
        id: 9,
        name: "Workmen Compensation",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/worker-i.png"),
    },
    {
        id: 10,
        name: "Personal Accident",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/accident.png"),
    },
    {
        id: 11,
        name: "Fire Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/home-insurance.png"),
    },
    {
        id: 12,
        name: "Liability Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/line-graph.png"),
    },
    {
        id: 13,
        name: "Travel Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/travel-protection.png"),
    },
    {
        id: 14,
        name: "GCV Trucks/Goods",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/truck.png"),
    },
    {
        id: 15,
        name: "PCV Buses/Taxies",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Sarsa/bus-side-view.png"),
    },
];
export default Product;
