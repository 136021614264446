import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { type } from "jquery";

export default function OurPartner() {
    let settings = {
        slidesToShow: 6,

        autoplay: true,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section class="section clients-partners-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <h5 class="font-regular">Amaze Clients</h5>
                    </div>
                    <div class="col-md-9" >
                        <Slider {...settings} className="PartnerLogoSlider">
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/admin-staff.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/cyient.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/ezresolve.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/greenpark.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/hmwssb.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/hyundai.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/india-immunologicals.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/jodas.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/lanco.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/pyro.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/rane.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/rithwik.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/surana.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/tatras.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/ysr.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/clients/zr-renewable.png")} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3">
                        <h5 class="font-regular">Partners</h5>
                    </div>
                    <div class="col-md-9">
                        <Slider {...settings} className="PartnerLogoSlider">
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/aegonlife.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/bajaj.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/digit.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/edelweiss.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/futura.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/hdfcergo.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/icici.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/iffco-tokio.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/indiafirst.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/kotak.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/raheja.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/shriram.png")} alt="" />
                            </div>
                            <div className="PartnerLogoBox">
                                <img src={require("./assets/img/BimaStreet/partners/tataaig.png")} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}