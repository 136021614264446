/* eslint-disable */ 
import React from "react";
import { Carousel } from "3d-react-carousal";
import Slides from "./Slides";
import TestimonialData from "./TestimonialData";
const Testimonial = (props) => {
  let slides = TestimonialData.map((data, key) => {
    return <Slides name={data.name} quotes={data.quotes} />;
  });

  return (
    <React.Fragment>
      <div className="container Testimonial">
        <div className="row text-center">
          <div className="col-sm-12 m-5">
            <h2>Customer Testimonials</h2>
          </div>
        </div>
        <Carousel slides={slides} autoplay={false} interval={2000} />
      </div>
    </React.Fragment>
  );
};
export default Testimonial;
