import React from "react"
import "react-reveal";
import ButtonClass from "../../ButtonClass";
const RequestForm = (props) => {
    return (
        <div class="ProductPageSectionThirdBox">
            <h3 class="ProductPageSectionThirdTitle">What is your interest? Request to call back.</h3>
            <div class="form-group">
                <label class="">
                    <select id="Interested" name="EnquiryTypeList" class="FormTagsBox">
                        <option value="">Interested In</option>
                        <option value="Car Insurance">Car Insurance</option>
                        <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                        <option value="Health Insurance">Health Insurance</option>
                        <option value="Travel Insurance">Travel Insurance</option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <span id="VldInterested" class="errMsgAll"></span>
                </label>
            </div>
            <div class="form-group">
                <label class="">
                    <input id="mobile" type="text" class="FormTagsBox" onkeypress="return isNumber(event)" placeholder="Mobile Number" maxlength="10" />
                    <span id="Vldmobile" class="errMsgAll"></span>
                </label>
            </div>
            <div class="form-group">
                <label class="">
                    <select class="FormTagsBox" id="prefertime">
                        <option value="Preferred time">Preferred time</option>
                        <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                        <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                        <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                        <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <span id="Vldprefertime" class="errMsgAll"></span>
                </label>
            </div>
            <input type="button" className={ButtonClass()} value="Send Request" />
        </div>
        );
};
export default RequestForm;
