import React from "react";
import { Redirect, Route } from "react-router-dom";
import MaskedInput from 'react-text-mask'

class Claims extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\s\-0-9]+\.)+[a-zA-Z\s]{2,}))$/
        );
        let field = this.state.fields
        if (field["claim"] === null || field["claim"] === undefined || field["claim"] === "") {
            errors["claim"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["message"] === null || field["message"] === undefined || field["message"] === "") {
            errors["message"] = "Please Enter Message"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
        }
        if (field["name"] === null || field["name"] === undefined || field["name"] === "") {
            errors["name"] = "Enter Your Name"
            isValid = true;
        } else if (field["name"].length < 4) {

            isValid = false;
            errors["name"] = "Please Enter Full Name"

        }
        if (
            field["email"] === null ||
            field["email"] === undefined ||
            field["email"] === ""
        ) {
            errors["email"] = "Please Enter Your Email";
            isValid = false;
        } else if (
            field["email"] !== null ||
            field["email"] !== undefined ||
            field["email"] !== ""
        ) {
            if (field["email"].length < 10) {
                errors["email"] = "Please Enter Your Email";
                isValid = false;
            }
        } else if (!pattern.test(field["email"])) {
            isValid = false;
            errors["email"] = "Please Enter Correct Email";
        }
        this.setState({ errors, isValid });



    }
    render() {

        return (
            this.state.isValid ? <Redirect to="/ThankYou" /> :
                <div className="claims">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="frm-center">
                                    <h5 className=" ">
                                        SUBMIT CLAIM REQUEST &nbsp;
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                    </h5>

                                    <small className="cl_Title_Sub p-4">We hope every thing is fine.</small>

                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="frm">
                                    <div className="">
                                        <span style={{ color: "black" }}>What is this claim for</span>
                                        <select id="subject-input" name="claim" className="form-control" onChange={this.handle}>
                                            <option value="0">Select Your Insurance Type</option>
                                            <option value="Car Insurance">Car Insurance</option>
                                            <option value="Health Insurance">Health Insurance</option>
                                            <option value="Two Wheeler Insurance">
                                                Two Wheeler Insurance
                  </option>
                                        </select>
                                        <label className="txtError">{this.state.errors["claim"]}</label>
                                    </div>
                                    <div className="">
                                        <span style={{ color: "black" }}>Tell us what happened</span>
                                        <textarea
                                            name="message"
                                            id="message-input"
                                            onChange={this.handle}
                                            value={this.state.fields["message"]}
                                            className="form-control"
                                            placeholder="Please enter whatever details you can provide for us to get started immediately"
                                        ></textarea>
                                        <label className="txtError">{this.state.errors["message"]}</label>
                                    </div>
                                    <div className="">



                                        <div className="mobile_no_container">
                                            <span style={{ color: "black" }}>Please provide your mobile number on which we can call you</span>
                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                            <label className="txtError">{this.state.errors["MobileNo"]}</label>
                                        </div>
                                    </div>
                                    <div className="">

                                        <div className="mobile_no_container pb-3">
                                            <span style={{ color: "black" }}> Your Name</span>
                                            <MaskedInput onChange={this.handle} className="form-control" name="name" value={this.state.fields["name"]} placeholder="Enter Your Name " mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                            <span className="txtError">{this.state.errors["name"]}</span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <span style={{ color: "black" }}> Your Email</span>

                                        <div className="mobile_no_container">
                                            <input
                                                className="form-control"
                                                onChange={this.handle}
                                                value={this.state.fields["email"]}
                                                id="TxtEmail"
                                                name="email"
                                                placeholder="Enter Your Email Id"
                                                required="required"
                                                type="email"

                                            />
                                            <span className="txtError">{this.state.errors["email"]}</span>
                                        </div>
                                    </div>
                                    <div className=" text-right mt-3">
                                        <button
                                            type="button"
                                            id="Reg_Btn"
                                            className="cl_btn"
                                            onClick={this.handleSubmit}
                                            data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Please wait..."
                                        >
                                            Register Claim
                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    };
}
export default Claims;
