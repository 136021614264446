/* eslint-disable */
import React, { useEffect } from 'react';
import { ChildBtn } from '../ButtonClass';
const CompareBox = (props) => {

    return (
        <div className="compareBoxM">

            {props.Item.map((row, i) => <div className="col-md-3 ng-scope" key={i}>
                <div className="CompareItem" id="compareItem1">
                    <button type="button" aria-hidden="true" class="close" onClick={() => props.RemoveItem(row.Key)}><i class="fas fa-times"></i></button>
                    <img src={row.UrlLogo} alt="img" />
                    <span className="ng-binding">{row.PlanName} </span>
                </div>
            </div>)}
            <div className="col-md-3 pull-right">
                <div className="compareBtns">
                    <button className={ChildBtn() + " mb-1"} onClick={props.sideModel}>Compare</button>
                    <br />
                    <button className={ChildBtn() + " mb-1"} onClick={props.clear}>Clear</button>
                </div>
            </div>
        </div>
    )
}
export default CompareBox;