import React from "react";
import RegisterPOS from "../RegisterPOS/RegisterPOS";
const Epartner = (props) => {
    return (
        <div className="PosRegisterPage ClaimsPage">
            <div className="ClaimsPageSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="ClaimsPageCnt">
                                <h1>Register as POSP</h1>
                                <div class="row pr-5">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/chart.png")} /> <br /> <span class="counter">9.3</span><span>+ Cr</span>
                                            <p>Premium</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/coin.png")} /><br /><span class="counter">9634</span><span>+</span>
                                            <p>Lives Covered</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/theme.png")} /><br /> <span class="counter">13095</span><span>+</span>
                                            <p>Policies Sold</p>
                                        </div>
                                    </div>
                                </div>

                                <small>
                                    *Standard Terms and Conditions Apply.<br />

                                    **Tax benefits are subject to changes in Income Tax Act.
                                </small>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <RegisterPOS />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ClaimsPageSec02 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="ClaimsPageSecCNTBox">
                                <p>In 2005, the Insurance Regulatory and Development Authority of India (IRDAI) introduced a New Insurance Products distribution channel called Point of Sale (PoS) Person, intending to increase penetration in the insurance business.</p>
                            </div>
                            <div className="ClaimsPageSecCNTBox">
                                <h2>What is a POSP?</h2>
                                <ul>
                                    <li>A Point of Sale Person (POSP) is an insurance agent who can sell insurance products across various companies and various categories – life plans, term plans, health plans, and more. He is appointed by Insurers or Corporate agents or Brokers. He usually sells over-the-counter (OTC) products which are pre-underwritten and authorized by IRDAI. The Product offered for sale are fixed value and fixed premium products with no option of change and thereby there is no need to explain the products to the clients at the time of the sale.  These products are designed in a way which one can understand easily and make decision to buy on the spot.</li>
                                </ul>
                                <br />
                                <h2>Why is POSP the best career to choose with Kare India?</h2>
                                <ul type="disc">
                                    <li>An insurance       Agent can sell the insurance Product of the Insurer with which he is       attached only.  A POSP attached to       an Insurance broker can sell policies of multiple insurance companies and       across both life and non-life categories.&nbsp;</li>
                                    <li>POSP can       issue the insurance policies straightway without underwriting.</li>
                                    <li>POSP are       provided with easy-to-use and efficient technology platforms to deliver       instant service to clients.&nbsp;</li>
                                    <li>POSP can       sell basic and transparent insurance plans which provide entire protection       and tax benefits.&nbsp;&nbsp;</li>
                                    <li>POSP can       gain more trust and improve customer relationships as they are locally       available for them.</li>
                                </ul>
                                <br />
                                <h2>Eligibility criteria to join as POSP?</h2>
                                <ul>
                                    <li>
                                        Minimum age - 18 years with class 10th pass  and an Aadhaar card. There is a 14 hour in house online training session followed by a simple exam as per IRDAI directives to become a POSP.
                                    </li>
                                </ul>
                                <br />
                                <h2>What are the products that can be sold by POSP?</h2>
                                <ul>
                                    <li>After becoming a POSP you can work as an independent Person associated with us and can sell numerous products as follows:</li>
                                    <ol type="disk">
                                        
                                            <li>Motor comprehensive insurance package policy for  two-wheeler, private cars, and commercial vehicles.</li>
                                            <li>Home insurance policy</li>
                                            <li>Travel insurance policy&nbsp;</li>
                                            <li>Personal accident policy</li>
                                            <li>Third party liability policy (for act) for two-wheeler,  private car, and commercial vehicles.&nbsp;</li>
                                            <li>All other policies approved by IRDAI</li>
                                       
                                    </ol>
                                </ul>
                                <br />
                                <h2>What are the challenges faced as a POSP?</h2>
                                <ul>
                                    <li>A POSP can market insurance products made available by the Regulator only and further they cannot offer claim consultancy, risk management services, and so on. They could not explain and sell complex insurance products.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Epartner;
