/* eslint-disable */
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { Redirect } from "react-router";
import { MasterGlobal } from "../App";
import ButtonClass from "../ButtonClass";
import DateFormatter from "./DateFormatter";
import DataLoader from "./Loader/DataLoader";
import LoaderOverlay from "./LoaderOverlay";
import OtpPopup from "./OtpPopup";
import ShareButton from "./ShareButton";
import AppComponentManager from "../components/AppComponentManager";
import TermsAndConditionLink from "../TermsAndConditionPage/TermsAndConditionLink";
import ThankInsp from "./ThankInsp";
import InspectionDec from "./InspectionDec";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js"
import CopyToClipboard from 'react-copy-to-clipboard';
import RedirectForm from "../components/RedirectForm";
import ProgressBarBox from "../components/ProgressBarBox";
export class ShowProposal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Model: [],
            Agree: false,
            IsMessage: false,
            MessageBody: null,
            APIUrl: null,
            shareOpen: false,
            askOtp: false,
            OTP: null,
            Loader: false,
            IsShared: false,
            receivedOTP: null,
            isLoaded: false,
            redirectToHome: false,
            dataFound: false,
            ShowTNC: false,
            IsInspection: false,
            IsInspConfirm: false,
            IsEdit: false,
            PaymentURL: null,
            htmlForm: null,
            GoToPayment: false,
            showProgress:true
        };
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.checkSharedURL = this.checkSharedURL.bind(this);
        this.Paynow = this.Paynow.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
        //this.state.CRMAPI=window.localStorage.getItem("crm")

        let url = new URLSearchParams(window.location.search);
        if (url.has("policyEnq")) {
            let enq = url.get("policyEnq");
            let fullpath = window.location.href.match("localhost") ? (this.state.APIUrl + window.location.pathname + "?policyEnq=" + enq) : window.location.href;
            fullpath = fullpath.match("#") ? fullpath.split("#")[0] : fullpath;
            this.checkSharedURL(fullpath);


        }
        else {
            this.state.isLoaded = true;
            this.state.Model = this.props.location.state.CompleteModel;
        }
        // this.setState({
        //   Model: this.state.Model,
        //   APIUrl: this.state.APIUrl,
        // });
        this.goBack = this.goBack.bind(this)
        this.otpConfirmation = this.otpConfirmation.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.checkSharedURL = this.checkSharedURL.bind(this);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        console.log(this.state.Model)
    }
    checkSharedURL = (enq) => {
        console.log(enq)
        let url = this.state.APIUrl;
        if (url.match("inshora")) {
            url = "https://inshoracover.com";
        }
        let Model = {
            URL: (url + "/api/api/InsurerMotor/GetShareData?url=") + enq
        }
        axios.post("/bpi/WeatherForecast/CallGet", Model)
            .then((Res) => {
                let data = Res.data;
                if (data != null) {
                    if (data.QuotePage !== null && data.quoteResponse !== null) {

                        let req;
                        let res;
                        if (data.QuotePage.match('IDVs":]')) {
                            data.QuotePage = data.QuotePage.replace('IDVs":]', 'IDVs":[]');
                        }
                        if (url.match("inshora")) {
                            req = this.htmlDecode(data.QuotePage.substring(1, data.QuotePage.length - 1));
                            res = this.htmlDecode(data.quoteResponse.substring(1, data.quoteResponse.length - 1));
                        } else {
                            req = data.QuotePage.substring(1, data.QuotePage.length - 1);
                            res = data.quoteResponse.substring(1, data.quoteResponse.length - 1);
                        }

                        window.sessionStorage.setItem("MotorproposalForm", req);
                        window.sessionStorage.setItem("ProposalResponse", res);

                        this.state.Model = JSON.parse(window.sessionStorage.getItem("MotorproposalForm"));
                        this.state.Model.ProposalResponse = JSON.parse(window.sessionStorage.getItem("ProposalResponse"));
                        this.state.IsShared = true;
                        this.state.isLoaded = true;
                        this.setState({ isLoaded: true });
                        this.Paynow(false);
                    }
                } else {
                    alert("No data found on this Enquiry Number")
                    this.setState({ dataFound: true });
                }
            })

    }
    htmlEncode(str) {

        return str.replace(/&/g, '&amp;')
            .replace(/"/g, "&quot;")
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')

    }
    htmlDecode(str) {
        return str.replace(/\\"/g, '\"')


    }
    componentDidMount() {
        if (this.state.dataFound) {
            this.setState({ redirectToHome: true })
        }
        this.setState({ Loader: false });
        let url = new URLSearchParams(window.location.search);
        if (url.get("policyEnq") === null) {
            let SaveReq = {
                shareButton: true,
                share: false,
                SharedEmail: this.state.Model.ProposalReq.Email,
                SaveReqData: true,
                EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                ShareFor: "Motor_Proposal",
                Quotedata: this.htmlEncode(window.sessionStorage.getItem("MotorproposalForm")),
                quoteRes: this.htmlEncode(window.sessionStorage.getItem("ProposalResponse")),
            }
            new ShareButton(SaveReq);
            this.Paynow(false);
        }

    }
    otpConfirmation(e) {


        switch (e.target.id) {
            case "otpClose":
                this.setState({ askOtp: false })
                break;
            case "payNow":
                let Model = {
                    URL: this.state.APIUrl + "/api/api/GEO/SendOTP?mobileno=" + this.state.Model.ProposalReq.Mobile + "&_UpdatedUri=" + this.state.APIUrl + "&enqno==" + this.state.Model.ProposalReq.EnquiryNo + "&roleid= "
                };
                axios.post("/bpi/WeatherForecast/CallGet", Model)
                    .then(Res => {
                        console.log(Res)
                        this.state.receivedOTP = Res.data;

                    })
                this.setState({ askOtp: true })
                break;
            case "confirmOTP":
                if (this.state.OTP === null) {
                    return alert("Please Enter OTP")
                }


                if (this.state.OTP == this.state.receivedOTP || this.state.OTP == "202101") {

                    if (this.state.Model.ProposalResponse.IsInspection && this.state.Model.ProposalResponse.IsAlreadyExpired && !this.state.Model.ProposalReq.ThirdPartyCover) {
                        this.setState({ IsInspection: true })
                    }
                    else {
                        this.setState({ Loader: true })
                        this.Paynow(true);
                    }
                } else alert("Please Input Correct OTP");
                break;
        }

    }
    handleChange(e) {

        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidUpdate() {
        if (document.getElementsByTagName("form").length > 0) {
            document.getElementsByTagName("form")[0].submit();
        }
    }
    Paynow(IsPayment) {
        let PaymentURL = null;
        let model = null;
        let returnUrl = this.state.APIUrl;
        if (returnUrl.match("inshora")) {
            returnUrl = "https://www.inshoracover.com";
            this.state.APIUrl = this.state.APIUrl.replace("https:", "http:");
        }

        switch (this.state.Model.buyObj.CompanyID) {
            case 102:
                model = {
                    applicationId: this.state.Model.ProposalResponse.ApplicationId,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    UserID: this.state.Model.ProposalResponse.CustomerID,
                    TP: this.state.Model.ProposalResponse.TotalPremium,
                };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/BajajPayment?TotalPremium=" + model.TP + "&UserId=" + model.UserID + "&TransactionID=" + model.applicationId + "&EnquiryNo=" + model.EnquiryNo +
                    "&IsShared=false" +
                    "&_url=" + returnUrl;

                break;
            case 124:
                model = {
                    applicationId: this.state.Model.ProposalResponse.ApplicationId,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo.toUpperCase(),
                    PolicyNo: this.state.Model.ProposalResponse.PolicyNumber,
                };

                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/GoDigitPayment?applicationId=" + model.applicationId + "&EnquiryNo=" + model.EnquiryNo + "&PolicyNo=" + model.PolicyNo + "&Uri=" + returnUrl;
                break;
            case 112:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    UserID: this.state.Model.ProposalResponse.ApplicationId,
                    ProposalNo: this.state.Model.ProposalResponse.ProposalNumber,
                };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/ReliancePayment?TotalPremium=" + model.TotalPremium + "&UserId=" + model.UserID + "&ProposalNo=" + model.ProposalNo + "&EnquiryNo=" + model.EnquiryNo + "&_uri=" + returnUrl;
                break;
            case 107:
                model = {
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                };
                if (returnUrl.match("riskovery")) {
                    returnUrl = returnUrl.replace("https://", "https://www.");
                } else if (returnUrl.match("onlinebimakaro")) {
                    returnUrl = returnUrl.replace("https://", "http://");
                }
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/IffcoPay?ApplicationId=" + model.ApplicationId + "&EnquiryNo=" + model.EnquiryNo + "&_Uri=" + returnUrl;
                break;
            case 103:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    CustomerID: this.state.Model.ProposalResponse.CustomerID,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    FirstName: this.state.Model.ProposalReq.FirstName,
                    LastName: this.state.Model.ProposalReq.LastName,
                    Email: this.state.Model.ProposalReq.Email,
                    AgentCode: this.state.Model.ProposalResponse.hdfcAgentCode,
                    ThirdPartyCover: this.state.Model.ProposalReq.ThirdPartyCover,
                    CompanyName: this.state.Model.ProposalReq.CompanyName,
                    Motor:
                        this.state.Model.ProposalReq.TypeofMotor == 0
                            ? "Two Wheeler"
                            : "Car",
                };
                //window.location.href = this.state.APIUrl + "/ProposalMotor/HDFCPayment?TotalPremium=" + model.TotalPremium + "&Type=Car&CustomerID=" + model.CustomerID + "&CustomerFirstName=" + model.FirstName + "&CustomerLastName=" + model.LastName + "&CustomerEmail=" + model.Email + "&AgentCode=" + model.AgentCode + "&ApplicationId=" + model.CustomerID + "&Thirdparty=" + model.ThirdPartyCover + "&EnquiryNo=" + model.EnquiryNo;
                if (model.CompanyName === "AHDFC") {
                    PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/AHDFCPayment?TotalPremium=" + model.TotalPremium + "&CustomerID=" + model.CustomerID + "&hdfcAgentCode=" + model.AgentCode + "&EnquiryNo=" + model.EnquiryNo + "&Uri=" + returnUrl;
                } else {
                    PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/HDFCPayment?TotalPremium=" + model.TotalPremium + "&Type=" +
                        model.Motor + "&CustomerID=" + model.CustomerID + "&CustomerFirstName=" + model.FirstName + "&CustomerLastName=" +
                        model.LastName + "&CustomerEmail=" + model.Email + "&AgentCode=" + model.AgentCode + "&ApplicationId=" + model.ApplicationId +
                        "&Thirdparty=" + model.ThirdPartyCover + "&EnquiryNo=" + model.EnquiryNo + "&Uri=" + returnUrl;
                }
                break;
            case 126:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    ProposalNumber: this.state.Model.ProposalResponse.ProposalNumber,
                    CustomerID: this.state.Model.ProposalResponse.CustomerID,
                    VehicleNo: this.state.Model.ProposalReq.VehicleNo,
                    Mobile: this.state.Model.ProposalReq.Mobile,
                };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/ICICIPayment?TotalPremium=" + model.TotalPremium + "&ApplicationId=" + model.ApplicationId +
                    "&EnquiryNo=" + model.EnquiryNo + "&ProposalNumber=" + model.ProposalNumber + "&CustomerID=" + model.CustomerID + "&VehicleNo=" + model.VehicleNo +
                    "&Mobile=" + model.Mobile + "&_uri=" + returnUrl;
                break;
            case 105: model = {
                TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                PolicyNumber: this.state.Model.ProposalResponse.PolicyNumber,
               
            };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/SompoPayment?TotalPremium=" + model.TotalPremium + "&ApplicationId=" + model.ApplicationId +
                    "&PosPolicyNo=" + model.PolicyNumber + "&EnquiryNo=" + model.EnquiryNo + "&_url=" + returnUrl;

                break;
            case 100:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    FirstName: this.state.Model.ProposalReq.FirstName,
                    LastName: this.state.Model.ProposalReq.LastName,
                    Email: this.state.Model.ProposalReq.Email,
                    CityName: this.state.Model.ProposalReq.CityName,
                    StateName: this.state.Model.ProposalReq.StateName,
                    PinCode: this.state.Model.ProposalReq.PinCode,
                    StateName: this.state.Model.ProposalReq.StateName,
                    StateName: this.state.Model.ProposalReq.StateName,
                    StateName: this.state.Model.ProposalReq.StateName,
                    StateName: this.state.Model.ProposalReq.StateName,
                    AddressLine1: this.state.Model.ProposalReq.AddressLine1,
                    AddressLine2: this.state.Model.ProposalReq.AddressLine2,
                    AddressLine3: this.state.Model.ProposalReq.AddressLine3,
                    QuoteNumber: this.state.Model.ProposalResponse.QuoteNumber,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                    CustomerID: this.state.Model.ProposalReq.CustomerID,
                };
                PaymentURL = this.state.APIUrl + "/ProposalMotor/LibertyPayment?TotalPremium=" + model.TotalPremium + "&FirstName=" + model.FirstName +
                    "&LastName=" + model.LastName + "&Email=" + model.Email + "&Mobile=" + model.Mobile + "&CityName=" + model.CityName +
                    "&StateName=" + model.StateName + "&PinCode=" + model.PinCode + "&AddressLine1=" + model.AddressLine1 + "&AddressLine2=" +
                    model.AddressLine2 +
                    "&AddressLine3=" +
                    model.AddressLine3 +
                    "&QuotationNumber=" +
                    model.QuoteNumber +
                    "&CustomerID=" +
                    model.CustomerID +
                    "&ApplicationId=" +
                    model.ApplicationId +
                    "&EnquiryNo=" +
                    model.EnquiryNo;
                break;
            case 108:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    PolicyNumber: this.state.Model.ProposalResponse.PolicyNumber,
                    FirstName: this.state.Model.ProposalReq.FirstName,
                    LastName: this.state.Model.ProposalReq.LastName,
                    Email: this.state.Model.ProposalReq.Email,
                    Mobile: this.state.Model.ProposalReq.Mobile,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                };
                PaymentURL =
                    this.state.APIUrl +
                    "/api/api/InsurerMotor/FuturePay?TotalPremium=" +
                    model.TotalPremium +
                    "&QuotationPolicyId=" +
                    model.PolicyNumber +
                    "&FirstName=" +
                    model.FirstName +
                    "&LastName=" +
                    model.LastName +
                    "&Email=" +
                    model.Email +
                    "&Mobile=" +
                    model.Mobile +
                    "&EnquiryNo=" +
                    model.EnquiryNo +
                    "&_uri=" + returnUrl;
                break;
            case 101:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    ProposalNumber: this.state.Model.ProposalResponse.ProposalNumber,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                };
                PaymentURL =
                    this.state.APIUrl +
                    "/api/api/InsurerMotor/TataPayment?TotalPremium=" +
                    model.TotalPremium +
                    "&ConsumerId=" +
                    model.ApplicationId +
                    "&ProposalNo=" +
                    model.ProposalNumber +
                    "&EnquiryNo=" +
                    model.EnquiryNo +
                    "&_UpdatedUri=" + returnUrl;
                break;
            case 118:
                model = {
                    TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                    ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                    PolicyNumber: this.state.Model.ProposalResponse.PolicyNumber,
                    EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                };
                PaymentURL = this.state.APIUrl +
                    "/api/api/InsurerMotor/GoSriramPayment?applicationId=" + model.ApplicationId +
                    "&EnquiryNo=" + model.EnquiryNo +
                    "&_Uri=" + returnUrl;
                break;
            case 113: model = {
                TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                PolicyNumber: this.state.Model.ProposalResponse.PolicyNumber,
                EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                FirstName: this.state.Model.ProposalReq.FirstName,
                LastName: this.state.Model.ProposalReq.LastName,
            };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/KotakPayment?TotalPremium=" +
                    model.TotalPremium +
                    "&FirstName=" +
                    model.FirstName +
                    "&LastName=" +
                    model.LastName +
                    "&Email=" +
                    model.Email +
                    "&Mobile=" +
                    model.Mobile +
                    "&ApplicationId=" + model.ApplicationId +
                    "&EnquiryNo=" +
                    model.EnquiryNo +
                    "&_url=" + returnUrl;
                break;
            case 110: model = {
                TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                ProposalNumber: this.state.Model.ProposalReq.ProposalNumber,
            };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/OICLPayment?Premium=" +
                    model.TotalPremium +
                    "&ApplicationID=" + model.ApplicationId +
                    "&EnquiryNo=" + model.EnquiryNo +
                    "&ProposalNumber=" + model.ProposalNumber +
                    "&_url=" + returnUrl;
                break;
            case 111: model = {
                TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                ApplicationId: this.state.Model.ProposalResponse.ApplicationId,
                EnquiryNo: this.state.Model.ProposalReq.EnquiryNo,
                FirstName: this.state.Model.ProposalReq.FirstName,
                Email: this.state.Model.ProposalReq.Email,
                Mobile: this.state.Model.ProposalReq.Mobile,
                QuoteNumber: this.state.Model.ProposalResponse.QuoteNumber,
                Motor:
                    this.state.Model.ProposalReq.TypeofMotor == 0
                        ? "Two Wheeler"
                        : "Car",
            };
                PaymentURL = this.state.APIUrl +
                    "/api/api/InsurerMotor/SundaramPayment?TotalPremium=" + model.TotalPremium +
                    "&FirstName=" + model.FirstName +
                    "&Email=" + model.Email +
                    "&Mobile=" + model.Mobile +
                    "&ApplicationId=" + model.ApplicationId +
                    "&EnquiryNo=" + model.EnquiryNo +
                    "&QuoteID=" + model.QuoteNumber +
                    "&MotorType=" + model.Motor +
                    "&_UpdatedUri=" + returnUrl;
                break;
            case 109: model = {
                TotalPremium: this.state.Model.ProposalResponse.TotalPremium,
                EnquiryNo: this.state.Model.QuoteReq.EnquiryNo,
                ApplicationId: this.state.Model.ProposalResponse.ApplicationId,


            };
                PaymentURL = this.state.APIUrl + "/api/api/InsurerMotor/BhartiPayment?TotalPremium=" + model.TotalPremium +
                    "&Channel=" + model.ApplicationId.split("~")[2] +
                    "&OrderNo=" + model.ApplicationId.split("~")[0] +
                    "&QuoteNo=" + model.ApplicationId.split("~")[1] +
                    "&EnquiryNo=" + model.EnquiryNo +
                    "&_UpdatedUri=" + returnUrl;
                break;
        }

        this.state.PaymentURL = PaymentURL;
        this.setState({ PaymentURL: this.state.PaymentURL })
        if (!IsPayment)
            return
        let PaymentModel = {
            URL: PaymentURL
        }
        console.log(PaymentURL)
       //window.location.href = PaymentURL;
         
        axios.post("/bpi/WeatherForecast/CallGet", PaymentModel)
            .then(Res => {
                console.log(Res.data);
                if (Res.data.match("body")) {
                    let htmlForm = Res.data.split("<body onload='document.forms[0].submit()'>")[1].replace("</body></html>", "");
                    this.setState({ htmlForm: htmlForm, GoToPayment: true });

                }
                else window.location.href = PaymentURL;
            }).catch(err => { window.location.href = PaymentURL; })
   }
    handle = (e) => {
        this.setState({
            [e.target.name]: !this.state.Agree,
        });
    };
    AlertMessage(messageBody) {
        this.setState({
            IsMessage: !this.state.IsMessage,
            MessageBody: messageBody,
        });
    }
    goBack() {
        if (this.state.IsShared) {
            this.setState({ IsEdit: true })
        } else
            this.props.history.goBack();
    }
    dobManage(data) {
        let element;
        let dd;
        let mm;
        let yy;
        if (data != undefined || data != null) {
            element = data.split("-");
            dd = element[2].substr(0, 2);
            mm = element[1];
            yy = element[0];
        }

        return dd + "-" + mm + "-" + yy;
    }

    async generatePDF() {
        
        let hF = document.getElementById("paymentURL");
        hF.style.display = "block";
        let hf1 = document.getElementById("hideInPDF");
        hf1.style.display = "none";
        let element = document.getElementById('CaptureProposal');
        element.style.marginTop="-80px"
        let backBtn = document.getElementById("backToProposalBtn");
        backBtn.style.display = "none";
        let opt = {
            margin: 0,
            filename: this.state.Model.ProposalReq.CompanyName + '_Summary.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 8, letterRendering: true, useCORS: true, dpi: 192 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'l', putOnlyUsedFonts: true },
            enableLinks: true,
            //pagebreak: { mode: 'avoid-all', before: '#part2break' }
        };
        try {
            await html2pdf().set(opt).from(element).save();
        } catch (err) { }
        hf1.style.display = "unset";
        hF.style.display = "none";
        backBtn.style.display = "";
        element.style.marginTop = "";
       
    }
    UrlF = (url) => {
        if (url === null || url === "")
            return url;
        if (url.match("http"))
            return url;
        return url.replace("//", "https://")
    }
    render() {
        if (!this.state.isLoaded) {
            return <DataLoader />
        }
        if (this.state.redirectToHome) {
            return <Redirect to="/" />
        }
        return (

            <div className="NewProposal">
                <ProgressBarBox Progress="90%" />
                {this.state.IsEdit &&
                    <Redirect to="/Proposal?IsEdit=true" />
                }
                <div id="paymentForm" dangerouslySetInnerHTML={{ __html: this.state.htmlForm }} ></div>
                <span className="text-right generatePDF" onClick={this.generatePDF}><i className="fas fa-file-pdf"></i>Generate PDF</span>
                <div className={(this.state.askOtp ? "hideIt " : "") + "container"} style={{ backgroundColor:"#E1EEF9" }} id="CaptureProposal" >
                    <div  className="proposalPageBox">
                        
                        <div className="row BackBTNRow" id="backToProposalBtn">
                            <div className="col-3 col-md-auto">
                                <a onClick={this.goBack} className="arw-btn">
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </div>
                            <div className="col-9 col-md-10 col-lg-11 align-self-center">
                                <h3 className="f-32 font-regular mb-0">Back to Proposal</h3>
                            </div>
                        </div>
                        <div className="row proposalBox03">
                            <div className="col-md-3 p-0">
                                <div className="proDetailsMM" >
                                    <div className="rowDetailsMM" style={{ paddingBottom:"10px" }}>

                                        <a style={{ display: "none", paddingTop: "-20px", paddingBottom: "20px" }} id="paymentURL" href={this.state.PaymentURL}>
                                            Go to Payment
                                        </a>
                                        <img src={this.UrlF(this.state.Model.buyObj.CompanyLogo)} id="companylogo" />
                                        <span id="manufacturevehicleName">{this.state.Model.CompModel.Vehicle}</span>
                                        <span id="manufacturevehiclevariant">{this.state.Model.CompModel.variant}</span><br />
                                        <p id="rtoCodewithLocation">

                                            {this.state.Model.CompModel.RTOName}
                                        </p>
                                        <p className="LeadIdHover enqueryNumber">
                                            <b>Enquiry#</b> <span id="EnquiryNo" className="ng-binding">{this.state.Model.ProposalReq.EnquiryNo}
                                                <CopyToClipboard text={this.state.Model.ProposalReq.EnquiryNo} >
                                                    <i className="fas fa-copy"></i>
                                                </CopyToClipboard>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM rowDetailsMM01" style={{ paddingBottom: "20px" }}>
                                        <p>Premium <span> <b>₹ {Math.round(this.state.Model.buyObj.NetPremium)}</b></span></p>
                                        <p>+ GST (18%)<span><b>₹ {Math.round(this.state.Model.buyObj.serviceTax)}</b></span></p>
                                        <p className="totalP">Total Premium
                                                <span><b>₹ {Math.round(this.state.Model.buyObj.TotalPremium)}</b></span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM">
                                        <p>
                                            IDV
                    <span><b>₹ </b> <b id="IDVData" className="ng-binding">{this.state.Model.buyObj.IDV}</b></span>
                                        </p>
                                        <p ng-show="!forrenew" className="ng-hide">
                                            Manufacture Date
                    <span id="RegistrationDate" className="ng-binding">{this.dobManage(this.state.Model.buyObj.ManufactureDate.slice(0, 10))}</span>
                                        </p>
                                        <p ng-show="forrenew">
                                            Registration Date
                    <span id="RegistrationDate" className="ng-binding">{this.dobManage(this.state.Model.QuoteReq.RegistrationYear)}</span>
                                        </p>
                                        <p ng-show="forrenew">
                                            Current NCB
                    <span id="currentNCB" className="ng-binding">{this.state.Model.buyObj.CurrentNCB}%</span>
                                        </p>
                                        <p>
                                            Policy Type
                    <span id="policyType" className="ng-binding">{this.state.Model.CompModel.NewOrRenew}</span>
                                        </p>

                                        <p>
                                            Policy Start Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.dobManage(this.state.Model.buyObj.PolicyStartDate.slice(0, 10))}</span>
                                        </p>
                                        <p>
                                            Policy Expiry Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.dobManage(this.state.Model.buyObj.PolicyEndDate.slice(0, 10))}</span>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="PS_Info">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="PS_Box">
                                                <h5 className="PS_Heading">Personal Details
                                                    <a onClick={this.goBack} className="backtoQuotes">
                                                        <i class="fas fa-edit"></i> Edit
                                                    </a>
                                                </h5>
                                                <label><p>Full Name :</p>
                                                    <span id="fname">
                                                        {this.state.Model.ProposalReq.FirstName} {this.state.Model.ProposalReq.LastName}
                                                    </span>
                                                </label>
                                                <label><p>Email :</p>
                                                    <span id="email">
                                                        {this.state.Model.ProposalReq.Email}
                                                    </span>
                                                </label>
                                                <label> <p>Mobile :</p>
                                                    <span id="mobile">
                                                        {this.state.Model.ProposalReq.Mobile}
                                                    </span>
                                                </label>

                                                {this.state.Model.ProposalReq.GST_Number !== "" &&
                                                    <label><p>GST Number :</p>
                                                        <span id="GST_NO" style={{ textTransform: "uppercase" }}>
                                                            {this.state.Model.ProposalReq.GST_Number}
                                                        </span>
                                                    </label>
                                                }
                                                <label> <p>Nominee :</p>
                                                    <span id="nomineename">
                                                        {this.state.Model.ProposalReq.NomineeName}
                                                    </span>
                                                </label>
                                                <label><p>DOB :</p>
                                                    <span id="dob">
                                                        {DateFormatter(this.state.Model.ProposalReq.DOB, "-", "/", "dd/mm/yy", "")}
                                                    </span>
                                                </label>

                                                <label><p>Address :</p>
                                                    <span id="addressLine1">
                                                        {this.state.Model.ProposalReq.AddressLine1},
                                                    {this.state.Model.ProposalReq.AddressLine2},
                                                    {this.state.Model.ProposalReq.AddressLine3}
                                                    </span>
                                                </label>

                                                <label> <p>City :</p>
                                                    <span id="city">
                                                        {this.state.Model.ProposalReq.CityName}
                                                    </span>
                                                </label>

                                                <label> <p>State :</p>
                                                    <span id="statename">
                                                        {this.state.Model.ProposalReq.StateName}
                                                    </span>
                                                </label>

                                                <label><p>PIN :</p>
                                                    <span id="pin">
                                                        {this.state.Model.ProposalReq.PinCode}
                                                    </span>
                                                </label>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="PS_Box">
                                                <h5 className="PS_Heading">Vehicle Details
                                                <a onClick={this.goBack} className="backtoQuotes">
                                                        <i class="fas fa-edit"></i> Edit
                                                    </a>
                                                </h5>
                                                <label><p>Vehicle :</p>
                                                    <span id="manufacturename">
                                                        {this.state.Model.CompModel.Vehicle}
                                                    </span>
                                                </label>
                                                <label><p>Variant :</p>
                                                    <span id="variant">
                                                        {this.state.Model.CompModel.variant}
                                                    </span>
                                                </label>
                                                <label><p>Fuel :</p>
                                                    <span id="fulename">
                                                        {this.state.Model.QuoteReq.FuelName}
                                                    </span>
                                                </label>
                                                <label><p>Registration# :</p>
                                                    <span>
                                                        {this.state.Model.ProposalReq.RTO_CODE}
                                                    </span>
                                                </label>
                                                <label><p>RTO Code :</p>
                                                    <span id="rtocode">
                                                        {this.state.Model.QuoteReq.RtoCode}
                                                    </span>
                                                </label>
                                                <label><p>Vehicle No:</p>
                                                    <span className="uppercase">
                                                        {this.state.Model.ProposalReq.VehicleNo}
                                                    </span>
                                                </label>


                                                <h5 className="PS_Heading">Registration Address Details</h5>
                                                <label><p>Address :</p>
                                                    <span id="peraddressLine1">
                                                        {this.state.Model.ProposalReq.AddressLine1}, {this.state.Model.ProposalReq.AddressLine2}, {this.state.Model.ProposalReq.AddressLine3}
                                                    </span>
                                                </label>
                                                <label><p>City :</p>
                                                    <span id="percity">
                                                        {this.state.Model.ProposalReq.CityName}
                                                    </span>
                                                </label>
                                                <label><p>State :</p>
                                                    <span id="perstatename">
                                                        {this.state.Model.ProposalReq.StateName}
                                                    </span>
                                                </label>
                                                <label><p>PIN :</p>
                                                    <span id="perpin">
                                                        {this.state.Model.ProposalReq.PinCode}
                                                    </span>
                                                </label>

                                            </div>
                                        </div>
                                        <div className="col-md-12" id="part2break">
                                            {/*                  <div className="PS_Box PS_Box_PD">*/}
                                            {/*                      <h5 className="PS_Heading">*/}
                                            {/*                          Addons*/}
                                            {/*<a onClick={this.goBack} className="backtoQuotes">*/}
                                            {/*                              <i class="fas fa-edit"></i> Edit*/}
                                            {/*</a>*/}
                                            {/*                      </h5>*/}
                                            {/*                      {this.state.Model.ProposalReq.chkPaidDriver ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Paid Driver</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkConsumables ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Consumables</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkDepreciation ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Depreciation</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkEngineProtector ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Engine Protector</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq*/}
                                            {/*                          .chkInconvenienceAllowance === "true" ? (*/}
                                            {/*                              <label><p>Inconvenience Allowancer</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkKeyReplacement ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Key Replacement</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkNCBProtection ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>NCB Protection</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkPassangerAssistance ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Passanger Assistance</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkPassengerCover ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Passenger Cover</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkReturnToInvoice ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Return To Invoice</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                      {this.state.Model.ProposalReq.chkRoadSideAssistance ===*/}
                                            {/*                          "true" ? (*/}
                                            {/*                              <label><p>Roadside Assistance</p> <span>₹150 <img src={require("../Contents/img/WrongCross.png")} /></span></label>*/}
                                            {/*                          ) : null}*/}
                                            {/*                  </div>*/}
                                            <div className="PS_Box PS_Box_PD">
                                                <h5 className="PS_Heading">Policy Details
                                                <a onClick={this.goBack} className="backtoQuotes">
                                                        <i class="fas fa-edit"></i> Edit
                                                    </a>
                                                </h5>
                                                <label><p>Policy For :</p>
                                                    <span id="typeofmotor">
                                                        {this.state.Model.QuoteReq.Type == 1 ? "Car-" : "2 Wheeler-"}
                                                        {this.state.Model.QuoteReq.odOnly == false &&
                                                            this.state.Model.QuoteReq.ThirdPartyCover == false ? " Comprehensive" :
                                                            this.state.Model.QuoteReq.ThirdPartyCover == true &&
                                                                this.state.Model.QuoteReq.odOnly == false ? " Third Party" : " OD Only"}
                                                    </span>
                                                </label>
                                                <label><p>Policy Type :</p>
                                                    <span id="policytype">
                                                        {this.state.Model.CompModel.NewOrRenew}
                                                    </span>
                                                </label>
                                                <label><p>Bsic OD :</p>
                                                    <span>₹ {Math.round(this.state.Model.buyObj.BasicOD)}</span>
                                                </label>
                                                <label><p>Basic TP :</p>
                                                    <span>₹ {Math.round(this.state.Model.buyObj.BasicTP)}</span>
                                                </label>
                                                <label><p>Premium :</p>
                                                    <span>₹ {Math.round(this.state.Model.buyObj.TotalPremium)}</span>
                                                </label>
                                                <label><p>GST :</p>
                                                    <span>₹ {Math.round(this.state.Model.ProposalResponse.ServiceTax)}
                                                    </span>
                                                </label>
                                                <label><p>NCB Discount :</p>
                                                    <span>₹ {Math.round(Math.abs(this.state.Model.buyObj.NCBAmount))}
                                                    </span>
                                                </label>
                                                <label><p>OD Discount :</p>
                                                    <span>₹ {Math.round(Math.abs(this.state.Model.buyObj.ODDiscount))}
                                                    </span>
                                                </label>
                                                <label><p>Chassis# :</p>
                                                    <span id="chasisno">
                                                        {this.state.Model.ProposalReq.ChassisNo}
                                                    </span>
                                                </label>
                                                <label><p>Engine# :</p>
                                                    <span id="engineno">
                                                        {this.state.Model.ProposalReq.EngineNo}
                                                    </span>
                                                </label>
                                                <label><p>Policy Expiry Date :</p>
                                                    <span id="policyenddate">
                                                        {this.dobManage(this.state.Model.buyObj.PolicyEndDate)}
                                                    </span>
                                                </label>
                                                <label><p>IDV :</p>
                                                    <span>₹ {Math.round(this.state.Model.buyObj.IDV)}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" id="hideInPDF">
                                        <div className="col-md-12">
                                            <div className="">
                                                <label className="d-flex align-items-center w-100">
                                                    <input
                                                        type="checkbox"
                                                        onChange={this.handle}
                                                        name="Agree"
                                                        checked={this.state.Agree}
                                                    />&nbsp;
              Agree on the <b><span className="p-0 d-inline" onClick={() => this.setState({ ShowTNC: !this.state.ShowTNC })} > Terms &amp; Conditions</span></b>
                                                </label>
                                                <label className="ammountTTL">
                                                    <strong>
                                                        Amount to be paid : ₹{" "}
                                                        <span id="main">
                                                            {Math.round(this.state.Model.buyObj.TotalPremium)}
                                                        </span>
                                                    </strong>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-12 text-right">
                                            <div className="d-inline-block w-100">
                                                <label class="float-left">
                                                    <input type="button" id="ResultId" onClick={() => this.setState({ shareOpen: true })} value="Share this Proposal" className="ShareProBtn" />
                                                </label>
                                                <label class="float-right">
                                                    <input
                                                        type="button"
                                                        value="Pay Now"
                                                        disabled={!this.state.Agree ? "disabled" : ""}
                                                        className={ButtonClass()}
                                                        onClick={this.otpConfirmation}
                                                        id="payNow"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(!this.state.APIUrl.match("hero")) && this.state.shareOpen && <ShareButton type={this.state.Model.QuoteReq.Type} SaveReqData={false} ShareFor="Motor_Proposal" SharedEmail={this.state.Model.ProposalReq.Mobile} share={false} shareButton={true} Quotedata={this.htmlEncode(window.sessionStorage.getItem("MotorproposalForm"))} quoteRes={this.htmlEncode(window.sessionStorage.getItem("ProposalResponse"))} EnquiryNo={this.state.Model.ProposalReq.EnquiryNo} />}
                {this.state.askOtp &&
                    <OtpPopup handle={this.handleChange} submit={this.otpConfirmation} close={() => this.setState({ askOtp: false })} />
                }{this.state.Loader &&
                    <LoaderOverlay />
                }
                <div className="TermsAndConditionPage">
                    {this.state.ShowTNC && TermsAndConditionLink()!==null && < AppComponentManager close={() => this.setState({ ShowTNC: !this.state.ShowTNC })} >
                        <TermsAndConditionLink />
                    </AppComponentManager>}
                </div>
                {this.state.IsInspection && <InspectionDec confInsp={() => this.setState({ IsInspConfirm: true })} close={() => this.setState({ IsInspection: false })} />}
                {this.state.IsInspConfirm && <Redirect to={"/InspectionConfirm?TID=" + this.state.Model.ProposalResponse.ProposalNumber} />}
                {this.IsMessage ? (
                    <div className="Manualmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header text-center">
                                    <button
                                        type="button"
                                        className="close btn-success"
                                        onClick={this.CloseMessage}
                                    >
                                        ×
                  </button>
                                </div>
                                <div className="modal-body">
                                    <span>{this.state.MessageBody}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
