/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
import { NavbarBrand } from "reactstrap";
const Footer = () => {
    return (
        <React.Fragment>
            <div id="pgFooter">
                <div class="footerTop wow fadeInUp animated">
                    <p>CIN: U66000DL1995PTC172311 | IRDAI - Direct Broker <a href="/Content/SMC/images/Certificate.pdf">License Code No. DB-272/04/289 Valid till 27/01/2023</a> <br />Principal Officer Mr. R P Bagga (Contact 011-66222266)<br /> Insurance is the subject matter of solicitation. Visitors are hereby informed that their information submitted on the website may be shared with insurers. Product information is authentic and solely based on the information received from the Insurer.</p>
                </div>
                <div class="footerBottom wow fadeInUp animated">
                    <div class="footerMid">
                        <div class="footerLogo"><img src={require("../assets/img/SMC/images/smclogo2.png")} alt="" /></div>
                        <div class="address">
                            <ul>
                                <li>
                                    <b>CORPORATE OFFICE:</b>
                                    <br /><b>SMC Insurance Brokers Pvt. Ltd.</b>
                                    <br />Pratap Nagar Metro Mall,
                        <br />Pratap Nagar,New Delhi-110007
                        <br />
                                </li>
                                <li>
                                    <b>REGISTERED OFFICE:</b>
                                    <br />11/6B, Shanti Chamber, Pusa Road, New Delhi-110005
                    </li>
                            </ul>
                        </div>
                        <div class="contact">
                            <ul class="">
                                <li> <i class="flaticon-smartphone"></i><a href="">+91-11-66222266</a></li>
                                <li><i class="flaticon-telephone"></i><a href="">1800 2666 3666</a></li>
                                <li><i class="flaticon-envelope"></i><a href="">smc@smcinsurance.com</a></li>
                            </ul>
                        </div>
                        <div class="social">
                            <ul class="">
                                <li><a href="#"><i class="flaticon-facebook"></i></a></li>
                                <li><a href=""><i class="flaticon-twitter"></i></a></li>
                                <li><a href=""><i class="flaticon-linkedin"></i></a></li>
                                <li><a href=""><i class="flaticon-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="mainLinks wow fadeInUp animated">
                        <div class="footerLinks">
                            <div>
                                <h4>ABOUT US</h4><a href="/SMCInsurance/AboutIns">SMC Insurance</a><a href="https://smcindiaonline.com/" target="_blank">SMC Group</a><a href="/SMCInsurance/AboutGroup">SMC News</a><a href="/SMCInsurance/Career">Career</a><a href="/SMCInsurance/Contact">Contact Us</a>
                            </div>
                            <div>
                                <h4>Insurance</h4><a href="">Two Wheeler</a><a href="">Health</a><a href="">Term Life</a><a href="#">Home</a><a href="">Other</a><a href="">Personal Accident</a><a href="">Critical Illness</a><a href="">Commercial Insurance</a>
                            </div>
                            <div>
                                <h4>Our Services</h4><a href="">Life Insurance</a><a href="/SMCInsurance/General_Insurance">General Insurance</a><a href="/SMCInsurance/Group_Insurance">Group Insurance</a><a href="/SMCInsurance/Risk_Management">Risk Management</a><a href="/SMCInsurance/Underwriting_Management">Underwriting</a><a href="/Home/Claim">Claim Management</a><a href="/SMCInsurance/MISP_Motor_Insurance">Misp</a><a href="/RegisterPos/Index">posp</a><a href="/RegisterPos/Index">Other</a><a href="/Account/Index">Customer</a><a href="/Account/Index">Employee</a>
                            </div>
                        </div>
                        <div class="footerCopy wow fadeInUp animated">
                            <div><span>Copyright 2020 <b>SMC INSURANCE | All right reserved</b></span></div>
                            <div><a href="">Disclaimer</a><a href="">Privacy Policy</a><a href="">Terms Of Use</a><a href="">Sitemap</a></div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Footer;
