/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
const AccordianCar = (props) => {

    return (
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 class="text-secondary mb-3">Frequently asked questions</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <Accordion class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">Why do I need to insure my vehicle?</Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Insurance protects the vehicle and helps the owner meet any financial loss caused
                                        due accidents, damage or theft. Insurance also ensure the safety of:
                                    <ul>
                                            <li>Passengers</li>
                                            <li>Fellow drivers</li>
                                            <li>Third party property</li>
                                            <li>Pedestrians</li>
                                            <li>Self</li>
                                        </ul>
                                    In India, driving a motor vehicle such as a car without insurance in public places
                                    is a punishable offense per the <strong>Motor Vehicles Act, 1988.</strong> Under the
                                    law, an individual found driving a vehicle without valid insurance can be fined Rs
                                    2,000 for their first offense and Rs 4,000 for a future offense.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="14">
                                    What are the factors affecting car insurance policy premium?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="14">
                                    <Card.Body>
                                        The premium payable depends on the below factors:
                                    <ul>
                                            <li>Cubic capacity of the engine</li>
                                            <li>Year of purchase</li>
                                            <li>Vehicle model</li>
                                            <li>Geographical Zone</li>
                                            <li>Insured Declared Value (IDV)</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card class="accordion-item">

                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    What is IDV?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        Insured Declared Value or IDV is the sum assured value in the vehicle insurance
                                        policy. It is calculated based on the manufacturer's listed price of a particular
                                        vehicle and adjusted against the current selling price of the car after calculating
                                        the depreciation percentage as per the rate grid. Simply put, it is the maximum sum
                                        insured that can be compensated in case of loss, theft, or complete damage to the
                                    vehicle.<br /><br />
                                                Insurance Regulatory & Development Authority (IRDA) which regulates the insurance
                                                industry in India, has set the IDV of the vehicle as 95% of its ex-showroom price.
                                                It is dependent on various factors, including age, vehicle brand. The IDV value
                                                decreases each year at the time of renewal of the vehicle insurance. With an
                                                increase in the age of the car, the depreciation also increases. IDV of the new
                                                vehicle will be high, and as the car gets older, IDV will go down.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    How to calculate the insurance premium for a car?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <strong>Car Insurance Calculator:</strong><br />
                                                        A car insurance premium calculator is an online tool to determine insurance policy
                                                        quotes in India. It is calculated based on a simple formula.
                                    <br /><strong>Own damage premium – (depreciation + NCB) + Liability
                                        premium</strong><br />
                                                                Five essential factors to consider are —<br />
                                        <ul>
                                            <li>No Claim Bonus (NCB)</li>
                                            <li>ADV</li>
                                            <li>Cubic capacity</li>
                                            <li>Year of manufacture</li>
                                            <li>Location</li>
                                        </ul>
                                    Mentioned below are the standard rates of depreciation specified by the Motor Tariff
                                    Act:
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    Can car insurance premiums be paid in instalments?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        As per Section 64VB of The Insurance Act, 1938 implies that car insurance coverage
                                        cannot be offered unless full premium payment is received on or before the policy
                                        start date. Hence, car insurance premium cannot be paid in instalments.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    What is ARAI?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        ARAI stands for Automotive Research Association of India. If the vehicle has an ARAI
                                        approved anti-theft device installed and dully certified by an agency, the
                                        policyholder gets a discount of 2.5% on the Own Damage (OD) premium upto ₹500.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    What is an endorsement?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        An endorsement is a written evidence of an agreed change in the motor policy. It is
                                        a formal document that incorporates any and all changes made in the terms of the
                                        policy. Additional premium may also be charged as applicable.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    What is the duration under Garage cash cover in car insurance policy?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        The duration for which the company will be liable for claim in respect of this add
                                        on will be considered from the day after the insured vehicle is delivered to the
                                        garage till the day immediately preceding the date of discharge or date of invoice
                                        for such repair as prepared by the garage, whichever is earlier.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                    What is the legal liability cover for a paid driver?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        A legal liability for a paid driver implies that if you have hired a driver to drive
                                        your vehicle and he meets with an accident while driving it, then the insurance
                                        company will provide compensation for his injury/ loss of life.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    What is constructive total loss?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        It refers to accidental damage or loss o the vehicle where the aggregate cost of
                                        retrieval and/or repairs totals to be more than 75% of the IDV on the policy.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="9">
                                    Will car insurance policy be issued if there is LPG/CNG kit fitted without RC
                                    endorsement?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        Policy can and will only be issued if the LPG/CNG kit is endorsed on the
                                        Registration Certificate (RC) by the concerned RTO.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="10">
                                    How many times a Road Side Assistance service claim can be made during a policy
                                    period?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="10">
                                    <Card.Body>
                                        A policyholder can claim Road Side Assistance service a maximum of 4 times for any
                                        of the assistance services opted.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="11">
                                    How to file for an insurance claim in Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="11">
                                    <Card.Body>
                                        The first step in the claims process is to inform the insurance company about the
                                        claim using the toll-free number mentioned in the policy. While filing for a claim,
                                        please keep the following details ready –
                                    <ul>
                                            <li>Name of the insured as per the policy</li>
                                            <li>
                                                Contact details of the insured or claimant</li>
                                            <li>
                                                Policy document, RC, and driving license</li>
                                            <li>
                                                In case of an accident, details of the parties and vehicles</li>
                                            <li>
                                                Date, venue, and time of the accident</li>
                                            <li>
                                                Brief description about the accident</li>
                                            <li>
                                                Extent of loss </li>
                                        </ul>

                                    Once the claim is filed with the insurer, a claims registration number is issued.
                                    Visit www.bimastreet.com/free-claims and enter the number to process your claim in
                                    the form, for a hassle free claims process.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="12">
                                    Is car insurance policy transferrable?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="12">
                                    <Card.Body>
                                        You can transfer the car insurance to the new owner's name if you are selling your
                                        four-wheeler. However, the NCB is non-transferred and is retained by self. You are
                                        entitled to carry forward your existing NCB to a new car insurance policy and avail
                                        such discounts on its premium.
                                    <br /><br />
                                                                        Within 15 days of purchase of second-hand car, the new car owner should inform the
                                                                        insurance company about the policy transfer. Online pre-inspection of the vehicle is
                                                                        required to be done using a link sent by the insurer.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="13">
                                    Can I renew my motor policy online?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="13">
                                    <Card.Body>
                                        With Bimastreet, it is easy to renew the car policy online. You can renew the policy
                                        as early as 60 days before the expiry of the existing policy. Our authorized
                                        surveyor is responsible for inspection of the vehicle before policy issuance if the
                                        vehicle insurance policy has already expired. The policy will be issued subject to a
                                        satisfactory inspection and submission of required documents.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AccordianCar;
