/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import AccordionData from "./AccordionData";
const Accordian = (props) => {
    const cd = AccordionData.map((data) => {
        return (
            <React.Fragment>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey={data.id} key={data.id}>
                        {data.title}
                        <span className={data.class}>
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                        <Card.Body>{data.body}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <br />
            </React.Fragment>
        );
    });
    return (
        <div className="faq p-0">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3
                            className="text-center"
                            style={{ color: "white", padding: "20px" }}
                        >
                            FAQs
          </h3>
                    </div>
                </div>
                <Accordion>{cd} </Accordion>
                <a href="http://onlinereviews.org.uk/hero-insurance/design-test/health-insurnace-faqs.html#faqs-sec1" target="_blank" className="text-center" style={{ color: "white", textDecoration: "underline", padding: "20px" }}>
                    <h4>Read more FAQs</h4>
                </a>

                <h2 className="text-center" style={{ color: "white", padding: "20px" }}>
                    <p> Got more Questions? Not to worry ! </p>
                </h2>

                <p className="text-center m-0 pb-5" style={{ color: "white" }}>
                    We at <b>HERO</b>, have an efficient and reliable customer support team
        at your service to address all your queries.You can write to us at
        <br />
                    <a href="email:wecare@heroinsurance.com" style={{ color: "white", textDecoration: "underline" }}>wecare@heroinsurance.com</a>, if you have any more questions, we would be
        more than glat to help you.
      </p>
            </div>
        </div>
    );
};
export default Accordian;
