import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";
import { NewCar } from "../Car/NewCar";
import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";
import { Login } from "../Login/Login";
import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";
import './assets/css/Insureasycustom.css'
import './assets/css/tabs-plain-style.css'
import './assets/css/carousel.css'

import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import InsureMiddleSection from "./InsureMiddleSection";
import { NavMenu } from "./NavMenu";
import Tab from "./HomeTab/Tab";
import PrivateCar from "./FooterPages/PrivateCar";
import PassengerCarrying from "./FooterPages/PassengerCarrying";
import Mediclaim from "./FooterPages/Mediclaim";
import Term from "./FooterPages/Term";
import Tnx from "../components/Tnx";
import {MasterGlobal} from "../App"
import Error404 from "../Error/Error404";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
const findPath=()=>{
  let url=window.location.href.toLowerCase();
  let path="HRIKLS";
  if(url.match("proposalmotor") || url.match("proposalhealth"))
  path=window.location.pathname
  return path;
}
const Insureeasy = (props) => {
 let apipath=findPath()


  return (
    <React.Fragment>
          <div className={"Insureasybody " + props.newUi}>
      <NavMenu/>
     
     
        <Layout>
        <Route exact path="/Health" component={Health} />
        <Route exact path="/Car" component={Car} />
                      <Route exact path="/Bike" component={Bike} />
                      <Route exact path="/Renew" component={Renew} />
                      <Route exact path="/New" component={Renew} />
                      
        <Switch>
        <Route exact path="/FourWheelerInsurance" component={PrivateCar} />
        <Route exact path="/TwoWheelerInsurance" component={PassengerCarrying} />
        <Route exact path="/HealthInsurance" component={Mediclaim} />
        <Route exact path="/TermLifeInsurance" component={Term} />
        <Route exact path="/GetQuote" component={MotorQuote} />
                      <Route exact path="/Proposal" component={Proposal} />
                      <Route exact path="/ShowProposal" component={ShowProposal} />
                      <Route exact path="/Health" component={Health} />
                      <Route exact path="/HealthQuotes" component={HealthQuotes} />
                      <Route exact path="/HealthProposal" component={HealthProposal} />
                      <Route exact path="/GetQuoteLife" component={LifeQuote} />
                      <Route
                          exact
                          path="/HealthShowProposal"
                          component={HealthShowProposal}
                      />
                      
                      
        
          <Route exact path="/About" component={About} />
          <Route exact path="/claims" component={Claims} />
          <Route exact path="/ePartner" component={Epartner} />
                  <Route exact path="/Contact" component={Contact} />
                  <Route exact path="/ThankYou" component={Tnx} />
        
                 <Route exact path="/" component={InsureMiddleSection}/>
                 
                  <Route exact path={apipath} component={PaymentSuccessOrFail} />
                  <Route component={Error404} />
        </Switch>
             
                      
               </Layout>


              <Footer />
              <SideNav />
              <SocialToggle mobile="8106602266" email="info@providenceindia.in" whatsapp="8106602266" />
      </div>
     
    </React.Fragment>
  );
};
export default Insureeasy;
