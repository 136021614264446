/* eslint-disable */
import React from 'react';
const PrivacyPolicy = () => {
    return (
        <section className="PolicyCnt">
            <div className="container">
                <h3>PRIVACY POLICY</h3>
                <p>

                    This Privacy Policy governs the manner in which Kare India collects,  uses, maintains and discloses information collected from users (each, a &ldquo;User&rdquo;)  of the http://www.insurekare.com website ('Site'). This privacy policy applies  to the Site and all products and services offered by Kare India.
        <strong>Personal  identification information</strong>
        Terms of sharing personal  information
        As a general rule, this website  does not collect Personal Information about you when you visit the site  automatically. You can generally visit the site without revealing Personal  Information, unless you choose to provide such information. If insurekare.com  requests you to provide personal information, adequate security measures will  be taken to protect your personal information. If at any time you believe the  principles referred to in this privacy statement have not been followed, or  have any other comments on these principles, please notify the web admin  through the Feedback link.  All personal  information you supply is protected according to the federal government&rsquo;s  Privacy Act.
    </p>
                <p>
                    We may collect personal identification information from you in a variety  of ways, including, but not limited to, when you visit our site, register on  the site, place an order, subscribe to the newsletter, respond to a survey,  fill out a form, and in connection with other activities, services, features or  resources we make available on our Site. You may be asked for, as appropriate,  name, email address, mailing address, phone number, credit card information. You  may, however, visit our Site anonymously. We will collect personal  identification information from you only if you voluntarily submit such  information to us. You can always refuse to supply personally identification  information, except that it may prevent you from engaging in certain Site  related activities.
                    By accessing this web site, you  are agreeing to be bound by this web site Terms and Conditions of Use,  applicable laws and regulations and their compliance. The materials contained  in this site are secured by relevant copyright and trade mark law.
    </p>

                <p>
                    <strong>Non-personal  identification information</strong>
        We may collect non - personal identification information about you whenever  you interact with our Site.Non - personal identification information may include  the browser name, the type of computer and technical information about you, means of connection to our Site, such as the operating system and the Internet  service providers utilized and other similar information.<br />
        Web browser  cookies <br />
        A cookie is a data file that  certain Websites write to your computer's hard drive when you visit such sites.  A cookie file can contain information such as a user identification code that  the site uses to track the pages you have visited and use the information  commercially. Our Site may use &ldquo;cookies&rdquo; to enhance User experience. User may  choose to set their web browser to refuse cookies, or to alert you when cookies  are being sent. If they do so, note that some parts of the Site may not  function properly.
    </p>

                <p>
                    <strong>Email management</strong>
        Your email  address will only be recorded if you choose to communicate with us. (For  example, registering email id in &ldquo;Your Email for Newsletter&rdquo; section, your  feedback for the website etc.) It will only be used for the purpose for which  you have provided it and will not be added to a mailing list. Your email  address will not be used for any other purpose, and will not be disclosed, without  your consent.
    </p>
                <p>
                    <strong>How we use  collected information</strong>
        Purposes of collection of data
        Kare India collects information  upon registration or login to the website for usage of the products/insurance  available on the site. The information asked for, are may be in nature of  personal. During the use of website some or more information may be asked which  can be verified through SMS &amp; email notifications etc. Providing the  information will be considered the consent to use this data for further sharing  the information with you for the new products renewal of insurance. This  information/data will also be shared with insurers for seeking the insurance  solution and insurance policy thereon.
        This website is designed,  developed and maintained by Kare India Insurance Brokers Pvt. Ltd. Though all  efforts have been made to ensure the accuracy and currency of the content on  this website, the same should not be construed as a statement of law or used  for any legal purposes. In case of any ambiguity or doubts, users are advised  to verify/check with the Company and/or other source(s), and to obtain  appropriate professional advice.
        Under no circumstances will this  Company be liable for any expense, loss or damage including, without  limitation, indirect or consequential loss or damage, or any expense, loss or  damage whatsoever arising from use, or loss of use, of data, arising out of or  in connection with the use of this website.
        These terms and conditions shall  be governed by and construed in accordance with the Indian Laws and terms and  conditions which will be laid down from time to time. Any dispute arising under  these terms and conditions shall be subject to the jurisdiction of the courts  of India.
        The information posted on this  website could include hypertext links or pointers to information created and  maintained by Non-Government / Private Organizations.
        Kare India is providing these  links and pointers solely for your information and convenience. When you select  a link to an outside website, you are leaving the insurekare website and are  subject to the privacy and security policies of the owners/sponsors of the outside  website.
        Kare India does not guarantee the availability  of such linked pages at all times
    </p>
                <p>Kare India may collect and use your personal information for the  following purposes:</p>
                <ul>
                    <li>To improve customer service Information,  you provide helps us respond to your customer service requests and support  needs more efficiently.</li>
                    <li>To personalize user experience, we  may use information in the aggregate to understand how our Users as a group use  the services and resources provided on our Site.</li>
                    <li>To improve our Site, we may use  feedback you provide to improve our products and services.</li>
                    <li>To process payments, we may use  the information Users provide about themselves when placing an order only to  provide service to that order. We do not share this information with outside  parties except to the extent necessary to provide the service.</li>
                    <li>To share some information (other  than your personal information like your name, address, email address or  telephone number) with third-party advertising companies and/or ad agencies to  serve ads when you visit our Web site.</li>
                    <li>To run a promotion, contest,  survey or other Site feature </li>
                    <li>To send your information you  agreed to receive about topics we think will be of interest to you.</li>
                    <li>To send periodic emails</li>
                </ul>
                <p>
                    We may use the email address to  send your information and updates pertaining to your order.
                    It may also be used to respond to  your inquiries, questions, and/or other requests.
                    If you decide to opt-in to our mailing list, you will receive emails that  may include company news, updates, related product or service information, etc.  If at any time the you would like to unsubscribe from receiving future emails,  we include detailed unsubscribe instructions at the bottom of each email or you  may contact us via our Site.
                    How we  protect your information
                    We adopt appropriate data collection, storage and processing practices  and security measures to protect against unauthorized access, alteration,  disclosure or destruction of your personal information, username, password,  transaction information and data stored on our Site.
                    Sensitive and private data exchange between the Site and its Users  happens over a SSL secured communication channel and is encrypted and protected  with digital signatures.
    </p>
                <p>
                    <strong>Sharing your  personal information</strong>
        We may use third party service providers to help us operate our business  and the Site or administer activities on our behalf, such as sending out  newsletters or surveys. We may share your information with these third parties  for those limited purposes provided that you have given us your permission.
        Changes to  this privacy policy
        Kare India has the discretion to update this privacy policy at any time.  When we do, we will revise the updated date at the bottom of this page and send  you an email. We encourage you to frequently check this page for any changes to  stay informed about how we are helping to protect the personal information we  collect. You acknowledge and agree that it is your responsibility to review  this privacy policy periodically and become aware of modifications.
    </p>
            </div>
        </section >
    )
}
export default PrivacyPolicy