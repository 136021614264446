/* eslint-disable */ 
import React, { Component } from "react";
import { NavbarBrand } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { Tab, Row, Col, Nav } from "react-bootstrap";
export class NewCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CarList: [],
      FuelList: [],
      VariantList: [],
      RTOList: [],
      YearList: [],
      CarVal: null,
      FuelVal: null,
      VariantVal: null,
      RTOVal: null,
      YearVal: null,
      MobileNo: null,
      IsReNew: false,
      PolicyType: "N",
      RegistrationDate: null,
      PreviousPolicyExpiredDate: null,
      ManufactureDate: null,
      PastYearClaimID: false,
      //PastYearClaimID: "N",
      TypeOfPolicy: 1,
      PACOVERYEAR: 1,
      BindPreviousNCB: "20",
      Organisation: false,
      MyPolicyexpired: false,
      Previouspolicydetails: false,
      ManufacturerID: 0,
      EnquiryNo: null,
      ISPACOVERODSELECTED: false,
      IsGetQuote: false,
      showPopup:true,
      fillNewCar:false,
    };
    this.pageLoad();
    console.log(this.state.CarList)
  }
  pageLoad() {
    this.CallGetMethod(
      "http://apsapi.stariengineering.com/api/Vehicle/GetVehiclesByType?type=Car",
      "CarList"
    );
  }
  handle = (e) => {
    if (
      e.target.name == "Organisation" ||
      e.target.name == "MyPolicyexpired" ||
      e.target.name == "Previouspolicydetails"
    ) {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    switch (e.target.name) {
      case "CarVal":
        this.CallGetMethod(
          "http://apsapi.stariengineering.com/api/Vehicle/GetFuelByVehicleID/" +
            e.target.value,
          "FuelList"
        );
        break;
      case "FuelVal":
        this.CallGetMethod(
          "http://apsapi.stariengineering.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" +
            this.state.CarVal +
            "&FuelID=" +
            e.target.value +
            "",
          "VariantList"
        );
        break;
      case "VariantVal":
        this.CallGetMethod(
          "http://apsapi.stariengineering.com/api/RTO/GetRtos",
          "RTOList"
        );
        break;
      case "RTOVal":
        if (this.state.IsReNew) this.makeYearList();
        break;
      case "Previouspolicydetails":
        this.setState({ MyPolicyexpired: e.target.checked });
        break;
      case "PastYearClaimID":
        this.setState({ PastYearClaimID: !this.state.PastYearClaimID });
        break;
      case "ISPACOVERODSELECTED":
        this.setState({
          ISPACOVERODSELECTED: !this.state.ISPACOVERODSELECTED,
          PACOVERYEAR: e.target.value == "Yes" ? 1 : 0,
        });
        break;
    }
  };
  makeYearList() {
    let date = new Date();
    let Currentyear = date.getFullYear();
    let lstYear = [];
    for (let i = Currentyear; i > Currentyear - 10; i--) {
      lstYear.push(i);
    }
    this.setState({ YearList: lstYear });
  }
  CallGetMethod(Url, prp) {
    fetch(Url, {
      method: "GET",
      headers: { ContentType: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ [prp]: data });
      });
  }
  ClickNew(param) {
    if (param == "New")
      this.setState({
        IsReNew: false,
        PolicyType: "N",
      });
    else
      this.setState({
        IsReNew: true,
        PolicyType: "R",
      });
  }
  getQuote = (e) => {
    let date = new Date();
    let manufacture = this.state.CarList.filter(
      (row) => row.VehicleID == this.state.CarVal
    )[0].ManufacturerID;
    let Req = {
      MobileNo: this.state.MobileNo,
      EnquiryDate: date,
      EnquiryType: "MOT",
      MotorEnquiry: {
        ManufactureID: manufacture,
        VehicleID: this.state.CarVal,
        VariantID: this.state.VariantVal,
        FuelID: this.state.FuelVal,
        RegistartionYear: this.state.YearVal,
        RTOID: this.state.RTOVal,
        PolicyType: this.state.PolicyType,
        MotorType: "Car",
      },
      UserID: 0,
      LeadSource: "online",
      ClientID: 52,
    };
    fetch("http://apsapi.stariengineering.com/api/Enquiry/AddEnquiry", {
      method: "POST",
      body: JSON.stringify(Req),
      headers: { "Content-Type": "application/json" },
    })
      .then((Response) => Response.text())
      .then((Res) => {
        let date = new Date();
        let month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let RegistrationDate = date.getFullYear() + "-" + month + "-01";
        let PreviousPolicyExpiredDate = "";
        this.setState({
          ManufacturerID: manufacture,
          EnquiryNo: Res.split("~")[0].replace('"', ""),
          RegistrationDate: RegistrationDate,
          PreviousPolicyExpiredDate: PreviousPolicyExpiredDate,
          IsGetQuote: true,
        });
      });
  };
  CallGetQuote = (e) => {
    this.GetQuotation(this.state.ManufacturerID, this.state.EnquiryNo);
  };
  GetQuotation(manufactureID, EnquiryNo) {
    let expirydate = this.state.PreviousPolicyExpiredDate;
    let fullDate = null;
    if (this.state.MyPolicyexpired) {
      let date = new Date(expirydate);
      date = date.setDate(date.getDate() - 90);
      let month =
        date.getMonth() + 1 <= 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() <= 10 ? "0" + date.getDate() : date.getDate();
      fullDate = date.getFullYear + "-" + month + "-" + day;
    } else fullDate = expirydate;

    let Req = {
      VehicleID: this.state.CarVal,
      VariantID: this.state.VariantVal,
      FUELID: this.state.FuelVal,
      RtoID: this.state.RTOVal,
      RegistrationYear: this.state.RegistrationDate,
      Clientid: "",
      ClientURL: "https://smc.smcinsurance.com",
      CityID: "0",
      StateID: "0",
      RtoCode: this.state.RTOList.filter(
        (row) => row.RTOID == this.state.RTOVal
      )[0].RTOCode,
      NomineeRelationID: "0",
      OccupationID: "0",
      PreviousInsurerCompanyID: "",
      Type: "1",
      ManufacturerID: manufactureID,
      RtoLocation: "",
      FuelName: this.state.FuelList.filter(
        (row) => row.FuelID == parseInt(this.state.FuelVal)
      )[0].FuelName,
      IsUat_Production: "",
      PreviousPolicyExpiredDate: fullDate,
      ManufactureDate: this.state.RegistrationDate,
      PastYearClaimID: "",
      TypeOfPolicy: "0",
      IDVLpgCng: 0,
      IDVElectrical: 0,
      IDVNonElectrical: 0,
      chkConsumables: false,
      chkEngineProtector: false,
      chkDepreciation: false,
      chkRoadSideAssistance: false,
      chkReturnToInvoice: false,
      chkPaidDriver: false,
      chkTyreSecure: false,
      chkKeyReplacement: false,
      chkNCBProtection: false,
      chkInconvenienceAllowance: false,
      chkTPPDcover: false,
      chkPassangerAssistance: false,
      ISPACOVERODSELECTED: this.state.ISPACOVERODSELECTED ? "YES" : "NO",
      PACOVERYEAR: this.state.ISPACOVERODSELECTED ? this.state.PACOVERYEAR : 0,
      chkPassengerCover: false,
      IsAntiTheft: false,
      ThirdPartyCover: false,
      IDV: 0,
      PassengerCoverSelectedIDV: 0,
      MobileNo: this.state.MobileNo,
      BindPreviousNCB: "",
      IsPreviousZeroDepthTaken: false,
      ComprehensiveforThree_FiveYear: false,
      IsCNG_LPG: "N",
      VoluntaryAmount: 0,
      AutoMobileMembership: "False",
      AutoMobileMembershipName: "",
      AutoMobileMembershipNumber: "",
      AutoMobileMembershipDate: "",
      EnquiryNo: EnquiryNo,
      PrevPolicyType: "1",
      vRanKey: "4834297750159265",
      vEncryptedLogin: "N0VpT3BaVTRVNW13eFNXb0dMaXVMQT09",
      vEncryptedPassword: "ck1QQ2V6Y09UeElCejJSc2hWTDdXQT09",
      AllCompIDVs: [],
      Period: 1,
      DepriciationPercentage: 0,
      odOnly: "False",
      DetariffRate: "",
      IsCorporate: this.state.Organisation ? true : false,
      IsPrevPolicyDetails: this.state.Previouspolicydetails,
    };
    this.props.history.push("/GetQuote", { Req: Req });
    /*fetch("https://smc.smcinsurance.com/api/api/InsurerMotor/GetALLQuotationV2", {
            method: "POST",
            body: JSON.stringify(Req),
            headers: { "Content-Type": "application/json" }
        }).then(response => response.json()).then(data => {
            console.log(data)
        })*/
        console.log(this.state.CarList)
  }
  close=(e)=>{
    this.setState({showPopup:false,fillNewCar:true})
  }
  render() {
    return (
      this.state.fillNewCar?<Redirect to="/" />:
      <div className="">
{this.state.showPopup?
      <div id="myModal" className="modal ProductPopUpBox" role="dialog" data-backdrop="static" >
      <div className="modal-dialog  modal-lg NewProModel0011">
         
          <div className="modal-content">
              <button type="button" className="close" data-dismiss="modal" onClick={this.close}>×</button>
              <div className="modal-body NewProModel00">
                  <div className="card NewProModel01">
                      <div className="row flexAuto">
                         
                          <div className="col-md-3">
                              <ul className="nav nav-tabs" role="tablist">
                                  <li role="presentation"><a href="JavaScript:Void(0)" className="tab2 active" aria-controls="browseTab" role="tab" data-toggle="tab">Brand </a></li>
                                  <li role="presentation"><a href="JavaScript:Void(0)" className="tab3" aria-controls="browseTab" role="tab" data-toggle="tab">Fuel</a></li>
                                  <li role="presentation"><a href="JavaScript:Void(0)" className="tab4" aria-controls="browseTab" role="tab" data-toggle="tab">Variant</a></li>
                                  <li role="presentation"><a href="JavaScript:Void(0)" className="tab5" aria-controls="browseTab" role="tab" data-toggle="tab">Rto</a></li>
                                  <li role="presentation" id="rgstYear" ><a href="JavaScript:Void(0)" className="tab6" aria-controls="browseTab" role="tab" data-toggle="tab"><span>Year</span></a></li>
                                  <li role="presentation"><a href="JavaScript:Void(0)" className="tab7" aria-controls="browseTab" role="tab" data-toggle="tab"><span className="hidden-xs hidden-sm">Your Car</span></a></li>
                              </ul>
                          </div>
                          <div className="col-md-9 productValueAlign">
                             
                              <div className="tab-content">
                                  <div role="tabpanel" className="tab-pane active" id="home">
                                      <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
  
                                          <div className="col-md-12 text-center">
                                              <div className="VehicleCnt01">
                                              <h4>
                                              Which {this.state.SelectMotor} do you drive?
                                            </h4>
                                            <span>
                                              Search for your {this.state.SelectMotor}
                                            </span>
                                              </div>
                                          </div>
                                          <div className="col-md-12 text-center">
                                              <div className="yrVehicle">
                                                  <select id="DdlVehicle" onChange={this.handle} className="form-control select2 " name="ManufactureID" style={{width:"100%"}} data-select2-id="DdlVehicle" tabIndex="-1" aria-hidden="true">
                                                      <option data-select2-id="2">Make And Model</option>
                                                      {this.state.CarList.map((row) => (
                                                        <option value={row.VehicleID}>
                                                          {row.Manu_Vehicle}
                                                        </option>
                                                      ))}
                                                  </select></div>
                                          </div>
                                          
                                      </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane" id="profile">
                                      <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                          <div className="col-md-12 text-center">
                                              <div className="VehicleCnt01">
                                                  <h4>What is the fuel type?</h4>
                                                  <span>Filter by Fuel type as shown below</span>
                                              </div>
                                          </div>
                                          <div className="col-md-12 text-center">
                                              <div className="yrVehicle">
                                                  <select id="DdlFuel" onChange={this.handle} className="form-control select2 select2-hidden-accessible" name="FuelID" style={{width:"100% !important"}} data-select2-id="DdlFuel" tabindex="-1" aria-hidden="true" onChange={this.handle}
                                                  >
                                                    <option>Select Fuel</option>
                                                    {this.state.FuelList.map((row) => (
                                                      <option value={row.FuelID}>
                                                        {row.FuelName}
                                                      </option>
                                                    ))}
  </select>                                   </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane" id="Model">
                                      <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                          <div className="col-md-12 text-center">
                                              <div className="VehicleCnt01">
                                                  <h4>Which  model do you drive?</h4>
                                                  <span>Select the model of your CAR</span>
                                              </div>
                                          </div>
                                          <div className="col-md-12 text-center">
                                              <div className="yrVehicle">
                                                  <select id="DdlVariant" className="form-control select2 select2-hidden-accessible" name="VariantID" style={{width:"100%"}} data-select2-id="DdlVariant" tabindex="-1" aria-hidden="true" onChange={this.handle}
                                                  >
                                                    <option>Select Variant</option>
                                                    {this.state.VariantList.map((row) => (
                                                      <option value={row.VariantID}>
                                                        {row.VariantName}{" "}
                                                      </option>
                                                    ))}</select>
                                     </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane" id="Rto">
                                      <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                          <div className="col-md-12 text-center">
                                              <div className="VehicleCnt01">
                                                  <h4>Where was your car registered?</h4>
                                                  <span>Search for your vehicle</span>
                                              </div>
                                          </div>
                                          <div className="col-md-12 text-center">
                                              <div className="yrVehicle">
                                                  <select id="DdlRTO" className="form-control select2 select2-hidden-accessible" name="RTO_ID" style={{width:"100% !important"}} data-select2-id="DdlRTO" tabindex="-1" aria-hidden="true" onChange={this.handle}
                                                  >
                                                    <option>Select RTO</option>
                                                    {this.state.RTOList.map((row) => (
                                                      <option value={row.RTOID}>
                                                        {row.RTOName}{" "}
                                                      </option>
                                                    ))}
                                                  </select>
                                 
                                                  </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane" id="messages">
                                      <div className="wow fadeInUp rgstYear" data-wow-delay="0.10s" >
                                          <div className="col-md-12 text-center">
                                              <div className="VehicleCnt01">
                                                  <h4>Which year was it registered?</h4>
                                                  <span>Please select the year of Registration of your CAR</span>
                                              </div>
                                          </div>
                                          <div className="col-md-12 text-center">
                                              <div className="yrVehicle">
                                                  <select id="DdlRegCarYear" className="form-control select2 select2-hidden-accessible" data-val="true" data-val-number="The field RegistrationYear must be a number." data-val-required="The RegistrationYear field is required." name="RegistrationYear" style={{width:"100% !important"}} data-select2-id="DdlRegCarYear" tabindex="-1" aria-hidden="true"
                                                 
                                                  onChange={this.handle}
                                                >
                                                  <option>Select Year</option>
                                                  {this.state.YearList.map((row) => (
                                                    <option value={row}>{row} </option>
                                                  ))}
                                                  </select>
                                                </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane" id="myNumberpop" >
                                      <div className="side-view side-viewLastStep">
                                          <div className="row">
                                              <div className="col-md-12">
                                                  <div className="inx-wrapper text-center">
                                                      <div className="inx-text"> One last Step to view the quotes<br/><small>Before you go ahead, make sure the info is correct.</small> </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-12">
                                                  <div className="FillingDetails">
                                                      <div className="FDImg">
                                                          <img src="/Content/PolicyMart/images/ProCar.png" />
                                                      </div>
                                                      <div className="FDCnt">
                                                          <p><strong>Vehicle</strong><span id="pop-car"></span></p>
                                                          <p><strong>Fuel</strong><span id="pop-fuel"></span></p>
                                                          <p><strong>Variant</strong><span id="pop-variant"></span></p>
                                                          <p><strong>RTO</strong><span id="pop-rto"></span></p>
                                                          <p className="RegYearhN"><strong>Year</strong><span id="pop-year"></span></p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-12">
                                                  <div className="mobileBox">
                                                      <div className="PF0012">
                                                          <div className="row">
                                                              <div className="col-md-8 NoPaddingRight">
                                                                  
                                                                  <input
                                                                  type="text"
                                                                  name="MobileNo"
                                                                  className="packageForm"
                                                                  maxLength="10"
                                                                  value={this.state.MobileNo}
                                                                  onChange={this.handle}
                                                                />
                                                             
                                                               <span>For Policy related updates</span>
                                                                 
                                                              </div>
                                                              <div className="col-md-4 NoPaddingLeft">
                                                              <input
                                                              type="button"
                                                              className="QouteMNBtn"
                                                              value="Get Quote"
                                                             
                                                            />   
                                                              <button type="button" id="btnCarSubmit" className="btn btn-block QouteMNBtn" data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Please wait..."  onClick={this.getQuote}>View Quotes</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  
  
  </div>
                               :null                   }

       {/* <div className="packagesMainCar">
          <div className="productTabsMainCar">
            <h3 class="packageHeading text-center packageHead">
              Let's plan your insurance online
            </h3>
            {/*
                        <div className="row">
                            <input type="button" onClick={(e) => this.ClickNew("New")} value="New" />
                            <input type="button" onClick={(e) => this.ClickNew("ReNew")} value="ReNew" />
                        </div>
                        *}
            <div className="ProductFormIn">
              <Tab.Container id="left-tabs-example" defaultActiveKey="BRAND">
                <Row>
                  <Col sm={3}>
                    <Nav
                      variant="pills"
                      className="flex-column"
                      style={{ backgroundColor: "#F9F9F9" }}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="BRAND">BRAND</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="FUEL">FUEL</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="VARIANT">VARIANT</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="RTO">RTO</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="CAR">YOUR CAR</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="BRAND">
                        <div className="row d-flex justify-content-center text-center">
                          <div class="VehicleCnt01 mt-5 mb-3">
                            <h4>
                              Which {this.state.SelectMotor} do you drive?
                            </h4>
                            <span>
                              Search for your {this.state.SelectMotor}
                            </span>
                          </div>
                          <div className="col-sm-6 ">
                            {" "}
                            <label>
                              <select
                                name="CarVal"
                                className="packageForm"
                                onChange={this.handle}
                              >
                                <option>Select Car</option>
                                {this.state.CarList.map((row) => (
                                  <option value={row.VehicleID}>
                                    {row.Manu_Vehicle}{" "}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="FUEL">
                        <div class="VehicleCnt01 mt-5 mb-3 text-center">
                          <h4>What is the fuel type?</h4>
                          <span>Filter by Fuel type as shown below</span>
                        </div>

                        <div className="row d-flex justify-content-center text-center">
                          <div className="col-sm-6">
                            {" "}
                            <label>
                              <select
                                name="FuelVal"
                                className="packageForm"
                                onChange={this.handle}
                              >
                                <option>Select Fuel</option>
                                {this.state.FuelList.map((row) => (
                                  <option value={row.FuelID}>
                                    {row.FuelName}{" "}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="VARIANT">
                        <div class="VehicleCnt01 mt-5 mb-3 text-center">
                          <h4>Which model do you drive?</h4>
                          <span>
                            Select the model of your {this.state.SelectMotor}
                          </span>
                        </div>
                        <div className="row d-flex justify-content-center text-center">
                          <div className="col-sm-6">
                            {" "}
                            <label>
                              <select
                                name="VariantVal"
                                className="packageForm"
                                onChange={this.handle}
                              >
                                <option>Select Variant</option>
                                {this.state.VariantList.map((row) => (
                                  <option value={row.VariantID}>
                                    {row.VariantName}{" "}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="RTO">
                        <div class="VehicleCnt01 mt-5 mb-3 text-center">
                          <h4>Where was your car registered?</h4>
                          <span>Search for your vehicle</span>
                        </div>
                        <div className="row d-flex justify-content-center text-center">
                          <div className="col-sm-6">
                            <label>
                              <select
                                name="RTOVal"
                                className="packageForm"
                                onChange={this.handle}
                              >
                                <option>Select RTO</option>
                                {this.state.RTOList.map((row) => (
                                  <option value={row.RTOID}>
                                    {row.RTOName}{" "}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="CAR">
                        <div class="VehicleCnt01 mb-3 text-center">
                          <h5>
                            <b>One last Step to view the quotes</b>
                          </h5>
                          <span style={{ fontSize: "12px" }}>
                            Before you go ahead, make sure the info is correct.
                          </span>
                        </div>
                        <div className="row d-flex justify-content-center text-center">
                          <div className="col-sm-6">
                            {this.state.IsReNew ? (
                              <label>
                                <select
                                  name="YearVal"
                                  className="packageForm"
                                  onChange={this.handle}
                                >
                                  <option>Select Year</option>
                                  {this.state.YearList.map((row) => (
                                    <option value={row}>{row} </option>
                                  ))}
                                </select>
                              </label>
                            ) : null}
                            <label>
                              <input
                                type="text"
                                name="MobileNo"
                                className="packageForm"
                                maxLength="10"
                                value={this.state.MobileNo}
                                onChange={this.handle}
                              />
                            </label>
                            <label>
                              <input
                                type="button"
                                className="QouteMNBtn"
                                value="Get Quote"
                                onClick={this.getQuote}
                              />
                            </label>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>*/
}
        {this.state.IsGetQuote ? (
          <div className="packagesMainCar packagesMainPopUp">
            <div className="productTabsMain">
              <div className="text-center">
                <h3 className="packageHeading">We have got the Quotes!</h3>
                <button type="button" onClick={this.close} className="close">
                  &times;
                </button>
              </div>
              <div className="ProductFormIn ProductFormInQuote">
                <label>
                  <span>What was your car Manufactured Date</span>
                  <input
                    type="date"
                    value={this.state.RegistrationDate}
                    name="RegistrationDate"
                    className="packageForm"
                    onChange={this.handle}
                  />
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="packageFormCheck"
                    name="Organisation"
                    onChange={this.handle}
                  />
                  <span className="CheckSpan">
                    Vehicle owned by organisation
                  </span>
                </label>

                <label className="paCoverOd">
                  <p>PA cover OD</p>
                  <input
                    className="packageFormCheck"
                    name="ISPACOVERODSELECTED"
                    type="radio"
                    value="No"
                    onChange={this.handle}
                    checked={!this.state.ISPACOVERODSELECTED}
                  />
                  <span className="CheckSpan"> No</span>
                  <input
                    className="packageFormCheck"
                    name="ISPACOVERODSELECTED"
                    type="radio"
                    value="Yes"
                    onChange={this.handle}
                    checked={this.state.ISPACOVERODSELECTED}
                  />
                  <span className="CheckSpan"> Yes</span>
                </label>
                {this.state.ISPACOVERODSELECTED ? (
                  <label>
                    <select
                      className="packageForm"
                      name="PACOVERYEAR"
                      value={this.state.PACOVERYEAR}
                    >
                      <option value="0">Select PA Cover Year</option>
                      <option value="1">1 Year</option>
                      <option value="3">3 Year</option>
                    </select>
                  </label>
                ) : (
                  <label>
                    <input
                      type="checkbox"
                      className="packageFormCheck"
                      name="TermCond"
                      checked={!this.state.ISPACOVERODSELECTED}
                    />
                    I hereby declare that I am holding an existing Personal
                    Accident Policy (standalone cover or existing motor
                    insurance policy) and/or any other policy/ies covering
                    against Death and Permanent Disability (Total and Partial)
                    of INR 15 Lakhs or more, which policy/ies is currently in
                    force and/or would continue to be in full force during the 1
                    year Own Damage term/period declared by me under this
                    Proposal. I accordingly confirm that I do not require the
                    Compulsory Personal Accident cover for owner driver under
                    this motor policy.
                  </label>
                )}
                <label>
                  <input
                    type="button"
                    value="Get Quote"
                    className="QouteMNBtn"
                    onClick={this.CallGetQuote}
                  />
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
