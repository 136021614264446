import React from "react";

const About = () => {
    return (
        <React.Fragment>
            <section className="aboutUs">
                <div className="container pb-4">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7">
                            <div className="section-heading text-left wow fadeInUp  animated">
                                <h1 className="section-title text-left">About us:</h1>
                                <p className="section-sub">
                                    <strong>Insuarnce Mandi</strong> Insuarnce Mandi is a product of Sanyog Insurance Brokers India Private Limited is a direct insurance broker delivering insurance and broking services to corporate clients in India.We at Sanyog strongly believe in service first and are committed to deliver end to end solutions to our clients using state of the art technology. We intend on using their expertise in delivering insurance and technology solutions for the Commercial and Retail broking market. Our goal is to innovate and grow Insurance business with our core values of Trust, Transparency and Transcend.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-md-5 p-5">
                            <img src={require("./assets/img/Mandi/services-1.png")} alt="Partnership Concept" className="work__bg wow fadeInRight  animated" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default About;
