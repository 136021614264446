/* eslint-disable */
import React from 'react';
const MotorIns = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <section class="summeryPage">
            <div class="container">
                <div class="main-counter-area">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <h3>CAR INSURANCE (4-wheeler) and BIKE INSURANCE (2-wheeler)</h3>
                                <p>Car insurance or Bike insurance, also known as auto or motor insurance protects the value of the vehicle in cases where due to uninvited incident like accident, natural disaster or theft, damage occurs resulting into a financial loss towards repairs or loss to the vehicle. Further the insurance pays for medical expenses to those involved in the accident and any property damage of the persons involved in the accident.  Thus, it covers and pays for financial losses to own damage to the vehicle, to damage to the property or the vehicle with which he meets with an accident and injuries even death to all involved in the accident called Third Party Liability Insurance.  You can opt to choose from covering your vehicle with only Third-Party Liability - which is mandatory or add own damage also to it called Comprehensive Policy</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/KareIndia/Motor.png")} className="p-4" />
                        </div>
                        <div class="col-md-12">
                            
                            <div class="ClaimsPageSecCNTBox border-0">
                                <p>A vehicle insurance policy gives a sense of security to a person to drive their vehicle confidently on the road and is a boon at the time of emergencies.</p>
                                <p>We believe that a vehicle insurance policy should be quick, easy and most of all - reliable. Hence, we have come up with a comprehensive policy that not only offers optimum protection to your vehicle, but also helps you financially in case of any unfortunate occurrence.</p>
                                <p>Now a dent in your car will not cause a dent in your finances. At Kare India, we have quick and effective solutions for all your vehicle insurance needs and more!WE offer you a third-party only or a comprehensive with own damage vehicle insurance at affordable premiums online.</p>
                                <p>Insurance Policy is a contract BETWEEN THE INSURER AND INSURED (VEHICLE OWNER) in which the insurance company agrees to pay to the insured for any financial losses AS RESULT OF ABOVE INCIDENTS</p>
                                <br /><br />
                                <h2>Vehicle Policies</h2>

                                <h3>Third Party:</h3>
                                <p>As the word states the third-party insurance safe guards the insured person from any financial loss that may occur as a result of the damage caused to third person by the insured. Here third party can be any person or a property or other person's vehicle.</p>

                                <h3>Comprehensive</h3>
                                <p>Comprehensive motor insurance safe guards the insured person from any financial loss that may occur not only as a as result of the damage caused to third person from the insured but also his own vehicle damages, personal belongings, theft, natural calamities when there is an accident or an incident.</p>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
export default MotorIns