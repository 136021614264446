import React, { Fragment } from "react";
const Dedicated = (props) => {
    return (
        <React.Fragment>
            <section className="PR_Section1">
                <div className="ReviewSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 offset-md-1">
                                <div class="wpb_wrapper">
                                    <p class="mb-0">Our Customer Say <span class="text-dark font-weight-bold ml-2">Excellent</span>
                                        <img class="ml-1" src="http://demo.oceanthemes.net/borrow/wp-content/uploads/2020/05/halfstar.svg" alt="" />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="wpb_wrapper">
                                    <p>4.6 out of 5 based on <span class="text-dark font-weight-bold ml-1">678 reviews </span> <img className="GoogleLogo" src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="PR_Section1">
                <div className="Workarea">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 p-0 offset-md-2">
                                <div className="Workareabox">
                                    <h3>24</h3>
                                    <p>Working Hours</p>
                                </div>
                            </div>
                            <div className="col-md-2 p-0">
                                <div className="Workareabox">
                                    <h3>30000 +</h3>
                                    <p>No. of Clients</p>
                                </div>
                            </div>
                            <div className="col-md-2 p-0">
                                <div className="Workareabox">
                                    <h3>2 Lacs</h3>
                                    <p>Completed Policies</p>
                                </div>
                            </div>
                            <div className="col-md-2 p-0">
                                <div className="Workareabox">
                                    <h3>50</h3>
                                    <p>Team Member</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default Dedicated;
