/* eslint-disable */
import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap'
import { Accordion, Card } from "react-bootstrap";
import { ChildBtn } from '../ButtonClass';
class PlanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: true,
        }
        this.close = this.close.bind(this)
    }
    close() {
        this.setState({ btnState: false })
    }
    render() {

        return (

            this.state.btnState ?
                <div className="HealthSeeDetails">
                    <div className="commenHealthPP newModalqt">
                        <div className="modalcontent">
                            <button type="button" class="close closeBack" onClick={this.close} ><i class="fas fa-arrow-left"></i></button>                            <div className="modal-header ui-draggable-handle">

                                <div className="commenHealthPPHead">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img className="popImg" src={this.props.src} />
                                        </div>
                                        <div className="col-md-8 text-right">
                                            <div className="premiumBox">
                                                <span className="sumInsuredTTLPOP">Sum Assured <br /><b className="ng-binding">₹{this.props.sumInsured}</b></span>
                                                <span className="sumInsuredTTLPOP">Premium/Yr <br /><b className="ng-binding">₹{Math.round(this.props.premiumPerYear)}</b></span>
                                                <a className={ChildBtn() + " w-25"}>
                                                    <span className="buyme ng-binding">Buy Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <span className="HealthPlanName">{this.props.planName}</span>
                                <Tabs defaultActiveKey="Features" id="uncontrolled-tab-example">
                                    <Tab eventKey="Features" title="Features">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="featurDetails">
                                                    <h3>Features</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="featurDetails featurDetailsPopup">
                                                    <h4>Main Features</h4>
                                                    <div className="FeatureBoxesMain">
                                                        {this.props.mainFeatures != null &&
                                                            this.props.mainFeatures.map((row) => 
                                                                <React.Fragment>
                                                                    {row.Features.match("Hospital Room") && <div className="FeatureBoxes d-flex align-items-center">
                                                                        <img src={require("../Contents/img/hospitalRoom.png")} />
                                                                        <div className="FeatureBoxesCnt">
                                                                            <h1>Room rent:</h1>
                                                                            <p>{row.Value}</p>
                                                                        </div>
                                                                        <div className="FeatureBoxesMassg">
                                                                            <div className="QuestionPopup">
                                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                                <div className="QuestionPopupInner">
                                                                                    <div className="head ng-binding">

                                                                                    </div>
                                                                                    <div className="body">
                                                                                        <p className="ng-binding">{ row.Value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}

                                                                    {row.Features.match("Share Claim") && <div className="FeatureBoxes d-flex align-items-center">
                                                                        <img src={require("../Contents/img/Copay.png")} />
                                                                        <div className="FeatureBoxesCnt">
                                                                            <h1>Copay:</h1>
                                                                            <p>{ row.Value}</p>
                                                                        </div>
                                                                        <div className="FeatureBoxesMassg">
                                                                            <div className="QuestionPopup">
                                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                                <div className="QuestionPopupInner">
                                                                                    <div className="head ng-binding">

                                                                                    </div>
                                                                                    <div className="body">
                                                                                        <p className="ng-binding">{ row.Value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {row.Value.match("waiting") && <div className="FeatureBoxes d-flex align-items-center">
                                                                        <img src={require("../Contents/img/WaitingPeriod.png")} />
                                                                        <div className="FeatureBoxesCnt">
                                                                            <h1>Waiting period:</h1>
                                                                            <p>{ row.Value}</p>
                                                                        </div>
                                                                        <div className="FeatureBoxesMassg">
                                                                            <div className="QuestionPopup">
                                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                                <div className="QuestionPopupInner">
                                                                                    <div className="head ng-binding">

                                                                                    </div>
                                                                                    <div className="body">
                                                                                        <p className="ng-binding">{ row.Value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {row.Features.match("Bonus on No") && <div className="FeatureBoxes d-flex align-items-center">
                                                                        <img src={require("../Contents/img/Renewel.png")} />
                                                                        <div className="FeatureBoxesCnt">
                                                                            <h1>Renewal Bonus:</h1>
                                                                            <p>{ row.Value}</p>
                                                                        </div>
                                                                        <div className="FeatureBoxesMassg">
                                                                            <div className="QuestionPopup">
                                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                                <div className="QuestionPopupInner">
                                                                                    <div className="head ng-binding">

                                                                                    </div>
                                                                                    <div className="body">
                                                                                        <p className="ng-binding">{ row.Value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {row.Features.match("Check") && <div className="FeatureBoxes d-flex align-items-center">
                                                                        <img src={require("../Contents/img/HealthCheckup.png")} />
                                                                        <div className="FeatureBoxesCnt">
                                                                            <h1>Health Checkup:</h1>
                                                                            <p>{row.Value}</p>
                                                                        </div>
                                                                        <div className="FeatureBoxesMassg">
                                                                            <div className="QuestionPopup">
                                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                                <div className="QuestionPopupInner">
                                                                                    <div className="head ng-binding">

                                                                                    </div>
                                                                                    <div className="body">
                                                                                        <p className="ng-binding">{ row.Value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                            </React.Fragment>
                                                            )}
                                                    </div>
                                                   {/* <ul>
                                                        {this.props.mainFeatures != null ?
                                                            this.props.mainFeatures.map((row) => {
                                                                return (<li style={{ listStyle: "circle" }} ng-repeat="Features in EachModel.MainFeatures" className="ng-scope">
                                                                    <span className="ng-binding">{row.Features} : {row.Value}</span>
                                                                    <div className="QuestionPopup">
                                                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                        <div className="QuestionPopupInner">
                                                                            <div className="head ng-binding">
                                                                                {row.Features}
                                                                            </div>
                                                                            <div className="body">
                                                                                <p className="ng-binding">{row.Value}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>)
                                                            }) : null}
                                                    </ul>*/}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="featurDetails featurDetailsPopup">
                                                    <h4>Main Features</h4>
                                                    <ul class="feature-list feature-list--lg feature-list--marked">
                                                        <li class="available">
                                                            <span>Recovery benefit</span>
                                                        </li>
                                                        <li class="available">
                                                            <span>E-Opinion on Critical illness</span>
                                                        </li>
                                                        <li class="available">
                                                            <span>Chronic Management Program</span>
                                                        </li>
                                                        <li class="available">
                                                            <span>Health returns for healthy heart score</span>
                                                        </li>
                                                        <li class="available">
                                                            <span>Wellness Coach</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Covered" title="Covered">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="featurDetails">
                                                    <h3>Coverage</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="featurDetails featurDetailsPopup featurDetailsPopupYes">
                                                    <h4>Covered</h4>
                                                    <ul>
                                                        {this.props.planDetails != null ?
                                                            this.props.planDetails.map((row) => {
                                                                return (row.Covered &&
                                                                    <li style={{ listStyle: "circle" }} ng-repeat="Features in EachModel.PlanFeatures" className="ng-scope">
                                                                        <span className="ng-binding">{row.Features} </span>
                                                                        <div className="QuestionPopup">
                                                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                            <div className="QuestionPopupInner">
                                                                                <div className="head ng-binding">
                                                                                    {row.Features}
                                                                                </div>
                                                                                <div className="body">
                                                                                    <p className="ng-binding">{row.Value}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }) : null}
                                                    </ul>

                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="featurDetails featurDetailsPopup featurDetailsPopupNo">
                                                    <h4>Not Covered</h4>
                                                    <ul>
                                                        {this.props.planDetails != null ?
                                                            this.props.planDetails.map((row) => {
                                                                return (!row.Covered &&
                                                                    <li style={{ listStyle: "circle" }} ng-repeat="Features in EachModel.PlanFeatures" className="ng-scope">
                                                                        <span className="ng-binding">{row.Features}</span>
                                                                        <div className="QuestionPopup">
                                                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                                            <div className="QuestionPopupInner">
                                                                                <div className="head ng-binding">
                                                                                    {row.Features}
                                                                                </div>
                                                                                <div className="body">
                                                                                    <p className="ng-binding">{row.Value}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }) : null}
                                                    </ul>

                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="featurDetails featurDetailsPopup">
                                                    <h4>Waiting Period</h4>
                                                    <p><strong>Covered since day 1</strong>Accidental Injuries</p>
                                                    <p><strong>30 days cooling period</strong>No non-accidental medical condition will be covered for first 30 days</p>
                                                    <p><strong>Covered after 2 years</strong>Prostrate Condition, Joint Surgeries -
                                                            Degenerative, Cataract, Piles, Varicose Veins,
                                                            Genito-Urinary Ailments, Slipped disc, Sinus Related Ailments etc</p>

                                                    <h4>Exclusions</h4>
                                                    <p>
                                                        <span>War related injuries,</span>
                                                        <span>Cosmetic surgery,</span>
                                                        <span>Lasik eye treatments,</span>
                                                        <span>Drugs or alcohol abuse,</span>
                                                        <span>Administratives expenses,</span>
                                                        <span>Registration fees</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Claim Process" title="Claim Process">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="featurDetails">
                                                    <h3>Claim Process</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="featurDetails featurDetailsPopup">
                                                    <h4>ASSISTANCE</h4>
                                                    <Accordion class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/Contact.png")} />
                                                                </div>
                                                                <span>STEP:1</span>
                                                                Contact Us
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    The customer will contact Via Email / Calls for any of the above-mentioned queries.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/ClaimIntiment.png")} />
                                                                </div>
                                                                <span>STEP:2</span>Claim Intimation
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    The Service Team will make a note of the customer and claim details. The same would be then forwarded to the Insurer for further action.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/Process.png")} />
                                                                </div>
                                                                <span>STEP:3</span>Insurer Processes
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="2">
                                                                <Card.Body>
                                                                    The Insurer takes action - ( Claim Intimated, Documents received, Cashless Approval given, etc )
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/CustomerIntiment.png")} />
                                                                </div>
                                                                <span>STEP:4</span>Customer Intimation
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="3">
                                                                <Card.Body>
                                                                    The Insurer's decision will be informed to the customer and as per the request raised the Service team will guide the customer for further claim settlement.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/FollowUp.png")} />
                                                                </div>
                                                                <span>STEP:5</span>Follow up with Customer & Insurers
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="4">
                                                                <Card.Body>
                                                                    Any pending dependency from the customer shall be conveyed to him/her and likewise, it will be forwarded to the Insurer again.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                                                <div className="ClaimProcessIcon">
                                                                    <img src={require("../Contents/img/Renewel.png")} />
                                                                </div>
                                                                <span>STEP:6</span>Claim Settlements
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="5">
                                                                <Card.Body>
                                                                    Post all the above procedure is done, the claim stands settled when the customer gets cashless approval or reimbursement amount is received.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="featurDetails accordionRight featurDetailsPopup">
                                                    <h4>FROM INSURER</h4>
                                                    <Accordion>
                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                Contact Us
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    The customer will contact Via Email / Calls for any of the above-mentioned queries.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card class="accordion-item">
                                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                                Claim Intimation
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    The Service Team will make a note of the customer and claim details. The same would be then forwarded to the Insurer for further action.
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                    <div className="accordionRightDownBox">
                                                        <p><span>Note:</span> You can find all your details and documents stored safely in My Account</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab eventKey="Network" title={`Network Hospitals ${this.props.networkHospitals == null ? "0" : this.props.networkHospitals.length}`}>
                                        <div className="HospitalList featurDetails featurDetailsPopup">
                                            <h3>
                                                Cashless treatement available at <strong className="ng-binding">{this.props.networkHospitals == null ? "0" : this.props.networkHospitals.length} Hospitals in your area</strong>
                                            </h3>
                                            {this.props.networkHospitals !== null && this.props.networkHospitals.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sr. No</th>
                                                        <th scope="col">Hospital Name</th>
                                                        <th scope="col">Mobile Number</th>
                                                        <th scope="col">Contact Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.props.networkHospitals.map((row, i) => {
                                                            return <tr>
                                                                <td scope="row">{i + 1}</td>
                                                                <td>{row.Hospital_Name}</td>
                                                                <td>{row.Mobile}</td>
                                                                <td>{row.Contact_Details}</td>
                                                            </tr>
                                                        })}</tbody>
                                            </table> : null}


                                        </div>
                                    </Tab>

                                </Tabs>


                            </div>
                            <div className="modal-footer">
                                <a target="_blank" href={this.props.brouchure}>Download Brouchure</a>
                            </div>
                        </div>
                    </div>
                </div> : null
        )
    }
}
export default PlanDetails;