import React, { useEffect, useState } from "react";
import { ChildBtn } from "../ButtonClass";
const OtpPopup = (props) => {
    const { initialMinute = 3, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [IsshowPass, setIsShow] = useState(false)

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div
            id="shareModel"
            className="modal ui-draggable show LatestOTPUi"
        >

            <section class="section wizard-section">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-3 col-md-2 col-lg-1 ForBimaStreet" style={{ display: "none" }}>
                            <a class="arw-btn" onClick={props.close}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                </svg>
                            </a>
                        </div>
                        <div class="col-9 col-md-10 col-lg-3 position-relative ForBimaStreet" style={{ display: "none" }}>
                            <div class="row">
                                <div class="col-lg-8">
                                    <h3 class="f-32 font-regular text-secondary text-capitalize">Last step : Enter verification code</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="wizard-box box d-flex flex-column justify-content-between">
                                <div className="modal-content SharePrpsl">
                                    <div className="modal-header text-center ui-draggable-handle">
                                        <button type="button" id="otpClose" className="close" data-dismiss="modal"
                                            aria-hidden="true" id="btnPopupHide3" onClick={props.close}>×</button>
                                        <h2>Send OTP</h2>
                                    </div>
                                    {/* <form className="ng-pristine ng-valid"> */}
                                    <div className="modal-body">
                                        <div className="shareBySMS">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label for="" class="form-label">Enter verification code</label>
                                                </div>
                                            </div>
                                            <div className="eyeSet">
                                                <label>
                                                    <input type={IsshowPass ? "tel" : "password"}
                                                        className="packageForm"
                                                        placeholder="OTP Number"
                                                        max="6"
                                                        maxLength="6"
                                                        name="OTP"
                                                        onChange={props.handle}
                                                    />
                                                    <i onClick={() => setIsShow(!IsshowPass)} className="fas fa-eye"></i>
                                                </label>


                                                <div class="wizard-bottom">
                                                    <button class="btn btn-blue w-100" type="button"
                                                        id="confirmOTP"
                                                        value="Confirm"
                                                        onClick={props.submit}
                                                        className={ChildBtn()}
                                                        style={{ display: "block" }}>Submit <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p>OTP sent your mobile number</p>
                                        <div>
                                            {minutes === 0 && seconds === 0 ? (
                                                <button
                                                    id="payNow"
                                                    onClick={props.submit}
                                                >
                                                    Resend OTP
                                                </button>
                                            )
                                                :
                                                (
                                                    <div>

                                                        <p>
                                                            otp expires in:{" "}
                                                            <span style={{ fontWeight: "bold", color: "blue" }}>
                                                                <i className="far fa-clock"></i> {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                                                            </span>
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>

                            </div>



                        </div>
                    </div>



                    <section class="f-14 ForBimaStreet" style={{ display: "none" }}>
                        <div class="row mt-5">
                            <div class="col-md-11 offset-lg-1">
                                Copyright 2020 - All right Reserved
                </div>
                        </div>

                    </section>

                </div>
            </section>
        </div>


    );
};
export default OtpPopup;
