import React from "react";
const Product = [
    {
        id: 1,
        name: "Car Insurance",
        to: "/Car",
        className: "nav-item nav-link",
        src: "fa-car"
    },
    {
        id: 2,
        name: "2 Wheeler Insurance",
        to: "/Bike",
        className: "productTabsRisk",
        src: "fa-motorcycle"
    },

    {
        id: 3,
        name: "PCV Buses/Taxies",
        className: "productTabsRisk",
        to: "/CommercialInner",
        src: "fa-taxi"
    },
    {
        id: 4,
        name: "GCV Trucks/Goods",
        className: "productTabsRisk",
        to: "/CommercialInner",
        src: "fa-truck"
    },
    {
        id: 5,
        name: "Health Insurance",
        className: "productTabsRisk",
        to: "/Health",
        src: "fa-heartbeat"
    },
    {
        id: 6,
        name: "Life Insurance",
        className: "productTabsRisk",
        to: "/Life",
        src: "fa-procedures"
    },
];
export default Product;
