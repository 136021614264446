/* eslint-disable */
import React from "react";
const ContactCertificate = (props) => {
    return (
        <section class="text-center letter">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0">
                        <div class="letter-box">
                            <p><span>Dear valued customer,</span></p>
                            <p>Thank you for placing your trust in us.</p>
                            <p>At <span>HERO</span>, we leave no stone unturned to meet our customers' expectations. We believe that if we direct our efforts towards earning customers, the business will always take care of itself.</p>
                            <p>Therefore, providing you with the best insurance products and a top-notch customer service experience has always been of first and foremost priority to us. That's how we've managed to garner the trust of our valued customers over the years, and we wish to carry forward the HERO legacy in the best possible way.</p>
                            <p>We strive to build a long-term association with our customers, and this is only possible by offering a quality customer service. Thus, in case of any query or concern, do not hesitate to get it touch with us. We would be more than happy to assist you.</p>
                            <p class="signature">
                                <span><i>Mr. Sanjay Radhakrishnan</i><br /> CEO </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactCertificate;
