import React from "react";
const Expertise = (props) => {
    return (
        <section className="pt-5 pb-5">
            <div className="container marketing">
                <div className="row">
                    <div className="col-lg-4 text-center">
                        <div className="features_box p-3">
                            <div className="features_desc mt-3 pt-3">
                                <img className="w-auto mb-3" src={require("../assets/img/LifeMart/earn-commission.jpg")} className="rounded-circle" width="75" height="75" />
                                <h6>Earn Commission</h6>
                                <p className="text-muted mt-3">Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <div className="features_box p-3">
                            <div className="features_desc mt-3 pt-3">
                                <img className="w-auto mb-3" src={require("../assets/img/LifeMart/irda.jpg")} className="rounded-circle" width="75" height="75" />
                                <h6>IRDA Approved</h6>
                                <p className="text-muted mt-3">Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <div className="features_box p-3">
                            <div className="features_desc mt-3 pt-3">
                                <img className="w-auto mb-3" src={require("../assets/img/LifeMart/no-paper.jpg")} className="rounded-circle" width="75" height="75" />
                                <h6>No Paper Work</h6>
                                <p className="text-muted mt-3">Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
