import React from "react";
const Product = [

    {
        id: 1,
        name: "Life",
        className: "productTabsRisk",
        to: "/",
        src: "fa-heartbeat"
    },
  {
    id: 2,
    name: "Health",
    className: "productTabsRisk",
    to: "/Health",
      src: "fa-briefcase-medical"
  },
  
    {
        id: 3,
        name: "Car",
        to: "/Car",
        className: "nav-item nav-link",
        src: "fa-car"
    },
    {
        id: 4,
        name: "2 Wheeler",
        to: "/Bike",
        className: "productTabsRisk",
        src: "fa-motorcycle"
    },

];
export default Product;
