import React from "react";
import Services from "./services/Services";
import Features from "./Features";
import OurPartner from "./OurPartner";
import Trust from "./Trust";
import ChooseUs from "./ChooseUs";
import Policies from "./Policies";
import Advantages from "./advantages/Advantages";
import Testimonial from "./Testimonial";
import Accordian from "./Accordian";
import Tab from "./HomeTab/Tab";
const HeroMiddleSection = () => {
  return (
    <React.Fragment>
    <Tab/>
      <Trust />
      <ChooseUs />
      <Policies />
      <Advantages />
      <Testimonial />
      <OurPartner />
      <Accordian />
    </React.Fragment>
  );
};
export default HeroMiddleSection;
