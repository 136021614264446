import React from 'react';
import { Link } from 'react-router-dom';
const Tnx = () => {
    return (
        <div className="jumbotron text-center" style={{ marginTop: "50px" }}>
            <h1 className="display-3">Thank You!</h1>
            <p className="lead">Please check your email for further instructions on how to complete your account setup.</p>
            <hr />
            <p>
                Thanks for being you.
                </p>
            <p className="lead">
                <Link className="btn btn-primary btn-sm" to="/" role="button">Continue to homepage</Link>
            </p>
        </div>
    )
}
export default Tnx;