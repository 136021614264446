import React from "react";

const About = () => {
  return (
    <React.Fragment>
          <section>
              <div class="container">
                  <div class="row aboutContent">
                      <div class="col-md-4">
                          <div class="ShadowMain">
                              <img src={require("./assets/img/RFL/AboutBannerOne.png")} />
                          </div>
                      </div>
                      <div class="col-md-8">
                          <div class="ShadowMain">
                              <h1>About Us</h1>
                              
                              <p class="text-justify">
With more than 50+ products to choose from, RiskFreeLife strives to find ideal insurance plan for you. We are an IRDA registered Insurance Broking company that offers you the facility to buy insurance seamlessly. 
<br />RFL works actively to furnish detailed information about its insurance products, which help you to make an informed choice. Our bouquet of insurance products ranges from motor, life, travel, health, liability, industries, event, pet, and more.
                    </p>

                            
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </React.Fragment>
  );
};
export default About;
