import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask'
import ButtonClass from '../../ButtonClass';
import ContinueToLife from '../../components/ContinueToLife';
import CustomFact from '../../Life/CustomFact';
import LifeHealthDetails from './LifeHealthDetails';
const BimastreetLife = (props) => {
    console.log("props: ", props);
    let errors = props.errors
    let date = new Date();
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let yy = date.getFullYear();
    let MaxDate = yy - 18 + "-" + mm + "-" + dd;
    let currDate = yy + "-" + mm + "-" + dd;
    const [isFound, setIsFound] = useState(false);
    const [Req, setReq] = useState(null);


    useEffect(() => {
        let LifeReq = window.localStorage.getItem("LifeReq");
        if (LifeReq !== null) {
            LifeReq = JSON.parse(LifeReq);
            
            setReq(LifeReq)
            setIsFound(true);
        }
    }, []);

    const [firstSec, setFirstSec] = useState(!props.showPopUp);
    const [secondSec, setSecond] = useState(props.showPopUp);
    const [displaySec, setDisplay] = useState(true)
    function manageSection() {
        setFirstSec(!firstSec);
        setSecond(!secondSec)
    }
    function setPopup() {
        
        return props.CallPopUp();
        
    }
    return (


       
            <div>


            {firstSec &&
                < section class="section wizard-section">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-3 col-md-2 col-lg-1">
                            <a onClick={props.bimaClose} class="arw-btn">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                </svg>
                            </a>
                        </div>
                        <div class="col-9 col-md-10 col-lg-3 position-relative">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h3 class="f-32 font-regular text-secondary text-capitalize">Please Enter Your Details</h3>
                                </div>
                            </div>
                            <div class="pageno d-none d-lg-flex"><span>01</span> &nbsp;of 05</div>
                        </div>
                        <div class="col-lg-8">

                            <div class="wizard-box box d-flex flex-column">
                               {/* {isFound ? <ContinueToLife
                                    gender={Req.Gender}
                                    dob={Req.DOB}
                                    fullName={Req.FullName}
                                    mobile={Req.Mobile}
                                    closeContinue={() => this.setState({ IsFound: false })}
                                    proceed={() => this.setState({ RedirectToQuote: true })}
                                /> : (*/}
                                        <div>
                                            <div>
                                                <label className="ForDesktop">
                                                    <select name="Gender" id="drpGender" value={props.Gender} onChange={props.handle}>
                                                        <option value="Select">Select Gender</option>
                                                        <option value="M">Male</option>
                                                        <option value="F">Female</option>
                                                    </select>
                                                </label>
                                                <span className="error">{errors.Gender}</span>
                                                <label className="ForMobileApp">
                                                    <div className="GenderApp Male">
                                                        <input type="radio" id="rblMale" value="M" className={props.Gender == "M" ? "active" : ""} name="Gender" onChange={props.handle} />
                                                        <span><small>Male</small></span>
                                                    </div>
                                                    <div className="GenderApp FeMale">
                                                        <input type="radio" id="rblFemale" value="F" className={props.Gender == "F" ? "active" : ""} name="Gender" onChange={props.handle} />
                                                        <span><small>Female</small></span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="ProductFormIn ProductFormInQuote">
                                                <div className="HealthCustomAgeBox">
                                                    <p className="HeadingTitleBima">Select Members</p>
                                                    <div className="FormRow">
                                                        <div className="col-50">
                                                            <div className="form-control">
                                                                <label className="d-block">
                                                                    <span>
                                                                        <input type="date" max={MaxDate} defaultValue={MaxDate} name="DOB" onChange={props.handle} value={props.DOB} id="textDOB" required="required" placeholder="DD/MM/YYYY" />
                                                                    </span>
                                                                </label>
                                                                <span className="error">{errors.DOB}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    

                            </div>
                            <div class="wizard-bottom">


                                <button className="btn btn-blue w-100" id="VahanCheck" onClick={manageSection}>
                                    Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                    </svg>
                                </button>
                            </div>


                        </div>
                    </div>



                    <section class="f-14">
                        <div class="row mt-5">
                            <div class="col-md-11 offset-lg-1">
                                Copyright 2020 - All right Reserved
                </div>
                        </div>

                    </section>

                </div>
            </section>
            }
            {secondSec && !props.showPopUp &&

                <section class="section wizard-section" style={{ display: displaySec ? "inherit" : "none" }} >
                    <div class="container">
                        <div class="row g-3">
                        <div class="col-3 col-md-2 col-lg-1">
                            <a onClick={manageSection} class="arw-btn">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                    </svg>
                                </a>
                            </div>
                            <div class="col-9 col-md-10 col-lg-3 position-relative">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h3 class="f-32 font-regular text-secondary text-capitalize">Enter Your Name and Mobile Number</h3>
                                    </div>
                                </div>
                                <div class="pageno d-none d-lg-flex"><span>02</span> &nbsp;of 05</div>
                            </div>
                            <div class="col-lg-8">
                                <div class="wizard-box box d-flex flex-column">


                                    <div className="row">
                                        <div className="col-md-6">
                                            <MaskedInput id="txtFirstName" className="form-control" value={props.FullName} onChange={props.handle} name="FullName" placeholder="Enter Full Name"
                                                mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} />

                                            <span className="error">{errors.FullName}</span>
                                        </div>

                                        <div className="col-md-6">
                                            <MaskedInput name="Mobile" className="form-control" value={props.Mobile} onChange={props.handle} mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" required="required" placeholder="+91"
                                                guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                            <span className="error">{errors.Mobile}</span>

                                        </div>
                                    </div>
                                </div>
                                <div class="wizard-bottom">


                                <button className="btn btn-blue w-100" id="VahanCheck" onClick={setPopup}>
                                        Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                        </svg>
                                    </button>

                                </div>

                            </div>
                        </div>



                        <section class="f-14">
                            <div class="row mt-5">
                                <div class="col-md-11 offset-lg-1">
                                    Copyright 2020 - All right Reserved
                </div>
                            </div>

                        </section>

                    </div>
                </section>
            }
            {props.showPopUp &&
                <LifeHealthDetails handle={props.handle}
                    SmokeStatus={props.SmokeStatus} close={props.close}
                    AnnualIncome={props.AnnualIncome} SumInsured={props.SumInsured} btnValue={props.btnValue}
                    Coverage={props.Coverage} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList} />
            }
        </div>


    );
}


export default BimastreetLife;