import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";
import "./assets/css/BimaStreet.css";
import Epartner from "./Epartner";
import Contact from "./Contact";

import BimaStreetMiddleSection from "./BimaStreetMiddleSection";
import { NavMenu } from "./NavMenu";
import PrivateCar from "./FooterPages/PrivateCar";
import PassengerCarrying from "./FooterPages/PassengerCarrying";
import Mediclaim from "./FooterPages/Mediclaim";
import Term from "./FooterPages/Term";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import ProductList from "./HomeTab/ProductList";
import { useState } from "react";
import CarInsurance from "./ProductPages/CarInsurance";
import BikeInsurance from "./ProductPages/BikeInsurance";
import HealthInsurance from "./ProductPages/HealthInsurance";
import LifeInsurance from "./ProductPages/LifeInsurance";
import LifeProposal from "../Life/LifeProposal";
import CarClaims from "./Claim/CarClaims";
import BikeClaims from "./Claim/BikeClaims";
import LifeClaims from "./Claim/LifeClaims";
import HealthClaims from "./Claim/HealthClaims";

const findPath=()=>{
    let url=window.location.href.toLowerCase();
    let path="HRIKLS";
    if(url.match("proposalmotor") || url.match("proposalhealth"))
    path=window.location.pathname
    return path;
  }
const BimaStreet = (props) => {
  
    let apiPath = findPath();
    //document.getElementById("BimaFooter").style.display = "block";
    return (
        <React.Fragment>
            <div className="BimaStreet">
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />



                </Layout>

                <Switch>

                    <Route exact path="/" component={BimaStreetMiddleSection} />
                    <Route exact path="/FourWheelerInsurance" component={PrivateCar} />
                    <Route exact path="/TwoWheelerInsurance" component={PassengerCarrying} />
                    <Route exact path="/HealthInsurance" component={Mediclaim} />
                    <Route exact path="/TermLifeInsurance" component={Term} />
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route exact path="/LifeProposal" component={LifeProposal} />
                    <Route exact path="/GetQuoteLife" component={LifeQuote} />
                    <Route exact path="/SelectInsuranceProduct" component={ProductList} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    
                    

                    <Route path="/About" component={About} />
 
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apiPath} component={PaymentSuccessOrFail} />
                    <Route exact path="/Car-Insurance" component={CarInsurance} />
                    <Route exact path="/Bike-Insurance" component={BikeInsurance} />
                    <Route exact path="/Health-Insurance" component={HealthInsurance} />
                    <Route exact path="/Life-Insurance" component={LifeInsurance} />
                    <Route exact path="/Car-free-claims-assistance" component={CarClaims} />
                    <Route exact path="/Bike-free-claims-assistance" component={BikeClaims} />
                    <Route exact path="/Life-free-claims-assistance" component={LifeClaims} />
                    <Route exact path="/Health-free-claims-assistance" component={HealthClaims} />
                </Switch>


                
                <SideNav />
                <SocialToggle mobile="9676671888" email="care@bimastreet.com" whatsapp="9676671888" />
            </div>
        </React.Fragment>
    );
};
export default BimaStreet;
