/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
import ButtonClass from "../ButtonClass";
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <React.Fragment>


                <section class="landing-hero inner-hero-section">



                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <h2 class="text-secondary">
                                    Let's Start a Conversation
                    </h2>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <p>You'll be talking to a Human. You wont hit a ridiculously long phone menu. You emails will be
                                promptly responded. We at Bimastreet provide an exceptional customer service we'd expect
                        ourselves.</p>

                            </div>
                        </div>

                    </div>
                    <div class="hero-img">
                        <img src={require("./assets/img/BimaStreet/contact-hero.svg")} class="img-fluid" />
                    </div>
                </section>




                <section class="section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <h5 class="text-secondary mb-4">Let Us Help</h5>

                                <h6 class="font-regular mb-3">9676671888/9391009482</h6>

                                <h6 class="font-regular">care@bimastreet.com</h6>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="wizard-box2 w-100">
                                            <div class="row gy-4">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                            <select className="form-control" id="Interested" name="typeList" onChange={this.handle}>
                                                                <option value="">Interested In</option>
                                                                <option value="Car Insurance">Car Insurance</option>
                                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                                <option value="Health Insurance">Health Insurance</option>
                                                                <option value="Travel Insurance">Travel Insurance</option>
                                                            </select>

                                                    </div>
                                                    <span className="txtError">{this.state.errors["typeList"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                    </div>
                                                    <span className="txtError">{this.state.errors["MobileNo"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                            <select className="form-control" name="time" onChange={this.handle}>
                                                                <option value="Preferred time">Preferred time</option>
                                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                                            </select>
                                                    </div>
                                                    <span className="txtError">{this.state.errors["time"]}</span>
                                                </div>
                                                
                                            </div>
                                            <a href="#" class="btn btn-primary mt-5" onClick={this.handleSubmit}>File your claim
                                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <div class="map-section">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d973.8153321445133!2d78.46912328176192!3d17.44571641615683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6e5314445adadd92!2sAmaze%20Insurance%20Brokers%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1633774784419!5m2!1sen!2sin" style={{ border: "0" }} allowfullscreen="" loading="lazy" width="100%" height="400px"></iframe>
                </div>





                <section class="section bg-secondary text-white call-for-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3">
                                <h2>Contact us</h2>
                            </div>
                            <div class="col-md-5">
                                <h6> We work round the clock to provide unparalleled customer experience. From customising new
                                insurance
                                policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                                step of the way.
                    </h6>
                            </div>
                            <div class="col-md-3 offset-md-1">
                                <a href="#" class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                    viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                        <path
                                            d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                            stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                        <path
                                            d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                            stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                        <path
                                            d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                            stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="16" height="16" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>

                                </a>
                                <a href="#" class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                    viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                    <path
                                        d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                        stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>

                                </a>
                            </div>
                        </div>
                    </div>
                </section>



            </React.Fragment>
        );
    };
}
export default Contact;
