import React from "react";

const About = () => {
  return (
    <React.Fragment>

          <section class="about-ss ProductPageSectionFirst">
              <div class="container">
                  <div class="SecondSectioninner">
                      <h3 class="aboutTitle">About us</h3>
                      <p>
                          SANSOM Insurance is one of the well-known Insurance Brokers , habitually for the clients present across Pan India and also balanced with the finest blend of Life and Non-Life Insurance business.

            </p>
                      <p>
                          We offer our customers with the opportunity to compare premium rates for all leading Insurance players compare their prices, key features and make an well - versed buying decision
            </p>
                      <p>
                          Our technology enriched, smooth comparison and information which is user-friendly. We are committed in providing the best insurance solutions to our customers with the help of our team of experts. Our highly experienced expert team assists the customers with the best insurance deals, which is hassle free and best fit, in the quickest possible time.
            </p>
                  </div>
              </div>
          </section>

          <section class="OurVision">
              <div class="container">
                  <div class="row OurVisionRow">
                      <div class="col-md-6">
                          <div class="row">
                              <div class="col-md-12 text-center">
                                  <h3 class="aboutTitle">Our Vision</h3>
                                  <p>
                                      To have a positive impact on the lives of our customer and our team
                        </p>
                              </div>
                              <div class="col-md-12 text-center">
                                  <h3 class="aboutTitle">Our Mission</h3>
                                  <p>
                                      we grow and prosper together with our customers, by providing service of great value to them & to help every customer have a positive memorable experience.
                        </p>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-5">
                          <img src={require("./assets/img/Sansom/Mission-Vision-Values.jpg")} />
                      </div>
                  </div>
              </div>
          </section>

    </React.Fragment>
  );
};
export default About;
