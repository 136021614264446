import React from "react";
const HowWork = (props) => {
    return (
        <section className="work-area pt-100 pb-100 bg-green-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-heading text-left wow fadeInUp  animated">
                            <span className="section-meta ">Help</span>
                            <h1 className="section-title">How Does It Work?</h1>
                            <p className="section-sub">Lorem ipsum dolor sit amet</p>
                            <div className="section-divider">
                            </div>
                        </div>

                        <div className="row pt-30">
                            <div className="col-12">
                                <div className="points">
                                    <ul>
                                        <li><i className="las la-check"></i> Answer a few simple questions so we get to know you better.</li>
                                        <li><i className="las la-check"></i>Browse throught your curate dentist recommendations.</li>
                                        <li><i className="las la-check"></i>Easily schedule and manage your appoinments.</li>
                                        <li><i className="las la-check"></i> Answer a few simple questions so we get to know you better.</li>
                                        <li><i className="las la-check"></i>Browse throught your curate dentist recommendations.</li>
                                        <li><i className="las la-check"></i>Easily schedule and manage your appoinments.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-md-6">
                        <img src={require("../assets/img/DemoTemp/work.png")} alt="How Does It Work" className="work__bg wow fadeInRight  animated" />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HowWork;
