/* eslint-disable */
import React from 'react';
const PrivacyPolicy = () => {
    return (
        <section class="productCntMain">
            <div class="container">
                <p>Privacy Policy - riskfreelife.com (Site) recognizes the  importance of maintaining your privacy. Riskfreelife.com is committed to  maintain the confidentiality, integrity and security of all information of our  users. This Privacy Policy describes how riskfreelife.com collects and handles  certain information it may collect and/or receive from you via the use of this  Site. Please see below for details on what type of information we may collect  from you, how that information is used in connection with the Services offered  through our Site and other shared with our business partners. This Privacy  Policy applies to current and former visitors to our Site and to our online  customers. By visiting and/or using our Site, you agree to this Privacy Policy.<br />
                    Securing the data<br/>
                        <br />
                            In order to  ensure the security and confidentiality of personal data that RFL collects  online, RFL uses networks protected, inter alia, by industry standard firewall  and password protection. In the course of handling personal data, RFL takes  reasonable measures designed to protect such information from loss, misuse,  unauthorized access, disclosure, alteration or destruction. In addition, all  sensitive/credit information supplied by the customer is encrypted via Secure  Socket Layer (SSL) technology. </p>
                        <p>&nbsp;</p>
                        <p>Authorisation  for Contact<br />
                            By  using the riskfreelife.com and/or registering yourself at  www.riskfreelife.com.com you authorize riskfreelife.com (including its  representatives, affiliates, and business partners):</p>
                            <ul>
                                <li>To contact you  via email or phone call or SMS</li>
                                <li>Offer you  Services for the product you have opted for</li>
                                <li>Provide product  knowledge</li>
                                <li>Offer promotional  offers running on riskfreelife.com and offers by its business partners and  associated third parties.</li>
                            </ul>
                            <p>For the above mentioned reasons your  information may be collected in the manner as detailed under this Policy. You,  hereby, agree that you authorize riskfreelife.com to contact you for the above  mentioned purposes even if you have registered yourself under DND or DNC or  NCPR service(s). Your authorization, in this regard, shall be valid as long as  your account is not deactivated, either by you or us.<br />
                                This policy does not apply to the  practices of companies that riskfreelife.com does not own or control or to  people that riskfreelife.com does not employ or manage.<br />
                                    Retainers  of Personal Information<br />
                                        Your  personal data will be stored and collected by riskfreelife.com along with its  parent company which wholly owns riskfreelife.com.<br />
                                            Purpose  of User Data Collection<br />
                                                riskfreelife.com  collects your information when you register for an account, when you use its  products or Services, visit its Site pages, and when you enter promotions or  sweepstakes as featured on the Site. When you register with riskfreelife.com,  you are asked for your first name, last name, state and city of residence,  email address, date of birth, and sex. Once you register at the Site and sign  in you are not anonymous to us.<br />
                                                    Also, you are asked for your contact  number during registration and may be sent SMS notifications about our Services  to your wireless device. Hence, by registering you authorize riskfreelife.com  to send texts and email alerts to you with your login details and any other  Service requirements, including promotional mails and SMSs.<br />
                                                        Your information is used to:</p>
                                                    <ul>
                                                        <li>Respond to queries  or requests submitted by you</li>
                                                        <li>Process orders or  applications submitted by you</li>
                                                        <li>Administer or  otherwise carry out our obligations in relation to any agreement with our  business partners</li>
                                                        <li>Anticipate and  resolve problems with any Services supplied to you</li>
                                                        <li>Send you  information about special promotions or offers. We might also tell you about  new features or products. These might be our own offers or products, or  third-party offers or products with which riskfreelife.com has a tie-up</li>
                                                        <li>Improve the Site  and Services offered by riskfreelife.com. We may combine information we get  from you with information about you we get from third parties</li>
                                                        <li>Send you notice,  communications, offer alerts relevant to your use of the Services offered on  this Site</li>
                                                        <li>As otherwise  provided in this Privacy Policy.</li>
                                                    </ul>
                                                    <p>Some features of this Site or our  Services will require you to furnish your personally identifiable information  as provided by you under Your Account section on our Site.<br />
                                                        Confidentiality,  Information Sharing and Disclosure<br />
                                                            riskfreelife.com  will not sell or rent your information to anyone other than as set forth in  this Privacy Policy. Notwithstanding the foregoing, we may share your  information to an affiliate and/or business partner. riskfreelife.com may also  share, sell, and/or transfer your personally identifiable information to any  successor-in-interest as a result of a sale of any part of riskfreelife.com's  business or upon the merger, reorganization, or consolidation of  riskfreelife.com with another entity on a basis that riskfreelife.com is not  the surviving entity.<br />
                                                                We limit the collection and use of  your personal information. We may make anonymous or aggregate personal  information and disclose such data only in a non-personally identifiable  manner. Such information does not identify you individually. Access to your  Account information and any other personal identifiably information is strictly  restricted and used only in accordance with specific internal procedures, in  order to operate, develop or improve our Services. We may use third party  service providers to provide you with our Services and we ensure such third  parties to maintain the confidentiality of the information we provide to them  under our contracts with them.<br />
                                                                    Law  and Data Protection<br />
                                                                        We  may also share your information, without obtaining your prior written consent,  with government agencies mandated under the law to obtain information for the  purpose of verification of identity, or for prevention, detection,  investigation including cyber incidents, prosecution, and punishment of  offences, or where disclosure is necessary for compliance of a legal  obligation. Any Information may be required to be disclosed to any third party  by us by an order under the law for the time being in force.<br />
                                                                            Cookies<br />
                                                                                A  cookie is a piece of data stored on the user's computer tied to information  about the user. We may use both session ID cookies and persistent cookies. For  session ID cookies, once you close your browser or log out, the cookie  terminates and is erased. A persistent cookie is a small text file stored on  your computer&rsquo;s hard drive for an extended period of time. Session ID cookies  may be used by PRP to track user preferences while the user is visiting the  Site.<br />
                                                                                    They also help to minimize load times  and save on server processing. Persistent cookies may be used by PRP to store  whether, for example, you want your password remembered or not, and other  information. Cookies used on the PRP website do not contain personally  identifiable information.<br />
                                                                                        Log  Files<br />
                                                                                            Like  most standard websites, we use log files. This information may include internet  protocol (IP) addresses, browser type, internet service provider (ISP),  referring/exit pages, platform type, date/time stamp, and number of clicks to  analyze trends, administer the site, track user's movement in the aggregate,  and gather broad demographic information for aggregate use.<br />
                                                                                                We may combine this automatically  collected log information with other information we collect about you. We do  this to improve Services we offer to you, to improve marketing, analytics or  site functionality.<br />
                                                                                                    Unsubscribe<br />
                                                                                                        If  you are no longer interested in receiving e-mail announcements and other  marketing information from us, please e-mail your request. Please note that it  may take about 10 days to process your request.<br />
                                                                                                            Cyber  Security<br />
                                                                                                                We  employ appropriate technical and organizational security measures at all times  to protect the information we collect from you. We use multiple electronic,  procedural, and physical security measures to protect against unauthorized or  unlawful use or alteration of information, and against any accidental loss,  destruction, or damage to information.<br />
                                                                                                                    However, no method of transmission  over the Internet, or method of electronic storage, is 100% secure. Therefore,  we cannot guarantee its absolute security. Further, you are responsible for  maintaining the confidentiality and security of your login id and password as  we will not provide these credentials to any third party.<br />
                                                                                                                        Third  Party Advertising and Data Collected by Third Parties<br />
                                                                                                                            We  may use third-party advertising companies and/or ad agencies to serve ads when  you visit our Site. There are number of products and services such as  insurance, risk management offered by third parties on the Site, such as  lenders, banks, insurance companies, credit card issuers or others. These  companies may use information (excluding your name, address, email address, or  telephone number) about your visits to this Site in order to provide  advertisements on this Site and other third party websites about goods and  services that may be of interest to you. If you choose to apply for these  separate products or services, disclose information to these providers, then  their use of your information is governed by their privacy policies.  riskfreelife.com is not responsible for their privacy policies.<br />
                                                                                                                                We use third-party service providers  to serve ads on our behalf across the internet and sometimes on this Site. They  may collect anonymous information about your visits to the Site, and your  interaction with our products and Services. They may also use information about  your visits to this and other websites for targeted advertisements for goods  and services. This anonymous information is collected through the use of a  pixel tag, which is industry standard technology used by most major websites. No  personally identifiable information is collected or used in this process.<br />
                                                                                                                                    There might be affiliates or other  sites linked to riskfreelife.com. Personal information that you provide to  those sites are not our property. These affiliated sites may have different  privacy practices and we encourage you to read their privacy policies when you  visit them.<br />
                                                                                                                                        Changes  in Privacy Policy<br />
                                                                                                                                            riskfreelife.com  reserves the right to change this policy from time to time at its sole  discretion. We may update this privacy policy to reflect changes to our  information practices. We encourage you to periodically review the privacy  policy.<br />
                                                                                                                                                This  document is published electronically and does not need signatures. If you have  questions, concerns, or suggestions regarding our Privacy Policy, then visit  our contact us section to reach us.</p>

            </div>
        </section>
    )
}
export default PrivacyPolicy