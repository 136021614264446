import React from 'react';
import Partner from './Partner';
const OurExperts = (props) => {
    return (
        <section class="ourExperts">
            <div class="container">
                <h5 class="tbk__title" itemprop="headline">THE TEAM</h5>
                <h4 class="tbk__subtitle" itemprop="alternativeHeadline">Our Experts</h4>
                <div class="row">
                    <div class="col-md-3">
                        <div class="profile_card">
                            <img src={require("./assets/img/Advance/Team1.png")} />
                            <div class="profile_content" id="PFD1">
                                <h2>Mr. S.K Tonk</h2>
                                <h3>Director &amp; Principal Officer</h3>
                                <ul class="social-link">
                                    <li>
                                        He is young energetic with experience of more than 25 years in insurance sector.
                            </li>
                                </ul>
                            </div>
                            <div class="profileFullDetails PFD1">
                                <h3>
                                    Mr. S.K.Tonk (Director &amp; Principal Officer)
                        </h3>
                                <p>
                                    Is a Post Graduate in Public Administration. Young energetic having more than 29 years of experience in insurance industry. Has worked with Oriental Insurance Co Ltd., for more than 15 years and with other insurance intermediaries. Life Member Insurance Institute of India, IRDA Broker Exam passed. Has vast experience in Marketing and claim management
                        </p>
                                <span class="BackTT">Back To Profile</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="profile_card">
                            <img src={require("./assets/img/Advance/Team2.jpg")} />
                            <div class="profile_content" id="PFD2">
                                <h2>Mr. Joydeep Majumdar</h2>
                                <h3>Director</h3>
                                <ul class="social-link">
                                    <li>Having a sales experience of 21 years in Hospitality / Banking and Insurance.</li>
                                </ul>
                            </div>
                            <div class="profileFullDetails PFD2">
                                <h3>
                                    Mr. Joydeep Majumdar (Co- Founder &amp; Director)
                        </h3>
                                <p>
                                    Is a post graduate in Marketing/Sales. Having a combined sales experience of 22 years in Hospitality/Banking and Insurance sector.  Previously worked in Max life Insurance in capacity of Head pan India business operations in Alternate channels. Also worked in company like ANZ Grindlays bank, Standard Chartered Bank &amp; Kotak Mahindra Life Insurance. IRDA broker exam passed. Has key specialty in handling mass sales projects and operations
                        </p>
                                <span class="BackTT">Back To Profile</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="profile_card">
                            <img src={require("./assets/img/Advance/Team3.jpg")}/>
                            <div class="profile_content" id="PFD3">
                                <h2>Mrs. Manashi Chatterji</h2>
                                <h3>Director</h3>
                                <ul class="social-link">
                                    <li>Having a rich experience in export house and hospitality industry.</li>
                                </ul>
                            </div>
                            <div class="profileFullDetails PFD3">
                                <h3>
                                    Mrs. Manashi Chatterji  (Director)
                        </h3>
                                <p>
                                    Is a Master of Arts from Indore university and having a rich experience in export house and hospitality industry
                        </p>
                                <span class="BackTT">Back To Profile</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="profile_card">
                            <img src={require("./assets/img/Advance/Team4.png")} />
                            <div class="profile_content">
                                <h2>Mr.Nirmal Kumar Serawat</h2>
                                <h3>Director</h3>
                                <ul class="social-link">
                                    <li>is a post graduate from Rajasthan University in Political science and has been involved in sales and marketing of real estate and Insurance for last 20 years. He is a keen learner and has a good rapport in public dealings. He is also a IRDA broker certified person</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurExperts;