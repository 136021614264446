import React from "react";
import "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Consultation = (props) => {
    let settings = {
        slidesToShow: 4,

        autoplay: true,
        slidesToScroll: 1,
        infinite: true,

    };
    return (
        <div class="cta-section pad-tb bg-fixed-img" data-parallax="scroll" data-speed="0.5">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-8">
                        <div class="cta-heading">
                            <h2 class="mb20 text-w aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">Request a Free Consultation!</h2>
                            <p class="text-w aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper.</p>
                            <a href="#modal" data-toggle="modal" data-target="#modal_aside_right" class="btnpora btn-rd3 mt40 noshadow aos-init aos-animate" data-aos="fade-up" data-aos-delay="500"> Get your Quote</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Consultation;
