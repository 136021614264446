import React from "react";
const Dedicated = (props) => {
  return (
      <section className="FirstSection section">
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <div className="FirstInfoInsured">
                          <h3 className="titleWithFont">
                              Are You Covered? <br />Compare different insurance policies
                    </h3>
                          <p>
                              Get your claims settled at ease<br />
                        Get Best rates in all types of insurance<br />
                        Get yourself insured in 5 minutes*
                    </p>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="FirstSectionCntBox">
                          <img src={require("../assets/img/RFL/insurance-06.png")} />
                      </div>
                  </div>
                  <div className="col-md-12">
                      <div className="FirstSectionCntBox002">
                          <h3 className="titleWithFont">Get Instant Free quote </h3>
                      </div>
                  </div>
              </div>

              <div className="row">
                  <div className="col-lg-4 text-center">
                      <div className="FirstSectionCntBox003">
                          <img src={require("../assets/img/RFL/HlthIcon1.png")} />
                          <h3 className="titleWithFont">Super-Simple claims</h3>
                          <p>We have a qualified,experienced team for claims,they ensure our customer gets a hassle free and smooth claim settlement.</p>
                      </div>
                  </div>
                  <div className="col-lg-4 text-center">
                      <div className="FirstSectionCntBox003">
                          <img src={require("../assets/img/RFL/HlthIcon2.png")} />
                          <h3 className="titleWithFont">Loved by Customers</h3>
                          <p>Customer satisfaction is our motto,we have a expert customer service team that gives best before and after sales service to all the customers</p>
                      </div>
                  </div>
                  <div className="col-lg-4 text-center">
                      <div className="FirstSectionCntBox003">
                          <img src={require("../assets/img/RFL/HlthIcon3.png")} />
                          <h3 className="titleWithFont">More TLC, Less T&C</h3>
                          <p>We believe Transparency is a key way of creating trust  In order to give the best customer experience we do not keep any hidden T&C .</p>
                      </div>
                  </div>

              </div>
          </div>
      </section>
    );
};
export default Dedicated;
