import React from "react";
import "react-reveal";
const WhyChoose = (props) => {
    return (
            <section class="CallRequestBack section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="CallRequestBackHead">
                                <h3 class="titleWithFont">
                                    Why Choose Us ?
                    </h3>
                                <p>
                                    Build Strategies, Build Confidence, Build Your Business.
                    </p>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="CallRequestBackBox text-left">
                                        <img src={require("../assets/img/SBM/icon1.png")} />
                                        <h3>Global Partnership</h3>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, quia consequuntur magni natus dolores eos voluptatem.</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="CallRequestBackBox text-left">
                                        <img src={require("../assets/img/SBM/icon2.png")} />
                                        <h3>Defined by our People</h3>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, quia consequuntur magni natus dolores eos voluptatem.</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="CallRequestBackBox text-left">
                                        <img src={require("../assets/img/SBM/icon3.png")} />
                                        <h3>Powered by Knowledge</h3>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, quia consequuntur magni natus dolores eos voluptatem.</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="CallRequestBackBox text-left">
                                        <img src={require("../assets/img/SBM/icon4.png")} />
                                        <h3>Taxes Andefficiency</h3>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, quia consequuntur magni natus dolores eos voluptatem.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="CallRequestBackForm">
                                <h3>Request Call Back</h3>
                                <p>Would You Like To Speak To One Of Our Financial Advisers Over The Phone? Just Submit Your Details.</p>
                                <div class="choose-relative">
                                    <div class="form-group">
                                        <label class="">
                                            <select id="Interested" class="FormTagsBox" name="EnquiryTypeList">
                                                <option value="">Interested In</option>
                                                <option value="Car Insurance">Car Insurance</option>
                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Travel Insurance">Travel Insurance</option>
                                            </select>
                                            <span id="VldInterested"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="">
                                            <input id="mobile" type="text" class="FormTagsBox" onkeypress="return isNumber(event)" placeholder="Mobile Number" maxlength="10" />
                                            <span id="Vldmobile"></span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="">
                                            <select class="FormTagsBox" id="prefertime">
                                                <option value="Preferred time">Preferred time</option>
                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                            </select>
                                            <span id="Vldprefertime"></span>
                                        </label>
                                    </div>
                                </div>

                                <input type="button" id="btnReq" class="btn callBackBtn" value="Send Request" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};
export default WhyChoose;
