import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import './assets/css/Riskovery.css';
import Footer from "./footer/Footer";

import ClaimsPage from "./ClaimsPage";
import Epartner from "./Epartner";
import Contact from "./Contact";

import RiskMiddleSection from "./RiskMiddleSection";

import { NavMenu } from "./NavMenu";

import PrivateCar from "./FooterPages/PrivateCar";
import PassengerCarrying from "./FooterPages/PassengerCarrying";
import Mediclaim from "./FooterPages/Mediclaim";
import Term from "./FooterPages/Term";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import LifePayment from "../Life/LifePayment";
import LifeProposal from "../Life/LifeProposal";
import ThankInsp from "../Car/ThankInsp";
const findPath = () => {
  let url = window.location.href.toLowerCase();
  let path = "HRIKLS";
    if (url.match("proposalmotor") || url.match("proposalhealth"))
        path = window.location.pathname
    else if (url.match("paymentresult")) {
        path = "/PaymentResult/" + window.location.pathname.match(/[^\/]+$/)[0];
    }
  return path;
}
const Riskovery = (props) => {
  let apiPath = findPath()
  return (
    <React.Fragment>
          <div className={"riskoverybody " + props.newUi}>
        <NavMenu />


        <Layout>
          <Route exact path="/Health" component={Health} />
          <Route exact path="/Car" component={Car} />
          <Route exact path="/Bike" component={Bike} />
          <Route exact path="/Renew" component={Renew} />
          <Route exact path="/New" component={Renew} />
        </Layout>


        <Switch>
          <Route exact path="/" component={RiskMiddleSection} />
          <Route exact path="/FourWheelerInsurance" component={PrivateCar} />
          <Route exact path="/TwoWheelerInsurance" component={PassengerCarrying} />
          <Route exact path="/HealthInsurance" component={Mediclaim} />
          <Route exact path="/TermLifeInsurance" component={Term} />
          <Route exact path="/GetQuoteLife" component={LifeQuote} />
          <Route exact path="/RedirectToPay" component={LifePayment} />
          <Route exact path="/GetQuote" component={MotorQuote} />
          <Route exact path="/Proposal" component={Proposal} />
          <Route exact path="/ShowProposal" component={ShowProposal} />
          <Route exact path="/Health" component={Health} />
          <Route exact path="/HealthQuotes" component={HealthQuotes} />
          <Route exact path="/HealthProposal" component={HealthProposal} />
          <Route exact path="/LifeProposal" component={LifeProposal} />
          <Route exact path="/InspectionConfirm" component={ThankInsp} />
          <Route exact path="/HealthShowProposal" component={HealthShowProposal} />
          
          

          <Route path="/About" component={About} />
          <Route path="/claims" component={ClaimsPage} />
          <Route path="/ePartner" component={Epartner} />
          <Route path="/contact-us" component={Contact} />
          <Route exact path="/ThankYou" component={Tnx} />
          <Route exact path={apiPath} component={PaymentSuccessOrFail} />
          <Route exact path="/PaymentResult" component={PaymentSuccessOrFail} />
          {/* <Route path='/privacy-policy' component={() => { 
            window.location.href = 'https://example.com/1234'; 
            return null;
       }}/>*/}
        </Switch>

        <Footer />
        <SideNav />
        <SocialToggle mobile="9987105551" email="satindar.khanuja@riskoveryinsurance.com" whatsapp="9987105551" />
      </div>
    </React.Fragment>
  );
};
export default Riskovery;
