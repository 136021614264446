/* eslint-disable */
import React from 'react';
const HealthIns = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <section class="summeryPage">
            <div class="container">
                <div class="main-counter-area">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <h3>HEALTH  Insurance</h3>
                                <p>
                                    The Insurance pays towards the expenses made by an  individual or any of his family members when hospitalized as an inpatient due  to an accident or ailment or illness. The Insurance Policy is a contract  between the insured and the Insurance Company by way of which the Insurance  agrees to pay the treatment hospital expenses on agreed terms. <br />
                                        COVERAGE</p>
                                <ul>
                                    <li>Room  and   boarding expenses</li>
                                    <li>Nursing  Expenses</li>
                                    <li>Fees of  surgeon, anesthetist, physician, consultants, specialists.</li>
                                    <li>Blood oxygen,  operation theatre, surgical applications, medicine, drugs, diagnostic, X-ray,  dialysis, chemotherapy, radio therapy, cost of pace maker, artificial limbs,  cost or organ and similar expenses of hospital.</li>
                                    <li>This includes expenses incurred during pre and  post-hospitalization, annual health check-ups, psychiatric support, critical  illnesses etc. </li>
                                    <li>In a Tailor made policy you may also cover </li>
                                    <ul>
                                        <li>Pre-existing diseases coverage </li>
                                        <li>maternity-related expenses </li>
                                        <li>Daily cash allowance etc. </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/KareIndia/Health_Insurance.jpg")} className="p-4" />
                        </div>
                        <div class="col-md-12">
                            <div class="ClaimsPageSecCNTBox border-0">
                                
                                    <p><h3>SIGNIFICANCE</h3></p>
                                    <ul>
                                        <li>Due to  increased life sustaining facilities available the expectation of survival has  crossed 70 years</li>
                                        <li>Our  lifestyles, or hereditary diseases or viral fever like Corona and even  accidents are increasing day by day.</li>
                                        <li>The cost of  treatment when hospitalized is rising day by day to the extent that people have  to mortgage their valuables to pay for treatment.</li>
                                        <li>Tax benefits</li>
                                    </ul>
                                    
                                    <h2>TYPES OF  HEALTH PLANS AVAILABLE</h2>
                                    <p>Various  Insurance Companies market various types of products.  This causes confusion while opting for the  best policy available for the client.   Thereby it is essential to understand the proper plan suitable to the  client with regard to financial coverage extent, Features,  and Customization options to the client. We shall explain below the various health  insurance plans available in the Indian Insurance market:<br />
                                        NORMAL PLANS</p>
                                        <ul>
                                            <li><a href="https://www.renewbuy.com/health-insurance/individual-health-insurance-plan/" target="_blank">Individual Plans&nbsp;</a>– This is the&nbsp;best health insurance policy&nbsp;for a single person  or those who only want to take a policy for themselves. It covers hospital and  surgical expenses to the extent of the sum insured under the policy including  other coverage as explained under Coverage above. </li>
                                        </ul>
                                        <ul>
                                            <li><u>Family Floater Plans</u> - Suchhealth insurance policies for the family cover self,  spouse, children and parents under one single policy with a payment of a single  premium. Such policies cover all hospitalisation treatments to all specified  members of the family as explained under Coverage, subject to the maximum of  Sum Insured under the policy.  Such&nbsp;health  insurance plans for family&nbsp;tend to be much more economical than buying  individual plans for every member separately. </li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <ul>
                                            <li>Settlements – This  may be done as follows: </li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <ul>
                                            <ul>
                                                <li>Cashless –           where  the insurers pay cost of treatment directly to the hospital </li>
                                                <li>Reimbursement – where the insured first pays the  entire cost of treatment to the hospital and thereafter deposits all document  with the insurer and file for re-imbursement </li>
                                                <li>Under both settlements above the Policy also covers 30  days pre-hospitalisation expenses and 60 days post discharge expenses. However  this out of hospital treatment should be consistent with the treatment provided  when hospitalised. </li>
                                            </ul>
                                        </ul>
                                        <p>SPECIALISED  PLANS</p>
                                        <ul>
                                            <li>Top up and Super Top up  Plans&nbsp;– This is the&nbsp;best health insurance policy&nbsp;for those who  already have some amount of health insurance. This type of insurance policy  usually has a deductible amount that will be the threshold limit above which  the Top-up or Super top-up plan will be activated. However, it can be an  excellent way to supplement an existing policy, whether self-purchased or  offered by an individual's employer. The other benefit is that premiums are  more affordable in this case. </li>
                                        </ul>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
export default HealthIns