import React from 'react';
import ButtonClass from '../ButtonClass';
import DatePicker from 'react-datepicker'
import MaskedInput from 'react-text-mask';
const GotQuotes=(props)=>{
  console.log(props);
    let maxYearRegistration;
    let minYearRegistration;
   
    let date = new Date();
    let Currentyear = date.getFullYear();
    let lstYear = [];
    try {
   
    for (let i = Currentyear-1; i > Currentyear - 16; i--) {
      lstYear.push(i);
    }
    }catch(e){console.log(e);}
    console.log(props)
    if(props.IsReNew){
      console.log("hiii")
    } else {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; //January is 0!
      var yyyy = today.getFullYear();
      if(dd<10){
        dd='0'+dd
      } 
      if(mm<10){
        mm='0'+mm
      } 

      today = yyyy+'-'+mm+'-'+dd;
     
      maxYearRegistration=today;
      minYearRegistration=yyyy-1+"-"+mm+"-"+dd;
    }
    return(
        <React.Fragment>

          <div id="myModal" className="modal ui-draggable" style={{display: "block"}}>
          <div className="modal-dialog newModalqt">
              <div className="modal-content">
                  <div className="modal-header text-center ui-draggable-handle">
                      <button type="button" className="close btn-success"  id="btnPopupHide" onClick={props.close}>×</button>
                      <h3>We have got the Quotes!</h3>
                      <h2>Confirm all the below details to ensure accurate premium</h2>
                  </div>
                 
                  {!props.IsReNew && <label>Your Policy will start from Tomorrow</label>}
                  <div className="modal-body">
                          <div className="newModalqtPopUp">
                          
                         {props.IsReNew && <div className="row">
                        
                                  <div className="col-md-6">
                                     <label><span>Manufacture year and month of your {props.motorType==1?"Car":"Bike"}</span></label> 
                                  </div>
                                  <div className="col-md-3">
                                        <select id="vehicleManufactureMonth" name="Manu_Month" className="packageForm" onChange={props.handle} defaultValue={props.Manu_Month}>
                                          <option value="0">Select Month</option>
                                          <option value="01">Jan</option>
                                          <option value="02">Feb</option>
                                          <option value="03">Mar</option>
                                          <option value="04">Apr</option>
                                          <option value="05">May</option>
                                          <option value="06">Jun</option>
                                          <option value="07">Jul</option>
                                          <option value="08">Aug</option>
                                          <option value="09">Sep</option>
                                          <option value="10">Oct</option>
                                          <option value="11">Nov</option>
                                          <option value="12">Dec</option>
                                      </select>
                                  </div>
                                  <div className="col-md-3">
                                        <select id="vehicleManufactureYear" name="Manu_Year" className="packageForm" onChange={props.handle} defaultValue={props.Manu_Year}>
                                          <option value="">Select Year</option>
                                          { lstYear.map((year)=><option key={year} value={year}>{year}</option>)}
                                      </select>
                                  </div>
                              </div>}
                              {!props.IsReNew && <div className="row">
                              <div className="col-sm-6"><span>{props.motorType==1?"Car ":"Bike "}Manufactured Date</span></div>
                              <div className="col-sm-5">
                              <input type="date" value={props.ManufactureDate} onChange={props.handle} name="ManufactureDate" max={maxYearRegistration} min={minYearRegistration} />
                             
                              </div>
                              </div>}
                          
                              { props.IsReNew?<React.Fragment>
                              <div className="row">
                                 
                                  <div className="col-md-6">
                                      
                                     

                                      <label>
                  <span>{props.IsReNew?"Exact registration":"Manufactured"} date of your {props.motorType==1?"Car":"Bike"}</span>
               
                  <input
                    type="date"
                    value={props.RegistrationDate}
                    name="RegistrationDate"
                    className="packageForm"
                    onChange={props.handle}
                   dateformat="DD/MM/YYYY"
                   max={maxYearRegistration} 
                   min={minYearRegistration}

                  />
                </label>
                                  </div>


                                  <div className="col-md-6" id="expiryDateDiv">
                                      
                                      {!props.MyPolicyexpired ? (
                                        <label>
                                        <span>Previous year policy expiry date</span>
                                          <input
                                            type="date"
                                            value={props.PreviousPolicyExpiredDate}
                                            name="PreviousPolicyExpiredDate"
                                            className="packageForm"
                                            onChange={props.handle}
                                          />
                                        </label>
                                      ) : null}</div>
                              </div>
                              <div className="PX_Day-BX">
                                  <div className="row">
                                      <div className="col-md-12">
                                      <input
                                      type="checkbox"
                                      className="packageFormCheck"
                                      name="Organisation"
                                      onChange={props.handle}
                                      checked={props.Organisation}
                                    /> 
                                    <label><span className="pl-2" >
                                    Vehicle owned by organisation
                                  </span></label>
                                      </div>

                                     {!props.Previouspolicydetails ? <div className="col-md-12" id="myPolicyExpired">
                                      <input
                                      type="checkbox"
                                      className="packageFormCheck"
                                      name="MyPolicyexpired"
                                      checked={props.MyPolicyexpired}
                                      onChange={props.handle}
                                    />
                                    <label><span className="pl-2">
                                    My policy expired more than 90 days
                                  </span></label>
                                    
                                      </div>:null}
                                      <div className="col-md-12">
                                      <input
                                      type="checkbox"
                                      className="packageFormCheck"
                                      name="Previouspolicydetails"
                                      checked={props.Previouspolicydetails}
                                      onChange={props.handle}
                                    />
                                    <label><span className="pl-2">
                                    I don't have previous policy details
                                  </span></label>
                                      </div>
                                  </div>
                              </div>
                           { !props.Previouspolicydetails ? <div className="row" ng-show="!IsPrevPolicyDetails">
                                  <div className="col-md-6">
                                  <label> <span >Previous policy type</span></label>
                                     
                                  </div>
                                  <div className="col-md-6">
                                  <select
                                  className="packageForm"
                                  name="PrevPolicyType"
                                  value={props.PrevPolicyType}
                                  onChange={props.handle}
                                >
                                  <option value="1">Comprehensive</option>
                                  <option value="2">Third Party</option>
                                  <option value="3">OD Cover Only</option>
                                </select>
                                  </div>
                              </div>:null} 
                              {props.PrevPolicyType!=2 && !props.MyPolicyexpired ?
                             
                             
                               
                                <div id="alreadyExpiry" className="row">
                                 <div className="col-md-6"> <label>
                                 <span>Have you made a claim in the past year?</span><br/>
                                 <input
                                  
                                   className="packageFormCheck pl-2"
                                   type="radio"
                                   value="N"
                                   name="PastYearClaimID"
                                   checked={!props.PastYearClaimID}
                                   onChange={props.handle}
                                 />
                                 <span className="CheckSpan"> No</span>
                                 <input
                                   name="PastYearClaimID"
                                   type="radio"
                                   value="Y"
                                   className="packageFormCheck pl-2"
                                   checked={props.PastYearClaimID}
                                   onChange={props.handle}
                                 />
                                 <span className="CheckSpan "> Yes</span>
                               </label></div>
                                 <div className="col-md-6">
                                 {!props.PastYearClaimID ? (
                                  <label>
                                    <span>Previous No Claim Bonus (NCB)</span>
                                    <select
                                      className="packageForm"
                                      name="BindPreviousNCB"
                                      value={props.BindPreviousNCB}
                                      onChange={props.handle}
                                    >
                                      <option value="0">0%</option>
                                      <option value="20">20%</option>
                                      <option value="25">25%</option>
                                      <option value="35">35%</option>
                                      <option value="45">45%</option>
                                      <option value="50">50%</option>
                                    </select>
                                  </label>
                                ) : <label>
                                <span>Claim Amount</span>
                                <MaskedInput
                                 mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/ ]}
                                guide={false} showMask={false}
                                value={props.ClaimAmount} className="packageForm" name="ClaimAmount"
                                 onChange={props.handle} onBlur={props.onBlur}
                                 
                                 />

                                </label>}
                                 </div>
                                </div>
                               : null}
              
                           </React.Fragment>:<div className="row">
                           <div className="col-md-12">
                          <label> <input
                           type="checkbox"
                           className="packageFormCheck"
                           name="Organisation"
                           checked={props.Organisation}
                           onChange={props.handle}
                         /> <span >
                         Vehicle owned by organisation
                       </span></label>
                           </div></div> }
                              <hr/>
                             {/*!props.Organisation && <div className="row PACOVEROD" id="PACOVEROD" ng-show="!IsCorporate">
                                 {/* <div className="col-md-6">
                                     <label><span >PA cover OD</span></label> 
                                      <label className="paCoverOd">
                                      <input
                                      className="packageFormCheck pl-2"
                                      name="ISPACOVERODSELECTED"
                                      type="radio"
                                      value="NO"
                                      onChange={props.handle}
                                      checked={!props.ISPACOVERODSELECTED}
                                    />
                                    <span  > No</span>
                                    <input
                                      className="packageFormCheck pl-2"
                                      name="ISPACOVERODSELECTED"
                                      type="radio"
                                      value="YES"
                                      onChange={props.handle}
                                      checked={props.ISPACOVERODSELECTED}
                                    />
                                    <span > Yes</span>
                                  
                                      </label>
                                      <div className="QuestionPopupPage QuestionPopupPagePopUp">
                                          <span>What's This?</span>
                                          <div className="QuestionPopupInnerPage">
                                              <div className="body">
                                                  <h3>Personal Accident (PA) Cover</h3>
                                                  <p>As per Insurance Regulatory and Development Authority of India notice. <strong>Personal Accident (PA) Cover is mandatory,</strong> if the car is owned by an individual not having <strong>Personal Accident cover of 15 Lakhs,</strong> please opt for 'Personal Acccident (PA) cover'</p>
                                                  <div className="tooltipinner">
                                                      You can opt out if...
                                                      The car is registered in a company's name
                                                      You already have a PA cover of 15 lakhs. (from any other vehicle owned by you or from a separate standalone PA Cover Policy)
                                                  </div>
                                                  <h3>What is PA Cover?</h3>
                                                  <p>This policy covers the owner for death or disability due to an accident. Owner (in case of disability) or nominee (in case of death) will get 15 lakhs.</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  
                                  {/*props.ISPACOVERODSELECTED ? (
                                    <div className="col-md-6">
                                    <label>
                                      <select
                                        className="packageForm"
                                        name="PACOVERYEAR"
                                        defaultValue={props.PACOVERYEAR}
                                        onChange={props.handle}
                                      >
                                        <option value="0">Select PA Cover Year</option>
                                        <option value="1">1 Year</option>
                                       {!props.IsReNew && <option value="3">3 Year</option>}
                                      </select>
                                    </label></div>
                                  ):
                                  
                                  <div className="col-md-12">
                                      <p id="pAcceptdeclaration" className="iAcceptedCNT" >
                                          <label><input
                                          type="checkbox"
                                          className="packageFormCheck"
                                          name="TermCond"
                                          checked={!props.ISPACOVERODSELECTED}
                                          onChange={props.handle}
                                        />
                                          </label> I hereby declare that I am holding an existing Personal Accident Policy (standalone cover or existing motor insurance policy) and/or any other policy/ies covering against Death and Permanent Disability (Total and Partial) of INR 15 Lakhs or more, which policy/ies is currently in force and/or would continue to be in full force during the 1 year Own Damage term/period declared by me under this Proposal. I accordingly confirm that I do not require the Compulsory Personal Accident cover for owner driver under this motor policy.
                                      </p>
                                  </div>*}
                                 {!props.ISPACOVERODSELECTED && <div className="col-md-12">
                                  <p id="pAcceptdeclaration" className="iAcceptedCNT" >
                                      <label><input
                                      type="checkbox"
                                      className="packageFormCheck"
                                      name="TermCond"
                                      checked={!props.ISPACOVERODSELECTED}
                                      onChange={props.handle}
                                    />
                                      </label> I hereby declare that I am holding an existing Personal Accident Policy (standalone cover or existing motor insurance policy) and/or any other policy/ies covering against Death and Permanent Disability (Total and Partial) of INR 15 Lakhs or more, which policy/ies is currently in force and/or would continue to be in full force during the 1 year Own Damage term/period declared by me under this Proposal. I accordingly confirm that I do not require the Compulsory Personal Accident cover for owner driver under this motor policy.
                                  </p>
                                  </div>*}
                                </div>*/}
                          </div>
                        <div className="text-center">
                       <label className="quotbtnWidth"><button type="button" id="btnThankYou" onClick={props.CallGetQuote}  className={ButtonClass()}>Information is accurate</button>
                       </label>
                          
                          </div>
                  </div>
              </div>
          </div>
      </div>


















 </React.Fragment>  )
}
export default GotQuotes;