import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
import { Accordion, Card } from "react-bootstrap"
const LifeInsurance = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg LifeInsBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Life Insurance</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">What is life Insurance?</h3>
                            <p class="padR_160">
                                Life insurance is a contract between an insurance policy holder and an insurer or assurer, where the insurer promises to pay a designated beneficiary a sum of money (the benefit) in exchange for a premium, upon the death of an insured person (often the policy holder). Depending on the contract, other events such as terminal illness or critical illness can also trigger payment. The policy holder typically pays a premium, either regularly or as one lump sum.
                                Apart from financial protection, people are attracted to life insurance as it is eligible to get tax deduction under Section 10(10D) & Section 80C of the Income Tax Act, 1961.
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>


            <section class="ProductPageSectionThird">
                <div class="container">
                    <div class="ProductPageSectionThirdP_Car">
                        <h3 class="SecondSectionHeading">I don't need Health Insurance, <br /><span>If you believe that read on...</span></h3>


                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/It Can Help You Deal with Debts.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>It Can Help You Deal with Debts</strong><br />In case of a crisis, you would not want your family to deal with financial problems. If you buy the right life insurance policy, then any outstanding debts like a car loan, home loan, and other personal loans will be taken care of.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/Your Family Will Be Taken Care Of.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Your Family Will Be Taken Care Of</strong><br />Nobody wants their family to face any financial problem if something were to happen to them. If something were to happen to you, you wouldn't want to let your family depend on someone else. Whether it is paying for the education of your child or making sure your spouse is financially secure, life insurance can secure your family's financial future.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/Life Insurance Can Supplement Your Retirement Goals.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Life Insurance Can Supplement Your Retirement Goals</strong><br /> We all want our retirement savings to last, don't we? And they will if you invest in a good insurance plan. With the right life insurance plan, you can get a regular stream of money every month. This idea is like a pension plan - you can get a steady income every month after your retirement.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/It Can Help You Achieve Your Long Term Goals.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>It Can Help You Achieve Your Long Term Goals</strong><br />Taking a life insurance policy is a long-term investment, which can help you achieve long-term goals. You can buy a house or plan your retirement. Before buying any policy, consider your options. Select the one you think would help you meet your long term goals.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/It Can Save Your Business.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>It Can Save Your Business</strong><br />Life insurance can not only protect your family, but also your business. There are some policies that can take care of your business. If you have a business of your own, and unfortunately something happens to you, then your portion of the business can be purchased by your partner. These business partners will enter a buy-sell agreement. Here, the payout will go to your nominees; however, they won't be given a stake in the company.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/Tax Planning.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Tax Planning</strong><br />Irrespective of what plan you buy, you could save taxes with insurance policies. As per Section 80C of the Income Tax Act, 1961, the premium paid for life insurance policies is qualified for deduction, up to a maximum of Rs. 1.5 lakh a year. Also, the maturity/death benefit is tax-free as per Section 10 (10D) of the Income Tax Act.</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <img src={require("../assets/img/Religare/lifeinsurance/Take life Insurance.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p>
                                    <strong>Take life Insurance</strong><br /><strong>You Might Not be Qualified for it Later</strong>
                            Life insurance policies always run on uncertainties. You may be healthy now and paying for life insurance may seem like an added financial burden. But if something happens to you all of sudden and on getting better, you'd realise that you should take a life insurance policy; however, at that time, you may not be allowed to buy a policy. This is why it is best to buy one policy as early as you can because it will still remain in force even if you start having health problems.
                        </p>
                                <p><strong>A Tool for Forced Savings</strong> If you choose a traditional or unit-linked policy, the premium you will pay every month will be higher than the cost that is required to insure you. This extra money that you pay gets invested. You can choose to sell or draw income from it or borrow the cash against the policy.</p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="SecondSectionHeading SecondSectionHeadingZeroDep">Benefits of Life Insurance</h3>
                        </div>
                        <div class="col-md-6">
                            <p>
                                We all know that life is unpredictable and such uncertainties can cause problem for individuals and his/her family at any time. So Availing a life insurance policy will make sure that your family and dependents can easily enjoy and can maintain their standard of living in case of any unwanted emergencies. There are several benefits that life insurance offers to the policy holders. Let's have a look to most significant ones.
                </p>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/Religare/lifeinsurance/Benefits-of-Life-Insurance.jpg")} />
                        </div>

                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container ProductPageLife">
                    <h3 class="SecondSectionHeading">TypesofLifeInsurancePoliciesinIndia</h3>

                    <div class="row">
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/lifeinsurance/Term plan.svg")} />
                                <p><strong>Term Life Insurance </strong><br />Term life insurance policy can be purchased only for a fixed period of time like 10 or 20 years. It is a type of life insurance that provides death benefits to the beneficiaries if the insured person dies during a specific time. A term life insurance policy does not offer any maturity benefits as it does not have any cash value but it is the most affordable type of life insurance policies.</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/lifeinsurance/Endowment Policy.svg")} />
                                <p><strong>Endowment Policy </strong><br />The only difference between this policy and the term insurance policy is that this policy comes with an extra benefit. The policyholder will receive an amount in lump sum if nothing happens to him until the date of maturity. If the insured person dies during the term of the policy, the nominee will receive the assured sum.</p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/lifeinsurance/Unit-linked Insurance Plan.svg")} />
                                <p><strong>Unit-linked Insurance Plan </strong><br />In addition to life security, this plan allows the policyholder to build wealth. As per this plan, the premium paid is divided and invested in debt funds and equity - one for building wealth and the other for life insurance.</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/lifeinsurance/Money Back Policy.svg")} />
                                <p><strong>Money Back Policy </strong><br />In a money back policy, the insured person receives a stipulated sum during the policy tenure. By taking this policy, you will receive a percentage of the assured sum at regular intervals. And if you live beyond the policy term, you will be eligible for the remaining amount plus bonus at the end of the policy period.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="ProductPageSectionThird">
                <div class="container">
                    <h3 class="SecondSectionHeading">Frequently Asked Questions</h3>
                    <div class="row" id="accordion-style-1">
                        <div class="col-md-12 mx-auto">
                            <Accordion className="ReligareCard">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="0">
                                        Can I take multiple health insurance ?
                            </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div id="faq1" aria-expanded="true">
                                                Individuals can buy multiple health insurance policies
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="1">
                                        How much SA do I need in a health insurance policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <div class="acod-content">
                                                There are no fixed rules in India to buy a health insurance. A person can opt SA min 2 Lac to 1 CR depends on his budget and requirement.
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="2">
                                        What is Co- Pay clause ?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Copayment in health insurance means an arrangement made with the health insurance company, in which the insured will need to pay a part of the medical expenses on their own. The remainder of the amount will be paid by the insurance company.
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="3">
                                        What is the meaning of network and non-network hospital?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <div class="acod-content">
                                                A hospital which has an agreement with health insurance company for providing cashless treatment is referred as network hospital. Non network hospital are those where policy holder seeking treatment will have to pay first for treatment and later claim as per reimbursement procedures
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="4">
                                        Day care procedures don't require 24 hours hospitalization?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            <div class="acod-content">
                                                >Day care procedures used to describe treatment that requires hospitalization for less than 24 hours. Such treatments are covered in your health insurance policy.
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="5">
                                        I am dissatisfied with my health policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Policy holders are allowed to switch their insurance companies and port their policy to another company if they are not happy with the existing Policy.
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default LifeInsurance;
