/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div class="container bottom_border">
                    <div class="row">
                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Motor Insurance</h5>

                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Private Car Insurance</a></li>
                                    <li><a href="#">Two Wheeler Insurance</a></li>
                                    <li><a href="#">Goods carrying vehicle</a></li>
                                    <li><a href="#">Passenger carrying vehicle</a></li>
                                </ul>
                            </div>

                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Health Insurance</h5>

                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Mediclaim Policy</a></li>
                                    <li><a href="#">Senior Citizen Insurance</a></li>
                                    <li><a href="#">Family Insurance</a></li>
                                    <li><a href="#">Parents Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Life Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Term Plans</a></li>
                                    <li><a href="#">Money Back Policy</a></li>
                                    <li><a href="#">Pension Plans</a></li>
                                    <li><a href="#">Type of Life Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Other Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Private Car Insurance</a></li>
                                    <li><a href="#">Two Wheeler Insurance</a></li>
                                    <li><a href="#">Taxi Insurance</a></li>
                                    <li><a href="#">School Bus Insurance</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="container">
                    <div class="footerBottom">


                        <div class="about text-center">

                            <p>
                                SS Insurance Brokers Private Limited<br />
                                108,109 First Floor, NDM-1, Netaji Subhash Place, Pitampura, New Delhi-110034
                    </p>
                            <p>
                                Registration Number - 665, IRDAI Registration Code: IRDA/DB733/18, Direct Broker: General and Life, Certificate Valid Upto: 14 November 2021,
                                <br />
                                Member: Insurance Brokers Association of India, CIN - U66030DL2017PTC325609
                            </p>





                        </div>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <li><a href="/Riskovery/Contact">Connect With Us</a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>

        </React.Fragment >
    );
};
export default Footer;
