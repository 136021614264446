/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
import ButtonClass from "../ButtonClass";
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <React.Fragment>
                <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                    <div class="breadcrumb-bg banner-area ContactRelBG banner-bg rocket-lazyload lazyloaded">
                        <h3>Contact Us</h3>
                    </div>
                </section>
                <section class="FourthSection AboutFourthSection ConatctFourthSection">
                    <div class="container">
                        <div class="SecondSectioninner">
                            <div class="FourthSectionBoxInner">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="FourthSectionBoxIn">
                                            <i class="fas fa-building"></i>
                                            <h3>
                                                <span>Find Us</span><br /> 2nd Floor, Tower � A, Prius Global, A-3, 4, 5, Sec - 125, Noida - 201301, Uttar Pradesh
                            </h3>

                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="FourthSectionBoxIn">
                                            <i class="fas fa-phone-alt"></i>
                                            <h3><span>Toll Number</span> <br /><a href="tel:18602588888">1860-25-88888</a></h3>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="FourthSectionBoxIn NoborderRight">
                                            <i class="fas fa-envelope-open"></i>
                                            <h3><span>Drop us a mail</span> <br /> <a href="mailto:wecare@religareonline.com">wecare@religareonline.com</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="contacUS_page">
                    <div class="container">
                        <div class="SecondSectioninner">
                            <div class="form-inline" role="form">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="contacUS_page_Form pb-0 ProductPageSectionThirdBox">
                                            <h3 class="SecondSectionHeading text-left p-0">Drop us a query!</h3>
                                            <form method="post">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group pb-3">
                                                            <input type="text" name="txtName" class="FormTagsBox" placeholder="Your Name" value="" id="nametxt" />
                                                            <span id="errornametxt" class="errMsgAll"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group pb-3">
                                                            <input type="text" name="txtEmail" class="FormTagsBox" placeholder="Your Email" value="" id="Emailtxt" />
                                                            <span id="errorEmailtxt" class="errMsgAll"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group pb-3">
                                                            <input type="tel" name="txtPhone" maxlength="10" class="FormTagsBox" placeholder="Your Phone Number" onkeypress="return isNumber(event)" value="" max="10" min="10" id="phonetxt" required="" />
                                                            <span id="errorphonetxt" class="errMsgAll"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group pb-3">
                                                            <textarea name="txtMsg" class="FormTagsBox" id="msgtxt" placeholder="Your Message"></textarea>
                                                            <span id="errormsgtxt" class="errMsgAll"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 text-center">
                                                        <div class="form-group">
                                                            <input type="submit" name="btnSubmit" className={ButtonClass()} value="Send Message" id="Contactbtn" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.5884176574255!2d77.3245104150815!3d28.582119482437722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59eeda89cd5%3A0xad82298812d2dfa1!2sReligare%20Broking%20Limited!5e0!3m2!1sen!2sin!4v1612799623574!5m2!1sen!2sin" width="100%" height="470" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    };
}
export default Contact;
