import React from "react"
const ShowSideError = (props) => {
    return (
        <div className="ShowSideError" style={{
            [props.position]: props.show ? "0px" : "-120px", background: props.IsSuccess ? "#ccf5d1" : "#ffd9dc",
            borderColor: props.IsSuccess ? "#97e6a5" : "#f5949d",
            borderRadius: props.Isleft ? "0px 30px 30px 0px" : "30px 0px 0px 30px",
           
        }}>
            <span>{props.errorMSG}</span>
        </div>
        )
}
export default ShowSideError