import React, { Component } from "react";
import {

    NavbarBrand,

    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomerLogin from "./CustomerLogin";
import ManageAccount from "../components/ManageAccount";
export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            Logo: require("./assets/img/Religare/logo.png"),
            Login: "Login",
        };
    }
    redirectTo(path) {
        // let url=window.location.href;
        // if(url.match("www")){
        //     if(path.match("www.")){
        //         path=path.replace("www.","")
        //     }
        // }
        window.location.assign(window.location.href = path)
    }
    render() {
        return (
            <header className="myNav">
                <nav className="mainHeader navbar fixed-top navbar-light">
                    <div className="container">
                        <div className="row MainMenu">
                            <div className="col-sm-2">
                                <NavbarBrand tag={Link} to="/">
                                    <img
                                        src={require("./assets/img/Religare/logo.png")}
                                        id="logoasdf"
                                    />
                                </NavbarBrand>
                            </div>
                            <div className="col-sm-10">
                                <ul className="SubHead_Risk">
                                    <li>
                                        <a href="tel:18602588888">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
      &nbsp; 1860-25-88888
    </a>
                                    </li>
                                    <li>
                                        <CustomerLogin />
                                    </li>

                                    <li>
                                        <a onClick={() => this.redirectTo("https://insurance.religareonline.com/myaccount")}>
                                            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
      &nbsp; <ManageAccount />
                                        </a>
                                    </li>

                                    <li id="talkToExperts" className="talkToExperts">
                                    </li>

                                </ul>
                                <ul className="MN_Menu navMenu navbar-right">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/About" >
                                            About Us
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Claims" >
                                            Claims
                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/ePartner" >
                                            ePartner
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-dark"
                                            to="/contact-us"
                                        >
                                            Contact Us
                </NavLink>
                                    </NavItem>

                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}
