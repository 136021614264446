const Partner = [
    {
        alt: "birlaHealthInsurance",
        src: require("../Contents/img/partner/birlaHealthInsurance.png"),
    },
    {
        alt: "bajaj",
        src: require("../Contents/img/partner/bajaj.png"),
    },
    {
        alt: "cigna-ttk-health-insurance-2-150x43",
        src: require("../Contents/img/partner/cigna-ttk-health-insurance-2-150x43.png"),
    },
    {
        alt: "future_logo",
        src: require("../Contents/img/partner/future_logo.png"),
    },
    {
        alt: "godigit",
        src: require("../Contents/img/partner/godigit.png"),
    },
    {
        alt: "hdfc_logo",
        src: require("../Contents/img/partner/hdfc_logo.png"),
    },
    {
        alt: "Icici_lom_Logo",
        src: require("../Contents/img/partner/Icici_lom_Logo.png"),
    },
    {
        alt: "iffco",
        src: require("../Contents/img/partner/iffco.jpg"),
    },
    {
        alt: "kotak_logo",
        src: require("../Contents/img/partner/kotak_logo.png"),
    },
    {
        alt: "star_logo",
        src: require("../Contents/img/partner/star_logo.png"),
    },
    {
        alt: "realince_logo.png",
        src: require("../Contents/img/partner/realince_logo.png"),
    },
    {
        alt: "religare_logo",
        src: require("../Contents/img/partner/religare_logo.png"),
    },
    {
        alt: "Sriram",
        src: require("../Contents/img/partner/Sriram.jpg"),
    },
    {
        alt: "universal_logo",
        src: require("../Contents/img/partner/universal_logo.png"),
    },
    {
        alt: "sbi-gene",
        src: require("../Contents/img/partner/sbi-gene.jpg"),
    },
    {
        alt: "icici",
        src: require("../Contents/img/partner/icici.jpg"),
    },
    {
        alt: "HdfcLifeInsurance",
        src: require("../Contents/img/partner/HdfcLifeInsurance.jpg"),
    },
    {
        alt: "EdelweissTokioLifeInsurance",
        src: require("../Contents/img/partner/EdelweissTokioLifeInsurance.jpg"),
    },
];
export default Partner;
