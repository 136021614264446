import React from "react";
const Product = [
    {
        id: 1,
        name: "Car",
        to: "/Car",
        className: "nav-item nav-link",
        src: require("./assets/img/Advance/Car.png"),
    },
    {
        id: 2,
        name: "2 Wheeler",
        to: "/Bike",
        className: "productTabsRisk",
        src: require("./assets/img/Advance/Bike.png"),
    },
    {
        id: 3,
        name: "Health",
        className: "productTabsRisk",
        to: "/Health",
        src: require("./assets/img/Advance/Health.png"),
    },
    {
        id: 4,
        name: "Life",
        className: "productTabsRisk",
        to: "/Life",
        src: require("./assets/img/Advance/Life.png"),
    },
    {
        id: 5,
        name: "Home",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Advance/Home.png"),
    },
    {
        id: 6,
        name: "Travel",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Advance/Travel.png"),
    },
];
export default Product;
