import React from 'react';
import Partner from './SecuritySection';
const SecuritySection = () => {
    return (



        <section class="callNowBoxMain">
            <div class="container">
                <div class="callNowBoxMain01">
                    <div class="row">
                        <div class="col-md-2">
                            <img src={require("./assets/img/P2C/irdai-150x150.jpg")} className="SecuImg" />
                        </div>
                        <div class="col-md-10">
                            <h3>LICENSED BY IRDAI</h3>
                            <p>
                                DIS Insurance Broking Pvt. Ltd. is Licensed by IRDA under the category of - DIRECT INSURANCE BROKER - (GENERAL &amp; LIFE), Licence No.- 483,
                                Licence Valid Up to March 19, 2023.
                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SecuritySection;