/* eslint-disable */
import React from 'react';
const HomeInsurance = () => {
    return (
        <section class="productCntMain">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="productCnt">
                            <h3>Home Insurance</h3>
                            <p>
                                Home insurance policies in India are property  insurance that provides coverage against house damage or that of the contents  inside. Apart from unauthorized entry, you can also get an extended cover for  accidents that may occur at home.
                            </p>
                            <p>
                                <strong>Benefits of Home Insurance</strong><br/>
                                    <br/>
                                        Home insurance offers numerous benefits to the policyholder. With a good home  insurance in place, you can have complete peace of mind regarding your property  investment. You can avail emergency repairs, the additional expenses of living  outside your home in case of damage to property, or replacement of personal  property, minus the hassles and minimum documentations. Besides, the annual  premiums involved are reasonably low in most cases so as not to put extra  financial burden on the insurer. The home insurance premium calculator can help  you to calculate and decide which home insurance plan to go for.

                    </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/RFL/LifeIns.png")} />
                    </div>
                    
                </div>
            </div>
        </section>
    )
}
export default HomeInsurance