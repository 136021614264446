/* eslint-disable */
import React from "react";
import Logo from "./Logo";
import fb from "../assets/img/facebook.svg";
import twitter from "../assets/img/twitter.svg";
import linkedin from "../assets/img/linkedin.svg";
const Social = () => {
    return (
        <div className="row">
            <div className="col-sm-12 social">
                <p>Follow Us on</p>
                <ul className="">

                    <li>
                        <a href="#">
                            <Logo alt="twitter" src={twitter} />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <Logo alt="LinkedIn" src={linkedin} />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <Logo alt="facebook" src={fb} />{" "}
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    );
};
export default Social;
