/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })

    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <section style={{ marginTop: "95px" }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14006.64678880708!2d77.3259452!3d28.6398997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x38e2a6364000d8f3!2sAdvance%20Life%20Insurance%20Brokers%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1582185331784!5m2!1sen!2sin"
                    className="map"
                    frameborder="0"
                    allowfullscreen=""
                ></iframe>
                <div className="container">

                    <div role="form" className="formAdvance">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="contacUS_page_Info">
                                    <div className="Spearhead">
                                        <h3>Advance Life Insurance Brokers</h3>
                                        <p>

                                            <strong>Corporate Office</strong><br />
A-106, Behind Angel Mega Mall,
Kaushambi, Ghaziabad-201010, Uttar Pradesh, INDIA<br /><br />
Contact no.- 0120-4300180<br /><br />

Email: info@advancelifeinsurance.in
                                    </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <h5 class="contactTitle">CONTACT US</h5>
                                <div className="contacUS_page_Form ">
                                    <h2>Let Us Help</h2>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" id="Interested" name="typeList" onChange={this.handle}>
                                                <option value="">Interested In</option>
                                                <option value="Car Insurance">Car Insurance</option>
                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Travel Insurance">Travel Insurance</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["typeList"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["MobileNo"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" name="time" onChange={this.handle}>
                                                <option value="Preferred time">Preferred time</option>
                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["time"]}</span>
                                    <div className="form-group text-left">
                                        <input type="button" id="btnReq" className="cl_btn" value="Send Request" onClick={this.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="contacUS_page_Info">
                                    <div className="Spearhead">
                                        <h3>Advance Life Insurance Brokers</h3>
                                        <p>
                                            <strong>Registered office:</strong><br /><br />
                                          Office No. 9, 1st Floor, D 5 Awadh Complex, Laxmi Nagar Delhi 110092
                                        <br /><br />
                                            <strong>Branch Office:</strong><br /><br />
                                        1) Office No 426, ARG Tower, North Avenue Road, Number 9 VKIA, Sikar Road, Jaipur -302013 (Rajasthan)
Ph : 09829063590
                                          <br /><br />

2) Office No C-135, Ground Floor, Sushant Shopping Arcade, Sushant Lok - I, Gurgaon-122001 ( Haryana)
Ph : 0124-4601882, 8527593741
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        );
    };
}
export default Contact;
