/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { NavbarBrand, NavItem, NavLink, } from "reactstrap";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <section class="section_all footer-section bg_footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-xs-12 footer-top">
                            <div class="col-lg-10 col-md-12 col-xs-12 mx-auto">
                                <ul class="row">
                                    <li class="col-lg-6 col-md-3 col-sm-4 col-12 text-center">
                                        <h6>LICENSED BY</h6>

                                        <img src={require("../assets/img/inshora/license.jpg")} class="img-fluid" />
                                    </li>
                                    <li class="col-lg-6 col-md-7 col-sm-6 col-12 payement-option text-center">
                                        <h6>PAYMENT OPTIONS</h6>
                                        <img src={require("../assets/img/inshora/payment1.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment2.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment3.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment4.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment5.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment6.jpg")} class="img-fluid" />
                                        <img src={require("../assets/img/inshora/payment7.jpg")} class="img-fluid" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-5 col-sm-3 footer-sec1">
                            <div class="footer_logo_img mt-3">
                                <a >

                                    <img src={require("../assets/img/inshora/logo-3.png")} alt="" class="img-fluid d-block" style={{position: "absolute", top:"-19px",left:"-25px"}} />
                                </a>
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-7 col-sm-6 footer-about-sec">
                            <div class="footer_heading_tag mt-3">
                                <h6 class="font-weight-bold">Registered Office</h6>
                            </div>
                            <p class="footer-abt-p"><b>Inshora Risk Advisory And Insurance Broking Pvt. Ltd.</b><br />D-484, 2nd Floor, Palam Extension, Sec-7, Dwarka, New Delhi-110077.<br /><strong>Call: <a href="https://wa.me/919811994580">9811994580</a></strong></p>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-8 insurance-solution">
                            <div class="footer_heading_tag mt-3">
                                <h6 class="font-weight-bold">Insurance Solutions</h6>
                            </div>
                            <div class="footer_links_all mt-3 inssol-cont">
                                <ul class="list-unstyled mb-0">
                                    <NavItem><NavLink tag={Link} to="/LifeInsurance">Life Insurance </NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/CarInsurance">Car Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/BikeInsurance">Bike Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} >Motor Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Health Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} >Professional Liability</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HomeInsurance">Property Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Fire Insurance </NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Marine Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Rural Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Employee Benefits </NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Micro Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Credit Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Engineering &amp; Projects Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Keyman Insurance</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Cyber Risks</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TravelInsurance">Travel Insurance</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 footer-serv-sec">
                            <div class="footer_heading_tag mt-3">
                                <h6 class="font-weight-bold">Services</h6>
                            </div>
                            <div class="footer_links_all mt-3">
                                <ul class="list-unstyled mb-0">
                                    <NavItem><NavLink  tag={Link}>Policy Servicing</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Claims Services</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Portfolio Review</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Dedicated Service Desk</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Risk Advisory</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Underwriting</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>



                        <div class="col-lg-2 col-md-3 col-sm-4 col-6 footer-navig-sec">
                            <div class="footer_heading_tag mt-3">
                                <h6 class="font-weight-bold">Navigate</h6>
                            </div>
                            <div class="footer_links_all mt-3">
                                <ul class="list-unstyled mb-0">
                                    <NavItem><NavLink  href="http://www.inshoracover.com/">Home</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link}>Insurance Blog</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link} to="/About">About Us</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link} to="/Claims">Claims</NavLink></NavItem>
                                    <NavItem><NavLink  tag={Link} to="/Career">Career</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/contact-us">Contact Us</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermsAndCondition">Terms and Condition</NavLink></NavItem>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="section_all footerlast-sec">
                <div class="container-fluid">
                    <div class="row flex">
                        <div class="col-lg-10 text-center flast-cont">
                            <div class="flast-upper">
                                <p>
                                    License No. 669 | IRDA Direct Broker Code: IRDA/DB/738/18 | Valid till: 01/01/2025 | CIN: U66000DL2018PTC330518<br /> Principal Officer : Dr Anju Punetha | Contact No . 9811994580<br />
                                        We hereby notify that disclosing, distributing, copying, reproducing, storing in a retrieval system, or transmitting in any form or by any means, electronic, mechanical, photocopying, recording, or otherwise, or taking any action in reliance on the contents of the presentation in its entirety or any part thereof is strictly prohibited without the prior written consent of Inshora Risk Advisory And Insurance Broking Pvt. Ltd.
                    </p>

                            </div>
                        </div>
                    </div>
                    <div class="row flex">
                        <div class="col-lg-7 col-md-8 flast-cont2 fsocial-icons">
                            <ul class="list-inline footer_social_icon fsocial-icons-ul mt-4">
                                <li class="list-inline-item">
                                    <NavLink tag={Link}>Privacy Policy</NavLink>
                                </li>
                                <li class="list-inline-item">
                                    <NavLink tag={Link}>Cancellation &amp; Refund</NavLink>
                                </li>
                                <li class="list-inline-item">
                                    <NavLink tag={Link}>Terms &amp; Conditions</NavLink>
                                </li>
                            </ul>
                            <p class="text-center flast2-p">Copyright 2019 inshoracover.com, All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default Footer;
