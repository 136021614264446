import React from "react";
const Product = [
    {
        id: 1,
        name: "Car Insurance",
        to: "/Car",
        className: "nav-item nav-link",
        src: "fa-car"
    },
    {
        id: 2,
        name: "2 Wheeler Insurance",
        to: "/Bike",
        className: "productTabsRisk",
        src: "fa-motorcycle"
    },

    {
        id: 3,
        name: "Health Insurance",
        className: "productTabsRisk",
        to: "/Health",
        src: "fa-heartbeat"
    },
    {
        id: 4,
        name: "Life Insurance",
        className: "productTabsRisk",
        to: "/Life",
        src: "fa-procedures"
    },
    {
        id: 5,
        name: "Home Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: "fa-home"
    },
    {
        id: 6,
        name: "Travel Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: "fa-plane-departure"
    },
];
export default Product;
