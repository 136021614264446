import React from "react";
import TabData from "./TabData";
const data = TabData;
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <div
            onClick={props.onClick}
            className="productTabsRisk productTabsRisk--active"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>

    ) : (
        <div
            onClick={props.onClick}
            className="productTabsRisk"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>
    );
}

function TabContent(props) {
    return (
        <div style={props.style} data-tab={props.dataTab}>
            {props.content}
        </div>
    );
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isActive: "1", data: data };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.getAttribute("data-tab") });
        console.log(ev.target);
    }

    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                dataTab={item.id}
                title={item.tabTitle}
                key={item.id}
                to={item.to}
            />
        ));
        var listContent = this.state.data.map((item, i) =>
            this.state.isActive === item.id ? (
                <TabContent dataTab={item.id} content={item.tabContent} key={i} />
            ) : (
                <TabContent
                    style={{ display: "none" }}
                    dataTab={item.id}
                    content={item.tabContent}
                    key={i}
                />
            )
        );
        return (
            <section className="homeSection">
                    <div class="BannerBox">
                        <div class="container">
                            <h3>Get the best Insurance cover online for all <br /> your Insurance needs anywhere anytime!</h3>
                            <img src={require("../assets/img/Religare/banner-vector.png")}/>
                        </div>
                    </div>
                <div className="productTabsMainBG">
                    <div className="container">
                            <div className="tabs">
                                <div className="packagesMainRisk">
                                    <div className="productTabsMainRisk" id="mainTab">
                                        {listTitle}
                                    </div>
                                </div>
                                <div className="tab-content">{listContent}</div>
                            </div>
                        </div>
                    </div>
            </section>

        );
    }
}
export default Tab;
