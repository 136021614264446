import React from 'react';
import Partner from './Partner';
import Zoom from 'react-reveal';
const OurPartner=()=>{
const a= Partner.map((v,i)=>{
    return(
    <React.Fragment>
    
    <img src={v.src} key={i} className="partnerLogo"/>
   
    </React.Fragment>
        )
})
    return(
    
     
        <div class="ourPartner pt-5 pb-5 bg-light">
            <div class="container">
                <h3 class="text-center titleWithFont">
                    Our Business Partners
                    </h3>
                <div className="row">{a}</div>
            </div>
        </div>
    )
}
export default OurPartner;