/* eslint-disable */ 
import React, { useState } from "react";
import InspectionStatus from "../Car/InspectionStatus";
import Call from "./Call";
import CallBack from "./CallBack";
import FeatureBgClass from "./FeatureBgClass";
import Offline from "./Offline";


class SideNav extends React.Component {
  constructor() {
    super();
   
    this.state = { condition: true, getStatus:false };
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    this.setState({ condition: !this.state.condition });
  }

  render() {
    return (
      <React.Fragment>
      {this.state.condition?<div className="CallRequest">
      <button className={"showOpt " + FeatureBgClass()} onClick={this.onButtonClick}>Show Options</button>
            <ul className={"ExtraFeature " + FeatureBgClass()}>
          <li> <i className="flaticon-headset"></i><a href="#" data-toggle="modal" data-target="#OfflineQuotePage">Offline Quotes</a></li>
          <li> <i className="flaticon-headset"></i><a href="#" data-toggle="modal" data-target="#CallRequest">Request Call Back</a></li>
          <li><i className="flaticon-smartphone"></i><a href="#" data-toggle="modal" data-target="#ClickToCall">Click to Call</a></li>
      </ul>
  </div> :
            <div className="CallRequest ExtraFeature002">
                <button className={"showOpt " + FeatureBgClass()} onClick={this.onButtonClick}>Show Options</button>
    <ul className={"ExtraFeature " + FeatureBgClass()}>
        <li> <i className="flaticon-headset"></i><a><Offline/></a></li>
        <li> <i className="flaticon-headset"></i><a><CallBack/></a></li>
        <li><i className="flaticon-smartphone"></i><a><Call/></a></li>
        <li><i className="flaticon-smartphone"></i><a onClick={()=>this.setState({getStatus:true})}>InspectionStatus</a></li>
    </ul>
</div>}
{this.state.getStatus && <InspectionStatus close={()=>this.setState({getStatus:false})} />}
</React.Fragment>
    );
  }
}
export default SideNav;
