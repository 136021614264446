import React from "react";
import "react-reveal";
import { Zoom } from "react-reveal";
const Features = (props) => {
  return (
      <section class="section bg-features vertical-content">
          <div class="bg-features-overlay"></div>
          <div class="container">
              <div class="row">
                  <div class="col-lg-6 offset-lg-6">
                      <div class="mt-3 features_desc text-white">
                          <div>
                              <h3 class="features-heading font-weight-bold">Completed 3 successful years in Insurance aggregation</h3>
                              <div class="main-title-border">Last 36 months of our team spent to understand Insurance needs and how to make your Insurance buying journey smooth and easy, build technology which we call a butter smooth experience</div>
                          </div>
                          <div class="features">
                              <div class="features-desc">
                                  <h3 class="features-title font-weight-bold">Unbiased Comparison</h3>
                                  <p class="features-sub-title pt-2">Unbiased information on Health, Motor and Life Insurance products to help you take an informed decision while going for a purchase</p>
                              </div>
                          </div>

                          <div class="features">
                              <div class="features-desc">
                                  <h3 class="features-title font-weight-bold">Easy Buying Process</h3>
                                  <p class="features-sub-title pt-2">Our state of the art User interface and Interactive User experience which shows you dynamic content that helps you understand smallest of the insurance terms</p>
                              </div>
                          </div>

                          <div class="features">
                              <div class="features-desc">
                                  <h3 class="features-title font-weight-bold">Big Savings</h3>
                                  <p class="features-sub-title pt-2">Best Value for your time, while you spend on our site to study and compare different Insurance products, Onboard we have best of the Insurance companies offering best discounts depending on parameters like location, Age, Family size usage and claims experience</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="features_side_mockup d-lg-block d-xl-block d-none">
              <img class="img-fluid mx-auto d-block" src={require("./assets/img/Riskovery/features.png")}/>
    </div>
</section>
  );
};
export default Features;
