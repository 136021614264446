import React from "react";
const Claiming = (props) => {
    return (
        <div class="content-inner">
            <div class="container claiming-simplified">
                <div class="row">
                    <div class="col-sm-6 wow zoomIn save-content">
                        <div>
                            <h1>Claiming <span>simplified</span></h1>
                            <p>
                                <i class="flaticon-resume"></i> Submit your claim hassle-free - just a call away or through our Android APP or Web-Portal. Our consultants will handle your claims with diligence and stand on your side throughout this process.
                                    </p><h3>What this means for you</h3>
                            <p><i class="flaticon-charity"></i> Our personal tele-claims service ensures that we are connected with you and assign a dedicated claims managers who take you through a personalised service. Our claims service is quick and easy so that you can concentrate on more important things.</p>
                            <a href="claim.html" class="site-button log-sign">Submit Claim Request </a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <img src={require("../assets/img/inshora/background/bg27.jpg")} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Claiming;
