import React from "react";
const TestimonialData = [
    {
        Heading: "Ms. Avanija Reddy",
        name: "MD, Fortune Hotels.",
        src: require("./assets/img/BimaStreet/Avanija_Reddy.png"),
        quotes:
            "Amaze is one of the best insurance brokers in the market. Dr. Muralidhara Reddy is known to me since 2009 and he has been servicing our Hotel Insurance requirements as well as my Family  Medical Insurance since then.  He has personally  helped with my cashless Mediclaim and got a 100% claim. I am extremely happy with their service and would certainly refer to anyone.",
    },
    {
        Heading: "Dr.Taufiq Munshi ",
        name: "Super Specialist Dentist",
        src: require("./assets/img/BimaStreet/Dr_Taufiq_Minshi.png"),
        quotes: "I have been dealing with Amaze Insurance company since Day one of their Start. The Best Customer Care Services that I can Expect. Best Team, who has always Guided me Right from which policy to buy with least quote and their Recommendation. In the Event of Claim, Amaze Insurance has been very kind enough to co-ordinate with Respective company and see that I get whatever best is possible. What more can you Expect from an Insurance company. I wish Amaze All the Best for their Future and Strongly recommend them for any kind of Insurance needs."
    },

    {

        name: "Musaddilal Jewellers",
        src: require("./assets/img/BimaStreet/Musaddilal.png"),
        quotes:
            "We’re pleased to Amaze on record on guiding us while finalizing our policies, in settleing claims and in recommending right insurance policies. We appreciate your team contributions in collecting the data, suggesting the suitable coverages and obtaining competitive quotes from various insurers thus reduced the amount of premium outgo. Further you’ve been rendering highly satisfactory services in renewals, claims and other relevant areas.",
    },

    {
        name: "GreenPark Hotels and Resorts Ltd.",
        src: require("./assets/img/BimaStreet/GreenPark_Hotel.png"),
        quotes:
            "We’re utilizing the services of Amaze Insurance Brokers and all our insurance portfolio like Property Insurance, Employee benefits and Motor policies including our sister organization STAMLO are with Amaze and we’re satisfied with their handling of our portfolio and Claim Settlements. Amaze has excellent supporting team having in-depth knowledge in insurance. They always guide us in taking suitable polices covering all our business needs.",
    },

    {
        name: "CYIENT Technologies",
        src: require("./assets/img/BimaStreet/CYIENT.png"),
        quotes:
            "We hereby wish to congratulate Amaze on the wonderful service rendered to us for the last 3 years, in all the  areas of insurance wherever we have requested support, we have had multiple claims from multiple departments and despite multiple challenges at various levels which encompass, several good initiatives such as vehicle insurance camps, health insurance awareness camps, we have received positive feedback from multiple stakeholders internally on the initiatives taken by you have had tremendous impact on associates in terms of awareness on insurance and overall satisfactions level on the service has been consistently delightful.",
    },

    {
        name: "AXIS Energy Ventures",
        src: require("./assets/img/BimaStreet/Axis_Energy.png"),
        quotes:
            "We would like to express our appreciation for all the services provided from day one. Your valuable support has really made us comfortable and also made us to bank on Amaze on all  fronts.",
    },

    {
        Heading: "Dr.P.Murali Krishna Reddy, D",
        name: "Assistant Director. ",
        src: require("./assets/img/BimaStreet/Dr P Murali Krishna Reddy.jpg"),
        quotes:
            "Amaze insurance Pvt LTD  is good company and noted for trust and good quality of services .It renders amazing  service in insurance issues.My four wheeler and healthcare policies are with Amaze insurance Pvt LTD only.",
    },

    {
        Heading: "Padma Rao",
        name: "Public Prosecutor, All India Viceprsedent",
        src: require("./assets/img/BimaStreet/Padma Rao.jpg"),
        quotes:
            "I feel I am totally insured as I take insurance through Amaze insurance brokers for all my insurance needs.The claim that was settled when my car met with an accident by amaze is really amazing as my involvement was almost negligible and their performance and speed was extraordinary.The pricing is the best along with the timely courteous reminders for renewals too.The experience is amazing for my 3 policies taken till date. 'I am associated with Amaze insurance, since they started the company .They used to provide comprehensive  corporate  insurance services of the manufacturing plant, stock insurance, and the medical insurance for all employees. The services were of highest standard, people friendly, and there always used to be ease in doing every thing. I am an individual client now, just for my mediclaim., and overseas travel policy..The executives of Amaze have guided me over the years, to get the best policies, with competitive premia. I wish them all the best, in their new endeavour to serve the clients in need of insurance in online mode. Best wishes Amaze👍' ",
    },
    
];
export default TestimonialData;









