import React from 'react';
const HealthInsurance = () => {
    return (
        <React.Fragment>
            <div class="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt">
                <div class="container">
                    <div class="dlab-bnr-inr-entry">
                        <h1 class="text-white">Health Insurance</h1>
                    </div>
                </div>
            </div>

            <div class="content-block">
                <div class="section-full content-inner">
                    <div class="container">
                        <div class="row py-5">
                            <div class="col-md-6 offset-md-3">
                                <img src={require("../assets/img/inshora/health.jpg")} />
                            </div>
                        </div>
                        <div class="row py-4">
                            <div class="col-sm-6">
                                <h3>What is Life Insurance?</h3>
                                <h5>A health insurance policy is a type of insurance that offers coverage for the medical and surgical expenses incurred by the policyholder when he/she is hospitalised during the policy period.</h5>
                                <p>Health insurers, today, also provide coverage for day care hospitalisation, pre-hospitalisation, and post-hospitalisation. The expenses incurred are either reimbursed to the insured individual or are paid to the healthcare provider directly. On one hand, issues with health have become unavoidable, thanks to changes in the environment and lifestyle over the past few decades. Leading a healthy lifestyle doesn't guarantee a healthy body any more. On the other hand, medical costs have skyrocketed, which can leave you in a dire situation if you aren't prepared. Health insurance has gained importance in today's world due to these reasons. It is a smart investment to make but requires a bit of research to ensure the plan is affordable, offers good coverage, and will pay out sufficiently when needed.</p>
                            </div>
                            <div class="col-sm-6">
                                <form class="inquiry-form wow fadeInUp dzForm contact-form" data-wow-delay="0.2s" action="">
                                    <div class="dzFormMsg"></div>
                                    <h3 class="box-title m-t0 m-b10">We support our customers 24x7.<span class="bg-primary"></span></h3>
                                    <p>We request you to contact at our support number mentioned below or assigned account manager via email. We will be happy to assist you.</p>
                                    <div class="row py-5">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-user"></i></span>
                                                    <input name="name" type="text" required="" class="form-control" placeholder="Full Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-mobile"></i></span>
                                                    <input name="phone" type="text" required="" class="form-control" placeholder="Phone" maxlength="10" minlength="10" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-email"></i></span>
                                                    <input name="email" type="email" class="form-control" required="" placeholder="Your Email Id" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-write"></i></span>
                                                    <select class="form-control" name="message" required="">
                                                        <option>I want to...</option>
                                                        <option>Buy Car Insurance</option>
                                                        <option>Buy Bike Insurance</option>
                                                        <option>Buy Health Insurance</option>
                                                        <option>Buy Term Insurance</option>
                                                        <option>Buy Travel Insurance</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-agenda"></i></span>
                                                    <textarea name="dzMessage" rows="4" class="form-control" required="" placeholder="Tell us about your project or idea"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <button name="send" type="submit" value="Submit" class="site-button button-lg cs-button"> <span>Call me</span> </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default HealthInsurance