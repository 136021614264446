import React from "react";
const Career = () => {
    return (
        <React.Fragment>
            <div class="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt">
                <div class="container">
                    <div class="dlab-bnr-inr-entry">
                        <h1 class="text-white">Openings with us</h1>
                    </div>
                </div>
            </div>
            <div class="content-block">
                <div class="section-full content-inner">
                    <div class="container">
                        <div class="row py-4">
                            <div class="col-sm-6">
                                <h3>We are looking for people who are self-motivated and passionate about making a difference. This is your chance to be part of futuristic insurance startup!</h3>
                                <h5>You can send us your resume on&nbsp;admin@inshoracover.com</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};
export default Career;