import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>

            <section class="second-section">
                <div class="container">
                    <div class="team-section padding80">
                        <div class="row text-center">
                            <div class="col-md-4 col-sm-4">
                                <div class="team-box">
                                    <div class="team-img">
                                        <img src={require("../assets/img/Sarsa/buy1.png")} />
                                    </div>
                                    <div class="team-title">
                                        <h3>25 Years Experience</h3>
                                        <p>We are working in insurance broking since 25 growing years.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="team-box">
                                    <div class="team-img">
                                        <img src={require("../assets/img/Sarsa/buy2.png")} />
                                    </div>
                                    <div class="team-title">
                                        <h3>Flexible Benefits</h3>
                                        <p>Life insurance and many other insurance policies can be used as a tool for long term saving and tax planning.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="team-box">
                                    <div class="team-img">
                                            <img src={require("../assets/img/Sarsa/icon-3.png")} />
                                    </div>
                                    <div class="team-title">
                                        <h3>Saving you money and time with Sarsa </h3>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <Zoom>
                <Dedicated />
            </Zoom>
            
        </React.Fragment>
    );
};
export default Services;
