import React from "react";
import { Carousel } from "react-bootstrap"
const Banner = (props) => {
    return (
        <div className="slideshow-block">
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={require("./assets/images/slideshow/01-01.png")}
                    alt="First slide"
                />
                <Carousel.Caption className="text-left">
                    <h1 class="featurette-heading">
                        PROTECT<br />
                        <span>YOUR FAMILY</span>
                    </h1>
                    <p>
                        Our team includes experienced professionals and <br />
                            young talent. This gives both expertise and dynamism.
                        </p>
                    <p><a class="btn btn-lg btn-success" href="#" role="button">PROTECT NOW</a></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={require("./assets/images/slideshow/02-01.png")}
                    alt="Second slide"
                />

                <Carousel.Caption className="text-left">
                    <h1 class="featurette-heading">
                        PROTECT<br />
                        <span>YOUR VEHICLE</span>
                    </h1>
                    <p>
                        Our team includes experienced professionals and <br />
                            young talent. This gives both expertise and dynamism.
                        </p>
                    <p><a class="btn btn-lg btn-success" href="#" role="button">PROTECT NOW</a></p>

                </Carousel.Caption>
            </Carousel.Item>

            </Carousel>
        </div>

    );
};
export default Banner;
