import React from "react";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChildBtn } from "../../ButtonClass";
const HowWork = (props) => {
    return (
        <section className="PR_Section1 about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="about-image">
                            <img src={require("../assets/img/Mandi/hero-forex.svg")} alt="image" />
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="about-content">
                            <span>About company</span>
                            <h3>Insuarnce Mandi</h3>
                            <p>Insuarnce Mandi is a product of Sanyog Insurance Brokers India Private Limited is a direct insurance broker delivering insurance and broking services to corporate clients in India.We at Sanyog strongly believe in service first and are committed to deliver end to end solutions to our clients using state of the art technology. We intend on using their expertise in delivering insurance and technology solutions for the Commercial and Retail broking market. Our goal is to innovate and grow Insurance business with our core values of Trust, Transparency and Transcend.</p>

                            <div class="about-btn">
                                <Link tag={Link} className={ChildBtn() + " default-btn"} to="/About">
                                    View More
                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HowWork;
