import React from "react";
import "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const OurWorks = (props) => {
    let settings = {
        slidesToShow: 4,

        autoplay: true,
        slidesToScroll: 1,
        infinite: true,

    };
    return (
        <section class="step-bg pt50 pb80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 v-center">
                        <div class="about-company common-heading m-text-c pr50">
                            <h2 class="mb20 aos-init" data-aos="fade-up" data-aos-delay="100">Choose and Apply for <em>personal credit</em> products on <span class="fw3">Pora Insurance</span></h2>
                            <p data-aos="fade-up" data-aos-delay="100" class="aos-init">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                    <div class="col-lg-7 v-center m-mt60">
                        <div class="row divrightbdr">
                            <div class="col-lg-6">
                                <div class="steps-div mt30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                    <div class="steps-icons-1">	<img src={require("../assets/img/Vedant/choice.png")} alt="steps" /> </div>
                                    <h4 class="mb10">Wide Choice</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                </div>
                                <div class="steps-div mt30 aos-init" data-aos="fade-up" data-aos-delay="300">
                                    <div class="steps-icons-1">	<img src={require("../assets/img/Vedant/credit-card.png")} alt="steps" /> </div>
                                    <h4 class="mb10">Easy Access to Credit</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 mt60 m-m0">
                                <div class="steps-div mt30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <div class="steps-icons-1">	<img src={require("../assets/img/Vedant/easy.png")} alt="steps" /> </div>
                                    <h4 class="mb10">Safe &amp; Secure</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                </div>
                                <div class="steps-div mt30 aos-init" data-aos="fade-up" data-aos-delay="500">
                                    <div class="steps-icons-1">	<img src={require("../assets/img/Vedant/customers.png")} alt="steps" /> </div>
                                    <h4 class="mb10">Customer First</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default OurWorks;
