/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import AccordionData from "./AccordionData";
const Accordian = (props) => {
    const cd = AccordionData.map((data) => {
        return (
            <React.Fragment>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey={data.id} key={data.id}>
                        {data.title}
                        <span className={data.class}>
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                        <Card.Body>{data.body}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <br />
            </React.Fragment>
        );
    });
    return (
        <div className="process-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="text-center mb-5">Frequently Asked Questions</h3>
                        <p></p>
                    </div>
                </div>
                <Accordion>{cd} </Accordion>

            </div>
        </div>
    );
};
export default Accordian;
