import React from 'react';
import "./loaderOverlay.css"
const LoaderOverlay=(props)=>{
return(
    <div className="modal ui-draggable show text-center">
    <svg width="200" height="200" id="svg">
    <circle id="dot1" class="shape" />
    <circle id="dot2" class="shape" />
    <circle id="dot3" class="shape" />
    <circle id="dot4" class="shape" />
  </svg>
    </div>
   
)
}
export default LoaderOverlay