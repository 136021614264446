/* eslint-disable */
import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
import ButtonClass from '../ButtonClass';
import ProgressBarBox from '../components/ProgressBarBox';

export class HealthProposal extends Component {
    InsuredData = [];
    constructor(props) {
        super(props);
        this.state = {
            InsurerData: null,
            ClientID: null,
            PolicyStartDate: null,
            PolicyEndDate: null,
            ProposerName: null,
            ProposerEmail: null,
            ProposerPan: null,
            ProposerMobile: null,
            ProposerDOB: null,
            ProposerGender: "M",
            ProposerOccupation: null,
            ProposerAnnualIncome: null,
            ProposerFatherName: null,
            ProposerNomineeName: null,
            ProposerNomineeRelation: null,
            ProposerNomineeDOB: null,
            GotoInsuredDetails: false,
            GotoCommunicationAddress: false,
            CommunicationStateList: [],
            CommunicationState: null,
            CommunicationCityList: [],
            CommunicationCity: null,
            CommunicationAddress1: null,
            CommunicationAddress2: null,
            CommunicationAddress3: null,
            CommunicationPinCode: null,
            ProposerOccupationList: [],
            CompaniesOtherMasterList: [],
            ProposerNomineeRelationList: [],
            AnnualIncomeList: [{ Name: "Upto 5L", ID: 500000 }, { Name: "5-10L", ID: 1000000 }, { Name: "10-15L", ID: 1500000 }, { Name: "15-20L", ID: 2000000 }, { Name: "ABOVE20L", ID: 2500000 }],
            ProposerSalutation: "",
            InsuredPerson: [{ id: 0, text: "First" }, { id: 1, text: "Second" }, { id: 2, text: "Third" }, { id: 3, text: "Fourth" }, { id: 4, text: "Fifth" }, { id: 5, text: "Sixth" }, { id: 6, text: "Seventh" }, { id: 7, text: "Eight" }],
            propsalDetails: true,
            IsMessage: false,
            MessageBody: null,
            InsuredList: [],
            InsuredRelationShiplist: [],
            InsuredOccupationList: [],
            Feet: [{ Name: "1 FT", ID: 1 }, { Name: "2 FT", ID: 2 }, { Name: "3 FT", ID: 3 }, { Name: "4 FT", ID: 4 }, { Name: "5 FT", ID: 5 }, { Name: "6 FT", ID: 6 }, { Name: "7 FT", ID: 7 }, { Name: "8 FT", ID: 8 }],
            Inch: [{ Name: "0 Inch", ID: 0 }, { Name: "1 Inch", ID: 1 }, { Name: "2 Inch", ID: 2 }, { Name: "3 Inch", ID: 3 }, { Name: "4 Inch", ID: 4 }, { Name: "5 Inch", ID: 5 }, { Name: "6 Inch", ID: 6 }, { Name: "7 Inch", ID: 7 }, { Name: "8 Inch", ID: 8 }, { Name: "9 Inch", ID: 9 }, { Name: "10 Inch", ID: 10 }, { Name: "11 Inch", ID: 11 }],
            Disabled: "",
            Wait: "Review and do Payment",
            APIUrl: null,
            CRMAPI: null,
            key1: "0",
            key2: "",
            key3: "",
            key4: "",
            p1: false,
            p2: false,
            p3: false,
            p4: false,
            fields: {},
            errors: {},
            HealthQuestion: [],
            PinCode: null,
            QuestionSet: [],
            askMonthYear: false,
            yearList: [],
            Adult: null,
            goToPay: false,
            error1: [],
            error2: [],
            error3: [],
            isValid1: false,
            isValid2: false,
            isValid3: false,
            QuoteReq: {},
            maxProposerDate: "",
            PersonCount: null,
            //changes by abhishek
            IsShowList: false,
            stateValue: null,
            stateName: "",
            showState: false,
            cityValue: null,
            cityName: "",
            showCity: false,
            CommunicationStateListMaster: [],
            CommunicationCityListMaster: [],
            AddonsList: false,
            InsuredPersonHealthDeclaration: [],
            PersonId: null

        }
        // this.state.APIUrl = MasterGlobal.PortalAPI;
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm")

        this.state.InsurerData = this.props.location.state.data;
        this.state.ClientID = this.props.location.state.ClientID;
        this.state.PinCode = this.props.location.state.PinCode;
        this.state.Adult = this.props.location.state.Adult;
        this.state.QuoteReq = JSON.parse(window.sessionStorage.getItem("HealthReq"));

        if (this.state.QuoteReq !== null || this.state.QuoteReq !== undefined) {
            this.state.PersonCount = this.state.QuoteReq.ListOfInsurer.length;
        } else {
            this.state.PersonCount = this.state.Adult;
        }
        let quoteReq = JSON.parse(window.sessionStorage.getItem("HealthReq"));
        if (quoteReq !== null) {
            if (quoteReq.ListOfInsurer.length > 0) {
                let proposerDOB = quoteReq.ListOfInsurer[0].DateOfBirth.split(" ")[0];

                this.state.ProposerDOB = proposerDOB;
                let dd = proposerDOB.split("-")[2];
                let mm = proposerDOB.split("-")[1];
                let yy = parseInt(proposerDOB.split("-")[0]) - 4;
                this.state.ProposerNomineeDOB = yy + "-" + mm + "-" + dd;
            }
        }

        let dt = new Date();
        let day = parseInt(dt.getDate()) < 10 ? "0" + parseInt(dt.getDate()) : parseInt(dt.getDate());
        let month = (parseInt(dt.getMonth()) + 1) < 10 ? "0" + (parseInt(dt.getMonth()) + 1) : (parseInt(dt.getMonth()) + 1);
        let startyear = dt.getFullYear()
        let endyear = parseInt(dt.getFullYear()) + 1;
        let enddate = day - 1;
        this.state.PolicyStartDate = day + "/" + month + "/" + startyear;
        this.state.PolicyEndDate = enddate + "/" + month + "/" + endyear;
        this.state.maxProposerDate = startyear - 18 + "-" + month + "-" + day;
        this.setState({
            // APIUrl: this.state.APIUrl,
            // CRMAPI: this.state.CRMAPI,
            InsurerData: this.state.InsurerData,
            PolicyStartDate: day + "/" + month + "/" + startyear,
            PolicyEndDate: enddate + "/" + month + "/" + endyear
        })
        this.LoadCompaniesOtherMasterList();
        this.goBack = this.goBack.bind(this);
        this.LoadCompaniesOtherMasterList = this.LoadCompaniesOtherMasterList.bind(this)
        this.manageHealthQuestion = this.manageHealthQuestion.bind(this)
        this.makeYearList = this.makeYearList.bind(this);
        this.ManageInsuredList = this.ManageInsuredList.bind(this)
        this.findHealthQuestion();
        this.makeYearList();

    }
    componentDidMount() {

        if (this.state.QuoteReq.ListOfInsurer != undefined && this.state.QuoteReq.ListOfInsurer.length > 0) {
            for (let i = 0; i < this.state.QuoteReq.ListOfInsurer.length; i++) {
                this.state.InsuredPerson[i].InsurerDOB = this.state.QuoteReq.ListOfInsurer[i].DateOfBirth.split(" ")[0]
            }
        }
        let propReq = window.sessionStorage.getItem("HealthProReq");
        let keyid = window.sessionStorage.getItem("HealthKey");
        if (propReq !== null) {
            propReq = JSON.parse(propReq);
            console.log(propReq)
            this.state.ProposerName = propReq.CustomerFirstName + " " + propReq.CustomerLastName;
            this.state.ProposerEmail = propReq.CustomerEmail;
            this.state.ProposerMobile = propReq.MobileNumber;
            this.state.ProposerPan = propReq.panNumber;
            this.state.ProposerGender = propReq.ProposerGender;
            this.state.ProposerFatherName = propReq.FatherName;
            this.state.ProposerNomineeName = propReq.ProposerNomineeName;
            this.state.ProposerAnnualIncome = propReq.annualIncome;
            this.state.CommunicationAddress1 = propReq.UserAddress.AddressLine1;
            this.state.CommunicationAddress2 = propReq.UserAddress.AddressLine2;
            this.state.CommunicationAddress3 = propReq.UserAddress.AddressLine3;
            this.state.PinCode = propReq.UserAddress.PinCode;
            let nomDOB = propReq.ListOfInsurer[0].NomineeDOB;
            this.state.ProposerNomineeDOB = nomDOB.split("/")[2] + "-" + nomDOB.split("/")[0] + "-" + nomDOB.split("/")[1];
            let dd = propReq.proposerDob.split("/")[1];
            let mm = propReq.proposerDob.split("/")[0];
            let yy = propReq.proposerDob.split("/")[2];
            this.state.proposerDOB = yy + "-" + mm + "-" + dd;
            let p = propReq.ListOfInsurer.length;
            for (let i = 0; i < p; i++) {
                this.state.InsuredPerson[i].InsurerName = propReq.ListOfInsurer[i].FirstName + " " + propReq.ListOfInsurer[i].LastName;
                this.state.InsuredPerson[i].InsurerGender = propReq.ListOfInsurer[i].sex;
                this.state.InsuredPerson[i].InsurerMaritalStatus = propReq.ListOfInsurer[i].MaritalStatus;
                this.state.InsuredPerson[i].InsurerWeight = propReq.ListOfInsurer[i].weight;
                this.state.InsuredPerson[i].InsurerHeightFeet = propReq.ListOfInsurer[i].Feet;
                this.state.InsuredPerson[i].InsurerHeightInch = propReq.ListOfInsurer[i].Inch;
                dd = propReq.ListOfInsurer[i].dob.split("/")[1];
                mm = propReq.ListOfInsurer[i].dob.split("/")[0];
                yy = propReq.ListOfInsurer[i].dob.split("/")[2];
                this.state.InsuredPerson[i].InsurerDOB = yy + "-" + mm + "-" + dd;
            }
            if (propReq.HealthQuoteResponse.Key === keyid) {
                this.state.CommunicationState = propReq.UserAddress.StateID;

                this.state.ProposerOccupation = propReq.proOccupation;
                this.state.ProposerNomineeRelation = propReq.NomineeRel;
                this.state.ProposerSalutation = propReq.Salutation;
                for (let i = 0; i < p; i++) {
                    this.state.InsuredPerson[i].InsurerRelation = propReq.ListOfInsurer[i].InsuRelationship;
                    this.state.InsuredPerson[i].InsurerOccupation = propReq.ListOfInsurer[i].InsurOccupation;
                }
                this.bindCity(propReq.UserAddress.StateID)
                this.setState({ CommunicationState: propReq.UserAddress.StateID, CommunicationCity: propReq.UserAddress.CityID });
            }

        }

    }
    LoadCompaniesOtherMasterList() {
        // if(this.state.APIUrl.match("inshora")){
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/CompanyOtherMaster/HealthCompaniesOtherMasterList"
        }

        fetch("/bpi/WeatherForecast/CallGet", {
            //fetch(this.state.APIUrl + "/healthapi/api/CompanyOtherMaster/HealthCompaniesOtherMasterList", {
            method: "POST",
            body: JSON.stringify(Model),
            headers: { "Content-Type": "application/json" }
        }).then(Response => Response.json()).then(data => {
            let Occupation = [];
            let RelationShip = [];
            let CompanyID = this.state.InsurerData.CompanyID;
            let CompanyKeyVal = this.state.InsurerData.PlanName;
            let InsuredRelationShiplist = [];
            if (CompanyID === 105 || CompanyID === 120 || CompanyID === 149) {
                RelationShip = data.filter(item => (item.MasterType === "RELATION") && item.CompanyID === CompanyID);
            }
            else if (CompanyID === 132 || CompanyID === 112) {
                RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyID === CompanyID);
            }
            else if (CompanyID === 104) {

                if (CompanyKeyVal.includes("Comprehensive")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "Comprehensive" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "Comprehensive" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("FamilyHealthOptima")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "FamilyHealthOptima" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "FamilyHealthOptima" && item.CompanyID === CompanyID);

                }
                else if (CompanyKeyVal.includes("MediClassic")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal === "Redcarpet") {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "Redcarpet" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "Redcarpet" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("Redcarpetfmly")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "Redcarpetfmly" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "Redcarpetfmly" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("DiabetesFmly")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "DiabetesFmly" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "Redcarpet" && item.CompanyID === CompanyID);
                    //$scope.SUFFERINGDIABTES = data.filter(item => (item.MasterType === "SUFFERINGDIABTES") && item.CompanyKey === "DiabetesFmly" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("DiabetesInd")) {
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "DiabetesInd" && item.CompanyID === CompanyID);
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "Redcarpet" && item.CompanyID === CompanyID);
                    //$scope.SUFFERINGDIABTES = data.filter(item => (item.MasterType === "SUFFERINGDIABTES") && item.CompanyKey === "DiabetesInd" && item.CompanyID === CompanyID);

                }

                else if (CompanyKeyVal.includes("ArogyaSanjeeviniIndividual")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "ArogyaSanjeeviniIndividual" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "ArogyaSanjeeviniIndividual" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("ArogyaSanjeeviniFloater")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "ArogyaSanjeeviniFloater" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "ArogyaSanjeeviniFloater" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("YoungStarIndividual")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "YoungStarIndividual" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "YoungStarIndividual" && item.CompanyID === CompanyID);
                }
                else if (CompanyKeyVal.includes("YoungStarFloater")) {
                    RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "MediClassic" && item.CompanyID === CompanyID);
                    InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "YoungStarFloater" && item.CompanyID === CompanyID);
                    Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyKey === "YoungStarFloater" && item.CompanyID === CompanyID);
                }

            }
            else { RelationShip = data.filter(item => (item.MasterType === "NOMINEE RELATION") && item.CompanyID === CompanyID); }
            if (CompanyID !== 104)
                InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION" || item.MasterType === "RELATION") && item.CompanyID === CompanyID);
            if (CompanyID !== 104)
                Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyID === CompanyID);
            if (CompanyID === 114)
                Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyID === CompanyID && item.CompanyKey === "Secure-1");

            if (CompanyID === 132) {

                InsuredRelationShiplist = data.filter(item => (item.MasterType === "Relation" || item.MasterType === "RELATION") && item.CompanyID === CompanyID);
                RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyID === CompanyID);
                Occupation = data.filter(item => item.MasterType === "OCCUPATION" && item.CompanyID === 120);
            }
            if (CompanyID === 115) {
                RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyID === CompanyID);
                InsuredRelationShiplist = data.filter(item => (item.MasterType === "PARTYRELATION") && item.CompanyID === CompanyID);
                Occupation = data.filter(item => (item.MasterType === "OCCUPATIONCODE") && item.CompanyID === CompanyID);
            }
            if (CompanyID === 124) {
                RelationShip = data.filter(item => (item.MasterType === "NOMINEE RELATION") && item.CompanyID === CompanyID);
                InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyID === CompanyID);
                Occupation = data.filter(item => (item.MasterType === "OCCUPATION") && item.CompanyID === CompanyID);

            }
            if (CompanyID === 148) {
                RelationShip = data.filter(item => (item.MasterType === "NOMINEERELATION") && item.CompanyKey === "PRO" && item.CompanyID === CompanyID);
                InsuredRelationShiplist = data.filter(item => (item.MasterType === "RELATION") && item.CompanyKey === "PRO" && item.CompanyID === CompanyID);
                Occupation = data.filter(item => (item.MasterType === "OCCUPATION") && item.CompanyKey === "PRO" && item.CompanyID === CompanyID);

            }
            if (CompanyID === 120) {
                Occupation = data.filter(item => (item.MasterType === "OCCUPATION") && item.CompanyID === CompanyID);
            }

            this.setState({
                ProposerOccupationList: Occupation,
                CompaniesOtherMasterList: data,
                ProposerNomineeRelationList: RelationShip,
                InsuredRelationShiplist: InsuredRelationShiplist,
                InsuredOccupationList: Occupation
            })

        });








        // if (this.state.InsurerData.CompanyID == 103) {
        //     Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", this.state.InsurerData.CompanyID)
        //     RelationShip = this.FilterCompaniesOtherMasterList(data, "NOMINEE RELATION", this.state.InsurerData.CompanyID)
        // }
        // else if(this.state.InsurerData.CompanyID == 132) {
        //     Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", 120)
        //     RelationShip = this.FilterCompaniesOtherMasterList(data, "NOMINEERELATION", this.state.InsurerData.CompanyID)
        // }
        // else {
        //     Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", this.state.InsurerData.CompanyID)
        //     RelationShip = this.FilterCompaniesOtherMasterList(data, "RELATION", this.state.InsurerData.CompanyID)
        // }

        //     this.setState({
        //         ProposerOccupationList: Occupation,
        //         CompaniesOtherMasterList: data,
        //         ProposerNomineeRelationList: RelationShip,
        //     })

        // });
        //}
        // else{fetch(this.state.APIUrl + "/healthapi/api/CompanyOtherMaster/HealthCompaniesOtherMasterList", {
        //     //fetch(this.state.APIUrl + "/healthapi/api/CompanyOtherMaster/HealthCompaniesOtherMasterList", {
        //     method: "GET",
        //     headers: { "Content-Type": "application/json" }
        // }).then(Response => Response.json()).then(data => {
        //     let Occupation = null;
        //     let RelationShip = null;
        //     if (this.state.InsurerData.CompanyID == 103) {
        //         Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", this.state.InsurerData.CompanyID)
        //         RelationShip = this.FilterCompaniesOtherMasterList(data, "NOMINEE RELATION", this.state.InsurerData.CompanyID)
        //     }
        //     else if(this.state.InsurerData.CompanyID == 132) {
        //         Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", 120)
        //         RelationShip = this.FilterCompaniesOtherMasterList(data, "NOMINEERELATION", this.state.InsurerData.CompanyID)
        //     }
        //     else {
        //         Occupation = this.FilterCompaniesOtherMasterList(data, "OCCUPATION", this.state.InsurerData.CompanyID)
        //         RelationShip = this.FilterCompaniesOtherMasterList(data, "RELATION", this.state.InsurerData.CompanyID)
        //     }

        //     this.setState({
        //         ProposerOccupationList: Occupation,
        //         CompaniesOtherMasterList: data,
        //         ProposerNomineeRelationList: RelationShip,
        //     })

        // });}

    }
    FilterCompaniesOtherMasterList(listData, MasterType, CompID) {
        let Occupation = listData.filter(row => row.MasterType == MasterType && row.CompanyID == CompID);
        console.log(Occupation)
        return Occupation
    }
    async findHealthQuestion() {

        let PlanName = this.state.InsurerData.CompanyID == 124 ? "DIGIT HEALTH CARE" : this.state.InsurerData.CompanyID == 108 ? "HealthTotal" : this.state.InsurerData.CompanyID == 148 ? "QBE" : this.state.InsurerData.PlanName;
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/Geo/GetPEDListBYCompany?compid=" + this.state.InsurerData.CompanyID + "&PlanName=" + PlanName,

        }
        fetch("/bpi/WeatherForecast/CallGet", {
            method: "POST",
            body: JSON.stringify(Model),
            headers: { "Content-Type": "application/json" }
        }).then(Response => Response.json()).then(data => {

            const Person = [];
            const QuestionSet = [];
            let Updata = data.filter((r, i) => r.Value = "NO");
            data.filter(r => QuestionSet.push({
                id: 0,
                Qid: null,
                Type: null
            }))
            this.state.InsuredList.forEach(() => Person.push(Updata));
            console.log(data)
            this.setState({ HealthQuestion: Updata, InsuredPersonHealthDeclaration: Person, QuestionSet: QuestionSet });

        })

    }
    dobManage(data) {
        if (data !== null && data.includes("-")) {
            let element = data.split("-");
            let dd = element[2];
            let mm = element[1];
            let yy = element[0]

            return mm + "/" + dd + "/" + yy;
        }
        return data
    }
    handle = (e) => {




        // if (e.target.name == "ProposerMobile") {
        //     let ss = e.target.value;
        //     if (ss.length > 10)
        //         this.setState({ [e.target.name]: ss.substring(0, 10) })
        // }
        // else
        console.log(e.target.value);
        if (e.target.type == "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }

        this.setState({ [e.target.name]: e.target.value })
        switch (e.target.name) {
            case "ProposerDOB":
                this.state.InsuredPerson[0].InsurerDOB = e.target.value;
                break;
            case "ProposerName": this.state.InsuredPerson[0].InsurerName = e.target.value;
                break;
            case "CommunicationState": this.bindCity(e.target.value);
                break;
        }


    }
    bindCity(stateID) {
        let CompanyID = this.state.InsurerData.CompanyID;
        let splplan = this.state.InsurerData.PlanName
        let stateid = CompanyID === 116 || CompanyID === 112 || CompanyID === 124 || CompanyID === 148 ? stateID + "~" + this.state.PinCode :
            CompanyID === 104 && splplan.includes("Arogya") ? stateID + "~" + this.state.PinCode + "~" + "Arogya" : CompanyID === 104 && !splplan.includes("Arogya") ? stateID + "~" + this.state.PinCode : stateID.split("~")[0];

        //let value = e.target.value.includes("~") ? e.target.value.split("~")[0] : e.target.value
        if (CompanyID === 20) {
            stateid = "0" + stateID;
        }
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/Geo/BindHealthCity?CompanyType=" + this.state.InsurerData.CompanyID + "&StateID=" + stateid
        }
        axios.post("/bpi/WeatherForecast/CallGet", Model)
            .then(data => {
                this.setState({ CommunicationCityList: data.data });
            })


    }
    loadInsuredAdd() {
        let insurer = {
            InsurerName: null,
            InsurerRelation: null,
            InsurerGender: null,
            InsurerMaritalStatus: null,
            InsurerDOB: null,
            InsurerHeightFeet: null,
            InsurerHeightInch: null,
            InsurerWeight: null,
            InsurerOccupation: null
        }
        let insurerList = [];
        for (var i = 0; i < this.state.Adult; i++) {
            insurerList.push(insurer);
        }
        this.setState({ InsuredList: insurerList })
        //InsuredList
    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    manageTabSection(first, second) {
        let leftTabId = "left-tabs-example-tab-";

        let rightTabId = "left-tabs-example-tabpane-";
        try {
            document.getElementById(leftTabId + first).classList.remove("active");
            document.getElementById(leftTabId + second).classList.add("active");
            document.getElementById(rightTabId + first).classList.remove("active");
            document.getElementById(rightTabId + first).classList.remove("show");
            document.getElementById(rightTabId + second).classList.add("active");
            document.getElementById(rightTabId + second).classList.add("show");
        } catch (err) { }
    }
    selectTabSection(first, second) {
        document.querySelectorAll(".active").forEach((r) => r.classList.remove("active"));
        this.manageTabSection(first, second)
    }
    Next(Goto) {
        let flag = true;
        let errors = {};
        if (Goto == "GotoInsuredDetails") {
            if (this.state.ProposerName == null || !this.state.ProposerName.includes(" ")) {
                flag = false;
                errors["ProposerName"] = "Full Name is required.\n"
            }
            if (this.state.ProposerEmail == null || !this.validateEmail(this.state.ProposerEmail)) {
                flag = false;
                errors["ProposerEmail"] = "Proposer Email is not valid.\n"
            }
            /* if (
                 this.state.ProposerPan == null ||
                 this.state.ProposerPan.length < 10
             ) {
                 flag = false;
                 errors["ProposerPan"] = "Pan is not valid.\n";
             }*/
            if (this.state.ProposerMobile == null || this.state.ProposerMobile.length !== 10) {
                flag = false;
                errors["ProposerMobile"] = "Mobile is not valid.\n"
            }
            if (this.state.ProposerDOB == null) {
                flag = false;
                errors["ProposerDOB"] = "Proposer DOB is not valid.\n"
            } else
                if (this.state.ProposerDOB !== null) {
                    let d1 = new Date();
                    let d2 = new Date(this.state.ProposerDOB);
                    let diff = (d1.getTime() - d2.getTime()) / 1000;
                    diff /= 60 * 60 * 24;
                    let r = Math.abs(Math.round(diff / 365.25));
                    if (r < 18) {
                        errors["ProposerDOB"] = "Only 18+ or 18 people";
                        flag = false;
                    }
                }
            // if (this.state.ProposerDOB == null) {
            //     flag = false;
            //     errors[""] = "Proposer DOB is not valid.\n"
            // }
            if (this.state.ProposerOccupation == null || this.state.ProposerOccupation == "select") {
                flag = false;
                errors["ProposerOccupation"] = "Select Proposer Occupation.\n"
            }
            // if (this.state.ProposerOccupation == null) {
            //     flag = false;
            //     errors[""] = "Select Proposer Occupation.\n"
            // }
            if (this.state.ProposerAnnualIncome == null || this.state.ProposerAnnualIncome == "select") {
                flag = false;
                errors["ProposerAnnualIncome"] = "Select Proposer Annual Income.\n"
            }
            if (this.state.ProposerNomineeName == null || !this.state.ProposerNomineeName.includes(" ")) {
                flag = false;
                errors["ProposerNomineeName"] = "Proposer Nominee Name is required.\n"
            }
            if (this.state.ProposerFatherName == null) {
                flag = false;
                errors["ProposerFatherName"] = "Proposer Father Name is required.\n"
            }
            if (this.state.ProposerSalutation === null || this.state.ProposerSalutation === "select") {
                flag = false;
                errors["ProposerSalutation"] = "Proposer Salutation is required.\n"
            }
            // if (this.state.ProposerNomineeName == null ) {
            //     flag = false;
            //     errors["ProposerNomineeRelation"] = "Proposer Nominee Relation is required.\n"
            // }
            if (this.state.ProposerNomineeRelation == null || this.state.ProposerNomineeRelation == "select") {
                flag = false;
                errors["ProposerNomineeRelation"] = "Proposer Nominee Relation is required.\n"
            }
            if (this.state.ProposerNomineeDOB == null) {
                flag = false;
                errors["ProposerNomineeDOB"] = "Proposer Nominee DOB is required.\n"
            } else
                if (this.state.ProposerNomineeDOB !== null) {
                    let d1 = new Date();
                    let d2 = new Date(this.state.ProposerNomineeDOB);
                    let diff = (d1.getTime() - d2.getTime()) / 1000;
                    diff /= 60 * 60 * 24;
                    let r = Math.abs(Math.round(diff / 365.25));
                    if (r < 18) {
                        errors["ProposerNomineeDOB"] = "Only 18+ or 18 people";
                        flag = false;
                    }
                }
            if (flag == true) {
                this.manageTabSection("first", "second");
                if (this.state.QuoteReq.ListOfInsurer != undefined && this.state.QuoteReq.ListOfInsurer.length > 0) {
                    for (let i = 0; i < this.state.QuoteReq.ListOfInsurer.length; i++) {
                        let rList;
                        rList = this.state.InsuredRelationShiplist.filter(row => row.Name.toLowerCase().match(this.state.QuoteReq.ListOfInsurer[i].ProposarRelationton.toLowerCase()));
                        console.log(this.state.InsuredRelationShiplist);
                        if (rList.length > 0) {
                            this.state.InsuredPerson[i].InsurerRelation = rList[0].CombindID;
                            this.state.InsuredPerson[i].InsurerGender = this.state.QuoteReq.ListOfInsurer[i].Gender
                            this.setState({ InsuredPerson: this.state.InsuredPerson })
                        }
                    }

                }

                this.setState({
                    propsalDetails: true,
                    key2: "1",
                    p1: true,
                    errors: {}
                })
                //     let RELATION
                //     if(this.state.InsurerData.CompanyID==132)
                //      RELATION = this.FilterCompaniesOtherMasterList(this.state.CompaniesOtherMasterList, "Relation", this.state.InsurerData.CompanyID)
                //    else {
                //      RELATION = this.FilterCompaniesOtherMasterList(this.state.CompaniesOtherMasterList, "RELATION", this.state.InsurerData.CompanyID)

                //    }
                //     this.setState({
                //         InsuredRelationShiplist: RELATION,
                //         InsuredOccupationList: this.state.ProposerOccupationList
                //     })
                this.loadInsuredAdd();
            }
            else {
                this.setState({
                    errors
                    // MessageBody: Message,
                    // IsMessage: true
                })
            }
        }
        else if (Goto == "GotoCommunicationAddress") {
            flag = true;
            errors = {};
            for (var i = 0; i < this.state.InsuredList.length; i++) {
                if (this.state.InsuredPerson[i].InsurerName == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerNameError"] = "Insurer name required.";

                }
                else
                    if (!this.state.InsuredPerson[i].InsurerName.includes(" ")) {
                        flag = false;
                        this.state.InsuredPerson[i]["InsurerNameError"] = "Insurer full name required.";


                    } else {
                        this.state.InsuredPerson[i]["InsurerNameError"] = "";
                    }
                if (this.state.InsuredPerson[i].InsurerRelation == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerRelationError"] = "Insurer relation required.";

                }
                else {
                    if (this.state.InsuredPerson[i].InsurerRelation == "select") {
                        flag = false;
                        this.state.InsuredPerson[i]["InsurerRelationError"] = "Insurer relation required.";

                    } else {
                        let list = this.state.InsuredRelationShiplist.filter(r => r.CombindID === this.state.InsuredPerson[i].InsurerRelation);
                        if (list.length > 0) {
                            let v = list[0].Name.toLowerCase();
                            if ((v.match("mother") || v.match("sister") || v.match("wife") || v.match("daughter")) && this.state.InsuredPerson[i]["InsurerGender"] == "M") {
                                flag = false;
                                this.state.InsuredPerson[i]["InsurerGenderError"] = "Gender is not valid";
                            } else this.state.InsuredPerson[i]["InsurerGenderError"] = "";

                        }
                    }
                }
                if (this.state.InsuredPerson[i].InsurerGender == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerGenderError"] = "Insurer Gender required.";

                } else this.state.InsuredPerson[i]["InsurerGenderError"] = "";
                if (this.state.InsuredPerson[i].InsurerMaritalStatus == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerMaritalStatusError"] = "Insurer Marital status  required.";

                } else {
                    let list = this.state.InsuredRelationShiplist.filter(r => r.CombindID === this.state.InsuredPerson[i].InsurerRelation);
                    if (list.length > 0) {
                        let v = list[0].Name.toLowerCase();

                        if ((v.match("spouse") || v.match("wife")) && this.state.InsuredPerson[i]["InsurerMaritalStatus"] === "N") {

                            flag = false;
                            this.state.InsuredPerson[i]["InsurerMaritalStatusError"] = "Marrital Status is not valid";


                        } else this.state.InsuredPerson[i]["InsurerMaritalStatusError"] = "";

                    }
                }
                if (this.state.InsuredPerson[i].InsurerDOB == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerDOBError"] = "Insurer DOB  required.";

                    // }else if(this.state.InsuredPerson[i].InsurerDOB!==undefined){
                    //     let d1 = new Date();
                    //     let d2 = new Date(this.state.InsuredPerson[i].InsurerDOB);
                    //     let diff = (d1.getTime() - d2.getTime()) / 1000;
                    //     diff /= 60 * 60 * 24;
                    //     let r = Math.abs(Math.round(diff / 365.25));
                    //     if (r < 18) {
                    //       errors["InsurerDOB"] = "Only 18+ or 18 people";
                    //       flag=false;
                    //       break;
                    // }

                } else this.state.InsuredPerson[i]["InsurerDOBError"] = "";

                if (this.state.InsuredPerson[i].InsurerWeight == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerWeightError"] = "Insurer Weight required.";

                } else if (this.state.InsuredPerson[i].InsurerWeight < 3 || this.state.InsuredPerson[i].InsurerWeight > 150) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerWeightError"] = "Insurer Weight must be greater than 3 and less than 150kg";

                } else this.state.InsuredPerson[i]["InsurerWeightError"] = "";
                if (this.state.InsuredPerson[i].InsurerHeightFeet == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerHeightFeetError"] = "Insurer Height required.";

                } else this.state.InsuredPerson[i]["InsurerHeightFeetError"] = ""
                if (this.state.InsuredPerson[i].InsurerHeightInch == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerHeightInchError"] = "Insurer Height required.";

                } else this.state.InsuredPerson[i]["InsurerHeightInchError"] = "";
                if (this.state.InsuredPerson[i].InsurerOccupation == undefined) {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerOccupationError"] = "Insurer Occupation required.";

                } else if (this.state.InsuredPerson[i].InsurerOccupation == "select") {
                    flag = false;
                    this.state.InsuredPerson[i]["InsurerOccupationError"] = "Insurer Occupation required.";

                } else this.state.InsuredPerson[i]["InsurerOccupationError"] = "";
            }
            if (flag == true) {
                this.manageTabSection("second", "Third")
                for (var i = 0; i < this.state.InsuredList.length; i++) {
                    delete this.state.InsuredPerson[i]["InsurerNameError"];
                    delete this.state.InsuredPerson[i]["InsurerRelationError"];
                    delete this.state.InsuredPerson[i]["InsurerGenderError"];
                    delete this.state.InsuredPerson[i]["InsurerMaritalStatusError"];
                    delete this.state.InsuredPerson[i]["InsurerDOBError"];
                    delete this.state.InsuredPerson[i]["InsurerWeightError"];
                    delete this.state.InsuredPerson[i]["InsurerHeightFeetError"];
                    delete this.state.InsuredPerson[i]["InsurerHeightInchError"];
                    delete this.state.InsuredPerson[i]["InsurerOccupationError"];
                }

                this.setState({
                    GotoInsuredDetails: true,
                    key3: "2",
                    p2: true,
                    errors: {}
                })
                let PinCode = this.state.InsurerData.CompanyID === 104 && this.state.InsurerData.PlanName.includes("Arogya") ? this.state.InsurerData.PinCode + "~" + "Arogya" : this.state.InsurerData.PinCode;

                let Model = {
                    URL: this.state.APIUrl + "/healthapi/api/Geo/BindHealthStateOnly?CompanyType=" + this.state.InsurerData.CompanyID + "&Pincode=" + PinCode
                }
                console.log(Model.URL)
                axios.post("/bpi/WeatherForecast/CallGet", Model)
                    .then(data => {
                        this.setState({
                            CommunicationStateList: data.data,
                            CommunicationPinCode: this.state.InsurerData.PinCode
                        })
                    })


            }
            else {
                this.setState({
                    errors
                    // MessageBody: Message,
                    // IsMessage: true
                })
            }
        }
        else if (Goto == "GotoQuestionList") {
            if (this.state.CommunicationAddress1 == null) {
                flag = false;
                errors["CommunicationAddress1"] = "Address required.";

            }
            if (this.state.CommunicationAddress2 == null) {
                flag = false;
                errors["CommunicationAddress2"] = "Address required.";

            }
            if (this.state.CommunicationState == null) {
                flag = false;
                errors["CommunicationState"] = "Communication state required.";

            }
            if (this.state.CommunicationCity == null) {
                flag = false;
                errors["CommunicationCity"] = "Communication City required.";

            }
            if (this.state.CommunicationPinCode == null) {
                flag = false;
                errors["CommunicationPinCode"] = "Communication Pin Code required.";

            }

            if (flag === true) {
                this.manageTabSection("Third", "Four")
                this.setState({
                    GotoInsuredDetails: true,
                    key4: "3",
                    p3: true,
                    goToPay: true,
                    errors: {}
                })
            }
            else { this.setState({ errors }) }
        }
        this.setState({
            [Goto]: true
        });
    }
    CloseMessage = (e) => {
        this.setState({ IsMessage: !this.state.IsMessage })
    }
    ManageInsuredList(index, prop, value) {
        if (prop == "InsurerName" && index == 0) {
            this.state.InsuredPerson[0].InsurerName = value;
            this.setState({ InsuredPerson: this.state.InsuredPerson })
        }
        switch (prop) {
            case "InsurerRelation": let list = this.state.InsuredRelationShiplist.filter(r => r.CombindID === value);
                if (list.length > 0) {
                    let v = list[0].Name.toLowerCase();
                    if (v.match("mother") || v.match("sister") || v.match("wife") || v.match("daughter")) {
                        this.state.InsuredPerson[index]["InsurerGender"] = "F";
                    }
                    else {
                        this.state.InsuredPerson[index]["InsurerGender"] = "M";
                    }
                    if (v.match("son") || v.match("wife") || v.match("spouse") || v.match("doughter")) {
                        this.state.InsuredPerson[0]["InsurerMaritalStatus"] = "M";
                    } else this.state.InsuredPerson[0]["InsurerMaritalStatus"] = "N";
                }
                break;

        }

        this.state.InsuredPerson[index][prop] = value;
        this.setState({ InsuredPerson: this.state.InsuredPerson });

        console.log(this.state.InsuredPerson);
    }
    GotoShowProposal = e => {

        let fname = this.state.ProposerName.includes(" ") ? this.state.ProposerName.split(" ")[0].trim() : this.state.ProposerName
        let lname = this.state.ProposerName.includes(" ") ? this.state.ProposerName.trim().replace(this.state.ProposerName.split(" ")[0].trim(), "").trim() : this.state.ProposerName

        let Req = {
            "SumInsured": this.state.InsurerData.SumInsured,
            "PolicyTerm": this.state.InsurerData.PolicyTerm,
            "PinCode": this.state.InsurerData.PinCode,
            "MobileNumber": this.state.ProposerMobile,
            "ClientURL": this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl,
            "TierID": this.state.InsurerData.TierID === undefined ? 1 : this.state.InsurerData.TierID,
            "EnquiryNo": this.state.InsurerData.EnquiryNo,
            "StartDate": this.state.InsurerData.StartDate,
            "EndDate": this.state.InsurerData.EndDate,//this.state.InsurerData.EndDate,
            "ProposerName": this.state.ProposerName,
            "MarritalStatus": "M",
            FatherName: this.state.ProposerFatherName,
            "ProposerEmail": this.state.ProposerEmail,
            "ProposerOccupation": this.state.InsurerData.CompanyID == 104 || this.state.InsurerData.CompanyID === 120 ? this.state.ProposerOccupationList.filter((row) => row.CombindID == this.state.ProposerOccupation)[0].Code : this.state.ProposerOccupation,
            "proposerPhone": this.state.ProposerMobile,
            "ProposerGender": this.state.ProposerGender,
            "proposerDob": this.dobManage(this.state.ProposerDOB),
            "panNumber": this.state.ProposerPan,
            "gstTypeId": "",
            "gstIdNumber": "",
            "annualIncome": this.state.ProposerAnnualIncome,
            "HealthQuoteResponse": this.state.InsurerData,
            "CompanyName": this.state.InsurerData.CompanyName,
            CompanyID: this.state.InsurerData.CompanyID,

            "UserAddress": {
                "AddressLine1": this.state.CommunicationAddress1,
                "AddressLine2": this.state.CommunicationAddress2,
                "AddressLine3": this.state.CommunicationAddress3,
                "CityID": this.state.CommunicationCity,
                "StateID": this.state.CommunicationState,
                "PinCode": this.state.CommunicationPinCode,
                "StateCode": this.state.CommunicationState,
                "PinCodeLocality": this.state.CommunicationStateList.filter(row => row.CombindID == this.state.CommunicationState)[0].StateName,
                "CityName": this.state.CommunicationCityList.filter(row => row.CombindID == this.state.CommunicationCity)[0].CityName,
                "StateName": this.state.CommunicationStateList.filter(row => row.CombindID == this.state.CommunicationState)[0].StateName,
                "IsPrimary": false,
                "PerAddressLine1": "",
                "PerAddressLine2": "",
                "PerAddressLine3": "",
                "PerPinCodeLocality": "",
                "PerCityID": "",
                "PerStateID": "",
                "PerStateCode": "",
                "PerCityName": "",
                "PerStateName": "",


            },
            "ListOfInsurer": this.MergeInsurerList(),
            "ClientID": this.state.ClientID,

            "CustomerEmail": this.state.ProposerEmail,
            "CustomerFirstName": fname,
            "CustomerLastName": lname,
            "BasePremium": this.state.InsurerData.BasePremium,
            "ProposerNomineeName": this.state.ProposerNomineeName,
            "TotalPremium": this.state.InsurerData.TotalPremium,
            "aadharNumber": null,
            "PPCPED": false,
            proOccupation: this.state.ProposerOccupation,
            NomineeRel: this.state.ProposerNomineeRelation,
            Salutation: this.state.ProposerSalutation



        }
        window.sessionStorage.setItem("HealthProReq", JSON.stringify(Req))
        this.setState({
            Disabled: "disabled",
            Wait: "Wait..."
        })
        console.log(JSON.stringify(Req))
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/InsurerHealth/HealthProposalResponse",
            PostString: JSON.stringify(Req)
        }
        fetch("/bpi/WeatherForecast/CallCheck",
            {
                method: "POST",
                body: JSON.stringify(Model),
                headers: { "Content-Type": "application/json" }
            }).then(Response => Response.json()).then(data => {
                if (data.ErrorMessage === null && data !== "") {
                    if (this.state.APIUrl.match("enivesh")) {
                        data.Request = "";
                        data.RequestPaymentXML = "";
                        data.ResponseData = ""
                    }
                    let json = {
                        PropsalReq: Req,
                        PropsalRes: data
                    }

                    this.props.history.push("/HealthShowProposal", { PropsalData: json })
                }
                else {
                    this.setState({
                        Wait: "Review and do Payment",
                        Disabled: "",
                        IsMessage: true,
                        MessageBody: data == "" ? "Try Again" : data.ErrorMessage
                    })
                }
            }).catch(exception => {
                console.log(exception)
                this.setState({
                    Wait: "Review and do Payment",
                    Disabled: ""
                })
            })

    }
    MergeInsurerList() {

        let InsurerList = [];
        for (var i = 0; i < this.state.InsuredList.length; i++) {
            var data = {
                "FirstName": this.state.InsuredPerson[i].InsurerName.split(" ")[0].trim(),
                "LastName": this.state.InsuredPerson[i].InsurerName.trim().replace(this.state.InsuredPerson[i].InsurerName.split(" ")[0].trim(), "").trim(),
                "dob": this.dobManage(this.state.InsuredPerson[i].InsurerDOB),
                "AgeYear": this.getAge(this.state.InsuredPerson[i].InsurerDOB.replace("-", "/")),
                "sex": this.state.InsuredPerson[i].InsurerGender,
                "MaritalStatus": this.state.InsuredPerson[i].InsurerMaritalStatus,
                //Illness
                "illness": this.state.InsuredPersonHealthDeclaration[i],
                "Cignaillness": this.state.InsuredPersonHealthDeclaration[i],
                "Feet": this.state.InsuredPerson[i].InsurerHeightFeet,
                "Inch": this.state.InsuredPerson[i].InsurerHeightInch,
                "relationshipId": this.state.InsurerData.CompanyID == 104 || this.state.InsurerData.CompanyID === 112 ? this.state.InsuredRelationShiplist.filter((r) => r.CombindID == this.state.InsuredPerson[i].InsurerRelation)[0].Code : this.state.InsuredPerson[i].InsurerRelation,
                "RelationShipType": this.state.InsuredRelationShiplist.filter((row) => row.CombindID == this.state.InsuredPerson[i].InsurerRelation)[0].Name,
                "occupationId": this.state.InsurerData.CompanyID == 104 || this.state.InsurerData.CompanyID === 112 || this.state.InsurerData.CompanyID === 120 ? this.state.InsuredOccupationList.filter((row) => row.CombindID == this.state.InsuredPerson[i].InsurerOccupation)[0].Code : this.state.InsuredPerson[i].InsurerOccupation,
                "height": this.feettocm(this.state.InsuredPerson[i].InsurerHeightFeet, this.state.InsuredPerson[i].InsurerHeightInch),
                "weight": this.state.InsuredPerson[i].InsurerWeight,
                "NomineeRelationType": this.state.ProposerNomineeRelationList.filter(row => row.CombindID == this.state.ProposerNomineeRelation)[0].Name,
                "NomineeRelationCode": this.state.ProposerNomineeRelationList.filter(row => row.CombindID == this.state.ProposerNomineeRelation)[0].CompanyValue,
                "NomineeRelationID": this.state.ProposerNomineeRelation == null ? null : (this.state.InsurerData.CompanyID == 104 || this.state.InsurerData.CompanyID === 112) ? this.state.ProposerNomineeRelationList.filter(row => row.CombindID == this.state.ProposerNomineeRelation)[0].Code : this.state.ProposerNomineeRelation,
                "NomineeName": this.state.ProposerNomineeName,
                "NomineeDOB": this.dobManage(this.state.ProposerNomineeDOB),
                "NomineeTitle": this.state.ProposerSalutation === "" ? "MR" : this.state.ProposerSalutation.toUpperCase(),
                "diabetesMellitus": null,
                "BloodSugar": null,
                "serumCreatinine": null,
                "HBA1C": null,
                "isPersonalAccidentApplicable": "false",
                InsuRelationship: this.state.InsuredPerson[i].InsurerRelation,
                InsurOccupation: this.state.InsuredPerson[i].InsurerOccupation
            }
            if (this.state.InsurerData.CompanyID === 115) {
                data["illness"] = []
            } else {
                delete data["Cignaillness"];
            }
            InsurerList.push(data);
        }
        return InsurerList;
    }
    feettocm(Feet, Inch) {
        let cm = (Feet * 30.48) + (Inch * 2.54);
        let round = Math.round(cm);
        return round;
    }
    goBack() {
        this.props.history.goBack();
    }
    manageHealthQuestion(index, Qid, Type, value, pi) {
        let shallowCopy = this.state.InsuredPersonHealthDeclaration[pi];
        window.sessionStorage.setItem("shallowCopy", JSON.stringify(shallowCopy));
        shallowCopy = JSON.parse(window.sessionStorage.getItem("shallowCopy"));
        shallowCopy[index].Value = (value === "1" ? "YES" : "NO");
        this.state.InsuredPersonHealthDeclaration[pi] = shallowCopy;
        this.state.QuestionSet[index].id = value === "1" ? (index + 1) : 0;
        this.state.QuestionSet[index].Qid = value === "1" ? Qid : null;
        this.state.QuestionSet[index].Type = value === "1" ? Type : null;
        this.setState({ QuestionSet: this.state.QuestionSet, askMonthYear: value === "1" ? true : false, PersonId: pi, InsuredPersonHealthDeclaration: [...this.state.InsuredPersonHealthDeclaration] })
        console.log(this.state.InsuredPersonHealthDeclaration)
        window.sessionStorage.removeItem("shallowCopy")
        /*let data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        if (value == 1) {
            let QData = {
                id: index + 1,
                Qid: Qid,
                Type: Type
            };

            data.splice(index, 0, QData)
            this.setState({ askMonthYear: true, QuestionSet: data })
            this.state.HealthQuestion[index].Value = "YES"

        }
        else {
            let QData = {
                id: 0,
                Qid: "",
                Type: ""
            };
            data.splice(index, 0, QData)
            this.setState({ askMonthYear: true, QuestionSet: data })
            this.state.HealthQuestion[index].Value = "NO"
        }*/
    }
    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    updatePED(index, value, name, pi) {
        let shallowCopy = this.state.InsuredPersonHealthDeclaration[pi];
        window.sessionStorage.setItem("shallowCopy", JSON.stringify(shallowCopy));
        shallowCopy = JSON.parse(window.sessionStorage.getItem("shallowCopy"));
        shallowCopy[index][name] = value;
        this.state.InsuredPersonHealthDeclaration[pi] = shallowCopy;
        /* switch (name) {
             case "month":
                
                 this.state.HealthQuestion[index].Month = value;
                 break;
             case "year":
                 this.state.HealthQuestion[index].Year = value;
                 break;
             case "describe": this.state.HealthQuestion[index].Text = value;
                 break;
         }*/
    }
    makeYearList() {
        let date = new Date()
        let currentYear = date.getFullYear();
        for (let i = currentYear; i >= currentYear - 24; i--) {
            this.state.yearList.push(i)
        }
    }
    UrlF = (url) => {
        return url.replace("https://", "").replace("http://", "").replace("//", "https://")
    }

    HealthDeclaration() {

    }

    render() {
        this.state.InsuredList = [];
        const QuoteReq = this.state.QuoteReq;
        for (let i = 0; i < this.state.PersonCount; i++) {
            this.state.InsuredList.push(i);

        }
        return (
            <div className="NewProposal">
                <ProgressBarBox Progress="50%" />
                <div className="container">
                    <div className="proposalPageBox">

                        <div className="row BackBTNRow">
                            <div className="col-3 col-md-auto">
                                <a className="arw-btn" onClick={this.goBack}>
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </div>
                            <div className="col-9 col-md-10 col-lg-11 align-self-center">
                                <h3 className="f-32 font-regular mb-0">Back to Quotation</h3>
                            </div>
                        </div>
                        <div className="row proposalBox03">
                            <div className="col-sm-3 p-0">
                                <div className="proDetailsMM">
                                    <div className="rowDetailsMM">

                                        <img id="companylogo" src={this.state.InsurerData.UrlLogo} />
                                        <span id="planname" className="ng-binding">{this.state.InsurerData.PlanName}</span>

                                        <p className="enqueryNumber">
                                            <b>Enquiry#</b> <span id="EnquiryNo" className="ng-binding">{this.state.InsurerData.EnquiryNo}</span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM rowDetailsMM01">

                                        <p>
                                            Premium
                    <span id="netPremium"><b>₹ </b><b className="ng-binding">{Math.round(this.state.InsurerData.BasePremium)}</b></span>
                                        </p>
                                        <p>
                                            + GST (18%)
                    <span id="serviceTax"><b>₹ </b><b className="ng-binding">{Math.round(this.state.InsurerData.ServiceTax)}</b></span>
                                        </p>
                                        <p className="totalP">
                                            Total Premium (Premium + Tax)
                    <span id="TotalPremium"><b>₹ </b><b className="ng-binding">{Math.round(this.state.InsurerData.TotalPremium)}</b></span>
                                        </p>


                                    </div>
                                    <div className="rowDetailsMM addonsListPro">
                                        <p
                                            onClick={() =>
                                                this.setState({ AddonsList: !this.state.AddonsList })
                                            }
                                        >
                                            {" "}
                    Addons{" "}
                                            <i
                                                className={
                                                    "fas " +
                                                    (this.state.AddonsList ? "fa-minus" : "fa-plus")
                                                }
                                            ></i>
                                        </p>
                                        {this.state.AddonsList && (
                                            <ul>
                                                {QuoteReq.AnyRoomUpgrade && <li>Any Room Upgrade</li>}
                                                {QuoteReq.HealthCheck && <li>Health Check</li>}
                                                {QuoteReq.CriticalIllness && <li>Critical Illness</li>}
                                                {QuoteReq.HomeCare && <li>Home Care</li>}
                                                {QuoteReq.HospitalCash && <li>Hospital Cash</li>}
                                                {QuoteReq.MaternityCover && <li>Maternity Cover</li>}
                                                {QuoteReq.NCBSuper && <li>NCB Super</li>}
                                                {QuoteReq.RestoreBenefits && <li>Restore Benefits</li>}
                                                {QuoteReq.UAR && <li>Unlimited Auto Recharge</li>}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="rowDetailsMM">
                                        <p>
                                            Sum Insured
                    <span id="SumInsuredData"><b>₹ </b><b className="ng-binding">{this.state.InsurerData.SumInsured}</b></span>
                                        </p>
                                        <p>
                                            Policy Term
                    <span id="Term" className="ng-binding">{this.state.InsurerData.PolicyTerm} Year</span>
                                        </p>
                                        <p>
                                            Policy Start Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.state.PolicyStartDate}</span>
                                        </p>
                                        <p>
                                            Policy End Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.state.PolicyEndDate}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 p-0">
                                <div className="ProposalTabBox">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                        <div className="ProposalTabsLeft">
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" onClick={() => this.selectTabSection("second", "first")}>
                                                        {
                                                            this.state.p1 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Proposer Details
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" onClick={() => this.selectTabSection("first", "second")}>
                                                        {
                                                            this.state.p2 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Insured Details
                                                        </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Third" onClick={() => this.selectTabSection("Four", "Third")}>
                                                        {
                                                            this.state.p3 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Communication Address
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Four" onClick={() => this.selectTabSection("Third", "Four")}>
                                                        {
                                                            this.state.p4 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Insurer Medical History
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <div className="BimaPayProcess" style={{ display: "none" }}>
                                                <ul class="info-text-ul">
                                                    <li>
                                                        <span class="icon"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5H12.5C12.7652 6.5 13.0196 6.60536 13.2071 6.79289C13.3946 6.98043 13.5 7.23478 13.5 7.5V14.5C13.5 14.7652 13.3946 15.0196 13.2071 15.2071C13.0196 15.3946 12.7652 15.5 12.5 15.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.5 6.5V4C3.5 3.07174 3.86875 2.1815 4.52513 1.52513C5.1815 0.868749 6.07174 0.5 7 0.5V0.5C7.92826 0.5 8.8185 0.868749 9.47487 1.52513C10.1313 2.1815 10.5 3.07174 10.5 4V6.5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M7 12.5C7.82843 12.5 8.5 11.8284 8.5 11C8.5 10.1716 7.82843 9.5 7 9.5C6.17157 9.5 5.5 10.1716 5.5 11C5.5 11.8284 6.17157 12.5 7 12.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        </span>
                                            Secure transaction protected by EVSSL Certificate
                                    </li>
                                                    <li><span class="icon">
                                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0)">
                                                                <path d="M0.5 8H15.5L12.5 5H3.5L0.5 8Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M3.5 5L4.5 1H11.5L12.5 5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M12 16C13.3807 16 14.5 14.8807 14.5 13.5C14.5 12.1193 13.3807 11 12 11C10.6193 11 9.5 12.1193 9.5 13.5C9.5 14.8807 10.6193 16 12 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4 16C5.38071 16 6.5 14.8807 6.5 13.5C6.5 12.1193 5.38071 11 4 11C2.61929 11 1.5 12.1193 1.5 13.5C1.5 14.8807 2.61929 16 4 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M9.66009 12.644C9.20655 12.2296 8.61443 11.9999 8.00009 11.9999C7.38575 11.9999 6.79363 12.2296 6.34009 12.644" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0">
                                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </span>
                                        Data Security and Privacy Guaranteed</li>
                                                    <li><span class="icon"><svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.2029 7.23333C13.5858 6.6469 13.7897 5.96169 13.7897 5.26132C13.7897 4.56095 13.5858 3.87573 13.2029 3.28931" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M11.3804 6.22103C11.6011 5.9499 11.7216 5.61099 11.7216 5.26139C11.7216 4.9118 11.6011 4.57289 11.3804 4.30176" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M0.86377 11.4659V2.15909C0.86377 1.88483 0.972718 1.62181 1.16665 1.42788C1.36058 1.23395 1.6236 1.125 1.89786 1.125H15.341C15.6153 1.125 15.8783 1.23395 16.0723 1.42788C16.2662 1.62181 16.3751 1.88483 16.3751 2.15909V11.4659C16.3751 11.7402 16.2662 12.0032 16.0723 12.1971C15.8783 12.3911 15.6153 12.5 15.341 12.5H1.89786C1.6236 12.5 1.36058 12.3911 1.16665 12.1971C0.972718 12.0032 0.86377 11.7402 0.86377 11.4659Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M3.44897 9.91479H9.65352" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M11.7217 9.91479H13.7899" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M6.0342 3.71021H3.44897V5.77839H6.0342V3.71021Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M9.65352 5.77835C9.93908 5.77835 10.1706 5.54686 10.1706 5.26131C10.1706 4.97575 9.93908 4.74426 9.65352 4.74426C9.36796 4.74426 9.13647 4.97575 9.13647 5.26131C9.13647 5.54686 9.36796 5.77835 9.65352 5.77835Z" fill="#4DAAE8" />
                                                    </svg>
                                                    </span>
                                        Robust Payment Gateways
                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="ProposalTabsRight">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    {
                                                        this.state.propsalDetails ?
                                                            <section>
                                                                <div className="border-bottom">
                                                                    <div className="row">
                                                                        <div className="col-md-6 pb-3">

                                                                            <MaskedInput onChange={this.handle} onBlur={this.handle} value={this.state.ProposerName} name="ProposerName" className="packageForm"
                                                                                placeholder="Proposer Name" mask={[/[a-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                                guide={false} showMask={false} />
                                                                            <span className="txtError">{this.state.errors["ProposerName"]}</span>


                                                                        </div>
                                                                        <div className="col-md-6 pb-3"> <input type="text" className="packageForm"
                                                                            name="ProposerEmail"
                                                                            value={this.state.ProposerEmail}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            placeholder="Email Address" />
                                                                            <span className="txtError">{this.state.errors["ProposerEmail"]}</span>
                                                                        </div>
                                                                        <div className="col-md-6 pb-3">
                                                                            <MaskedInput onChange={this.handle} onBlur={this.handle} name="ProposerPan"
                                                                                value={this.state.ProposerPan} className="packageForm"
                                                                                placeholder="Enter PAN Number" mask={[/[a-zA-z]/,
                                                                                    /[a-zA-z]/,
                                                                                    /[a-zA-z]/,
                                                                                    /[a-zA-z]/,

                                                                                    /[a-zA-z]/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,

                                                                                    /\d/,
                                                                                    /[a-zA-Z]/,]}
                                                                                guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                                            <span className="txtError">{this.state.errors["ProposerPan"]}</span>
                                                                        </div>
                                                                        <div className="col-md-6 pb-3">

                                                                            <MaskedInput className="packageForm" name="ProposerMobile"
                                                                                value={this.state.ProposerMobile}
                                                                                onChange={this.handle}
                                                                                onBlur={this.handle}
                                                                                placeholder="Mobile Number" mask={[
                                                                                    /[6-9]/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,]}
                                                                                guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                                            <span className="txtError">{this.state.errors["ProposerMobile"]}</span>
                                                                        </div>


                                                                        <div className="col-md-6 pb-3  InsurerOwner">
                                                                            <label className="labeltext">Gender</label>
                                                                            <div className="form-check-inline">
                                                                                <label>
                                                                                    <input type="radio" name="ProposerGender"
                                                                                        value="M"
                                                                                        checked={this.state.ProposerGender == "M"}
                                                                                        onChange={this.handle}
                                                                                        onBlur={this.handle}
                                                                                    /> Male
                                                                                    </label>
                                                                                <label>
                                                                                    <input type="radio" name="ProposerGender"
                                                                                        value="F"
                                                                                        checked={this.state.ProposerGender == "F"}
                                                                                        onChange={this.handle}
                                                                                        onBlur={this.handle}
                                                                                    /> Female
                                                                                    </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 pb-3">
                                                                            <select className="packageForm"
                                                                                name="ProposerOccupation"
                                                                                onChange={this.handle}
                                                                                onBlur={this.handle}
                                                                                value={this.state.ProposerOccupation}>
                                                                                <option value="select">Proposer Occupation</option>
                                                                                {
                                                                                    this.state.ProposerOccupationList.map(row =>
                                                                                        <option value={row.CombindID}>{row.Name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            <span className="txtError">{this.state.errors["ProposerOccupation"]}</span>
                                                                        </div>

                                                                        <div className="col-md-6 pb-3"> <select className="packageForm"
                                                                            name="ProposerAnnualIncome"
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            value={this.state.ProposerAnnualIncome}>
                                                                            <option value="select">Annual Income</option>
                                                                            {
                                                                                this.state.AnnualIncomeList.map(row =>
                                                                                    <option value={row.ID}>{row.Name}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                            <span className="txtError">{this.state.errors["ProposerAnnualIncome"]}</span>
                                                                        </div>
                                                                        <div className="col-md-6 pb-3">

                                                                            <MaskedInput onChange={this.handle} onBlur={this.handle} name="ProposerFatherName"
                                                                                value={this.state.ProposerFatherName}
                                                                                placeholder="Father Name" className="packageForm"
                                                                                mask={[/[a-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                                guide={false} showMask={false} />
                                                                            <span className="txtError">{this.state.errors["ProposerFatherName"]}</span>
                                                                        </div>
                                                                        <div className="col-md-6 pb-3">


                                                                            <input type="date" className="packageForm"
                                                                                name="ProposerDOB"
                                                                                value={this.state.ProposerDOB}
                                                                                onChange={this.handle}
                                                                                onBlur={this.handle}
                                                                                max={this.state.maxProposerDate}
                                                                            />
                                                                            <span className="txtError">{this.state.errors["ProposerDOB"]}</span>



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 ">
                                                                            <h4 className="insuredPersons pt-3 pb-2 m-0">Nominee Details</h4>
                                                                        </div>
                                                                        <div className="col-md-4 pb-3">
                                                                            <select className="packageForm"
                                                                                onChange={this.handle}
                                                                                onBlur={this.handle}
                                                                                name="ProposerSalutation"
                                                                                value={this.state.ProposerSalutation}>
                                                                                <option value="select">Salutation</option>
                                                                                <option value="Mr">Mr</option>
                                                                                <option value="Ms">Ms</option>
                                                                                <option value="Miss">Miss</option>
                                                                            </select>
                                                                            <span className="txtError">{this.state.errors["ProposerSalutation"]}</span>
                                                                        </div>
                                                                        <div className="col-md-4 pb-3">
                                                                            <MaskedInput onChange={this.handle} onBlur={this.handle} name="ProposerNomineeName"
                                                                                value={this.state.ProposerNomineeName}
                                                                                placeholder="Nominee Name" className="packageForm"
                                                                                mask={[/[a-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                    /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                                guide={false} showMask={false} />
                                                                            <span className="txtError">{this.state.errors["ProposerNomineeName"]}</span>


                                                                        </div>
                                                                        <div className="col-md-4 pb-3"><select className="packageForm"
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            name="ProposerNomineeRelation"
                                                                            value={this.state.ProposerNomineeRelation}
                                                                        >
                                                                            <option value="select">Relationship</option>
                                                                            {
                                                                                this.state.ProposerNomineeRelationList.map(row =>
                                                                                    <option value={row.CombindID}>{row.Name}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                            <span className="txtError">{this.state.errors["ProposerNomineeRelation"]}</span>
                                                                        </div>
                                                                        <div className="col-md-4 pb-3"> <input type="date" className="packageForm "
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            name="ProposerNomineeDOB"
                                                                            value={this.state.ProposerNomineeDOB}
                                                                        />
                                                                            <span className="txtError">{this.state.errors["ProposerNomineeDOB"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <button onClick={(e) => this.Next("GotoInsuredDetails")} type="button" id="myBtn1003" className={ButtonClass()}>
                                                                                Continue to Insured Details
                                                                        </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null}
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="second">
                                                    {
                                                        this.state.GotoInsuredDetails ?
                                                            <section >

                                                                {
                                                                    this.state.InsuredList.map((row, ind) =>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <h4 className="insuredPersons p-0 pt-3 pb-2 m-0">{this.state.InsuredPerson[ind].text} Insured Details </h4>
                                                                            </div>
                                                                            <div className="col-md-6 pb-3">
                                                                                <MaskedInput name="InsurerName"
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    value={this.state.InsuredPerson[ind].InsurerName}
                                                                                    className="packageForm" placeholder="Insured Name"
                                                                                    mask={[/[a-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                        /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                        /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                        /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,
                                                                                        /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                                    guide={false} showMask={false} />
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerNameError"]}</span>
                                                                            </div>
                                                                            <div className="col-md-6 pb-3">
                                                                                <select className="packageForm"
                                                                                    name="InsurerRelation"
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    value={this.state.InsuredPerson[ind].InsurerRelation}
                                                                                >
                                                                                    <option value="select">Insured Relationship</option>
                                                                                    {
                                                                                        this.state.InsuredRelationShiplist.map(row =>
                                                                                            <option value={row.CombindID}>{row.Name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerRelationError"]}</span>
                                                                            </div>

                                                                            <div className="col-md-6 form-group InsurerOwner">
                                                                                <label className="labeltext"> Gender</label>
                                                                                <div className="form-check-inline">
                                                                                    <label>
                                                                                        <input type="checkbox" name="InsurerGender"
                                                                                            value="M"
                                                                                            checked={this.state.InsuredPerson[ind].InsurerGender == "M"}
                                                                                            onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}

                                                                                        /> Male
                                                                                        </label>
                                                                                    <label>
                                                                                        <input type="checkbox" name="InsurerGender"
                                                                                            value="F"
                                                                                            checked={this.state.InsuredPerson[ind].InsurerGender == "F"}
                                                                                            onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}

                                                                                        /> Female
                                                                                        </label>
                                                                                    <span className="txtError">{this.state.InsuredPerson[ind]["InsurerGenderError"]}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 form-group InsurerOwner">
                                                                                <label className="labeltext">Marital Status</label>
                                                                                <div className="form-check-inline">
                                                                                    <label>
                                                                                        <input type="checkbox" name="InsurerMaritalStatus"
                                                                                            value="M"
                                                                                            checked={this.state.InsuredPerson[ind].InsurerMaritalStatus == "M"}
                                                                                            onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}

                                                                                        /> Married
                                                                                        </label>
                                                                                    <label>
                                                                                        <input type="checkbox" name="InsurerMaritalStatus"
                                                                                            value="N"
                                                                                            checked={this.state.InsuredPerson[ind].InsurerMaritalStatus == "N"}
                                                                                            onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}

                                                                                        />Single
                                                                                        </label>
                                                                                    <span className="txtError">{this.state.InsuredPerson[ind]["InsurerMaritalStatusError"]}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 pb-3">
                                                                                <input type="date" className="packageForm"
                                                                                    name="InsurerDOB"
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    value={this.state.InsuredPerson[ind].InsurerDOB}
                                                                                />
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerDOBError"]}</span>
                                                                            </div>
                                                                            <div className="col-md-3 pb-3">
                                                                                <MaskedInput
                                                                                    mask={[/\d/, /\d/, /\d/]}
                                                                                    guide={false} showMask={false} type="tel" className="packageForm"
                                                                                    name="InsurerWeight"

                                                                                    value={this.state.InsuredPerson[ind].InsurerWeight}
                                                                                    onChange={(e) => { this.ManageInsuredList(ind, e.target.name, e.target.value) }}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    placeholder="Weight (In KG)" max="3" />
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerWeightError"]}</span>
                                                                            </div>
                                                                            <div className="col-md-3 pb-3">
                                                                                <select className="packageForm"
                                                                                    name="InsurerHeightFeet"
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    value={this.state.InsuredPerson[ind].InsurerHeightFeet}
                                                                                >
                                                                                    <option value="select">Height (Feet)</option>
                                                                                    {
                                                                                        this.state.Feet.map(row =>
                                                                                            <option value={row.ID}>{row.Name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerHeightFeetError"]}</span>
                                                                            </div>
                                                                            <div className="col-md-3 pb-3">
                                                                                <select className="packageForm"
                                                                                    name="InsurerHeightInch"
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    value={this.state.InsuredPerson[ind].InsurerHeightInch}
                                                                                >
                                                                                    <option>Height (Inch)</option>
                                                                                    {
                                                                                        this.state.Inch.map(row =>
                                                                                            <option value={row.ID}>{row.Name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerHeightInchError"]}</span>
                                                                            </div>
                                                                            <div className="col-md-6 pb-3">
                                                                                <select className="packageForm"
                                                                                    name="InsurerOccupation"
                                                                                    value={this.state.InsuredPerson[ind].InsurerOccupation}
                                                                                    onChange={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                    onBlur={(e) => this.ManageInsuredList(ind, e.target.name, e.target.value)}
                                                                                >
                                                                                    <option value="select">Occupation</option>
                                                                                    {
                                                                                        this.state.InsuredOccupationList !== null && this.state.InsuredOccupationList.map(row =>
                                                                                            <option value={row.CombindID}>{row.Name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                <span className="txtError">{this.state.InsuredPerson[ind]["InsurerOccupationError"]}</span>
                                                                            </div>
                                                                            <div className="DeviderInsured"></div>
                                                                        </div>
                                                                    )
                                                                }
                                                                <label>
                                                                    <button className={ButtonClass()}
                                                                        onClick={(e) => this.Next("GotoCommunicationAddress")}>Continue to Address</button>
                                                                </label>

                                                            </section>
                                                            : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Third">
                                                    {
                                                        this.state.GotoCommunicationAddress ?
                                                            <section>

                                                                <div className="row">
                                                                    <div className="col-md-6 pb-3">
                                                                        <input type="text" className="packageForm"
                                                                            name="CommunicationAddress1"
                                                                            value={this.state.CommunicationAddress1}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            placeholder="Address Line 1" />
                                                                        <span className="txtError">{this.state.errors["CommunicationAddress1"]}</span>

                                                                    </div>
                                                                    <div className="col-md-6 pb-3">
                                                                        <input type="text" className="packageForm"
                                                                            name="CommunicationAddress2"
                                                                            value={this.state.CommunicationAddress2}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            placeholder="Address Line 2" />
                                                                        <span className="txtError">{this.state.errors["CommunicationAddress2"]}</span>

                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 pb-3">
                                                                        <input type="text" className="packageForm"
                                                                            name="CommunicationAddress3"
                                                                            value={this.state.CommunicationAddress3}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            placeholder="Address Line 3" />
                                                                    </div>
                                                                    <div className="col-md-6 pb-3">
                                                                        <select className="packageForm"
                                                                            name="CommunicationState"
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            value={this.state.CommunicationState}
                                                                        >
                                                                            <option value="select">State</option>
                                                                            {
                                                                                this.state.CommunicationStateList.map(row =>
                                                                                    <option value={row.CombindID}>{row.StateName}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                        <span className="txtError">{this.state.errors["CommunicationState"]}</span>

                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 pb-3">
                                                                        <select className="packageForm"
                                                                            name="CommunicationCity"
                                                                            onChange={this.handle}

                                                                            value={this.state.CommunicationCity}
                                                                        >
                                                                            <option value="select">City</option>
                                                                            {
                                                                                this.state.CommunicationCityList.length > 0 && this.state.CommunicationCityList.map(row =>
                                                                                    <option value={row.CombindID}>{row.CityName}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                        <span className="txtError">{this.state.errors["CommunicationCity"]}</span>
                                                                    </div>
                                                                    <div className="col-md-6 pb-3">
                                                                        <MaskedInput name="CommunicationPinCode"
                                                                            value={this.state.CommunicationPinCode}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            placeholder="PIN Code" className="packageForm"
                                                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                            guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                                        <span className="txtError">{this.state.errors["CommunicationPinCode"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <button className={ButtonClass()} disabled={this.state.Disabled}
                                                                            onClick={(e) => this.Next("GotoQuestionList")}
                                                                        >Continue to Medical History</button>
                                                                    </div>
                                                                </div>





                                                            </section>
                                                            : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Four">
                                                    {/*******Updated***********/}
                                                    <table className="table-bordered table table-striped">
                                                        <thead>
                                                            <th>Questions</th>
                                                            {this.state.InsuredList.map((row, i) => <th> {this.state.InsuredPerson[i].text} Insured</th>)}
                                                        </thead>
                                                        <tbody>
                                                            {this.state.HealthQuestion !== null && this.state.HealthQuestion.map((desc, i) => {
                                                                return desc.QuestionDescription != "" && <React.Fragment><tr key={i}>

                                                                    <td>{desc.QuestionDescription}</td>
                                                                    {this.state.InsuredList.map((row, pi) => <td><select className="packageForm" defaultValue="2" id={desc.QuestionSet} onChange={(e) => this.manageHealthQuestion(i, desc.QuestionSet, desc.Type, e.target.value, pi)}>
                                                                        <option value="1">Yes</option>
                                                                        <option value="2">No</option>
                                                                    </select></td>)}
                                                                </tr>

                                                                    {this.state.askMonthYear && this.state.QuestionSet[i].Qid == desc.QuestionSet && (desc.Type == "dropdown_date" || desc.Type == "dropdown_date_string") && <tr>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <select name="Month" onChange={(e) => this.updatePED(i, e.target.value, e.target.name, this.state.PersonId)}>
                                                                                        <option value="">Select Month</option>
                                                                                        <option value="1">Jan</option>
                                                                                        <option value="2">Feb</option>
                                                                                        <option value="3">Mar</option>
                                                                                        <option value="4">Apr</option>
                                                                                        <option value="5">May</option>
                                                                                        <option value="6">Jun</option>
                                                                                        <option value="7">Jul</option>
                                                                                        <option value="8">Aug</option>
                                                                                        <option value="9">Sep</option>
                                                                                        <option value="10">Oct</option>
                                                                                        <option value="11">Nov</option>
                                                                                        <option value="12">Dec</option>
                                                                                    </select></div>
                                                                                <div className="col-md-4">
                                                                                    <select name="Year" onChange={(e) => this.updatePED(i, e.target.value, e.target.name, this.state.PersonId)}>
                                                                                        <option value="">Select Year</option>
                                                                                        {this.state.yearList.map((row) => <option value={row}>{row}</option>)}
                                                                                    </select></div>
                                                                                {row.Type == "dropdown_date_string" && <div className="col-md-4">
                                                                                    <input type="text" name="Text" onChange={(e) => this.updatePED(i, e.target.value, e.target.name, this.state.PersonId)} />
                                                                                </div>}
                                                                            </div>
                                                                        </td>

                                                                    </tr>}
                                                                </React.Fragment>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    {/*******Updated***********/}


                                                    {/* <table className="table-bordered table table-striped">
                                                        <thead>
                                                            <th>Questions</th>
                                                            <th>First Insured</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.HealthQuestion !== null && this.state.HealthQuestion.map((row, i) => {
                                                                return row.QuestionDescription != "" && <React.Fragment><tr key={i}>

                                                                    <td>{row.QuestionDescription}</td>
                                                                    <td><select className="packageForm" defaultValue="2" id={row.QuestionSet} onChange={(e) => this.manageHealthQuestion(i, row.QuestionSet, row.Type, e.target.value)}>
                                                                        <option value="1">Yes</option>
                                                                        <option value="2">No</option>
                                                                    </select></td>
                                                                </tr>
                                                                    {this.state.askMonthYear && this.state.QuestionSet[i].Qid == row.QuestionSet && (row.Type == "dropdown_date" || row.Type == "dropdown_date_string") && <tr>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <select name="month" onChange={(e) => this.updatePED(i, e.target.value, e.target.name)}>
                                                                                        <option value="">Select Month</option>
                                                                                        <option value="1">Jan</option>
                                                                                        <option value="2">Feb</option>
                                                                                        <option value="3">Mar</option>
                                                                                        <option value="4">Apr</option>
                                                                                        <option value="5">May</option>
                                                                                        <option value="6">Jun</option>
                                                                                        <option value="7">Jul</option>
                                                                                        <option value="8">Aug</option>
                                                                                        <option value="9">Sep</option>
                                                                                        <option value="10">Oct</option>
                                                                                        <option value="11">Nov</option>
                                                                                        <option value="12">Dec</option>
                                                                                    </select></div>
                                                                                <div className="col-md-4"> <select name="year" onChange={(e) => this.updatePED(i, e.target.value, e.target.name)}>
                                                                                    <option value="">Select Year</option>
                                                                                    {this.state.yearList.map((row) => <option value={row}>{row}</option>)}
                                                                                </select></div>
                                                                                {row.Type == "dropdown_date_string" && <div className="col-md-4">
                                                                                    <input type="text" name="describe" onChange={(e) => this.updatePED(i, e.target.value, e.target.name)} />
                                                                                </div>}
                                                                            </div>
                                                                        </td>

                                                                    </tr>}</React.Fragment>
                                                            })}

                                                        </tbody>
                                                    </table>*/}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {this.state.goToPay && <button className={ButtonClass()} disabled={this.state.Disabled}
                                                                onClick={this.GotoShowProposal}
                                                            >{this.state.Wait}</button>}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>


                        {
                            this.state.IsMessage ?
                                <div className="Manualmodal">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header text-center">
                                                <button type="button" className="close btn-success" onClick={this.CloseMessage}>×</button>
                                            </div>
                                            <div className="modal-body">
                                                <span>{this.state.MessageBody}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}