import React from "react";
const Dedicated = (props) => {
  return (
      <div className="call-to-action-bar">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <h3>Please go through our Frequently Asked Questions</h3>
                  </div>
                  <div className="col-lg-4 text-center"> <a className="btn btn-orange btn-lg" href="#" role="button">CLICK HERE</a> </div>
              </div>
          </div>
      </div>
    );
};
export default Dedicated;
