import React from "react";
const SecurityPriority = (props) => {
    return (
        <div class="section-space80 SecurityPreio bg-light">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6 col-md-12 col-12">
                        <div class="text-center">
                            <img src={require("../assets/img/Mandi/shield.svg")} class="mb-4 SecurityIcon" />
                            <h1 class="">Your security. Our priority.</h1>
                            <p>
                                We always have your security in mind. Rest easy knowing your
                                data is protected with 128-bit encryption.
                  <a href="#!">Learn more.</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SecurityPriority;
