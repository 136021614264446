/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <section style={{ marginTop: "150px" }}>
                <div className="container">
                    <h5 className="titleWithFont text-center">Let's Start a Conversation</h5>
                    <p className="text-center">
                        You'll be talking to a Human. You wont hit a ridiculously long phone
                        menu. You emails will be promptly responded. We at SBM Insurance provide an
                        exceptional customer service we'd expect ourselves.
      </p>
                    <div role="form">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="contacUS_page_Form ">
                                    <h2>Let Us Help</h2>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" id="Interested" name="typeList" onChange={this.handle}>
                                                <option value="">Interested In</option>
                                                <option value="Car Insurance">Car Insurance</option>
                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Travel Insurance">Travel Insurance</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["typeList"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["MobileNo"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" name="time" onChange={this.handle}>
                                                <option value="Preferred time">Preferred time</option>
                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["time"]}</span>
                                    <div className="form-group text-left">
                                        <input type="button" id="btnReq" className="cl_btn" value="Send Request" onClick={this.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contacUS_page_Info">
                                    <h3 className="orDivider">OR</h3>
                                    <div className="Spearhead">

                                        <div class="row">
                                            <div class="col-md-2">
                                            </div>

                                            <div class="col-md-10">
                                                <p> <b>SBM Insurance Brokers Pvt.Ltd.</b></p>
                                                <p>
                                                    Registered Office Address: E-2/20, Behind G3S Complex, Sector-11, Rohini, Delhi-110085<br />
                            Corporate Office Address: Testimonials (5-7) Mrs. Mamta Bansal, Dr. Arpana  Bansal, Mr. R. K. Rana, Mr. B. M. Gupta, Mr. A.K. Goel
                        </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2">
                                            </div>

                                            <div class="col-md-10">
                                                <p>
                                                    <b title="Phone">Call Us </b>Landline: 011-27570050 Mobile: 8882573728
                        </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2">
                                            </div>
                                            <div class="col-md-10">
                                                <p>
                                                    <b title="Email">Email</b>: sbminsurance07@gmail.com, info@sbminsurance.in , po@sbminsurance.in
                        </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13994.03156675522!2d77.1132757!3d28.7342493!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ccda107a95bb993!2sSBM%20INSURANCE%20BROKERS%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1603902533452!5m2!1sen!2sin"
                    className="map"
                    frameborder="0"
                    allowfullscreen=""
                ></iframe>
            </section>
        );
    };
}
export default Contact;
