import React from "react";

import WhyCompare from "./WhyCompare";
import OurInsurer from "./OurInsurer";
import Claiming from "./Claiming";
import SocialConnect from "./SocialConnect";
import SimpleMasg from "./SimpleMasg";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
import QuestionSection from "../QuestionSection/QuestionSection";
import TestimonialSection from "../Testimonial/TestimonialSection";
const Services = (props) => {
    return (
        <React.Fragment>
            <div class="section-full content-inner-2 about-bx2">
                <div class="container compare-container">
                    <h2><span class="span">How does it work</span><br /> It is quicker than counting 100</h2>
                    <div class="row">
                        <div class="col-sm-3 wow fadeInUp">
                            <div class="col-12 text-center compare-steps">
                                <div class="step-details">
                                    <h3>01</h3>
                                </div>
                                <img src={require("../assets/img/inshora/home/service1.jpg")} />
                                <h4>Fill us in</h4>
                                <p>Share a few basic details to get the price estimates.</p>
                            </div>
                        </div>
                        <div class="col-sm-3 wow fadeInUp">
                            <div class="col-12 text-center compare-steps">
                                <div class="step-details">
                                    <h3>02</h3>
                                </div>
                                <img src={require("../assets/img/inshora/home/service2.jpg")} />
                                <h4>Understand your options</h4>
                                <p>Compare your cover options based on what's important to you.</p>
                            </div>
                        </div>
                        <div class="col-sm-3 wow fadeInUp">
                            <div class="col-12 text-center compare-steps">
                                <div class="step-details">
                                    <h3>03</h3>
                                </div>
                                <img src={require("../assets/img/inshora/home/service3.jpg")} />
                                <h4>Take your pick</h4>
                                <p>Choose the best option from a variety of companies.</p>
                            </div>
                        </div>
                        <div class="col-sm-3 wow fadeInUp">
                            <div class="col-12 text-center compare-steps">
                                <div class="step-details">
                                    <h3>04</h3>
                                </div>
                                <img src={require("../assets/img/inshora/home/service4.jpg")} />
                                <h4>Seal the Deal</h4>
                                <p>Knowing you're making an informed choice, you can accept and buy your insurance with just a click or Connect with our customer happiness executives for any queries or help.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="section-full business-pro-blog">
                <div class="container cover-pause-container">
                    <div class="row flex">
                        <div class="col-sm-6">
                            <img src={require("../assets/img/inshora/background/bg26.png")} />
                        </div>
                        <div class="col-sm-6 wow zoomIn save-content">
                            <div class="save">
                                <h1>Save upto 70% of your premiums by using <span>Inshora Cover</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Zoom>
                <WhyCompare />
            </Zoom>
            <Zoom>
                <OurInsurer />
            </Zoom>
            <Zoom>
                <Claiming />
            </Zoom>
            <section className="container">
                <QuestionSection />
                <TestimonialSection />
            </section>

            <Zoom>
                <SocialConnect />
            </Zoom>
            <Zoom>
                <SimpleMasg />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
