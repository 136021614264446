import React from "react";

const About = () => {
    return (
        <React.Fragment>
            <section className="aboutUs">
                <div className="container">
                    <div className="row row justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="section-heading text-left wow fadeInUp  animated">
                                <h1 className="section-title text-left">About us:</h1>
                                <p className="section-sub">Know your Insurance Marketing firm.</p>
                                <div className="section-divider">
                                    <h2>Mission:</h2>
                                    <p>
                                        To provide the best insurance planning covering one and their dependents from all possible uncertainties.
								  </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={require("./assets/img/DemoTemp/partnership-concept.jpg")} alt="Partnership Concept" className="work__bg wow fadeInRight  animated" />
                        </div>
                    </div>
                </div>
                <div class="services-area in-section section-padding-lg">
                    <div class="container">
                        <div class="section-title text-left">
                            <h2>Who we are</h2>
                            <p>Incorporated in 2015 under regulation by Insurance Regulatory and Development Authority of India (IRDAI), ENIVESH is amongst the first 10 Insurance Marketing Firms (IMF) in the country. Being one of the oldest and most trusted IMF, we have tie
                            -ups with multiple life, non-life and health insurance companies and cater to a wide range of financial products and services.
</p>
                            <p>An insurance policy requires systematic approach for identifying the RISK, hidden terms and potential benefits. This is where our expertise plays the most important role of determining the right product with best possible benefits. As an advisory and financial service provider, we believe that pricing should not be the only parameter when it comes to selection of an insurance policy.</p>
                            <p>
                            94%, one of the least complaints ratio ranging under 0.5% and a remarkable settlement ratio at 100% claims being settled.</p>
                            <br />
                            <br />
                            <h2>Our Chief</h2>
                            <p>Babasaheb Kadam. CFP<br />
                                Principle officer.</p>

                        </div>
                    </div>
                </div>

            </section>
        </React.Fragment>
    );
};
export default About;
