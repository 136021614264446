import React from 'react';
import { useState } from 'react';
const LifeHealthDetails = (props) => {
    const [Step3, setStep3] = useState(true);
    const [Step4, setStep4] = useState(false);
    const [Step5, setStep5] = useState(false);
    const [stepCount, setStepCount] = useState("3");
    const [Content, setContent] = useState("Please Enter Your Family Details");

    function HandleStep3() {
        setStep4(true)
        setStep3(false)
        setStepCount("4")
        setContent("Annual Income")
    }
    function HandleStep4() {
        setStep5(true)
        setStep4(false)
        setStepCount("5")
        setContent("Required Sum Assured Value")
    }
    function stepBack() {
        if (Step3) {
            return props.close()
        } else if (Step4) {
            setStep4(false);
            setStep3(true);
            setStepCount("3")
            setContent("Do You Consume Tobacco or Nicotine?")

        } else if (Step5) {
            setStep5(false);
            setStep4(true);
            setStepCount("4")
            setContent("Annual Income")
        }
    }
    return (
        <section class="section wizard-section">
            <div class="container">
                <div class="row g-3">
                    <div class="col-3 col-md-2 col-lg-1">
                        <a onClick={stepBack} class="arw-btn">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                            </svg>
                        </a>
                    </div>
                    <div class="col-9 col-md-10 col-lg-3 position-relative">
                        <div class="row">
                            <div class="col-lg-8">
                                <h3 class="f-32 font-regular text-secondary text-capitalize">{Content}</h3>
                            </div>
                        </div>
                        <div class="pageno d-none d-lg-flex"><span>{"0" + stepCount}</span> &nbsp;of 05</div>
                    </div>
                    <div class="col-lg-8">
                        <div class="wizard-box box d-flex flex-column">
                            {Step3 &&
                                <label className="ForMobileApp">
                                    <p class="HeadingTitleBima">Select Members</p>
                                <div className="GenderApp FeMale">
                                    <input type="radio" name="SmokeStatus" className={props.SmokeStatus === "N"?"active":""} checked={props.SmokeStatus === "N"} value="N" onChange={props.handle} />
                                        <span><small>No</small></span>
                                    </div>
                                    <div className="GenderApp FeMale">
                                    <input type="radio" name="SmokeStatus" value="Y" className={props.SmokeStatus === "Y" ? "active" : ""} checked={props.SmokeStatus === "Y"} onChange={props.handle} />
                                        <span><small>Yes</small></span>
                                    </div>
                                </label>
                            }

                            {Step4 &&
                                <div class="row">
                                    <div class="col-md-6">
                                            <p class="HeadingTitleBima">Select Annual Income</p>
                                            <select class="form-control" value={props.AnnualIncome} onChange={props.handle} name="AnnualIncome" id="drpIncome">
                                                {props.IncomeList.map((r, i) => <option value={r}>{i + 2} Lakhs</option>)}


                                            </select>
                                    </div>
                                </div>
                            }
                            {Step5 &&
                                <div class="row">
                                    <div class="col-md-6">
                                            <p class="HeadingTitleBima">Cover Value?</p>
                                            <select class="form-control" name="SumInsured" onChange={props.handle} value={props.SumInsured} id="drpCoverAmount">
                                                <option value="300000000">30 Crores</option>
                                                <option value="290000000">29 Crores</option>
                                                <option value="280000000">28 Crores</option>
                                                <option value="270000000">27 Crores</option>
                                                <option value="260000000">26 Crores</option>
                                                <option value="250000000">25 Crores</option>
                                                <option value="240000000">24 Crores</option>
                                                <option value="230000000">23 Crores</option>
                                                <option value="220000000">22 Crores</option>
                                                <option value="210000000">21 Crores</option>
                                                <option value="200000000">20 Crores</option>
                                                <option value="190000000">19 Crores</option>
                                                <option value="180000000">18 Crores</option>
                                                <option value="170000000">17 Crores</option>
                                                <option value="160000000">16 Crores</option>
                                                <option value="150000000">15 Crores</option>
                                                <option value="140000000">14 Crores</option>
                                                <option value="130000000">13 Crores</option>
                                                <option value="120000000">12 Crores</option>
                                                <option value="110000000">11 Crores</option>
                                                <option value="100000000">10 Crores</option>
                                                <option value="90000000">9 Crores</option>
                                                <option value="80000000">8 Crores</option>
                                                <option value="70000000">7 Crores</option>
                                                <option value="60000000">6 Crores</option>
                                                <option value="50000000">5 Crores</option>
                                                <option value="40000000">4 Crores</option>
                                                <option value="30000000">3 Crores</option>
                                                <option value="20000000">2 Crores</option>
                                                <option value="10000000">1 Crore</option>
                                                <option value="9000000">90 Lakhs</option>
                                                <option value="8000000">80 Lakhs</option>
                                                <option value="7000000">70 Lakhs</option>
                                                <option value="6000000">60 Lakhs</option>
                                                <option value="5000000">50 Lakhs</option>
                                                <option value="4000000">40 Lakhs</option>
                                                <option value="3000000">30 Lakhs</option>
                                                <option value="2500000">25 Lakhs</option>
                                                <option value="2000000">20 Lakhs</option>
                                                <option value="1000000">10 Lakhs</option>

                                            </select>
                                    </div>
                                    <div class="col-md-6">
                                            <p class="HeadingTitleBima">Cover Age?</p>
                                            <select class="form-control" name="Coverage" onChange={props.handle} value={props.Coverage} id="drpCoverageAge">
                                                {props.CoverList.map((r) => <option value={r}>{r} Years</option>)}


                                            </select>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="wizard-bottom">
                            {Step3 && < button className="btn btn-blue w-100" id="VahanCheck" onClick={HandleStep3}>
                                Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                </svg>
                            </button>}
                            {Step4 && < button className="btn btn-blue w-100" id="VahanCheck" onClick={HandleStep4} >
                                Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                </svg>
                            </button>}

                            {Step5 && <button className="btn btn-blue w-100" id="VahanCheck" onClick={props.callGetQuote}>
                                {props.btnValue}
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                </svg>
                            </button>

                            }

                        </div>

                    </div>
                </div>



                <section class="f-14">
                    <div class="row mt-5">
                        <div class="col-md-11 offset-lg-1">
                            Copyright 2020 - All right Reserved
                </div>
                    </div>

                </section>

            </div>
        </section>
    )
}
export default LifeHealthDetails