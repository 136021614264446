import React, { useEffect, useState } from 'react';
import ButtonClass from '../../ButtonClass';
import { CheckAppConfirmation } from '../AppNotification/CheckAppConfirmation';
const AppGetAlert = () => {
    const [IsAlert, setAlert] = useState(false);
    const [AppURL, setAppUrl] = useState("");
    const [IsMobile, setMobile] = useState(false);
    useEffect(() => {
        let appdata = CheckAppConfirmation();
        let width = window.innerWidth;
        if (appdata.IsShowNotification) {
            if (width <= 600) {
                setAppUrl(appdata.PlayApp);
                setMobile(true);
            }
            else setAppUrl(appdata.WebApp);
       
            setTimeout(setAlert(true), 5000)
        }
       
    }
        , [])

    return (
        IsAlert ? < div className="AppGetAlert" >
            <span><button type="button" class="close" onClick={() => setAlert(false)}>×</button></span>
            <img src={require("../../Contents/img/AppInstall.png")} />
            <h3>Now Android App is available</h3>
           
            {!IsMobile? AppURL:
                < a href={AppURL} className={ButtonClass() + " mt-3 h-auto w-auto"}>Install Now</a>}

        </div>:<div></div>
    )
}
export default AppGetAlert;