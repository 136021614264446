import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
import { Accordion, Card } from "react-bootstrap"
const HealthInsurance = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg HealthInsBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Health Insurance</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading text-left mb-3">What is Health Insurance?</h3>
                            <p class="padR_160">
                                Health insurance is an insurance cover in case of medical emergency arises; health insurance essentially covers your medical expenses, due to a disease/illness or even accident. Health insurance covers hospitalization expenses arising from a specified illness or disease up to the maximum limit or value of the policy. It includes room rent, surgical and operation theatre expenses, nursing, anaesthetist charges, prescription medications and relevant diagnostic procedures. Most insurance policies require overnight hospital stays for a successful claim.
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>

            <section class="ProductPageSectionThird">
                <div class="container">
                    <h3 class="SecondSectionHeading">Type of Cover in Health Insurance</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Floater cover for family.svg")} />
                                <p>
                                    <strong>Floater cover for family</strong><br />
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Individual cover for family.svg")} />
                                <p>
                                    <strong>Individual cover for family</strong><br />
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Health  insurance for senior citizens.svg")} />
                                <p>
                                    <strong>Health  insurance for senior citizens</strong><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="SecondSectionHeadingBox">
                                <h3 class="SecondSectionHeading">I don't need Health Insurance, <br /><span>If you believe that read on...</span></h3>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <ul class="DoNotNeed">
                                <li>Health insurance provides coverage against critical illnesses due to increasing lifestyle disease</li>

                                <li>Health insurance helps to deal with increasing medical treatment cost and increasing healthcare infrastructure cost.</li>

                                <li>Health insurance is a provision of sufficient finance if any medical emergency arise in family due to any accident or disease.</li>

                                <li>Health insurance helps to avoid sacrificing hard earned saving on medical expenses.</li>

                                <li>Health care plans provide tax benefits. Premiums paid towards your health care policy are eligible for tax deductions under Section 80D of the Income Tax Act, 1961.</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/Religare/I don't need Health Insurance.jpg")} />
                        </div>
                        <div class="col-md-12">
                            <p>
                                Getting insurance has never been this easy with our certified insurance experts around you. One should know how much coverage he required to safeguard the health of his loved ones, with all upgraded features, no limit room rent facility, maximum day care converges, pre and post hospitalization charges and highest loyalty bonus also known as cumulative bonus.
                    <br />Our experts with many years of industry experience will support you throughout the journey, from identifying the right cover to issuing the policy till settling the claim when required.
                    <br />Call us to know- Right Cover with Right Premium with Right consultants

                </p>
                        </div>
                    </div>
                </div>
            </section>



            <section class="ProductPageSectionThird">
                <div class="container">
                    <h3 class="SecondSectionHeading">Frequently Asked Questions</h3>
                    <div class="row" id="accordion-style-1">
                        <div class="col-md-12 mx-auto">
                            <Accordion className="ReligareCard">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="0">
                                        Can I take multiple health insurance ?
                            </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div id="faq1" aria-expanded="true">
                                                Individuals can buy multiple health insurance policies
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="1">
                                        How much SA do I need in a health insurance policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <div class="acod-content">
                                                There are no fixed rules in India to buy a health insurance. A person can opt SA min 2 Lac to 1 CR depends on his budget and requirement.
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="2">
                                        What is Co- Pay clause ?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Copayment in health insurance means an arrangement made with the health insurance company, in which the insured will need to pay a part of the medical expenses on their own. The remainder of the amount will be paid by the insurance company.
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="3">
                                        What is the meaning of network and non-network hospital?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <div class="acod-content">
                                                A hospital which has an agreement with health insurance company for providing cashless treatment is referred as network hospital. Non network hospital are those where policy holder seeking treatment will have to pay first for treatment and later claim as per reimbursement procedures
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="4">
                                        Day care procedures don't require 24 hours hospitalization?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            <div class="acod-content">
                                                >Day care procedures used to describe treatment that requires hospitalization for less than 24 hours. Such treatments are covered in your health insurance policy.
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="5">
                                        I am dissatisfied with my health policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Policy holders are allowed to switch their insurance companies and port their policy to another company if they are not happy with the existing Policy.
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default HealthInsurance;
