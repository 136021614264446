/* eslint-disable */ 
import React from 'react';
const PassengerCarrying=()=>{
    return(
        <section className="productCntMain">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="productCnt">
                    <h3>WHAT IS TWO WHEELER INSURANCE ?</h3>
                    <p>
                        Two Wheeler Insurance is an insurance policy  that protects the owner of the vehicle against any financial loss arising out  of damage or theft of vehicle. Two Wheeler Insurance Policy also covers the  damage caused to third party or property. Two Wheeler Insurance is mandatory in  India and is the best way to financially secure yourself, your family and your  vehicle. Two Wheeler Insurance helps cover against theft, financial loss caused  by accidents, ( Bodily Injury, Medical Payments, Property Damage ) and any  subsequent liabilities. It may cover the insured party, the insured vehicle  (damage) and third parties (Two Wheeler and people) based on the type of  insurance bought.
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <img src={require("../assets/img/Passenger.png")}/>
            </div>
            <div className="col-md-12">
                <div className="productCnt">
                    
                    <p>
                        Third party coverage policy<br/>
                        <strong>THIRD PARTY LIABILITY COVERAGE POLICY</strong><br/>
                        A third-party Two Wheeler Insurance plan  provides coverage against any legal liability arising out of injuries to a  third-party when the policyholder is at fault. It covers damages and injuries  caused by the insured vehicle, to a third-party person or property. As per the  Motor Vehicles Act, 1988, it is mandatory for every motor vehicle owner to buy  at least third-party insurance coverage in India.
                    </p>
                    <p>
                        comprehensive insurance Policy<br/>
                        <strong>COMPREHENSIVE INSURANCE POLICY</strong><br/>
                        A comprehensive Two Wheeler Insurance plan  offers complete protection against the damages to the two wheeler due to an  accident or a road-mishap. This plan is called a comprehensive plan because it  provides coverage for the damages to the two wheeler, third-party legal  liability, theft, along with the personal accident coverage.
                    </p>
                    <p>A comprehensive Two Wheeler Insurance plan  includes coverage for fire, theft, natural and man-made catastrophes, such as a  tornado, hurricane, vandalism, damage caused to the two wheeler by animals,  falling objects, civil disturbance etc.</p>
                    <p>
                        <strong>POINTS TO CHECK BEFORE BUYING TWO WHEELER  INSURANCE</strong><br/>
                        <strong>TYPE OF TWO WHEELER INSURANCE PLAN:</strong><br/>
                        One needs to decide whether a Comprehensive Two  Wheeler Insurance is required or a standalone Third Party Liability Insurance  is to be taken. Third Party Liability is mandatory by law in India. If one opts  for only Third Party Liability policy, then only injuries caused to other  people in an accident will be covered but not the damage to the Two Wheeler or  property.
                    </p>
                    <p>
                        <strong>CASHLESS FACILITY:</strong><br/>
                        Before buying a Two Wheeler Insurance policy,  one should check up the list of garage which are providing the cashless  facility for claim settlement. This will help in the easy settlement of the  claims.
                    </p>
                    <p>
                        <strong>INSURED DECLARED VALUE (IDV):</strong><br/>
                        Two Wheeler Insurance premium is linked to the  Insured Declared Value (IDV) of the Two Wheeler. IDV is the maximum amount that  one can claim under a motor insurance policy. Lower the IDV Lower Coverage as  well as Lower Premium.
                    </p>
                    <p>
                        <strong>NO CLAIM BONUS (NCB):</strong><br/>
                        If there is no claim during the entire year  then about 5-10% discount is given on renewal Premium So, on renewal, NCB needs  to be checked that it has been accurately calculated especially if there has  been no claim in the previous year and NCB needs to be carried forward. NCB can  accumulate upto a maximum of 50%.
                    </p>
                    <p>
                        <strong>CLAIM PROCEDURE:</strong><br/>
                        The most important aspect of a “good” two  wheeler insurance policy would be easy and efficient claim procedure and hence  knowing the same and being aware is a very important factor while buying Two  Wheeler Insurance policy.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>
    )
}
export default PassengerCarrying