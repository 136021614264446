import React from "react";
import { Redirect, Route } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import ButtonClass from "../ButtonClass";

class Claims extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\s\-0-9]+\.)+[a-zA-Z\s]{2,}))$/
        );
        let field = this.state.fields
        if (field["claim"] === null || field["claim"] === undefined || field["claim"] === "") {
            errors["claim"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["message"] === null || field["message"] === undefined || field["message"] === "") {
            errors["message"] = "Please Enter Message"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
        }
        if (field["name"] === null || field["name"] === undefined || field["name"] === "") {
            errors["name"] = "Enter Your Name"
            isValid = true;
        } else if (field["name"].length < 4) {

            isValid = false;
            errors["name"] = "Please Enter Full Name"

        }
        if (
            field["email"] === null ||
            field["email"] === undefined ||
            field["email"] === ""
        ) {
            errors["email"] = "Please Enter Your Email";
            isValid = false;
        } else if (
            field["email"] !== null ||
            field["email"] !== undefined ||
            field["email"] !== ""
        ) {
            if (field["email"].length < 10) {
                errors["email"] = "Please Enter Your Email";
                isValid = false;
            }
        } else if (!pattern.test(field["email"])) {
            isValid = false;
            errors["email"] = "Please Enter Correct Email";
        }
        this.setState({ errors, isValid });



    }
    render() {

        return (
            this.state.isValid ? <Redirect to="/ThankYou" /> :
                <React.Fragment>
                    <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                        <div class="breadcrumb-bg banner-area banner-bg ClaimRelBG rocket-lazyload lazyloaded">
                            <h3>Claim</h3>
                        </div>
                    </section>
                    <section class="claim-section">
                        <div class="container">
                            <div class="SecondSectioninner">
                                <div class="main-agileits02claim">
                                    <div class="mainw3-agileinfoclaim">
                                        <div class="login-formclaim row">
                                            <div class="col-md-5">
                                                <div class="login-form2claim ProductPageSectionThirdBox Claim_Form">
                                                    <div className="">
                                                        <span style={{ color: "black" }}>What is this claim for</span>
                                                        <select id="subject-input" name="claim" className="form-control" onChange={this.handle}>
                                                            <option value="0">Select Your Insurance Type</option>
                                                            <option value="Car Insurance">Car Insurance</option>
                                                            <option value="Health Insurance">Health Insurance</option>
                                                            <option value="Two Wheeler Insurance">
                                                                Two Wheeler Insurance
                  </option>
                                                        </select>
                                                        <label className="txtError">{this.state.errors["claim"]}</label>
                                                    </div>
                                                    <div className="">
                                                        <span style={{ color: "black" }}>Tell us what happened</span>
                                                        <textarea
                                                            name="message"
                                                            id="message-input"
                                                            onChange={this.handle}
                                                            value={this.state.fields["message"]}
                                                            className="form-control"
                                                            placeholder="Please enter whatever details you can provide for us to get started immediately"
                                                        ></textarea>
                                                        <label className="txtError">{this.state.errors["message"]}</label>
                                                    </div>
                                                    <div className="">



                                                        <div className="mobile_no_container">
                                                            <span style={{ color: "black" }}>Please provide your mobile number on which we can call you</span>
                                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                                            <label className="txtError">{this.state.errors["MobileNo"]}</label>
                                                        </div>
                                                    </div>
                                                    <div className="">

                                                        <div className="mobile_no_container pb-3">
                                                            <span style={{ color: "black" }}> Your Name</span>
                                                            <MaskedInput onChange={this.handle} className="form-control" name="name" value={this.state.fields["name"]} placeholder="Enter Your Name " mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                                            <span className="txtError">{this.state.errors["name"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <span style={{ color: "black" }}> Your Email</span>

                                                        <div className="mobile_no_container">
                                                            <input
                                                                className="form-control"
                                                                onChange={this.handle}
                                                                value={this.state.fields["email"]}
                                                                id="TxtEmail"
                                                                name="email"
                                                                placeholder="Enter Your Email Id"
                                                                required="required"
                                                                type="email"

                                                            />
                                                            <span className="txtError">{this.state.errors["email"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="w-50 mt-3">
                                                        <button
                                                            type="button"
                                                            id="Reg_Btn"
                                                            className={ButtonClass()}
                                                            onClick={this.handleSubmit}
                                                            data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Please wait..."
                                                        >
                                                            Register Claim
                </button>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-7 POSContent">
                                                <div class="login-form1claim">
                                                    <div class="logo-oneclaim">
                                                        <h3 class="text-left">Claim for any insurance Need any help!</h3>
                                                        <p>
                                                            Insurance policies promise compensation for the financial loss that you suffer in case of emergencies which are covered under the policy. You buy an insurance policy for this promise and so when the covered contingency occurs, you expect the insurer to fulfil its promise and settle your claims.
                                        </p>
                                                    </div>
                                                    <div class="PageImg">
                                                        <img src={require("./assets/img/Religare/Claim.jpg")} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="ProductPageSectionFirst">
                        <div class="container">
                            <div class="POSContent">
                                <h3>What is  an insurance claim?</h3>
                                <p>
                                    Claim in an insurance  policy is when you make a demand on the insurance company for payment of the  policy benefits. A claim occurs when the event, which the policy covers,  happens and such happening causes a financial loss. If the claim is covered  under the insurance policy, the insurer pays the benefits promised under the  plan and covers your financial loss.<br />
                                        When it comes to insurance claims, you are  supposed to follow a specific process to get your claim settled. This insurance  claim process is different for different types of insurance policies. So, let's  understand the insurance claim process for some of the most popular types of  insurance plans -
            </p>

                                <h3>Insurance  claims&nbsp;under motor insurance policies</h3>
                                <p>
                                    Motor insurance  policies can be car insurance plans or bike insurance plans. The insurance  claim process for both car insurance and bike insurance is the same. Let's  understand what the process is -<br />
                                            Under motor insurance policies, there are two  types of claims - third party liability claims or own damage claims. The  insurance claim process for both these instances is different. Here's what is  the process under each instance involves -
            </p>
                                <ol class="ClaimList" type="1">
                                    <li><strong>Third party claims: </strong><br />Third party liability claims occur when any  other individual is hurt or killed by your vehicle or if any third party  property is damaged. The insurance claim process for third party claims is as  follows -</li>
                                    <ol class="ClaimListInner01" type="a">
                                        <li>You should inform the insurance company  immediately of the claim</li>
                                        <li>File an FIR with the local police authority</li>
                                        <li>The claim would be taken to the motor  accidents tribunal which would pass a ruling on the financial liability that  you face</li>
                                        <li>Depending on the ruling of the tribunal, the  insurance company would settle the claim</li>
                                    </ol>
                                    <li><strong>Own damage claims: </strong><br />Own damage insurance claim means when your car  or bike itself suffers damage. Own damage claims can, further, be subdivided  into two types - damages suffered by the vehicle or theft of the vehicle.  Here's the insurance claim process for each -</li>
                                    <ol class="ClaimListInner01" type="a">
                                        <li><strong>Damages suffered by the car or bike:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>Inform the insurance  company immediately of the damage</li>
                                            <li>The company would then  provide you the details of the nearest preferred garage where you can take your  vehicle</li>
                                            <li>Once your vehicle is  in the garage, the company's surveyor would visit and assess the damages. After  assessing the damages the claim report would be prepared</li>
                                            <li>Depending on the claim  report submitted by the surveyor, the insurance company approves the repairs</li>
                                            <li>Once the repairs are  approved, the vehicle is repaired. The company pays the repair costs directly  to the garage and the claim is settled on a cashless basis</li>
                                            <li>If, however, you get  your car repaired at a non-networked garage, you would have to bear the repair  costs and the insurance company would then reimburse the costs to you when you  submit all the bills</li>
                                        </ol>
                                        <li><strong>Theft of the car or bike:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>If your vehicle is  stolen, you should immediately inform the insurance company of the theft</li>
                                            <li>A police FIR is also  mandatory</li>
                                            <li>Once the FIR is filed,  the police try and locate your vehicle. If they fail, they would issue a  non-traceable report to you</li>
                                            <li>You would have to  submit this report to the insurance company along with the claim form</li>
                                            <li>The insurance company  would then pay you the Insured Declared Value (IDV) of your insurance policy  and the claim would be settled</li>
                                        </ol>
                                    </ol>
                                </ol>

                                <h3>Documents required for  vehicle&nbsp;insurance claims</h3>
                                <p>
                                    In case of vehicle  insurance claims, the following documents would have to be submitted -
            </p>
                                <ol class="ClaimList">
                                    <li>The RC book of the vehicle</li>
                                    <li>Driving license</li>
                                    <li>PUC certificate</li>
                                    <li>Claim form which should be duly filled and  signed</li>
                                    <li>Police FIR, wherever necessary</li>
                                    <li>Identity proof of the policyholder</li>
                                    <li>Any other documents as required by the  insurance company</li>
                                </ol>

                                <h3>Insurance  claims&nbsp;under health insurance policies</h3>
                                <p>
                                    The insurance claim  process under health insurance policies is as follows -
            </p>
                                <ol class="ClaimList" type="1">
                                    <li>To avail a cashless claim you should seek  treatment at a hospital which is tied-up with the insurance company. In a  cashless claim, the insurer settles your hospital bills directly with the  hospital and you don't have to shoulder the financial burden</li>
                                    <li>You have to get the pre-authorization form,  fill it and submit it to get cashless claim settlements. The form is available  at the hospital. You should submit the form within 24 hours of emergency  hospitalisation and 3-4 days before planned hospitalisation.</li>
                                    <li>The insurance company assesses the  pre-authorization claim form and approves cashless claims</li>
                                    <li>The hospital bills are, then, paid by the  insurance company directly</li>
                                    <li>After you are discharged, you should fill up a  claim form and submit it with the discharge summary and all the medical  documents</li>
                                    <li>If you take treatments at a non-networked  hospital, you have to bear the medical expenses yourself. You can then submit  the claim form and all the medical bills and reports and the insurance company  would reimburse you for the expenses incurred</li>
                                </ol>


                                <h3>Documents required for  health&nbsp;insurance claims</h3>
                                <p>
                                    For getting a  settlement of your health insurance claim, the following documents would be  required:
            </p>
                                <ol class="ClaimList" type="1">
                                    <li>Claim form which should be completely filled  and submitted</li>
                                    <li>Discharge summary from the hospital</li>
                                    <li>Police FIR (in case of accidental claims)</li>
                                    <li>All medical bills and receipts</li>
                                    <li>All medical documents and investigative  reports</li>
                                    <li>Identity proof of the insured</li>
                                </ol>


                                <h3>How is  health&nbsp;insurance claims&nbsp;handled?</h3>
                                <p>
                                    Health insurance  claims can be handled by either TPAs or the insurance company's in-house claim  settlement team. Let's understand the difference -
            </p>
                                <ol class="ClaimList" type="1">
                                    <li><strong>TPAs </strong>TPAs mean Third Party Administrators. TPAs are specific  companies which help facilitate a health insurance claim between you and the  company. You make a claim to the TPA which would assess your claim and then  forward it to the insurance company. The insurance company would then reject or  settle your insurance claim. TPAs are, therefore, middlemen in the insurance  claim process.</li>
                                    <li><strong>In-house claim department</strong> In case of in-house claim department, the  insurance company does not engage the services of a TPA. Instead, the company  creates a dedicated claim handling department itself. You can, therefore,  directly report your insurance claim to the insurance company through its in-house  claim settlement department. The department would then assess your claim and  make a decision on it.</li>

                                    <li><strong>Which is better: TPA or in-house Health  Insurance Claim? </strong>In-house claim  settlement departments are better as your claims get settled within a shorter period  of time compared to TPAs. The company might also offer you value-added benefits  for your claims through its claim settlement department. In case of TPAs, they  are tasked only to act as middlemen. They cannot accept or reject your claim.  So, if a large number of claims get piled with TPAs, your insurance claim  process would become too long and time-consuming. That is why try and buy a  health insurance policy from a company which has an in-house claim settlement  department.</li>
                                </ol>


                                <h3>Insurance  claims&nbsp;under life insurance policies</h3>
                                <p>
                                    Under life insurance  plans, insurance claims are categorized under the following two heads
            </p>
                                <ol class="ClaimList" type="1">
                                    <li><strong>Maturity claims </strong>This is when the term of the plan comes to an  end and you are alive to collect the plan benefits.</li>
                                    <ol class="ClaimListInner01" type="a">
                                        <li><strong>Insurance claims process&nbsp;for maturity  claims:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>Maturity claims are  initiated by the insurance company itself as the policy approached maturity</li>
                                            <li>You have to submit a  maturity discharge form and the policy bond to receive the claim</li>
                                            <li>Once the documents are  submitted, the claim would be credited to your bank account</li>
                                        </ol>
                                        <li><strong>Documents required for maturity claims:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>Maturity claim form</li>
                                            <li>Policy bond</li>
                                            <li>Your identity proof</li>
                                            <li>Your bank account  details for receiving the maturity proceeds</li>
                                        </ol>
                                    </ol>
                                    <li><strong>Death  claims </strong>In case of death  during the policy tenure, death claims occur. Death claims are collected by the  nominee whom you appoint in your insurance policy.</li>
                                    <ol class="ClaimListInner01" type="a">
                                        <li><strong>Insurance claim&nbsp;process for death claims:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>The nominee has to  inform the insurance company about the death of the insured</li>
                                            <li>A claim form should be  filled and submitted and you would also have to submit various relevant  documents</li>
                                            <li>The company would  verify the claim and the documents submitted</li>
                                            <li>Once verified, the  company would pay the claim directly to the nominee's bank account</li>
                                        </ol>
                                        <li><strong>Documents required for death claims:</strong></li>
                                        <ol class="ClaimListInner02" type="I">
                                            <li>Claim form, filled and  signed by the nominee</li>
                                            <li>Identity proof of the  nominee</li>
                                            <li>Original Policy bond</li>
                                            <li>Death certificate</li>
                                            <li>Police FIR (in case of  accidental death)</li>
                                            <li>Medical reports (if  available)</li>
                                            <li>Coroner's report,  punchnama, post mortem report, etc. (in case of accidental deaths)</li>
                                        </ol>
                                    </ol>
                                </ol>
                                <p>
                                    So, these are the insurance  claim processes of some of the most popular insurance plans. understand the  claim process so that if you face an insurance claim you would know exactly how  to get your claims settled and the documents required for the same. Alternatively,  you can also contact Religare &nbsp;team for  your insurance claim settlements. Religare has a dedicated claims handling  department which gets your claims settled for you. Religare claim department  handles the insurance claim process making it convenient for you. Just intimate  the claim by calling Religare helpline at 1860-25-88888 or by sending an email to insurance@religare.com

            </p>
                            </div>
                        </div>
                    </section>
                </ React.Fragment>
        );
    };
}
export default Claims;
