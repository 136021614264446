import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";

import "./assets/css/Pratiraksha.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import { NavMenu } from "./NavMenu";
import PrivateCar from "./FooterPages/PrivateCar";
import PassengerCarrying from "./FooterPages/PassengerCarrying";
import Mediclaim from "./FooterPages/Mediclaim";
import Term from "./FooterPages/Term";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PratirakshaMiddleSection from "./PratirakshaSection";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
const findPath=()=>{
    let url=window.location.href.toLowerCase();
    let path="HRIKLS";
    if(url.match("proposalmotor") || url.match("proposalhealth"))
    path=window.location.pathname
    return path;
  }
const Pratiraksha = (props) => {
    let apipath=findPath();
    return (
        <React.Fragment>
            <div className={"Pratirakshabody " + props.newUi}>
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />



                </Layout>

                <Switch>
                    <Route exact path="/FourWheelerInsurance" component={PrivateCar} />
                    <Route exact path="/TwoWheelerInsurance" component={PassengerCarrying} />
                    <Route exact path="/HealthInsurance" component={Mediclaim} />
                    <Route exact path="/TermLifeInsurance" component={Term} />
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    
                    

                    <Route path="/About" component={About} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apipath} component={PaymentSuccessOrFail} />
                    <PratirakshaMiddleSection />
                </Switch>

                <Footer />
                <SideNav />
                <SocialToggle mobile="9324374807" email="service@enivesh.com" whatsapp="9324374807" />
            </div>
        </React.Fragment>
    );
};
export default Pratiraksha;
