/* eslint-disable */
import React from 'react';
const TravelIns = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <section class="summeryPage">
            <div class="container">
                <div class="main-counter-area">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <h3>Travel Insurance</h3>
                                <ul>
                                    <li>We all travel – for business or for pleasure and thereby we are exposed  to a number of risks during our journey.   They can be within India or outside India.  The risks pertain to :</li>

                                    <ul>

                                        <li>Accident  or injury or health related issues</li>
                                        <li>Accidental  Compensation</li>
                                        <li>Loss  of ticket</li>
                                        <li>Loss  of passport</li>
                                        <li>Missing  a connecting flight</li>
                                        <li>Trip  cancellation or curtailment</li>
                                        <li>Baggage  loss / delay</li>
                                        <li>Emergency  evacuation</li>
                                        <li>And  many related issues</li>

                                    </ul>
                                    <p>There  are a number of insurers who offer such solutions as provided below:</p>

                                    <li>International Travel Insurance, </li>
                                    <li>Student Travel Insurance, </li>
                                    <li>Domestic Travel Insurance, and </li>
                                    <li>Senior Citizen Travel Insurance. You can choose a plan depending on your  travel patterns and needs.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/KareIndia/Travel.png")} />
                        </div>
                        <div class="col-md-12">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <p>What  if it were a hassle free journey whether International or domestic with an  Insurance Policy to take care of such contingencies?  Above are mentioned the coverage extension  under these policies aged six and above. For frequent travelers both International  as well as domestic, we recommend the multi-trip policy that will cover  travelers for an entire year.The same features are extended to the Senior  citizens or for Students planning studies abroad</p>
                                <h3>Domestic Insurance</h3>
                                <p>
                                    This  insurance extends to cover our residences or place where we live from various  peril to which our house and the contents that we have accumulated through our  hard efforts.  The perils may include:
                        </p>
                                <ul>
                                    <li>Fire, lightening, explosions,</li>
                                    <li>Riots strikes, malicious damages,</li>
                                    <li>AOG perils like storms, flooding etc</li>
                                    <li>Impact damage</li>
                                    <li>Earthquake etc.</li>
                                    <li>Theft burglary</li>
                                    <li>Electrical and mechanical breakdowns of our equipment</li>
                                </ul>
                                <p>Through  ensuring Insurance coverage you can these we are financially protected if some  damage occurs</p>




                                <p><h3>COMMERCIAL Insurance </h3><br />
                                    <b>Property Insurance</b> <br />
                                        There is ahuge number of various policies available under  this head primarily focused on protecting the financial loss that may occur to  the property or contents in any business venture</p>
                                    <ul>
                                        <li>Fire with  Earthquake and STFI</li>
                                        <li>Engineering  Insurances – Contractors All Risk, Erection All risk, Machinery insurance etc.</li>
                                        <li>Marine –  covers goods in transit within India and Abroad</li>
                                        <li>Commercial  Auto insurance and Aircraft insurance</li>
                                        <li>Shopkeepers&rsquo;  insurance</li>
                                        <li>Office  premises Insurance</li>
                                    </ul>
                                <p><h3>Health and similar insurance</h3></p>
                                    <ul>
                                        <li>Group  Mediclaim Insurance</li>
                                        <li>Group  Personal Accident</li>
                                        <li>Group Term  Life</li>
                                    </ul>
                                    <p><h3>Liability Insurance</h3><br />
                                        The business may be exposed to various Liabilities and  cause huge business losses which may be protected by insuring under the  following covers:</p>
                                        <ul>
                                            <li>Workmen  Compensation now called Employers Liability Insurance which covers physical  injury or death compensation</li>
                                            <li>Commercial  General Liability to cover financial losses arising from any injury or death to  a third party other than the insured or his employees.  The legal costs are covered</li>
                                            <li>Professional  Liability where if a liability claim is filed in a court of law against  negligence or non-compliance of contractual obligation or errors and omissions  in providing services leading to a loss to the Principal.The legal costs are  covered.</li>
                                            <li>Product  Liability – Where the product does not perform as per the terms and conditions  of the contract leading to loss to the principals.The legal costs are covered</li>
                                        </ul>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
export default TravelIns