/* eslint-disable */ 
import React from "react";
const L = () => {
    return (
        <>
            <p>There are four types of insurance plans that you should definitely purchase. They are mentioned as below</p>
            <ul className="bullets">
                <li>Health Insurance</li>
                <li>Life Insurance</li>
                <li>Motor Insurance</li>
                <li>Property Insurance</li>
            </ul >
            <p>Do consider all inclusions and exclusions of your insurance plans before making the final purchase! In addition, various add - on covers can help provide that extended coverage & enhance your basic insurance policy.Ensure to keep these different factors in mind, to avoid any inconvenience in the later stage.</p>

            </>
        )
}

const AccordionData = [
  {
    id: "0",
        title: "What kind of insurance do I actually need?",
        body: <L/>,
    class: "bg-red",
  },
  {
    id: "1",
    title:
      "What are the must-have I should considere before buying insurance online ?",
      body: "Before purchasing an insurance policy online, it is of primary importance to check if a policy you are considering fulfills your needs. Also, it is necessary that you verify the authenticity of the service-providers.  Also, make a note of the coverage, premium, claim-settlement ratio, renewal process, and the terms and conditions of the insurance policy beforehand. You can also consider going through the online customer reviews of the insurer before making the final purchase.",
    class: "bg-blue",
  },
  {
    id: "2",
    title: "Why buy Insurance from Insurance Mandi ?",
      body: "With our help, you can easily purchase insurance policies with wider coverage, affordable premiums and other attractive features such as hassle-free policy renewal, claims assistance and efficient customer service.  At Insurance Mandi, we always strive to deliver the best in the simplest way.",
    class: "bg-red",
  },
  {
    id: "3",
    title: "How different is Insurance Mandi from other insurance marketplace ?",
      body: "At Insurance Mandi, our efforts are always directed towards building a good customer relationship. We also partner with several reputable brands, and this enables us to bring the best for you.",
    class: "bg-blue",
  },
  {
    id: "4",
    title: "Does Insurance Mandi offer Zero Depreciation",
      body: "Absolutely! Our endeavor is to provide you with all the possible options.",
    class: "bg-red",
  },
  {
    id: "5",
    title: "How do I raise a claim ?",
      body: "Simple! Visit our claims section to raise a claim. In case of any claim-related queries, you can always reach out to us, and we will be happy to assist you.",
    class: "bg-blue",
  },
  {
    id: "6",
    title:
      "Is claim settlement Ratio an important factor for choosing an Insurance Provider?",
        body: "You got it right! Claim Settlement Ratio is a crucial factor that you must consider before choosing an insurance provider. The Claim Settlement Ratio indicates the number of claims an insurer has settled against the total number of claims received. The efficiency of the insurer is judged on the basis of its Claim Settlement Ratio. Also when someone clicks on Read More FAQs link he/ she should be directed to FAQs page which should be developed in the following way",
    class: "bg-red",
  },
];
export default AccordionData;
