import axios from 'axios';
import React, { Component } from 'react';
class LifePayment extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            pLifeModel:{},
            APIUrl:window.localStorage.getItem("portal")
         }
         this.state.pLifeModel=this.props.Req;
        
    }
    componentDidMount(){
       let companyName=this.state.pLifeModel.CompanyName;
       let InsurerModel="";
       switch(companyName){
           case "AegonLife":InsurerModel="Aegon";
           break;
           case "EdelweissLife":InsurerModel="Edelweiss";
           break;
       }
       if(InsurerModel==""){
           alert("Some Error Occured");
       window.location.href="/"
       }
      
       let Model={
           URL:this.state.APIUrl+"/lifeapi/api/InsurerTerm/"+InsurerModel,
           PostString:JSON.stringify(this.state.pLifeModel)
       }
       console.log(JSON.stringify(Model))
    axios.post("/bpi/WeatherForecast/CallCheck",Model)
    .then(Res=>{
        console.log(Res.data);
        if(this.state.pLifeModel.CompanyName==="AegonLife"){
        window.location.href=Res.data;
    }
        else if(this.state.pLifeModel.CompanyName==="EdelweissLife"){
            window.sessionStorage.setItem("payRes",Res.data)
            
            window.location.href=JSON.parse(window.sessionStorage.getItem("payRes")).Resume_Journey_URL;
        }
    })
      
       
    }
    render() { 
        return ( <h1>Please Wait...</h1> );
    }
}
 
export default LifePayment;