import axios from 'axios';
import React, { Component } from 'react';
import { useState } from 'react';
import { ChildBtn } from '../ButtonClass';
let ApiUrl=window.localStorage.getItem("portal");
const getStatus=(TransectionNumber,EnquiryNo)=>{
    if(TransectionNumber==null && EnquiryNo==null){

        return alert("Please Enter Transection Number or Enquiry Number")
    }
    let Model={
        EnquiryNumber :EnquiryNo,
        ProposalNo :TransectionNumber,
        ClientURL :ApiUrl,
    };
    let md={
        URL: ApiUrl+"/api/api/InsurerMotor/GetInspectionStaus",
        PostString:JSON.stringify(Model)
    }
    axios.post("/bpi/WeatherForecast/CallCheck",md)
    .then(Res=>{
        if(Res.data.ErrorMsg===null)
            window.location.href=Res.data.PaymentUrl;
            else alert(Res.data.ErrorMsg)
    })
    
}
const InspectionStatus= (props) => {
    const [TransectionNumber,setTransection]=useState(null);
const [EnquiryNo,setEnquiryNo]=useState(null);
    return (<div className="modal fade MTB-Insured-HH ui-draggable show" id="CallRequest" data-backdrop="static" role="dialog">
    <div className="modal-dialog newModalqt">
        <div className="modal-content">
            <div className="modal-header text-center ui-draggable-handle">
                Get Inspection Status
                <button type="button" className="close" data-dismiss="modal" onClick={props.close}>×</button>
            </div>
            <div className="modal-body">
                <div className="choose-relative">
                    <div className="form-group">
                        <label className="">
                                <input type="text" className="FormTagsBox" name="TransectionNumber" onChange={(e) => setTransection(e.target.value)} value={TransectionNumber} placeholder="Transection Number" />
                            <span id="VldInterested"></span>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className="">
                            <input id="mobile" type="text" className="FormTagsBox" value={EnquiryNo} onChange={(e)=>setEnquiryNo(e.target.value)}  placeholder="Enquiry Number" />
                            <span id="Vldmobile"></span>
                        </label>
                    </div>
                   
                </div>
            </div> 
                            <div className="modal-footer text-center">
                                <div className="col-md-6"><input type="button" id="btnReq" className={ChildBtn()} onClick={()=>getStatus(TransectionNumber,EnquiryNo)} value="Get Status" /></div>
                
            </div>
        </div>
    </div>
</div>)
}
export default InspectionStatus;