import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";

import "./assets/css/KareIndia.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import KareMiddleSection from "./KareMiddleSection";
import { NavMenu } from "./NavMenu";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import Career from "./Career";
import TravelIns from "./FooterPages/TravelIns";
import PrivacyPolicy from "./FooterPages/PrivacyPolicy";
import TermsAndCondition from "./FooterPages/TermsAndCondition";
import HealthIns from "./FooterPages/HealthIns";
import LifeIns from "./FooterPages/LifeIns";
import MotorIns from "./FooterPages/MotorIns";
const findPath=()=>{
    let url=window.location.href.toLowerCase();
    let path="HRIKLS";
    if(url.match("proposalmotor") || url.match("proposalhealth"))
    path=window.location.pathname
    return path;
  }
const KareIndia = (props) => {
    let apiPath = findPath()
    return (
        <React.Fragment>
            <div className="KareIndiabody">
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />



                </Layout>

                <Switch>
                    <Route exact path="/" component={KareMiddleSection} />
                    
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    <Route exact path="/GetQuoteLife" component={LifeQuote} />
                    
                    

                    <Route path="/About" component={About} />
                    <Route path="/Career" component={Career} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apiPath} component={PaymentSuccessOrFail} />
                    <Route exact path="/TravelInsurance" component={TravelIns} />
                    <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
                    <Route exact path="/TermsAndCondition" component={TermsAndCondition} />
                    <Route exact path="/HealthInsurance" component={HealthIns} />
                    <Route exact path="/LifeInsurance" component={LifeIns} />
                    <Route exact path="/MotorInsurance" component={MotorIns} />
                </Switch>

                <Footer />
                <SideNav />
                <SocialToggle mobile="01204560064" email="customercare@kareindia.com" whatsapp="9811862140" />
            </div>
        </React.Fragment>
    );
};
export default KareIndia;
