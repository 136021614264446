import React from "react";
const Product = [
  {
    id: 1,
    name: "Car",
    to: "/Car",
    className: "nav-item nav-link",
    
    },
  {
    id: 2,
    name: "2 Wheeler",
    to: "/Bike",
    className: "productTabsRisk",
    
  },
  
  {
    id: 3,
    name: "Health",
    className: "productTabsRisk",
    to: "/Health",
   
  },
  {
    id: 4,
    name: "Life",
    className: "productTabsRisk",
    to: "/",
   
    },
    {
        id: 5,
        name: "GCV",
        className: "productTabsRisk",
        to: "/",

    },
    {
        id: 6,
        name: "PCV",
        className: "productTabsRisk",
        to: "/",

    },
];
export default Product;
