import React from 'react';
import ButtonClass, { ChildBtn } from "../ButtonClass";
import MaskedInput from 'react-text-mask'
import CustomFact from './CustomFact';
import "./Life.css"
const Life = (props) => {
    let errors = props.errors
    let date=new Date();
    let dd=date.getDate()<10?"0"+date.getDate():date.getDate();
    let mm=(date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1);
    let yy=date.getFullYear();
    let MaxDate=yy-18+"-"+mm+"-"+dd;
    let currDate=yy+"-"+mm+"-"+dd;
        return (
            <React.Fragment>
            <div className="row d-flex justify-content-center">
                <div className="col-sm-10">
                    <div className="packagesMainHealth text-center">
                        <div className="productTabsMainHealth productTabsMainHelath text-center">

                                <h3 className="heading text-center">Term Life Plans at Lowest Rates</h3>

                                <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                                    <div className="PF0012">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-3 NoPaddingRight">
                                                    <label>
                                                        <select name="Gender" id="drpGender" value={props.Gender} onChange={props.handle}>
                                                            <option value="Select">Select Gender</option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                        </select>
                                                    </label>
                                                    <span className="error">{errors.Gender}</span>
                                                    
                                                </div>
                                                <div className="col-md-6">
                                                    <label>
                                                        <MaskedInput id="txtFirstName" value={props.FullName} onChange={props.handle} name="FullName" placeholder="Enter Full Name"
                                                            mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} />

                                                        <span id="txtFullnameSpesfic-error" className="error"></span>
                                                    </label>
                                                    <span className="error">{errors.FullName}</span>
                                                   
                                                </div>
                                                <div className="col-md-3 NoPaddingLeft">
                                                    <label>



                                                        <input type="date" max={MaxDate} defaultValue={MaxDate} name="DOB" onChange={props.handle} value={props.DOB} id="textDOB" required="required" placeholder="DD/MM/YYYY" className="hasDatepicker" />
                                                    </label>
                                                   <span className="error">{errors.DOB}</span>
                                                   
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 offset-md-4">
                                                    <label>
                                                        <MaskedInput name="Mobile" value={props.Mobile} onChange={props.handle} mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" id="txtMobile" required="required" placeholder="+91"
                                                        guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                    <label id="txtMobileSpesfic-error" className="error"></label>
                                                    </label>
                                                    <span className="error">{errors.Mobile}</span>
                                                   
                                                </div>
                                                <div className="col-md-4 offset-md-4">
                                                    <button type="button" className={ButtonClass()} id="FirstForm" onClick={ props.CallPopUp}>Next</button>
                                                    <p style={{ color: "#142963" }}>I Agree to<a target="_blank"> <span style={{ color: "#F58634" }}>T&amp;C</span></a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                </div>
                {
                    props.showPopUp && <CustomFact handle={props.handle} 
                    SmokeStatus={props.SmokeStatus}  close={ props.close}
                    AnnualIncome={props.AnnualIncome} SumInsured={props.SumInsured}
                    Coverage={ props.Coverage}  callGetQuote={props.callGetQuote} BtnValue={props.BtnValue} IncomeList={props.IncomeList} CoverList={props.CoverList}  />
                }
            </React.Fragment>
        );
    }


export default Life;