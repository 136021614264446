import React from "react";
import { Link } from "react-router-dom";
const ProductList = (props) => {
    return (
        <section class="section explore-products pt-3">
            <div class="container">
                <h5 class="text-secondary font-bold">Explore our products</h5>
                <div class="row mt-4 mt-md-3 g-4 g-md-5">
                    <div class="col-6 col-md-3 mt-5">
                        <Link to="/Car-Insurance" class="text-primary">
                            <div class="box">
                                <h5>Car insurance</h5>
                                <p class="d-none text-dark d-md-block mt-5 pt-md-4">
                                    Accident, Theft, AOG Perils and Third-Party Cover - Trust
                                    Bimastreet for all insurance policies.
                        </p>
                                <div class="row mt-3">
                                    <div class="col">
                                        <img src={require("../assets/img/BimaStreet/car.svg")} class="img-fluid w-auto" />
                                    </div>
                                    <div class="col-auto text-end align-self-end">
                                        <a href="#" class="stretched-link">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12.95 1.55L18.65 7.25H0.5V8.75H18.65L12.95 14.45L14 15.5L21.5 8L14 0.5L12.95 1.55Z"
                                                    fill="#72B571" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-6 col-md-3 mt-5">
                        <Link to="/Bike-Insurance" class="text-primary">
                            <div class="box">
                                <h5>Bike insurance</h5>
                                <p class="d-none text-dark d-md-block mt-5 pt-md-4">
                                    Drive your vehicle with full financial protection. Accidental
                                    Damages, Bike Theft and Third-Party Cover.
                        </p>
                                <div class="row mt-3">
                                    <div class="col">
                                        <img src={require("../assets/img/BimaStreet/bike.svg")} class="img-fluid w-auto" />
                                    </div>
                                    <div class="col-auto text-end align-self-end">
                                        <a href="#" class="stretched-link">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12.95 1.55L18.65 7.25H0.5V8.75H18.65L12.95 14.45L14 15.5L21.5 8L14 0.5L12.95 1.55Z"
                                                    fill="#72B571" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-6 col-md-3 mt-5">
                        <Link to="/Health-Insurance" class="text-primary">
                            <div class="box">
                                <h5>Health insurance</h5>
                                <p class="d-none text-dark d-md-block mt-5 pt-md-4">
                                    Don't worry about hospitalisation expenses. Have your family
                                    insured completely with Bimastreet.
                        </p>
                                <div class="row mt-3">
                                    <div class="col">
                                        <img src={require("../assets/img/BimaStreet/health.svg")} class="img-fluid w-auto" />
                                    </div>
                                    <div class="col-auto text-end align-self-end">
                                        <a href="#" class="stretched-link">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12.95 1.55L18.65 7.25H0.5V8.75H18.65L12.95 14.45L14 15.5L21.5 8L14 0.5L12.95 1.55Z"
                                                    fill="#72B571" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-6 col-md-3 mt-5">
                        <Link to="/Life-Insurance" class="text-primary">
                            <div class="box">
                                <h5>Life insurance</h5>
                                <div class="row">
                                    <div class="col-md-11">
                                        <p class="d-none text-dark d-md-block mt-5 pt-md-4">
                                            Guarantee your family's future with the right term policy from
                                            Bimastreet.
                                </p>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col">
                                        <img src={require("../assets/img/BimaStreet/life.svg")} class="img-fluid w-auto" />
                                    </div>
                                    <div class="col-auto text-end align-self-end">
                                        <a href="#" class="stretched-link">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12.95 1.55L18.65 7.25H0.5V8.75H18.65L12.95 14.45L14 15.5L21.5 8L14 0.5L12.95 1.55Z"
                                                    fill="#72B571" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ProductList;
