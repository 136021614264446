import React, { Component, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Modal, Accordion, Card } from "react-bootstrap";
export const NavMenu = () => {
    const history = useHistory()
    const [path, setPath] = useState("")
    useEffect(() => {
        return history.listen((location) => {
            setPath(location.pathname);
        })
    }, [history])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <header>

                {path === "/" || path === "" || path === "/Car-Insurance" || path === "/Bike-Insurance" || path === "/Health-Insurance" ?
                    <>
                        <nav class="navbar navbar-expand-md navbar-light d-none d-md-flex">
                            <div class="container-fluid">
                                <Link class="navbar-brand" to="/">
                                    <img src={require("./assets/img/BimaStreet/logo.svg")} id="logoasdf" />
                                </Link>
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNav">
                                    <ul class="navbar-nav">
                                        <li class="nav-item product-megamenu" onMouseOver={() => document.getElementById("tsdMegamenu").classList.add("tsdMegamenuHover")} onMouseOut={() => document.getElementById("tsdMegamenu").classList.remove("tsdMegamenuHover")} >
                                            <a class="nav-link" href="#">Products</a>

                                        </li>
                                        <li class="nav-item">
                                            <Link to="/About" class="nav-link">About</Link>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Free Claims Assistance
              </a>
                                            <ul class="dropdown-menu animate slideIn" aria-labelledby="navbarDropdown">
                                                <li><Link class="dropdown-item" to="/Car-free-claims-assistance">Car Free Claims Assistance</Link></li>
                                                <li><Link class="dropdown-item" to="/Bike-free-claims-assistance">Bike Free Claims Assistance</Link></li>
                                                <li><Link class="dropdown-item" to="/Health-free-claims-assistance">Health Free Claims Assistance</Link></li>
                                                <li><Link class="dropdown-item" to="/Life-free-claims-assistance">Life Free Claims Assistance</Link></li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/contact-us" class="nav-link">Contact</Link>
                                        </li>
                                    </ul>
                                    <ul class="navbar-nav ml-auto">
                                        <li class="nav-item">
                                            <a class="nav-link" href="/myaccount">
                                                <span class="d-none d-md-inline">Login</span> <svg class="ms-md-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 3C12.7417 3 13.4667 3.21993 14.0834 3.63199C14.7001 4.04404 15.1807 4.62971 15.4646 5.31494C15.7484 6.00016 15.8226 6.75416 15.6779 7.48159C15.5333 8.20902 15.1761 8.8772 14.6517 9.40165C14.1272 9.9261 13.459 10.2833 12.7316 10.4279C12.0042 10.5726 11.2502 10.4984 10.5649 10.2145C9.87972 9.93072 9.29405 9.45007 8.88199 8.83339C8.46994 8.2167 8.25 7.49168 8.25 6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3ZM12 1.5C10.9617 1.5 9.94662 1.80791 9.08326 2.38478C8.2199 2.96166 7.54699 3.7816 7.14963 4.74091C6.75227 5.70022 6.64831 6.75582 6.85088 7.77422C7.05345 8.79262 7.55347 9.72808 8.28769 10.4623C9.02192 11.1965 9.95738 11.6966 10.9758 11.8991C11.9942 12.1017 13.0498 11.9977 14.0091 11.6004C14.9684 11.203 15.7883 10.5301 16.3652 9.66674C16.9421 8.80339 17.25 7.78835 17.25 6.75C17.25 5.35761 16.6969 4.02226 15.7123 3.03769C14.7277 2.05312 13.3924 1.5 12 1.5Z" fill="black"></path>
                                                    <path d="M19.5 22.5H18V18.75C18 18.2575 17.903 17.7699 17.7145 17.3149C17.5261 16.86 17.2499 16.4466 16.9017 16.0983C16.5534 15.7501 16.14 15.4739 15.6851 15.2855C15.2301 15.097 14.7425 15 14.25 15H9.75C8.75544 15 7.80161 15.3951 7.09835 16.0983C6.39509 16.8016 6 17.7554 6 18.75V22.5H4.5V18.75C4.5 17.3576 5.05312 16.0223 6.03769 15.0377C7.02226 14.0531 8.35761 13.5 9.75 13.5H14.25C15.6424 13.5 16.9777 14.0531 17.9623 15.0377C18.9469 16.0223 19.5 17.3576 19.5 18.75V22.5Z" fill="black"></path>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <div class="justify-content-between align-self-center  d-flex d-md-none">
                            <div class="col-auto p-0 d-flex align-items-center">
                                <a data-bs-toggle="offcanvas" onClick={handleShow} role="button" aria-controls="mobileMenu">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23 13H1C0.448 13 0 12.553 0 12C0 11.447 0.448 11 1 11H23C23.552 11 24 11.447 24 12C24 12.553 23.552 13 23 13Z"
                                            fill="#212121" />
                                        <path
                                            d="M23 6H12C11.448 6 11 5.553 11 5C11 4.447 11.448 4 12 4H23C23.552 4 24 4.447 24 5C24 5.553 23.552 6 23 6Z"
                                            fill="#212121" />
                                        <path
                                            d="M12 20H1C0.448 20 0 19.553 0 19C0 18.447 0.448 18 1 18H12C12.552 18 13 18.447 13 19C13 19.553 12.552 20 12 20Z"
                                            fill="#212121" />
                                    </svg>
                                </a>
                                <a href="#" class="ml-2">
                                    <img src={require("./assets/img/BimaStreet/logo.svg")} />
                                </a>
                            </div>
                            <div class="col-auto p-0 align-self-center">
                                <a href="#">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 3C12.7417 3 13.4667 3.21993 14.0834 3.63199C14.7001 4.04404 15.1807 4.62971 15.4646 5.31494C15.7484 6.00016 15.8226 6.75416 15.6779 7.48159C15.5333 8.20902 15.1761 8.8772 14.6517 9.40165C14.1272 9.9261 13.459 10.2833 12.7316 10.4279C12.0042 10.5726 11.2502 10.4984 10.5649 10.2145C9.87972 9.93072 9.29405 9.45007 8.88199 8.83339C8.46994 8.2167 8.25 7.49168 8.25 6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3ZM12 1.5C10.9617 1.5 9.94662 1.80791 9.08326 2.38478C8.2199 2.96166 7.54699 3.7816 7.14963 4.74091C6.75227 5.70022 6.64831 6.75582 6.85088 7.77422C7.05345 8.79262 7.55347 9.72808 8.28769 10.4623C9.02192 11.1965 9.95738 11.6966 10.9758 11.8991C11.9942 12.1017 13.0498 11.9977 14.0091 11.6004C14.9684 11.203 15.7883 10.5301 16.3652 9.66674C16.9421 8.80339 17.25 7.78835 17.25 6.75C17.25 5.35761 16.6969 4.02226 15.7123 3.03769C14.7277 2.05312 13.3924 1.5 12 1.5Z"
                                            fill="black" />
                                        <path
                                            d="M19.5 22.5H18V18.75C18 18.2575 17.903 17.7699 17.7145 17.3149C17.5261 16.86 17.2499 16.4466 16.9017 16.0983C16.5534 15.7501 16.14 15.4739 15.6851 15.2855C15.2301 15.097 14.7425 15 14.25 15H9.75C8.75544 15 7.80161 15.3951 7.09835 16.0983C6.39509 16.8016 6 17.7554 6 18.75V22.5H4.5V18.75C4.5 17.3576 5.05312 16.0223 6.03769 15.0377C7.02226 14.0531 8.35761 13.5 9.75 13.5H14.25C15.6424 13.5 16.9777 14.0531 17.9623 15.0377C18.9469 16.0223 19.5 17.3576 19.5 18.75V22.5Z"
                                            fill="black" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </>
                    :
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="container-fluid">
                            <Link class="navbar-brand" to="/">
                                <img src={require("./assets/img/BimaStreet/logo.svg")} id="logoasdf" />
                            </Link>
                            <div class="ms-auto">
                                <a href="#" class="btn btn-primary w-auto"><span class="d-none d-md-inline">99594334673</span>
                                    <svg class="ms-md-3" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                                stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
                                            <path
                                                d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                                stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
                                            <path
                                                d="M9.66699 3.66675C10.551 3.66675 11.3989 4.01794 12.024 4.64306C12.6491 5.26818 13.0003 6.11603 13.0003 7.00008"
                                                stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </a>
                            </div>
                        </div>
                    </nav>

                }








            </header>
            <Modal show={show} onHide={handleClose} className="MobileMenuMain">
                <div class="offcanvas offcanvas-start mobile-menu">
                    <div class="offcanvas-header text-end justify-content-end">
                        <button type="button" class="btn-close text-reset" onClick={handleClose}></button>
                    </div>
                    <div class="offcanvas-body">

                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="#">Home</a>
                            </li>
                        </ul>

                        <Accordion class="accordion">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Products
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <nav class="nav flex-column">
                                            <Link to="/Car-Insurance" class="nav-link ps-0">Car insurance</Link>
                                            <Link to="/Bike-Insurance" class="nav-link ps-0">Bike insurance</Link>
                                            <Link to="/Health-Insurance" class="nav-link ps-0">Health insurance</Link>
                                            <Link to="/Life-Insurance" class="nav-link ps-0">Life insurance</Link>
                                        </nav>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="#">About</a>
                            </li>
                        </ul>
                        <Accordion class="accordion">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Free Claims Assistance
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <nav class="nav flex-column">
                                            <a class="nav-link ps-0" href="car-free-claims-assistance.html">Car Free Claims Assistance</a>
                                            <a class="nav-link ps-0" href="bike-free-claims-assistance.html">Bike Free Claims Assistance</a>
                                            <a class="nav-link ps-0" href="health-free-claims-assistance.html">Health Free Claims Assistance</a>
                                            <a class="nav-link ps-0" href="life-free-claims-assistance.html">Life Free Claims Assistance</a>
                                        </nav>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="#">Contact</a>
                            </li>
                        </ul>



                    </div>
                </div>
            </Modal>

            <div class="tsd-megamenu" onMouseOver={() => document.getElementById("tsdMegamenu").classList.add("tsdMegamenuHover")} onMouseOut={() => document.getElementById("tsdMegamenu").classList.remove("tsdMegamenuHover")} id="tsdMegamenu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 pt-4">
                            <h5><Link to="/Car-Insurance?id=firstSection" class="text-primary">Car insurance</Link></h5>
                            <nav class="nav flex-column">
                                <Link to="/Car-Insurance?id=whatisCarinsurance" class="nav-link">What is Car insurance</Link>
                                <Link to="/Car-Insurance?id=mandatoryinIndia" class="nav-link" >Is car insurance mandatory in India?</Link>
                                <Link to="/Car-Insurance?id=benefits" class="nav-link">Benefits of car insurance</Link>
                                <Link to="/Car-Insurance?id=types" class="nav-link" >Types of car insurance</Link>
                                <Link to="/Car-Insurance?id=keyTerms" class="nav-link">Key terms used in car insurance</Link>
                                <Link to="/Car-Insurance?id=buycar" class="nav-link" >Buy car insurance online</Link>
                                <Link to="/Car-Insurance?id=whyBimastreet" class="nav-link" >Why Bimastreet for car insurance</Link>
                            </nav>
                        </div>
                        <div class="col-md-3 pt-4">
                            <h5><Link to="/Bike-Insurance" class="text-primary">Bike insurance</Link></h5>
                            <nav class="nav flex-column">
                                <Link to="/Bike-Insurance?id=whatIsBike" class="nav-link">What is bike insurance?</Link>
                                <Link to="/Bike-Insurance?id=mandatorySec" class="nav-link" >Is bike insurance mandatory in India?</Link>
                                <Link to="/Bike-Insurance?id=benefitsSec" class="nav-link" >Benefits of bike or two-wheeler insurance</Link>
                                <Link to="/Bike-Insurance?id=TypesOfBike" class="nav-link" >Types of bike insurance</Link>
                                <Link to="/Bike-Insurance?id=buyBike" class="nav-link" >Buy bike insurance online</Link>
                                <Link to="/Bike-Insurance?id=claimBike" class="nav-link" >Claim two-wheeler insurance online</Link>
                                <Link to="/Bike-Insurance?id=whyBuy" class="nav-link" >Why Bimastreet for bike insurance</Link>
                            </nav>
                        </div>
                        <div class="col-md-3 pt-4">
                            <h5><Link to="/Health-Insurance" class="text-primary">Health insurance</Link></h5>
                            <nav class="nav flex-column">
                                <Link to="/Health-Insurance?id=whatishealth" class="nav-link">What is Health insurance</Link>
                                <Link to="/Health-Insurance?id=benefitsOfHealth" class="nav-link">Benefits of health insurance</Link>
                                <Link to="/Health-Insurance?id=TypesOfHealth" class="nav-link">Types of health insurance</Link>
                                <Link to="/Health-Insurance?id=KeTermsHealth" class="nav-link">Terms used in health insurance</Link>
                                <Link to="/Health-Insurance?id=BuyHealth" class="nav-link">Buy health insurance online</Link>
                                <Link to="/Health-Insurance?id=whyBimaHealth" class="nav-link">Why Bimastreet for health insurance</Link>
                            </nav>
                        </div>
                        <div class="col-md-3 pt-4">
                            <h5><Link to="/Life-Insurance" class="text-primary">Life insurance</Link></h5>
                            <nav class="nav flex-column">
                                <Link to="/Life-Insurance?id=WhatIsLife" class="nav-link">What is Life insurance?</Link>
                                <Link to="/Life-Insurance?id=benefitsOfLife" class="nav-link">Benefits of life insurance</Link>
                                <Link to="/Life-Insurance?id=TypesOfLife" class="nav-link">Types of life insurance</Link>
                                <Link to="/Life-Insurance?id=KeyTermsLife" class="nav-link">Key terms used in life insurance</Link>
                                <Link to="/Life-Insurance?id=BuyLife" class="nav-link">Buy life insurance online</Link>
                                <Link to="/Life-Insurance?id=WhyBima" class="nav-link">Why Bimastreet for life insurance</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

