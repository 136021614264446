const Partner = [

    {
        alt: "icici",
        src: require("../Contents/img/partner/icici.jpg"),
    },
    {
        alt: "HdfcLifeInsurance",
        src: require("../Contents/img/partner/HdfcLifeInsurance.jpg"),
    },
    
    {
        alt: "LICIndia",
        src: require("../Contents/img/partner/LICIndia.jpg"),
    },
    {
        alt: "religare_logo",
        src: require("../Contents/img/partner/religare_logo.png"),
    },
  {
      alt: "star_logo",
      src: require("../Contents/img/partner/star_logo.png"),
  },
  
  {
      alt: "godigit",
      src: require("../Contents/img/partner/godigit.png"),
    },
    {
        alt: "bajaj",
        src: require("../Contents/img/partner/bajaj.png"),
    },
    {
        alt: "tata_Logo",
        src: require("../Contents/img/partner/tata_Logo.png"),
    },
    {
        alt: "hdfc_logo",
        src: require("../Contents/img/partner/hdfc_logo.png"),
    },
    
    {
        alt: "iffco",
        src: require("../Contents/img/partner/iffco.jpg"),
    },
    {
        alt: "newindia",
        src: require("../Contents/img/partner/newindia.jpg"),
    },

    {
        alt: "united",
        src: require("../Contents/img/partner/united.png"),
    },

  
  
  
];
export default Partner;
