import React, { Component } from 'react';
const SecondAddress = (props) => {
    return (
        <div className="row">
            <div id="rechangeAdrs" style={{display: "block"}}>
                <div className="col-md-12 form-group">
                    <div className="inline ng-binding">
                       {props.type}  Registration Address
                        <span className="ng-binding">Mandatory because your communication state does not match your{props.type}  registration state</span>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <div ng-class="PerAddressLine1Div" className="fillFields fillFieldsFullName styleForm">
                                <input id="UserAddress_PerAddressLine1" onChange={props.handle} maxlength="15" name="PerAddressLine1"  placeholder="Address Line 1" required="required" type="text" value={props.PerAddressLine1} className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength form-control style-inpt style-inpt-home" aria-required="true" />
                                <span></span>
                                <span className="field-validation-valid" data-valmsg-for="PerAddressLine1" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div className="col-md-6 form-group">
                            <div ng-class="PerAddressLine2Div" className="fillFields fillFieldsFullName styleForm">
                                <input id="UserAddress_PerAddressLine2" onChange={props.handle} maxlength="30" name="PerAddressLine2"  placeholder="Address Line 2" required="required" type="text" value={props.PerAddressLine2} className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength form-control style-inpt style-inpt-home" aria-required="true" />
                                <span></span>
                                <span className="field-validation-valid" data-valmsg-for="PerAddressLine2" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <div className="fillFields styleForm">
                                <input onChange={props.handle} className="form-control style-inpt style-inpt-home " id="UserAddress_PerAddressLine3" maxlength="30" name="PerAddressLine3" placeholder="Address Line 3" type="text" value={props.PerAddressLine3} required/>
                                <span></span>
                                <span className="field-validation-valid" data-valmsg-for="PerAddressLine3" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div className="col-md-6 form-group PerStatesMainDiv" style={{pointerEvents:"none"}}>
                            <div ng-class="PerStatesDiv" className="fillFields fillFieldsFullName styleForm" name="PerStateName">
                                <select className=" form-control style-inpt style-inpt-home " name="PerStateID" onChange={props.handle} required>
                               
                                        <option value={props.CombindID}>{props.StateName}</option>
                                  
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <div ng-class="CityDiv" className="fillFields fillFieldsFullName styleForm">
                                <select ng-model="PerCityModel" onChange={props.handle} className="select2 form-control style-inpt style-inpt-home"  name="PerCityID" value={props.PerCityID} required>
                                <option value="">--Select City--</option>
                                {
                                    props.cityList.length>0 && props.cityList.map((row)=>{
                                         return  <option value={row.CombindID}>{row.CityName}</option>
                                     })
                                 }
                                </select>
                            </div>


                        </div>
                        <div className="col-md-6 form-group">
                            <div ng-class="PerPincodeDiv" className="fillFields fillFieldsFullName styleForm">
                                <input id="UserAddress_PerPinCode" onChange={props.handle} maxlength="6" name="PerPinCode" placeholder="Pincode" type="tel" value={props.PerPinCode} className="ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength form-control style-inpt style-inpt-home" required />
                                <span></span>
                                <span className="field-validation-valid"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SecondAddress;