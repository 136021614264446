import React from "react";
const Expertise = (props) => {
    return (
        <section class="section vertical-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <img src={require("../assets/img/Sansom/Mission-Vision-Values.jpg")} />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
