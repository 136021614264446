import axios from 'axios';
import React from 'react';
import Loader from '../Health/Loader';
import PaymenResultHealth from './PaymentResultHealth';
import PaymentSuccessOrFailDumy from './PaymentSuccessOrFailDumy';
class PaymentSuccessOrFail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Response: [],
            IsLoader: true,
            IsMotor: true,
            InsurerLogo: "",
            policyPdfUrl:null

        }
        this.checkPayment = this.checkPayment.bind(this);
       
    }
    componentDidMount() {
        let UrlModel = {
            URL: window.location.href,
        }
        axios.get(window.location.href)
            .then(res => console.log(res))
        axios.post("/bpi/WeatherForecast/UrlWriter", UrlModel);
        this.checkPayment();
    }
     removeTimestamp(uriStr) {
    // Decode the JSON encoded in the URI.
    var jsonObj = JSON.parse(decodeURIComponent(uriStr));
    // Remove the "t" property.
    delete jsonObj['t'];
    // Re-encode as a URI-encoded JSON string
    return encodeURIComponent(JSON.stringify(jsonObj));
}
    async checkPayment() {
        let portalurl = window.localStorage.getItem("portal");
        let rt = portalurl;
        if (rt.match("inshora")) {
            rt="https://inshoracover.com"
        }
        let url = window.location.search;
        let param = new URLSearchParams(url);
        let controllerName = window.location.pathname.match(/[^\/]+$/)[0];
        let QueryString="";
        let MiddlePath = "";
        if (window.location.href.toLocaleLowerCase().match("proposalmotor")) {
            MiddlePath = "/api/api/InsurerMotor/";

        } else {
            MiddlePath = "/healthapi/api/InsurerHealth/";
            this.setState({ IsMotor: false });
        }
        let amt;
        let InsurerLogo = "";
        let CompanyName = "";
        switch (controllerName) {
            case "ReliancePaymentResult":
                QueryString =window.location.href.toLocaleLowerCase().match("proposalmotor") ?  "ReliancePaymentResult?Output=" + param.get("Output") :
                      "ReliancePaymentResult?Output=" + param.get("Output") + "&EnquiryNo=" + param.get("EnquiryNo") + "&ApplicationId=" + param.get("ApplicationId");
                InsurerLogo = portalurl + "/insurer logo/realince.png";
                CompanyName="Reliance"
                break;
            case "DigitPaymentResult":
                QueryString = "DigitPaymentResult?EnquiryNo=" + param.get("EnquiryNo") + "&ApplicationID=" + param.get("ApplicationID") + "&errorMsg=" + param.get("errorMsg");
                InsurerLogo = portalurl + "/insurer logo/godigit.png";
                CompanyName = "Digit"; //confirm
                break;
            case "IffcoPaymentResult":
                QueryString = "IffcoPaymentResult?ITGIResponse=" + param.get("ITGIResponse");
                InsurerLogo = portalurl + "/insurer logo/iffco.png";
                CompanyName = "Iffco";
                console.log(param.get("ITGIResponse"));
                break;
            case "BajajMotorPaymentResult":
                QueryString = "BajajMotorPaymentResult?vg_payStatus=" + param.get("vg_payStatus") + "&p_policy_ref=" + param.get("p_policy_ref") + "&p_pay_status=" + param.get("p_pay_status")
                    + "&policyref=" + param.get("policyref") + "&requestId=" + param.get("requestId") + "&custName=" + param.get("custName");
                InsurerLogo = portalurl + "/insurer logo/bajaj.png";
                CompanyName = "Bajaj";
                break;
            case "PaymentResult":
                amt = param.get("Amt") == null ? "" : param.get("Amt");
                QueryString = "HdfcPaymentResult?PolicyNo=" + param.get("PolicyNo") + "&Msg=" + param.get("Msg") + "&ProposalNo=" + param.get("ProposalNo") + "&Amt=" + amt + "&request=&QueryString=&uri=" + (portalurl.match("riskovery") ? "https://www.riskoveryinsurance.com" : rt);
                InsurerLogo = portalurl + "/insurer logo/hdfc.png";
                CompanyName = "HDFC";
             
                break;
            case "SriramPaymentResult":
                QueryString = "SriramPaymentResult";
                let json = decodeURI(window.location.href).split("Res=")[1];
               
                this.setState({ Response: JSON.parse(json) });
                InsurerLogo = portalurl + "/insurer logo/shriram.png";
                CompanyName = "Sriram";
                this.setState({ IsLoader: false });
                break;
            case "TataPaymentResult":
                QueryString = "TataPaymentResult?EnquiryNo=" + param.get("EnquiryNo");
                InsurerLogo = portalurl + "/insurer logo/tata.png";
                CompanyName = "Tata";
                break;
            case "KotakPaymentResult":
                QueryString = "KotakPaymentResult?EnquiryNo=" + param.get("EnquiryNo");
                let JsonData = param.get("result");
                this.state.Response = JSON.parse(JsonData);
                this.state.IsLoader = false;
                this.setState({ Response: JSON.parse(JsonData), IsLoader: false });
                InsurerLogo = portalurl + "/insurer logo/kotak.png";
                CompanyName="Kotak"
                break;
            case "SompoPaymentResult":
                QueryString = "SompoPaymentResult?MSG=" + param.get("MSG");
                InsurerLogo = portalurl + "/insurer logo/universal.png";
                CompanyName = "Universal Sompo";
                break;
            case "LibertyPaymentResult":
                QueryString = "LibertyPaymentResult?EnquiryNo=" + param.get("EnquiryNo");
                InsurerLogo = portalurl + "/insurer logo/liberty.png";
                CompanyName="Loberty"
                break;
            case "FuturePaymentResult":
                
                QueryString = "FuturePaymentResult?WS_P_ID=" + param.get("WS_P_ID") + "&TID=" + param.get("TID") + "&PGID=" + param.get("PGID")
                    + "&Premium=" + param.get("Premium") + "&Response=" + param.get("Response") + "&EnquiryNumber=" + param.get("EnquiryNumber") + "&_url=" + rt;
                InsurerLogo = portalurl + "/insurer logo/future.png";
                CompanyName="Future"
                break;
            case "ICICILombardPaymentResult":
                QueryString = "ICICILombardPaymentResult?ApplicationId=" + param.get("ApplicationId");
                InsurerLogo = portalurl + "/insurer logo/Icici.png";
                CompanyName = "ICICI Lombard";
                break;
            case "BirlaPaymentResult":
                QueryString = "BirlaPaymentResult?EnquiryNo=" + param.get("EnquiryNo") + "&Res=" + param.get("Res");
                InsurerLogo = portalurl + "/insurer logo/Birla.jpg";
                CompanyName="Aditya Birla"
                break;
            case "StarHealthPaymentResult":
                QueryString = "StarHealthPaymentResult?token=" + param.get("purchaseToken");
                InsurerLogo = portalurl + "/insurer logo/star.jpg";
                CompanyName = "Star";
                break;
            case "BajajPaymentResult":
                QueryString = "BajajPaymentResult?EnquiryNo=" + param.get("EnquiryNo") + "&amt=" + param.get("amt")
                    + "&txn=" + param.get("txn") + "&referenceno=" + param.get("referenceno") + "&endorsementno=" + param.get("endorsementno");
                InsurerLogo = portalurl + "/insurer logo/bajaj.png";
                CompanyName = "Bajaj";
                break;
            case "GodigitPaymentResult":
                QueryString = "GodigitPaymentResult?policyNumber=" + param.get("policyNumber") +
                    "&transactionNumber=" + param.get("transactionNumber");
                InsurerLogo = portalurl + "/insurer logo/godigit.png";
                CompanyName="GoDigit"
                break;
            case "BhartiPaymentResult": QueryString = "BhartiPaymentResult?TotalPremium=" + param.get("TotalPremium") + "&Channel=" + param.get("ApplicationId").split("~")[2] +
                "&OrderNo=" + param.get("ApplicationId").split("~")[0] + "&QuoteNo=" + param.get("ApplicationId").split("~")[1] + "&url=" + rt;
                InsurerLogo = portalurl + "/insurer logo/bharti.png";
                CompanyName = "Bharti";
            case "ReligarePaymentResult": QueryString = "ReligarePaymentResult?EnquiryNo=" + param.get("EnquiryNo") + "&ProposalNo=" + param.get("ProposalNo");
                CompanyName = "Care";
                break;
            case "HdfcPaymentResult":
                amt = param.get("Amt") == null ? "" : param.get("Amt");
                if (MiddlePath.match("InsurerMotor")) {
                    QueryString = "HdfcPaymentResult?PolicyNo=" + param.get("PolicyNo") + "&Msg=" + param.get("Msg") + "&ProposalNo=" + param.get("ProposalNo") + "&Amt=" + amt + "&uri=" + (portalurl.match("riskovery") ? "https://www.riskoveryinsurance.com" : rt);
                } else if (MiddlePath.match("InsurerHealth")) {
                    let TransactionID = param.get("TransactionID") == null ? param.get("ProposalNo") : param.get("ProposalNo");
                    let PolicyNumber = param.get("PolicyNumber") == null ? param.get("PolicyNo") : param.get("PolicyNumber");
                    let PolicyStatus = param.get("PolicyStatus") === null ? param.get("Msg") : param.get("PolicyStatus");
                    let PaymentResponse = "";
                    QueryString = "/HdfcPaymentResult?TransactionID=" + TransactionID+ "&PolicyNumber="+ PolicyNumber+ "&PolicyStatus="+ PolicyStatus+ "&PaymentResponse="+ PaymentResponse
                }
                InsurerLogo = portalurl + "/insurer logo/hdfc.png";
                CompanyName = "HDFC";
                break;
            case "HdfcPaymentResultHealth":
                let JsonDataH = param.get("Res");
                this.state.Response = JSON.parse(JsonDataH);
                this.state.IsLoader = false;
                InsurerLogo = portalurl + "/insurer logo/hdfc.png";
                CompanyName = "HDFC";
                this.setState({ Response: JSON.parse(JsonDataH), IsLoader: false });
                break;
            case "HdfcPaymentResultMotor":
                let JsonDataM = param.get("Res");
                this.state.Response = JSON.parse(JsonDataM);
                this.state.IsLoader = false;
                InsurerLogo = portalurl + "/insurer logo/hdfc.png";
                CompanyName = "HDFC";
                this.setState({ Response: JSON.parse(JsonDataM), IsLoader: false });
                break;
            case "MotorResult": alert("some error occured")
                break;
        }
        QueryString = portalurl + MiddlePath + QueryString
        console.log(QueryString);
        let PaymentInfo = {
            Logo: InsurerLogo,
            CompanyName: CompanyName
        }
        let Model = {
            URL: QueryString
        }
        if (controllerName !="MotorResult" && controllerName != "KotakPaymentResult" && controllerName != "SriramPaymentResult" && controllerName != "HdfcPaymentResultHealth" && controllerName !="HdfcPaymentResultMotor") {

            await axios.post("/bpi/WeatherForecast/CallGet", Model)
                .then((data) => {
                    this.state.Response = data.data;
                    this.state.InsurerLogo = PaymentInfo;
                    this.setState({ Response: data.data, InsurerLogo: PaymentInfo });
                    if (window.location.href.toLocaleLowerCase().match("proposalmotor")) {
                        this.setState({ IsMotor: true });

                    } else {

                        this.setState({ IsMotor: false });
                    }
                    this.setState({ IsLoader: false, IsMotor: this.state.IsMotor });
                    //window.removeEventListener("beforeunload", (ev) => {console.log("listener removed") });
                    console.log(data)
                });
        } else {
            this.setState({ InsurerLogo: PaymentInfo });
        }

    }
    render() {

        return (
            <PaymentSuccessOrFailDumy IsLoader={this.state.IsLoader}
                PaymentStatus={ this.state.Response.Paymentflag}
                path={this.state.Response.path} transectionNo={this.state.Response.TransactionID}
                name={this.state.Response.ClientName} companyName={this.state.Response.Companyname}
                InsurerLogo={this.state.InsurerLogo} premium={this.state.Response.PaidAmount} />
            /*this.state.IsMotor ? <section className="thanksPageMain" className="QuotePage">
                <div className="container">
                    <div className="tablePrchsDetailsMain">
                        {this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.Paymentflag ?
                            <React.Fragment><div className="text-center"><img src={require("../Contents/img/right.jpg")} /></div>
                                <h3>Thanks you for your Purchase!</h3>
                                {*//*<h2>We checked and it seems that your policy document is taking a tad bit longer than it usally does. But worry not! We've got this covered and are doing every bit to ensure you get your policy in the next 24 hours.</h2>*//*}

                                <h2>Welcomes you!. You will be receiving softcopy of your policy on your registered email shortly.</h2>
                            </React.Fragment>
                            :
                            <React.Fragment><div className="text-center"><img src={require("../Contents/img/wrong.jpg")} />
                            </div><h3>We Regret that your Purchase is Failed..!</h3></React.Fragment>
                        }

                        

                        <div className="tablePrchsDetails">
                            <h4>Your Policy Details</h4>
                            <div className="cnt">
                                <p id="iscmpname">Company Name :<span id="compname">{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.Companyname}</span></p>


                                <p>Policy No <span id="policyno">{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.PolicyNo}</span></p>

                                <p>Enquiry No <span id="policyno">{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.EnquiryNo}</span></p>
                                <p>Proposal No <span>{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.ProposalNo}</span></p>

                                <p>Amount<span>{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.PayAmount}</span></p>

                                <p>Email ID <span>{this.state.IsLoader ? <Loader bgColor="blue" /> : this.state.Response.Email}</span></p>

                                <p>Download URL <span>{this.state.Response.Paymentflag !== undefined && this.state.Response.Paymentflag ? <a id="" href={ this.state.Response.path}>Download</a>:"Not Available"}</span></p>



                            </div>
                        </div>
                    </div>
                    <h4 style={{ textAlign: "center" }}>We look forward to a long relationship!.</h4>
                </div>
            </section> : <PaymenResultHealth IsLoader={this.state.IsLoader} PaymentStatus={this.state.Response.PolicyStatus}
                Companyname={this.state.Response.ClientURL} PolicyNo={this.state.Response.PolicyNumber} EnquiryNo={this.state.Response.EnquiryNo}
                ProposalNo={this.state.Response.ProposalNumber} PayAmount={this.state.Response.PayAmount} Email={this.state.Response.Email}
            />*/
        )
    }
}
export default PaymentSuccessOrFail