import React from "react";
import Partner from "./Partner";
import Zoom from "react-reveal";
const OurPartner = () => {
  const a = Partner.map((v,i) => {
    return (
      <React.Fragment>
        <img  key={{i}} src={v.src} className="partnerLogo" />
     
      </React.Fragment>
    );
  });
  return (
    <Zoom duration={1000}>
    <div className="container">
      <div className="partner">
        <h1 className="text-center m-4">Our Trusted Partners</h1>
        {a}
      </div></div>
    </Zoom>
  );
};
export default OurPartner;
