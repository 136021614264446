import React, { Component } from "react";
import {NavbarBrand, NavItem, NavLink,} from "reactstrap";
import { Link } from "react-router-dom";
import CustomerLogin from "./CustomerLogin";
import ManageAccount from "../components/ManageAccount";
export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            Logo: require("./assets/img/inshora/logo2.png"),
            Login: "Login",
        };
    }
    redirectTo(path){
        let url=window.location.href;
        if(url.match("www")){
            if(path.match("www.")){
                path=path.replace("www.","")
            }
        }
        window.location.assign(window.location.href=path)
    }
    render() {
        return (
            <header className="myNav">
                <nav className="mainHeader navbar fixed-top navbar-light">
                    <div className="container">
                        <div class="row topHeaderInsh d-flex justify-content-between">
                            <div class="dlab-topbar-left">
                                <ul className="SubHead_Risk">
                                    <li>
                                        <img src={require("./assets/img/inshora/phoneIcon.png")} /> <a href="tel:9811994580">9811994580/ 9811994581</a>
                                    </li>

                                </ul>
                            </div>
                            <div class="dlab-topbar-right">
                                <ul className="SubHead_Risk">
                                    <li><i class="ti-email m-r5"></i> <a href="mailto:icare@inshoracover.com">icare@inshoracover.com</a></li>
                                    <li>
                                    <CustomerLogin />
                                </li>
                                    </ul>
                            </div>
                        </div>
                        <div className="row MainMenu">
                            <div className="col-sm-3">
                                <NavbarBrand tag={Link} to="/">
                                    <img
                                        src={require("./assets/img/inshora/logo2.png")}
                                        id="logoasdf"
                                    />
                                </NavbarBrand>
                            </div>
                            <div className="col-sm-9">

                                <ul className="MN_Menu navMenu navbar-right">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" >
                                            Home
                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" >
                                            Insurance Solutions
                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/About" >
                                            About Us
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Claims" >
                                            Claims
                </NavLink>
                                    </NavItem>
                                   

                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-dark"
                                            to="/contact-us"
                                        >
                                            Contact
                </NavLink>
                                    </NavItem>
                                   
                                    <NavItem className="log-sign">
                                    <a onClick={()=>this.redirectTo("https://www.inshoracover.com/myaccount")} className="site-button">
                                        <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                        &nbsp; <ManageAccount />
                                    </a>
                                </NavItem>

                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}
