/* eslint-disable */
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import MaskedInput from 'react-text-mask';
import axios from "axios";
import ButtonClass, { ChildBtn } from "../../ButtonClass";
import ContinueToHealth from "../../components/ContinueToHealth"
export class InsureHealth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Gender: 1,
            MobileNo: null,
            PinCode: null,
            IsShowInsuredPerson: false,
            IsShowInsuredChildren: false,
            TblChild: [],
            IsYou: true,
            YouAge: null,
            IsSpouse: false,
            SpouseAge: null,
            IsFather: false,
            FatherAge: null,
            IsMother: false,
            MotherAge: null,
            APIUrl: null,
            CRMAPI: null,
            ClientID: null,
            UserID: 0,
            adult: 0,
            children: 0,
            fields: {},
            validity: '',
            errors: {},
            Req: [],
            you: false,
            spouse: false,
            father: false,
            mother: false,
            insured: {},
            nextComponent: false,
            HistoryRes: null,
            isValid: false,
            adultAgeList: [],
            childAgeList: [],
            redirectToQuote: false,
            BTNValue: "VIEW QUOTES",
            IsFound:false,
        };
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm")
        this.removeChild = this.removeChild.bind(this)
        this.LoadFirst = this.LoadFirst.bind(this)
        for (let i = 18; i <= 100; i++) {
            this.state.adultAgeList.push(i)

        }
        for (let i = 1; i <= 24; i++) {
            this.state.childAgeList.push(i)

        }
        this.handle = this.handle.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.LoadFirst();
        let healthReq = window.sessionStorage.getItem("HealthQuoteReq");
        let healthRes = window.sessionStorage.getItem("HealthQuoteRes");
        if (healthReq !== null || healthRes !== null) {
            window.sessionStorage.removeItem("HealthQuoteReq");
            window.sessionStorage.removeItem("HealthQuoteRes");
        }
    }
    componentDidMount() {
        let HealthReq = window.localStorage.getItem("HealthReq");
        if (HealthReq !== null) {
            HealthReq = JSON.parse(HealthReq);
            console.log(HealthReq);
            this.setState({
                HistoryRes: HealthReq,
               
                IsFound: true
            });
        }
    }
    LoadFirst() {
        let url = this.state.APIUrl == null ? window.location.hostname : this.state.APIUrl;
        if (url.match("www")) {
            url = url.replace("www.", "");
        }
        url = url.replace("https://", "");
        let rq = {
            URL: url,

        }

        let Model = {
            URL: this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq)
        };
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", Model)
                .then((Res) => {
                    this.setState({ ClientID: Res.data.id })

                });
        } catch (err) {
            console.log("Client Id" + err)
        }
        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            //Token = JSON.parse(Token);
            let parseToken = false;
            if (Token.charAt(0) === "{")
                parseToken = true;
            console.log(Token)
            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                PostString: JSON.stringify(data)
            }
            try {
                axios.post("/bpi/WeatherForecast/CallCheck", md)
                    .then((Res) => {
                        this.setState({ UserID: Res.data.userID });
                        this.state.UserID = Res.data.userID;

                    });
            } catch (err) {
                console.log("userID" + err)
            }
        }
    }





    AddEnquiry(adultCount, childCount) {
        this.state.BTNValue = "Wait..."
        let EnqReq = {
            MobileNo: this.state.fields["MobileNo"],
            EnquiryDate: "0001-01-01T00:00:00",
            EnquiryType: "HLT",
            Status: false,
            EnquiryNo: null,
            TotalEnquiry: 0,
            HealthEnquiry: {
                ID: 0,
                TblChild: this.state.TblChild,
                UserID: this.state.UserID,
                PinCode: this.state.fields["PinCode"],
                AdultCount: adultCount,
                ChildCount: childCount,
                ResultId: "0532cecd-12e9-41d4-8a80-b859981f76f2",
                PolicyType: adultCount == 1 ? "Individual" : "Family Floter",
                Gender: this.state.Gender,
                UserAge: this.state.YouAge,
                SpouseAge: this.state.IsSpouse ? this.state.SpouseAge : null,
                FatherAge: this.state.IsFather ? this.state.FatherAge : null,
                MotherAge: this.state.IsMother ? this.state.MotherAge : null,
                SonAge1:
                    childCount > 0
                        ? this.state.TblChild[0].ChildGender == "B"
                            ? this.state.TblChild[0].Age
                            : null
                        : null,
                SonAge2:
                    childCount > 1
                        ? this.state.TblChild[1].ChildGender == "B"
                            ? this.state.TblChild[1].Age
                            : null
                        : null,
                SonAge3:
                    childCount > 2
                        ? this.state.TblChild[2].ChildGender == "B"
                            ? this.state.TblChild[2].Age
                            : null
                        : null,
                SonAge4:
                    childCount > 3
                        ? this.state.TblChild[3].ChildGender == "B"
                            ? this.state.TblChild[3].Age
                            : null
                        : null,
                DoughterAge1:
                    childCount > 0
                        ? this.state.TblChild[0].ChildGender == "G"
                            ? this.state.TblChild[0].Age
                            : null
                        : null,
                DoughterAge2:
                    childCount > 1
                        ? this.state.TblChild[1].ChildGender == "G"
                            ? this.state.TblChild[1].Age
                            : null
                        : null,
                DoughterAge3:
                    childCount > 2
                        ? this.state.TblChild[2].ChildGender == "G"
                            ? this.state.TblChild[2].Age
                            : null
                        : null,
                DoughterAge4:
                    childCount > 3
                        ? this.state.TblChild[3].ChildGender == "G"
                            ? this.state.TblChild[3].Age
                            : null
                        : null,
            },
            UserID: this.state.UserID,
            LeadSource: "online",
            ClientID: this.state.ClientID,
        };

        console.log(EnqReq)
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/Enquiry/AddEnquiry",
            PostString: JSON.stringify(EnqReq)
        }
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", Model)
                .then((data) => {

                    EnqReq.EnquiryNo = data.data.split("~")[0].replace('"', "");
                    this.state.HistoryRes = EnqReq;
                    window.localStorage.setItem("HealthReq", JSON.stringify(this.state.HistoryRes))
                    this.setState({ HistoryRes: EnqReq, redirectToQuote: true });
                });
        } catch (err) { }
    }
    handleRedirect() {
        let isValid = true;
        let errors = {};

        if ((this.state.IsYou === true && (this.state.YouAge < 18))) {
            isValid = false
            errors["YouAgeError"] = "Select Your Age";
        }
        if ((this.state.IsSpouse === true && (this.state.SpouseAge < 18))) {
            isValid = false
            errors["SpouseAgeError"] = "Select Spouse Age";
        }
        if ((this.state.IsFather === true && (this.state.FatherAge < 18))) {
            isValid = false
            errors["FatherAgeError"] = "Select Father Age";
        }
        if ((this.state.IsMother === true && (this.state.MotherAge < 18))) {
            isValid = false
            errors["MotherAgeError"] = "Select Mother Age";
        }
        for (let i = 0; i < this.state.TblChild.length; i++) {
            if (this.state.TblChild[i].Age === null) {
                isValid = false;
                errors["childError"] = "Select Child Age";
                break
            }
        }


        this.setState({ isValid, errors })
        if (isValid) {
            let adultCount = 0;
            let childCount = this.state.TblChild.filter((row) => row.Age > 0).length;
            if (this.state.IsYou) adultCount += 1;
            if (this.state.IsSpouse) adultCount += 1;
            if (this.state.IsFather) adultCount += 1;
            if (this.state.IsMother) adultCount += 1;
            if (adultCount > 0 || childCount > 0)
                this.AddEnquiry(adultCount, childCount);
        }
    }
    handle = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        if (e.target.type == "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        } else if (e.target.type == "radio") {

            this.setState({ [e.target.name]: e.target.checked, [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        console.log([e.target.name] + "=" + e.target.value)
    };

    chkInput(e) {
        const validity = (e.target.validity.valid) ? e.target.value : this.state.validity;
        this.setState({ validity });
    }

    insure = (e) => {
        switch (e.target.name) {
            case "Member":
                let isValid = true;
                let errors = {};
                var pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (this.state.Gender === 0) {
                    isValid = false;
                    errors["Gender"] = "Please Select Gender First"
                }
                if (this.state.PinCode === null) {
                    isValid = false

                    errors["PinCode"] = "Please Enter Pin Code"
                }
                else if (this.state.PinCode !== null) {

                    if (this.state.PinCode.length < 6) {
                        isValid = false

                        errors["PinCode"] = "Please Enter Pin Code"
                    }
                }
                if (this.state.MobileNo === null) {
                    isValid = false;

                    errors["MobileNo"] = "please Enter Mobile Number"
                }
                else if (this.state.MobileNo !== null) {
                    if (!pattern.test(this.state.MobileNo)) {
                        isValid = false;

                        errors["MobileNo"] = "please Enter Mobile Number"
                    }
                    else if (this.state.MobileNo.length < 10) {
                        isValid = false;

                        errors["MobileNo"] = "please Enter Mobile Number"
                    }
                }
                if (isValid)
                    this.setState({ IsShowInsuredPerson: !this.state.IsShowInsuredPerson, });
                this.setState({ errors });
                break;
            case "Children":
                this.setState({
                    IsShowInsuredChildren: !this.state.IsShowInsuredChildren,
                });
                break;
            case "AddMoreChild":
                if (this.state.TblChild.length < 4) {
                    let child = {
                        index: this.state.TblChild.length,
                        ChildGender: "B",
                        Age: null,
                    }
                    this.state.TblChild.push(child);
                    this.setState({ TblChild: this.state.TblChild, IsShowInsuredChildren: true });

                } else return alert("Child can't Exceed")
                break;
        }
    };
    manageChildList(index, name, value) {
        let data = this.state.TblChild.filter((row) => row.index == index)[0];
        if (name == "Gender") {
            data.ChildGender = value;
        } else if (name == "Age") {
            data.Age = value;
        }
        this.state.TblChild[index] = data;
        this.setState({ TblChild: this.state.TblChild });
    }
    close = (e) => {
        this.setState({ IsShowInsuredPerson: false });
    };
    removeChild(e) {
        let id = e.target.id;
        let TblChild = this.state.TblChild;
        let index = TblChild.indexOf(id)
        if (id > -1) {
            TblChild.splice(index, 1);

        }
        this.setState({ TblChild })

    }
    render() {
        const HealthReq = this.state.HistoryRes;
        return (
            this.state.redirectToQuote ? <Redirect to={{
                pathname: "/HealthQuotes",
                state: { Req: this.state.HistoryRes }
            }} /> : this.state.IsFound ? <ContinueToHealth gender={HealthReq.HealthEnquiry.Gender} adult={HealthReq.HealthEnquiry.AdultCount} age={HealthReq.HealthEnquiry.UserAge} pincode={HealthReq.HealthEnquiry.PinCode} mobile={HealthReq.MobileNo} closeContinue={() => this.setState({ IsFound: false })} proceed={() => this.setState({ redirectToQuote:true})} /> :
                <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 ">
                        <div className="packagesMainHealth text-center">
                            <div className="productTabsMainHealth HealthPopUpTabCustom text-left">
                                <h3 className="heading text-center">Best Health Insurance plans</h3>
                                <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">

                                    <div className="PF0012">
                                        <div className="row align-items-end justify-content-center">

                                            <div>
                                                <label className="ForDesktop">
                                                    <select id="SlectGender" onChange={this.handle} name="Gender">
                                                        <option value="0">Select Gender</option>
                                                        <option id="rblMale" value="1">Male</option>
                                                        <option id="rblFemale" value="2">Female</option>
                                                    </select>
                                                </label>
                                                <span className="error">{this.state.errors["Gender"]}</span>
                                                <label className="ForMobileApp">
                                                    <div className="GenderApp Male">
                                                        <input type="radio" id="rblMale" value="1" className={this.state.Gender == "1" ? "active" : ""} name="Gender" onChange={this.handle} />
                                                        <span><img src={require("../../Contents/img/manLine.png")} /> <small>Male</small></span>
                                                    </div>
                                                    <div className="GenderApp FeMale">
                                                        <input type="radio" id="rblFemale" value="2" className={this.state.Gender == "2" ? "active" : ""} name="Gender" onChange={this.handle} />
                                                        <span><img src={require("../../Contents/img/womanLine.png")} /> <small>Female</small></span>
                                                    </div>
                                                </label>

                                            </div>

                                        </div>
                                        <div className="row">


                                            <div className="col-md-4 offset-md-2">
                                                <label>

                                                    <MaskedInput type="tel" onChange={this.handle} value={this.state.PinCode} name="PinCode" value={this.state.PinCode} placeholder="Enter PIN Code" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                                </label>
                                                <span className="error">{this.state.errors["PinCode"]}</span>
                                            </div>

                                            <div className="col-md-4">
                                                <label>
                                                    <MaskedInput type="tel" onChange={this.handle} id="HealthMobileNoh" name="MobileNo" value={this.state.MobileNo} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />



                                                </label>
                                                <span className="error">{this.state.errors["MobileNo"]}</span>
                                            </div>

                                            <div className="col-md-4 offset-md-4">
                                                <label className="text-center">
                                                    <button className="RoundArrowBTN" id="quotesBtn" onClick={this.insure} name="Member"><i class="fas fa-arrow-right"></i></button>
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {this.state.IsShowInsuredPerson ? (
                            <div className="HealthPopUpBg HealthPopUpBgCustom">
                                <div className="container">
                                    <div className="packagesMainPopUpH">
                                        <button type="button" onClick={this.close} className="close"><i class="fas fa-arrow-left"></i></button>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="HealthCustomLeftCNT">
                                                    <h3>Why buy Health Insurance ?</h3>
                                                    <p>All Covid-19 Treatments covered<br />
                                                    Instant Purchase, No Medicals Required<br />
                                                    Tax Benefits upto ₹ 75,000 under Sec. 80D<br />
                                                    24x7 Claims Assistance *</p>
                                                    <img src={require("../../Contents/img/HealthCommen.png")} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="productTabsMainHealth">
                                                    <div className="text-center">
                                                        <h5 className="memberInsure">Select the family members to insure</h5>
                                                    </div>
                                                    <div className="ProductFormIn ProductFormInQuote">
                                                            <div className="HealthCustomAgeBox">
                                                                {this.state.IsYou && < label >
                                                                    <div className="FormRow">
                                                                        <div className="col-50">
                                                                            <label>
                                                                                <span><img src={require("../../Contents/img/Male.png")} />
                                                                                    <input type="CheckBox" name="IsYou" checked={this.state.IsYou} onChange={this.handle} />
                                                                You
                                                            </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-50">
                                                                            {this.state.IsYou ?
                                                                                <React.Fragment>
                                                                                    <select className="form-control input-lg"
                                                                                        onChange={this.handle} name="YouAge"
                                                                                        value={this.state.YouAge}>
                                                                                        <option value="0">Select Age</option>
                                                                                        {this.state.adultAgeList.map((v) => {
                                                                                            return <option key={v} value={v}>{v} Year</option>
                                                                                        })}
                                                                                    </select>
                                                                                    <small>{this.state.errors["YouAgeError"]}</small>
                                                                                </React.Fragment>
                                                                                : null}

                                                                        </div>
                                                                    </div>
                                                                </label>}
                                                            {this.state.IsSpouse &&
                                                                < label >
                                                                    <div className="FormRow">
                                                                        <div className="col-50">
                                                                            <label>
                                                                                <span><img src={require("../../Contents/img/Female.png")} />
                                                                                    <input type="CheckBox" name="IsSpouse" checked={this.state.IsSpouse} onChange={this.handle} />
                                                                Spouse
                                                            </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-50">
                                                                            {this.state.IsSpouse ?
                                                                                <React.Fragment>
                                                                                    <select className="form-control input-lg"
                                                                                        onChange={this.handle} name="SpouseAge"
                                                                                        value={this.state.SpouseAge}
                                                                                    >
                                                                                        <option value="0">Select Age</option>
                                                                                        {this.state.adultAgeList.map((v) => {
                                                                                            return <option key={v} value={v}>{v} Year</option>


                                                                                        })}
                                                                                    </select>
                                                                                    <small>{this.state.errors["SpouseAgeError"]}</small>
                                                                                </React.Fragment>
                                                                                : null}

                                                                        </div>
                                                                    </div>
                                                                </label>}
                                                            {this.state.IsFather && <label>
                                                                <div className="FormRow">
                                                                    <div className="col-50">
                                                                        <label>
                                                                            <span><img src={require("../../Contents/img/father.png")} />
                                                                                <input type="CheckBox" name="IsFather" checked={this.state.IsFather} onChange={this.handle} />
                                                                Father
                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-50">
                                                                        {this.state.IsFather ?
                                                                            <React.Fragment>
                                                                                <select className="form-control input-lg"
                                                                                    onChange={this.handle} name="FatherAge"
                                                                                    value={this.state.FatherAge}
                                                                                >
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return v >= (parseInt(this.state.YouAge) + 18) && <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["FatherAgeError"]}</small>
                                                                            </React.Fragment>
                                                                            : null}

                                                                    </div>
                                                                </div>
                                                            </label>}
                                                            {this.state.IsMother && <label>
                                                                <div className="FormRow">
                                                                    <div className="col-50">
                                                                        <label>
                                                                            <span><img src={require("../../Contents/img/mother.png")} />
                                                                                <input type="CheckBox" name="IsMother" checked={this.state.IsMother} onChange={this.handle} />
                                                                Mother
                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-50">
                                                                        {this.state.IsMother ?
                                                                            <React.Fragment>
                                                                                <select className="form-control input-lg"
                                                                                    onChange={this.handle} name="MotherAge"
                                                                                    value={this.state.MotherAge}
                                                                                >
                                                                                    <option value="0">Select Age</option>
                                                                                    {this.state.adultAgeList.map((v) => {
                                                                                        return v >= ( parseInt(this.state.YouAge) + 14) && <option key={v} value={v}>{v} Year</option>
                                                                                    })}
                                                                                </select>
                                                                                <small>{this.state.errors["MotherAgeError"]}</small>
                                                                            </React.Fragment>
                                                                            : null}

                                                                    </div>
                                                                </div>
                                                            </label>}




                                                            {this.state.IsShowInsuredChildren ? (
                                                                <div>
                                                                    {this.state.TblChild.map((row, ind) => (
                                                                        <label>
                                                                            <div className="FormRow FormRowHealth">
                                                                                <div className="col-40 mr-2">
                                                                                    <label>
                                                                                        <span><img src={require("../../Contents/img/childrens.png")} /></span>

                                                                                        <select
                                                                                            name="Gender"
                                                                                            className="packageForm"
                                                                                            onChange={(e) =>
                                                                                                this.manageChildList(
                                                                                                    ind,
                                                                                                    e.target.name,
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option value="B">Son</option>
                                                                                            <option value="G">Doughter</option>
                                                                                        </select>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-40">
                                                                                    <select className="packageForm"
                                                                                        name="Age"
                                                                                        value={this.state.fields["Age"]}
                                                                                        onChange={(e) =>
                                                                                            this.manageChildList(
                                                                                                ind,
                                                                                                e.target.name,
                                                                                                e.target.value
                                                                                            )
                                                                                        }>
                                                                                        <option value="0">Select Age</option>
                                                                                        <option value="0.5">{'<'}1 Year</option>
                                                                                        {this.state.childAgeList.map((v) => {
                                                                                            return <option key={v} value={v}>{v} Year</option>
                                                                                        })}
                                                                                    </select>
                                                                                    <small>{this.state.errors["childError"]}</small>
                                                                                </div>
                                                                                <div className="ageWithdel">
                                                                                    <i className="fa fa-times" onClick={this.removeChild} id={ind} aria-hidden="true"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    ))}


                                                                </div>
                                                            ) : null}
                                                        </div>
                                                            <div className="FormRow">
                                                            {!this.state.IsYou && < button className="AddBtnHlt" onClick={() => this.setState({ IsYou: true })} name="AddYou"><i class="fas fa-plus"></i> Add Yourself</button>}
                                                            {!this.state.IsSpouse && < button className="AddBtnHlt" onClick={() => this.setState({ IsSpouse: true })} name="AddWife"><i class="fas fa-plus"></i> Add Wife</button>}
                                                            {!this.state.IsFather && <button className="AddBtnHlt" onClick={() => this.setState({ IsFather: true })} name="AddFather"><i class="fas fa-plus"></i> Add Father</button>}
                                                            {!this.state.IsMother && <button className="AddBtnHlt" onClick={() => this.setState({ IsMother: true })} name="AddMother"><i class="fas fa-plus"></i> Add Mother</button>}
                                                            < button className="AddBtnHlt" onClick={this.insure} name="AddMoreChild"><i class="fas fa-plus"></i> Add Children</button>
                                                        </div>
                                                            <div className="col-50 text-right">
                                                                <button className={ChildBtn()} id="quotesBtn" onClick={this.handleRedirect}>{this.state.BTNValue}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
        );
    }
}
