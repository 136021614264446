import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section>
                <div class="wrapper1">
                    <div class="wrapper__center">
                        <div class="elements_wrapper1">
                            <div class="container">
                                <div class="row">
                                    <div class="grid_12">
                                        <h6><span class="__orange">hello there!</span></h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="grid_8 preffix_2">
                                        <span class="h6_span">liquam rhoncus, libero non congue ultri nterdum velit quam sed nisi.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="grid_12">
                                        <p>Mes cuml dia sed in lacus ut eniascet etor ingerto aliiqt es sitet amet eismod ictorut dolore ligulate ameti dapi licdu nt mtsent lusto dolor ltisim comme. Mes cuml dia sed inertio lacus ut eniascet ingerto aliiqt sit eism odictor. Ut ligulate ameti dapibus ticdu mtsent dolorltiis sim. Mes cuml dia sed in lacus ut enia etor. ingerto dolore ipsum commete mtsent aliiqt. Es sitet amet eismod ictor ut ligulate ameti dapibusti mtsent.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="SI_Product">
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img1.png")} alt="" />
                                        <h3>moto<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img2.png")} alt="" />
                                        <h3>life<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img3.png")} alt="" />
                                        <h3>condo<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img4.png")} alt="" />
                                        <h3>travel<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight">
                                        <div class="box_inner">
                                            <img src={require("../assets/img/StarInsured/page1_img5.png")} alt="" />
                                            <h3>home<br />insurance</h3>
                                        </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img6.png")} alt="" />
                                        <h3>business<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img7.png")} alt="" />
                                        <h3>auto<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                            <div class="grid_3">
                                <a href="#">
                                    <div class="post1 maxheight"><div class="box_inner">
                                        <img src={require("../assets/img/StarInsured/page1_img8.png")} alt="" />
                                        <h3>health<br />insurance</h3>
                                    </div></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>
        </React.Fragment>

    );
};
export default Services;
