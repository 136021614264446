import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";

import "./assets/css/Inshora.css";
import "./assets/css/templete.css";
import "./assets/css/themify/themify-icons.css";
import "./assets/css/font/flaticon.css";
//import "./assets/css/templete.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import InshoraMiddleSection from "./InshoraMiddleSection";
import { NavMenu } from "./NavMenu";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import Career from "./Career";
import HealthInsurance from "./FooterPages/HealthInsurance";
import CarInsurance from "./FooterPages/CarInsurance";
import BikeInsurance from "./FooterPages/BikeInsurance";
import TravelInsurance from "./FooterPages/TravelInsurance";
import LifeInsurance from "./FooterPages/LifeInsurance";
import HomeInsurance from "./FooterPages/HomeInsurance";
import TermsAndCondition from "./FooterPages/TermsAndCondition";
import axios from "axios";
const findPath=()=>{
    let url=window.location.href.toLowerCase();
    let path="HRIKLS";
    if(url.match("proposalmotor") || url.match("proposalhealth"))
    path=window.location.pathname
    return path;
  }
//   const callReload=()=>{
//       let url=window.location.href;
//       let key= window.sessionStorage.getItem("RedirectToken");
//       console.log(window.sessionStorage.getItem("RedirectToken"))
//       if(key==null && url.match("/healthapi/api/")){
//         window.sessionStorage.setItem("RedirectToken","asdjkfh");
         
         
//       }
//   }
const Inshora=()=>  {
 let apipath=findPath();
     //callReload();
   return (
        <React.Fragment>
            <div className="Inshorabody">
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />



                </Layout>

                <Switch>

                    <Route exact path="/" component={InshoraMiddleSection} />
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route exact path="/GetQuoteLife" component={LifeQuote} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    
                    

                    <Route path="/About" component={About} />
                    <Route path="/Career" component={Career} />
                    <Route path="/HealthInsurance" component={HealthInsurance} />
                    <Route exact path="/CarInsurance" component={CarInsurance} />
                    <Route exact path="/BikeInsurance" component={BikeInsurance} />
                    <Route exact path="/TravelInsurance" component={TravelInsurance} />
                    <Route exact path="/LifeInsurance" component={LifeInsurance} />
                    <Route exact path="/HomeInsurance" component={HomeInsurance} />
                    <Route exact path="/TermsAndCondition" component={TermsAndCondition} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apipath} component={PaymentSuccessOrFail} />
                    
                </Switch>

                <Footer />
                <SideNav />
                <SocialToggle mobile="9811994581" email="icare@inshoracover.com" whatsapp="9811994581" />
            </div>
        </React.Fragment>
    )
}
export default Inshora;
