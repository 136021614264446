/* eslint-disable */ 
import React from "react";
import TabData from "./TabData";
import "./tab.css";
import Product from '../Product';
import "../assets/css/hero.css"
const data = TabData;

function TabTitle(props) {
  return props.isActive === props.dataTab ? (
    
      <div
        onClick={props.onClick}
        className="productHero productHero--active"
        data-tab={props.dataTab}
       id="myDiv"
        
      >
        {props.title}
      </div>
  
  ) : (
    <div
      onClick={props.onClick}
      className="productHero"
      data-tab={props.dataTab}
    
    >
     {props.title}
    </div>
  );
}

function TabContent(props) {
  return (
    <div style={props.style} data-tab={props.dataTab}>
    
      {props.content}
    </div>
  );
}

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isActive: "1", data: data };
    this.changeActive = this.changeActive.bind(this);
   
  }
componentDidMount(){
  if(this.state.isActive==="1"){
    let f=document.getElementById("1");
    f.src=Product[0].onMouseOver;
  }
}
  changeActive(ev) {
    this.setState({ isActive: ev.target.getAttribute("data-tab") });
   let id1=document.getElementById("1");
   let id2=document.getElementById("2");
   let id3=document.getElementById("3");
   let id4=document.getElementById("4");
   let id5=document.getElementById("5");
  switch (ev.target.getAttribute("data-tab")) {
    case "1":
      id1.src=Product[0].onMouseOver;
      id2.src=Product[1].src;
      id3.src=Product[2].src;
      id4.src=Product[3].src;
       id5.src=Product[4].src;
      break;
      case "2":
        id1.src=Product[0].src;
        id2.src=Product[1].onMouseOver;
        id3.src=Product[2].src;
        id4.src=Product[3].src;
         id5.src=Product[4].src;
        break;
        case "3":
          id1.src=Product[0].src;
          id2.src=Product[1].src;
          id3.src=Product[2].onMouseOver;
          id4.src=Product[3].src;
           id5.src=Product[4].src;
          break;
          case "4":
            id1.src=Product[0].src;
            id2.src=Product[1].src;
            id3.src=Product[2].src;
            id4.src=Product[3].onMouseOver;
             id5.src=Product[4].src;
            break;
            case "5":
              id1.src=Product[0].src;
              id2.src=Product[1].src;
              id3.src=Product[2].src;
              id4.src=Product[3].src;
               id5.src=Product[4].onMouseOver;
              break;
  
    default:  let cid=document.getElementById("1")
    cid.src=data[1].activeSrc
      break;
  }
   
  
   
  }
  
  render() {
    var listTitle = this.state.data.map((item) => (
      <TabTitle
        isActive={this.state.isActive}
        onClick={this.changeActive}
        dataTab={item.id}
        title={item.tabTitle}
        key={item.id}
        to={item.to}
        id={item.id}
       

      />
    ));
    var listContent = this.state.data.map((item) =>
      this.state.isActive === item.id ? (
        <TabContent dataTab={item.id} content={item.tabContent} />
      ) : (
        <TabContent
          style={{ display: "none" }}
          dataTab={item.id}
          content={item.tabContent}
        />
      )
    );
    return (
     <React.Fragment>
      <div className="packagesMainH">
      <div className="productTabsMainHero text-center">
                    <h3 className="HeadingHero">Buy Insurance, the simple way.</h3>
            {listTitle}
          </div>
       
        
        </div>
        <div className="tab-contentHero d-flex justify-content-center">
        <div className="tabInner">{listContent}</div></div>
        </React.Fragment>
    );
  }
}
export default Tab;
