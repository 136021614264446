/* eslint-disable */
import React from "react";
const EssenceOfHero = (props) => {
    return (
        <section class="text-center essence">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-12">
                        <h2 class="text-left headingCont">Essence of HERO</h2>
                        <div class="indi-essence">
                            <div class="one">
                                <h3>Empathy</h3>
                                <p>We had a humble beginning, but faith and determination are two crucial things that kept us going. Therefore, as a customer, when you put your faith in us, we consider it our responsibility to offer you the best and be there for you at all times.</p>
                                <img src={require("../assets/img/essence-1.svg")} alt="image" /></div>
                        </div>
                        <div class="indi-essence">
                            <div class="one">
                                <h3>Innovation</h3>
                                <p>We believe in seizing every opportunity, as it pushes us harder to succeed. At HERO, courage and imagination are the driving force for the innovative vision and creation of products that satisfy customer needs.</p>
                                <img src={require("../assets/img/essence-2.svg")} alt="image" /></div>
                        </div>
                        <div class="indi-essence">
                            <div class="one">
                                <h3>Adaptability</h3>
                                <p>We understand that challenges make us stronger and thus, we've always accepted them with open arms. This has only helped us improve our performance and deliver quality service, time and again. Due to our ability to adapt to consumers' requirements, we've delivered efficiently across generations. Now, as we move into the era of digitization, we are here to bring the best insurance products for you at your fingertips.</p>
                                <img src={require("../assets/img/essence-3.svg")} alt="image" /></div>
                        </div>
                        <div class="indi-essence">
                            <div class="one">
                                <h3>Customer Centricity</h3>
                                <p>HERO is a people's brand that aims at making the best services accessible to you. Our success has always laid in the reliability of our brand and commitment to serve customers of various socio-economic segments.</p>
                                <img src={require("../assets/img/essence-4.svg")} alt="image" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default EssenceOfHero;
