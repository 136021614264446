import React from "react";
import ButtonClass from "../ButtonClass";
const ContinueToHealth = (props) => {
    return (
        <div>
            <div className="ContinueTOquote">
                <div className="row">
                    <div className="col-md-2">
                        <div className="ContinueTOquoteBox">
                            <p>Your Gender</p>
                            <span>
                                <strong>{props.gender === "1" ? "Male" : "Female"}</strong>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ContinueTOquoteBox">
                            <p>Member </p>
                            <span>
                                adults: <strong>{props.adult}</strong>
                                {" "} | {" "}

                                age: <strong>{props.age}</strong>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="ContinueTOquoteBox">
                            <p>Pincode</p>
                            <span>
                                <strong>{props.pincode}</strong>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="ContinueTOquoteBox">
                            <p>Mobile No.</p>
                            <span>
                                <strong>{props.mobile}</strong>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <button
                            className={ButtonClass() + " ContinueBTN"}
                            id="quotesBtn"
                            onClick={props.proceed}
                        >
                            Continue
            </button>
                    </div>
                </div>
                <span className="mt-4 d-inline-block NewSearch" onClick={props.closeContinue}>
                    Start a new search <i class="fas fa-long-arrow-alt-right"></i>{" "}
                </span>
            </div>
        </div>
    );
};
export default ContinueToHealth;
