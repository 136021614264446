const FeatureBgClass = () => {
    let url = window.location.href;
    let classes;
    if (url.match("hero"))
        return classes = "ExtraFeatureBGHero";

    else if (url.match("insureasy"))
        return classes = "ExtraFeatureBGInsEasy";

    else if (url.match("riskoveryinsurance"))
        return classes = "ExtraFeatureBGRisKov";

    else if (url.match("insurekare"))
        return classes = "ExtraFeatureBGInsKare";

    else if (url.match("myinsurancekart"))
        return classes = "ExtraFeatureBGLifeMart"

    else if (url.match("onlinebimakaro"))
        return classes = "ExtraFeatureBGSBM"

    else if (url.match("sansominsure"))
        return classes = "ExtraFeatureBGSansom"

    else if (url.match("riskfreelife"))
        return classes = "ExtraFeatureBGRFL"
   
    else if (url.match("policy2cover")) 
        return classes = "ExtraFeatureP2C"

    else if (url.match("bimaplanner")) 
        return classes = "ExtraFeatureBPlaner"

    else if (url.match("sarsabima")) 
        return classes = "ExtraFeatureSarsa"

    else if (url.match("bookmybima"))
        return classes = "ExtraFeatureBMB"

    else if (url.match("advancelifeinsurance")) 
        return classes = "ExtraFeatureAdvance"

    else if (url.match("enivesh"))
        return classes = "ExtraFeatureEnivesh"

    else if (url.match("bimastreet"))
        return classes = "ExtraFeatureBstreet"

    else if (url.match("policyleader"))
        return classes = "ExtraFeaturePleader"

    else if (url.match("star"))
        return classes = "ExtraFeatureStar"
    else if (url.match("pratiraksha4ever"))
    return classes = "ExtraFeaturePratiraksha"
    else if(url.match("smc"))
    return classes = "ExtraFeatureSMC"
    else if (url.match("inshoracover"))
        return classes = "ExtraFeatureInshora"
    else if (url.match("religare"))
        return classes = "ExtraFeatureReligare"


    else return classes = "ExtraFeatureInsuMandi"
}
export default FeatureBgClass
