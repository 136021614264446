import React from "react";
const WhyChooseUs = (props) => {
    return (
        <section class="process-area pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <span>Transparent process</span>
                    <h2>Why people choose us</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="process-item">
                            <div class="icon">
                                <i class="flaticon-guarantee"></i>
                            </div>
                            <h3>Guarantee</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="process-item">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3>Speed</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="process-item">
                            <div class="icon">
                                <i class="flaticon-reliability"></i>
                            </div>
                            <h3>Reliability</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="process-item">
                            <div class="icon">
                                <i class="flaticon-user-experience"></i>
                            </div>
                            <h3>Experience</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default WhyChooseUs;
