/* eslint-disable */ 
import React from "react";
import { Carousel } from "3d-react-carousal";
import Slides from "./Slides";
import TestimonialData from "./TestimonialData";
const Testimonial = (props) => {
  let slides = TestimonialData.map((data, key) => {
    return <Slides name={data.name} quotes={data.quotes} />;
  });

  return (
    <React.Fragment>
          <div className="Testimonial">
              <h3>Customer Reviews</h3>
              <p>what our clients say...</p>
              <Carousel slides={slides} autoplay={true} interval={2000} />
      </div>
    </React.Fragment>
  );
};
export default Testimonial;
