import React from "react";
import { Carousel } from "react-bootstrap"
const Banner = (props) => {
    return (
        <div className="slideshow-block">
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                        src={require("./assets/img/Pratiraksha/Life-Insurance.jpg")}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                        src={require("./assets/img/Pratiraksha/Motor-Insurance.jpg")}
                    alt="Second slide"
                />    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require("./assets/img/Pratiraksha/Health-Insurance.jpg")}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require("./assets/img/Pratiraksha/Home-Insurance.jpg")}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={require("./assets/img/Pratiraksha/Travel-Insurance.jpg")}
                        alt="Second slide"
                    />
                </Carousel.Item>

            </Carousel>
        </div>

    );
};
export default Banner;
