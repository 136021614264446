import React from "react";

const ProductTab = (props) => {
    return (
        <React.Fragment>
            <span>{props.name}</span>
        </React.Fragment>
    );
};
export default ProductTab;
