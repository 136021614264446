import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
  return (
    <React.Fragment>
          <div className="container info-block">
              <div className="row">
                  <div className="col-lg-4">
                      <div className="box">
                          <center>
                              <img src={require("../assets/images/icons_Earn-Commission.png")} alt="Earn your Commission" />
                          </center>
                          <h3>Earn Commission</h3>
                          <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>
                      </div>
                  </div>
                  <div className="col-lg-4">
                      <div className="box">
                          <center>
                              <img src={require("../assets/images/icons_irdai-logo.png")} alt="IRDAI" />
                          </center>
                          <h3>IRDA Approved</h3>
                          <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>
                      </div>
                  </div>
                  <div className="col-lg-4">
                      <div className="box">
                          <center>
                              <img src={require("../assets/images/icons_no-paperwork.png")} alt="No Paper Work" />
                          </center>
                          <h3>No Paper Work</h3>
                          <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>
                      </div>
                  </div>
              </div>
          </div>


          <Zoom>
              <Dedicated />
          </Zoom>
          <Zoom>
              <Expertise />
          </Zoom>

    </React.Fragment>
    );
};
export default Services;
