/* eslint-disable */ 
import React from 'react';
const Loader=(props)=>{
    return(
        <div className="" style={{display:"inline-flex",marginTop:"3px", borderRadius:"4px"}}>
        <span className="dot-loader" style={{backgroundColor:props.bgColor}}></span>
        <span className="dot-loader dot-loader--2" style={{backgroundColor:props.bgColor}}></span>
        <span className="dot-loader dot-loader--3" style={{backgroundColor:props.bgColor}}></span>
      </div>
    )
}
export default Loader