import React from 'react'
import "react-reveal";
import AccordianCar from './FAQAccordian/AccordianCar';
import Scroller from './Scroller';
const CarInsurance = (props) => {
    Scroller();
    return (
        <div className="ProduactPageBima">
            <section class="landing-hero" id="firstSection">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h2 class="text-secondary">
                                Get the right insurance policy with FREE claim assistance.
                    </h2>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <p>Accident, Theft, AOG Perils and Third-Party Cover - Trust Bimastreet for all insurance
                        policies.</p>
                            <a class="btn btn-primary mt-4">Get Quotes <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="hero-img">
                    <img src={require("../assets/img/BimaStreet/car-hero.svg")} class="img-fluid" />
                </div>
            </section>


            <section class="section pb-0 whatisCarinsurance"  id="whatisCarinsurance">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h4 class="text-secondary">What is car insurance?</h4>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>According to a recent report released by the National Crime Records Bureau (NCRB),
                                    almost 2.5 lakh vehicles, including cars, are stolen in India every year while only
                                around 25% have been recovered.</p>

                                    <p>With the accidents causing damages to your vehicle on the rise, protecting yourself
                                    from financial burden is essential. Car insurance helps vehicle owners recover the
                                damages in accidents and use the car without worries. But what is car insurance?</p>

                                    <p>Since August 2000, the insurance industry in India is regulated and monitored by IRDA
                                    to protect the policyholder's interests. Broadly, the insurance industry comprises
                                    of life insurance and non-life insurance. Car insurance falls under the non-life
                                insurance category.</p>

                                    <p>Car insurance is an agreement between the car owner and the insurance company, where
                                    the insurance company is liable to pay for damages or loss to the insured against
                                    the premium collected over some time. Many factors affect the policy and insurance
                                    premium, such as the make and model of the car, claims history, RTO registration,
                                among others.</p>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </section>

            <section class="mandatory-section mandatoryinIndia" id="mandatoryinIndia">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Is car insurance mandatory in India?</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-9">
                                    It is mandatory to have car insurance in India, whether it is a commercial vehicle or a
                                    personal vehicle. It is essential to note that driving a motor vehicle such as a car
                            without insurance in public places is a punishable offense per the <strong>Motor
                                Vehicles Act, 1988.</strong> Under the law, an individual found driving a vehicle
                            without valid insurance can be fined Rs 2,000 for their first offense and Rs 4,000 for a
                            future offense. This act mandates the need for third-party motor insurance where the
                            vehicle owner is liable for any damage or injury to third-party life or property caused
                            by the use of the insured's vehicle. Although mandated by the government, the retention
                            rate of customers who continue to insure their car after the initial term needs to be
                            improved. At the end of 2020, Statista reported that the Indian motor insurance industry
                            had 86% retention ratio.
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="benfit-section benefits" id="benefits">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-3 align-self-center">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Benefits of car insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <p>Getting car insurance online made easy with Bimastreet</p>
                            <a class="btn btn-primary">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4 g-4 g-md-3 cont">
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Taking Third Party Liability (TPL) car insurance coverage meets your
                        liability.</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Car insurance covers third-party death and bodily injury, damages to the
                        vehicle, death of the driver or passenger, and third-party property damage.</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Buying car insurance online has proven to result in lower premiums because of
                        wider choice and healthy competition, thus increasing affordability.</p>
                        </div>
                        <div class="col-md-3">
                            <p>Car insurance protects both the vehicle and the insured, thus paying for
                        damages to the car and injuries to third parties, and damages to third-party properties.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue types" id="types">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Types of car insurance policy</h5>
                                    <p>Various types of insurance coverages are offered in India. The two main types of
                                    insurance that should be considered while purchasing a new car or renewing expired
                                insurance.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-4 g-md-3">
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Third-party insurance or Act Only Policy</h6>
                                Covers bodily injuries/death and damages or losses caused to the third-party
                                properties by the insured car.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Comprehensive insurance Or Package Policy</h6>
                                Offers enhanced insurance that covers damage to the insured vehicle (Car) caused due
                                to accidents, fire, theft, vandalism, natural disaster, civil disturbance, etc.,
                                along with third-party liability.
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="row mt-5">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h6 class="text-secondary mb-3 font-semibold">Addons</h6>
                                    <p>There are various beneficial add-ons to a car insurance policy that can be availed
                                    based on the necessity of the policyholder. While these are not mandatory, they
                                    offer wider coverage and more benefits to the insured. Some of the add-on insurance
                                coverages are:</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row h-100 g-4 g-md-3">
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">No claim bonus retention cover</h6>
                                Offered by the insurer is a discount that the policyholder can continue in the
                                renewal despite making claims during the policy term.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Zero depreciation cover</h6>
                                Enhances the basic car insurance policy as it allows the policyholder to claim the
                                full price of replaced parts/repair charges without the insurer deducting
                                depreciation.
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section class="section-2 keyTerms" id="keyTerms">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Key terms used in car insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row g-3 g-md-5">
                                <div class="col-md-6">
                                    <p><strong>Insured Declared Value (IDV)</strong> is the maximum sum payable by the
                                insurer at the time of theft or total damage of the insured vehicle. This value is
                                arrived at after applying Depreciation depending on the age of the vehicle on the
                                new vehicle price as on the date of taking out the policy</p>

                                    <p>Based on the affordability and risk of the policyholder, a portion of the claim
                                called <strong>voluntary deductible</strong> is to be borne by the insured during a
                                claim, and a suitable discount will be given if the insured opts for this.</p>

                                    <p><strong>Legal liability</strong> is the liability payable to a third party for death
                                or bodily injuries, damages, or loss to third-party property in case of an accident
                                as per the Hon'ble Court award.</p>
                                </div>
                                <div class="col-md-6">
                                    <p><strong>A compulsory deductible</strong> is a mandatory amount calculated based on
                                the vehicle's cubic capacity to be borne by the policyholder during a claim.</p>

                                    <p><strong>Break-in insurance</strong> happens when the policy moves to lapsed status
                                due to failure of renewal upon which insurance will be granted only upon inspection
                                of the vehicle either by self or by the insurance company.</p>

                                    <p><strong>Valid Driving License</strong> – License should be valid as on the accident –
                                license should be appropriate to the type of vehicle being driven.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue buycar" id="buycar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">How to buy car insurance online?</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-8">
                                    <p>There is a technology-driven shift in the world of insurance purchase and
                                    compensation. As the online insurance process is instantaneous, there is a steady
                                    rise in insurance issued online. There is also more transparency as the website or
                                    the platform will contain all the necessary information needed to make an informed
                                    choice. Now, buy car insurance online with Bimastreet. From new car insurance, third
                                    party car insurance, buy the best car insurance online with Bimastreet for a new or
                                used cars.</p>
                                </div>
                            </div>

                            <p><strong>To buy online, simply keep the following documents ready</strong></p>
                            <div class="row g-2">
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In case of new car insurance </strong></p>
                                        <p>Covers bodily injuries/death and damages or losses caused to the third-party
                                    properties by the insured car.</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In case of car insurance renewal online</strong>
                                        </p>
                                        <p>A copy of the Registration Certificate (RC) to enter the right engine number,
                                    chassis number, car model, year of purchase,</p>

                                        <p>Details of previous insurance policy, including policy number, date of policy
                                    purchase</p>

                                        <p>Details of previous claims, if any</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In case of used-car insurance renewal
                                        online</strong></p>
                                        <p>A copy of the Registration Certificate (RC) to enter the right engine number,
                                    chassis number, car model, year of purchase,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 whyBimastreet" id="whyBimastreet">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Your quest for the best car insurance in India ends here
                                with Bimastreet. </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Twin Benefits – Best policy at best premiums AND free claims assistance</p>
                                    <p>Benefit from rich industry experience and knowledge</p>
                                    <p>Free claims assistance for speedy, judicious, and hassle-free settlement</p>
                                    <p>Best insurance policy recommendation from the largest range of insurance companies
                            </p>
                                    <p>Gain access to the widest cover with all add ons available in the industry.</p>
                                </div>
                                <div class="col-md-6">
                                    <p>The choice to choose from various add ons like Nil Depreciation Cover, Return to
                                Invoice, Engine Protector, RSA, Windshield Cover, NCB Protector, etc.</p>
                                    <p>24/7 free claims assistance</p>
                                    <p>Legal assistance in case of Third-Party Claims (TPC)</p>
                                    <p>Access to tie up garages for Value Added Services</p>
                                    <p>Single point of contact 24/7 for all claim related assistance – Free Of Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-primary">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    Why only buy car insurance online when you can also get free claim service online?
                        </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <a class="btn btn-primary">Get Twin Benefits <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <AccordianCar />

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};
export default CarInsurance;