import React from "react";
import { ChildBtn } from "../ButtonClass";
import { Redirect, Route } from "react-router-dom";
import MaskedInput from 'react-text-mask'
class Offline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.openPopup = this.openPopup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    openPopup() {
        this.setState({ isActive: !this.state.isActive })
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, [e.target.name]: e.target.value });
    }

    handleSubmit() {

        let errors = {}
        let isValid = true;
        let pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\s\-0-9]+\.)+[a-zA-Z\s]{2,}))$/
        );
        let field = this.state.fields

        if (field["fullname"] === null || field["fullname"] === undefined || field["fullname"] === "") {
            errors["fullname"] = "Enter Your Name"
            isValid = true;
        } else if (field["fullname"].length < 4) {

            isValid = false;
            errors["fullname"] = "Please Enter Full Name"

        }

        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
        }

        if (
            field["email"] === null ||
            field["email"] === undefined ||
            field["email"] === ""
        ) {
            errors["email"] = "Please Enter Your Email";
            isValid = false;
        } else if (
            field["email"] !== null ||
            field["email"] !== undefined ||
            field["email"] !== ""
        ) {
            if (field["email"].length < 10) {
                errors["email"] = "Please Enter Your Email";
                isValid = false;
            }
        } else if (!pattern.test(field["email"])) {
            isValid = false;
            errors["email"] = "Please Enter Correct Email";
        }

        if (field["Segment"] === null || field["Segment"] === undefined || field["Segment"] === "") {
            errors["Segment"] = "Select Segment"
            isValid = true;
        }

        if (field["RMname"] === null || field["RMname"] === undefined || field["RMname"] === "") {
            errors["RMname"] = "Enter RM Name"
            isValid = true;
        } else if (field["RMname"].length < 4) {

            isValid = false;
            errors["RMname"] = "Please Enter RM Name"

        }

        if (field["RTO"] === null || field["RTO"] === undefined || field["RTO"] === "") {
            errors["RTO"] = "Enter Your RTO"
            isValid = true;
        } else if (field["RTO"].length < 4) {

            isValid = false;
            errors["RTO"] = "Please Enter RTO"

        }
        if (field["Company"] === null || field["Company"] === undefined || field["Company"] === "") {
            errors["Company"] = "Select Your Company"
            isValid = true;
        }
        if (field["PrevNCB"] === null || field["PrevNCB"] === undefined || field["PrevNCB"] === "") {
            errors["PrevNCB"] = "Select Your Previous NCB"
            isValid = true;
        }
        if (field["UploadRC"] === null || field["UploadRC"] === undefined || field["UploadRC"] === "") {
            errors["UploadRC"] = "Upload Your RC"
            isValid = true;
        }
        if (field["UploadPolicy"] === null || field["UploadPolicy"] === undefined || field["UploadPolicy"] === "") {
            errors["UploadPolicy"] = "Upload Your Policy"
            isValid = true;
        }

        this.setState({ errors, isValid });



    }
     render() {


        return (
            <React.Fragment>
                <span onClick={this.openPopup} style={{ color: "white" }}>
                    Offline Quotes
      </span>
                {this.state.isActive ?
                    <div className="modal fade ui-draggable show" id="OfflineQuotePage" data-backdrop="static" role="dialog">
                        <div className="modal-dialog newModalqt">
                            <div className="modal-content">
                                <div className="modal-header text-center ui-draggable-handle">
                                    Request Offline Quotes
                <button type="button" className="close" data-dismiss="modal" onClick={this.openPopup}>×</button>
                                </div>
                                <div className="modal-body uploadPBoxBody">
                                    <div className="upload_P_Box">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="styleForm">
                                                    <MaskedInput
                                                        mask={[
                                                            /[a-zA-z]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                        ]}
                                                        className="FormTagsBox"
                                                        placeholder="Enter your First Name"
                                                        guide={false}
                                                        name="fullname"
                                                        onChange={this.handle}
                                                        value={this.state.fields["fullname"]}
                                                    />
                                                    <span className="txtError">{this.state.errors["fullname"]}</span>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="styleForm">
                                                    <MaskedInput
                                                        mask={[
                                                            /[6-9]/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ]}
                                                        className="FormTagsBox"
                                                        placeholder="Enter Mobile number"
                                                        guide={false}
                                                        name="MobileNo"
                                                        onChange={this.handle}
                                                        value={this.state.fields["MobileNo"]}
                                                    />
                                                    <span className="txtError">
                                                        {this.state.errors["MobileNo"]}
                                                    </span>                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="styleForm">
                                                    <input
                                                        onChange={this.handle}
                                                        value={this.state.fields["email"]}
                                                        id="TxtEmail"
                                                        name="email"
                                                        placeholder="Enter Your Email Id"
                                                        required="required"
                                                        type="email"

                                                    />
                                                    <span className="txtError">{this.state.errors["email"]}</span>                                               </label>
                                            </div>
                                            <div className="col-md-6">
                                                <select id="SegmentID" name="Segment"  onChange={this.handle}>
                                                    <option>Select Segment</option>
                                                    <option value="car">Private Car</option>
                                                    <option value="twowheeler">Two Wheeler</option>
                                                    <option value="health">Health</option>
                                                </select>
                                                <span className="txtError">{this.state.errors["Segment"]}</span>

                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <label className="styleForm">
                                                    <MaskedInput
                                                        mask={[
                                                            /[a-zA-z]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                        ]}
                                                        className="FormTagsBox"
                                                        placeholder="Enter your RM Name"
                                                        guide={false}
                                                        name="RMname"
                                                        onChange={this.handle}
                                                        value={this.state.fields["RMname"]}
                                                    />
                                                    <span className="txtError">{this.state.errors["RMname"]}</span>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="styleForm">
                                                    <MaskedInput
                                                        mask={[
                                                            /[a-zA-z]/,
                                                            /[a-zA-z\s]/,
                                                             /\d/, /\d/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                            /[a-zA-z\s]/,
                                                        ]}
                                                        className="FormTagsBox"
                                                        placeholder="RTO"
                                                        guide={false}
                                                        name="RTO"
                                                        onChange={this.handle}
                                                        value={this.state.fields["RTO"]}
                                                    />
                                                    <span className="txtError">{this.state.errors["RTO"]}</span>                                                </label>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <select className="selectpicker" name="Company"  onChange={this.handle}>
                                                    <option value="REQUIRED COMPANY">REQUIRED COMPANY</option>
                                                    <option value="100-Liberty Videocon">Liberty Videocon</option>
                                                    <option value="101-TATA">TATA</option>
                                                    <option value="102-BAJAJ">BAJAJ</option>
                                                    <option value="103-HDFC">HDFC</option>
                                                    <option value="104-StarHealth">StarHealth</option>
                                                    <option value="105-Sompo">Sompo</option>
                                                    <option value="106-NIA">NIA</option>
                                                    <option value="107-Iffco">Iffco</option>
                                                    <option value="108-Future">Future</option>
                                                    <option value="109-Bharti">Bharti</option>
                                                    <option value="110-OICL">OICL</option>
                                                    <option value="111-SUNDARAM">SUNDARAM</option>
                                                    <option value="112-Reliance">Reliance</option>
                                                    <option value="113-Kotak">Kotak</option>
                                                    <option value="114-Religare">Religare</option>
                                                    <option value="115-Cigna">Cigna</option>
                                                    <option value="116-Birla">Birla</option>
                                                    <option value="117-MaxBupa">MaxBupa</option>
                                                    <option value="118-SriRam">SriRam</option>
                                                    <option value="120-Appolo">Appolo</option>
                                                    <option value="124-GoDigit">GoDigit</option>
                                                    <option value="126-ICICI Lombard">ICICI Lombard</option>
                                                    <option value="128-NIC">NIC</option>
                                                    <option value="129-Oriental">Oriental</option>
                                                    <option value="130-SBI General">SBI General</option>
                                                    <option value="132-ICICI Health">ICICI Health</option>
                                                    <option value="145-DHFL">DHFL</option>
                                                    <option value="147-Acko">Acko</option>
                                                    <option value="148-Raheja">Raheja</option>
                                                    <option value="149-MaxBupaHealth">MaxBupaHealth</option>
                                                </select>
                                                <span className="txtError">{this.state.errors["Company"]}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="OfQuoteNCB">
                                                    <label>Claim taken in the Expiring policy</label><br />
                                                    <label className="OfQuoteNCB02">
                                                        <input type="radio" id="IsNCB" value="Yes" name="NCBApplicable" className="radioBtn" /> Yes
                                        </label>
                                                    <label>
                                                        <input type="radio" id="IsNCB1" value="No" name="NCBApplicable" checked="checked" className="radioBtn" /> No
                                        </label>
                                                </div>

                                            </div>
                                            <div className="col-md-6 NCBPercent">
                                                <select id="NCBPercent" name="PrevNCB"  onChange={this.handle}>
                                                    <option>Select Previous NCB</option>
                                                    <option value="0">0 %</option>
                                                    <option value="20">20 %</option>
                                                    <option value="20">25 %</option>
                                                    <option value="30">30 %</option>
                                                    <option value="35">35 %</option>
                                                    <option value="45">45 %</option>
                                                    <option value="50">50 %</option>
                                                </select>
                                                <span className="txtError">{this.state.errors["PrevNCB"]}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 NotHealth">
                                                <label className="styleForm">Upload RC</label>
                                                <input type="file" id="FileUploadRC" accept=".png,.jpeg,.jpg,.pdf,.bmp" name="UploadRC"  onChange={this.handle} />
                                                <span className="errMasgOLQ" >max size 500kb in.png,.jpeg,.jpg,.pdf,.bmp</span>
                                                <span className="txtError">{this.state.errors["UploadRC"]}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="styleForm">Upload Policy </label>
                                                <input type="file" id="FileUploadPolicy" accept=".png,.jpeg,.jpg,.pdf,.bmp" required="" name="UploadPolicy"  onChange={this.handle} />
                                                <span className="errMasgOLQ" >max size 500kb in.png,.jpeg,.jpg,.pdf,.bmp</span>
                                                <span className="txtError">{this.state.errors["UploadPolicy"]}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="col-md-3">
                                        <button
                                            type="button"
                                            id="Reg_Btn"
                                            className={ChildBtn()}
                                            onClick={this.handleSubmit}
                                            data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Please wait..."
                                        >
                                            Submit
                </button>                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}  </React.Fragment>

        );
    }
}
export default Offline;
