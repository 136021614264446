import React from "react";
import Services from "./services/Services";
import OurPartner from "./OurPartner";
import Tab from "./HomeTab/Tab";

const VedantInsuranceFinalScetion = () => {
    return (
        <React.Fragment>
            <Tab />
            <Services />
        </React.Fragment>
    );
};
export default VedantInsuranceFinalScetion;
