import React from "react";

const Career = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <React.Fragment>
            <section class="posRegister_pg01">
            </section>
            <section class="CallRequestBack section">
                <div class="container">
                    <div class="row">

                        <div class="col-md-5">
                            <div class="CallRequestBackForm">
                                <h4>Apply Now!</h4>

                                <form id="claim-form" class="Claim_Form" method="post" action="">
                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="txtNmae" name="Name" placeholder="Enter your Name" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="TxtEmail" name="Email" placeholder="Enter your Email" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="txtNmae" name="Name" placeholder="Enter Mobile Number" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="TxtEmail" name="Email" placeholder="Position Interested In" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="txtNmae" name="Name" placeholder="Enter Your Location" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="text" class="form-control" id="TxtEmail" name="Email" placeholder="Enter Work Experience" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="mobile_no_container">
                                            <input type="file" class="form-control" id="TxtEmail" name="Email" placeholder="Enter Work Experience" />
                                        </div>
                                    </div>
                                    <div class="form-group text-right">
                                        <button type="button" id="Reg_Btn" class="btn MTB-Claim-btn">Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div class="col-md-7">
                            <div class="section-heading-2">
                                <h3>
                                    Career  with Kare India
                    </h3>
                                <ul>
                                    <li>Employees are always a big asset for any organization Our  success is attributed to the efficient and committed colleagues working  together towards a common goal. </li>
                                    <li>We continuously strive to provide inspiring leadership by  matching these core values. Our workplace encourages knowledge sharing and  provides excellent learning.</li>
                                    <li>KARE INDIA is one of India's fastest growing Insurance Composite  Broking Company having well establish credential and reputation as most  professional organization.</li>
                                    <li>To keep up with our fast trajectory and achieve our  ambitious plan we wish to recruit professionals who will be responsible for  developing business throughout India.</li>
                                    <li>We are looking for young minds, highly motivated,  experienced professional possessing knowledge about the business with passion  for excellence.</li>
                                    <li>Salary is not a constraint for deserving candidates.</li>
                                    <li>Those interested are requested to send a copy of resume  to&nbsp;<a href="mailto:hr@insurekare.com">hr@insurekare.com</a></li>
                                </ul>


                            </div>

                        </div>
                    </div>
                </div>
            </section>

            
    </React.Fragment>
  );
};
export default Career;
