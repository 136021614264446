import React from "react";
const Expertise = (props) => {
    return (
        <div className="our-experience-block">
            <h2 className="h1">OUR EXPEREINCE</h2>
            <h4 className="dec">We Have Compaleted 2 Lacs + Policies Successfully.</h4>
            <p>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime.<br />
        The standard chunk of those interested Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime.
    </p>
            <div className="container">
                <div className="row text-center">
                    <div className="col-6 col-md border-right">
                        <div>
                            <img src={require("../assets/images/icons_Working Hours.png")} alt = "24 Working Hours" />
                            <h3>
                                <br />
                        24 <br />
                        Working Hours
                    </h3>
                        </div>
                    </div>
                    <div className="col-6 col-md border-right">
                        <div>
                            <img src={require("../assets/images/icons_Editing-hours.png")} alt = "24 Working Hours" />
                            <h3>
                                <br />
                        12 <br />
                        Editing Hours
                    </h3>
                        </div>
                    </div>
                    <div className="col-6 col-md border-right">
                        <div>
                            <img src={require("../assets/images/icons_Team Members.png")} alt = "50+ Team Members" />
                            <h3>
                                <br />
                        50+ <br />
                        Team Members
                    </h3>
                        </div>
                    </div>
                    <div className="col-6 col-md border-right">
                        <div>
                            <img src={require("../assets/images/icons_No of clients.png")} alt = "Number of clients" />
                            <h3>
                                <br />
                        30,000 <br />
                        No. of Clients
                    </h3>
                        </div>
                    </div>
                    <div className="col-6 col-md">
                        <div>
                            <img src={require("../assets/images/icons_Completed-Policies.png")} alt="50+ Team Members" />
                            <h3>
                                <br />
                        02 Lac <br />
                        Completed Pol.
                    </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Expertise;
