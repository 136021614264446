import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const UnitLinkedPlans = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg UnitLinkedBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Unit Linked Plans (ULIPs)</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="padR_160">
                                <h3 class="SecondSectionHeading mb-3 text-left">Advantage of ULIPs</h3>

                                <ul class="DoNotNeed">
                                    <li><strong>Flexible:</strong> ULIPs offer investors the option  of switching between funds, resulting in better choices to the investor.  Investors can choose to invest in either debt or equity funds depending on  their risk appetite and market conditions. </li>
                                    <li><strong>Risk  appetite:</strong> ULIPs  offer investors to pick choose their investments based on their risk appetite.  Low risk appetite investors can choose to invest in debt funds and those who  are willing to take a higher risk can opt for equity funds. </li>
                                    <li><strong>Tax  benefits:</strong> With  ULIPs being life insurance products, they offer tax  benefits in the form of tax free maturity. However this tax benefit depends on  the type of ULIP invested, as equity funds could be taxed 15% under certain  conditions. </li>
                                    <li><strong>Low  charges:</strong> ULIPs do  not have high charges associated with them. IRDA has capped the annual charge  on ULIPs at 2-2.25% p.a. for the initial 10 years, with the charges on par with  those of mutual funds. </li>
                                    <li>
                                        <strong>Long term investment: </strong>ULIPs are a long term investment option due to  the increased lock-in period which also reaps bigger returns.
                            </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default UnitLinkedPlans;
