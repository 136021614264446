const NcbManage=(regYear)=>
        {
            let Currentyear = new Date()
    Currentyear = Currentyear.getFullYear()
    let diffYear = regYear;

    console.log(regYear);
    diffYear = Currentyear - diffYear;
    console.log(diffYear);
            let ncbValue = 0;
            if (diffYear == 2)
            {
                ncbValue = 20;
            }
            else if (diffYear == 3)
            {
                ncbValue = 25;
            }
            else if (diffYear == 4)
            {
                ncbValue = 35;
            }
            else if (diffYear == 5)
            {
                ncbValue = 45;
            }
            else if (diffYear >= 6)
            {
                ncbValue = 50;
            }
            return ncbValue;
        }
        export default NcbManage