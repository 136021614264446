import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
import MaskedInput from "react-text-mask"
import ButtonClass from "../ButtonClass";
import AccordianRel from "./AccordianRel/AccordianRel";
class Epartner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
            fname: null,
            lname: null,
            email: null,
            mobileno: null,
            aadhar: null,
            pan: null,
            date: null,
            Address: null,
            ClientID: null,
            UserID: 0,
            APIUrl: null,
            CRMAPI: null,
            logo: null,
            btnValue:"Register POS"
        };
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm");
        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setStorageValue= this.setStorageValue.bind(this);
        this.pageLoad()
    }
    pageLoad() {
        let url = this.state.APIUrl == null ? window.location.hostname : this.state.APIUrl;
        if (url.match("www")) {
            url = url.replace("www.", "");
        }
        url = url.replace("https://", "");
        let rq = {
            URL: url,

        }
        let mo={
            URL: this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq)
        }
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", mo)
                .then((Res) => {
                    this.setState({ ClientID: Res.data.id, logo: Res.data.companyLogo })

                });
        } catch (err) {
            console.log("Client Id" + err)
        }
        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            let parseToken = false;
            if (Token.charAt(0) === "{")
                parseToken = true;
            console.log(Token)
            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                PostString: JSON.stringify(data)
            }
            try {
                axios.post("/bpi/WeatherForecast/CallCheck", md)
                    .then((Res) => {
                        this.setState({ UserID: Res.data.userID });
                        this.state.UserID = Res.data.userID;

                    });
            } catch (err) {
                console.log("userID" + err)
            }
        }
    }
    async handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
       await this.setState({ fields, [e.target.name]: e.target.value });
    }
    FormSubmitt = (fname, lname, email, mobile, address, pan, dob, adhar) => {
        /*-------------------UserId-------------------------------*/
        let isValid = false;
        /*-------------------end----------------------------*/
        let data = ""
        let generatePass = this.state.ClientID + "_" + fname.substring(0, 3) + "@12";
        let Req = {


            "UserID": this.state.UserID,
            "ClientID": this.state.ClientID,

            "FirstName": fname,
            "LastName": lname,
            "UserName": fname + " " + lname,
            "Password": generatePass,
            "Active": "Y",
            "UserType": "P",
            "CreatedDate": new Date(),
            "CreateBy": null,
            "ModifiedDate": null,
            "ModifiedBy": null,
            "OTP": null,
            "MODPassDate": null,
            "LastLogin": null,
            "EmailAddress": email,
            "IsAuthenticated": null,
            "PhoneNo": null,
            "MobileNo": mobile,
            "AlternateNo": null,
            "DesignationNO": null,
            "CompanyID": null,
            "Address": address,
            "CityID": null,
            "DeptId": null,
            "RoleId": 0,
            "ProfileImage_URL": null,
            "Adhaar_Front_URL": null,
            "Adhaar_Back_URL": null,
            "PAN_URL": null,
            "QualificationCertificate_URL": null,
            "CancelCheque_URL": null,
            "Productid": null,
            "AdhaarNumber": adhar,
            "PANNumber": pan,
            "GSTNumber": null,
            "C_PANNumber": null,
            "KeyAccountManager": null,
            "CD_Limit": null,
            "BeneficiaryName": null,
            "BankAccountNo": null,
            "IFSC_Code": null,
            "Product": null,
            "CarPayout": null,
            "TwoWheelerPayout": null,
            "HealthPayout": null,
            "TravelPayout": null,
            "GST_Number": null,
            "LifePayout": null,
            "Qualification": null,
            "Insurer": null,
            "TrainingPeriod": null,
            "Form_16_url": null,
            "Bank_Statement_Url": null,
            "InvestmentProof_url": null,
            "Others_url": null,
            "PinCode": null,
            "DOB": dob,
            "Married": null,
            "Gender": null,
            "facebook": null,
            "GCV_PAYOUT": null,
            "TAXI_PAYOUT": null,
            "MARINE_PAYOUT": null,
            "GST_CERTIFICATE": null,
            "CarPayoutOpt": null,
            "TwoPayoutOpt": null,
            "RhHead": null,
            "bhCityId": 0,
            "ReferCode": null,
            "PosOrasociate": null,
            "AccountType": null,
            "BankName": null,
            "BankBranch": null,
            "urllogo": this.state.logo,
            "clienturl": this.state.APIUrl


        };
        fetch(this.state.APIUrl + "/api/api/InsurerMotor/CreateRegisterASPos", {
            method: "POST",
            body: JSON.stringify(Req),
            headers: { "Content-Type": "application/json" },
        })
            .then((Response) => Response.json())
            .then((Res) => {
                data = Res;
                this.state.btnValue = "Register POS";
                if (Res.match("1")) {
                    alert("Form Submitted");
                } else
                    return alert("Something Wrong");
                    this.setState({btnValue:"Register POS"})
            });


    }
    handleSubmit() {
        this.state.btnValue="wait.."
        let pan=window.localStorage.getItem("pan");
        let email=window.localStorage.getItem("email");
        let adhar=window.localStorage.getItem("adhar");
        let mobile=window.localStorage.getItem("mobile");
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;
        let pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (this.state.fname === null || this.state.fname === "") {
            errors["fname"] = "Please Enter Your First Name";
            isValid = false;
        } else if (this.state.fname !== null && this.state.fname !== ""
        ) {
            if (this.state.fname.length < 4) {
                errors["fname"] = "Please Enter Your First Name";
                isValid = false;
            }
        }
        if (this.state.lname === null || this.state.lname === "") {
            errors["lname"] = "Please Enter Your Last Name";
            isValid = false;
        } else if (this.state.lname !== null && this.state.lname !== "") {
            if (this.state.lname.length < 4) {
                errors["lname"] = "Please Enter Your Last Name";
                isValid = false;
            }
        }
        if (this.state.mobileno === null || this.state.mobileno === "") {
            errors["mobileno"] = "Please Enter Your Mobile Number";
            isValid = false;
        } 
        if (this.state.mobileno !== null && this.state.mobileno !== "") {
            if (this.state.mobileno.length < 10) {
                errors["mobileno"] = "Please Enter Your Mobile Number";
                isValid = false;
            }
        }
        // if(this.state.mobileno===mobile){
        //     errors["mobileno"] = "Mobile Already Exist";
        //     isValid = false;
        // }
        if (this.state.email === null || this.state.email === "") {
            errors["email"] = "Please Enter Your Email";
            isValid = false;
        } 
        if (this.state.email !== null && this.state.email !== "") {
            if (this.state.email.length < 10) {
                errors["email"] = "Please Enter Your Email";
                isValid = false;
            }
        } 
        if (!pattern.test(this.state.email)) {
            isValid = false;
            errors["email"] = "Please Enter Correct Email";
        } 
        // if (this.state.email!==null && this.state.email!==email){
        //     isValid = false;
        //     errors["email"] = "Email already Exist";
        // }
        if (this.state.aadhar === null || this.state.aadhar === "") {
            errors["aadhar"] = "Please Enter Your Aadhar Number";
            isValid = false;
        } 
        if (this.state.aadhar !== null && this.state.aadhar !== "") {
            if (this.state.aadhar.length < 12) {
                errors["aadhar"] = "Please Enter Your Aadhar Number";
                isValid = false;
            }
        }
        // if(this.state.aadhar !== null && this.state.aadhar!==adhar){
        //     errors["aadhar"] = "Adhar already Exist";
        //     isValid = false;
        // }
        if (this.state.pan === null || this.state.pan === "") {
            errors["pan"] = "Please Enter Your PAN Number";
            isValid = false;
        } 
        if (this.state.pan !== null && this.state.pan !== "") {
            if (this.state.pan.length < 10) {
                errors["pan"] = "Please Enter Your PAN Number";
                isValid = false;
            }
        }
    //    if(this.state.pan !==null && this.state.pan !==pan){
    //     errors["pan"] = "PAN already exist";
    //     isValid = false;
    //    }
        if (this.state["date"] === null || this.state["date"] === "") {
            errors["date"] = "Please Enter Your DOB Number";
            isValid = false;
        }
        if (this.state.date !== null || this.state.date !== "") {
            let d1 = new Date();
            let d2 = new Date(this.state.date);
            let diff = (d1.getTime() - d2.getTime()) / 1000;
            diff /= 60 * 60 * 24;
            let r = Math.abs(Math.round(diff / 365.25));
            if (r < 18) {
                errors["date"] = "Only 18+ or 18 people";
                isValid = false;
            }
        }
        if (this.state["Address"] === null || this.state["Address"] === "" ) {
            errors["Address"] = "Please Enter Your Address ";
            isValid = false;
        } 
        if (this.state["Address"] !== null && this.state["Address"] !== "" ) {
            if (this.state["Address"].length < 10) {
                errors["Address"] = "Please Enter Your Address";
                isValid = false;
            }
        }
        this.setState({ errors, isValid });

        if (isValid) {
            this.FormSubmitt(this.state.fname, this.state.lname, this.state.email, this.state.mobileno, this.state.Address, this.state.pan, this.state.date, this.state.aadhar);
        } else {
            this.state.btnValue = "Register POS";
        }
        this.setState({btnValue:this.state.btnValue})
    } 
    setStorageValue(e){
        let errors={}
        
           if( e.target.name== "email") {
                let email=window.localStorage.getItem("email");
                           if(e.target.value!=null && e.target.value!=email) {
                            window.localStorage.setItem("email",e.target.value);
                            this.state.errors["email"]="";
                           }else {
                               this.state.errors["email"]="Record alread exist";
                           }}
               
            if(e.target.name== "mobileno")
            {  
                let mobile=window.localStorage.getItem("mobile");  
            if(e.target.value!== null && e.target.value!=mobile) {
                window.localStorage.setItem("mobile",e.target.value);
                this.state.errors["mobileno"]=""
               }
               else {
                this.state.errors["mobileno"]="Record alread exist";
           }}
           
            if(e.target.name== "aadhar"){ 
                let adhar=window.localStorage.getItem("aadhar");
            if(e.target.value!== null && e.target.value!=adhar) {
                window.localStorage.setItem("aadhar",e.target.value);
                this.state.errors["aadhar"]=""
               }else {
                this.state.errors["aadhar"]="Record alread exist";
           }}
                
            if(e.target.name== "pan") { 
                let pan=window.localStorage.getItem("pan");
            if(e.target.value!== null && e.target.value!=pan) {
                window.localStorage.setItem("pan",e.target.value);
                this.state.errors["pan"]=""
               }
               else {
                this.state.errors["pan"]="Record alread exist";
           }}
          
        
        this.setState({errors:this.state.errors})
    }
    render() {


        return (
            <React.Fragment>
                <section class="xs-breadcrumb ProductPageMain  breadcrumb-height">
                    <div class="breadcrumb-bg banner-area RegisterPosBG banner-bg rocket-lazyload lazyloaded" >
                        <h3>Register as a POSP</h3>
                    </div>
                </section>
                <section class=" ProductPageSectionFirstPOS">
                    <div class="container">
                        <div class="SecondSectioninner">
                            <div class="row">
                                <div class="col-md-12 main-agileits02claim">
                                    <div class="mainw3-agileinfoclaim">
                                        <div class="login-formclaim row">
                                            <div class="col-md-7">
                                                <h3 class="SecondSectionHeading text-left mb-3">Who is a POSP?</h3>
                                                <p class="padR_160">
                                                    The Insurance Regulatory and Development Authority of India (IRDAI), in 2015, allowed for a new type of insurance intermediary called the “Point of Sale Person (POSP)”. Which means an individual who possesses the minimum qualifications, had undergone training and passed the examination as specified in IRDAI PosP Guidelines, solicits and markets only such products as specified by the Authority (IRDAI)
                                    <strong>Eligibility To become a POS:</strong>  POS should be <strong>18 years of age at least and a class 10th pass with a Aadhaar card.</strong> As per IRDAI Regulations, Insurer or any intermediaries will conduct a 15 hours in-house training session and an examination following that. Then they will issue a certificate and maintain the records for at least 5 years.
                                </p>

                                            </div>

                                            <div class="col-md-5">
                                                <form action="/Home/Refer" method="post" novalidate="novalidate">
                                                    <div className="ProductPageSectionThirdBox">

                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <MaskedInput
                                                                    mask={[
                                                                        /[a-zA-z]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                    ]}
                                                                    className="FormTagsBox"
                                                                    placeholder="Enter your First Name"
                                                                    guide={false}
                                                                    name="fname"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["fname"]}
                                                                />
                                                                <span className="txtError">{this.state.errors["fname"]}</span>  </div>

                                                        </div>

                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <MaskedInput
                                                                    mask={[
                                                                        /[a-zA-z]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                        /[a-zA-z\s]/,
                                                                    ]}
                                                                    className="FormTagsBox"
                                                                    placeholder="Enter your Last Name"
                                                                    guide={false}
                                                                    name="lname"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["lname"]}
                                                                />
                                                                <span className="txtError">{this.state.errors["lname"]}</span>
                                                            </div>
                                                        </div>

                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <input
                                                                    className="FormTagsBox"
                                                                    id="TxtEmail"
                                                                    name="email"
                                                                    placeholder="Enter Your Email Id"
                                                                    type="email"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["email"]}
                                                                    onBlur={this.setStorageValue}
                                                                />
                                                                <span className="txtError">{this.state.errors["email"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <MaskedInput
                                                                    mask={[
                                                                        /[6-9]/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    className="FormTagsBox"
                                                                    placeholder="Enter Mobile number"
                                                                    guide={false}
                                                                    name="mobileno"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["mobileno"]}
                                                                    onBlur={this.setStorageValue}
                                                                />
                                                                <span className="txtError">
                                                                    {this.state.errors["mobileno"]}
                                                                </span> </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <MaskedInput
                                                                    mask={[
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,

                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,

                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    className="FormTagsBox"
                                                                    placeholder="Enter Aadhar number"
                                                                    guide={false}
                                                                    name="aadhar"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["aadhar"]}
                                                                    onBlur={this.setStorageValue}
                                                                />
                                                                <span className="txtError">
                                                                    {this.state.errors["aadhar"]}
                                                                </span>  </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <MaskedInput
                                                                    mask={[
                                                                        /[a-zA-z]/,
                                                                        /[a-zA-z]/,
                                                                        /[a-zA-z]/,
                                                                        /[a-zA-z]/,

                                                                        /[a-zA-z]/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,

                                                                        /\d/,
                                                                        /[a-zA-Z]/,
                                                                    ]}
                                                                    className="FormTagsBox"
                                                                    placeholder="Enter PAN number"
                                                                    guide={false}
                                                                    name="pan"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["pan"]}
                                                                    style={{ textTransform: "uppercase" }}
                                                                    onBlur={this.setStorageValue}
                                                                />
                                                                <span className="txtError">{this.state.errors["pan"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <input
                                                                    type="date"
                                                                    className="FormTagsBox"
                                                                    id="txtNmae"
                                                                    name="date"
                                                                    onChange={this.handle}
                                                                    value={this.state.fields["date"]}
                                                                />
                                                                <span className="txtError">{this.state.errors["date"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <div className="label">
                                                                <input className="FormTagsBox" id="Address" onChange={this.handle} name="Address" placeholder="Address" type="text" value={this.state.fields["Address"]} />
                                                                <span className="txtError">{this.state.errors["Address"]}</span> </div>
                                                        </div>
                                                        <button type="button" id="btnSubmit" className={ButtonClass()} onClick={this.handleSubmit}>{this.state.btnValue}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section class="ProductPageSectionThird">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="SecondSectionHeadingBox">
                                    <h3 class="SecondSectionHeading">Become your own boss in RBL</h3>
                                    <p class="text-center">Get all your quotes in one place, paperless working platform, manage all the renewals and claims, get trained in all the latest products, and generate leads.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <ul class="DoNotNeed">
                                    <li><strong>Hassle free  Online Process:</strong> Once you become a POS with RBL, you  are all set to sell Car, Two-wheeler, Health &amp; Life Insurance policies  online.</li>
                                    <li><strong>Multiple Choices:</strong> As RBL is partnered with multiple insurance  providers which give an opportunity to work for more than one insurance  provider.</li>
                                    <li><strong>Earn unlimited  income:</strong> The easiest way to earn extra income  without putting much of your efforts is by becoming a POS with RBL and earn as  much as you want to.</li>
                                    <li><strong>Earn Renewal  Income:</strong></li>
                                    <li><strong>Work Part time or Full time</strong>- It’s your Choice.</li>
                                    <li><strong>No Investment:</strong> All you need to have is a smartphone or  computer system with internet connectivity.</li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <img src={require("./assets/img/Religare/Become your own boss in RBL.jpg")} />
                            </div>
                        </div>
                    </div>
                </section>
                <section class="ProductPageSectionFirst ProductPageSectionSecondAccordian">
                    <div class="container">
                        <h3 class="SecondSectionHeading">Frequently Asked Questions</h3>
                        <div class="row" id="accordion-style-1">
                            <div class="col-md-12 mx-auto">
                                <AccordianRel />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    };
}
export default Epartner;
