import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>

            <Zoom>
                <Dedicated />
            </Zoom>
            <section class="whatWeDoBoxMain">
                <div class="container">
                    <h2 class="tbk__subtitle" itemprop="alternativeHeadline">POLICY2COVER ADVANTAGE</h2>
                    <h5 class="tbk__title" itemprop="headline">Compare quotes from top insurance companies and you could save up 80% on your regular premiums.</h5>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/choice.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Maximum Options</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">Choose from wide range of options before purchasing. We offer you the highest number of insurance product options across India's leading insurance companies,</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/detailing.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Detailed Comparision</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">Our detailed comparison tables show premiums with detailed product features and benefits. So that you choose only what's right for you.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/price.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Lowest Price</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">Lowest price guaranteed, choose between 30+ insurers, not just the cheapest plans but also the most optimal - so you get much more for your hard-earned money!</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/safe.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Safe and Secure</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">Your data is private and stored in secured servers. We don't share it with anyone, we are fully encrypted using highest secured SSL to protect your data and privacy</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/claim.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Claim Support</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">We support you for rightful judgement. Guiding you to the right product, getting right documentation &amp; whatever else it takes to get the claim resolved,</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/P2C/fast.png")} />
                                <h3>
                                    <a href="life-insurance/index.html">Fast Processing</a>
                                </h3>
                                <p class="tbk__title" itemprop="headline">Our comparisons respond super fast to your customized filters. Get your policy in your inbox within minutes &amp; save money while you're at it!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </React.Fragment>
    );
};
export default Services;
