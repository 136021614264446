import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
import MaskedInput from "react-text-mask"
class Epartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        fields: {},
        errors: {},
        isValid: false,
        fname:null,
        lname:null,
        email:null,
        mobileno:null,
        aadhar:null,
        pan:null,
        date:null,
        Address:null,
        ClientID:null,
        UserID:0,
        APIUrl:null,
        CRMAPI:null,
        logo:null,
    
  };
      this.state.APIUrl=window.localStorage.getItem("portal");
      this.state.CRMAPI=window.localStorage.getItem("crm");
      this.handle = this.handle.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.pageLoad()
    }
    pageLoad(){
      let url = this.state.APIUrl==null?window.location.hostname:this.state.APIUrl;
          if (url.match("www")) {
              url = url.replace("www.", "");
          }
          url=url.replace("https://", "");
          let rq = {
              URL: url,
  
          }
          try {
              axios.post(this.state.CRMAPI + "/api/Account/DomainLogo",rq)
              .then((Res) => {
                  this.setState({ ClientID: Res.data.id,logo:Res.data.companyLogo })
  
              });
          } catch (err) {
              console.log("Client Id"+err)
          }
          let Token = window.localStorage.getItem("token");
          if (Token != null) {
              let parseToken = false;
              if (Token.charAt(0) === "{")
                  parseToken = true;
             
              let data = {
                  Token: parseToken ? JSON.parse(Token).token : Token,
              };
              let md = {
                  URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                  PostString: JSON.stringify(data)
              }
              try {
                  axios.post("/bpi/WeatherForecast/CallCheck", md)
                  .then((Res) => {
                      this.setState({ UserID: Res.data.userID });
                      this.state.UserID = Res.data.userID;
  
                  });
              }catch (err) {
                  console.log("userID"+err)
              }
          }
    }
    handle(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({ fields,[e.target.name]:e.target.value });
    }
    FormSubmitt=(fname,lname,email,mobile,address,pan,dob,adhar)=>{
      /*-------------------UserId-------------------------------*/
     let isValid=false;
      /*-------------------end----------------------------*/
       let data=""
       let generatePass = this.state.ClientID + "_" + fname.substring(0,3) + "@12";
      let Req={
           
      
               "UserID":this.state.UserID,
               "ClientID": this.state.ClientID,
              
               "FirstName": fname,
               "LastName": lname,
               "UserName": fname+" "+lname,
               "Password": generatePass,
               "Active": "Y",
               "UserType": "P",
               "CreatedDate": new Date(),
               "CreateBy": null,
               "ModifiedDate": null,
               "ModifiedBy": null,
               "OTP": null,
               "MODPassDate": null,
               "LastLogin": null,
               "EmailAddress": email,
               "IsAuthenticated": null,
               "PhoneNo": null,
               "MobileNo": mobile,
               "AlternateNo": null,
               "DesignationNO": null,
               "CompanyID": null,
               "Address": address,
               "CityID": null,
               "DeptId": null,
               "RoleId": 0,
               "ProfileImage_URL": null,
               "Adhaar_Front_URL": null,
               "Adhaar_Back_URL": null,
               "PAN_URL": null,
               "QualificationCertificate_URL": null,
               "CancelCheque_URL": null,
               "Productid": null,
               "AdhaarNumber": adhar,
               "PANNumber": pan,
               "GSTNumber": null,
               "C_PANNumber": null,
               "KeyAccountManager": null,
               "CD_Limit": null,
               "BeneficiaryName": null,
               "BankAccountNo": null,
               "IFSC_Code": null,
               "Product": null,
               "CarPayout": null,
               "TwoWheelerPayout": null,
               "HealthPayout": null,
               "TravelPayout": null,
               "GST_Number": null,
               "LifePayout": null,
               "Qualification": null,
               "Insurer": null,
               "TrainingPeriod": null,
               "Form_16_url": null,
               "Bank_Statement_Url": null,
               "InvestmentProof_url": null,
               "Others_url": null,
               "PinCode": null,
               "DOB": dob,
               "Married": null,
               "Gender": null,
               "facebook": null,
               "GCV_PAYOUT": null,
               "TAXI_PAYOUT": null,
               "MARINE_PAYOUT": null,
               "GST_CERTIFICATE": null,
               "CarPayoutOpt": null,
               "TwoPayoutOpt": null,
               "RhHead": null,
               "bhCityId": 0,
               "ReferCode": null,
               "PosOrasociate": null,
               "AccountType": null,
               "BankName": null,
               "BankBranch": null,
               "urllogo":this.state.logo,
               "clienturl": this.state.APIUrl
             
             
        };
        fetch(this.state.APIUrl + "/api/api/InsurerMotor/CreateRegisterASPos", {
           method: "POST",
           body: JSON.stringify(Req),
           headers: { "Content-Type": "application/json" },
         })
           .then((Response) => Response.json())
           .then((Res) => {
            data=Res;
            if(Res.match("1")){
             alert("Form Submitted");
            } else
            return alert("Something Wrong");
           });
   
            
   }
    handleSubmit() {
      let fields = this.state.fields;
      let errors = {};
      let isValid = true;
      let pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (
        fields["fname"] === null ||
        fields["fname"] === undefined ||
        fields["fname"] === ""
      ) {
        errors["fname"] = "Please Enter Your First Name";
        isValid = false;
      } else if (
        fields["fname"] !== null ||
        fields["fname"] !== undefined ||
        fields["fname"] !== ""
      ) {
        if (fields["fname"].length < 4) {
          errors["fname"] = "Please Enter Your First Name";
          isValid = false;
        }
      }
      if (
        fields["lname"] === null ||
        fields["lname"] === undefined ||
        fields["lname"] === ""
      ) {
        errors["lname"] = "Please Enter Your Last Name";
        isValid = false;
      } else if (
        fields["lname"] !== null ||
        fields["lname"] !== undefined ||
        fields["lname"] !== ""
      ) {
        if (fields["lname"].length < 4) {
          errors["lname"] = "Please Enter Your Last Name";
          isValid = false;
        }
      }
      if (
        fields["mobileno"] === null ||
        fields["mobileno"] === undefined ||
        fields["mobileno"] === ""
      ) {
        errors["mobileno"] = "Please Enter Your Mobile Number";
        isValid = false;
      } else if (
        fields["mobileno"] !== null ||
        fields["mobileno"] !== undefined ||
        fields["mobileno"] !== ""
      ) {
        if (fields["mobileno"].length < 10) {
          errors["mobileno"] = "Please Enter Your Mobile Number";
          isValid = false;
        }
      }
      if (
        fields["email"] === null ||
        fields["email"] === undefined ||
        fields["email"] === ""
      ) {
        errors["email"] = "Please Enter Your Email";
        isValid = false;
      } else if (
        fields["email"] !== null ||
        fields["email"] !== undefined ||
        fields["email"] !== ""
      ) {
        if (fields["email"].length < 10) {
          errors["email"] = "Please Enter Your Email";
          isValid = false;
        }
      } else if (!pattern.test(fields["email"])) {
        isValid = false;
        errors["email"] = "Please Enter Correct Email";
      }
      if (
        fields["aadhar"] === null ||
        fields["aadhar"] === undefined ||
        fields["aadhar"] === ""
      ) {
        errors["aadhar"] = "Please Enter Your Aadhar Number";
        isValid = false;
      } else if (
        fields["aadhar"] !== null ||
        fields["aadhar"] !== undefined ||
        fields["aadhar"] !== ""
      ) {
        if (fields["aadhar"].length < 12) {
          errors["aadhar"] = "Please Enter Your Aadhar Number";
          isValid = false;
        }
      }
      if (
        fields["pan"] === null ||
        fields["pan"] === undefined ||
        fields["pan"] === ""
      ) {
        errors["pan"] = "Please Enter Your PAN Number";
        isValid = false;
      } else if (
        fields["pan"] !== null ||
        fields["pan"] !== undefined ||
        fields["pan"] !== ""
      ) {
        if (fields["pan"].length < 10) {
          errors["pan"] = "Please Enter Your PAN Number";
          isValid = false;
        }
      }
      if (
        fields["date"] === null ||
        fields["date"] === undefined ||
        fields["date"] === ""
      ) {
        errors["date"] = "Please Enter Your DOB Number";
        isValid = false;
      } else if (
        fields["date"] !== null ||
        fields["date"] !== undefined ||
        fields["date"] !== ""
      ) {
        let d1 = new Date();
        let d2 = new Date(fields["date"]);
        let diff = (d1.getTime() - d2.getTime()) / 1000;
        diff /= 60 * 60 * 24;
        let r = Math.abs(Math.round(diff / 365.25));
        if (r < 18) {
          errors["date"] = "Only 18+ or 18 people";
          isValid = false;
        }
      }
      if (
        fields["Address"] === null ||
        fields["Address"] === undefined ||
        fields["Address"] === ""
      ) {
        errors["Address"] = "Please Enter Your Address ";
        isValid = false;
      } else if (
        fields["Address"] !== null ||
        fields["Address"] !== undefined ||
        fields["Address"] !== ""
      ) {
        if (fields["Address"].length < 10) {
          errors["Address"] = "Please Enter Your Address";
          isValid = false;
        }
      }
      this.setState({ errors, isValid });
     
      if (this.state.isValid) {
      this.FormSubmitt(this.state.fname,this.state.lname,this.state.email,this.state.mobileno,this.state.Address,this.state.pan,this.state.date,this.state.aadhar);
      }
     
    }
  render() { 
   

  return (
    <section className="posRegister_pg01">
    {this.state.isValid?<Redirect to="/ThankYou" />:
    <div className="posRegister_pg02">
        <h5 className="titleWithFont text-center" style={{color:"black"}}>LET US WORK TOGETHER</h5>
        <p className="text-center">Make a Career – Help us to grow – Earn Money</p>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="formWrapper register">
                        <div className="epartnerForm">
                            <h3 className="ProductPageSectionThirdTitle" style={{color:"black"}}>Partner with us!</h3>
                            



<form action="/Home/Refer" method="post" novalidate="novalidate">       
 <div className="ProductPageSectionThirdBox">

            <div className="mt-2">
                <div className="label">
                <MaskedInput
                mask={[
                  /[a-zA-z]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                ]}
                className="FormTagsBox"
                placeholder="Enter your First Name"
                guide={false}
                name="fname"
                onChange={this.handle}
                value={this.state.fields["fname"]}
              />
              <span className="txtError">{this.state.errors["fname"]}</span>  </div>

            </div>

            <div className="mt-2">
                <div className="label">
                <MaskedInput
                mask={[
                  /[a-zA-z]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                  /[a-zA-z\s]/,
                ]}
                className="FormTagsBox"
                placeholder="Enter your Last Name"
                guide={false}
                name="lname"
                onChange={this.handle}
                value={this.state.fields["lname"]}
              />
              <span className="txtError">{this.state.errors["lname"]}</span>
             </div>
            </div>

            <div className="mt-2">
                <div className="label">
                <input
                className="FormTagsBox"
                onblur="Bluremailcheck(this)"
                id="TxtEmail"
                name="email"
                placeholder="Enter Your Email Id"
                type="email"
                onChange={this.handle}
                value={this.state.fields["email"]}
              />
              <span className="txtError">{this.state.errors["email"]}</span>
             </div>
            </div>
            <div className="mt-2">
                <div className="label">
                <MaskedInput
                    mask={[
                      /[6-9]/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    className="FormTagsBox"
                    placeholder="Enter Mobile number"
                    guide={false}
                    name="mobileno"
                    onChange={this.handle}
                    value={this.state.fields["mobileno"]}
                  />
                  <span className="txtError">
                    {this.state.errors["mobileno"]}
                  </span> </div>
            </div>
            <div className="mt-2">
                <div className="label">
                <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,

                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,

                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                className="FormTagsBox"
                placeholder="Enter Aadhar number"
                guide={false}
                name="aadhar"
                onChange={this.handle}
                value={this.state.fields["aadhar"]}
              />
              <span className="txtError">
                {this.state.errors["aadhar"]}
              </span>  </div>
            </div>
            <div className="mt-2">
                <div className="label">
                <MaskedInput
                mask={[
                  /[a-zA-z]/,
                  /[a-zA-z]/,
                  /[a-zA-z]/,
                  /[a-zA-z]/,

                  /[a-zA-z]/,
                  /\d/,
                  /\d/,
                  /\d/,

                  /\d/,
                  /[a-zA-Z]/,
                ]}
                className="FormTagsBox"
                placeholder="Enter PAN number"
                guide={false}
                name="pan"
                onChange={this.handle}
                value={this.state.fields["pan"]}
                style={{textTransform:"uppercase"}}
              />
              <span className="txtError">{this.state.errors["pan"]}</span>
            </div>
            </div>
            <div className="mt-2">
                <div className="label">
                <input
                type="date"
                className="FormTagsBox"
                id="txtNmae"
                name="date"
                onChange={this.handle}
                value={this.state.fields["date"]}
              />
              <span className="txtError">{this.state.errors["date"]}</span>
             </div>
            </div>
            <div className="mt-2">
                <div className="label">
                    <input className="FormTagsBox" id="Address" onChange={this.handle} name="Address"  placeholder="Address" type="text" value={this.state.fields["Address"]} />
                    <span className="txtError">{this.state.errors["Address"]}</span> </div>
            </div>
            <button type="button" id="btnSubmit" className="cl_btn" onClick={this.handleSubmit}>Register POS</button>
        </div>
</form>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="featuredArea">
                        <div id="lginBox">
                            <h2>Are you already an agent?</h2>
                            <a href="https://www.advancelifeinsurance.in/myaccount" className="button translateArea translateArea1">Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
              }
    </section>  );
};}
export default Epartner;
