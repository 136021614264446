/* eslint-disable */
import React from "react";
import ButtonClass from "../ButtonClass";
import axios from "axios";

class SidePan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            CarVal: this.props.modelValue,
            FuelVal: this.props.fuelID,
            VariantVal: this.props.varientId,
            RTOVal: this.props.rtovalue,
            YearVal: null,
            CarList: this.props.CarList,
            CarListMaster: this.props.CarList,
            FuelList: [],
            VariantList: [],
            VariantList: this.props.VariantList,

            RTOList: [],

            APIUrl: null,
            CRMAPI: null,
            type: this.props.type,
            SelectedMotor: null,

            carName: this.props.carName,
            fuelName: this.props.fuelName,
            SelectedVariant: null,
            SelectedRTO: null,
            RTOname: this.props.RTO,
            varName: this.props.varient,
        };
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm");
    }
    updateState(e) {
        this.setState({
            showFuel: !this.state.showFuel,
            showVariant: !this.state.showVariant,
        });
    }

    render() {
        console.log(`sidepan state:  `, this.state);
        console.log("sidepan porps: ", this.props);
        return (
            <div
                id="myModalEdit"
                className="modal fade editModal ui-draggable show"
                role="dialog"
                data-backdrop="static"
                style={{ paddingRight: "17px", display: "block" }}
            >
                <div className="modal-dialog  modal-lg editModal001">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            FILL YOUR  {this.props.MotorType == 0 ? "BIKE" : "CAR"} DETAILS
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={this.props.close}
                            >
                                ×
            </button>
                        </div>

                        <div className="modal-body editModal002">
                            <div className="yrVehicle">
                                    <button
                                        className="searchButtonCar ProductFormInput"
                                        id="carname"
                                        onClick={this.props.manageSearch}
                                    >
                                        {this.state.CarVal == null
                                            ? `--Make and Model--`
                                            : this.props.carName}
                                    </button>
                                    {this.props.showCar && (
                                        <div className="manageDrpDwn">
                                            <input
                                                type="text"
                                                className=" "
                                                placeholder="Search..."
                                                onChange={this.props.searchFilter}
                                                autoComplete="off"
                                                onKeyDown={this.props.searchDown}
                                                id="searchtext"
                                                autoFocus
                                            />
                                            <ul id="searchul">
                                                {this.props.MotorType < 2 &&
                                                    this.props.CarListMaster.length > 0 &&
                                                    this.props.CarListMaster.map((row, i) => (
                                                        <li
                                                            key={i}
                                                            value={row.VehicleID}
                                                            onClick={(e) =>
                                                                this.props.manageClick(row.VehicleID, "CarVal")
                                                            }
                                                        >
                                                            {row.Manu_Vehicle}
                                                        </li>
                                                    ))}
                                                {this.props.MotorType > 1 &&
                                                    this.props.CarListMaster.length > 0 &&
                                                    this.props.CarListMaster.map((row, i) => {
                                                        return (
                                                            <li
                                                                key={i}
                                                                id={row.CombindID}
                                                                value={row.CombindID}
                                                                onClick={(e) =>
                                                                    this.props.manageClick(
                                                                        row.CombindID,
                                                                        "CarVal"
                                                                    )
                                                                }
                                                            >
                                                                {row.Manu_Vehicle}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    )}

                                {/* <select
                  id="DdlVehicle"
                  onChange={this.props.handle}
                  defaultValue={this.props.modelValue}
                  className="form-control boxHeight"
                  name="CarVal"
                  style={{ width: "100% !important" }}
                >
                  <React.Fragment>
                    {" "}
                    <option value="0">--Select Motor--</option>
                    {this.props.CarList.length > 0 &&
                      this.props.CarList.map((row) => (
                        <option value={row.VehicleID}>
                          {row.Manu_Vehicle}
                        </option>
                      ))}
                  </React.Fragment>
                </select> */}
                            </div>

                            {/* <div className="yrVehicle justify-content-center">
                <select
                  name="FuelVal"
                  id="FuelVal"
                  defaultValue={this.state.fuelName}
                  className="form-control select2"
                  onChange={this.props.searchFilter}
                  onKeyDown={this.props.searchDown}
                  style={{ width: "300px", height: "60px" }}
                >
                  <option value="0">Select Fuel</option>
                  {this.state.FuelList.map((row) => (
                    <option
                      key={row.FuelID}
                      value={
                        this.state.props.type < 2 ? row.FuelID : row.CombindID
                      }
                    >
                      {row.FuelName}
                    </option>
                  ))}
                </select>
              </div> */}

                            <div className="yrVehicle">
                                <select
                                    id="DdlFuel"
                                    onChange={this.props.handle}
                                    value={this.props.fuelID === null ? "0" : this.props.fuelID}
                                    className="ProductFormInput"
                                    name="FuelVal"
                                    style={{ width: "100% !important" }}
                                    data-select2-id="DdlFuel"
                                    tabindex="-1"
                                    aria-hidden="true"
                                >
                                    <option value="0">--Select Fuel--</option>
                                    {this.props.FuelList.length > 0 &&
                                        this.props.FuelList.map((row) => (
                                            <option value={row.FuelID}>{row.FuelName}</option>
                                        ))}
                                </select>
                            </div>

                            <div className="yrVehicle">
                                    <button
                                        onClick={this.props.manageSearch}
                                    className="searchButtonCar ProductFormInput"
                                        id="variantname"
                                    >
                                        {this.props.varientId == null
                                            ? "--Select Variant--"
                                            : this.props.varName}
                                    </button>
                                    {this.props.showVariant && (
                                        <div className="manageDrpDwn">
                                            <input
                                                type="text"
                                                placeholder="Search Variant"
                                                onChange={this.props.searchFilter}
                                                autoComplete="off"
                                                onKeyDown={this.props.searchDown}
                                                id="searchvariant"
                                                autoFocus
                                            />
                                            <ul id="searchulvariant">
                                                {this.props.MotorType < 2 &&
                                                    this.props.VariantListMaster.length > 0 &&
                                                    this.props.VariantListMaster.map((row) => (
                                                        <li
                                                            key={row.VariantID}
                                                            value={row.VariantID}
                                                            onClick={(e) =>
                                                                this.props.manageClick(
                                                                    row.VariantID,
                                                                    "VariantVal"
                                                                )
                                                            }
                                                        >
                                                            {row.VariantName}
                                                        </li>
                                                    ))}
                                                {this.props.MotorType > 1 &&
                                                    this.props.VariantListMaster.length > 0 &&
                                                    this.props.VariantListMaster.map((row) => (
                                                        <li
                                                            key={row.VariantID}
                                                            id={row.CombindID}
                                                            value={row.CombindID}
                                                            onClick={(e) =>
                                                                this.props.manageClick(
                                                                    row.CombindID,
                                                                    "VariantVal"
                                                                )
                                                            }
                                                        >
                                                            {row.VariantName}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    )}

                                <div>
                                </div>
                            </div>

                            {/* <div className="yrVehicle">
                <select
                  id="DdlVariant"
                  onChange={this.props.handle}
                  value={this.props.varientId}
                  className="form-control boxHeight"
                  name="VariantVal"
                  style={{ width: "100%" }}
                  data-select2-id="DdlVariant"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <option value="0">--Select Variant--</option>
                  {this.props.VariantList.length > 0 &&
                    this.props.VariantList.map((row) => (
                      <option value={row.VariantID}>{row.VariantName}</option>
                    ))}
                </select>
              </div> */}

                            <div className="yrVehicle">
                                    <button
                                        onClick={this.props.manageSearch}
                                    className="searchButtonCar ProductFormInput"
                                        id="rtoname"
                                    >
                                        {this.props.rtovalue == null
                                            ? "--Select RTO--"
                                            : this.props.RTOname}
                                    </button>
                                    {this.props.showRTO && (
                                        <div className="manageDrpDwn">
                                            <input
                                                type="text"
                                                placeholder="Search RTO"
                                                onChange={this.props.searchFilter}
                                                autoComplete="off"
                                                onKeyDown={this.props.searchDown}
                                                id="searchrto"
                                                autoFocus
                                            />
                                            <ul id="searchulrto">
                                                {this.props.RTOListMaster.map((row) => (
                                                    <li
                                                        key={row.RTOID}
                                                        value={row.RTOID}
                                                        onClick={(e) =>
                                                            this.props.manageClick(row.RTOID, "RTOVal")
                                                        }
                                                    >
                                                        {row.RTOName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                            </div>

                            {/* <div className="yrVehicle">
                <select
                  id="DdlRTO"
                  defaultValue={this.props.rtovalue}
                  onChange={this.props.handle}
                  className="form-control boxHeight"
                  name="RTOVal"
                  style={{ width: "100% !important" }}
                >
                  <option value="0">--Select RTO--</option>
                  {this.props.RTOList.length > 0 &&
                    this.props.RTOList.map((row) => (
                      <option value={row.RTOID}>{row.RTOName}</option>
                    ))}{" "}
                </select>{" "}
              </div> */}

                            {this.props.IsReNew ? (
                                <div className="yrVehicle">
                                        <button
                                            onClick={this.props.manageSearch}
                                            className="searchButtonCar ProductFormInput"
                                            id="year"
                                        >
                                            {this.props.yearValue == null
                                                ? "--Select Year--"
                                                : this.props.yearValue}
                                        </button>
                                        {this.props.showYear && (
                                            <div className="manageDrpDwn">
                                                <input
                                                    type="tel"
                                                    placeholder="Search Year"
                                                    onChange={this.props.searchFilter}
                                                    autoComplete="off"
                                                    onKeyDown={this.props.searchdown}
                                                    id="searchyear"
                                                    autoFocus
                                                />
                                                <ul id="searchulyear">
                                                    {this.props.YearListMaster.map((row) => (
                                                        <li
                                                            key={row}
                                                            value={row}
                                                            onClick={(e) => this.props.manageClick(row, "YearVal")}
                                                        >
                                                            {row}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                </div>
                            ) : null}

                            {/* {this.props.IsReNew ? (
                <div className="yrVehicle ">
                  <select
                    id="DdlRegCarYear"
                    onChange={this.props.handle}
                    defaultValue={
                      this.props.yearValue === null ? "0" : this.props.year
                    }
                    style={{ width: "100% !important" }}
                    className="form-control boxHeight"
                    data-val="true"
                    name="YearVal"
                    style={{ width: "100% !important" }}
                    data-select2-id="DdlRegCarYear"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="0">--Select Year--</option>
                    {this.props.YearListMaster.map((row) => (
                      <option value={row}>{row} </option>
                    ))}
                  </select>{" "}
                </div>
              ) : null} */}

                            <div className="data-box yrVehicle">
                                <input
                                    maxlength="10"
                                    name="MobileNo"
                                    onChange={this.props.handle}
                                    className="ProductFormInput"
                                    id="MobileNo"
                                    placeholder="Mobile Number"
                                    type="tel"
                                    value={this.props.mobile}
                                    aria-required="true"
                                    aria-invalid="false"
                                />
                            </div>

                            <div className="yrVehicle">
                                <button
                                    type="button"
                                    onClick={this.props.updateResult}
                                    className={ButtonClass()}
                                >
                                    Update Result
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SidePan;
