import React from "react";
import Services from "./services/Services";
import OurPartner from "./OurPartner";
import Tab from "./HomeTab/Tab";

const RiskFreeMiddleSection = () => {
  return (
    <React.Fragment>
   <Tab/>
      <Services />
          <OurPartner />
    </React.Fragment>
  );
};
export default RiskFreeMiddleSection;
