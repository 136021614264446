import React from "react";
const Expertise = (props) => {
    return (
        <section class="benefits">
            <div class="container">
                <div class="col-md-12">
                    <h3 class="my_titleAllHead __orange">Benefits of Policy</h3>
                </div>
                <div class="row benefitsRow">
                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/mobile app icon.png")} />
                            <p>Mobile App</p>
                        </div>
                    </div>

                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/secure.png")} />
                            <p>Secure</p>
                        </div>
                    </div>

                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/transparent.png")} />
                            <p>Easy � Transparent</p>
                        </div>
                    </div>
                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/hassel free icon.png")} />
                            <p>Hassle Free Claims</p>
                        </div>
                    </div>
                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/best policy.png")} />
                            <p>Best Policy</p>
                        </div>
                    </div>
                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/best rate icon.png")} />
                            <p>Best Rates</p>
                        </div>
                    </div>

                    <div class="col-md-3 text-center">
                        <div class="benefitsPoints">
                            <img src={require("../assets/img/StarInsured/compare.png")} />
                            <p>Compare</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
