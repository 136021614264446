import React from 'react';
import "../Contents/css/MainStyle.css"
const IsCorporatePopuo=(props)=>{
    return (<div id="ChkCorporateModel" className="modal">
    <div className="modal-dialog">
        <div className="modal-content text-center">
            
                <div className="modal-body">
                    <div className="ChkCorporateModelPopup">
                        <div>
                            <h3>As per your vehicle registration type selection,here’s your revised premium...</h3>
                        </div>
                    </div>
                    <div className="ChkCorporateModelPopup">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ChkCorporateModelPopup002">
                                    <img id="companylogo" src={props.src} />
                                    <p><span>Old Premium</span> <span className="pull-right ng-binding" id="OldPremium">{"₹"+props.OldPremium}</span></p>
                                    <p><span>Revised Premium</span> <span className="pull-right ng-binding" id="RevisedPremium">{"₹"+props.RevisedPremium}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <button type="button" className="close qtPPBtn qtPPBtnProceed" onClick={props.Proceed} placeholder="Proceed" aria-hidden="true" id="CorporateModelId">Proceed</button>
                    </div>
                </div>
            
        </div>
    </div>
</div>)
}
export default IsCorporatePopuo;