import React from "react";
import "./services.css";
import { Carousel } from "react-bootstrap"
import "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section class="services-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div className="testimonial">
                                <h3 className="testimonialTitle">WHAT OUR CUSTOMERS ARE SAYING</h3>
                                <Carousel>
                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <span className="star">&#9733;</span>
                                            <span className="star">&#9733;</span>
                                            <span className="star">&#9733;</span>
                                            <span className="star">&#9733;</span>
                                            <span className="star">&#9733;</span>
                                            <p>While interacting with Pratiraksha 4 Ever Insurance brokers Pvt ltd I found that
                                            this new organization is working for customers and keeping ethics at every step of their process.
                                                I am delighted to discuss my insurance requirement and satisfied with their approach.</p>
                                            <h4>
                                                SAURABH SHAH ( HEAD-ADMIN ) | ERIS LIFE SCIENCE LTD
                                            </h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <p>The people at Pratiraksha 4 Ever Insurance brokers Pvt ltd are very cordial and always ready to help me and my company and instantly give resolutions to my insurance requirements. They are always ready to go out of the way to support.</p>
                                            <h4>BAL KISHAN SHARMA ( DIRECTOR ) | BANSIYA MINECHEM PVT LTD</h4>
                                            <h4>AHMEDABAD</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <p>Very happy to interact with Directors and employees of Pratiraksha 4 Ever Insurance brokers Pvt ltd. They heard my requirements and accordingly suggested me the insurance which is suitable for me and my family. They have kept a customer centric approach throughout our interaction.
                                                </p>
                                                <h4>
                                                    HIMANSHU SHAH ( GENERAL MANAGER ) | TORRENT POWER LTD</h4>
                                                    <h4>AHMEDABAD</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
<p>Pratiraksha 4 Ever Insurance brokers Pvt ltd approached me for my insurance requirement and found them so diligent and they have given me detailed solution and very happy with their prompt services.
                                            </p>
                                            <h4>ROHIT PATEL ( DIRECTOR ) | SPECIFIC CERAAMICS LIMITED
                                                <br/>GANDHINAGAR</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <p>Pratiraksha 4 Ever Insurance brokers Pvt ltd is a new company but their experience is very rich in insurance. The kind of solution 
                                            they are providing are amazing and they are updated and techno savvy and latest in their insurance field.
                                            </p>
                                            <h4><strong>SUNIL SHARMA</strong>  ( PARTNER )  | KIRAN BULK CARRIERS<br />JAIPUR</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <p>I am delighted to discuss my insurance requirement with Pratiraksha 4 Ever Insurance brokers Pvt ltd and satisfied with their approach. 
  They have kept a customer centric approach throughout our interaction.</p>
                                            <h4><strong>DHAWAL THAKKAR</strong>   ( DIRECTOR )  | SAKAR GRANITO (INDIA) PVT. LIMITED<br />AHMEDABAD</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Carousel.Caption>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <p>The people at Pratiraksha 4 Ever Insurance brokers Pvt ltd are very cordial and always ready to help me. They have kept a customer 
  centric approach throughout our interaction. They suggested to me the insurance which is suitable for me and my family.</p>
                                            <h4><strong>DR. RAVIKESH YADAV</strong>    ( VICE PRESIDENT )  | DAVA ONCOLOGY</h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                </Carousel>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div className="EnqueryForm">
                                <form>
                                    <div className="formBox">
                                        <label>
                                            <input type="text" placeholder="Name" className="form-control" />
                                        </label>
                                        <label>
                                            <input type="text" placeholder="Phone Number" className="form-control" />
                                        </label>
                                        <label>
                                            <input type="text" placeholder="Email" className="form-control" />
                                        </label>
                                        <label>
                                            <textarea type="text" rows="5" placeholder="Massage" className="form-control"></textarea>
                                        </label>
                                        <label>
                                            <button className="btn">Send in your queries</button>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
};
export default Services;
