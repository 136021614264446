const Partner = [
  
  {
    alt: "cigna-ttk-health-insurance-2-150x43",
    src: require("../Contents/img/partner/cigna-ttk-health-insurance-2-150x43.png"),
  },
 
  {
    alt: "godigit",
    src: require("../Contents/img/partner/godigit.png"),
  },
  {
    alt: "hdfc_logo",
    src: require("../Contents/img/partner/hdfc_logo.png"),
  },
  {
    alt: "Icici_lom_Logo",
    src: require("../Contents/img/partner/Icici_lom_Logo.png"),
  },
  {
    alt: "star_logo",
    src: require("../Contents/img/partner/star_logo.png"),
  },
  
  {
    alt: "religare_logo",
    src: require("../Contents/img/partner/religare_logo.png"),
  },
 
  {
    alt: "universal_logo",
    src: require("../Contents/img/partner/universal_logo.png"),
  },
  {
    alt: "sbi-gene",
    src: require("../Contents/img/partner/sbi-gene.jpg"),
  },
    {
        alt: "kotak_logo",
        src: require("../Contents/img/partner/kotak_logo.png"),
    },
    {
        alt: "kotak_logo",
        src: require("../Contents/img/partner/tata_Logo.png"),
    },
];
export default Partner;
