import React from "react";
const Product = [
  {
    id: 1,
    name: "Car",
    to: "/Car",
    className: "nav-item nav-link",
    src:"fa-car"
    },
  {
    id: 2,
    name: "2 Wheeler",
    to: "/Bike",
    className: "productTabsRisk",
    src:"fa-motorcycle"
  },
  
  {
    id: 4,
    name: "Health",
    className: "productTabsRisk",
    to: "/Health",
    src: "fa-heartbeat"
  },
  
];
export default Product;
