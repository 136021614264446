import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import VahanDataSelection from '../../components/VahanDataSelection';
import NcbManage from '../../Car/NcbManage';
class BimaStreetMotorUIManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HoldRequestData: {},
            errors: {},
            fields: {},
            isValid: false,
            CarRegistration: null,
            BikeRegistration: null,
            IsLoaded: false,
            VahanData: {},
            RedirectTo: false,
            TwList: [],
            FourWheelerList: [],
            RTOList: [],
            VariantList: [],
            PastYearClaimID: false,
            Organisation: false,
            ISPACOVERODSELECTED: false,
            PACOVERYEAR: 0,
            MobileNo: null,
            MyPolicyexpired: false,
            BindPreviousNCB: 20,
            Previouspolicydetails: false,
            CarVal: [],
            FuelData: {},
            YearVal: null,
            type: this.props.type,
            RTOVal: [],
            ManufactureDate: null,
            ClientID: null,
            APIUrl: null,
            UserID: 0,
            EnquiryNo: null,
            ShowSelectedData: false,
            VariantVal: [],
            PrevPolicyType: 1,
            ClaimAmount: 0,
            BtnValue: "View Quote",
            RedirectToquote: false,
            EditedVariant: null,
            Edited: false,
            TwListUpdated: [],
            FwListUpdated: [],
            RTOUpdatdList: []
        }
        this.state.APIUrl = window.localStorage.getItem("portal")
        this.handle = this.handle.bind(this);
        this.vahanApiCall = this.vahanApiCall.bind(this);
        this.GoToQuote = this.GoToQuote.bind(this);
        this.getSelectedData = this.getSelectedData.bind(this);
    }
    componentDidMount() {
        axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=Two Wheeler")
            .then(Res => {
                this.setState({ TwList: Res.data });
            });
        axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=Car")
            .then(Res => {
                this.setState({ FourWheelerList: Res.data });
                window.sessionStorage.setItem("fw", JSON.stringify(Res.data))
            });
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
            .then(Res => {
                this.setState({ RTOList: Res.data });
            });
    }
    handle(e) {
        let fields = {};
        let isValid = false;
        fields[e.target.name] = e.target.value
        if (
            e.target.name == "Organisation" ||
            e.target.name == "MyPolicyexpired" ||
            e.target.name == "Previouspolicydetails"
        ) {
            this.setState({
                [e.target.name]: e.target.checked,
            });
        } else {
            this.setState({ fields, [e.target.name]: e.target.value });
        }


        switch (e.target.name) {
            case "Organisation": this.state.Organisation = e.target.value == "on" ? true : false; break;
            case "Previouspolicydetails":
                this.setState({ MyPolicyexpired: e.target.checked, Previouspolicydetails: !this.state.Previouspolicydetails });
                break;
            case "PastYearClaimID":

                this.setState({ PastYearClaimID: !this.state.PastYearClaimID });
                break;
            case "ISPACOVERODSELECTED":
                this.setState({
                    ISPACOVERODSELECTED: !this.state.ISPACOVERODSELECTED,
                });
                break;

        }



        if (this.props.type == 1) {
            if (fields["CarRegistration"] != undefined)
                if (fields["CarRegistration"].length >= 8) {
                    isValid = true
                }
        } else if (this.props.type == 0) {
            if (fields["BikeRegistration"] != undefined)
                if (fields["BikeRegistration"].length >= 8) {
                    isValid = true
                }
        }
        this.state.isValid = isValid;
        this.setState({ isValid })


    }
    GoToQuote() {

        if (this.state.MobileNo != null) {
            if (this.state.MobileNo.length !== 10) {
                return alert("Please enter Mobile No");
            }
        } else {
            return alert("Please Enter mobile no")
        }
        this.GenerateEnquiry()
    }
    GenerateEnquiry() {
        let date = new Date();
        let Req = {
            MobileNo: this.state.MobileNo,
            EnquiryDate: date,
            EnquiryType: "MOT",
            MotorEnquiry: {
                ManufactureID: this.state.CarVal[0].ManufacturerID,
                VehicleID: this.state.CarVal[0].VehicleID,
                VariantID: this.state.VariantVal[0].VariantID,
                FuelID: this.state.FuelData.FuelVal,
                RegistartionYear: this.state.YearVal,
                RTOID: this.state.RTOVal[0].RTOID,
                PolicyType: 1,
                MotorType: this.props.type == 1 ? "Car" : "Two",
            },
            UserID: this.state.UserID,
            LeadSource: "online",
            ClientID: this.state.ClientID,
        };
        //Car.CompData = [];
        //Car.CompData.push(Req);
        let CompModel = {
            Vehicle: this.state.CarVal[0].Manu_Vehicle,
            variant: this.state.VariantVal[0].VariantName,
            RTOName: this.state.RTOVal[0].RTOName,
            NewOrRenew: "Renew",
        };
        console.log(JSON.stringify(Req))
        //Car.CompData.push(CompModel);
        this.state.HoldRequestData = Req;
        this.state.HoldRequestData.CompModel = CompModel;
        this.setState({ HoldRequestData: this.state.HoldRequestData });
        let EnquiryModel = {
            URL: this.state.APIUrl + "/api/api/Enquiry/AddEnquiry",
            PostString: JSON.stringify(Req)
        }

        axios.post("/bpi/WeatherForecast/CallCheck", EnquiryModel)
            .then((Res) => {
                this.state.EnquiryNo = Res.data.split("~")[0].replace('\"', "");
                this.setState({

                    EnquiryNo: Res.data.split("~")[0].replace('\"', ""),

                });
                this.CallQuote();
            });

    }
    getSelectedData(e) {
        //this.setState({[e.target.name]:e.target.value});
        let data = [];
        data.push(this.state.VariantVal[e.target.value]);
        console.log(data);
        // data=this.state.VariantVal.filter((row)=>row.VehicleID==e.target.value);

        this.setState({ VariantVal: data, Edited: false })
    }
    async vahanApiCall() {
        if (this.state.CarRegistration != null) {

            if (this.state.CarRegistration.length == 12) {
                if (this.state.CarRegistration.charAt(2) == 0) {
                    this.state.CarRegistration = this.state.CarRegistration.replace("0", "")

                }
            }

        } else if (this.state.BikeRegistration != null) {

            if (this.state.BikeRegistration.length == 12) {
                if (this.state.BikeRegistration.charAt(2) == 0) {
                    this.state.BikeRegistration = this.state.BikeRegistration.replace("0", "")

                }
            }

        } else {
            return alert("Please Enter an Valid Number")
        }
        console.log(this.state.CarRegistration)
        this.setState({ BtnValue: "Wait..." });
        let APIUrl = window.localStorage.getItem("portal");
        let CRMAPI = window.localStorage.getItem("crm")
        let urllist = APIUrl;
        if (urllist.match("www")) {
            urllist = urllist.replace("www.", "");
        }
        urllist = urllist.replace("https://", "")
        let rq = {
            URL: urllist

        }

        let Model = {
            URL: CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq)
        }
        try {
            fetch("/bpi/WeatherForecast/CallCheck", {
                method: "POST",
                body: JSON.stringify(Model),
                headers: { "Content-Type": "application/json" },
            })
                .then((Response) => Response.json())
                .then((Res) => {
                    this.setState({ ClientID: Res.id })
                    this.state.ClientID = Res.id;
                    window.sessionStorage.setItem("logo", Res.companyLogo);
                });
        } catch (err) {
            console.log(err)
        }
        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            // Token = JSON.parse(Token);
            let data = {
                Token: Token,
            };
            fetch(CRMAPI + "/api/User/GetUserInfo", {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json" },
            })
                .then((Response) => Response.json())
                .then((Res) => {
                    this.setState({ UserID: Res.userID });
                    this.state.UserID = Res.userID;
                    window.sessionStorage.setItem("userData", JSON.stringify(Res))


                });

        }

        let qr = window.sessionStorage.getItem("QuoteResponse");
        let qreq = window.sessionStorage.getItem("QuoteRequest")
        if (qr !== null && qreq !== null) {
            window.sessionStorage.removeItem("QuoteResponse");
            window.sessionStorage.removeItem("QuoteRequest");
        }

        let Req = {
            reg_no: this.props.type == 1 ? this.state.CarRegistration.replace("-", "").toUpperCase() : this.state.BikeRegistration.replace("-", "").toUpperCase(),
            consent: "Y",
            consent_text: "VAHAN API CHECK consent_text"
        };
        let headers = {
            qt_api_key: "023993b7-d88a-4583-8f3f-90de71e31094",
            qt_agency_id: "485bb2ca-e1ce-44a3-8ed6-177451106300"
        }
        console.log(Req)
        //VahanPostMethod
        //this.setState({RedirectTo:true})
        console.log(this.state.FourWheelerList);
        //"https://prod.aadhaarapi.com/verify-rc/v3"
        this.state.TwListUpdated = this.state.TwList;
        this.state.FwListUpdated = this.state.FourWheelerList;
        this.state.RTOUpdatdList = this.state.RTOList;

        let NewModel = {
            URL: "https://bimastreet.com/api/api/Geo/VahanPostMethod?url=https://prod.aadhaarapi.com/verify-rc/v3&req=" + JSON.stringify(Req),
            PostString: JSON.stringify(Req),
        }
        try {
            axios.post("/bpi/WeatherForecast/CallCheck", NewModel)
                .then((Res) => {
                    window.sessionStorage.setItem("VahanResponse", (Res.data));
                    console.log(Res.data)
                    this.state.VahanData = JSON.parse(Res.data)
                    this.setState({ VahanData: this.state.VahanData, IsLoaded: true });
                    let data = JSON.parse(Res.data)

                    let CarVal = [];
                    let VariantVal = [];
                    let YearVal = null;
                    let RTOVal = [];
                    let VariantList = [];
                    let store = [];
                    let model = data.result.rc_maker_model.split(" ");
                    let modelLength = model.length;
                    let i;
                    let minLength;
                    let firstStep = data.result.rc_maker_desc.split(" ")[0];

                    if (this.props.type == 1) {

                        CarVal = this.state.FourWheelerList.filter((row) => row.Manu_Vehicle.toLowerCase().match(firstStep.toLowerCase()));
                        if (CarVal.length < 1) {
                            CarVal = this.state.FourWheelerList.filter((row) => row.Manu_Vehicle.toLowerCase().match(data.result.rc_maker_model.toLowerCase()));
                        }

                        if (CarVal.length == 0) {
                            CarVal = this.state.FourWheelerList;
                        }
                        store = [];
                        for (i = 0; i < modelLength; i++) {
                            let p = CarVal.filter((row) => row.Manu_Vehicle.match(model[i]));
                            if (p.length > 0)
                                store.push(p);

                        }
                        console.log(store)
                        if (store.length > 0) {
                            minLength = store.sort((a, b) => b.length - a.length);

                        }
                        console.log(minLength);
                        minLength = minLength.filter((a) => a.length > 0);

                        minLength = minLength[minLength.length - 1];
                        CarVal = minLength;

                        let MotorData = this.state.FourWheelerList.filter((row) => row.Manu_Vehicle.toLowerCase().match(data.result.rc_maker_model.toLowerCase()));
                        if (MotorData.length > 0) {
                            if (MotorData.length < CarVal.length) {
                                CarVal = MotorData;
                            }
                        }

                        console.log(CarVal)
                        if (CarVal.length == 0) {
                            this.setState({ RedirectTo: true })
                        }
                    }
                    else {
                        CarVal = this.state.TwList.filter((row) => row.Manu_Vehicle.toLowerCase().match(firstStep.toLowerCase()));

                        if (CarVal.length < 1) {
                            CarVal = this.state.TwList.filter((row) => row.Manu_Vehicle.toLowerCase().match(data.result.rc_maker_model.toLowerCase()));
                        }
                        if (CarVal.length == 0) {
                            CarVal = this.state.TwList;
                        }
                        store = [];
                        for (i = 0; i < modelLength; i++) {
                            let p = CarVal.filter((row) => row.Manu_Vehicle.toLowerCase().match(model[i].toLowerCase()));
                            store.push(p);
                        }
                        console.log(store)
                        if (store.length > 0) {
                            minLength = store.sort((a, b) => b.length - a.length);
                            console.log(minLength)
                            minLength = minLength[minLength.length - 1];
                            CarVal = minLength;
                        } else {
                            this.setState({ RedirectTo: true })
                            return
                        }


                        let MotorData = this.state.TwList.filter((row) => row.Manu_Vehicle.toLowerCase().match(data.result.rc_maker_model.toLowerCase()));
                        if (MotorData.length > 0) {
                            if (MotorData.length < CarVal.length) {
                                CarVal = MotorData;
                            }
                        }
                        console.log(CarVal)
                        if (CarVal.length == 0) {
                            this.setState({ RedirectTo: true });

                        }
                    }
                    if (CarVal.length == 0) {
                        this.setState({ RedirectTo: true });
                        return
                    }
                    let FuelData = {
                        FuelVal: null,
                        FuelName: null
                    }
                    if (data.result.rc_fuel_desc.toLowerCase().match("petrol/cng")) {
                        FuelData.FuelVal = 103;
                        FuelData.FuelName = "Petrol CNG"
                    } else if (data.result.rc_fuel_desc.toLowerCase().match("diesel")) {
                        FuelData.FuelVal = 102;
                        FuelData.FuelName = "Diesel"
                    } else if (data.result.rc_fuel_desc.toLowerCase().match("petrol")) {
                        FuelData.FuelVal = 101;
                        FuelData.FuelName = "Petrol"
                    }
                    YearVal = data.result.rc_regn_dt.split("-")[2];
                    this.setState({ BindPreviousNCB: NcbManage(YearVal) })
                    let rtoWithNo = data.result.rc_regn_no;
                    let p1 = rtoWithNo.substring(0, 4);
                    let p2 = p1.replace(/[^A-Z]/gi, "").length;
                    if (p2 == 3) {
                        p1 = rtoWithNo.substring(0, 3);
                    }
                    if (p2 == 2) {
                        if (rtoWithNo.length == 7)
                            p1 = rtoWithNo.substring(0, 3);
                    }
                    if (p1.length == 3) {
                        p1 = p1.substring(0, 2) + "0" + p1.substring(2);
                    }
                    RTOVal = this.state.RTOList.filter((row) => row.RTOName.toUpperCase().match(p1.toUpperCase()));
                    let manu_date = data.result.rc_manu_month_yr.split("/");
                    if (manu_date[0].length == 1) {
                        manu_date[0] = "0" + manu_date[0]
                    }
                    manu_date = manu_date[1] + "-" + manu_date[0] + "-" + "01";
                    let variantdata;
                    axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" + CarVal[0].VehicleID +
                        "&FuelID=" + FuelData.FuelVal)
                        .then((row) => {
                            variantdata = row.data;
                            this.state.VariantList = row.data;
                            this.state.CarVal = CarVal;
                            this.state.FuelData = FuelData;
                            this.state.RTOVal = RTOVal;
                            this.state.YearVal = YearVal;
                            this.state.ManufactureDate = manu_date;
                            if (row.data.length == 0) {
                                if (FuelData.FuelVal == 101) {
                                    FuelData.FuelVal = 102;
                                    FuelData.FuelName = "Diesel"
                                } else if (FuelData.FuelVal == 102) {
                                    FuelData.FuelVal = 101;
                                    FuelData.FuelName = "Petrol"
                                }
                                axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" + CarVal[0].VehicleID +
                                    "&FuelID=" + FuelData.FuelVal)
                                    .then((Res) => {
                                        variantdata = Res.data;
                                        this.state.VariantList = Res.data;
                                        this.setState({ VariantList: this.state.VariantList });
                                        VariantList = this.state.VariantList.filter((row) => row.VariantName.includes(parseInt(data.result.rc_cubic_cap)));

                                        this.state.VariantVal = VariantList.length > 0 ? VariantList : variantdata;
                                        console.log(variantdata)
                                       
                                        this.setState({ CarVal, FuelData: FuelData, RTOVal, YearVal,  ManufactureDate: manu_date, VariantVal: VariantList.length > 0 ? VariantList : variantdata });
                                        this.setState({ BtnValue: "View Quote", ShowSelectedData: true })
                                    })
                            }
                            else {
                                this.setState({ VariantList: this.state.VariantList });
                                VariantList = this.state.VariantList.filter((row) => row.VariantName.includes(parseInt(data.result.rc_cubic_cap)));
                                this.state.VariantVal = VariantList.length > 0 ? VariantList : variantdata;
                                console.log(variantdata)
                                this.setState({ CarVal, FuelData: FuelData, RTOVal, YearVal, ManufactureDate: manu_date, VariantVal: VariantList.length > 0 ? VariantList : variantdata });
                                this.setState({ BtnValue: "View Quote", ShowSelectedData: true })
                            }
                        });

                })
                .catch((error) => {
                    // Error
                    if (error.response) {

                        console.log(error.response.data);

                        this.setState({ RedirectTo: true, BtnValue: "View Quote" })


                    } else if (error.request) {

                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                        this.setState({ RedirectTo: true, BtnValue: "View Quote" })
                    }

                });
        }

        catch (err) {
            console.log(err);
        }

    }
    CallQuote() {
        let data = this.state.VahanData;
        if (this.state.IsLoaded) {

            let Req = {
                IsReNew: true,
                VehicleID: this.state.CarVal[0].VehicleID,
                VariantID: this.state.VariantVal[0].VariantID,
                FUELID: this.state.FuelData.FuelVal+"",
                YearVal: this.state.YearVal,
                RtoID: this.state.RTOVal.length > 0 ? this.state.RTOVal[0].RTOID : "",
                MyPolicyexpired: this.state.MyPolicyexpired,
                ExpMoreThan90Days: this.state.MyPolicyexpired,
                RegistrationYear: this.DateFormatter(data.result.rc_regn_dt),
                Manu_Month: data.result.rc_manu_month_yr.split("/")[0],
                Manu_Year: data.result.rc_manu_month_yr.split("/")[1],
                Clientid: this.state.ClientID,
                ClientURL: this.state.APIUrl,
                CityID: "0",
                StateID: "0",
                RtoCode: this.state.RTOVal.length > 0 ? this.state.RTOVal[0].RTOCode : "",
                NomineeRelationID: "0",
                OccupationID: "0",
                PreviousInsurerCompanyID: "",
                Type: this.props.type,
                VehicleType: this.props.type,
                ManufacturerID: this.state.CarVal.length > 0 ? this.state.CarVal[0].ManufacturerID : "",
                RtoLocation: "",
                FuelName: this.state.FuelData.FuelName,
                IsUat_Production: "",
                PreviousPolicyExpiredDate: this.DateFormatter(data.result.rc_insurance_upto),
                ManufactureDate: this.state.ManufactureDate,
                PastYearClaimID: this.state.PastYearClaimID ? "Y" : "N",
                TypeOfPolicy: 1,
                IDVLpgCng: 0,
                IDVElectrical: 0,
                IDVNonElectrical: 0,
                //Addons
                chkConsumables: false,
                ConsumablesAmt: 0,
                chkEngineProtector: false,
                EngineProtectorAmt: 0,
                chkDepreciation: false,
                ZeroDepreciationAmt: 0,
                chkRoadSideAssistance: false,
                RoadSideAssistanceAmt: 0,
                Organisation: this.state.Organisation,
                chkReturnToInvoice: false,
                ReturnToInvoiceAmt: 0,
                chkPaidDriver: false,
                papaiddriverAmt: 0,
                chkTyreSecure: false,
                TyreSecureAmt: 0,
                chkKeyReplacement: false,
                KeyReplacementAmt: 0,
                chkNCBProtection: false,
                NCBProtectionAmt: 0,
                chkInconvenienceAllowance: false,
                InconvenienceAllowanceAmt: 0,
                chkTPPDcover: false,
                TPPDcoverAmt: 0,
                chkPassangerAssistance: false,
                PassangerAssistanceAmt: 0,
                ClaimAmount: 0,
                chkPassengerCover: false,
                PassengerCoverAmt: 0,
                PassengerCoverSelectedIDV: 0,
                //*******END*******************
                ISPACOVERODSELECTED: this.state.ISPACOVERODSELECTED ? "YES" : "NO",
                PACOVERYEAR: this.state.ISPACOVERODSELECTED ? this.state.PACOVERYEAR : 0,
                IsAntiTheft: false,
                ThirdPartyCover: this.state.PrevPolicyType == 2 ? true : false,
                IDV: 0,
                MobileNo: this.state.MobileNo,
                BindPreviousNCB:
                    this.state.MyPolicyexpired || this.state.PastYearClaimID
                        ? 0
                        : this.state.BindPreviousNCB,
                PreviousNCB: this.state.MyPolicyexpired || this.state.PastYearClaimID
                    ? 0
                    : this.state.BindPreviousNCB,
                IsPreviousZeroDepthTaken: false,
                ComprehensiveforThree_FiveYear: false,
                IsCNG_LPG: "N",
                VoluntaryAmount: 0,
                AutoMobileMembership: "False",
                AutoMobileMembershipName: "",
                AutoMobileMembershipNumber: "",
                AutoMobileMembershipDate: "",
                EnquiryNo: this.state.EnquiryNo,
                PrevPolicyType: this.state.PrevPolicyType,
                vRanKey: "4834297750159265",
                vEncryptedLogin: "N0VpT3BaVTRVNW13eFNXb0dMaXVMQT09",
                vEncryptedPassword: "ck1QQ2V6Y09UeElCejJSc2hWTDdXQT09",
                AllCompIDVs: [],
                Period: 1,
                DepriciationPercentage: 0,
                odOnly: this.state.PrevPolicyType == 3 ? true : false,
                DetariffRate: "",
                IsCorporate: this.state.Organisation ? true : false,
                IsPrevPolicyDetails: this.state.Previouspolicydetails,

            }
            let vahanDetails = {
                VehicleNo: this.props.type == 1 ? this.state.CarRegistration.split("-")[1].toUpperCase() : this.state.BikeRegistration.split("-")[1].toUpperCase(),
                ChasisNo: data.result.rc_chasi_no,
                EngineNo: data.result.rc_eng_no,
                Per_Address: data.result.rc_permanent_address,
                Prv_PolicyNo: data.result.rc_insurance_policy_no,
                OwnerName: data.result.rc_owner_name,
               
            }
            window.localStorage.setItem("ProceedWithSame", JSON.stringify(Req));
            window.sessionStorage.setItem("VahanInfo", JSON.stringify(vahanDetails));
            this.state.HoldRequestData.QuoteReq = Req;
            this.setState({ RedirectToquote: true })
            // this.props.history.push("/GetQuote", { Req: this.state.HoldRequestData });
        }
    }
    DateFormatter(date) {
        date = date.split("-");
        let dd = date[0];
        let mm = date[1];
        let yy = date[2];
        switch (mm) {
            case "Jan": mm = "01";
                break;
            case "Feb": mm = "02";
                break;
            case "Mar": mm = "03";
                break;
            case "Apr": mm = "04";
                break;
            case "May": mm = "05";
                break;
            case "Jun": mm = "06";
                break;
            case "Jul": mm = "07";
                break;
            case "Aug": mm = "08";
                break;
            case "Sep": mm = "09";
                break;
            case "Oct": mm = "10";
                break;
            case "Nov": mm = "11";
                break;
            case "Dec": mm = "12";
                break;
            default: mm = "06";
                break;
        }
        return yy + "-" + mm + "-" + dd
    }
    close = () => {
        this.setState({ ShowSelectedData: !this.state.ShowSelectedData, Edited: false });
    }
    render() {
        return (
            <div>
            {this.state.ShowSelectedData ? <VahanDataSelection handle={this.handle} CarVal={this.state.CarVal} SelectMotor={this.state.type==0?"Two":"Car"} 
            RTOVal={this.state.RTOVal} YearVal={this.state.YearVal} fuil={this.state.FuelData.FuelName} 
            SelectedVariant={this.state.VariantVal} Organisation={this.state.Organisation} Previouspolicydetails={this.state.Previouspolicydetails}
            MyPolicyexpired={this.state.MyPolicyexpired}  PrevPolicyType={this.state.PrevPolicyType} 
            PastYearClaimID={this.state.PastYearClaimID} BindPreviousNCB={this.state.BindPreviousNCB} ClaimAmount={this.state.ClaimAmount} MobileNo={this.state.MobileNo}
            BtnValue={this.state.BtnValue} proceed={()=>this.GoToQuote()} type={this.props.type} closeBtn={this.close} Edited={this.state.Edited} selectVariant={()=>this.setState({Edited:true})} EditChange={this.getSelectedData} />
               :
                <section class="section wizard-section">
                    <div class="container">
                        <div class="row g-3">
                            <div class="col-3 col-md-2 col-lg-1">
                                <a onClick={this.props.close} class="arw-btn">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA" />
                                    </svg>
                                </a>
                            </div>
                            <div class="col-9 col-md-10 col-lg-3 position-relative">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h3 class="f-32 font-regular text-secondary text-capitalize">Enter your {this.props.Motor} Registration Number</h3>
                                    </div>
                                </div>
                                <div class="pageno d-none d-lg-flex"><span>02</span> &nbsp;of 07</div>
                            </div>
                            <div class="col-lg-8">
                                <div class="wizard-box box d-flex flex-column justify-content-between">


                                    <div class="mb-5">
                                        <label for="" class="form-label">{this.props.Motor} Registration Number</label>
                                        <MaskedInput className="form-control" name={this.props.type == 1 ? "CarRegistration" : "BikeRegistration"} onChange={this.handle} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, "-", /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder={"Enter " + this.props.Motor + " Number( e.g. MH01AA1234)"} style={{ textTransform: "uppercase" }} />

                                    </div>

                                    <div>
                                        <Link
                                            to={"/Renew?type=" + this.props.type}
                                            className="text-center headingBt">
                                            Don't know your {this.props.Motor}Number? <b class="text-regular font-bold">Click Here</b>
                                        </Link><br/>
                                        <Link className="headingBt text-center"
                                            to={"/New?type=" + this.props.type}
                                        > <b class="text-regular font-bold">Click Here</b> for new {this.props.Motor}/ first time insurance purchase</Link>
                                    </div>



                                </div>
                                <div class="wizard-bottom">
                                    
                                    {this.state.isValid ?
                                        <button className="btn btn-blue w-100" onClick={this.vahanApiCall} id="VahanCheck" >
                                            {this.state.BtnValue}
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                            </svg>
                                        </button> :
                                        <button className="btn btn-blue w-100" id="VahanCheck" style={{ opacity: "0.5", pointerEvents: "none" }} >
                                            Next
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z" fill="#FFFAFA" />
                                            </svg>
                                        </button>
                                    }

                                </div>

                            </div>
                        </div>



                        <section class="f-14">
                            <div class="row mt-5">
                                <div class="col-md-11 offset-lg-1">
                                    Copyright 2020 - All right Reserved
                </div>
                            </div>

                        </section>

                    </div>
                </section>}

                {this.state.RedirectTo && <Redirect to={"/Renew?type=" + this.props.type} />}
                {this.state.RedirectToquote && <Redirect to={{
                    pathname: "/GetQuote",
                    state: { Req: this.state.HoldRequestData }
                }} />}
            </div>
        );
    }
}

export default BimaStreetMotorUIManager;