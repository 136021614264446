/* eslint-disable */
import React from "react";
const ContactAbout = (props) => {
    return (
        <section class="text-center at-hero mob-padt-88">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-5 flex-end">
                        <img src={require("../assets/img/at-hero.svg")} alt="" class="embed-responsive pl-5 pr-5" />
                    </div>
                    <div class="col-md-6">
                        <h1 class="text-left headingCont">Contact Us</h1>
                        <p class="text-left"><span>At HERO,</span> we've constantly emphasized the importance of evolving with time for the better. While we are always on our toes when it comes to addressing the concerns of our customers, we also acknowledge that there's always a room for improvement. With this in mind and with your support and genuine feedback, we would like to set new highs with regards to customer service.</p>
                        <p class="text-left">You can reach out to us in the following ways.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactAbout;
