import React from 'react';
const CheckLogin=()=>{
    let Token = window.localStorage.getItem("token");
    if (Token != null) {
      let data = {
        Token: Token.token,
      };
     return true;
      // fetch(this.state.CRMAPI + "/api/User/GetUserInfo", {
      //   method: "POST",
      //   body: JSON.stringify(data),
      //   headers: { "Content-Type": "application/json" },
      // })
      //   .then((Response) => Response.json())
      //   .then((Res) => {
      //    this.setState({UserData:Res})
         
      //   });
    } 
    return false
    
  }
  export default CheckLogin;

  const LoginOrLogout = (e) => {
    let fetchmodel = JSON.parse(window.localStorage.getItem("token"));
    let body = {
        Token: fetchmodel.token,
    };
    fetch(this.state.URL + "/api/Account/LogOut", {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    })
        .then((Response) => Response.text())
        .then((data) => {
            window.localStorage.removeItem("token");
            window.location.href = "/";
        });
};
export {LoginOrLogout}
