import React from 'react';
import BimaPlanner from './BimaPlannerComponents/BimaPlanner';
import BimaPlannerMiddleSection from './BimaPlannerComponents/BimaPlannerMiddleSection';
import BMBInsurance from './BMBComponents/BMBInsurance';
import BMBMiddleSection from './BMBComponents/BMBMiddleSection';
import HeroHome from './Herocomponents/HeroHome';
import HeroMiddleSection from './Herocomponents/HeroMiddleSection';
import Insureeasy from './InsureeasyComponents/Insureeasy';
import InsureMiddleSection from './InsureeasyComponents/InsureMiddleSection';
import KareIndia from './KareIndiaComponents/KareIndia';
import KareMiddleSection from './KareIndiaComponents/KareMiddleSection';
import LifeMart from './LifeMartComponents/LifeMart';
import LifeMiddleSection from './LifeMartComponents/LifeMiddleSection';
import Policy2Coverinsurance from './Policy2CoverComponents/Policy2Coverinsurance';
import Policy2CoverMiddleSection from './Policy2CoverComponents/Policy2CoverMiddleSection';
import RiskFreeLife from './RiskFreeLifeComponents/RiskFreeLife';
import RiskMiddleSection from './Riskoverycomponents/RiskMiddleSection';
import Riskovery from './Riskoverycomponents/Riskovery';
import Sansom from './SansomComponents/Sansom';
import SansomMiddleSection from './SansomComponents/SansomMiddleSection';
import SarsaBima from './SarsaBimaComponents/SarsaBima';
import SarsaBimaMiddleSection from './SarsaBimaComponents/SarsaBimaMiddleSection';
import SBMinsurance from './SBMinsuranceComponents/SBMinsurance';
import SBMMiddleSection from './SBMinsuranceComponents/SBMMiddleSection';
import Advance from "./AdvanceComponents/Advance";
import AdvanceMiddleSection from "./AdvanceComponents/AdvanceMiddleSection"
import DemoTemp from './DemoTempComponents/DemoTemp';
import DemoMiddleSection from './DemoTempComponents/DemoMiddleSection';
import BimaStreet from './BimaStreetComponents/BimaStreet';
import BimaStreetMiddleSection from './BimaStreetComponents/BimaStreetMiddleSection';
import PolicyLeader from './PolicyLeaderComponents/PolicyLeader';
import PolicyLeaderMiddleSection from './PolicyLeaderComponents/PolicyLeaderMiddleSection';
import StarInsured from './StarInsuredComponents/StarInsured';
import StarInsuredMiddleSection from './StarInsuredComponents/StarInsuredMiddleSection';
import Inshora from './InshoraComponents/Inshora';
import InshoraMiddleSection from './InshoraComponents/InshoraMiddleSection'
import Religare from './ReligareComponents/Religare';
import ReligareMiddleSection from './ReligareComponents/ReligareMiddleSection';
import Pratiraksha from './PratirakshaComponents/Pratiraksha';
import PratirakshaMiddleSection from './PratirakshaComponents/PratirakshaSection';
import RiskFreeMiddleSection from './RiskFreeLifeComponents/RiskFreeMiddleSection';
import SMCinsurance from './SMCinsuranceComponents/SMCinsurance';
import InsuranceMandi from './InsuranceMandiComponents/InsuranceMandi';
import InsuranceMandiScetion from './InsuranceMandiComponents/InsuranceMandiScetion';
import VedantInsuranceFinal from './VedantInsuranceFinalComponents/VedantInsuranceFinal';
import AdvanceLife from './AdvanceComponents/HomeTab/AdvanceLife';
const Manager=()=>{
    let url =window.location.origin;
    if (url.match("hero")) {
       
      return <HeroHome/>
      }else  if(url.match("insureasy")){
         
        return <Insureeasy newUi="NewUIStyle" />
      } else  if(url.match("riskoveryinsurance")){
        return <Riskovery newUi="NewUIStyle"/>
      }else if(url.match("insurekare")){
          return <KareIndia />
      }else if(url.match("myinsurancekart")){
        return <LifeMart newUi="NewUIStyle" />
      }else if(url.match("onlinebimakaro")){
         return <SBMinsurance/>
      }else if(url.match("sansominsure"))
        return <Sansom newUi="NewUIStyle" />
      else if(url.match("riskfreelife"))
      return <RiskFreeLife />
    else if(url.match("policy2cover")) {
        return <Policy2Coverinsurance />
    }
    else if(url.match("bimaplanner")){
        return <BimaPlanner/>
    }
    else if(url.match("sarsabima")){
        return <SarsaBima/>
    }
    else if(url.match("bookmybima")){
        return <BMBInsurance/>
    }
    else if (url.match("advancelifeinsurance")) {
        return <Advance />
    }
    else if (url.match("enivesh"))
    return <DemoTemp/>
    else if (url.match("bimastreet"))
    return <BimaStreet />
   else if (url.match("policyleader"))
    return <PolicyLeader />
    else if (url.match("star"))
    return <StarInsured />
    else if (url.match("inshoracover"))
    return <Inshora />
    else if(url.match("religare")){
        return <Religare />
    }
    else if (url.match("pratiraksha4ever")) {
        return <Pratiraksha newUi="NewUIStyle" />
    }
    else if (url.match("ui"))
        return <SMCinsurance />
    else if (url.match("mandi"))
        return <InsuranceMandi newUi="NewUIStyle" />
    else if (url.match("veinsure")) {
        return <VedantInsuranceFinal />
    }
    else if (url.match("localhost"))
        return <Inshora />
    
      
}
const Middle=()=>{
    let url =window.location.href;
    if (url.match("hero")) {
       
      return <HeroMiddleSection/>
      }else  if(url.match("insureasy")){
         
      return <InsureMiddleSection />
      } else  if(url.match("riskoveryinsurance")){
      return <RiskMiddleSection />
      }else if(url.match("insurekare")){
          return <KareMiddleSection />
      }else if(url.match("myinsurancekart")){
          return <LifeMiddleSection />
      }else if(url.match("onlinebimakaro")){
         return <SBMMiddleSection />
      }else if(url.match("sansominsure"))
      return <SansomMiddleSection />
      else if(url.match("riskfreelife"))
      return <RiskFreeMiddleSection />
      else if(url.match("policy2cover")) {
        return <Policy2CoverMiddleSection />
    }
   
    else if(url.match("bimaplanner")){
        return <BimaPlannerMiddleSection/>
    }
    else if(url.match("sarsabima")){
        return <SarsaBimaMiddleSection/>
    }
    else if(url.match("bookmybima")){
        return <BMBMiddleSection/>
    }
    else if (url.match("advancelifeinsurance")) {
        return <AdvanceMiddleSection />
    }
    else if (url.match("enivesh"))
    return <DemoMiddleSection />
    else if (url.match("bimastreet"))
    return <BimaStreetMiddleSection />
    else if (url.match("policyleader"))
    return <PolicyLeaderMiddleSection />
    else if (url.match("star"))
    return <StarInsuredMiddleSection />
    else if (url.match("inshoracover"))
    return < InshoraMiddleSection/>
    else if (url.match("religare")){
        return <ReligareMiddleSection />
    }
    else if (url.match("pratiraksha4ever")) {
        return <PratirakshaMiddleSection />
    }
    else if (url.match("mandi"))
        return <InsuranceMandiScetion />
   
    else
        return <InsuranceMandiScetion />
   
}
/*-------------------------*/

const ComponentManager=(props)=>{
  
    return <Manager />
}
export default ComponentManager
const MiddleSection=()=>{
    return <Middle />
} 
export {MiddleSection}
// var querystring = new URLSearchParams(window.location.search);
//     var ValueQueryString = window.localStorage.getItem("token") //querystring.get("user");
//     var CoreInfo = null;
//     if (ValueQueryString != null) {
//         let body = {
//             Token: ValueQueryString
//         };
//         $.ajax({
//             type: 'POST',
//             async: true,
//             url: window.location.origin + '/coreapi/api/BookingPolicy/GetCRMCurrentUrl',
//             contentType: 'application/json; charset=utf-8',
//             data: ValueQueryString,
//             success: function (data) {
//                 try {
//                     if (data.coreAPIURL != null) {
//                         CoreInfo = data;
//                         $("#IsLogin").attr("href","/myaccount/home/index").text("Go To CRM")
//                         console.log(data)
//                         //GetPrivileges(data, body.Token);
//                     }
//                 }
//                 catch (ex) {
//                     console.log(ex)
//                 }
//             },
//             error: function (ex) {
//                 console.log(ex);
//             }
//         });
//     }
//     function GetPrivileges(model, token) {
//         let body = {
//             Token: token,
//             Privilegeid: 3
//         }
//         $.ajax({
//             type: 'POST',
//             async: true,
//             url: window.location.origin + '/coreapi/api/Home/DashBoardPrivilages',
//             contentType: 'application/json; charset=utf-8',
//             data: JSON.stringify(body),
//             success: function (data) {
//                 //CoreInfo = data;
//                 console.log(data);
//             },
//             error: function (ex) {
//                 console.log(ex);
//             }
//         });
//     }