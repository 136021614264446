import React from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
const Dedicated = (props) => {
    return (
        <section class="hero-section" >
            <img src={require("../assets/img/BimastreetBG.gif")} />
            <div class="hero-sectionCNT">
                <h1>Your quest for <span>best<br class="d-none d-md-block" /> insurance</span> policy ends here</h1>
                <p>Bimastreet - where industry knowledge<br /> meets customer expectations</p>
                <NavLink className="btn btn-primary mt-5" tag={Link} to="/SelectInsuranceProduct">
                    Get Quotes
                    <svg width="14" height="10" viewBox="0 0 14 10"
                        fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA" />
                    </svg>
                </NavLink>
            </div>

        </section>
    );
};
export default Dedicated;
