import React from "react";

const ProductTab = (props) => {
    return (
        <>
            <i className="image-block"><img src={props.src} /></i>
            <span className="font-weight-bold small text-uppercase">{props.name}</span>
        </>
    );
};
export default ProductTab;
