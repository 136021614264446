import React from "react";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { Route } from "react-router-dom";
import { InsureCar } from "./InsureCar";
import { InsureBike } from "./InsureBike";
import { InsureHealth } from "./InsureHealth";
import LifeMain from "../../Life/LifeMain";
import Commercial from "../../Commercial/Commercial";
import { InsureCommercial } from "./InsureCommercial";
const TabData = [
  {
    id: "1",
        tabTitle: <ProductTab src={Product[0].src} name={Product[0].name} />,
        tabContent: <InsureCar />,    
  },
  {
    id: "2",
    tabTitle: <ProductTab src={Product[1].src} name={Product[1].name} />,
      tabContent: <InsureBike />,
    
  },
  {
    id: "3",
      tabTitle: <ProductTab src={Product[2].src} name={Product[2].name} />,
      tabContent: <InsureCommercial Motor="PCV" type="2" />,
    
    },
  {
    id: "4",
      tabTitle: <ProductTab src={Product[3].src} name={Product[3].name} />,
      tabContent: <InsureCommercial Motor="GCV" type="3" />,
    
  },
  {
    id: "5",
    tabTitle: <ProductTab src={Product[4].src} name={Product[4].name} />,
      tabContent: <InsureHealth />,
    
  },
  {
    id: "6",
    tabTitle: <ProductTab src={Product[5].src} name={Product[5].name} />,
      tabContent: <LifeMain/>,
    
  },
];
export default TabData;
