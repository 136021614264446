import React from 'react'
import "react-reveal";
import AccordianHealth from './FAQAccordian/AccordianHealth';
import Scroller from './Scroller';
const HealthInsurance = (props) => {
    Scroller();
    return (
        <div className="ProduactPageBima">
            <section class="landing-hero">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h2 class="text-secondary">
                                Leverage our rich industry experience to get the right life insurance
                    </h2>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <p>Have your family insured completely with Bimastreet. Hassle free reimbursement available.                        .</p>
                            <a class="btn btn-primary mt-4">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="hero-img">
                    <img src={require("../assets/img/BimaStreet/health-claim.svg")} class="img-fluid" />
                </div>
            </section>

            <section class="section whatishealth">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h4 class="text-secondary">What is health insurance?</h4>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-10">


                                    <p><strong>Bimastreet believes in economic freedom through insurance penetration.</strong><br />
                                            We have seen reports where one illness wiped out all the finances of a family, who then approached society for help. This is a financial disaster, which can be prevented by proper financial planning by taking adequate health insurance for the family. As a matter of fact, the premium paid is neither a wasteful expenditure nor even an investment plan but a God’s gift during the need.</p>

                                    <p>Although it is not mandatory in India to have health insurance, it is important to take a pragmatic ap-proach to protect oneself and loved ones. Reportedly, the gross direct premium income of the Indian health insurance industry reached INR 470 billion in 2020.</p>

                                    <p>The healthcare industry in India has key players from the private sector as well. In March 2021, a 41% increase in health insurance companies in the non-life insurance sector was driven solely by the rising demand for health insurance products. To help those who cannot afford insurance, the Gov-ernment of India has taken several measures to help the population. Some of them are —</p>

                                    <p><strong>The Ayushman Bharat PMJAY is the most significant health assurance scheme globally that offers healthcare to low-income earners.</strong></p>

                                    <p><strong>The Rashtriya Swasthya Bima Yojana (RSBY) protects workers and their families in the un-organized sector by offering health insurance coverage for hospitalization.</strong></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="benfit-section benefitsOfHealth">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-3 align-self-center">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Benefits of health insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <p>Getting health insurance online made easy with Bimastreet</p>
                            <a class="btn btn-primary">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4 g-4 g-md-3 cont">
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Offers financial security against sudden medical expenditure for treatment of illnesses</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Health Card acts like a debit card avoiding any payment to the hospital during admission.</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Cashless hospitalization treatment through the network hospitals of insurance companies/TPA</p>
                        </div>
                        <div class="col-md-3">
                            <p>In case of treatment in a non-network hospital, the hospital bills are reimbursed by the insur-ance company.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue TypesOfHealth">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Types of health insurance policy</h5>
                                    <p>Health Insurance Policy covers hospitalization expenses, including pre and post-hospitalization ex-penses of the policyholder up to the sum insured.</p>
                                    <p>There are different types of health insurance plans. They are </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-4 g-md-3">
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Individual Health Insurance Policy</h6>
                                Covers hospitalization expenses and other expenses like pre and post-hospitalization expenses for the insured members for the sum insured selected per individual.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Family floater Health Insurance Policy</h6>
                                Is most popular nowadays, covering hospitalization expenses and other expenses like pre & post-hospitalization expenses for the insured members for the sum insured floating on the en-tire family, which means the sum Insured can be utilized by family member or more than one.
                            </div>
                                </div>





                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Top Up Health Insurance Policy</h6>
                                Is a special type of insurance policy that covers hospitalization expenses over a threshold limit like Rs. 3.00 lakhs or 5.00 Lakhs. Top up policy responds when the hospitalization expenses exceed the threshold limit opted.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Critical Illness Insurance Policy</h6>
                                Is a policy that covers listed Critical Illnesses or Major Diseases, and the premiums are very reasonable. This policy can be taken as an additional cover with a base policy as this Critical Illness Policy would respond during major diseases.
                            </div>
                                </div>


                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Corona Kavach or Corona Insurance Policy</h6>
                                The hospitalization treatment for Corona is very much covered under the normal health insur-ance policies. Given India's low penetration of health insurance, IRDA designed and made this Corona Kavach Policy available through all health and general insurance companies.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Health Insurance Policy for Senior Citizens</h6>
                                These are specially designed plans for Senior Citizens as the entry age is restricted under regu-lar health insurance plans. Under these policies age at entry is extended up to 80 years, and then the policy is a renewable lifetime.
                            </div>
                                </div>


                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">The hospitalization cash benefit is an Add On cover or a separate policy</h6>
                                which gives the insured a daily cash allowance, apart from the hospitalization expenses for the number of days in the hospital. This is offered as a built-in coverage within the health insur-ance policy.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">A personal accident plan add-on cover </h6>
                                Protects the insured for disability, loss of income due to an accident. In case of death, the poli-cy nominee gets full compensation from the insurer
                            </div>
                                </div>

                            </div>
                        </div>
                    </div>






                </div>
            </section>

            <section class="section-2 KeTermsHealth">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Key terms used in health insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row g-3 g-md-5">
                                <div class="col-md-6">
                                    <p><strong>ZCashless claim —</strong> A health insurance policy offers the insured a hassle-free claims process as the insurance company pays the hospital directly on behalf of the policyholder. This facility is available to the insured when hospitalized or treated from one of the affiliated or network hos-pitals. The process is easy and benefits the insured as there is minimal paperwork involved.</p>

                                    <p><strong>Daycare expenses -</strong> This type of expense is incurred when treatments, procedures, or opera-tions require less than 24-hour hospitalization. Policyholders can now claim the costs incurred for single-day procedures such as radiation therapy, dialysis, chemotherapy, cataract surgery, among others. However, it is essential to note that daycare expense is not the same as out-patient procedure as OPD does not require hospitalization.</p>
                                </div>
                                <div class="col-md-6">
                                    <p><strong>Pre and post-hospitalization costs —</strong> Tests performed before admission and after discharge from the hospital are considered pre and post-hospitalization costs, respectively. Health insur-ance policies cover hospitalization costs and expenses for tests as they are expensive.</p>
                                    <p><strong>Hospitalization expenditure —</strong> Bills incurred as part of hospital procedure, operation, or surgery are classified under hospitalization expenditure. These expenses are different from daycare as the insured is admitted for more than 24 hours.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue BuyHealth">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">How to buy health insurance online?</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-8">
                                    <p>There is a technology-driven shift in the world of insurance purchase and compensation. As the online insurance process is instantaneous, there is a steady rise in insurance issued online. There is also more transparency as the website or the platform will contain all the necessary information to make an in-formed choice.</p>
                                </div>
                            </div>

                            <p><strong>To buy online, follow these steps.</strong></p>

                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 whyBimaHealth">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Your quest for the best health insurance ends here with Bimastreet.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Twin Benefits – Best policy at best premiums AND free claims assistance</p>

                                    <p>Benefit from rich industry experience and knowledge</p>

                                    <p>Free claims assistance for speedy, judicious, and hassle-free settlement</p>
                                </div>
                                <div class="col-md-6">
                                    <p>Best insurance policy recommendation from the most extensive range of insurance companies</p>

                                    <p>24/7 free claims assistance</p>

                                    <p>Single point of contact 24/7 for all claim related assistance – Free Of Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-primary">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    Why only buy car insurance online when you can also get free claim service online?
                        </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <a class="btn btn-primary">Get Twin Benefits <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <AccordianHealth />

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};
export default HealthInsurance;