import React from "react";
const SimpleMasg = (props) => {
    return (
        <section class="py-5">
            <div class="container">
                <div class="row flex">
                    <div class="col-12 new-footer-top">
                        <div>
                            <h2>We've helped thousands of people to find the<span class="span"> right insurance.</span></h2>
                        </div>
                    </div>
                    <div class="col-4">
                        <img src={require("../assets/img/inshora/home/footer-top.jpg")} class="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default SimpleMasg;
