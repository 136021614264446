import React from "react";
import Claims from "../Claims/Claims";
const ClaimsPage = (props) => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <div className="ClaimsPage">
            <div className="ClaimsPageSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="ClaimsPageCnt">
                                <h1>Our team to give you best of the insurance products <br /> <br /></h1>
                                <div class="row counter-area-small">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/chart.png")} /> <br /> <span class="counter">9.3</span><span>+ Cr</span>
                                            <p>Premium</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/coin.png")} /><br /><span class="counter">9634</span><span>+</span>
                                            <p>Lives Covered</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/theme.png")} /><br /> <span class="counter">13095</span><span>+</span>
                                            <p>Policies Sold</p>
                                        </div>
                                    </div>
                                </div>

                                <small>
                                    *Standard Terms and Conditions Apply.<br />

                                    **Tax benefits are subject to changes in Income Tax Act.
                                </small>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <Claims />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ClaimsPageSec02 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            
                            <div className="ClaimsPageSecCNTBox">
                                
                                    <h3>Vehicle Claims</h3>
                                    <p>We at Kare work with you not only to sell the Insurance Policies but we hold your hands too when there is a claim under the policy.  We consider it our duty to assist you in settlement of the claim in a smooth way.
                                    <br />
                                        The steps in the process are defined below.  They may however vary from one insurer to another.
                                    </p>
                                    <p><strong>Step 1</strong> In case of an unfortunate accident, arrange the documents of the vehicle as below:</p>
                                    <ul>
                                        <li>Registration Certificate</li>
                                        <li>Vehicle Insurance Policy</li>
                                        <li>Driver License</li>
                                        <li>Claim form of the respective Insurance Company - to be filled with each detail with regard to the accident including  narrative and other material information.</li>
                                        <li>Approximate Estimate of damage from the  repairer workshop</li>
                                        <li>Odometer reading</li>
                                        <li>Self-arrange for some photographs on the spot  of the accident if possible</li>
                                    </ul>
                                    <p><strong>Step 2</strong> Lodge the claim with the insurers - this can be done over phone (there is a toll-free number on the policy document) and even e-mail mentioned on the policy document.</p>
                                    <ul>
                                        <li>On phone they may enquire certain details, so  keep above listed documents handy.</li>
                                        <li>On e- mail you may attach the scanned copies  of the above documents.</li>
                                    </ul>
                                    <p><strong>Step 3</strong></p>
                                    <strong>In case of Motor Vehicle accident</strong><br />
                                    <ul>
                                        <li>A surveyor will be appointed with his contact  details either e-mailed or SMS to you.</li>
                                        <li>You may contact him and ask him to visit the  repairer work shop.</li>
                                        <li>He will validate the accident details and  settle the claim towards repairer with the workshop.</li>
                                    </ul>
                                    
                                    <strong>In case of Motor Vehicle theft</strong><br />
                                    <ul>
                                        <li>Report the incident to the nearest Police Station and  obtain a F I R.</li>
                                        <li>You may visit the Insurance Company along with the FIR  and other documents if available and lodge a theft claim.</li>
                                        <li>Investigator will be appointed to verify the FIR and the  details of your narrative.</li>
                                        <li>The Police will carry out their own investigation. In case of Motor Vehicle accidentIf the vehicle is recovered it will be handed  over to the owner. If not, they will give the insured a No Trace Report after  some period of time which you can file with the insurance Company.</li>
                                        <li>The Insurers shall ask you to inform the RTO about the  theft.</li>
                                        <li>The insurers shall reimburse your loss.</li>
                                    </ul>
                                    <strong>In case of Third-Party Insurance Claim</strong><br />
                                    <p>This claim arises normally when there is an injury or death caused due to the accident.  Regardless of who is at fault, the accident is reported to the Police and FIR obtained.  Both parties then approach their respective Insurance Company for redressal of their loss and compensation.</p>
                                    <ul>
                                        <li>The injured party or both parties will file a case with  the Police in which case the insurers must be informed on receiving summons who  shall arrange for the Lawyer to contest case.</li>
                                        <li>The case shall be contested in the MACT (Motor Accident  Claims Tribunal)</li>
                                        <li>Diligently follow the advice of the lawyer, submit all  documents as advised by him.</li>
                                        <li>Wait for the MACT Verdict and comply as advised in it.</li>
                                        <li>Towards damages to your own vehicle follow the procedure  as advised under &ldquo;In case of Motor Vehicle accident&rdquo;</li>
                                    </ul>

                                    <p><strong>Settlement</strong></p>
                                    <strong>In case of Motor Vehicle accident - </strong><br />
                                    <ul>
                                        <li>If settlement is cashless, the insured can  collect the vehicle after repairs from the workshop without paying  anything. If it is reimbursement, then  insured may settle the bill and hand over the above documents and receipt of  payment to the insurers who shall reimburse the same</li>
                                    </ul>
                                    <p><strong>In case of Motor Vehicle theft -</strong></p>
                                    <ul>
                                        <li>The insurers shall reimburse your loss.</li>
                                    </ul>
                                    <p><strong>In case of Third-Party Insurance  Claim</strong></p>
                                    <ul>
                                        <li>After the final report of police investigation is  submitted, the insurance company will pay the claim amount to you.</li>
                                        <li>At the time of the claim payment, you will have to hand  over the vehicle keys to the insurer.</li>
                                        <li>In case your car was bought on loan, the insurance company will settle the depreciated value with the financier and the remaining amount will have to be paid by you.</li>
                                    </ul>
                                    <p>In case you have any other query, please feel free to call us  or email us at support@insurekare.com</p>
                                    <br />
                                    <p><strong>To file claim please report by calling on below toll-free numbers</strong></p>
                                    <table className="table table-bordered">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th>Insurer</th>
                                            <th>Claim Support Number <small>(Toll Free)</small></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>BHARTI-AXA</td>
                                                <td>18001032292</td>
                                            </tr>
                                            <tr>
                                                <td>IFFCO-TOKIO</td>
                                                <td>18001035499</td>
                                            </tr>
                                            <tr>
                                                <td>HDFC ERGO</td>
                                                <td>18002700700</td>
                                            </tr>
                                            <tr>
                                                <td>SBI GENERAL INSURANCE</td>
                                                <td>1800221111</td>
                                            </tr>
                                            <tr>
                                                <td>TATA -AIG</td>
                                                <td>18002667780</td>
                                            </tr>
                                            <tr>
                                                <td>BAJAJ ALLIANZ</td>
                                                <td>18001025858 / 18002095858</td>
                                            </tr>
                                            <tr>
                                                <td>RELIANCE GENERAL INSURANCE</td>
                                                <td>18003009</td>
                                            </tr>
                                            <tr>
                                                <td>L&amp;T GENERAL INSURANCE</td>
                                                <td>18002095846</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ClaimsPage;
