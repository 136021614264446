import axios from 'axios';
import React from 'react';
import { Redirect } from 'react-router';
import LoadCompanyData from './LoadCompnyData';
let clientID=0;
    let urllogo="";
    let APIUrl=window.localStorage.getItem("portal");
    let CRMAPI=window.localStorage.getItem("crm")
    let urllist=APIUrl
    let clientid=0
    let UserID=0;
    const findClient= ()=>{
      if(urllist.match("www")){
        urllist=urllist.replace("www.","");
      }
      urllist=urllist.replace("https://","")
          let rq = {
              URL: urllist
    
        }
       
              fetch(CRMAPI + "/api/Account/DomainLogo", {
         method: "POST",
         body: JSON.stringify(rq),
         headers: { "Content-Type": "application/json" },
       })
         .then((Response) => Response.json())
         .then((Res) => {
              clientID=Res.id;
              urllogo=Res.companyLogo;
              clientid=Res.clientId;
         });
    }
     const findUserID=async ()=>{
     
      let Token = window.localStorage.getItem("token");
   
      if (Token != null) {
          let parseToken = false;
          if (Token.charAt(0) === "{")
              parseToken = true;
          console.log(Token)
          let data = {
              Token: parseToken ? JSON.parse(Token).token : Token,
          };
          let md = {
              URL: this.state.CRMAPI + "/api/User/GetUserInfo",
              PostString: JSON.stringify(data)
          }
         
              axios.post("/bpi/WeatherForecast/CallCheck", md)
          .then((Res) => {
           UserID=Res.data.userID
          });}
    }
    
const FormSubmitt=(fname,lname,email,mobile,address,pan,dob,adhar)=>{
   /*-------------------UserId-------------------------------*/
  let isValid=false;
   /*-------------------end----------------------------*/
    let data=""
    let generatePass = clientID + "_" + fname.substring(0,3) + "@12";
   let Req={
        
   
            "UserID":UserID,
            "ClientID": clientID,
           
            "FirstName": fname,
            "LastName": lname,
            "UserName": fname+" "+lname,
            "Password": generatePass,
            "Active": "Y",
            "UserType": "P",
            "CreatedDate": new Date(),
            "CreateBy": null,
            "ModifiedDate": null,
            "ModifiedBy": null,
            "OTP": null,
            "MODPassDate": null,
            "LastLogin": null,
            "EmailAddress": email,
            "IsAuthenticated": null,
            "PhoneNo": null,
            "MobileNo": mobile,
            "AlternateNo": null,
            "DesignationNO": null,
            "CompanyID": null,
            "Address": address,
            "CityID": null,
            "DeptId": null,
            "RoleId": 8,
            "ProfileImage_URL": null,
            "Adhaar_Front_URL": null,
            "Adhaar_Back_URL": null,
            "PAN_URL": null,
            "QualificationCertificate_URL": null,
            "CancelCheque_URL": null,
            "Productid": null,
            "AdhaarNumber": adhar,
            "PANNumber": pan,
            "GSTNumber": null,
            "C_PANNumber": null,
            "KeyAccountManager": null,
            "CD_Limit": null,
            "BeneficiaryName": null,
            "BankAccountNo": null,
            "IFSC_Code": null,
            "Product": null,
            "CarPayout": null,
            "TwoWheelerPayout": null,
            "HealthPayout": null,
            "TravelPayout": null,
            "GST_Number": null,
            "LifePayout": null,
            "Qualification": null,
            "Insurer": null,
            "TrainingPeriod": null,
            "Form_16_url": null,
            "Bank_Statement_Url": null,
            "InvestmentProof_url": null,
            "Others_url": null,
            "PinCode": null,
            "DOB": dob,
            "Married": null,
            "Gender": null,
            "facebook": null,
            "GCV_PAYOUT": null,
            "TAXI_PAYOUT": null,
            "MARINE_PAYOUT": null,
            "GST_CERTIFICATE": null,
            "CarPayoutOpt": null,
            "TwoPayoutOpt": null,
            "RhHead": null,
            "bhCityId": 0,
            "ReferCode": null,
            "PosOrasociate": null,
            "AccountType": null,
            "BankName": null,
            "BankBranch": null,
            "urllogo":urllogo,
            "clienturl": APIUrl
          
          
     };
     fetch(APIUrl + "/api/api/InsurerMotor/CreateRegisterASPos", {
        method: "POST",
        body: JSON.stringify(Req),
        headers: { "Content-Type": "application/json" },
      })
        .then((Response) => Response.json())
        .then((Res) => {
         data=Res;
         if(Res.match("1")){
          alert("Form Submitted");
         } else
         return alert("Something Wrong");
        });

         
}
export default FormSubmitt