import React from 'react';
const ThankInsp = (props) => {
    let tid = new URLSearchParams(window.location.search);
    tid = tid.get("TID");
    return (<div className="text-center thanksPage ClaimsPageSec">
    <header className="site-header" id="header">
        <h1 className="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
    </header>
    <h2>For Trusting Us</h2>
    <div className="main-content">
        <p className="main-content__body">
            Your vehicle inspection request has been sent successfully. You will receive e-mail or call for inspection from insurance company shortly.
        </p>
        <p className="main-content__body">
                For any query please contact our support team with your Transaction Number.
        </p>
            <strong>Your Transaction Number :: <span id="PropNumber">{tid}</span></strong>
        <div className="icici" style={{display:"none"}}>
            <strong>Your Co-relation ID :: <span id="relation"></span></strong>
                <strong>Your Enquiry Number :: <span id="Enquiry">{ props.Enquiry}</span></strong>
        </div>
    </div>


</div> );
}
 
export default ThankInsp;