import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const HealthInsuranceforSeniorCitizens = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg SeniorCitizenBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Senior Citizen health plans</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">What is Healthy Insurance cover Policy for Senior Citizens?</h3>
                            <p class="padR_160">
                                A senior citizen health insurance plan may have high cost as the insured is having more risk to become ill or injured easily due to higher age.
                    <br />Senior citizen health insurance may come with co-payment condition on claim amount and may restrictions on per day room rent charges on normal or ICU rooms
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>

            <section class="ProductPageSectionThird">
                <div class="container">

                    <h3 class="SecondSectionHeading">Why do you need Cover for Senior Citizens?</h3>

                    <ul class="DoNotNeed">
                        <li>Cost of medical treatment in India is always on the rise. Health insurance for senior citizen can ensure that an unfortunate ailment at old age does not impact your finances and assist you to beat medical inflation.</li>
                        <li>Day care costs which arises for special procedures</li>
                        <li>Ambulance coverage reduces the impact of miscellaneous costs that are incurred</li>
                        <li>Senior citizen health insurance plan includes annual health check-ups to keep track of your health</li>
                        <li>Coverage for alternative treatment takes into account the latest practices wherein treatments combine multiple disciplines</li>
                        <li>Pre-existing diseases are covered subject to terms and conditions of the policy</li>
                    </ul>
                    <p>
                        <strong>Things to keep in mind while buying Senior Citizen Health Plan</strong>
                        <br />Purchasing a health insurance plan for the senior citizen is always a wise decision to opt for and avoid uncertainties of medical costs due to sudden illness. Unique annual health check-up feature helps you to monitor health yearly.
            <br />Let's consider the factors which one should keep in mind while buying senior citizen health insurance.
        </p>

                    <ul class="DoNotNeed">
                        <li><strong>Co-payment:</strong><br /> Co-pay is the fixed percentage of the claim amount that an insured has to pay from his own       pocket for every covered services claim; rest will be paid by company. </li>
                        <li><strong>Expenses covered:</strong><br /> It covers a wide spectrum of medical expenses including in-patient hospitalization, pre and       post hospitalization, day care treatments, ICU charges, daily allowance       and many more. </li>
                        <li><strong>Waiting Period for Pre-Existing Conditions:</strong><br /> It is the time period that must elapse before       coverage can begin. It depends on the insured&rsquo;s medical history and other       related factors; in case of pre-existing ailments, the waiting period is 4       years.</li>
                        <li><strong>Sub-limits:</strong><br /> It is the limit placed by the insurance company on the health insurance claim. It is       usually applicable to services such as room rent, ambulance charges, and       certain medical procedures.</li>
                        <li><strong>Additional Features:</strong><br /> While choosing health insurance plans for parents you should also check additional       features. Many companies offer additional features such as coverage to       alternative treatments, annual health check-up, second opinion etc.</li>
                        <li><strong>No Claim Bonus</strong></li>
                    </ul>


                </div>
            </section>






        </React.Fragment>
    );
};
export default HealthInsuranceforSeniorCitizens;
