/* eslint-disable */
import React from 'react';
const TravelInsurance = () => {
    return (
        <React.Fragment>
            <div class="content-block contact-page d-block">
                <div className="container">
                <section class="terms-section bg-light">
                    <div class="row">
                        <div class="col-lg-12 mx-auto bg-white p-5 terms-condition">
                            <h3>Terms and conditions for the use of the <span class="span">InshoraCover</span></h3>
                            <p>This website ("Website") are owned and operated by Inshora Risk Advisory and Insurance Broking Pvt. Ltd. (IRDAI license 669, IRDA Direct Broker Code: IRDA/DB/738/18 CIN: U66000DL2018PTC330518) , registered office at 788 Ground Floor, Pocket-2, Phase-2, Sector-14, Dwarka, New Delhi-110078. In these Terms of Use, "we", "our" and "us" means inshoracover wherever applicable.</p>
                            <ul>
                                <li>Introduction</li>
                                <li>Privacy Policy</li>
                                <li>Third party products</li>
                                <li>The iNSHORA Cover service and how we are paid</li>
                                <li>Permitted use</li>
                                <li>Registering an account</li>
                                <li>No advice</li>
                                <li>Intellectual property rights</li>
                                <li>Exclusions of liability</li>
                                <li>Indemnity</li>
                                <li>Contact us</li>
                                <li>General</li>
                                <li>Governing law</li>
                            </ul>
                            <h4>1. Introduction</h4>
                            <p>
                                1.1 All access to and use of this Website is subject to these terms and conditions ("Terms of
                                Use"). By accessing and using this Website you agree to be bound by these Terms of Use. If
                                you do not agree to these Terms of Use, you are not permitted to access and use this Website
                                and you should immediately cease accessing and using this Website.
                        </p>
                            <p>1.2 If you breach any provision of these Terms of Use then your right to access and use this Website will immediately cease.</p>
                            <p>
                                1.3 This Website is intended for use by Indian residents only, aged 18 years and over. You
                                should note that this Website (including products, price references and product descriptions)
                                is frequently updated. Products may be withdrawn at any time, without notice.
                        </p>
                            <p>
                                1.4 We may amend these Terms of Use at any time and so we recommend that you refer to
                                these Terms of Use each time you use this Website. The amended Terms of Use will be
                                effective from the date they are posted on this Website. Your continued use of this Website
                                will constitute your acceptance of the amended Terms of Use.
                        </p>
                            <h4>2. Privacy Policy</h4>
                            <p>
                                2.1 We take your privacy seriously. Please visit our Privacy Policy to find out how we use the
                                information that you provide to us. Your use of this Website is at all times subject to the
                                provisions of our Privacy Policy.
                        </p>
                            <h4>3. Third party products</h4>
                            <p>
                                3.1 You can apply for a number of products via this Website. These products are not
                                provided by us but are instead provided by third parties over whom we do not have control. It
                                is your responsibility to satisfy yourself that you wish to obtain any product before doing so.
                                Before making a decision to purchase a product, you should consult the relevant product
                                documents. The presence of a product on this Website does not constitute an endorsement by
                                us of the provider, the product, the content of the provider's website, or the activities of the
                                provider.
                        </p>
                            <p>3.2 All product prices are quoted in Indian Rupees.</p>
                            <p>
                                3.3 We are not responsible or liable for any loss or damage you or any third party may suffer
                                or incur in connection with any product you obtain after using this Website or for any acts,
                                omissions, errors or defaults of any third party in connection with that product.
                        </p>
                            <p>
                                3.4 This Website contains statements regarding the terms of the products, including features
                                and inclusions. Some of these statements are generalised in order to give you a summary of
                                the products being compared. However, not all products are the same and you should confirm
                                the exact terms of any product that you intend to purchase prior to purchasing that product.
                                The product information supplied on the website is available only to Indian residents.
                        </p>
                            <p>
                                3.5 By submitting your personal details through this Website, you are able to make an offer
                                to obtain a product from a third party provider. That offer may be accepted or rejected. The
                                contract for the product will only be concluded once your offer has been accepted by the
                                relevant third party provider. If your offer is accepted then the product will be provided to
                                you, subject to the third party provider's terms and conditions. You will receive written
                                confirmation if your offer has been accepted.
                        </p>
                            <h4>4. The <span class="span">InshoraCover</span> service and how we are paid</h4>
                            <p>
                                4.1 We provide a service on this Website which enables you to research and compare health
                                insurance, life insurance, car insurance, 2W insurance, and travel insurance, all of which are
                                provided by third parties.
                        </p>
                            <p>4.2 Our comparison service is free for you to use. We receive payment from the third party product providers when you use our service to purchase products from them</p>
                            <h4>5. Permitted use</h4>
                            <p>5.1 You are only permitted to use this Website for personal use, subject to your compliance with these Terms of Use. Commercial use of this Website is strictly prohibited</p>
                            <p>5.2 You are not permitted to do anything that may affect the security of this Website or any information or material stored within it.</p>
                            <h5>5.3 You agree not to:</h5>
                            <p>(a) use any automated tool (such as a spider, robot or aggregation tool) to access, copy or extract such information;</p>
                            <p>(b) copy or disclose to any person, any Website content, other than as expressly permitted by these Terms of Use;</p>
                            <p>
                                (c) use any method or process (including data scraping, collection or accumulation tool,
                                robot, spider or scripted responses) for the purpose of obtaining, processing, copying,
                                replicating, distributing, reconfiguring, republishing, viewing, assessing, analysing,
                                modifying or repackaging any Website content;
                        </p>
                            <p>(d) use any method or process to consolidate or combine any Website content with any other content, data, information, images or material;</p>
                            <p>(e) reverse engineer, disassemble or otherwise attempt to construct or identify this Website's source code, formulas or processes;</p>
                            <p>(f) use systematic, repetitive or any other methods which are designed to obtain a large number of quotes, comparisons, rankings or other pricing and related information from this Website;</p>
                            <p>(g) use, obtain or attempt to obtain from this Website, information in order to identify or discover pricing, underwriting, rating and related business methodology or systems; or</p>
                            <p>(h) post, distribute or send any �spamming material' or any other form of bulk communication.</p>
                            <p>5.4 You are not permitted to use this Website in any unlawful or fraudulent manner.</p>
                            <p>5.5 You agree that if you provide any information on this Website, that information will be true, accurate and complete.</p>
                            <h4>6. Registering an account</h4>
                            <p>
                                6.1 This Website allows you to create a personal account. This Website may allow you to
                                sign in to your personal account by generating a standard username and password, or by
                                using your social media username and password. Any such account or access details provided
                                or created by you will be referred to collectively as "Login Details".
                        </p>
                            <h5>6.2 In all cases, if you create a personal account, you agree that:</h5>
                            <p> (a) your Login Details must be used by you only;</p>
                            <p>
                                (b) you will keep your Login Details secure and confidential at all times and will not disclose
                                these to any other person. For security purposes we recommend that you change your
                                password at least once every 6 months;
                        </p>
                            <p>(c) you are responsible for all actions that take place as a result of access to this Website's services via your Login Details; and</p>
                            <p>(d) any breach of these Terms of Use under your Login Details will be treated as a breach by you.</p>
                            <p>
                                6.3 If you believe that the security of your Login Details is compromised you must notify us
                                in writing immediately, but in any event you should change your password immediately. We
                                will not be liable for any loss you incur due to any unauthorised use of your Login Details
                                (including if someone else accesses your account through Login Details they have obtained
                                from you or through a violation by you of these Terms of Use).
                        </p>
                            <p>
                                6.4 We may disable or close your account at any time as a result of any suspected or actual
                                unauthorised use of your Login Details and we will notify you by using the contact details
                                that you have previously provided.
                        </p>
                            <h4>7. No advice</h4>
                            <p>7.1 iNSHORA Cover does not offer financial, investment or other advice. Nothing on this Website constitutes financial, investment or other advice by us in relation to any product.</p>
                            <p>
                                7.2 This Website is intended to be used for comparison and research purposes, but iNSHORA
                                Cover is not able to bind any third party provider or ensure that any third party provider sells
                                any product to you. Accordingly, nothing on this Website amounts to an offer by us or any
                                third party provider to: (a) sell any product to you; or (b) enter into any contract with you.
                        </p>
                            <h4>8. Intellectual property rights</h4>
                            <p>
                                8.1 The word or mark " iNSHORA Cover" however represented, all associated logos and
                                symbols, and any combinations with another word or mark, are the trademarks of Inshora
                                Risk Advisory and Insurance Broking Pvt. Ltd. or one of its group companies.
                        </p>
                            <p>8.2 All intellectual property rights in any material or content contained in or accessible via this Website is either owned by iNSHORA Cover or has been licensed to us for use by us.</p>
                            <h5>8.3 You will only make fair use of the trademarks on this Website and will not use them:</h5>
                            <p>(a) as part of your own marks;</p>
                            <p>(b) in a manner which is likely to cause confusion or damage to the reputation of iNSHORA Cover or</p>
                            <p>(c) to identify or imply endorsement or otherwise of products or services to which they do not relate.</p>
                            <p>
                                8.4 Except as set out elsewhere in these Terms of Use, none of the intellectual property rights
                                belonging to iNSHORA Cover or our licensors in and relating to this Website (including any
                                content or material displayed on this Website) may be used, copied, published or distributed
                                by you without our prior written consent.
                        </p>
                            <h4>9. Exclusions of liability</h4>
                            <p>
                                9.1 This Website, content and services are supplied to you on a strictly "as is" basis and we
                                make no warranties, express or implied, regarding their satisfactory quality, fitness for a
                                particular purpose, reliability, timeliness, accuracy, completeness, security or that they are
                                free from error.
                        </p>
                            <p>
                                9.2 We try to ensure that the information displayed on this Website is accurate, however your
                                access to this Website and any action you carry out on the basis of information you obtain
                                from this Website is carried out entirely at your own risk and we accept no liability for any
                                losses that you may suffer as a result.
                        </p>
                            <p>9.3 We do not give any warranty that the Website is free from viruses or anything else which may have a harmful effect on any technology.</p>
                            <p>
                                9.4 We aim to provide uninterrupted access to this Website but we give no warranty as to the
                                uninterrupted availability of this Website. We reserve the right to suspend, restrict or
                                terminate your access to this Website at any time.
                        </p>
                            <h5>
                                9.5 We will not be liable for any loss of use, profits or data or any indirect, special or
                                consequential damages or losses, whether such losses or damages arise in contract,
                                negligence or tort, or otherwise in relation to:
                        </h5>
                            <p>(a) your use of, reliance upon or inability to use this Website or content;</p>
                            <p>(b) the deletion with or without notice or cause of any of your data or information stored on this Website;</p>
                            <p>
                                (c) any loss of your content or communications resulting from delays, non-deliveries, missed
                                deliveries, service interruptions or a failure, suspension or withdrawal of all or part of this
                                Website at any time;
                        </p>
                            <p>(d) the removal from this Website of any content or communications sent or posted by you on or via this Website;</p>
                            <p>(e) any circumstance that is outside of our reasonable control; and</p>
                            <p>(f) any loss or damage that is not directly caused by us or which we could not reasonably expect at the time we entered into our agreement with you.</p>
                            <p>9.6 Nothing in these Terms of Use is intended to exclude or limit our liability for death or personal injury, our fraud or any other liability which we may not by law exclude or limit.</p>
                            <h4>10. Indemnity</h4>
                            <p>
                                10.1 You agree to indemnify us, our directors, employees, agents, and contractors for any
                                loss suffered, or any costs that may be incurred arising from your breach of these Terms of
                                Use or your use of this Website.
                        </p>
                            <h4>11. Contact us</h4>
                            <p>
                                11.1 We have a number of links on this Website. These may take you to other websites. The
                                purpose of these links is to provide you with easy access to further information you may need
                                in making your purchase decision. In no circumstances do we make representations about the
                                accuracy of any information contained on any other websites. We exclude all liability for any
                                loss, damage, cost or expense you incur in accessing or attempting to access a third party site.
                        </p>
                            <p>11.1 If you have any questions concerning this Website or the services and products we provide, please contact us .</p>
                            <h4>12. General</h4>
                            <p>
                                12.1 If any part of these Terms of Use is determined to be legally invalid or unenforceable,
                                such provision will be superseded by a valid, enforceable provision that most closely matches
                                the intent of the original provision and the remainder of the terms will continue unaffected.
                        </p>
                            <p>12.2 You or we will not be prevented from taking any further action if you or we fail to enforce the rights granted under these Terms of Use.</p>
                            <p>
                                12.3 These Terms of Use constitute the entire agreement between you and us relating to your
                                access to and use of this Website and supersedes any prior agreements (including any
                                previous terms of use of this Website).
                        </p>
                            <h4>13. Governing law</h4>
                            <p>
                                13.1 Any disputes arising pursuant to these terms and conditions are subject to the
                                exclusive jurisdiction of courts in Delhi.
                        </p>
                            <p>
                                13.2 Each of you and us submits to the non-exclusive jurisdiction of the courts of Delhi, India
                                in connection with these Terms of Use and your access to and use of this Website.
                        </p>
                            <p>
                                13.3 This website is controlled by Inshora Risk Advisory and Insurance Broking Pvt. Ltd. from
                                our offices located in Delhi, India. It can be accessed by most countries around the world. As
                                each country has laws that may differ from those of Delhi, by accessing our website, you
                                agree that the statutes and laws of Delhi, without regard to the conflict of laws and the
                                United Nations Convention on the International Sales of Goods, will apply to all matters
                                relating to the use of this website and the purchase of any products or services through this
                                site.
                        </p>
                            <p>
                                Furthermore, any action to enforce this User Agreement shall be brought in the federal or
                                state courts located in India, Delhi. You hereby agree to personal jurisdiction by such courts,
                                and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
                        </p>
                            <h2>Inshora Cover's Privacy Policy<br /><small>Our privacy commitment</small></h2>
                            <p>
                                iNSHORA Cover is committed to protecting the privacy of your personal information. This
                                policy explains how iNSHORA Cover manages the personal information that we collect, use
                                and disclose and how to contact us if you have any further queries about our management of
                                your personal information. This policy does not cover personal information collected or held
                                by iNSHORA Cover or any of its related bodies corporate (as applicable) about its
                                employees.
                        </p>
                            <p>
                                iNSHORA Cover is bound by the Indian Privacy Act and Information Technology Act 2000),
                                which regulate how we may collect, use, disclose and store personal information, and how
                                individuals may access and correct personal information held about them.
                        </p>
                            <p>
                                Please contact us if you have any questions regarding iNSHORA Cover's Privacy Policy or
                                would like us to send you a copy of this policy using the details provided below.
                        </p>
                            <h4>How does iNSHORA Cover collect and hold your personal information?</h4>
                            <h6>
                                We will only collect personal information about you by lawful and fair means. We usually
                                collect personal information directly from you (or from someone you've authorised to deal
                                with us on your behalf), for example when:
                        </h6>
                            <ul>
                                <li>you enquire about or seek a quote for a product or service we sell;</li>
                                <li>you apply for or purchase a product available through iNSHORA Cover;</li>
                                <li>you contact us by telephone, via mail, e-mail or online;</li>
                                <li>you visit our website (including when you request a quote);</li>
                                <li>we supply any other products or services to you; or</li>
                                <li>
                                    you enter into or participate in any promotions, competitions, specials or other
                                    offers with iNSHORA Cover.
                            </li>
                            </ul>
                            <h6>We may collect personal information:</h6>
                            <ul>
                                <li>
                                    from other companies, such as other companies that provide comparison services, if
                                    you have been referred to us by them;
                            </li>
                                <li>from marketing and similar lists we obtain from third parties;</li>
                                <li>from third parties to enhance the quality of the personal information that we hold.</li>
                            </ul>
                            <p>
                                If we collect personal information about you from a third party, we will only do so using fair
                                and lawful means and if we or the third party have received your consent to the disclosure.
                        </p>
                            <p>
                                If you provide us with personal information about another person (such as a family member),
                                it's important that you let the person know we have collected their information and that they
                                can ask to access and correct the information we hold about them.
                        </p>
                            <h4>What kinds of personal information does iNSHORA Cover collect and hold?</h4>
                            <p>
                                The kinds of personal information that we collect and hold about you will depend on the
                                circumstances in which it is being collected, including whether we collect the information
                                from you as a customer, participating provider, contractor, stakeholder, job applicant or in
                                some other capacity.
                        </p>
                            <p>
                                If you request a quote for a product or service offered by our participating providers, we may
                                collect your name, address, email address, telephone number, date of birth and any other
                                information that we need to be able to provide you with the quote. For example, if you wish
                                to obtain a quote for health insurance, we'll ask you for basic information about your family
                                circumstances, pre-existing health conditions and general health care needs. With this
                                information we'll be able to provide you with a comparison of policies that meet your needs.
                                The information we collect will vary depending on the products you're interested in. If you
                                decide to apply for or buy a product through us, we'll ask you for additional information so
                                that we can complete your request.
                        </p>
                            <p>
                                At the time we collect your personal information, we'll display this privacy policy or a
                                collection notice so that you can understand the personal information that we're collecting in
                                the particular circumstances and how we'll manage that information.
                        </p>
                            <p>
                                If you deal with us in some other capacity (for example, as a participating provider, a supplier
                                or a stakeholder), we may collect your name, contact details and any other information you
                                choose to provide to us.
                        </p>
                            <p>
                                Any personal information that you provide via our website or contact centre (for example, for
                                comparison purposes, to obtain a quote or to purchase products) is collected and stored by
                                iNSHORA Cover.
                        </p>
                            <p>
                                To provide some of our services, we may need to collect and hold your sensitive information
                                (such as medical or certain lifestyle information). This may be necessary, for example, if you
                                wish to obtain a comparison quote on health or life insurance. We will only collect sensitive
                                information about you with your consent.

                                Some of the products offered by our participating providers can cover more than one person
                                (for example, family and couples health insurance policies that cover two or more people). If
                                you provide us with information about your partner or a dependant, you must obtain that
                                person's permission to give us the information and inform them of this policy.
                        </p>
                            <p>
                                If we are not able to collect your personal information, or if you provide us with incorrect or
                                incomplete information, we may not be able to provide you with our products or services or
                                do business with you or the organisation with which you are connected.
                        </p>
                            <h4>How does iNSHORA Cover use your personal information?</h4>
                            <h6>We collect, hold, use and disclose personal information so that we can:</h6>
                            <ul>
                                <li>provide you with our services and products;</li>
                                <li>
                                    assess your eligibility for the products and services that you're interested in,
                                    including any special offers you've redeemed in connection with your purchase;
                            </li>
                                <li>
                                    in the case of some products such as home and contents insurance, to let you know
                                    that your product is due for renewal and to arrange for it to be renewed;
                            </li>
                                <li>
                                    verify your identity and manage any enquiries or complaints you have after you've
                                    used our services or purchased a product through us;
                            </li>
                                <li>
                                    let you know about other products, services or promotional offers that we think may
                                    be of interest to you;
                            </li>
                                <li>ensure we comply with the requirements of our participating providers;</li>
                                <li>
                                    conduct research and analysis to improve our understanding of your interests in
                                    relation to our services and offers, including improving our data analytics capability
                                    to provide our customers with better comparisons;
                            </li>
                                <li>
                                    develop insights from our interactions with our customers to enhance our services
                                    and the way we deliver them so that we can keep on meeting the needs of our
                                    customers;
                            </li>
                                <li>
                                    analyse your interactions and use of our online services to understand the
                                    effectiveness of our marketing initiatives;
                            </li>
                                <li>
                                    maintain and improve our customer service by monitoring our service for quality
                                    control, quality and training purposes;
                            </li>
                                <li>consider job applicants for current and future employment;</li>
                                <li>
                                    manage our relationships with our participating providers, suppliers and
                                    stakeholders;
                            </li>
                                <li>
                                    manage our internal business, in particular financial management, reporting and
                                    accounting; and
                            </li>
                                <li>meet our regulatory and legal obligations.</li>
                            </ul>
                            <p>
                                We may also use your information for other purposes required or authorised by or under law,
                                including purposes for which you have provided your consent.
                        </p>
                            <h4>In what circumstances will iNSHORA Cover disclose your personal information?</h4>
                            <p>
                                If you decide to apply for or buy a particular product through iNSHORA Cover, we'll share
                                your information with the product provider so that we can complete the sale or application.
                                For some products that we compare, such as travel insurance and credit cards, we'll send

                                your personal information directly to the product provider so that you can complete your
                                purchase or submit your application directly with the provider. If the provider contacts you
                                directly, they may provide us with information collected from you for the purposes described
                                in this policy.
                        </p>
                            <p>
                                We use service providers to help us provide our comparison services and to conduct our day
                                to day business activities. We take steps to ensure that all our service providers agree to
                                protect the privacy and security of your personal information, and that they only use it for the
                                purposes in this policy, or any collection notice that we provide to you at the time we collect
                                your personal information.
                        </p>
                            <h6>These service providers generally help us by:</h6>
                            <ul>
                                <li>
                                    helping us fulfil customer offers and to manage competitions that we run from time
                                    to time;
                            </li>
                                <li>processing payments for some purchases made on the iNSHORA Cover website;</li>
                                <li>operating a call centre that allows us to provide our services during extended hours;</li>
                                <li>
                                    providing us with software so that we can run comparisons and provide you with our
                                    services;
                            </li>
                                <li>
                                    keeping records related to the services we provide and, if you decide to buy through
                                    us, your purchase of the product;
                            </li>
                                <li>
                                    monitoring our telephone calls for quality control and training purposes so we can
                                    keep improving our customer service;
                            </li>
                                <li>providing us with our office management software, such as email;</li>
                                <li>
                                    providing us with software that supports communication between our IT systems,
                                    including Indian-hosted cloud services; and
                            </li>
                                <li>
                                    supporting our electronic direct marketing activities, such as sending our marketing
                                    emails.
                            </li>
                            </ul>
                            <p>
                                We also share personal information with other companies within the iNSHORA Cover group
                                for the purposes we've already mentioned.
                        </p>
                            <h4>Direct marketing</h4>
                            <p>
                                We use personal information to inform you about products and services offered by
                                iNSHORA Cover and our business partners, which we think may be of interest to you. When
                                we contact you it may be by mail, telephone, email, SMS or social media. Information about
                                how to opt-out of receiving marketing material from us is set out below.
                        </p>
                            <p>
                                If you want to know how we obtained your personal information, please contact our Privacy
                                Officer using the details provided below.
                        </p>
                            <h4>How can you opt out of receiving direct marketing?</h4>
                            <p>
                                You can let us know at any time if you do not want the personal information we hold about
                                you to be used or disclosed for direct marketing purposes, or you do not wish to be contacted
                                in any of the above ways, by contacting us. In some circumstances we may need to contact
                                you to obtain additional information, to verify your identity or to clarify your request.

                                If you wish to unsubscribe from future marketing and promotional material, please click here
                                or go to /unsubscribe.
                        </p>
                            <h4>Use of Government Identifiers</h4>
                            <p>
                                Depending on the service you've asked that we provide, we may collect government-related
                                identifiers, such as Aadhar Card numbers, or a driver's licence number. We collect such
                                details only where it is lawful to do so. We do not use government-related identifiers as our
                                own identifier of individuals.
                        </p>
                            <h4>Data quality and Security</h4>
                            <p>
                                We may store your information in hardcopy and/or in electronic form. We will take
                                reasonable steps to ensure that your personal information is protected from misuse,
                                interference and loss and from unauthorised access, modification or disclosure.
                        </p>
                            <p>
                                This may include a range of systems and communication security measures, as well as the
                                secure storage of hard copy documents. In addition, access to your personal information will
                                be restricted to those properly authorised to have access.
                        </p>
                            <p>
                                We rely on the personal information we hold in conducting our business. Therefore, it is very
                                important that the information we hold is accurate, complete and up-to-date. If you believe
                                that your personal information is inaccurate, incomplete, out of date, and irrelevant or
                                misleading, please contact our Privacy Officer using the details provided below and we'll
                                correct that information within 30 days of receiving any information we need to make the
                                change.
                        </p>
                            <p>
                                Before we correct your personal information, we'll need to verify and confirm your identity.
                                If someone makes the request on your behalf, we will require your prior written consent or
                                we will contact you directly to collect your consent.
                        </p>
                            <p>
                                If we don't agree that the information needs to be corrected, we'll write to let you know why.
                                If that occurs, you can ask us to include a statement with the information that says you
                                believe it's inaccurate, incomplete, out of date, irrelevant or misleading.
                        </p>
                            <h4>Website usage and Cookies</h4>
                            <p>
                                Our website is professionally hosted and operates in a secure environment. You should
                                however be aware that there is always an inherent risk in transmitting your personal
                                information via the Internet.
                        </p>
                            <p>
                                Cookies are pieces of information that a website downloads to your computer to record your
                                preferences while you browse. Other technologies that may be used by iNSHORA Cover
                                include web beacons (which may operate in conjunction with cookies). The information
                                collected through these technologies may be used by iNSHORA Cover to improve and
                                customise your experience on our website. In addition, we collect aggregated non-personally
                                identifiable data from our website to help us maintain and improve delivery of the iNSHORA

                                Cover online service, to provide a consistent experience and targeted marketing. This
                                aggregated information assists iNSHORA Cover to improve content and website navigation.
                        </p>
                            <p>
                                You can configure your web browser to reject and block cookies. If you decide to delete or
                                block certain cookies, your experience on our website may be limited.
                        </p>
                            <p>
                                When you visit any of our online resources, our system may collect information about your
                                visit for statistical purposes, including:
                        </p>
                            <ul>
                                <li>server address;</li>
                                <li>top level domain name (for example .com, .gov, .org,.in.co.in etc.);</li>
                                <li>the date and time of your visit to the site;</li>
                                <li>the pages you accessed and documents downloaded during your visit;</li>
                                <li>the previous site you visited;</li>
                                <li>if you've visited our site before; and</li>
                                <li>the type of browser used.</li>
                            </ul>
                            <p>
                                We record this information to maintain our server and improve our services. There are
                                several methods and packages that we use to collect visitor behaviours on each of our online
                                platforms. Like a number of commercial website operators, we use tools to measure and
                                analyse internet usage across our websites. This enables us to track the number of unique
                                browsers to our websites.
                        </p>
                            <p>
                                In some cases, we allow third-party vendors to use cookies and web beacon to provide you
                                with information about our services and products. These technologies may be used in regard
                                to activities like surveys, website analytics, online behavioural advertising and email
                                campaign management. There are ways for you to opt out of third-party cookies; (i) by
                                contacting iNSHORA Cover that allows you to opt out of some online behavioural
                                advertising. These services provided by third party vendors generally operate without the
                                collection or use of any personal information. In many cases, the information relates only to a
                                device or is of a statistical nature, and we will not be able to identity the user.
                        </p>
                            <h4>Links to third party sites</h4>
                            <p>
                                We provide links to third party websites from our website. We are not responsible for the
                                content or privacy practices or policies of these third party websites.
                        </p>
                            <p>
                                These links are provided for your convenience and do not represent iNSHORA Cover's
                                endorsement of any linked third party website. We recommend that you review the privacy
                                policies of these third parties prior to providing them with your personal information.
                        </p>
                            <h4>How you can access or correct your personal information</h4>
                            <p>
                                You can request access to the personal information we hold about you by contacting our
                                Privacy Officer using the details provided below. Within about 7 business days of receiving
                                your request, we will let you know if we need additional information to process your request
                                and how long we'll take.
                        </p>
                            <p>
                                You can expect to have access to the information within 30 days of providing us with the
                                information we need to find your personal information. Before we make your personal
                                information available to you, we'll need to verify and confirm your identity. If someone
                                makes the request on your behalf, we will require your prior written consent or we will
                                contact you directly to collect your consent.
                        </p>
                            <p>
                                In some circumstances we may refuse to give you access to some or all information you have
                                requested. For example, where we have been unable to verify and confirm your identity, if
                                providing access would have an unreasonable impact on the privacy of others; or giving
                                access would reveal evaluative information in connection with a commercially sensitive
                                decision-making process.
                        </p>
                            <p>
                                If we deny or restrict access to the information you have requested, we will give you written
                                reasons for this decision when we respond to your request.
                        </p>
                            <p>
                                In most cases, access to your personal information will be provided free of charge. There may
                                be circumstances where, because of the nature of your request, we might need to charge a fee
                                to cover the time we'll need to spend collating your personal information or putting it in a
                                format that we're able to send it to you (whether at your request or to maintain the security of
                                the information). We will let you know before we start working on your request so that you
                                can make an informed decision.
                        </p>
                            <h4>How you can make a privacy complaint</h4>
                            <p>
                                Please contact our Privacy Officer using the details provided below if you have any concerns
                                or complaints about the manner in which we have collected or handled your personal
                                information. We will investigate your complaint and you can expect a response from us in
                                writing within 30 days. If after our initial assessment of your complaint we decide that we
                                need more information or that we'll need more time to respond to you, we'll let you know
                                within a few days of receiving your complaint.
                        </p>
                            <h4>How to contact us</h4>
                            <h6>If you:</h6>
                            <ul>
                                <li>
                                    have queries, concerns or complaints about the manner in which your personal
                                    information has been collected or handled by iNSHORA Cover;
                            </li>
                                <li>
                                    would like to request a hard copy of this privacy policy or discuss any issues relating
                                    to this policy or privacy matters generally; or
                            </li>
                                <li>
                                    would like to request access to or correction of the personal information we hold
                                    about you;
                            </li>
                            </ul>
                            <h5>Please contact our Privacy Officer:</h5>
                            <address>
                                <h6>Neetu Jha</h6>
                                <p>
                                    788 Ground Floor, Pocket-2,
                                    Phase-2, Sector-14, Dwarka,
                                    New Delhi-110078
                            </p>
                                <h6>Call: 011 40 45 6060</h6>
                            </address>

                        </div>
                    </div>
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};
export default TravelInsurance