import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <React.Fragment>
          <div class="bottom-nav">
              <footer class="container">
                  <div class="row" style={{ borderBottom:"1px dotted #6c757d", paddingBottom:"25px", paddingTop:"10px" }}>
                      <div class="col-6 col-md">
                          <h5>MOTOR INSURANCE</h5>
                          <ul class="list-unstyled text-small">
                              <li><a class="text-muted" href="#">Private Car Insurance</a></li>
                              <li><a class="text-muted" href="#">Two Wheeler Insurance</a></li>
                              <li><a class="text-muted" href="#">Goods Carrying Vehicle</a></li>
                              <li><a class="text-muted" href="#">Passenger Carrying Vehicle</a></li>
                          </ul>
                      </div>
                      <div class="col-6 col-md">
                          <h5>HEALTH INSURANCE</h5>
                          <ul class="list-unstyled text-small">
                              <li><a class="text-muted" href="#">Mediclaim Policy</a></li>
                              <li><a class="text-muted" href="#">Senior Citizen Insurance</a></li>
                              <li><a class="text-muted" href="#">Family Insurance</a></li>
                              <li><a class="text-muted" href="#">Parents Insurance</a></li>
                          </ul>
                      </div>
                      <div class="col-6 col-md">
                          <h5>LIFE INSURANCE</h5>
                          <ul class="list-unstyled text-small">
                              <li><a class="text-muted" href="#">Term Plans</a></li>
                              <li><a class="text-muted" href="#">Money Back Policy</a></li>
                              <li><a class="text-muted" href="#">Pension Plans</a></li>
                              <li><a class="text-muted" href="#">Type of Life Insurance</a></li>
                          </ul>
                      </div>
                      <div class="col-6 col-md">
                          <h5>OTHER INSURANCE</h5>
                          <ul class="list-unstyled text-small">
                              <li><a class="text-muted" href="#">Private Car Insurance</a></li>
                              <li><a class="text-muted" href="#">Two Wheeler Insurance</a></li>
                              <li><a class="text-muted" href="#">Taxi Insurance</a></li>
                              <li><a class="text-muted" href="#">School Bus Insurance</a></li>
                          </ul>
                      </div>
                      <div class="col-6 col-md">
                          <h5>REACH US</h5>
                          <ul class="list-unstyled text-small">
                              <li><span class="text-muted">Corporate Office Address</span></li>
                              <li><span class="text-muted">6-3-1112, First Floor,</span></li>
                              <li><span class="text-muted"> AVR Towers, Somajiguda,</span></li>
                              <li><span class="text-muted">Hyderabad � 500016 , Telangana.</span></li>
                          </ul>
                      </div>
                  </div>
                  <div class="row" style={{paddingTop: "25px"}}>
                      <div class="col col-9" style={{ color: "#6c757d" }}>
                          <p style={{ fontSize: "0.8em" }}>
                              Providence India Insurance Broking Pvt Ltd LICENSE NO: IRDAI/542,  |  Valid Till: 15/Oct/2021,<br />
                    CIN: U67190TG2015PTC097235, Principal Officer: K.S.MURARY. (Contact: +91 - 8106602266)
                </p>
                      </div>
                      <div class="col-3" style={{ textAlign: "right"}}> <a href="#" class="fab fa-facebook"></a> <a href="#" class="fab fa-twitter"></a> <a href="#" class="fab fa-youtube"></a> </div>
                  </div>
              </footer>
          </div>
          
        <Bottom />
     
    </React.Fragment>
  );
};
export default Footer;
