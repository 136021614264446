let DummyCardData=[
    {
        planName:"iSelect Term Plan",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/r7qJ7vp/Canera-Hsbc-Obc.png"
    },
    {
        planName:"iTerm Insurance Plan-Life Protect",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/ZXrmq05/Aegon.png"
    },
    {
        planName:"Flexi Online Term",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/jrZkV4P/Future.png"
    },
    {
        planName:"Flexi e-Term",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/CB94MGj/primica.png"
    },
    {
        planName:"Kotak e-Term Plan Life Option",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/44kgJsp/Kotak.png"
    },
    {
        planName:"iProtectSmart",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/4jcfmRF/IciciPru.png"
    },
]
export default DummyCardData;

const DummyCardDataForRFL=[
    {
        planName:"iProtectSmart",
        coverValue:"10000000",
        claimRatio:"98",
        logo:"https://i.ibb.co/4jcfmRF/IciciPru.png"
    },
]
export {DummyCardDataForRFL}