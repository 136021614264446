/* eslint-disable */ 
import React, { useState } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { NewCar } from "../Car/NewCar";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import MaskedInput from 'react-text-mask'
import ButtonClass from "../ButtonClass";
class Bike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors:{},
      field:{},
      isValid:true,
      registration:null,
      }
      this.handle=this.handle.bind(this)
      this.submit=this.submit.bind(this)
  }
  handle(e){
    let errors={}
    let isValid=true;
   let field=this.state.field;
    field[e.target.name]=e.target.value;
    this.setState({field,[e.target.name]:e.target.value})
    if(this.state.field["registration"]===undefined){
      isValid=false;
      errors["registration"]="Please Enter Number Correct Number"
    }
    else if(this.state.field["registration"]!==undefined){
      if(this.state.field["registration"].length<10){
        isValid=false;
      }
    }
    this.setState({errors , isValid})
  }
  submit(){
    let regPattern=RegExp(/^[A-Za-z]{2}[0-9]{1,2}[-][A-Z]{2,3}[0-9]{4,5}$/)
    if(this.state.field["registration"]===undefined || this.state.field["registration"]==="" )
    return alert("Enter Registration Number")
    else if(!regPattern.test(this.state.field["registration"].toUpperCase()))
    return alert("Please Provide Correct Value")
    
  }
  
  render() { 
  return (
    <div className="container-fluid ">
      <div className="row d-flex justify-content-center pt-3">
        <div className="col-sm-12 productTabsMainCar">
          <h3 className="heading text-center">
            Find better plans for your
            <strong> Two Wheeler Insurance</strong>
          </h3>
          <h6 className="text-center">
            <p>Get best offers with attractive premium</p>
          </h6>
          <div className="ProductFormIn ProductForm01">
                                        <div className="PF0012 SpaceBetweenRow002">
                                            <div className="row">
                                                <div className="col-md-7 NoPaddingRight">
                                                    <label>
                                                    <MaskedInput name="registration" onChange={this.handle} mask={[/[A-Za-z]/,/[A-Za-z]/, /\d/, /\d/,'-', /[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, /\d/, /\d/,/\d/]} guide={false} showMask={false} placeholder="Enter Bike Registration Number" name="registration" style={{textTransform:"uppercase"}} />
   </label>
                                                    </div>
                                                <div className="col-md-5">
                                                    <label>
                                                      {  
                                                        this.state.isValid? <Link to="/Renew?type=0">
                                                    <button className={ButtonClass()} id="quotesBtn" onClick={this.submit}>VIEW QUOTES</button> </Link>
  : <button className="QouteMNBtnrisk diablebtn" id="quotesBtn" >VIEW QUOTES</button> 
}
  </label> </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-7 mt-3">
                                                   
                                                    <Link
                                                   
                                                    className="headingBt"
                                                    to="/Renew?type=0"
                                                  >
                                                   
                                                      Don't know your bike number? <b>Click here</b>
                                                  
                                                  </Link> 
                                                </div>
                                                <div className="col-md-5 mt-3">
                                                   
                                                    <Link
                                                   
                                                    className="headingBt"
                                                    to="/New?type=0"
                                                  >
                                                   
                                                      Bought a new bike? <b>Click here</b>
                                                   
                                                  </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
         </div>
      </div>
      {/*<div
      //   className="packagesMainCar ml-5"
      //   style={{ width: "70%", marginLeft: "30px" }}
      // >
      //   <div className="productTabsMainCar">
      //     <h3 className="packageHeading bp">
      //       We help you find better plans for your
      //       <strong>Bike Insurance</strong>
      //     </h3>
      //     <div className="ProductFormIn">
      //       <label>
      //         <input
      //           type="text"
      //           placeholder="Enter Bike Registration Number"
      //           className="packageForm"
      //         />
      //         <button className="QouteMNBtn">VIEW QUOTES</button>
      //       </label>
      //       <label>
      //         <NavbarBrand tag={Link} to="/Renew?type=0">
      //           Don't know your bike number? <b>Click here</b>
      //         </NavbarBrand>
      //       </label>
      //       <label id="New">
      //         <NavbarBrand tag={Link} to="/New">
      //           Bought a new bike? <b>Click here</b>
      //         </NavbarBrand>
      //       </label>
      //     </div>
      //   </div>
      // </div>*/}
    </div>
  );}
};
export { Bike };
