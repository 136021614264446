/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask'
import { Tab, Accordion, Card, Nav } from 'react-bootstrap';
import ButtonClass from '../ButtonClass';
import SecondAddress from './SecondAddress';
import axios from 'axios';
import IsCorporatePopuo from './IsCorporatePopup';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Progress } from 'reactstrap';
import ProgressBarBox from '../components/ProgressBarBox';
export class Proposal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Req: [],
            Model: null,
            IsPersonalDetail: false,
            IsVehicleDetail: false,
            IsAddressDetail: false,
            IsCarOwner: true,
            Owner: false,
            OwnerVal: null,
            OwnerName: null,
            GSTNum: null,
            OwnerEmail: null,
            OwnerMobileNo: null,
            PGender: true,
            PGenderval: 1,
            PMarital: false,
            PDOB: null,
            PNomineeName: null,
            PNomineeRelation: null,
            PNomineeAge: null,
            PNomineeOccupation: null,
            PVehicleFinance: null,
            VehicleIsFinance: false,
            VEngine: null,
            VChasis: null,
            VRegistration: null,
            VRegistrationDate: null,
            VPreviosInsurer: null,
            VPreviosPolicyNo: null,
            POccupations: [],
            PFinances: [],
            FilterFinancerList: [],
            PRelationShips: [],
            VPreviosInsurerList: [],
            MasterList: [],
            AddressLine1: null,
            AddressLine2: null,
            AddressLine3: null,
            State: null,
            StateName: "Select State",
            stateList: [],
            filterStateList: [],
            City: null,
            CityName: "Select City",
            cityList: [],
            filterCityList: [],
            Financer: "Select Financer",
            Index: 0,
            ADPinCode: null,
            IsMessage: false,
            IsProceePropsal: false,
            IsCorporatePopup: false,
            OldPremium: 0,
            ProposalBtnVal: "Review and do Payment",
            MessageBody: null,
            APIUrl: null,
            TPPolicyNumber: null,
            TPPolicyInsurer: null,
            TPPolicyStartDate: null,
            TPPolicyEndDate: null,
            isPUC: "YES",
            pucvalidUpto: null,
            pucnumber: null,
            IsChangeAddress: false,
            PerCityID: "",
            PerStateID: "",
            PerAddressLine1: "",
            PerAddressLine2: "",
            PerAddressLine3: "",
            PerCityName: "",
            PerPinCode: "",
            PerStateName: "",
            key1: "0",
            key2: "",
            key3: "",
            key4: "",
            openAcc: "0",
            p1: false,
            p2: false,
            p3: false,
            fields: {},
            errors1: {},
            errors2: {},
            errors3: {},
            errors4: {},
            isValid1: false,
            isValid2: false,
            isValid3: false,
            isValid4: false,
            errors: {},
            date: null,
            crd: null,
            IsChangeAddress: false,
            perCityList: [],
            Salutation: null,
            TPStartdate: null,
            TPEnddate: null,
            TPmaxEndDate: null,
            showStateList: false,
            showCityList: false,
            showFinancerList: false,
            PrevPolicyExp: null,
            PreviousInsurerAddModel: "",
            GotoQuote: true,
            AddonsList: false
        };

        this.state.APIUrl = window.localStorage.getItem("portal");
        //this.state.CRMAPI=window.localStorage.getItem("crm")

        let prm = new URLSearchParams(window.location.search);
        if (prm.get("IsEdit") === "true") {
            let data = JSON.parse(window.sessionStorage.getItem("MotorproposalForm"));
            this.state.Model = data;
            this.state.buyObj = data.buyObj;
            this.state.OldPremium = data.buyObj.TotalPremium
            this.state.VRegistration = !data.QuoteReq.IsReNew ? "NEW0001" : null;
            this.state.GotoQuote = false;
        }
        else {
            this.state.Model = this.props.location.state.Model;
            this.state.buyObj = this.props.location.state.Model.buyObj;
            this.state.OldPremium = this.props.location.state.Model.buyObj.TotalPremium;
            this.state.VRegistration = !this.props.location.state.Model.QuoteReq.IsReNew ? "NEW0001" : null
        }
        console.log(this.state.Model)
        this.getRtoState()
        // this.setState({
        //     Model: this.props.location.state.Model,
        //     APIUrl: this.state.APIUrl
        // });
        this.handle = this.handle.bind(this)

        this.goBack = this.goBack.bind(this);
        this.NextStep = this.NextStep.bind(this)
        this.eighteenYearBack = this.eighteenYearBack.bind(this);
        this.searchDown = this.searchDown.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.manageSearch = this.manageSearch.bind(this);
        this.manageClick = this.manageClick.bind(this);
        this.eighteenYearBack()




    }
    specialCharacterRemover(add) {
        if (add === null || add === "")
            return add
        return add.replace(/[^a-zA-Z0-9]/g, ' ');
    }
    async componentDidMount() {
        let vahanInfo = window.sessionStorage.getItem("VahanInfo");
        if (vahanInfo !== null) {
            vahanInfo = JSON.parse(vahanInfo);
            /* let add = vahanInfo.Per_Address;
             let l = add.length;
            // let d = l / 29;
             let ini = 0;*/

            this.setState({
                VEngine: vahanInfo.EngineNo,
                VChasis: vahanInfo.ChasisNo,
                VRegistration: vahanInfo.VehicleNo,
                VPreviosPolicyNo: vahanInfo.Prv_PolicyNo,
                OwnerName: vahanInfo.OwnerName,

            })
        }

        await axios.get("https://centralapi.stariglocal.com/api/RTO/bindstate?companytype=" + this.state.Model.buyObj.CompanyID + "&stateid=")
            .then(data => {
                data = data.data;
                this.state.stateList = data;
                this.state.filterStateList = data;
                this.setState({ stateList: data, filterStateList: data })
            })
        let data = JSON.parse(window.sessionStorage.getItem("MotorproposalForm"));
        let CompanyId = window.sessionStorage.getItem("CompID");
        if (data !== null) {


            data = data.ProposalReq;

            this.state.Owner = data.IsCorporate;
            this.state.OwnerName = data.FirstName + " " + data.LastName;
            this.state.GSTNum = data.GST_Number;
            this.state.OwnerEmail = data.Email;
            this.state.OwnerMobileNo = data.Mobile;
            this.state.PGender = data.GenderID;
            this.state.PGenderval = data.GenderID;
            this.state.PMarital = data.MaritalStatus == "N" ? false : true;
            this.state.PDOB = data.DOB.replaceAll("/", "-");
            this.state.PNomineeName = data.NomineeName;
            this.state.PNomineeAge = data.NomineeAge;
            this.state.VehicleIsFinance = data.VehicleIsFinance == "YES" ? true : false;
            this.state.VEngine = data.EngineNo;
            this.state.VChasis = data.ChassisNo;
            this.state.VRegistration = data.VehicleNo;
            this.state.VPreviosPolicyNo = data.PreviousPolicyNumber;
            this.state.TPPolicyInsurer = data.TPPolicyInsurer;
            this.state.TPPolicyNumber = data.TPPolicyNumber;
            this.state.TPPolicyStartDate = data.TPPolicyStartDate;
            this.state.TPPolicyEndDate = data.TPPolicyEndDate;
            this.state.isPUC = data.isPUC;
            this.state.pucnumber = data.pucnumber;
            this.state.pucvalidUpto = data.pucvalidUpto;
            this.state.AddressLine1 = data.AddressLine1;
            this.state.AddressLine2 = data.AddressLine2;
            this.state.AddressLine3 = data.AddressLine3;
            this.state.ADPinCode = data.PinCode;
            this.state.PerAddressLine1 = data.PerAddressLine1;
            this.state.PerAddressLine2 = data.PerAddressLine2;
            this.state.PerAddressLine3 = data.PerAddressLine3;
            this.state.PerStateID = data.PerStateID;
            this.state.PerCityID = data.PerCityID;
            this.state.PerPinCode = data.PerPinCode;
            this.setState({ isValid1: true, isValid2: this.state.Owner ? true : this.state.isValid2 });
            if (data.buyObj !== undefined && data.buyObj.CompanyID == CompanyId) {
                this.state.PNomineeRelation = data.Relationship;
                this.state.PNomineeOccupation = data.Occupation;
                this.state.PVehicleFinance = data.FinancerVal;
                this.state.State = data.StateID;
                this.state.City = data.CityID;
                this.state.StateName = data.StateName;
                this.state.CityName = data.CityName;
                this.findCityList(data.StateID);
                this.state.VPreviosInsurer = this.state.Model.QuoteReq.IsReNew ? data.PrvInsurerCompanyId.split("~")[0] : "";
                this.setState({ isValid1: true, isValid2: true, isValid3: true, isValid4: true });

            }
        }

        this.setState({ Owner: this.state.Model.QuoteReq.Organisation });
        let date;
        if (this.state.Model.QuoteReq.MyPolicyexpired) {
            date = this.prevPolicyDateFormatter(this.state.Model.QuoteReq.PreviousPolicyExpiredDate);

        } else {

            date = this.dateFormatter(this.state.Model.QuoteReq.PreviousPolicyExpiredDate, "-", "-", "dd/mm/yy", "st");
        }
        this.state.PrevPolicyExp = date;

    }
    eighteenYearBack() {
        let today = new Date()
        let backdate = today.getFullYear() - 18;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate()
        let mm = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1
        let oldDate = backdate + "-" + mm + "-" + dd;
        let currentDate = today.getFullYear() + "-" + mm + "-" + dd;

        this.state.date = oldDate
        this.state.crd = currentDate;
        this.state.pucvalidUpto = currentDate;
        let tpBackDate = (today.getFullYear() - 5) + "-" + mm + "-" + parseInt(dd + 1);
        this.state.TPStartdate = tpBackDate;
        this.state.TPEnddate = (today.getFullYear() + 1) + "-" + mm + "-" + dd;
        this.state.TPmaxEndDate = (today.getFullYear() + 5) + "-" + mm + "-" + dd;
    }
    dateFormatter(date, devider, splt, formate, type) {
        let element = date.split(splt);
        let dd = element[2];
        let mm = element[1];
        let yy = element[0];
        let newDate;
        let dateFormate = formate;

        if (type == "st") {
            switch (parseInt(mm)) {
                case 1: mm = "Jan"
                    break;
                case 2: mm = "Feb"
                    break;
                case 3: mm = "March"
                    break;
                case 4: mm = "Apr"
                    break;
                case 5: mm = "May"
                    break;
                case 6: mm = "Jun"
                    break;
                case 7: mm = "Jul"
                    break;
                case 8: mm = "Aug"
                    break;
                case 9: mm = "Sep"
                    break;
                case 10: mm = "Oct"
                    break;
                case 11: mm = "Nov"
                    break;
                case 12: mm = "Dec"
                    break;
            }

        }
        switch (dateFormate) {
            case "dd/mm/yy": newDate = dd + devider + mm + devider + yy;
                break;
            case "mm/dd/yy": newDate = mm + devider + dd + devider + yy;
                break;
            case "yy/mm/dd": newDate = yy + devider + mm + devider + dd;
                break;
        }
        return newDate;
    }
    getRtoState() {
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetStateByRTO?Rto=" + this.state.Model.QuoteReq.RtoCode + " ")
            .then(data => {

                this.setState({ PerStateName: data.data })
            })
    }
    handle = (e) => {
        if (e.target.name != "Owner" || e.target.name != "PGender") {
            this.setState({ [e.target.name]: e.target.value })
        }
        switch (e.target.name) {
            case "Owner":
                let buyObj = this.state.buyObj;

                if (e.target.value === "Yes") {
                    this.state.Model.buyObj.IsCorporate = true;
                    this.state.Model.buyObj.ISPACOVERODSELECTED = "NO";
                    this.state.Model.buyObj.PACOVERYEAR = 0;
                    this.state.Model.buyObj.NetPremium = (buyObj.NetPremium - buyObj.PAOwnerCoverDriver);
                    this.state.Model.buyObj.serviceTax = (buyObj.serviceTax - buyObj.PAOwnerCoverDriver * 18 / 100);
                    this.state.Model.buyObj.TotalPremium = (buyObj.NetPremium + buyObj.serviceTax);

                } else {
                    this.state.Model.buyObj.IsCorporate = false;
                    this.state.Model.buyObj.ISPACOVERODSELECTED = buyObj.ISPACOVERODSELECTED;
                    this.state.Model.buyObj.PACOVERYEAR = buyObj.PACOVERYEAR;
                    this.state.Model.buyObj.NetPremium = buyObj.NetPremium;
                    this.state.Model.buyObj.serviceTax = buyObj.serviceTax;
                    this.state.Model.buyObj.TotalPremium = buyObj.TotalPremium;

                }
                this.setState({
                    OwnerVal: e.target.value,
                    [e.target.name]: !this.state.Owner,
                    IsCorporatePopup: true,
                })
                break;
            case "PGender":
                this.setState({
                    PGenderval: e.target.value,
                    [e.target.name]: !this.state.PGender
                })
                break;
            case "PMarital":
                this.setState({
                    [e.target.name]: !this.state.PMarital
                })
                break;
            case "VehicleIsFinance":

                this.state.VehicleIsFinance = !this.state.VehicleIsFinance ? true : false;


                this.setState({
                    [e.target.name]: this.state.VehicleIsFinance,

                })
                break;
            case "OwnerMobileNo":
                let ss = e.target.value;
                if (ss.length > 10)
                    this.setState({ [e.target.name]: ss.substring(0, 10) })
                break;
            case "PNomineeAge":
                let age = e.target.value;
                if (age.length > 3)
                    this.setState({ [e.target.name]: age.substring(0, 3) })
                break;

            case "State":

                let StateID = "";
                if (this.state.Model.buyObj.CompanyID == 107 || this.state.Model.buyObj.CompanyID == 103 || this.state.Model.buyObj.CompanyID == 105 || this.state.Model.buyObj.CompanyID == 100 || this.state.Model.buyObj.CompanyID == 108 || this.state.Model.buyObj.CompanyID == 118 || this.state.Model.buyObj.CompanyID == 106) {
                    StateID = e.target.value.split("~")[0];
                }
                else {
                    StateID = e.target.value;
                }
                axios.get("https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=" + this.state.Model.buyObj.CompanyID + "&StateID=" + StateID)
                    .then(data => {
                        this.setState({ cityList: data.data })

                    })
                let StateName = this.state.PerStateName;
                if (StateName === "CHHATTISGARH" && this.state.Model.buyObj.CompanyName == "HDFC") {
                    StateName = "CHATTISGARH";
                }
                if (StateName == "CHANDIGARH U.T.") {
                    StateName = "CHANDIGARH";
                }
                if (StateName === "DELHI" && this.state.Model.buyObj.CompanyName === "Sriram") {
                    StateName = "NEW DELHI";
                }
                if (StateName === "ODISHA" && (this.state.Model.buyObj.CompanyName === "Bharti" || this.state.Model.buyObj.CompanyID == 102)) {
                    StateName = "ORISSA";
                }
                if (StateName === "MAHARASHTRA" && this.state.Model.buyObj.CompanyName === "Kotak") {
                    StateName = "MAHARASTRA";
                }
                if (StateName === "UTTARAKHAND" && this.state.Model.buyObj.CompanyID === 107) {
                    StateName = "UTTRANCHAL";
                }
                let state = this.state.stateList.filter((row) => row.StateName == StateName)[0]
                let selected = this.state.stateList.filter((row) => row.CombindID == e.target.value)[0]
                if (state.StateName != selected.StateName) {

                    let PerStateID = this.state.PerStateID;
                    if (this.state.Model.buyObj.CompanyID == 107 || this.state.Model.buyObj.CompanyID == 103 || this.state.Model.buyObj.CompanyID == 100 || this.state.Model.buyObj.CompanyID == 108 || this.state.Model.buyObj.CompanyID == 118) {
                        PerStateID = PerStateID.split("~")[0];
                    }
                    else {
                        PerStateID = PerStateID;
                    }
                    axios.get("https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=" + this.state.Model.buyObj.CompanyID + "&StateID=" + PerStateID)
                        .then(data => {
                            this.setState({ perCityList: data.data })
                        })

                    this.setState({ IsChangeAddress: true })
                }
                else this.setState({ IsChangeAddress: false })
                break;
            case "PerCityID": let percityname = this.state.perCityList.filter((row) => row.CombindID == e.target.value)

                this.setState({ PerCityName: percityname[0].CityName })
        }
        let fields = this.state.fields;

        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        let isValid1 = true;
        let isValid2 = true;
        let isValid3 = true;
        let isValid4 = true;
        let gst = RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/);
        let errors1 = {};
        let errors2 = {};
        let errors3 = {};
        let errors4 = {};

        if (this.state.OwnerName === null || this.state.OwnerName === " ") {
            isValid1 = false;
            errors1["OwnerName"] = "Please Enter Owner Name"
        }
        if (this.state.Owner) {
            if (this.state.GSTNum === null) {
                isValid1 = false
                errors1["GSTNum"] = "Please Enter GST Number"
            } else if (this.state.GSTNum !== null) {
                if (gst.test(this.state.fields["GSTNum"])) {
                    isValid1 = false
                    errors1["GSTNum"] = "Enter correct GST Number"
                }
            }
        }
        if (this.state.OwnerEmail === null || this.state.OwnerEmail === " ") {
            isValid1 = false
            errors1["OwnerEmail"] = "Please Enter Email"
        } else if (this.state.OwnerEmail !== null) {
            if (!this.validateEmail(this.state.OwnerEmail)) {
                isValid1 = false
                errors1["OwnerEmail"] = "Enter correct Email"
            }
        }
        if (this.state.OwnerMobileNo === null || this.state.OwnerMobileNo === " ") {
            isValid1 = false
            errors1["OwnerMobileNo"] = "Please Enter Mobile Number"
        } else if (this.state.OwnerMobileNo !== null) {
            if (this.state.OwnerMobileNo.length != 10) {
                isValid1 = false
                errors1["OwnerMobileNo"] = "Enter correct Mobile Number"
            }
        }
        this.setState({ errors1, isValid1 })
        /*------------ Section 1--------------------*/

        if (!this.state.Owner) {
            if (this.state.PDOB === null) {
                isValid2 = false;
                errors2["PDOB"] = "Please Enter DOB"
            } else if (this.state.PDOB !== null) {
                let d1 = new Date();
                let d2 = new Date(this.state.PDOB);
                let diff = (d1.getTime() - d2.getTime()) / 1000;
                diff /= 60 * 60 * 24;
                let r = Math.abs(Math.round(diff / 365.25));
                if (r < 18) {
                    errors2["PDOB"] = "Only 18+ or 18 people";
                    isValid2 = false;
                }
            }
            if (this.state.PNomineeName === null || this.state.PNomineeName === "") {
                isValid2 = false;
                errors2["PNomineeName"] = "Enter Nominee Name"
            }
            if (this.state.PNomineeRelation === null || this.state.PNomineeRelation == "select") {
                isValid2 = false;
                errors2["PNomineeRelation"] = "Select Relation"
            }
            if (this.state.PNomineeAge === null || this.state.PNomineeAge === " ") {
                isValid2 = false;
                errors2["PNomineeAge"] = "Enter Age"
            } else if (this.state.PNomineeAge !== null) {
                if (this.state.PNomineeAge <= 17) {
                    isValid2 = false;
                    errors2["PNomineeAge"] = "only 18 or 18+ people"
                }
            }
            if (this.state.PNomineeOccupation === null || this.state.PNomineeOccupation == "select") {
                isValid2 = false;
                errors2["PNomineeOccupation"] = "Select Occupation"
            }
        }

        this.setState({ errors2, isValid2 })
        /* ------------------section 2 End----------------------*/

        if (this.state.VEngine === null || this.state.VEngine === "") {
            isValid3 = false;
            errors3["VEngine"] = "Please Enter Engine Number"
        } else if (this.state.VEngine !== null) {
            if (this.state.VEngine.length < 6) {
                isValid3 = false;
                errors3["VEngine"] = "Please Enter at least 6 characters"
            }
        }
        if (this.state.VChasis === null || this.state.VChasis === "") {
            isValid3 = false;
            errors3["VChasis"] = "Please Enter Chasis Number"
        } else if (this.state.VChasis !== null) {
            if (this.state.VChasis.length < 6) {
                isValid3 = false;
                errors3["VChasis"] = "Please Enter at least 6 characters"
            }
        }


        if (this.state.Model.QuoteReq.IsReNew) {
            if (this.state.VRegistration === null || this.state.VRegistration === "") {
                isValid3 = false;
                errors3["VRegistration"] = "Please Enter Registeration Number"
            } else if (this.state.VRegistration !== null) {
                if (this.state.VRegistration.length < 4) {
                    isValid3 = false;
                    errors3["VRegistration"] = "Please Enter Correct Registration Number"
                }
            }
            if (!this.state.Model.QuoteReq.IsPrevPolicyDetails) {
                if (this.state.VPreviosPolicyNo === null || this.state.VPreviosPolicyNo === "") {
                    isValid3 = false;
                    errors3["VPreviosPolicyNo"] = "Please Enter Policy Number"
                } else
                    if (this.state.VPreviosPolicyNo !== null) {
                        if (this.state.VPreviosPolicyNo.length < 6) {
                            isValid3 = false;
                            errors3["VPreviosPolicyNo"] = "Please Enter at least 6 characters"
                        }
                    }

                if (this.state.VPreviosInsurer === null || this.state.VPreviosInsurer === "select") {
                    isValid3 = false;
                    errors3["VPreviosInsurer"] = "Select Previous Insurer"
                }
            }
        }


        this.setState({ errors3, isValid3 })
        /*--------------------section 3 End---------------------- */
        if (this.state.AddressLine1 === null || this.state.AddressLine1 === "") {
            isValid4 = false;
            errors4["AddressLine1"] = "Enter Address";
        }
        if (this.state.AddressLine2 === null || this.state.AddressLine3 === "") {
            isValid4 = false;
            errors4["AddressLine2"] = "Enter Address";
        }
        // if (this.state.State == null || this.state.State === "select") {
        //     isValid4 = false;
        //     errors4["State"] = "Select State"
        // }
        // if (this.state.City === null || this.state.City === "select") {
        //     isValid4 = false;
        //     errors4["City"] = "Select City"
        // }
        if (this.state.ADPinCode === null || this.state.ADPinCode === " ") {
            isValid4 = false;
            errors4["ADPinCode"] = "Enter Pin Code"
        } else if (this.state.ADPinCode !== null) {
            if (this.state.ADPinCode.length < 6) {
                isValid4 = false;
                errors4["ADPinCode"] = "Enter Correct Pin Code"
            }
        }


        this.setState({ errors4, isValid4 })
        /* ------------------Section 4 End ----------------------------  */


    }
    manageTabSection(first, second) {
        let leftTabId = "left-tabs-example-tab-";

        let rightTabId = "left-tabs-example-tabpane-";
        try {
            document.getElementById(leftTabId + first).classList.remove("active");
            document.getElementById(leftTabId + second).classList.add("active");
            document.getElementById(rightTabId + first).classList.remove("active");
            document.getElementById(rightTabId + first).classList.remove("show");
            document.getElementById(rightTabId + second).classList.add("active");
            document.getElementById(rightTabId + second).classList.add("show");
        } catch (err) { }
    }
    selectTabSection(first, second) {
        document.querySelectorAll(".active").forEach((r) => r.classList.remove("active"));
        this.manageTabSection(first, second)
    }
    NextStep(Option) {

        switch (Option) {
            case "PersonalDetails":
                let oname = this.state.OwnerName.trim();
                if (!oname.includes(" ")) {
                    this.state.errors1["OwnerName"] = "Please Enter Full Name";
                    this.state.isValid1 = false;
                }
                if (this.state.isValid1) {

                    if (this.validateEmail(this.state.OwnerEmail) &&
                        this.state.OwnerMobileNo !== null &&
                        this.state.OwnerMobileNo.length == 10 && this.state.OwnerName !== null &&
                        this.state.OwnerName.length >= 4) {
                        let CompID = this.state.Model.buyObj.CompanyID;// == 102 ? 124 : this.state.Model.buyObj.CompanyID
                        let CompName = this.state.Model.buyObj.CompanyName;
                        axios.get("https://centralapi.stariglocal.com/api/CompanyOtherMaster/CompaniesOtherMasterList?CompanyID=" + CompID + "&IsRenewal=" + this.state.Model.QuoteReq.IsReNew)
                            .then(data => {
                                let RelationFilter = "NOMINEERELATIONSHIP";
                                if (CompID == 112 || CompID == 103 || CompID == 126 || CompID == 100
                                    || CompID == 109 || CompID == 108 || CompID == 101)
                                    RelationFilter = "RELATION"
                                data = data.data;
                                this.setState({
                                    POccupations: data.filter(row => row.MasterType == "OCCUPATION"),
                                    PRelationShips: data.filter(row => (row.MasterType == "NOMINEERELATIONSHIP" || row.MasterType == "RELATION") && row.CompanyID == CompID),
                                    MasterList: data
                                })
                                let fin = data.filter(row => row.MasterType == "FINANCER" && row.CompanyID == this.state.Model.buyObj.CompanyID)
                                if (this.state.Model.QuoteReq.Type === 0 && this.state.Model.buyObj.CompanyID === 103) {

                                    fin = data.filter(item => item.MasterType === (CompName.match("AHDFC") ? "FINANCER_AHDFC" : "FINANCER") && item.CompanyID === 103 && item.MasterID > 31345);
                                }
                                else {


                                    if (this.state.Model.QuoteReq.Type === 1 && this.state.Model.buyObj.CompanyID === 103) {

                                        fin = data.filter(item => item.MasterType === (CompName.match("AHDFC") ? "FINANCER_AHDFC" : "FINANCER") && item.CompanyID === 103 && item.MasterID < 50635);
                                    }
                                    else if (this.state.Model.buyObj.CompanyID === 102) {

                                        fin = data.filter(item => item.MasterType === "FINANCER" && item.CompanyID === 124);
                                    }
                                    else if (this.state.Model.buyObj.CompanyID === 111) {

                                        fin = data.filter(item => item.MasterType === "FINANCER" && item.CompanyID === 124);
                                    }
                                    else {
                                        fin = data.filter(item => item.MasterType === "FINANCER" && item.CompanyID === this.state.Model.buyObj.CompanyID);
                                    }
                                }
                                this.setState({
                                    PFinances: fin,
                                    FilterFinancerList: fin,
                                    Financer: this.state.PVehicleFinance === null ? "Select Financer" : fin.filter(row => row.MasterID == this.state.PVehicleFinance)[0].Name
                                })
                            })
                        this.manageTabSection("first", "second");

                        /*  let ownerDetails = document.getElementById("OwnerDetails");
                          ownerDetails.classList.remove("show");
                          let showPersonal = document.getElementById("PersonalDetails");
                          showPersonal.classList.add("show");*/



                        this.setState({
                            IsPersonalDetail: true,
                            IsVehicleDetail: false,
                            IsAddressDetail: false,
                            IsCarOwner: true,
                            key1: "0",
                            key2: "1",
                            openAcc: "1",
                            p1: true,

                        })

                    }
                }
                else {
                    this.setState({ errors: this.state.errors1 })

                } break;
            case "VehicleDetails":
                if (this.state.isValid2) {

                    if (!this.state.Owner) {

                        if (this.state.PNomineeName !== null && this.state.PNomineeName.length < 3 && this.state.PNomineeRelation === null
                            && this.state.PNomineeAge === null && this.state.PNomineeOccupation === null
                            && this.state.PDOB === null) {
                            return
                        }
                    }
                    if (this.state.VehicleIsFinance) {
                        if (this.state.PVehicleFinance === null || this.state.PVehicleFinance === "select") {

                            this.state.errors.PVehicleFinance = "Select Finance";
                            this.setState({ errors: this.state.errors })
                            return;
                        }
                    }
                    let PreviosInsurerList = null;
                    if (this.state.Model.buyObj.CompanyID == 103) {
                        if (this.state.Model.QuoteReq.Type == 1) { //PREVINSCOMP_AHDFC
                            PreviosInsurerList = this.state.MasterList.filter(row => row.MasterType == "PREVINSCOMP_CAR")
                        }
                        else {
                            PreviosInsurerList = this.state.MasterList.filter(row => row.MasterType == "PREVINSCOMP_TWO")
                        }
                        if (this.state.Model.buyObj.CompanyName === "AHDFC") {
                            PreviosInsurerList = this.state.MasterList.filter(row => row.MasterType == "PREVINSCOMP_AHDFC")
                        }
                    }
                    else {
                        PreviosInsurerList = this.state.MasterList.filter(row => row.MasterType == "PREVINSCOMP" && row.CompanyID == this.state.Model.buyObj.CompanyID)
                    }
                    //let ownerDetails = document.getElementById("PersonalDetails");
                    //ownerDetails.classList.remove("show");
                    //let showPersonal = document.getElementById("VehicleDetails");
                    //showPersonal.classList.add("show");
                    this.manageTabSection("second", "Third")
                    this.setState({
                        IsPersonalDetail: true,
                        IsVehicleDetail: true,
                        IsAddressDetail: false,
                        IsCarOwner: true,
                        VPreviosInsurerList: PreviosInsurerList,
                        key1: "0",
                        key2: "1",
                        key3: "2",
                        openAcc: "2",
                        p1: true,
                        p2: true

                    })

                } else {
                    this.setState({ errors: this.state.errors2 })


                }
                break;
            case "AddressDetails":
                if (this.state.Model.QuoteReq.odOnly) {
                    if (this.state.TPPolicyInsurer === null) {
                        this.state.isValid3 = false;
                        this.state.errors3["TPPolicyInsurerError"] = "Please Enter TP Insurer"
                    }
                    if (this.state.TPPolicyNumber === null) {
                        this.state.isValid3 = false;
                        this.state.errors3["TPPolicyNumberError"] = "Please Enter TP Policy Number"
                    } else if (this.state.TPPolicyNumber.length < 7) {
                        this.state.isValid3 = false;
                        this.state.errors3["TPPolicyNumberError"] = "Please Enter TP Policy Number"
                    }
                }
                if (this.state.isValid3) {
                    if (this.state.VEngine.length >= 5 && this.state.VChasis.length >= 5
                        && this.state.VRegistration.length >= 4
                    ) {
                        let StateName = this.state.PerStateName;
                        if (StateName === "CHHATTISGARH" && this.state.Model.buyObj.CompanyName == "HDFC") {
                            StateName = "CHATTISGARH";
                        }
                        if (StateName == "CHANDIGARH U.T.") {
                            StateName = "CHANDIGARH";
                        }
                        if (StateName === "DELHI" && (this.state.Model.buyObj.CompanyName === "Sriram" || this.state.Model.buyObj.CompanyID == 107)) {
                            StateName = "NEW DELHI";
                        }
                        if (StateName === "ODISHA" && (this.state.Model.buyObj.CompanyName === "Bharti" || this.state.Model.buyObj.CompanyID == 102)) {
                            StateName = "ORISSA";
                        }
                        if (StateName === "MAHARASHTRA" && this.state.Model.buyObj.CompanyName === "Kotak") {
                            StateName = "MAHARASTRA";
                        }
                        if (StateName === "UTTARAKHAND" && this.state.Model.buyObj.CompanyID === 107) {
                            StateName = "UTTRANCHAL";
                        }



                        let state = this.state.stateList.filter((row) => row.StateName.toLowerCase() == StateName.toLowerCase());


                        try {
                            this.setState({ PerStateID: state[0].CombindID })
                        }
                        catch (err) {
                            console.log(err);
                        }
                        this.manageTabSection("Third", "Four")
                        //let ownerDetails = document.getElementById("VehicleDetails");
                        //ownerDetails.classList.remove("show");
                        //let showPersonal = document.getElementById("Communication");
                        //showPersonal.classList.add("show");

                        this.setState({
                            IsPersonalDetail: true,
                            IsVehicleDetail: true,
                            IsAddressDetail: true,
                            IsCarOwner: true,
                            key1: "0",
                            key2: "1",
                            key3: "2",
                            key4: "3",
                            openAcc: "3",
                            p1: true,
                            p2: true,
                            p3: true,

                        })
                    }
                } else {
                    this.setState({ errors: this.state.errors3 })

                }
                break;
            case "Payment":
                if (this.state.State === null) {
                    this.state.isValid4 = false;
                    this.state.errors4["State"] = "Select State Name";
                }
                if (this.state.City === null) {
                    this.state.isValid4 = false;
                    this.state.errors4["City"] = "Select City Name";
                }
                if (this.state.isValid4) {

                    if (this.state.AddressLine1.length >= 1 && this.state.AddressLine2.length >= 1
                        && this.state.State != null
                        && this.state.City != null && this.state.ADPinCode.length >= 6) {
                        this.state.IsPersonalDetail = true;
                        this.state.IsVehicleDetail = true;
                        this.state.IsAddressDetail = true;
                        this.state.IsCarOwner = true;
                        this.setState({
                            IsPersonalDetail: true,
                            IsVehicleDetail: true,
                            IsAddressDetail: true,
                            IsCarOwner: true,
                            key1: "",
                            key2: "",
                            key3: "",
                            key4: "3",

                        })
                        this.CallProposal();

                    }
                }
                else {

                    this.setState({ errors: this.state.errors4 })








                }
                break;
        }

    }
    prevPolicyDateFormatter(date) {
        console.log(date);
        let newDate
        if (date != null || date != undefined) {
            let s = date.split("-");
            let mm;

            switch (parseInt(s[0])) {
                case 1: mm = "Jan"
                    break;
                case 2: mm = "Feb"
                    break;
                case 3: mm = "March"
                    break;
                case 4: mm = "Apr"
                    break;
                case 5: mm = "May"
                    break;
                case 6: mm = "Jun"
                    break;
                case 7: mm = "Jul"
                    break;
                case 8: mm = "Aug"
                    break;
                case 9: mm = "Sep"
                    break;
                case 10: mm = "Oct"
                    break;
                case 11: mm = "Nov"
                    break;
                case 12: mm = "Dec"
                    break;
            }


            newDate = s[1] + "-" + mm + "-" + s[2];
            console.log(newDate)
            return newDate

        } else return date
    }
    CallProposal() {
        if (this.state.Model.QuoteReq.EnquiryNo == "") {
            alert("some error occured, please try again");
            return;
        }
        if (this.state.IsChangeAddress) {
            if (this.state.PerAddressLine1 === "" || this.state.PerAddressLine1 === " " || this.state.PerAddressLine2 === "" || this.state.PerAddressLine2 === " " || this.state.PerPinCode === "" || this.state.PerPinCode === null || this.state.PerPinCode.length !== 6 || this.state.PerCityName === "") {
                return alert("Please Fill Registration Address")
            }

        }
        let dt = new Date();
        let prv = this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.VPreviosInsurer)[0];
        let Req = null;
        let finance = this.state.PFinances.filter(row => row.MasterID == this.state.PVehicleFinance)[0];
        let userToken = window.localStorage.getItem("token");
        if (userToken !== null) {
            if (userToken.charAt(0) === "{") {
                userToken = JSON.parse(userToken).token;
            }
        }
        let CompId = this.state.Model.buyObj.CompanyID;
        let manufacturerYear = new Date(this.state.Model.QuoteReq.ManufactureDate);
        let rtom = this.state.Model.QuoteReq.RtoCode;
        if (this.state.Model.buyObj.CompanyID === 107) {
            try {
                if (this.state.VRegistration.replace(/[^A-Za-z]/g, "").length > 2) {
                    if (rtom !== null && rtom.match("-")) {
                        rtom = rtom.split("-");
                        rtom = rtom[0] + "-" + parseInt(rtom[1]);
                    }
                }
            } catch (err) { }
        }
        if (this.state.Model.buyObj.CompanyID === 124) {
            Req = {
                //Addonse
                "chkPaidDriver": this.state.Model.QuoteReq.chkPaidDriver,
                "IDVLpgCng": this.state.Model.QuoteReq.IDVLpgCng,
                "Email": this.state.OwnerEmail,
                "PreviousNCB": this.state.Model.QuoteReq.IsReNew ? parseInt(this.state.Model.QuoteReq.BindPreviousNCB) : "0",
                "RegistrationDate": this.dateFormatter(this.state.Model.QuoteReq.RegistrationYear, "-", "-", "dd/mm/yy", "st"),
                "ChassisNo": this.state.Model.QuoteReq.Type < 2 ? this.state.VChasis : this.state.VChasis.substr(this.state.VChasis.length - 7),
                "chkDepreciation": this.state.Model.QuoteReq.chkDepreciation,
                "ISPACOVERODSELECTED": this.state.Model.QuoteReq.ISPACOVERODSELECTED,
                "ManufactureDate": this.dateFormatter(this.state.Model.QuoteReq.ManufactureDate, "-", "-", "dd/mm/yy", "st"),
                "NomineeDob": this.state.Owner ? "0001-01-01" : (parseInt(dt.getFullYear()) - parseInt(this.state.PNomineeAge)) + "-10-01",
                "PreviousPolicyNumber": !this.state.Model.QuoteReq.IsReNew ? "" : this.state.VPreviosPolicyNo,
                "RTO_CODE": this.state.Model.QuoteReq.RtoCode.replace("-", "") + "-" + this.state.VRegistration,
                "chkKeyReplacment": this.state.Model.QuoteReq.chkKeyReplacement + "",
                "PinCode": this.state.ADPinCode,
                "PassengerCoverSelectedIDV": this.state.Model.QuoteReq.PassengerCoverSelectedIDV,
                "CompanyName": this.state.Model.buyObj.CompanyName,
                "GenderID": parseInt(this.state.PGenderval),
                "chkReturnToInvoice": this.state.Model.QuoteReq.chkReturnToInvoice,
                "chkConsumables": this.state.Model.QuoteReq.chkConsumables,
                "DOB": this.state.Owner ? "0001-01-01" : this.dateFormatter(this.state.PDOB, "/", "-", "yy/mm/dd", ""),
                "IDVElectrical": this.state.Model.QuoteReq.IDVElectrical,
                "IDVNonElectrical": this.state.Model.QuoteReq.IDVNonElectrical,
                "ZeroDepth": this.state.Model.buyObj.ZeroDepreciationAmt,
                "Consumable": this.state.Model.buyObj.ConsumablesAmt,
                "IDV": this.state.Model.buyObj.IDV,
                "NomineeName": this.state.PNomineeName,
                "chkEngineProtector": this.state.Model.QuoteReq.chkEngineProtector,
                "EngineNo": this.state.Model.QuoteReq.Type < 2 ? this.state.VEngine : this.state.VEngine.substr(this.state.VEngine.length - 7),
                "TypeofMotor": this.state.Model.QuoteReq.Type,
                "VehicleCode": this.state.Model.buyObj.vehicleCode,
                "chkPassengerCover": this.state.Model.QuoteReq.chkPassengerCover,
                "FirstName": this.state.OwnerName.trim().split(" ")[0],
                "PreviousPolicyExpiredDate": (this.state.Model.QuoteReq.IsReNew) ? this.state.PrevPolicyExp : "",
                "CityName": this.state.cityList.filter(row => row.CombindID == this.state.City)[0].CityName,
                "CityID": this.state.City,
                "StateID": this.state.State,
                "TypeOfPolicy": this.state.Model.QuoteReq.TypeOfPolicy,
                "Mobile": this.state.OwnerMobileNo,
                "AddressLine3": this.state.AddressLine3,
                "VoluntaryAmount": this.state.Model.QuoteReq.VoluntaryAmount,
                "VoluntaryDiscountAmt": this.state.Model.buyObj.VoluntaryDiscountAmt,
                "AddressLine2": this.state.AddressLine2,
                "AddressLine1": this.state.AddressLine1,
                "NomineeRelationship": this.state.Owner ? "" : this.state.PRelationShips.filter(row => row.MasterID == this.state.PNomineeRelation)[0].Name.trim(),
                "NomineeRelation": this.state.Owner ? "" : this.state.PRelationShips.filter(row => row.MasterID == this.state.PNomineeRelation)[0].Code,
                "PastYearClaimID": this.state.Model.QuoteReq.PastYearClaimID,
                "IsAntiTheft": this.state.Model.QuoteReq.IsAntiTheft,
                "ClientURL": this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl,
                "ThirdPartyCover": this.state.Model.QuoteReq.ThirdPartyCover,
                "chkTPPDcover": this.state.Model.QuoteReq.chkTPPDcover,
                "LastName": this.state.OwnerName.replace(this.state.OwnerName.trim().split(" ")[0], "").trim(),
                "PrvInsurerCompanyId": this.state.Model.QuoteReq.IsReNew && !this.state.Model.QuoteReq.IsPrevPolicyDetails ? this.state.VPreviosInsurer + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.VPreviosInsurer)[0].Code : "" /*prv.MasterID + "~" + prv.Code*/,
                "NomineeAge": this.state.Owner ? "" : this.state.PNomineeAge,
                "OwnerProffessionCode": this.state.owner ? "" : this.state.PNomineeOccupation,
                "RtoModel": this.state.Model.QuoteReq.RtoCode,
                "MaritalStatus": this.state.PMarital ? "M" : "N",
                "Owner": this.state.Owner ? "YES" : "No",
                "chkKeyReplacement": this.state.Model.QuoteReq.chkKeyReplacement,
                "chkTyreSecure": this.state.Model.QuoteReq.chkTyreSecure,
                "chkRoadSideAssistance": this.state.Model.QuoteReq.chkRoadSideAssistance,
                "VehicleNo": this.state.Model.QuoteReq.IsReNew ? this.state.VRegistration : "NEW0001",
                "StateName": this.state.stateList.filter(row => row.CombindID == this.state.State)[0].StateName,
                AlreadyExpired: this.state.Model.buyObj.AlreadyExpired,
                "IsChangeAddress": this.state.IsChangeAddress,
                "PerCityID": this.state.IsChangeAddress ? this.state.PerCityID : "",
                "PerStateID": this.state.IsChangeAddress ? this.state.PerStateID : "",
                "PerAddressLine1": this.state.IsChangeAddress ? this.state.PerAddressLine1 : "",
                "PerAddressLine2": this.state.IsChangeAddress ? this.state.PerAddressLine2 : "",
                "PerAddressLine3": this.state.IsChangeAddress ? this.state.PerAddressLine3 : "",
                "PerCityName": this.state.IsChangeAddress ? this.state.PerCityName : "",
                "PerPinCode": this.state.IsChangeAddress ? this.state.PerPinCode : "",
                "PerStateName": this.state.IsChangeAddress ? this.state.PerStateName : "",
                "Period": this.state.Model.buyObj.Period,
                "CorrelationId": this.state.Model.QuoteReq.Type == 1 ? "PVT_CAR_PACKAGE_20201031161334" : "TWO_WHEELER_PACKAGE_2020121182421",
                "EnquiryNo": this.state.Model.QuoteReq.EnquiryNo,
                "PACOVERYEAR": this.state.Model.QuoteReq.PACOVERYEAR,
                "IsComprehensiveforThree_FiveYear": this.state.Model.buyObj.ComprehensiveforThree_FiveYear,
                "ComprehensiveforThree_FiveYear": this.state.Model.buyObj.ComprehensiveforThree_FiveYear,
                "IsCorporate": this.state.Owner,
                "contactpersonname": this.state.Owner ? this.state.OwnerName : "",
                "VehicleIsFinance": this.state.VehicleIsFinance ? "YES" : "NO",
                "GST_Number": this.state.Owner ? this.state.GSTNum : "",
                "Vehiclefinance": !this.state.VehicleIsFinance ? "0~0~0" : finance.MasterID + "~" + finance.Name + "~" + finance.Code,
                "PrevPolicyType": this.state.Model.QuoteReq.PrevPolicyType,
                "odOnly": this.state.Model.QuoteReq.odOnly,
                "PosUserID": 0,
                usertokenvalue: userToken === null ? "" : userToken,
                "TPPolicyStartDate": this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyStartDate === null ? this.state.crd.replaceAll("-", "/") : this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyStartDate !== null ? this.state.TPPolicyStartDate.replaceAll("-", "/") : this.state.TPPolicyStartDate,
                "TPPolicyEndDate": this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyEndDate === null ? this.state.TPEnddate.replaceAll("-", "/") : this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyEndDate !== null ? this.state.TPPolicyEndDate.replaceAll("-", "/") : this.state.TPPolicyEndDate,
                "TPPolicyNumber": this.state.TPPolicyNumber,
                "TPPolicyInsurer": this.state.TPPolicyInsurer == null ? null : this.state.TPPolicyInsurer + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.TPPolicyInsurer)[0].Code + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.TPPolicyInsurer)[0].Name,
                "isPUC": this.state.isPUC,
                "pucnumber": this.state.pucnumber,
                "pucvalidUpto": this.state.pucvalidUpto == null ? null : this.state.pucvalidUpto.replaceAll("-", "/"),
                "IsPrevPolicyDetails": this.state.Model.QuoteReq.IsPrevPolicyDetails,
                Relationship: this.state.PNomineeRelation,
                Occupation: this.state.PNomineeOccupation,
                FinancerVal: this.state.PVehicleFinance,

            };
        }
        else {


            Req = {
                "CompanyName": this.state.Model.buyObj.CompanyName,
                "RTO_CODE": rtom.replace("-", "") + "-" + this.state.VRegistration,
                "PreviousPolicyExpiredDate": (this.state.Model.QuoteReq.IsReNew) ? this.state.PrevPolicyExp : "",
                "chkEngineProtector": this.state.Model.QuoteReq.chkEngineProtector + "",
                "chkConsumables": this.state.Model.QuoteReq.chkConsumables + "",
                "chkReturnToInvoice": this.state.Model.QuoteReq.chkReturnToInvoice + "",
                "chkPaidDriver": this.state.Model.QuoteReq.chkPaidDriver + "",
                "chkPassengerCover": this.state.Model.QuoteReq.chkPassengerCover + "",
                "chkDepreciation": this.state.Model.QuoteReq.chkDepreciation + "",
                "chkKeyReplacement": this.state.Model.QuoteReq.chkKeyReplacement + "",
                "chkTyreSecure": this.state.Model.QuoteReq.chkTyreSecure + "",
                "chkRoadSideAssistance": this.state.Model.QuoteReq.chkRoadSideAssistance + "",
                "chkNCBProtection": this.state.Model.QuoteReq.chkNCBProtection + "",
                "chkPassangerAssistance": this.state.Model.QuoteReq.chkPassangerAssistance + "",
                "chkInconvenienceAllowance": this.state.Model.QuoteReq.chkInconvenienceAllowance + "",
                "TypeofMotor": parseInt(this.state.Model.QuoteReq.Type),
                "ClientURL": this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl,
                "VehicleNo": this.state.Model.QuoteReq.IsReNew ? this.state.VRegistration : "NEW0001",
                "PassengerCoverSelectedIDV": this.state.Model.QuoteReq.PassengerCoverSelectedIDV,
                "IsAntiTheft": this.state.Model.QuoteReq.IsAntiTheft + "",
                "PreviousPolicyStartDate": null,

                "ManufactureYear": manufacturerYear.getFullYear(),
                //"Antitheft": 0,
                "ElectricalIdv": this.state.Model.QuoteReq.IDVElectrical,
                "NonElectricalIdv": this.state.Model.QuoteReq.IDVNonElectrical,
                "CNGLPGIDV": null,
                "ManufacturerID": this.state.Model.QuoteReq.ManufacturerID,
                "VehicleID": this.state.Model.QuoteReq.VehicleID,
                "VariantID": this.state.Model.QuoteReq.VariantID,
                "RTOId": this.state.Model.QuoteReq.RtoID,
                "MaritalStatus": this.state.Owner ? "" : this.state.PMarital ? "M" : "N",
                "AutoMobileMembership": this.state.Model.QuoteReq.AutoMobileMembership,
                "AutoMobileMembershipDate": this.state.Model.QuoteReq.AutoMobileMembershipDate,
                "AutoMobileMembershipNumber": this.state.Model.QuoteReq.AutoMobileMembershipNumber,
                "AutoMobileMembershipName": this.state.Model.QuoteReq.AutoMobileMembershipName,
                "IsCNGLPG": this.state.Model.QuoteReq.IsCNG_LPG,
                "CurrentNCB": this.state.Model.buyObj.CurrentNCB,
                "BasicOD": this.state.Model.buyObj.BasicOD,
                "BasicTP": this.state.Model.buyObj.BasicTP,
                "ODDiscountLoading": -45,
                "OdDiscountAmt": this.state.Model.buyObj.ODDiscount,
                "TotalOD": this.state.Model.buyObj.TotalOD,
                GUID: null,
                "TotalTP": this.state.Model.buyObj.TotalTP,
                "PAOwnerDriver": this.state.Model.buyObj.PAOwnerCoverDriver,
                "PAtoPassenger": 0,
                "NCBAMOUNT": this.state.Model.buyObj.NCBAmount,
                "LLDriver": this.state.Model.buyObj.PAPaidDriver == null ? 0 : this.state.Model.buyObj.PAPaidDriver,
                "ServiceTax": this.state.Model.buyObj.serviceTax,
                "TotalPremium": this.state.Model.buyObj.TotalPremium,
                "NetPremium": this.state.Model.buyObj.NetPremium,
                "MinIDV": this.state.Model.buyObj.MinIDV,
                "MaxIDV": this.state.Model.buyObj.MaxIDV,
                "VoluntaryAmount": this.state.Model.QuoteReq.VoluntaryAmount,
                "VoluntaryDiscountAmt": this.state.Model.buyObj.VoluntaryDiscountAmt,
                "AutoDiscount": this.state.Model.buyObj.AutoDiscount,
                "BuiltCngIDV": this.state.Model.QuoteReq.IDVLpgCng == 0 ? null : this.state.Model.QuoteReq.IDVLpgCng,
                "ZeroDepth": this.state.Model.buyObj.ZeroDepreciationAmt,
                "Antitheft": this.state.Model.buyObj.Antitheft,
                "Consumable": this.state.Model.buyObj.ConsumablesAmt,
                "PassengerCoverAmt": this.state.Model.buyObj.PassengerCoverAmt,
                "TowingAndRelated": null,
                "CNGLPGOD": this.state.Model.buyObj.CNGLPGODAmt,
                "CNGLPGTP": this.state.Model.buyObj.CNGLPGTPAmt,
                "NomineeName": this.state.Owner ? "" : this.state.PNomineeName,
                "NomineeRelationship": this.state.Owner ? "" : this.state.PRelationShips.filter(row => row.MasterID == this.state.PNomineeRelation)[0].Name,
                "NomineeRelation": this.state.Owner ? "" : this.state.PRelationShips.filter(row => row.MasterID == this.state.PNomineeRelation)[0].Code,
                "CityID": this.state.City,
                "StateID": this.state.State,
                "OwnerProffessionCode": this.state.Owner ? "" : this.state.POccupations.filter(row => row.MasterID == this.state.PNomineeOccupation)[0].Code,
                "PrvInsurerCompanyName": !this.state.Model.QuoteReq.IsReNew || this.state.Model.QuoteReq.IsPrevPolicyDetails ? null : prv.Name,
                "ThirdPartyCover": this.state.Model.QuoteReq.ThirdPartyCover,
                "chkTPPDcover": this.state.Model.QuoteReq.chkTPPDcover,
                "ISPACOVERODSELECTED": this.state.Model.QuoteReq.ISPACOVERODSELECTED,
                "IDVElectrical": this.state.Model.QuoteReq.IDVElectrical,
                "IDVNonElectrical": this.state.Model.QuoteReq.IDVNonElectrical,
                "IDVLpgCng": this.state.Model.QuoteReq.IDVLpgCng,
                "TypeOfPolicy": this.state.Model.QuoteReq.TypeOfPolicy,
                "IDV": this.state.Model.buyObj.IDV,
                "ActualIDV": this.state.Model.buyObj.ActualIDV,
                "VehicleCode": this.state.Model.buyObj.vehicleCode,
                "ChassisNo": this.state.Model.QuoteReq.Type < 2 ? this.state.VChasis : this.state.VChasis.substr(this.state.VChasis.length - 7),
                "EngineNo": this.state.Model.QuoteReq.Type < 2 ? this.state.VEngine : this.state.VEngine.substr(this.state.VEngine.length - 7),
                "ManufactureDate": this.dateFormatter(this.state.Model.QuoteReq.ManufactureDate, "-", "-", "dd/mm/yy", "st"),
                "RegistrationDate": this.dateFormatter(this.state.Model.QuoteReq.RegistrationYear, "-", "-", "dd/mm/yy", "st"),
                "PrvInsurerCompanyId": this.state.Model.QuoteReq.IsReNew ? this.state.Model.QuoteReq.IsPrevPolicyDetails ? "" : this.state.VPreviosInsurer + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.VPreviosInsurer)[0].Code : "",
                "PreviousPolicyNumber": !this.state.Model.QuoteReq.IsReNew && this.state.Model.QuoteReq.IsPrevPolicyDetails ? "" : this.state.VPreviosPolicyNo,
                "PreviousInsurerAddModel": this.state.PreviousInsurerAddModel,
                "PastYearClaimID": this.state.Model.QuoteReq.PastYearClaimID,
                "PreviousNCB": this.state.Model.QuoteReq.IsReNew ? this.state.Model.buyObj.PreviousNCB : "0",
                "GenderID": parseInt(this.state.PGenderval),
                "FirstName": this.state.OwnerName.trim().split(" ")[0],
                "LastName": this.state.OwnerName.replace(this.state.OwnerName.trim().split(" ")[0], "").trim(),
                "DOB": this.state.Owner ? "0001-01-01" : this.dateFormatter(this.state.PDOB, "/", "-", "yy/mm/dd", ""),
                "Mobile": this.state.OwnerMobileNo,
                "Email": this.state.OwnerEmail,
                "AddressLine1": this.state.AddressLine1,
                "AddressLine2": this.state.AddressLine2,
                "AddressLine3": this.state.AddressLine3,
                "CityName": this.state.cityList.filter(row => row.CombindID == this.state.City)[0].CityName,
                "PinCode": this.state.ADPinCode,
                "PolicyStartDate": this.state.Model.buyObj.PolicyStartDate,
                "PolicyEndDate": this.state.Model.buyObj.PolicyEndDate,
                "ExShowRoomPrice": this.state.Model.buyObj.ExShowRoomPrice,
                "NomineeAge": this.state.Owner ? "" : this.state.PNomineeAge,
                "StateName": this.state.stateList.filter(row => row.CombindID == this.state.State)[0].StateName,
                "discount_perc": this.state.Model.buyObj.discount_perc === null ? "0" : this.state.Model.buyObj.discount_perc,
                "AlreadyExpired": this.state.Model.buyObj.AlreadyExpired,
                "IsChangeAddress": this.state.IsChangeAddress,
                "PerCityID": this.state.IsChangeAddress ? this.state.PerCityID : "",
                "PerStateID": this.state.IsChangeAddress ? this.state.PerStateID : "",
                "PerAddressLine1": this.state.IsChangeAddress ? this.state.PerAddressLine1 : "",
                "PerAddressLine2": this.state.IsChangeAddress ? this.state.PerAddressLine2 : "",
                "PerAddressLine3": this.state.IsChangeAddress ? this.state.PerAddressLine3 : "",
                "PerCityName": this.state.IsChangeAddress ? this.state.PerCityName : "",
                "PerPinCode": this.state.IsChangeAddress ? this.state.PerPinCode : "",
                "PerStateName": this.state.IsChangeAddress ? this.state.PerStateName : "",
                "OrderNo": this.state.Model.buyObj.OrderNo,
                "QuoteNo": this.state.Model.buyObj.QuoteNo,
                "NillperciationRate": this.state.Model.buyObj.NillperciationRate,
                "SecurePlusRate": this.state.Model.buyObj.SecurePlusRate,
                "SecurePremiumRate": this.state.Model.buyObj.SecurePremiumRate,
                "PACOVERYEAR": this.state.Model.buyObj.PACOVERYEAR,
                //"pp_covertype_name": this.state.Model.SelectedInsurer.pp_covertype_name,
                "pp_covertype_name": this.state.Model.QuoteReq.PrevPolicyType == "2" ? "Liability" : "Package",
                "pp_covertype_code": this.state.Model.QuoteReq.PrevPolicyType,
                pDetariffObj_inout: this.state.Model.buyObj.pDetariffObj_inout,
                "TransactionID": this.state.Model.buyObj.TransactionID,
                "CorrelationId": this.state.Model.buyObj.CorrelationId,
                Token: this.state.Model.buyObj.Token,
                "usertokenvalue": userToken === null ? "" : userToken,
                "ComprehensiveforThree_FiveYear": this.state.Model.buyObj.ComprehensiveforThree_FiveYear + "",
                "EnquiryNo": this.state.Model.buyObj.EnquiryNo,
                "IsPrevPolicyDetails": this.state.Model.buyObj.IsPrevPolicyDetails + "",
                "IsCorporate": this.state.Owner,
                "contactpersonname": this.state.Owner ? this.state.OwnerName : "",
                "VehicleIsFinance": this.state.VehicleIsFinance ? "YES" : "NO",
                "Vehiclefinance": !this.state.VehicleIsFinance ? "0~0~0" : finance.MasterID + "~" + finance.Name + "~" + finance.Code,
                "VehicleFinanceType": !this.state.VehicleIsFinance || this.state.Model.buyObj.CompanyName !== "Sundaram" ? "" : this.state.PVehicleFinance,
                VehicleFinanceCity: "",
                "UniqueRequestID": this.state.Model.buyObj.UNIQUEID,
                "RtoModel": rtom,
                "PosPolicyNo": this.state.Model.buyObj.PosPolicyNo,
                "PrevPolicyType": this.state.Model.QuoteReq.PrevPolicyType + "",
                "Period": this.state.Model.QuoteReq.Period,
                "odOnly": this.state.Model.QuoteReq.odOnly,
                "GST_Number": this.state.Owner ? this.state.GSTNum : "",
                "Owner": this.state.Owner ? "YES" : "No",
                "vEncryptedLogin": this.state.Model.buyObj.vEncryptedLogin,
                "vEncryptedPassword": this.state.Model.buyObj.vEncryptedPassword,
                "vRanKey": this.state.Model.buyObj.vRanKey,
                "PosUserID": 0,
                "TPPolicyStartDate": this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyStartDate === null ? this.state.crd.replaceAll("-", "/") : this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyStartDate !== null ? this.state.TPPolicyStartDate.replaceAll("-", "/") : this.state.TPPolicyStartDate,
                "TPPolicyEndDate": this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyEndDate === null ? this.state.TPEnddate.replaceAll("-", "/") : this.state.Model.QuoteReq.odOnly === true && this.state.TPPolicyEndDate !== null ? this.state.TPPolicyEndDate.replaceAll("-", "/") : this.state.TPPolicyEndDate,
                "TPPolicyNumber": this.state.TPPolicyNumber,
                "TPPolicyInsurer": this.state.TPPolicyInsurer == null ? null : this.state.TPPolicyInsurer + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.TPPolicyInsurer)[0].Code + "~" + this.state.VPreviosInsurerList.filter(row => row.MasterID == this.state.TPPolicyInsurer)[0].Name,
                "vWorkFlowID": this.state.Model.buyObj.vWorkFlowID,
                "CustomerID": this.state.Model.buyObj.CustomerID,
                "DetariffRate": this.state.Model.buyObj.DetariffRate,
                "customeridvpercentage": this.state.Model.buyObj.customeridvpercentage,
                "isPUC": this.state.isPUC,
                "pucnumber": this.state.pucnumber == null ? "" : this.state.pucnumber,
                "pucvalidUpto": (this.state.pucvalidUpto == null) ? null : this.state.pucvalidUpto.replaceAll("-", "/"),
                "ClaimAmount": this.state.Model.buyObj.ClaimAmount,
                Relationship: this.state.PNomineeRelation,
                Occupation: this.state.PNomineeOccupation,
                FinancerVal: this.state.PVehicleFinance

            }
        }
        window.sessionStorage.setItem("propReq", JSON.stringify(Req))
        this.state.Model.ProposalReq = Req;
        if (this.state.Model.buyObj.CompanyID === 105) {
            this.state.Model.SelectedInsurer.QuotationRequest = "";
            this.state.Model.buyObj.QuotationRequest = "";
        }
        this.setState({

            Model: this.state.Model,
            IsProceePropsal: true,
            ProposalBtnVal: "Please Wait..."
        });
        try {
            let apipath = this.state.APIUrl + "/api/api/InsurerMotor/CallPVCProposal";
            if (this.state.Model.QuoteReq.Type < 2) {
                apipath = this.state.APIUrl + "/api/api/InsurerMotor/CallPVCProposal";
            } else {
                apipath = this.state.APIUrl + "/commercial/api/api/InsurerCommercial/CallPVCProposal";
            }
            let Model = {
                URL: apipath,
                PostString: JSON.stringify(Req)
            }
            console.log(Req);
            window.sessionStorage.setItem("propreq", JSON.stringify(Req));
            try {
                window.sessionStorage.setItem("MotorproposalForm", JSON.stringify(this.state.Model));
            } catch (error) {

            }
            axios.post("/bpi/WeatherForecast/CallCheck", Model)
                .then(res => {
                    console.log(res.data);
                    res = res.data;
                    if (res.ErrorMessage === null && res !== "") {
                        /* this.state.Model.ProposalReq.CompModel = this.state.Model.CompModel;
                         this.state.Model.ProposalReq.QuoteReq = this.state.Model.QuoteReq;
                         this.state.Model.ProposalReq.buyObj = this.state.Model.buyObj;*/
                        window.sessionStorage.setItem("ProposalResponse", JSON.stringify(res));
                        this.state.Model.ProposalResponse = res;
                        // this.state.Model.ProposalReq.buyObj.QuotationRequest="";
                        //this.state.Model.ProposalReq.buyObj.QuotationResponse="";
                        //this.state.Model.ProposalResponse.ResponseData="";
                        //this.state.Model.ProposalReq.pDetariffObj_inout="";
                        //this.state.Model.ProposalReq.buyObj.pDetariffObj_inout="";
                        window.sessionStorage.setItem("MotorproposalForm", JSON.stringify(this.state.Model));
                        this.props.history.push("/ShowProposal", { CompleteModel: this.state.Model })

                    }
                    else {

                        this.setState({
                            IsPersonalDetail: true,
                            IsVehicleDetail: true,
                            IsAddressDetail: true,
                            IsMessage: true,
                            key1: "0",
                            key2: "1",
                            key3: "2",
                            key4: "3",
                            MessageBody: res == "" ? "Try Again" : res.ErrorMessage,
                            IsProceePropsal: false,
                            ProposalBtnVal: "Review and do Payment"
                        })
                    }
                })
                .catch((error) => {
                    axios.post("/bpi/WeatherForecast/CallCheck", Model)
                        .then(res => {
                            console.log(res.data)
                            res = res.data;
                            if (res.ErrorMessage == null && res !== "") {

                                window.sessionStorage.setItem("ProposalResponse", JSON.stringify(res));
                                this.state.Model.ProposalResponse = res;
                                this.state.Model.ProposalReq.buyObj.QuotationRequest = "";
                                this.state.Model.ProposalReq.buyObj.QuotationResponse = ""
                                this.state.Model.ProposalResponse.ResponseData = "";
                                this.state.Model.ProposalReq.pDetariffObj_inout = "";
                                this.state.Model.ProposalReq.buyObj.pDetariffObj_inout = "";
                                window.sessionStorage.setItem("MotorproposalForm", JSON.stringify(this.state.Model));
                                this.props.history.push("/ShowProposal", { CompleteModel: this.state.Model })

                            }
                            else {

                                this.setState({
                                    IsPersonalDetail: true,
                                    IsVehicleDetail: true,
                                    IsAddressDetail: true,
                                    IsMessage: true,
                                    key1: "0",
                                    key2: "1",
                                    key3: "2",
                                    key4: "3",
                                    MessageBody: res.ErrorMessage,
                                    IsProceePropsal: false,
                                    ProposalBtnVal: "Review and do Payment"
                                })
                            }
                        })

                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        }
        catch (ex) {
            this.setState({
                IsMessage: true,
                MessageBody: ex,
                IsProceePropsal: false,
                ProposalBtnVal: "Review and do Payment"
            });
        }

    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    CloseMessage = (e) => {
        this.setState({ IsMessage: !this.state.IsMessage })
    }
    AlertMessage(messageBody) {
        this.setState({
            IsMessage: !this.state.IsMessage,
            MessageBody: messageBody
        });
    }
    goBack() {
        this.props.history.goBack();
    }
    dobManage(data) {
        if (data !== null && data.includes("-")) {
            let element = data.split("-");
            let dd = element[2].substr(0, 2);
            let mm = element[1];
            let yy = element[0];

            return dd + "-" + mm + "-" + yy;
        }
        return data
    }
    searchFilter = (e) => {
        let id = e.target.id;
        let filterList = [];
        this.setState({ Index: 0 });
        switch (id) {
            case "searchState":
                filterList = this.state.stateList.filter((row) => row.StateName.toLowerCase().includes(document.getElementById("searchState").value.toLowerCase()))
                this.setState({ filterStateList: filterList })
                break;
            case "searchCity":
                filterList = this.state.cityList.filter((row) => row.CityName.toLowerCase().includes(document.getElementById("searchCity").value.toLowerCase()))
                this.setState({ filterCityList: filterList })
                break;
            case "searchFinancer":
                filterList = this.state.PFinances.filter((row) => row.Name.toLowerCase().includes(document.getElementById("searchFinancer").value.toLowerCase()))
                this.setState({ FilterFinancerList: filterList })
                break;
        }
    }
    searchDown = (e) => {
        let currenttxtID = e.target.id;
        let UlID = "";
        switch (currenttxtID) {
            case "searchState": UlID = "findStateVal";
                break;
            case "searchCity": UlID = "findCityVal";
                break;
            case "searchFinancer": UlID = "findFinancerVal";
                break;
        }
        try {
            let selectList = document.getElementById(UlID)
            let Options = selectList.getElementsByTagName("li");
            switch (e.keyCode) {
                case 40:
                    Options[this.state.Index].className = "activeopt";
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    this.state.Index += 1;
                    this.setState({ Index: this.state.Index });
                    break;
                case 38:
                    if (this.state.Index > 0)
                        this.state.Index -= 1;
                    Options[this.state.Index].className = "activeopt";
                    Options[this.state.Index + 1].removeAttribute("class");
                    this.setState({ Index: this.state.Index })
                    break;
                case 13:
                    this.setState({ Index: 0 });
                    let selectedOption = document.getElementsByClassName("activeopt");

                    let filterList = [];
                    let Text = "";
                    switch (currenttxtID) {
                        case "searchState":
                            filterList = this.state.stateList.filter(row => row.CombindID == selectedOption[0].id);
                            console.log(filterList)
                            if (filterList.length > 0) {
                                this.state.State = selectedOption[0].id;
                                this.setState({ State: selectedOption[0].id, StateName: filterList[0].StateName, showStateList: false });

                                //**************Binding City****************** */
                                this.findCityList(selectedOption[0].id);
                                //**************End */
                            }

                            break;
                        case "searchCity":
                            filterList = this.state.cityList.filter(row => row.CombindID == selectedOption[0].id);
                            if (filterList.length > 0) {
                                this.state.City = selectedOption[0].id;
                                this.setState({ City: selectedOption[0].id, CityName: filterList[0].CityName, showCityList: false });
                            }
                            break;
                        case "searchFinancer":
                            filterList = this.state.PFinances.filter(row => row.MasterID == selectedOption[0].id);
                            if (filterList.length > 0) {
                                this.state.PVehicleFinance = selectedOption[0].id;
                                this.setState({ PVehicleFinance: selectedOption[0].id, Financer: filterList[0].Name, showFinancerList: false })
                            }
                            break;
                    }
            }
        }
        catch (er) {
            console.log(er)
        }
    }
    manageClick(id, val) {
        let filterList = [];
        this.setState({ [id]: val });
        switch (id) {
            case "State":
                filterList = this.state.stateList.filter(row => row.CombindID == val)[0];
                this.setState({ StateName: filterList.StateName, showStateList: !this.state.showStateList });
                this.setState({ showStateList: this.state.showStateList });
                this.findCityList(val);

                break;
            case "City": filterList = this.state.cityList.filter(row => row.CombindID == val)[0];
                this.setState({ CityName: filterList.CityName, showCityList: false });
                this.setState({ showCityList: this.state.showCityList })
                break;
            case "PVehicleFinance":
                filterList = this.state.PFinances.filter(row => row.MasterID == val)[0];
                this.setState({ Financer: filterList.Name, showFinancerList: false });
                this.setState({ showFinancerList: this.state.showFinancerList });
                break;
        }
    }
    findCityList(stateVal) {
        let StateName = this.state.PerStateName;
        if (StateName === "CHHATTISGARH" && this.state.Model.buyObj.CompanyName == "HDFC") {
            StateName = "CHATTISGARH";
        }
        if (StateName == "CHANDIGARH U.T.") {
            StateName = "CHANDIGARH";
        }
        if (StateName === "DELHI" && (this.state.Model.buyObj.CompanyName === "Sriram" || this.state.Model.buyObj.CompanyID == 107)) {
            StateName = "NEW DELHI";
        }
        if (StateName === "ODISHA" && (this.state.Model.buyObj.CompanyName === "Bharti" || this.state.Model.buyObj.CompanyID == 102)) {
            StateName = "ORISSA";
        }
        if (StateName === "MAHARASHTRA" && (this.state.Model.buyObj.CompanyName === "Kotak")) {
            StateName = "MAHARASTRA";
        }
        if (StateName === "UTTARAKHAND" && this.state.Model.buyObj.CompanyID === 107) {
            StateName = "UTTRANCHAL";
        }
        let StateID = "";
        if (this.state.Model.buyObj.CompanyID == 103 || this.state.Model.buyObj.CompanyID == 106 || this.state.Model.buyObj.CompanyID == 107 || this.state.Model.buyObj.CompanyID == 105 || this.state.Model.buyObj.CompanyID == 100 || this.state.Model.buyObj.CompanyID == 108 || this.state.Model.buyObj.CompanyID == 118) {
            StateID = stateVal.split("~")[0];
        }
        else {
            StateID = stateVal;
        }
        if (this.state.Model.buyObj.CompanyID == 111) {
            StateID = this.state.stateList.filter(row => row.CombindID == stateVal)[0].StateName;
        }
        axios.get("https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=" + this.state.Model.buyObj.CompanyID + "&StateID=" + StateID)
            .then(data => {
                this.setState({ cityList: data.data, filterCityList: data.data })

            })
        let state = this.state.stateList.filter((row) => row.StateName.toLowerCase() == StateName.toLowerCase())[0]
        let selected = this.state.stateList.filter((row) => row.CombindID == stateVal)[0]
        try {
            if (state.StateName != selected.StateName) {

                let PerStateID = this.state.PerStateID;
                if (this.state.Model.buyObj.CompanyID == 107 || this.state.Model.buyObj.CompanyID == 105 || this.state.Model.buyObj.CompanyID == 103 || this.state.Model.buyObj.CompanyID == 100 || this.state.Model.buyObj.CompanyID == 108 || this.state.Model.buyObj.CompanyID == 118 || this.state.Model.buyObj.CompanyID == 106) {
                    PerStateID = PerStateID.split("~")[0];
                }
                else {
                    PerStateID = PerStateID;
                }
                if (this.state.Model.buyObj.CompanyID == 111) {
                    PerStateID = this.state.stateList.filter(row => row.CombindID == PerStateID)[0].StateName;
                }
                axios.get("https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=" + this.state.Model.buyObj.CompanyID + "&StateID=" + PerStateID)
                    .then(data => {
                        this.setState({ perCityList: data.data, })
                    })

                this.setState({ IsChangeAddress: true })
            }
            else this.setState({ IsChangeAddress: false })
        } catch (err) {
            console.log(err)
        }

    }
    manageSearch(e) {
        switch (e.target.id) {
            case "State":

                this.setState({ showStateList: !this.state.showStateList, filterStateList: this.state.stateList });
                break;
            case "City": this.setState({ showCityList: !this.state.showCityList, filterCityList: this.state.cityList });
                break;
            case "Financer": this.setState({ showFinancerList: !this.state.showFinancerList, FilterFinancerList: this.state.PFinances });
                break;
        }
    }
    UrlF = (url) => {
        try {
            if (url === null || url === "")
                return url;
            if (url.match("http"))
                return url;
            return url.replace("https://", "").replace("http://", "").replace("//", "https://")
        } catch (err) {
            return url;
        }
    }
    render() {
        const Model = this.state.Model.QuoteReq;
        return (
            <div className="NewProposal">
                <ProgressBarBox Progress="50%" />
                <div className="container">

                    <div className="proposalPageBox">
                        
                        {this.state.GotoQuote &&
                            <div className="row BackBTNRow">
                                <div className="col-3 col-md-auto">
                                    <a onClick={this.goBack} className="arw-btn">
                                        <i class="fas fa-arrow-left"></i>
                                    </a>
                                </div>
                                <div className="col-9 col-md-10 col-lg-11 align-self-center">
                                    <h3 className="f-32 font-regular mb-0">Back to Quotation</h3>
                                </div>
                            </div>
                        }
                        <div className="row proposalBox03">
                            <div className="col-sm-3 p-0">

                                <div className="proDetailsMM">
                                    <div className="rowDetailsMM">
                                        <img id="companylogo" src={this.UrlF(this.state.Model.buyObj.CompanyLogo)} />
                                        <span id="manufacturevehicleName" className="ng-binding">{this.state.Model.CompModel.Vehicle}</span>
                                        <span id="manufacturevehiclevariant" className="ng-binding">{this.state.Model.CompModel.variant}</span>
                                        <p id="rtoCodewithLocation" className="ng-binding">{this.state.Model.RTO_CODE} {this.state.Model.CompModel.RTOName}</p>
                                        <p className="LeadIdHover enqueryNumber">
                                            <b>Enquiry#</b> <span id="EnquiryNo" className="ng-binding">{this.state.Model.QuoteReq.EnquiryNo}<CopyToClipboard text={this.state.Model.QuoteReq.EnquiryNo}><i className="fas fa-copy"></i></CopyToClipboard></span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM rowDetailsMM01">
                                        <p>
                                            Premium
                    <span><b>₹ </b> <b id="netPremium" className="ng-binding">{Math.round(this.state.Model.buyObj.NetPremium)}</b></span>
                                        </p>
                                        <p>
                                            + GST (18%)
                    <span><b>₹ </b> <b id="serviceTax" className="ng-binding">{Math.round(this.state.Model.buyObj.serviceTax)}</b></span>
                                        </p>
                                        <p className="totalP">
                                            Total Premium
                    <span><b>₹ </b> <b id="TotalPremium" className="ng-binding">{Math.round(this.state.Model.buyObj.TotalPremium)}</b></span>
                                        </p>


                                    </div>
                                    <div className="rowDetailsMM addonsListPro">
                                        <p onClick={() => this.setState({ AddonsList: !this.state.AddonsList })}> Addons <i className={"fas " + (this.state.AddonsList ? "fa-minus" : "fa-plus")}></i></p>
                                        < ul style={{ height: this.state.AddonsList ? "auto" : "0px", opacity: this.state.AddonsList ? "1" : "0" }}>

                                            {Model.chkDepreciation && <li>Zero Depriciation
                                        {/*<span>*/}
                                                {/*        ₹150*/}
                                                {/*            <img src={require("../Contents/img/WrongCross.png")} />*/}

                                                {/*    </span>*/}
                                            </li>}
                                            {Model.chkPaidDriver && <li>Paid Driver</li>}
                                            {Model.chkPassengerCover && <li>Passenger Cover</li>}
                                            {Model.chkReturnToInvoice && <li>Invoice Cover</li>}
                                            {Model.chkRoadSideAssistance && <li>Roadside Assistance</li>}
                                            {Model.chkConsumables && <li>Consumable Cover</li>}
                                            {Model.chkNCBProtection && <li>NCB Protection</li>}
                                            {Model.chkInconvenienceAllowance && <li>Inconvenience Allowance</li>}
                                            {Model.chkEngineProtector && <li>Engine Protector Cover</li>}
                                            {Model.chkPassangerAssistance && <li>Passenger Assistance</li>}
                                            {Model.chkKeyReplacement && <li>Key Replacement</li>}
                                        </ul>
                                    </div>
                                    <div className="rowDetailsMM">
                                        <p>
                                            IDV
                    <span><b>₹ </b> <b id="IDVData" className="ng-binding">{this.state.Model.buyObj.IDV}</b></span>
                                        </p>
                                        <p ng-show="!forrenew" className="ng-hide">
                                            Manufacture Date
                    <span id="RegistrationDate" className="ng-binding">{this.dobManage(this.state.Model.buyObj.ManufactureDate.slice(0, 10))}</span>
                                        </p>
                                        <p ng-show="forrenew">
                                            Registration Date
                    <span id="RegistrationDate" className="ng-binding">{this.dobManage(this.state.Model.QuoteReq.RegistrationYear)}</span>
                                        </p>
                                        <p ng-show="forrenew">
                                            Current NCB
                    <span id="currentNCB" className="ng-binding">{this.state.Model.buyObj.CurrentNCB}%</span>
                                        </p>
                                        <p>
                                            Policy Type
                    <span id="policyType" className="ng-binding">{this.state.Model.CompModel.NewOrRenew}</span>
                                        </p>

                                        <p>
                                            Policy Start Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.dobManage(this.state.Model.buyObj.PolicyStartDate.slice(0, 10))}</span>
                                        </p>
                                        <p>
                                            Policy Expiry Date
                    <span id="PolicyStartDateProposal" className="ng-binding">{this.dobManage(this.state.Model.buyObj.PolicyEndDate.slice(0, 10))}</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-9 p-0">

                                <div className="ProposalTabBox">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                        <div className="ProposalTabsLeft">
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" onClick={() => this.selectTabSection("second", "first")}>
                                                        {
                                                            this.state.p1 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        {
                                                            this.state.Model.QuoteReq.VehicleType == 1 ? "Car" :
                                                                this.state.Model.QuoteReq.VehicleType == 2 ? "PCV" :
                                                                    this.state.Model.QuoteReq.VehicleType == 3 ? "GCV" : "Bike"
                                                        } Owner Details
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" onClick={() => this.selectTabSection("first", "second")}>
                                                        {
                                                            this.state.p2 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Personal Details
                                                        </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Third" onClick={() => this.selectTabSection("Four", "Third")}>
                                                        {
                                                            this.state.p3 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Vehicle Details
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Four" onClick={() => this.selectTabSection("Third", "Four")}>
                                                        {
                                                            this.state.p4 ? <a className="rightIcon"></a> : <a className="wrongIcon"></a>
                                                        }
                                                        Communication Address
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <div className="BimaPayProcess" style={{ display: "none" }}>
                                                <ul class="info-text-ul">
                                                    <li>
                                                        <span class="icon"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5H12.5C12.7652 6.5 13.0196 6.60536 13.2071 6.79289C13.3946 6.98043 13.5 7.23478 13.5 7.5V14.5C13.5 14.7652 13.3946 15.0196 13.2071 15.2071C13.0196 15.3946 12.7652 15.5 12.5 15.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.5 6.5V4C3.5 3.07174 3.86875 2.1815 4.52513 1.52513C5.1815 0.868749 6.07174 0.5 7 0.5V0.5C7.92826 0.5 8.8185 0.868749 9.47487 1.52513C10.1313 2.1815 10.5 3.07174 10.5 4V6.5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M7 12.5C7.82843 12.5 8.5 11.8284 8.5 11C8.5 10.1716 7.82843 9.5 7 9.5C6.17157 9.5 5.5 10.1716 5.5 11C5.5 11.8284 6.17157 12.5 7 12.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        </span>
                                            Secure transaction protected by EVSSL Certificate
                                    </li>
                                                    <li><span class="icon">
                                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0)">
                                                                <path d="M0.5 8H15.5L12.5 5H3.5L0.5 8Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M3.5 5L4.5 1H11.5L12.5 5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M12 16C13.3807 16 14.5 14.8807 14.5 13.5C14.5 12.1193 13.3807 11 12 11C10.6193 11 9.5 12.1193 9.5 13.5C9.5 14.8807 10.6193 16 12 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4 16C5.38071 16 6.5 14.8807 6.5 13.5C6.5 12.1193 5.38071 11 4 11C2.61929 11 1.5 12.1193 1.5 13.5C1.5 14.8807 2.61929 16 4 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M9.66009 12.644C9.20655 12.2296 8.61443 11.9999 8.00009 11.9999C7.38575 11.9999 6.79363 12.2296 6.34009 12.644" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0">
                                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </span>
                                        Data Security and Privacy Guaranteed</li>
                                                    <li><span class="icon"><svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.2029 7.23333C13.5858 6.6469 13.7897 5.96169 13.7897 5.26132C13.7897 4.56095 13.5858 3.87573 13.2029 3.28931" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M11.3804 6.22103C11.6011 5.9499 11.7216 5.61099 11.7216 5.26139C11.7216 4.9118 11.6011 4.57289 11.3804 4.30176" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M0.86377 11.4659V2.15909C0.86377 1.88483 0.972718 1.62181 1.16665 1.42788C1.36058 1.23395 1.6236 1.125 1.89786 1.125H15.341C15.6153 1.125 15.8783 1.23395 16.0723 1.42788C16.2662 1.62181 16.3751 1.88483 16.3751 2.15909V11.4659C16.3751 11.7402 16.2662 12.0032 16.0723 12.1971C15.8783 12.3911 15.6153 12.5 15.341 12.5H1.89786C1.6236 12.5 1.36058 12.3911 1.16665 12.1971C0.972718 12.0032 0.86377 11.7402 0.86377 11.4659Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M3.44897 9.91479H9.65352" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M11.7217 9.91479H13.7899" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M6.0342 3.71021H3.44897V5.77839H6.0342V3.71021Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                        <path d="M9.65352 5.77835C9.93908 5.77835 10.1706 5.54686 10.1706 5.26131C10.1706 4.97575 9.93908 4.74426 9.65352 4.74426C9.36796 4.74426 9.13647 4.97575 9.13647 5.26131C9.13647 5.54686 9.36796 5.77835 9.65352 5.77835Z" fill="#4DAAE8" />
                                                    </svg>
                                                    </span>
                                        Robust Payment Gateways
                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="ProposalTabsRight">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    {
                                                        this.state.IsCarOwner ?

                                                            <div className="ProductFormIn ">

                                                                <div className="row">
                                                                    <div className="col-md-12 no-padding">
                                                                        <div className="InsurerOwner mb-4 pl-3" id="IscorpSelDisable">

                                                                            <label className="labeltext pr-2">{this.state.Model.QuoteReq.VehicleType == 1 ? "Car" : this.state.Model.QuoteReq.VehicleType == 2 ? "PCV" : this.state.Model.QuoteReq.VehicleType == 3 ? "GCV" : "Bike"} registered in company name?</label>
                                                                            <div className="form-check-inline">
                                                                                <label>
                                                                                    <input type="radio" type="radio" value="Yes" name="Owner" checked={this.state.Owner}
                                                                                        onChange={this.handle} /> Yes

                                                                                </label>
                                                                                <label>
                                                                                    <input type="radio" name="Owner" value="Y" checked={!this.state.Owner}
                                                                                        onChange={this.handle} id="UserInput_Owner" /> No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.IsCorporatePopup && <IsCorporatePopuo Proceed={() => this.setState({ IsCorporatePopup: false })} RevisedPremium={Math.round(this.state.Model.buyObj.TotalPremium)} OldPremium={Math.round(this.state.OldPremium)} src={this.state.Model.buyObj.CompanyLogo} />}
                                                                {!this.state.Model.QuoteReq.IsReNew && <div className="row ">
                                                                    <div className="col-md-3 form-group">

                                                                        <select className="packageForm" value={this.state.Salutation} name="Salutation" onChange={this.handle}>
                                                                            <option value="select">Salutation</option>
                                                                            <option value="Mr">Mr.</option>
                                                                            <option value="Mrs">Mrs.</option>
                                                                            <option value="Miss">Miss</option>
                                                                        </select>


                                                                    </div>
                                                                </div>}

                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">

                                                                        <MaskedInput
                                                                            mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                            guide={false} showMask={false} placeholder="Owner Name, as per RC copy"
                                                                            value={this.state.OwnerName} className="packageForm" name="OwnerName"
                                                                            onChange={this.handle} onBlur={this.handle} />
                                                                        <span className="txtError" style={{ color: "red" }}>{this.state.errors["OwnerName"]}</span>
                                                                    </div>


                                                                    {
                                                                        this.state.Owner ?

                                                                            <div className="col-md-6 form-group">

                                                                                <MaskedInput
                                                                                    mask={[/[0-9]/, /[0-9]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[A-Za-z]/, /[1-9A-Za-z]/, 'z', /[0-9A-Za-z]/]}
                                                                                    guide={false} showMask={false} placeholder="GST Number"
                                                                                    name="GSTNum" value={this.state.GSTNum} onBlur={this.handle}
                                                                                    onChange={this.handle} className="packageForm"
                                                                                    style={{ textTransform: "uppercase" }}
                                                                                />
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["GSTNum"]}</span>
                                                                            </div>


                                                                            : null
                                                                    }

                                                                    <div className="col-md-6 form-group">
                                                                        <input type="email"
                                                                            placeholder="Email ID"
                                                                            name="OwnerEmail" value={this.state.OwnerEmail} onBlur={this.handle}
                                                                            onChange={this.handle} className="packageForm" />
                                                                        <span className="txtError" style={{ color: "red" }}>{this.state.errors["OwnerEmail"]}</span>
                                                                    </div>

                                                                    <div className="col-md-6 form-group">
                                                                        <MaskedInput className="packageForm" type="tel"
                                                                            mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                            guide={false} showMask={false} placeholder="Mobile Number"
                                                                            name="OwnerMobileNo" value={this.state.OwnerMobileNo} onBlur={this.handle}
                                                                            onChange={this.handle} />

                                                                        <span style={{ color: "red" }} className="txtError">{this.state.errors["OwnerMobileNo"]}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <button className={ButtonClass()}
                                                                            onClick={(e) => this.NextStep("PersonalDetails")}>
                                                                            Continue to Personal Details <i className="fa fa-angle-right" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                            : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    {
                                                        this.state.IsPersonalDetail ? <React.Fragment>
                                                            <div className="proposalForm001Body ProductFormIn">

                                                                {!this.state.Owner && < React.Fragment >
                                                                    <div className="row">
                                                                        <div className="col-md-6 form-group">
                                                                            <div className="InsurerOwner">
                                                                                <label className="labeltext">Gender</label>
                                                                                <div class="form-check-inline">
                                                                                    <label>

                                                                                        <input type="radio" name="PGender"
                                                                                            checked={this.state.PGender}
                                                                                            onChange={this.handle}
                                                                                            value="1"
                                                                                        /> Male</label>
                                                                                    <label><input type="radio" name="PGender"
                                                                                        checked={!this.state.PGender}
                                                                                        onChange={this.handle}
                                                                                        value="2"
                                                                                    /> Female</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="InsurerOwner">
                                                                                <label className="labeltext">Marital Status</label>
                                                                                <div class="form-check-inline">

                                                                                    <label>
                                                                                        <input type="radio" name="PMarital"
                                                                                            checked={this.state.PMarital}
                                                                                            onChange={this.handle}

                                                                                        /> Married </label>
                                                                                    <label>
                                                                                        <input type="radio" name="PMarital"
                                                                                            checked={!this.state.PMarital}
                                                                                            onChange={this.handle} /> Single
                                                                                </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 form-group">
                                                                            <label className="labeltext">Date of Birth</label>
                                                                            <input type="date"
                                                                                name="PDOB"
                                                                                value={this.state.PDOB}
                                                                                onChange={this.handle}
                                                                                onBlur={this.handle}
                                                                                className="packageForm"

                                                                                max={this.state.date}
                                                                                defaultValue={this.state.date}

                                                                            />
                                                                            <small>*Date Format : (MM/DD/YYYY)</small>
                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["PDOB"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="border-bottom mt-3 mb-3"></div>
                                                                    <div className="row">
                                                                        <div className="col-md-12  form-group">Nominee Details</div>
                                                                        <div className="col-md-5 form-group">
                                                                            <div className="fillFields">
                                                                                <MaskedInput className="packageForm"
                                                                                    mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[\sa-zA-Z]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/]}
                                                                                    guide={false} showMask={false} placeholder="Nominee Name"
                                                                                    name="PNomineeName" onBlur={this.handle}
                                                                                    value={this.state.PNomineeName}
                                                                                    onChange={this.handle}
                                                                                    className="packageForm"
                                                                                />
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["PNomineeName"]}</span>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 form-group"> <select
                                                                            name="PNomineeRelation" onBlur={this.handle}
                                                                            value={this.state.PNomineeRelation}
                                                                            onChange={this.handle} className="packageForm"
                                                                        ><option value="select">Select Relationship </option>
                                                                            {
                                                                                this.state.PRelationShips.map(row =>
                                                                                    <option value={row.MasterID}>{row.Name}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["PNomineeRelation"]}</span>
                                                                        </div>
                                                                        <div className="col-md-2 form-group">
                                                                            <MaskedInput className="packageForm"
                                                                                mask={[/\d/, /\d/]} onBlur={this.handle}
                                                                                guide={false} showMask={false} placeholder="Age"
                                                                                name="PNomineeAge" className="packageForm"
                                                                                value={this.state.PNomineeAge}
                                                                                onChange={this.handle}
                                                                            />
                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["PNomineeAge"]}</span>
                                                                        </div>
                                                                        <div className="col-md-5 form-group">
                                                                            <select className="packageForm"
                                                                                name="PNomineeOccupation"
                                                                                value={this.state.PNomineeOccupation}
                                                                                onChange={this.handle} onBlur={this.handle}
                                                                            >
                                                                                <option value="select">Select Occupation</option>
                                                                                {
                                                                                    this.state.POccupations.map(row =>
                                                                                        <option value={row.MasterID}>{row.Name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["PNomineeOccupation"]}</span>
                                                                        </div>
                                                                    </div> </React.Fragment>}
                                                                <div className="border-bottom mt-3 mb-3"></div>
                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="InsurerOwner">
                                                                            <label className="labeltext">
                                                                                Vehicle is Finance ?
                                                                                </label>
                                                                            <div className="form-check-inline">
                                                                                <label>
                                                                                    <input type="radio" name="VehicleIsFinance"
                                                                                        checked={this.state.VehicleIsFinance}
                                                                                        onChange={this.handle}
                                                                                        value="Y"
                                                                                    />Yes
                                                                                </label>
                                                                                <label>
                                                                                    <input type="radio" name="VehicleIsFinance"
                                                                                        checked={!this.state.VehicleIsFinance}
                                                                                        onChange={this.handle}
                                                                                        value="N"
                                                                                    />No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.VehicleIsFinance ?
                                                                            <div className="col-md-6 form-group">
                                                                                <label className="w-100">
                                                                                    <button onClick={this.manageSearch} className="searchButtonCar" id="Financer">{this.state.Financer}</button>
                                                                                    {this.state.showFinancerList && <div className="manageDrpDwn">
                                                                                        <input type="text" className=" " placeholder="Search..." onChange={this.searchFilter} autoComplete="off"
                                                                                            onKeyDown={this.searchDown}
                                                                                            id="searchFinancer" autoFocus />
                                                                                        <ul id="findFinancerVal">
                                                                                            {this.state.FilterFinancerList.map(row => {
                                                                                                return <li id={row.MasterID} key={row.MasterID} onClick={() => this.manageClick("PVehicleFinance", row.MasterID)}>{row.Name}</li>
                                                                                            })}
                                                                                        </ul></div>}
                                                                                </label>
                                                                                {/* <select
                                                                                className="packageForm"
                                                                                name="PVehicleFinance"
                                                                                value={this.state.PVehicleFinance}
                                                                                onChange={this.handle} onBlur={this.handle}
                                                                            >
                                                                                <option value="select">Select Financer</option>
                                                                                {
                                                                                    this.state.PFinances.map(row =>
                                                                                        <option value={row.MasterID}>{row.Name}</option>
                                                                                    )
                                                                                }
                                                                            </select>*/}
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["PVehicleFinance"]}</span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <button className={ButtonClass()}
                                                                            onClick={(e) => this.NextStep("VehicleDetails")} >
                                                                            Continue to Vehicle Details <i className="fa fa-angle-right" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div></React.Fragment>
                                                            : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Third">
                                                    {
                                                        this.state.IsVehicleDetail ?
                                                            <div className="proposalForm001Body ProductFormIn">
                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <MaskedInput className="packageForm"
                                                                                    mask={[/[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/]}
                                                                                    guide={false} showMask={false} maxLength="17" placeholder="Engine Number"
                                                                                    value={this.state.VEngine} onBlur={this.handle}
                                                                                    name="VEngine" onChange={this.handle}
                                                                                    className="packageForm"
                                                                                    style={{ textTransform: "uppercase" }}
                                                                                />

                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["VEngine"]}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <MaskedInput className="packageForm"
                                                                                    mask={[/[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/]}
                                                                                    guide={false} showMask={false} maxLength="17" placeholder="Chassis Number"
                                                                                    value={this.state.VChasis} onBlur={this.handle}
                                                                                    name="VChasis" onChange={this.handle}
                                                                                    style={{ textTransform: "uppercase" }}

                                                                                />

                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["VChasis"]}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    {this.state.Model.QuoteReq.IsReNew && <div className="col-md-6">
                                                                        <div className="row">
                                                                            <div className="col-md-4 form-group">
                                                                                <div className="fillFields"> <label>
                                                                                    <input type="text" className="packageForm" value={this.state.Model.QuoteReq.RtoCode} disabled />
                                                                                </label></div>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <div className="fillFields"> <label>
                                                                                    <MaskedInput className="packageForm"
                                                                                        mask={[/[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /\d/, /\d/, /\d/, /\d/]}
                                                                                        guide={false} showMask={false} onBlur={this.handle}
                                                                                        placeholder="Vehicle Registration No."
                                                                                        value={this.state.VRegistration}
                                                                                        name="VRegistration" onChange={this.handle}
                                                                                        style={{ textTransform: "uppercase" }}
                                                                                    />


                                                                                    <small>*e.g:AB1234</small>
                                                                                    <span className="txtError" style={{ color: "red" }}>{this.state.errors["VRegistration"]}</span>
                                                                                </label></div>
                                                                            </div>
                                                                        </div>


                                                                    </div>}
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <input type="text"
                                                                                    value={this.dateFormatter(this.state.Model.QuoteReq.RegistrationYear, "-", "-", "dd/mm/yy", "st")}
                                                                                    name="VRegistrationDate" onChange={this.handle} className="packageForm"
                                                                                    disabled />
                                                                                <small>Registration Date</small>
                                                                            </label>
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                                {this.state.Model.QuoteReq.IsReNew && <React.Fragment>{this.state.Model.QuoteReq.IsPrevPolicyDetails == false && <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <select name="VPreviosInsurer" className="packageForm"
                                                                                    value={this.state.VPreviosInsurer}
                                                                                    onChange={this.handle} onBlur={this.handle}
                                                                                ><option value="select">Select Previos Insurer</option>
                                                                                    {
                                                                                        this.state.VPreviosInsurerList.map(row =>
                                                                                            <option value={row.MasterID}>{row.Name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["VPreviosInsurer"]}</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <input type="text" placeholder="Previous Policy Number"
                                                                                    value={this.state.VPreviosPolicyNo} onBlur={this.handle}
                                                                                    name="VPreviosPolicyNo" onChange={this.handle} maxlength="40" minlength="10"
                                                                                    style={{ textTransform: "uppercase" }} className="packageForm" />
                                                                                {/* <MaskedInput className="packageForm"
                                                                                    mask={[/[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/, /[a-zA-Z\d]/]}
                                                                                    guide={false} showMask={false}
                                                                                    placeholder="Previous Policy Number"
                                                                                    value={this.state.VPreviosPolicyNo} onBlur={this.handle}
                                                                                    name="VPreviosPolicyNo" onChange={this.handle}
                                                                                    style={{ textTransform: "uppercase" }}
                                                                                />*/}


                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["VPreviosPolicyNo"]}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                                    {(this.state.Model.QuoteReq.odOnly === true) &&
                                                                        <React.Fragment>
                                                                            <div className="row">
                                                                                <div className="col-md-6 form-group">
                                                                                    <div className="fillFields">
                                                                                        <label>
                                                                                            <select name="TPPolicyInsurer" className="packageForm"
                                                                                                value={this.state.TPPolicyInsurer}
                                                                                                onChange={this.handle}
                                                                                            ><option value="select">TP Previos Insurer</option>
                                                                                                {
                                                                                                    this.state.VPreviosInsurerList.map(row =>
                                                                                                        <option value={row.MasterID}>{row.Name}</option>
                                                                                                    )
                                                                                                }
                                                                                            </select>
                                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["TPPolicyInsurerError"]}</span>

                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 form-group">
                                                                                    <div className="fillFields">
                                                                                        <label>
                                                                                            <input className="packageForm" maxlength="40" minlength="10"
                                                                                                type="text" placeholder="TP Policy Number" value={this.state.TPPolicyNumber} name="TPPolicyNumber" onChange={this.handle} />


                                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["TPPolicyNumberError"]}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 form-group">
                                                                                    <input type="date" onBlur={this.handle}
                                                                                        name="TPPolicyStartDate"
                                                                                        value={this.state.TPPolicyStartDate}
                                                                                        onChange={this.handle}
                                                                                        className="packageForm"
                                                                                        max={this.state.crd}
                                                                                        min={this.state.TPStartdate}
                                                                                        defaultValue={this.state.crd}

                                                                                    />
                                                                                    <small>*Date Format : (DD/MM/YYYY)(TP Start Date)</small>
                                                                                    <span className="txtError" style={{ color: "red" }}>{this.state.errors["TPPolicyStartDate"]}</span>
                                                                                </div>
                                                                                <div className="col-md-6 form-group">
                                                                                    <input type="date"
                                                                                        name="TPPolicyEndDate"
                                                                                        value={this.state.TPPolicyEndDate}
                                                                                        onChange={this.handle} onBlur={this.handle}
                                                                                        className="packageForm"
                                                                                        min={this.state.TPEnddate}
                                                                                        max={this.state.TPmaxEndDate}
                                                                                        defaultValue={this.state.TPEnddate}

                                                                                    />
                                                                                    <small>*Date Format : (DD/MM/YYYY)(TP End Date)</small>
                                                                                    <span className="txtError" style={{ color: "red" }}>{this.state.errors["TPPolicyEndDate"]}</span>
                                                                                </div>
                                                                            </div>



                                                                        </React.Fragment>
                                                                    }

                                                                    {(this.state.Model.buyObj.CompanyID == 118 || this.state.Model.buyObj.CompanyID === 111 || this.state.Model.buyObj.CompanyID === 101 || this.state.Model.buyObj.CompanyID === 108 || this.state.Model.buyObj.CompanyID === 109) &&
                                                                        <React.Fragment><div className="row mt-3 mb-3">
                                                                            <div className="col-md-6 form-group">
                                                                                <label className="labeltext">
                                                                                    Valid PUC is available ?
                                                                         </label>
                                                                            </div>
                                                                            <div className="col-md-6 form-group">

                                                                                <div className="form-check-inline mt-3">
                                                                                    <label className="customradio m-0">
                                                                                        <span className="radiotextsty">Yes</span>
                                                                                        <input type="radio" name="isPUC"
                                                                                            checked={this.state.isPUC == "YES"}
                                                                                            onChange={this.handle}
                                                                                            value="YES"
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                    <label className="customradio m-0">
                                                                                        <span className="radiotextsty">No</span>
                                                                                        <input type="radio" name="isPUC"
                                                                                            checked={this.state.isPUC == "NO"}
                                                                                            onChange={this.handle}
                                                                                            value="NO"
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            {this.state.isPUC == "YES" && (this.state.Model.buyObj.CompanyID == 118 || this.state.Model.buyObj.CompanyID === 111 || this.state.Model.buyObj.CompanyID === 101) && <div className="row PUCDetails" style={{ display: "block" }}>
                                                                                <div className="col-md-12 form-group">
                                                                                    <div className="inline">
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 form-group ">
                                                                                            <div ng-className="PUCNumberDiv" className="fillFields fillFieldsFullName styleForm">
                                                                                                <input onBlur={this.handle} id="MotorInput_pucnumber" onChange={this.handle} name="pucnumber" placeholder="PUC Number" required="required" style={{ textTransform: "uppercase" }} type="text" value={this.state.pucnumber} className="form-control style-inpt style-inpt-home" aria-required="true" />

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 form-group ">
                                                                                            <div className="fillFields fillFieldsFullName styleForm">
                                                                                                <input id="PUCValidDate" name="pucvalidUpto" onBlur={this.handle} onChange={this.handle} min={this.state.crd} value={this.state.pucvalidUpto} max={this.state.TPEnddate} placeholder="PUC EXPIRY DATE" type="date" className="form-control style-inpt style-inpt-home" aria-required="true" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>} </React.Fragment>}
                                                                </React.Fragment>

                                                                }


                                                                <div className="row">
                                                                    <div className="col-md-6 form-group"> <label>
                                                                        <button className={ButtonClass()}
                                                                            onClick={(e) => this.NextStep("AddressDetails")} >
                                                                            Continue to Address <i className="fa fa-angle-right" aria-hidden="true"></i> </button>
                                                                    </label></div>
                                                                </div>



                                                            </div>
                                                            : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Four">
                                                    {
                                                        this.state.IsAddressDetail ? <React.Fragment>
                                                            <div className="proposalForm001Body ProductFormIn">
                                                                <div className="row">
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields">
                                                                            <label>
                                                                                <input type="text" placeholder="Address Line 1"
                                                                                    name="AddressLine1"
                                                                                    value={this.state.AddressLine1}
                                                                                    onChange={this.handle}
                                                                                    maxLength="15" onBlur={this.handle}
                                                                                    className="packageForm"
                                                                                />
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["AddressLine1"]}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="fillFields"> <label>
                                                                            <input type="text" placeholder="Address Line 2"
                                                                                name="AddressLine2"
                                                                                maxLength="30" onBlur={this.handle}
                                                                                value={this.state.AddressLine2}
                                                                                onChange={this.handle}
                                                                                className="packageForm"
                                                                            />
                                                                        </label>
                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["AddressLine2"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="filFields"> <label>
                                                                            <input type="text" placeholder="Address Line 3"
                                                                                name="AddressLine3"
                                                                                maxLength="30" onBlur={this.handle}
                                                                                value={this.state.AddressLine3}
                                                                                onChange={this.handle}
                                                                                className="packageForm"
                                                                            />
                                                                        </label></div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="vehicleContainer">


                                                                            <label>
                                                                                <button onClick={this.manageSearch} className="searchButtonCar" id="State">{this.state.StateName}</button>
                                                                                {this.state.showStateList && <div className="manageDrpDwn">
                                                                                    <input type="text" className=" " placeholder="Search..." onChange={this.searchFilter} autoComplete="off"
                                                                                        onKeyDown={this.searchDown}
                                                                                        id="searchState" autoFocus />
                                                                                    <ul id="findStateVal">
                                                                                        {this.state.filterStateList.map(row => {
                                                                                            return <li id={row.CombindID} key={row.CombindID} onClick={() => this.manageClick("State", row.CombindID)}>{row.StateName}</li>
                                                                                        })}
                                                                                    </ul></div>}
                                                                                {/*<select
                                                                            name="State"
                                                                            value={this.state.State}
                                                                            onChange={this.handle}
                                                                            className="packageForm"
                                                                            onBlur={this.handle}
                                                                        ><option value="select">State</option>
                                                                            {
                                                                                this.state.stateList.map(row =>
                                                                                    <option value={row.CombindID}>{row.StateName}</option>
                                                                                )
                                                                            }
                                                                        </select>*/}
                                                                                <span className="txtError">{this.state.errors["State"]}</span>
                                                                            </label></div></div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="vehicleContainer">
                                                                            <label>
                                                                                <button onClick={this.manageSearch} className="searchButtonCar" id="City">{this.state.CityName}</button>
                                                                                {this.state.showCityList &&
                                                                                    <div className="manageDrpDwn">
                                                                                        <input type="text" className=" " placeholder="Search..." onChange={this.searchFilter} autoComplete="off"
                                                                                            onKeyDown={this.searchDown}
                                                                                            id="searchCity" autoFocus />
                                                                                        <ul id="findCityVal">
                                                                                            {this.state.filterCityList.map(row => {
                                                                                                return <li id={row.CombindID} key={row.CombindID} onClick={() => this.manageClick("City", row.CombindID)}>{row.CityName}</li>
                                                                                            })}
                                                                                        </ul></div>}
                                                                                {/*<select
                                                                            name="City"
                                                                            value={this.state.City}
                                                                            onChange={this.handle}
                                                                            onBlur={this.handle}
                                                                            className="packageForm"
                                                                        ><option>City</option>
                                                                            {
                                                                                this.state.cityList.map(row =>
                                                                                    <option value={row.CombindID}>{row.CityName}</option>
                                                                                )
                                                                            }
                                                                        </select>*/}
                                                                                <span className="txtError" style={{ color: "red" }}>{this.state.errors["City"]}</span>
                                                                            </label></div></div>
                                                                    <div className="col-md-6 form-group">
                                                                        <label>
                                                                            <MaskedInput className="packageForm" type="tel"
                                                                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                                guide={false} showMask={false}
                                                                                placeholder="PIN Code"
                                                                                name="ADPinCode" value={this.state.ADPinCode}
                                                                                onChange={this.handle} onBlur={this.handle}

                                                                            />

                                                                            <span className="txtError" style={{ color: "red" }}>{this.state.errors["ADPinCode"]}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {this.state.IsChangeAddress &&
                                                                    <SecondAddress PerCityID={this.state.PerCityID} type={this.state.Model.QuoteReq.Type == 1 ? "Car" : "Two Wheeler"} PerPinCode={this.state.PerPinCode} CombindID={this.state.PerStateID} StateName={this.state.PerStateName} cityList={this.state.perCityList} handle={this.handle} />
                                                                }
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label>
                                                                            <button className={ButtonClass()}
                                                                                disabled={this.state.IsProceePropsal ? "disabled" : ""}
                                                                                onClick={(e) => this.NextStep("Payment")}>
                                                                                {this.state.ProposalBtnVal} <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                                            </button>
                                                                        </label>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </React.Fragment>
                                                            : null
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.IsMessage ?
                            <div className="Manualmodal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header text-center">
                                            <button type="button" className="close btn-success" onClick={this.CloseMessage}>×</button>
                                        </div>
                                        <div className="modal-body">
                                            <span>{this.state.MessageBody}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div >
            </div>
        )
    }
}