import React, { Component } from "react";
import { MasterGlobal } from "../App";


export class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            //Logo: require("./assets/img/logo.png"),
            Login: "Login",
            showHome: true,
        };
        this.homeSelect();
        this.CompanyAPI();
        MasterGlobal.btn = "Login";
        this.homeSelect = this.homeSelect.bind(this);
    }
    CompanyAPI() {
        let model = {
            CurrentUrl: window.location.hostname,
        };
        fetch("/bpi/WeatherForecast/GetAPIUrls", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(model),
        })
            .then((response) => response.json())
            .then((data) => {
                MasterGlobal.CRMAPI = data.crmapi;
                MasterGlobal.PortalAPI = data.portalAPI;
                window.localStorage.setItem("portal", data.portalAPI)
                window.localStorage.setItem("crm", data.crmapi)
                this.loadLogo(data.crmapi);
            });
    }
    loadLogo(ss) {
        let splitUrl = window.location.hostname.split(".")[1];
        let tempurl = MasterGlobal.CRMAPI;
        var Body = {
            URL: splitUrl == undefined ? "smcinsurance" : splitUrl,
        };
        let Model = {
            URL: ss + "/api/Account/DomainLogo",
            PostString: JSON.stringify(Body)
        }

        fetch("/bpi/WeatherForecast/CallCheck", {
            method: "POST",
            body: JSON.stringify(Model),
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    Logo: data.companyLogo,
                });
            });
    }
    LoginOrLogout = (e) => {
        let fetchmodel = JSON.parse(window.localStorage.getItem("token"));
        let body = {
            Token: fetchmodel.token,
        };
        fetch(this.state.URL + "/api/Account/LogOut", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
        })
            .then((Response) => Response.text())
            .then((data) => {
                window.localStorage.removeItem("token");
                MasterGlobal.btn = "Login";
                window.location.href = "/";
            });
    };
    ShowNavMenu = (e) => {
        if (this.state.NavMenuClass == "nav-xbootstrap visible")
            this.setState({ NavMenuClass: "nav-xbootstrap" });
        else this.setState({ NavMenuClass: "nav-xbootstrap visible" });
    };
    static displayName = Layout.name;

    homeSelect = () => {
        let url = window.location.href;
        url.includes("/GetQuote") ? this.setState({ showHome: false }) : this.setState({ showHome: true })

    }

    render() {
        return (

            <>

                {this.props.children}
            </>
        );
    }
}
