import React, { Component } from 'react';
import { Accordion, Card } from "react-bootstrap"
import "./AccordianRel.css"
const AccordianRel = (props) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <Accordion className="ReligareCard">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="0">
                            Who can appoint a POS?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div id="faq1" aria-expanded="true">
                                    An Insurance company or an insurance intermediary can engage a POS to represent him.
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="1">
                            What product can a POSP sell?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div class="acod-content">
                                    • Motor insurance<br />
                                    • Life insurance<br />
                                    • Travel insurance<br />
                                    • Personal accident insurance plans<br />
                                    • Home Insurance<br />
                                    Can be sold by a POS. That is because this type of insurance has minimal underwriting and people with lesser training will be able to do justice to them.
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="2">
                            Will the POS will honored a certificate to stating their association with RBL?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div class="acod-content">
                                    Yes: RBL will notify the IRDA about your association with it.RBl and IRDA certified POS will sign an agreement and after that POS will be given the certificate of association with RBL
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="3">
                            Who can become a POSP agent?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <div class="acod-content">
                                    Anyone can become a POS agent after being certified. He should be min Ssc passed and should have valid document like- Pan and Aadhar Card. No matter what you do in daily life, with a little skill, you can sell insurance, protect people financially, and earn good incomes with zero investments from your end. Still, here are some professions that would be ideally suited
                                    <br/>• Small business owners who meet regularly with customers
                                    <br />• Those who have their own individual insurance business
                                    <br />• Retired bankers
                                    <br />• Financial consultants
                                    <br />• Mutual Fund distributors
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="4">
                            The training and certification to become a POS with RBL is having charges?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                <div class="acod-content">
                                    RBL is providing the training and certification to Prospective POS free of Cost.
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="5">
                            What are the Documentation required to Become POS with RBL?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                                <div class="acod-content">
                                    The following are the minimum requirements to join RBL as A POS:<br />
                                    • Resident of India<br />
                                    • Attained 18 years of age<br />
                                    • Passed SSC or equivalent certification (matriculation)<br />
                                    • PAN Card<br />
                                    • Aadhar Card<br />
                                    • Bank account
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
        </div>

    )
}
export default AccordianRel