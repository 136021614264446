/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div class="container bottom_border">
                    <div class="row">
                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Motor Insurance</h5>

                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Private Car Insurance</a></li>
                                    <li><a href="#">Two Wheeler Insurance</a></li>
                                    <li><a href="#">Goods carrying vehicle</a></li>
                                    <li><a href="#">Passenger carrying vehicle</a></li>
                                </ul>
                            </div>

                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Health Insurance</h5>

                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Mediclaim Policy</a></li>
                                    <li><a href="#">Senior Citizen Insurance</a></li>
                                    <li><a href="#">Family Insurance</a></li>
                                    <li><a href="#">Parents Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Life Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Term Plans</a></li>
                                    <li><a href="#">Money Back Policy</a></li>
                                    <li><a href="#">Pension Plans</a></li>
                                    <li><a href="#">Type of Life Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Other Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="#">Private Car Insurance</a></li>
                                    <li><a href="#">Two Wheeler Insurance</a></li>
                                    <li><a href="#">Taxi Insurance</a></li>
                                    <li><a href="#">School Bus Insurance</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="container">
                    <div class="footerBottom compInfo">


                        <div class="about text-center"><p>
                            <strong>KATARIA INSURANCE BROKERS LTD - CIN: U67200GJ2014PTC078421</strong><br />
                            Licence No: 591, IRDA Direct Broker Code:IRDA/DB 654/16, Valid up to 05-04-2023<br />
                            Category: Direct (General)<br />
                            Principal Officer: Ms. Palak Shaunak Shah<br />

                            The prospect's visitor's particulars could be shared with Insurers. The information displayed<br />
                            on this website is of the insurers with whom our Company has an agreement.

                    </p>
                        </div>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <li><a href="/Riskovery/Contact">Connect With Us</a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>
            <Bottom />

        </React.Fragment >
    );
};
export default Footer;
