import React from "react";
const Dedicated = (props) => {
  return (
      <section className="FirstSection section">
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <div className="FirstInfoInsured">
                          <h3 className="titleWithFont">
                              Are You Covered? <br />Compare different insurance policies<br /><small>2,500+ Customers</small>
                          </h3>
                          <p>
                              Cras chinwag brown bread Eaton cracking goal so I said a load of old tosh baking cakes, geeza arse it's your round grub sloshed burke, my good sir chancer he legged it he lost his bottle pear shaped bugger all mate
                    </p>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="FirstSectionInfoBox">
                          <img src={require("../assets/img/SBM/home7_icon1.png")} />
                          <h3>National top 50 consulting firms</h3>
                          <p>Consistently ranked among the top consulting firms across the nation.</p>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="FirstSectionInfoBox">
                          <img src={require("../assets/img/SBM/home7_icon2.png")} />
                          <h3>National top 50 consulting firms</h3>
                          <p>Consistently ranked among the top consulting firms across the nation.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
};
export default Dedicated;
