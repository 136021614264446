import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const TwoWheelerInsurance = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg TwoWheeInsBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Two Wheeler Insurance</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading text-left mb-3">What is Two Wheeler Insurance?</h3>
                            <p>
                                Under a comprehensive two wheeler Insurance policy, your vehicle<br /> will be covered against theft, loss and damage. This coverage will also<br /> offer personal accidental cover for the owner or rider in event of an accident.<br /> In addition, this type of two wheeler Insurance also covers you in the <br />case of Third party liability.
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionThird ProductPageSectionThirdTwoWH">
                <div class="container">
                    <h3 class="SecondSectionHeading">Factors to consider when buying a comprehensive<br /> Two Wheeler Insurance policy</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Coverage features.svg")} />
                                <p>
                                    <strong>Coverage features </strong><br />
                                                Though the coverage in all plans would include third party liabilities and own damage suffered, you can find various additional benefits inbuilt in the policy. So, look at the coverage features provided by the plan and understand them to know exactly for what you are covered.
                    </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Insured Declared Value.svg")} />
                                <p>
                                    <strong>Insured�s Declared Value (IDV)</strong><br />
                                                        this represents the total effective coverage value and is calculated by deducting the age-based depreciation from the market price of the Two wheeler. You should choose a plan with the highest IDV to avail increased coverage levels.
                    </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Add-ons available.svg")} />
                                <p>
                                    <strong>Add-ons available</strong><br />
                                                                below mentioned Add ons are available in Market for a two wheeler comprehensive policy .
                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <h3 class="SecondSectionHeading">Type of Motor Insurance Policies</h3>
                    <div class="ProductPageSectionThirdP_Car">
                        <div class="row">
                            <div class="col-md-1">
                                <img src={require("../assets/img/Religare/Roadside assistance cover.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Roadside assistance cover:</strong><br /> It is an emergency assistance service provided by the insurer  to bike riders whose bikes have faced a breakdown on the road. RSA cover for 2  wheeler Insurance may include, tyre issues, battery problems, key related  concerns, technical snags, emergency fuel, towing assistance etc.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <img src={require("../assets/img/Religare/Zero depreciation cover.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Zero depreciation cover:</strong><br /> With  this add-on, secure your two wheeler against a reduction in the value of parts  with age. You will get the parts replaced without any pinch to your wallet.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <img src={require("../assets/img/Religare/Cover for consumables.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>Cover for consumables:</strong><br /> <strong>Consumables</strong> Add On <strong>Cover in two wheeler Insurance</strong> provides <strong>coverage</strong> for  all the <strong>consumable</strong> items of a <strong>bike</strong> such as a  grease, lubricants clip, bearings, fuel filter, oil filter, brake oil, nut and  bolt, screw, etc., which are not usually <strong>covered</strong> in a standard  own-damage <strong>insurance</strong> policy.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <img src={require("../assets/img/Religare/NCB protect.svg")} />
                            </div>
                            <div class="col-md-11">
                                <p><strong>NCB protect</strong> <br /> If you don't make a  claim in your two wheeler insurance policy, you are eligible for a discount in  Premium.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </React.Fragment>
    );
};
export default TwoWheelerInsurance;
