/* eslint-disable */
import React, { Component } from "react";
import TrustUs from "../TrustUs";
const Bottom = () => {
    return (
        <div class="foot-bg2">
            <div class="container text-center">
                <div class="row justify-content-center toll-box">
                    <div class="phone-nb text-center">
                        <p>Mail Us:</p>
                        <p><a href="mailto:wecare@heroinsurance.com">wecare@heroinsurance.com</a></p>
                    </div>
                    {/*<div class="phone-nb">*/}
                    {/*    <p>Toll Free No 2:</p>*/}
                    {/*    <p><a href="tel:99469 01212">99469 01212</a></p>*/}
                    {/*</div>*/}
                </div>
                {/*<div class="row justify-content-center paymethod">*/}
                {/*    <p class="ttl">Payment methods</p>*/}
                {/*</div>*/}
                {/*<div class="row justify-content-center paymethod">*/}
                {/*    <p><a href="#"><img src={require("../assets/img/master-card.jpg")} alt="master card" /></a> <a href="#"><img src={require("../assets/img/paytm.jpg")} alt="Paytm" /></a> <a href="#"><img src={require("../assets/img/rupay.jpg")} alt="Rupay" /></a> </p>*/}
                {/*</div>*/}
                <div class="row copy">
                    <p>All Rights Reserved 2020 @Hero Insurance</p>
                    <ul>
                        <li><a href="#">Sitemap</a></li>
                        <li><a href="terms-conditions.html">T &amp; C and Privacy Policy</a></li>
                        <li><a href="#">Disclaimer</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default Bottom;
