/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import AccordionData from "./AccordionData";
const Accordian = (props) => {
    const cd = AccordionData.map((data) => {
        return (
            <React.Fragment>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey={data.id} key={data.id}>
                        {data.title}
                        <span className={data.class}>
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                        <Card.Body>{data.body}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <br />
            </React.Fragment>
        );
    });
    return (
        <section className="faq-section pad-tb">
            <div className="container">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-8">
                        <div class="about-company common-heading mb-5">
                            <h2 data-aos="fade-up" data-aos-delay="100" class="aos-init aos-animate">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center">
                    <div class="col-lg-8">
                        <Accordion>{cd} </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Accordian;
