import React from "react";
import "react-reveal";
const HowItWork = (props) => {
    return (
        <React.Fragment>
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="MuiBox-root">
                                <h6>A product by Finshots</h6>
                                <h1 className="MuiTypography-h4">Think Insurance is Complicated? Well, think again.</h1>
                                <p>With Ditto, you don't just compare insurance. You understand it. Narrow down choices. Avoid pitfalls and make better decisions.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="MuiBox-root">
                                <img src={require("../assets/img/SMC/images/toolsintro.png")} alt="toolsintro" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default HowItWork;
