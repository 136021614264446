/* eslint-disable */
import React from 'react';
const TermLifeInsurance = () => {
    return (
        <section class="productCntMain">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="productCnt">
                            <h3>Term life insurance</h3>
                            <p>
                                This is a type of life insurance policy that provides coverage for a certain period of time, or a specified tenure of years. This policy covers risk only during the selected term period and if the policyholder survives the term, the risk cover comes to an end. If the insured dies during the time period specified in the policy and the policy is active - or in force - then a death benefit will be paid.
                    </p>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/RFL/LifeIns.png")} />
                    </div>
                    <div class="col-md-12">
                        <div class="productCnt">
                            <h4>Savings and  protection</h4>
                            <p>
                                The purpose of any responsible person's life is to create  sufficient wealth and security for the future. Every person works hard  throughout life so that he and his loved ones may enjoy every material comfort  and not want for anything. This often entails compromising on small joys in the  present to enjoy a good life later.&nbsp;<br />
                                <br />
                                        But what if there was a way to enjoy a good  standard of living and also save consistently for future use? RFL offers a  money back insurance policy that creates wealth in the form of periodic  incremental savings. RFL savings with Protection Solutions offers you the  chance to steadily save money in small amounts, with the added advantages of a  large life cover and tax free returns on the endowment insurance plan. These  small savings secure your future, and your loved ones can be assured of  stability even in your absence.
                    </p>

                            <h4>Guaranteed Income</h4>
                            <p>Life is  all about fulfilling your dreams for your family, like providing for children&rsquo;s  education, planning for retirement, etc. However, in an environment which is  full of uncertainty and volatility, you need surety that these dreams will be  fulfilled, even if you are not around.</p>
                            <p>To  ensure that your dreams for your family are fulfilled, Max Life Insurance  brings to you a plan that takes care of your worries related to volatility. Max  Life Guaranteed Income Plan offers guaranteed benefits from the very outset.  The plan provides a Guaranteed Income for a period of 10 years after the Policy  Term. The income payable monthly in the last 5 years of the payout period is  twice the income payable monthly in the first 5 years of the payout period.  This is followed by a one-time guaranteed Terminal Benefit payable at the end  of the Payout Period. Additionally, it ensures that the lifestyle of your  family is protected against any exigencies.</p>


                            <h4>Investment  Linked</h4>
                            <p>
                                Investment-linked insurance policies (ILPs) have both life  insurance and investment components. Your premiums are used to pay for units in  investment–linked sub-fund(s) of your choice. Some of the units you buy are  then sold to pay for insurance and other charges, while the rest remain  invested.&nbsp;&nbsp;<br />
                                <br />
                                                ILPs provide insurance protection in the event  of death or total and permanent disability (TPD), if included. Depending on the  policy, the death or TPD&nbsp;benefit may comprise the higher of the&nbsp;sum  assured or value of ILP units or some combination of the sum assured and  the&nbsp;value of ILP units. How much is paid depends on the value of the units  of the sub-fund at the time.&nbsp;<br />
                                <br />
                                                        Some consumers prefer ILPs because they want  more exposure to investments than other life insurance products may provide.  But if you are more concerned about getting insurance coverage, make sure the  product you buy meets this need. You may need to consider other life insurance  products.&nbsp;<u> </u>
                            </p>



                            <h4>Pension Plan</h4>
                            <p>
                                Gives  the benefits of both <strong>investment</strong> and savings by offering you the most  reliable income source after retirement. It creates a retirement corpus which  is invested on maturity to generate a continuous flow of income for your  expenses. The investment amount shall depend on your <strong>monthly income</strong> requirement during your post <strong>retirement</strong> years. By using retirement calculators, you can easily calculate your expected  pension amount.<br />
                                <br />
                                <strong>Necessary Documents</strong><br />
                                                                    Age Proof<br />
                                                                        Duly Filled Proposal Form<br />
                                                                            Identity Proof<br />
                                                                                Income Proof<br />
                                                                                    Address Proof<br />
                                <br />
                                <br />
                                <strong>Pension plans</strong> are also called as &lsquo;Retirement Plans&rsquo;<br />
                                                                                                    that saves your lifestyle after <strong>retirement</strong>. Due to increasing living  cost, proper planning has become necessary to enjoy comfortable retirement  life. <br />
                                <br />
                                <strong>Pension Plan Types</strong><br />
                                <strong>› Immediate Annuity</strong><br />
                                                                                                                    In an immediate <strong>annuity plan</strong>,  one has to deposit a lump sum amount and instantly, pension will start. It is  further differentiated into following categories:-<br />
                                <br />
                                <strong>&nbsp;&nbsp;&nbsp; Annuity Certain</strong><br />
                                                                                                                                According to this clause, annuitant receives a fixed sum of amount for certain  number of years; the annuitant can decide the period. In case annuitant passed  away before expiring the term period, the annuity will be paid to beneficiary.<br />
                                <br />
                                <strong>&nbsp;&nbsp;&nbsp; Guaranteed Period Annuity</strong><br />
                                                                                                                                            According to this annuity option, annuitant will receive annuity for the term  period and after expiring the term period as well. During the <strong>policy</strong> term, if  annuitant dies, then the annuity will be received by the beneficiary. Pension  will continue throughout life only if annuitant survives.<br />
                                <br />
                                <strong>&nbsp;&nbsp;&nbsp; Life Annuity</strong><br />
                                                                                                                                                        Under this option, annuitant will get <strong>pension income</strong> regularly until death and in case annuitant  selects &lsquo;with spouse&rsquo; choice, then the pension amount will continue and be paid  to the spouse after the death of annuitant.<br />
                                <br />
                                <strong>› Deferred Annuity</strong><br />
                                                                                                                                                                    It helps to build a financial corpus through regular or single  premiums over a policy term. Pension will start when the policy term period is  expired. The accumulated amount is consists of guaranteed additions, bonuses  and sum assured and invested to build flow for regular income.<br />
                                <br />
                                <strong>› National Pension Scheme</strong><br />
                                                                                                                                                                                Government has been launched <strong>New  Pension Plan</strong> for those who want to build pension amount. Put  your <strong>savings</strong> in new pension plan and then, it will be invested in debt market and equity as  per your choice. At the time of retirement, you can withdraw 60 percent of  total amount and remaining 40 percent is used to purchase annuity. You will not  get tax benefit on the maturity amount.<br />
                                <br />
                                <strong>› Pension Funds</strong><br />
                                                                                                                                                                                            This is one of the best ways to generate corpus amount and are meant for long  term period.<br />
                                <br />
                                <strong>Features &amp; Advantages</strong><br />
                                <strong>Tax Benefits </strong><br />
                                                                                                                                                                                                            The final payout is offered in two days. 33 percent of final pay out withdrawn  in lump sum and is not eligible for tax benefits. However, the remaining amount  is taxable. <br />
                                <br />
                                <strong>Minimum Guarantee </strong><br />
                                                                                                                                                                                                                        According to IRDA guidelines, there should be &lsquo;non-zero returns&rsquo; on all  premiums or guaranteed maturity benefits on each pension plan in India. Most  insurers guarantee at least 1% of total premium over the complete term period  of policy.
                    </p>


                            <h4>Child Plan</h4>
                            <p>
                                With  the rise in the cost of education it is very difficult to raise a child without  the best child plan in India. There are two options; either you start saving up  for your child&rsquo;s future on your own or get the best child insurance plan from  trusted insurers. Child education plans and child future plans in India are  structured in a manner that will help you to provide financial support to your  child when there are crucial situations like higher education and marriage  funds involved. <br />
                                <br />
                                                                                                                                                                                                                                There are a lot of factors that should be considered while buying a child  future plan. You will need an insurer that will offer you various products on  child plan and more. We at RFL offer you just that affordable best children  insurance plan for education and future and added benefits. Not only should the  child&rsquo;s education needs should be fulfilled but they should also have a secured  future to finance their crucial needs like starting a new business or getting  married.<br />
                                <br />
                                                                                                                                                                                                                                        When your child grows up and you plan their higher studies you should have the  confidence to have a strong child education plan to let you go through it,  hence we will offer you the best children insurance plans as we are affiliated  to companies who are experts in putting forward best child education plan. Before  you plan to buy insurance for your child, make sure you go through the details  and quotes offered by other companies, this will help you choose the best <a href="http://insurancebazar.in/Life-Child-Plan.aspx">child insurance plan</a>. When  you will come to <a href="http://insurancebazar.in/">Riskfreelife.com</a>, you will  be able to get your quote directly online as we specialize in offering one of  the best online child plan services.
                    </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TermLifeInsurance