import React from "react";
const Product = [

    {
        id: 1,
        name: "Car Insurance" ,
    to: "/Car",
        className: "nav-link mb-3 p-3 shadow",
        src:
             require("./assets/images/icons_Car-Insurance.png"),
  },
  {
    id: 2,
      name: "Bike Insurance",
    to: "/Bike",
      className: "nav-link mb-3 p-3 shadow",
    src:
        require("./assets/images/icons_bike-insurance.png"),
  },
  {
    id: 3,
    name: "PCV Buses/Taxies",
      className: "nav-link mb-3 p-3 shadow",
    to: "/",
    src:
        require("./assets/images/icons_Commercial Insurance.png"),
    },
  {
    id: 4,
    name: "GCV Trucks/Goods",
      className: "nav-link mb-3 p-3 shadow",
    to: "/",
    src:
        require("./assets/images/icons_Commercial Insurance.png"),
    },
  
  {
    id: 5,
    name: "Health Insurance",
      className: "nav-link mb-3 p-3 shadow",
    to: "/Health",
    src:
        require("./assets/images/icons_Health Insurance.png"),
  },
  {
    id: 6,
      name: "Life Insurance",
      className: "nav-link mb-3 p-3 shadow",
    to: "/",
    src:
        require("./assets/images/icons_Life Insurance.png"),
  },
];
export default Product;
