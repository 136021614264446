import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";
import "./assets/css/VedantInsuranceFinal.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import { NavMenu } from "./NavMenu";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import VedantInsuranceFinalScetion from "./VedantInsuranceFinalScetion";
const VedantInsuranceFinal= (props) => {
    return (
        <React.Fragment>
            <div className="VedantInsuranceFinalBody">
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />



                </Layout>

                <Switch>
                    
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    
                    

                    <Route path="/About" component={About} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <VedantInsuranceFinalScetion />
                </Switch>

                <Footer />
                <SideNav />
                <SocialToggle mobile="9324374807" email="service@enivesh.com" whatsapp="9324374807" />
            </div>
        </React.Fragment>
    );
};
export default VedantInsuranceFinal;
