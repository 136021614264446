/* eslint-disable */ 
import React from "react";

const ProductTab = (props) => {
  return (
    <React.Fragment>
    <img src={props.src} id={props.id} onMouseOver={(e)=>e.target.src=props.onMouseOver} onMouseOut={(e)=>e.target.src=props.src} className={props.className}/>
    <p className="tabName">{props.name}<br/>Insurance</p>
    </React.Fragment>
  );
};
export default ProductTab;
