import { useEffect, useState } from 'react';
import { scroller } from "react-scroll";
import { useHistory } from 'react-router-dom';
const Scroller = () => {
    let initi = 1;
    const history = useHistory();
    const [Query, setQuery] = useState(initi)
    useEffect(() => {
        return history.listen((location) => {
            setQuery(initi++);
            console.log(Query)
        })
    }, [history])
    let id;
    function scrollToSection() {
        id = new URLSearchParams(window.location.search).get("id")
        scroller.scrollTo(id, {
            smooth: "easeInOutQuart",
        });
    };
    useEffect(() => { scrollToSection() }, [Query])
}
export default Scroller;