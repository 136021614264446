import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const PensionPlan = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg PensionPlanBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Pension Plan</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">Benefits of Pension/Annuity Plans</h3>
                            <p class="mb-3 padR_160">
                                There's a large part of our population which is fast approaching retirement, making them ideal candidates for pension plans. Some of the benefits common to all pension plans are listed below.
                </p>
                            <div class="ProductPageSectionThirdP_Car padR_160">

                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Provision of Regular Income Post Retirement.svg")} />

                                    </div>
                                    <div class="col-md-10">
                                        <p>
                                            <strong>Provision  of Regular Income Post Retirement</strong> - One of the biggest  benefits of a pension plan is that it provides incomes after retirement. While  some plans provide you with lifelong income, there are others which offer  better returns.<br />
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Funds at Times of Need.svg")} />

                                    </div>
                                    <div class="col-md-10">
                                        <p>
                                            <strong>Funds at  Times of Need</strong> - Some pension plans provide lump sum  payments which can help you meet major expenses through life. Before your  retirement, you may have several major expenses to take care of life purchasing  a house, financing your child&rsquo;s education, etc. <br />
                                        </p>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Tax Benefits.svg")} />

                                    </div>
                                    <div class="col-md-10">
                                        <p>
                                            <strong>Tax  Benefits</strong> - Investing in an insurance policy comes with a set of  tax benefits which you can avail. The same applies to retirement insurance  plans. Check all the policies which you may have short-listed for the tax  benefits they offer. Investing in a pension plan from an early age can help you  save considerably on tax payments. Check your policy details to find out and  understand the ways in which you may benefit from the available provisions of  tax exemption under Section  80C of the Income Tax Act.<br />
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src={require("../assets/img/Religare/lifeinsurance/Insurance Protection.svg")} />
                                    </div>
                                    <div class="col-md-10">
                                        <p>
                                            <strong>Insurance Protection</strong> - In  addition to providing income post retirement, <u>pension plans</u> also provide  insurance cover. This is especially useful to provide protection in the  unfortunate event of a death following which the family&rsquo;s income will be  protected. Insurance cover forms a part of most retirement plans which are  offered by. This is helpful so that the surviving spouse does not have to  undergo the financial burden following an unfortunate event.
                            </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>




            <section class="ProductPageSectionThird">
                <div class="container">
                    <div class="ProductPageLife ProductPageLife03">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="SecondSectionHeadingBox">
                                    <h3 class="SecondSectionHeading">Riders Available with Pension plans</h3>
                                    <p class="text-center">Most pension plans come with add-on riders which can be taken to enhance the benefits provided by the plan. Some of the commonly available riders include</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Accidental death and dismemberment rider.svg")} />

                                    <p><strong>Accidental death and dismemberment rider</strong></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Term Rider.svg")} />

                                    <p><strong>Term rider</strong></p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Critical Illness Rider.svg")} />

                                    <p><strong>Critical illness rider</strong></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Waiver of premium rider.svg")} />
                                    <p><strong>Waiver of premium rider</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
};
export default PensionPlan;
