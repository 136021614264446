/* eslint-disable */
import React from "react";
const ContactMape = (props) => {
    return (
        <section class="text-center location">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.740538684231!2d77.26666741440509!3d28.547517494675837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3e6046ecb67%3A0x3c2914c281924dd6!2s264%2C%20Okhla%20Phase%20III%2C%20Okhla%20Industrial%20Area%2C%20New%20Delhi%2C%20Delhi%20110020!5e0!3m2!1sen!2sin!4v1601565566222!5m2!1sen!2sin" width="600" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            <div class="address-box">
                <h2>Our <span>Location</span></h2>
                <form>
                    <select>
                        <option value="delhi">Delhi</option>
                    </select>
                </form>
                <p class="address">264, Okhla Phase III, Okhla<br /> Industrial Area,<br /> New Delhi, Delhi 110020</p>
            </div>
        </section>
    );
};
export default ContactMape;
