import React from 'react';
import ButtonClass from '../ButtonClass';
const TalkExperts = (props) => {
    return (
        <div id="talktoExperts" className="modal">
            <div className="modal-dialog newModalqt newModalqtPopUpExperts">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" onClick={ props.close} >�</button>
                        <div className="newModalqtPopUp text-center">
                            <h3>TALK TO AN EXPERT</h3>
                            <p>
                                Have questions on insurance purchase?
                        <br /><strong>Our experts will help you out with your requirements.</strong>
                            </p>
                            <h1 className="text-center TalkThanksMsg">
                                Your call has been scheduled.
                                Our expert will call you soon. Thank You.
                    </h1>
                            <input type="tel" placeholder="Enter your mobile number" className="FormSelect" id="talkInput" />

                            <button type="button" className={ButtonClass ()} id="talktoexpertsBtn">Talk to an experts</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
}

export default TalkExperts