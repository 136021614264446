import React from "react";
import "react-reveal";
const SectionSecond = (props) => {
    return (
        <React.Fragment>
            <section class="services-area pt-100 pb-70">
                <div className="container">
                    <div className="sectionSecond">
                        <div className="howitworkCNT text-center MuiBox-root">
                            <h6>The Ditto Experience</h6>
                            <h2 className="MuiTypography-h4">From the folks that brought<br /> you Finshots</h2>
                            <p>Choosing a health or life insurance policy can be extremely overwhelming. <br/>So we&rsquo;ll tell you what we would do if we were you. And you can take it from there. Ditto!!! Get it?</p>
                        </div>

                        <div className="MuiPaper-elevation1 jss75">
                            <div className="MuiPaper-elevation2">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="MuiBox-root">
                                            <img src={require("../assets/img/SMC/images/alreadyhealth.png")} alt="alreadyhealth" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="MuiBox-root">
                                            <h1 className="MuiTypography-h4">Understand your Health insurance policy</h1>
                                            <p>Most policies have hidden terms and conditions buried deep down in the policy brochure. So to make sure you stay clear of unpleasant surprises, we've designed this tool to help you read your policy document in plain English.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="MuiPaper-elevation3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="MuiPaper-elevation3Main">
                                            <div className="MuiPaper-elevation3Box d-flex align-item-center flex-wrap">
                                                <div>
                                                    <img src={require("../assets/img/SMC/images/cutie.svg")} alt="cutie" />
                                                </div>
                                                <div className="ml-3 pt-1">
                                                    <h3>Prarthana C</h3>
                                                    <p className="m-0">Lawyer</p>
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex align-item-center flex-wrap">
                                                <img className="jss64" src={require("../assets/img/SMC/images/quote.png")} alt="quote" />
                                                <p>Thank god I talked to you guys before buying the policy my uncle suggested. You are lifesavers. Literally!!!</p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="MuiPaper-elevation3Main">
                                            <div className="MuiPaper-elevation3Box d-flex align-item-center flex-wrap">
                                                <div>
                                                    <img src={require("../assets/img/SMC/images/handsome.svg")} alt="handsome" />
                                                </div>
                                                <div className="ml-3 pt-1">
                                                    <h3>Prarthana C</h3>
                                                    <p className="m-0">Lawyer</p>
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex align-item-center flex-wrap">
                                                <img className="jss64" src={require("../assets/img/SMC/images/quote.png")} alt="quote" />
                                                <p>Thank god I talked to you guys before buying the policy my uncle suggested. You are lifesavers. Literally!!!</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="MuiPaper-elevation3Main">
                                            <div className="MuiPaper-elevation3Box d-flex align-item-center flex-wrap">
                                                <div>
                                                    <img src={require("../assets/img/SMC/images/footballer.svg")} alt="footballer" />
                                                </div>
                                                <div className="ml-3 pt-1">
                                                    <h3>Prarthana C</h3>
                                                    <p className="m-0">Lawyer</p>
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex align-item-center flex-wrap">
                                                <img className="jss64" src={require("../assets/img/SMC/images/quote.png")} alt="quote" />
                                                <p>Thank god I talked to you guys before buying the policy my uncle suggested. You are lifesavers. Literally!!!</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default SectionSecond;
