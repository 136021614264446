import React, { Component } from 'react';
import { Accordion, Card } from "react-bootstrap"
import "./Question.css"
const QuestionSection = (props) => {
    return (
        <div className="row">
            <div className="col-md-12 seperator">
                <h2 className="text-center">Do you have Questions?</h2><br />
                <div class="dlab-separator"></div>
            </div>
            <div className="col-md-5">
                <img src={require("./img/faq.jpg")} />
            </div>
            <div className="col-md-7">
                <Accordion className="inshoraCard">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="inshoraCardHeader" eventKey="0">
                            1. How to get the most out of your car insurance quotes?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div id="faq1" aria-expanded="true">
                                    <div class="acod-content">If you're looking for car insurance quotes, the good news is it only takes minutes to get a few online. Here are a few tips to help the quote process go smoothly:</div>
                                    <p class="acod-content"><b>Get more than one quote.</b>&nbsp;By getting car insurance quotes from more than one insurer, you're able to understand how insurers differ in their cover and you're better placed to find one that meets your needs and offers good value for money.</p>
                                    <p class="acod-content"><b>Use Google autofill.</b>&nbsp;Since you'll be getting your car insurance quotes one-by-one, every time-saving tip helps. One brilliant way to save time is to use Google autofill. If you use Google Chrome, it can remember your name, address and phone number - and every time it sees you are filling out personal details online, it will give you the option of automatically filling this information into the right fields.</p>
                                    <p class="acod-content"><b>Buy online.</b>&nbsp;Buying online will usually give you a 20 - 30% discount on your premium, simply because you've done most of the work yourself! It's a simple, yet effective way of reducing your costs.</p>
                                </div></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="inshoraCardHeader" eventKey="1">

                            2. What’s the difference between types of car insurance?
                            
          </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body><div class="acod-content">
                                With several different types of car insurance out there to choose from, it can be hard to keep track of what each one may or may not cover. Of course, the inclusions, benefits and
                                optional extras available may vary between the many providers out there, so you should always read the Product Disclosure Document(PDD) of any insurance policy before purchasing it.
            <p class="acod-content">
                                    To help you understand the types of car insurance, we’ve compiled the following
                                    table as a simple way to compare them; however, the information in this table should be used as a guide only.
            </p>
                                <img src={require("./img/faq1.png")} class="img-fluid" />
                            </div></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="inshoraCardHeader" eventKey="2">

                            3. What are extras?
                            
          </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body><div class="acod-content">
                                ‘Extras’ are inclusions or perks bundled into car insurance policies that are competing for your business. Otherwise known as ‘benefits’, some common extras you may find incorporated into your policy are:
            <p class="acod-content">
                                    <b>Zero depreciation.</b>Zero depreciation cover, also called 'zero dep', ‘Nil depreciation’ or “bumper to bumper car insurance” policy, offers complete coverage without factoring in depreciation. It means, if your car gets damaged following a collision, you will receive the entire cost from the insurer.<br />
                                    <b>Consumables.</b> Usually consumables like oil, rubbers, nuts and bolts, filters, lubricants etc. are not covered under comprehensive car insurance policy.  This is an add-on cover available at little extra premium. It covers entire expenses towards consumables which are unfit for further use, arising out of damage due to an accident or any such event.<br />
                                    <b>Engine protection cover.</b>This add-on cover available to protects engine of your car which in general not covered by ordinary comprehensive car insurance policy. This is particularly useful for people who residing in area prone to water logging. This is offered at very economical extra premium by insurance companies.<br />
                                    <b>No Claim Bonus (NCB) protect cover</b>–For each claim free years you will accrue no claim bonus (NCB). This bonus can be used to avail a discount in the renewal premium for the subsequent year. By paying little extra premium for this addon cover you can retain your accrued NCB even after you raise claim with insurer.<br />
                                    <b>Hire car cover.</b> Your insurer may provide you with a hire car when your vehicle is being repaired or replaced.<br />
                                    <b>Roadside assistance.</b> Your insurer may waive roadside assistance membership fees upon taking out cover.<br />
                                    <b>Replacement keys.</b> Your insurer may cover the cost to replace your keys if you were to misplace them.
                <b>Windscreen cover.</b> Your insurer may cover the cost of repairs or replacement, including cracks to your windscreen.<br />
                                    <b>Personal effects cover.</b> Your insurer may cover the cost to replace your valuables in your car (up to a certain value).
            </p>
                            </div>
                                <div class="acod-content">Depending on your insurer, there are various extras available when taking out car cover. Like all insurance products, it’s important to understand the fine print when choosing extras, so that you fully understand what you’re covered for, and how adding extras may affect the cost of your premium.</div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="inshoraCardHeader" eventKey="3">

                            4. How do I find great-value car insurance?
                            
          </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body><div class="acod-content">
                                You can potentially save on premiums by:
            <p class="acod-content">
                                    <b>1.	Paying for your premiums annually.</b> As opposed to monthly or quarterly.<br />
                                    <b>2.	Increasing your basic excess.</b> If you increase your excess, you can typically reduce the cost of your premiums. However, be aware that if you need to claim, you will be paying a greater amount in excess.<br />
                                    <b>3.	Compare often.</b> You might have been on a great value deal a few years ago, but have you reviewed your options lately? Perhaps something cheaper has come along that offers the same benefits, but you won’t know unless you shop around.<br />
                                    <b>4.	Adjust Insured Declared Value (IDV).</b> Insured Declared Value is the maximum Sum Assured fixed by the insurer which is provided on theft or total loss of vehicle. Basically, IDV is the current market value of the vehicle. You can reduce your car premium by fixing lower IDV. However this is not advisable as in case of total loss you will have to settle for less amount than the market price of your vehicle.<br />
                                    <b>5.	Limit your small claims.</b> By claiming small amounts from insurer every year will wipe out your NCB discount which will increase your premium amount. You need to compare cost of fixing your car vs. NCB you will get in each claim free year.<br />
                                    <b>6.	Transfer your NCB.</b> If you are buying a new car after selling your old one, you should transfer your accumulated NCB. This process is very simple. You should collect NCB certificate for your vehicle from the insurance company, and produce while buying a new car policy. If you are not planning to buy new car immediately, you should still get NCB retention certificate from insurer as it is valid for 3 years.


            </p>
                            </div></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="inshoraCardHeader" eventKey="4">

                            5. What do I have to do if another person lodges a claim against me?
                            
          </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body><div class="acod-content">Your insurer is there to do the hard work for you. Contact them immediately if you receive any demands from the other party, their insurer or their lawyer. If someone approaches you in-person or via phone, tell them who you're insured with and that the insurer will be handling the claim. Never admit that you were at fault and don't promise any payments. If you can, take down the other person's name, address, phone number and vehicle info. Then contact your insurer who can tell you what to do next.</div></Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
        </div>

    )
}
export default QuestionSection