import React from "react";
const BestInsurSection = (props) => {
    return (
        <section class="section-2 bg-light-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-9">
                                <h5 class="font-regular text-secondary">
                                    How to find the best insurance policy with
                                    Bimastreet
                            </h5>
                                <p class="mt-2 mt-md-5 mb-0">
                                    We leverage our industry experience to curate the best insurance coverage for
                                    car, two
                                    wheeler, health, and life.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 steps-section mt-5 mt-md-0">

                        <div class="row">
                            <div class="col-md-4">
                                <div class="step-box">
                                    <div class="row">
                                        <div class="col-auto col-md-12">
                                            <figure>
                                                <img src={require("../assets/img/BimaStreet//step1.svg")} />
                                            </figure>
                                        </div>
                                        <div class="col col-md-12">
                                            <h6>Step 1</h6>
                                        Choose a policy to suit your needs from the largest range of insurance companies in India.
                                    </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="step-box">
                                    <div class="row">
                                        <div class="col-auto col-md-12">
                                            <figure><img src={require("../assets/img/BimaStreet//step2.svg")} /></figure>
                                        </div>
                                        <div class="col col-md-12">
                                            <h6>Step 2</h6>
                                        Fill in your details and make payment online to get the policy delivered to your mail account.
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="step-box">
                                    <div class="row">
                                        <div class="col-auto col-md-12">
                                            <figure><img src={require("../assets/img/BimaStreet//step3.svg")} /></figure>
                                        </div>
                                        <div class="col col-md-12">
                                            <h6>Step 3</h6>
                                        Enjoy twin benefits - best policy at best premium and FREE claims assistance
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};
export default BestInsurSection;
