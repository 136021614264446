/* eslint-disable */ 
import React from "react";
const Chat = (props) => {
  return (
    <div className="chat">
      <img src={require("../assets/img/chat.png")} alt="chat" />
      <span style={{ fontSize: "9px", fontWeight: "bold" }}>Chat Now</span>
    </div>
  );
};

const ContactUs = (props) => {
  return (
    <div className="contactUs">
      <img src={require("../assets/img/contact.png")} alt="chat" />
      <span style={{ fontSize: "9px", fontWeight: "bold" }}>Contact Us</span>
    </div>
  );
};
export default Chat;
export { ContactUs };
