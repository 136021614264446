/* eslint-disable */ 
import React from "react";
import TrustUs from "../TrustUs";
const Advantages = (props) => {
    return (

        <div className="advantages">
            <div className="advantagesBG"><img src={require("../assets/img/border.PNG")} /></div>

          <div className="container">
      <p className="text-center">
        <h1 className="mt-5 pt-5">Hero IBIL Advantages</h1>
        <br />
      </p>
      <div className="advantagesContainer">
      <div className="row d-flex justify-content-center">
      <div className="col-sm-6 advantages-txt">
        <h4>Commitment, Always for the Long Haul</h4>
        <p className="text-red pt-3">
          Yes, at <b>Hero</b> , we believe in long term associations
        </p>
        <ul className="checklist-ul">
          <li>
            We have always tried to push the envelop in terms of our service
            delievery! By doing so, we have earned the previlege of being one
            of the most trusted brands.
          </li>
          <li className="pt-2">
            
            Hero IBIL is Certified and Licensed by the Insurance Regulatory
            and Development Authority of India(IRDA). Also we have partners
            from varied Insurance Sectors, and this makes us a one-stop
            solution for all your insurance need
          </li>
        </ul>

        <p>
          <b>
            So, if you are planning to purchase an insurance policy, look no
            further
          </b>
        </p>
      </div>
      <div className="col-sm-5 d-flex justify-content-center">
        <TrustUs name="miles.PNG" alt="member" className="img002" />
      </div>
    </div>
    <div className="row">
      <div className="col-sm-5 d-flex justify-content-center">
        <TrustUs name="prices.PNG" alt="member" className="img002" />
      </div>
      <div className="col-sm-6 advantages-txt ">
        <h3>Easy Purchases at Attractive Price</h3>
        <p className="text-red pt-3">
          We will make you an offer you cannot refuse. The insurance policies
          provided by us
        </p>
        <p>
          <ul className="checklist-ul">
            <li>Have extensive coverage</li>
            <li>Most competitive premium</li>
            <li> No tadious paperwork or stringent documentation</li>
          </ul>
        </p>

        <p className="text-red">
          With <b>HERO</b> purchasing an Insurance policy could not have been
          more convenient.
        </p>
        <ul className="checklist-ul">
          <li>Just compare different plans through a single click</li>
          <li>Choose the best suited policy</li>
        </ul>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6 advantages-txt">
        <p>
          <h3>We've Got Your Back</h3>
        </p>
        <p className="text-red">
          HERO will be there for you <b>24 x 7!</b> you are important to us,
          and we cheris that.
        </p>
        <ul className="checklist-ul">
          <li>
            Our in-house support team focuses on providing a positive customer
            experiance, both at the point of sale and after the sale.
          </li>
          <li>
            We provide round-the-clock assistance to ensure that all the
            customer queries and grievances are addressed and resolved at the
            earliest.
          </li>
        </ul>
        <p>
          <b>
            Providing you a top-notch service is, and will always be our first
            and foremost priority.{" "}
          </b>
        </p>
      </div>
      <div className="col-sm-5 d-flex justify-content-center">
        <TrustUs name="back.PNG" alt="member" className="img002" />
      </div>
    </div>
    <div className="row pb-6">
      <div className="col-sm-5 d-flex justify-content-center">
        <TrustUs name="full.PNG" alt="member" className="img002" />
      </div>
      <div className="col-sm-6 advantages-txt">
        <p>
          <h3>Zero Hassle, Full Support</h3>
        </p>
        <p className="text-red">
          Apart from buying an insurance policy, you can also renew your
          policy online within minuts with us
        </p>
        <ul className="checklist-ul">
          <li> 
            Our hassle-free renewal process can help you with timely policy
            renewal, there by protecting you against unknown financial risks.
          </li>
          <li>
            Additionally filling claims is also simple with us at HERO IBIL!
            we partner with reputable insurance providers having a high claim
            settlement ratio.This helps us in delivering a quality services.
          </li>
        </ul>
        <p>
          <b>
            Have a stress-free experience of renewal and filling claims at
            HERO IBIL!
          </b>
        </p>
      </div>
    </div>
   
      </div>
      <div className="row d-flex justify-content-center pt-6">
        <div className="col-8 text-center">
          <h2 style={{marginTop:"40px", marginBottom:"30px"}}>
            The&nbsp;
            <span className="text-red" style={{ fontSize: "30px", fontWeight:"600" }}>
              HERO&nbsp;
            </span>
            Legacy
          </h2>
          <p style={{paddingBottom:"40px"}}>
            A relationship is a two-way street. When you choose to put your
            trust in us, we strive to stand true to your expectations and guide
            you throughout your journey with us. Our age-old Hero legacy set by
            the founders, aims to maintain and preserve our relationship with
            customers by offering customer-made plans and quality customer
            service in your hour of need
          </p>
        </div>
      </div>
          </div>
      </div>
  );
};
export default Advantages;
