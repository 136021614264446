/* eslint-disable */ 
import React from 'react';
const HealthInsurance=()=>{
    return(
        <section class="productCntMain">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="productCnt">
                            <h3>Health Insurance</h3>
                            <p>Health is very precious and maintaining a good health is really difficult. The best mediclaim policy covers expensive medical costs during accidents, sudden illness and surgeries. The best health insurance policy in India also includes doctor’s fees, charges on your room, diagnostic and medical facilities, ambulance service cap, oxygen, blood, medicines and things like artificial limbs or pacemaker etc which may arise in future. Mediclaim policies are the best way to handle crisis situations like injury or illness at any age in life with ease. It takes away the burden of medical expenditures, whether planned or sudden. Policyholder may get cashless facility based on insurance company only if insured visit one of their networked hospitals. Health insurancecompanies in India will pay the medical bills of the insured when policyholder gets injured in an accident or becomes sick. Healthcare plans are available for an individual and families as well. Buy best health insurancepolicy for family and you can easily afford the best medical facilities for them. It ensures that all hospitalization aspects are well covered, so that you can concentrate your attention on good health of your family.</p>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/RFL/HealthIns.png")} />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HealthInsurance