/* eslint-disable */
import axios from 'axios';
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { NavbarBrand } from 'reactstrap'
import { MasterGlobal } from '../App'
import ButtonClass, { ChildBtn } from '../ButtonClass';
import DataLoader from '../Car/Loader/DataLoader';
import MotorQuoteFooter from '../Car/MotorQuoteFooter';
import ShareButton from '../Car/ShareButton';
import ProgressBarBox from '../components/ProgressBarBox';
import CompareBox from './CompareBox';
import HealthCard from './HealthCard';
import HealthQuoteFooter from './HealthQuoteFooter';
import Loader from './Loader';
import LoaderBox from './LoaderBox';
import PlanDetails from './PlanDetails';
import SideCompareModel from './SideCompareModel';
import SideModel from './SideModel';

export class HealthQuotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Req: null,
            QuoteReq: {},
            SumInsured: 500000,
            PolicyTerm: 1,
            PremiumOrder: 1,
            QuoteResponse: [],
            IsLoader: true,
            LoaderList: [],
            coverValue: "₹5 - ₹ 6 Lacs",
            /* --------------------------------Update Result-------------------------------- */
            Gender: null,
            PinCode: null,
            MobileNo: null,
            IsYou: false,
            YouAge: null,
            IsSpouse: false,
            SpouseAge: null,
            IsFather: false,
            FatherAge: null,
            IsMother: false,
            MotherAge: null,

            isValid: true,
            IsShowInsuredChildren: true,
            TblChild: [],
            SonAge1: null,
            sonAge2: null,
            sonage3: null,
            sonage4: null,
            DoughterAge1: null,
            DoughterAge2: null,
            DoughterAge3: null,
            DoughterAge4: null,
            AdultCount: null,
            ChildCount: null,
            PolicyType: null,

            /* --------------------------------Update Result-------------------------------- */
            LoaderValue: [1, 2, 3, 4, 5, 6],
            APIUrl: null,
            CRMAPI: null,
            showPlanDetails: false,
            showCompare: false,
            sideModel: false,
            cover: false,

            addonsOpen: false,
            BasePlanOpen: false,
            keyid: null,
            compareItem: [],
            sideM: false,
            /* ------------------------------Addons-------------------------------------*/
            NCBSuper: false,
            HospitalCash: false,
            MaternityCover: false,
            RestoreBenefits: false,
            CriticalIllness: false,
            HealthCheck: false,
            HomeCare: false,
            UAR: false,
            AnyRoomUpgrade: false,
            PersonalAccident: false,
            ExpertOpinion: false,
            CBB: false,
            OPWMC: false,
            TopUpPlan: false,
            DeductableSI: "0",
            redirect: false,
            isLoaded: false,
            totalMember: 0,
            NotResponding: [],
            max: 600000,
            min: 500000,
            filterResponse: [],
            filterKeyList: [],

            /* --------------------------------end---------------------------------------*/

            isFilter: false,
            activeFilter: [],
            ViewOption: "1",
            IsHide: false,
            WishList: [],
            IsSort: false
        }

        // this.state.APIUrl=MasterGlobal.PortalAPI
        // this.state.CRMAPI = MasterGlobal.CRMAPI;
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm")
        let url = new URLSearchParams(window.location.search);
        if (url.has("policyEnq")) {
            this.checkSharedURL(url.get("policyEnq"));
        } else {
            if (this.props.location.state !== undefined && this.props.location.state.Req !== undefined && this.props.location.state.Req !== null)
                this.state.Req = this.props.location.state.Req;
            else {
                alert("Some Error Occured")
                this.state.redirect = true;
            }
        }

        this.showSideModel = this.showSideModel.bind(this);
        this.showCompareBox = this.showCompareBox.bind(this);
        this.planDetails = this.planDetails.bind(this)
        this.coverPopup = this.coverPopup.bind(this)
        this.setCoverValue = this.setCoverValue.bind(this)
        this.hideQuote = this.hideQuote.bind(this);
        this.createWishlist = this.createWishlist.bind(this);
        this.addonsDpDw = this.addonsDpDw.bind(this)
        this.BasePlan = this.BasePlan.bind(this)
        this.handle = this.handle.bind(this)
        this.updateResult = this.updateResult.bind(this)
        this.addMore = this.addMore.bind(this)
        this.manageChildList = this.manageChildList.bind(this)
        this.showBottomPannel = this.showBottomPannel.bind(this)
        this.clearBottomPannel = this.clearBottomPannel.bind(this)
        this.sideCompare = this.sideCompare.bind(this)
        this.addonsCalculate = this.addonsCalculate.bind(this)
        this.removeChild = this.removeChild.bind(this);
        this.SetPremiumOrder = this.SetPremiumOrder.bind(this);
        this.removeItem = this.removeItem.bind(this);
        console.log(this.state.Req)

    }
    checkSharedURL = (enq) => {

        let url = this.state.APIUrl;
        if (url.match("inshora")) {
            url = "https://inshoracover.com"
        }
        let Model = {
            URL: url + "/healthapi/api/InsurerHealth/GetShareQuotes?EnquiryNo=" + enq
        }

        axios.post("/bpi/WeatherForecast/CallPOST", Model)
            .then((Res) => {
                let data = Res.data;
                console.log(data)
                if (data.HealthRequest !== null) {
                    let quoteReq = window.sessionStorage.getItem("HealthQuoteReq");
                    let quoteRes = window.sessionStorage.getItem("HealthQuoteRes");
                    if (quoteReq != null || quoteRes !== null) {
                        window.sessionStorage.removeItem("HealthQuoteReq");
                        window.sessionStorage.removeItem("HealthQuoteRes");
                    }
                    window.sessionStorage.setItem("HealthQuoteReq", data.HealthRequest.replaceAll(/\\/g, ""));

                    this.state.Req = JSON.parse(window.sessionStorage.getItem("HealthQuoteReq"));

                    //window.sessionStorage.setItem("HealthQuoteReq", data.HealthRequest.substring(1, data.HealthRequest.length - 1));

                    this.state.isLoaded = true;
                    this.setState({ isLoaded: true });
                    this.componentDidMount();
                } else {
                    this.state.redirect = true;
                    this.setState({ redirect: true });
                }
            })

    }
    componentDidMount() {

        this.setState({ ViewOption: window.localStorage.getItem("ViewOption") });
        let wl = window.sessionStorage.getItem("wishlist");
        if (wl !== null) {
            this.setState({ WishList: JSON.parse(wl) });
        }
        if (this.state.Req === null) {
            return
        }
        let Req = this.state.Req.HealthEnquiry;
        this.state.IsYou = Req.UserAge !== null ? true : false;
        this.state.YouAge = Req.UserAge;
        this.state.IsSpouse = Req.SpouseAge !== null ? true : false
        this.state.SpouseAge = Req.SpouseAge;
        this.state.IsFather = Req.FatherAge !== null ? true : false;
        this.state.FatherAge = Req.FatherAge
        this.state.IsMother = Req.MotherAge !== null ? true : false;
        this.state.MotherAge = Req.MotherAge
        this.state.SonAge1 = Req.SonAge1;
        this.state.SonAge2 = Req.SonAge2;
        this.state.SonAge3 = Req.SonAge3;
        this.state.SonAge4 = Req.SonAge4;
        this.state.DoughterAge1 = Req.DoughterAge1;
        this.state.DoughterAge2 = Req.DoughterAge2;
        this.state.DoughterAge3 = Req.DoughterAge3;
        this.state.DoughterAge4 = Req.DoughterAge4;
        this.state.MobileNo = this.state.Req.MobileNo;
        this.state.PinCode = Req.PinCode;
        this.state.Gender = Req.Gender;
        this.state.TblChild = Req.TblChild;
        this.state.PolicyType = Req.PolicyType;
        this.state.AdultCount = Req.AdultCount
        this.state.ChildCount = Req.ChildCount

        for (let i = 0; i < 5; i++) {
            this.state.LoaderList.push(i);
        }
        this.setState({
            LoaderList: this.state.LoaderList
        })
        let quoteReq = window.sessionStorage.getItem("HealthQuoteReq");
        let quoteRes = window.sessionStorage.getItem("HealthQuoteRes");
        let NotResponding = window.sessionStorage.getItem("NotResponding");
        if (NotResponding != null) {
            this.state.NotResponding = JSON.parse(NotResponding);
        }
        if (quoteRes == null) {
            this.Quotes();
        }
        else {
            this.state.QuoteResponse = JSON.parse(quoteRes);
            this.setState({ IsLoader: false, isLoaded: true, QuoteResponse: this.state.QuoteResponse });
        }

    }
    handle(e) {
        switch (e.target.name) {
            case "ViewOption": window.localStorage.setItem("ViewOption", e.target.value);
                break;
            case "Gender": this.state.Req.HealthEnquiry.Gender = this.state.Gender

                break;
            case "PinCode": if (this.state.PinCode !== null) {
                if (this.state.PinCode.length === 6) {
                    /*this.state.Req.HealthEnquiry.PinCode = this.state.PinCode*/
                    this.setState({ PinCode: e.target.value })
                }
            }
                break;
            case "MobileNo": if (this.state.MobileNo !== null) {
                if (this.state.MobileNo.length === 10) {
                    this.state.Req.MobileNo = this.state.MobileNo
                }
            } break;
            case "policyTerm": this.state.PolicyTerm = e.target.value;
                this.state.SumInsured = this.state.min;
                this.state.QuoteResponse = [];
                this.Quotes();
                break;
            case "PremiumOrder": if (e.target.value !== '0') {
                this.state.PremiumOrder = e.target.value
                this.setState({ PremiumOrder: e.target.value })
            }
                this.Quotes();
                break;
            case "DeductableSI": if (e.target.value === "0")
                return alert("Select Deductable SI");
                this.state.DeductableSI = e.target.value;
                this.state.SumInsured = this.state.min;
                this.Quotes();

                break;
        }
        if (e.target.type == "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        } else if (e.target.type == "radio") {
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }
    addonsCalculate() {
        this.setState({ addonsOpen: !this.state.addonsOpen });
        this.state.SumInsured = this.state.min;
        this.state.QuoteResponse = [];
        this.Quotes();
    }
    updateResult() {
        if (this.state.MobileNo === null || this.state.MobileNo === "")
            return alert("Please Enter MobileNo")

        if (this.state.MobileNo !== null) {
            if (this.state.MobileNo.length !== 10) {
                return alert("Please Enter Valid Mobile Number")
            }
        } else this.state.Req.MobileNo = this.state.MobileNo
        if (this.state.PinCode === null || this.state.PinCode === "")
            return alert("Please Enter MobileNo")
        if (this.state.PinCode !== null) {
            if (this.state.PinCode.length !== 6) {
                return alert("Please Enter Valid Pin Code")
            }
        } else this.state.Req.HealthEnquiry.PinCode = this.state.PinCode

        if (this.state.IsSpouse) {

            if (this.state.SpouseAge === null && this.state.SpouseAge <= 0) {

                return alert("Please Select Spouse Age")
            }

        }
        if (this.state.IsYou) {
            if (this.state.YouAge === null || this.state.YouAge <= 0) {

                return alert("Please Select Your Age");
            }

        }
        if (this.state.IsFather) {
            if (this.state.FatherAge === null || this.state.FatherAge <= 0) {

                return alert("Please Select Father Age");
            }

        }
        if (this.state.IsMother) {
            if (this.state.MotherAge === null || this.state.MotherAge <= 0) {
                this.state.Req.HealthEnquiry.MotherAge = 0
                return alert("Please Select Mother Age");
            }

        }

        let adultCount = 0;
        let childCount = this.state.TblChild.filter((row) => row.Age > 0).length;
        if (this.state.IsYou && this.state.YouAge !== null) adultCount += 1;
        if (this.state.IsSpouse && this.state.SpouseAge !== null) adultCount += 1;
        if (this.state.IsFather && this.state.FatherAge !== null) adultCount += 1;
        if (this.state.IsMother && this.state.MotherAge !== null) adultCount += 1;
        this.state.SonAge1 =
            childCount > 0
                ? this.state.TblChild[0].ChildGender == "B"
                    ? this.state.TblChild[0].Age
                    : null
                : null
        this.state.SonAge2 =
            childCount > 1
                ? this.state.TblChild[1].ChildGender == "B"
                    ? this.state.TblChild[1].Age
                    : null
                : null
        this.state.SonAge3 =
            childCount > 2
                ? this.state.TblChild[2].ChildGender == "B"
                    ? this.state.TblChild[2].Age
                    : null
                : null
        this.state.SonAge4 =
            childCount > 3
                ? this.state.TblChild[3].ChildGender == "B"
                    ? this.state.TblChild[3].Age
                    : null
                : null
        this.state.DoughterAge1 =
            childCount > 0
                ? this.state.TblChild[0].ChildGender == "G"
                    ? this.state.TblChild[0].Age
                    : null
                : null
        this.state.DoughterAge2 =
            childCount > 1
                ? this.state.TblChild[1].ChildGender == "G"
                    ? this.state.TblChild[1].Age
                    : null
                : null
        this.state.DoughterAge3 =
            childCount > 2
                ? this.state.TblChild[2].ChildGender == "G"
                    ? this.state.TblChild[2].Age
                    : null
                : null
        this.state.DoughterAge4 =
            childCount > 3
                ? this.state.TblChild[3].ChildGender == "G"
                    ? this.state.TblChild[3].Age
                    : null
                : null

        this.state.AdultCount = adultCount;
        this.state.ChildCount = childCount;
        this.state.Req.HealthEnquiry.UserAge = this.state.YouAge;
        this.state.Req.HealthEnquiry.SpouseAge = this.state.IsSpouse ? this.state.SpouseAge : null;
        this.state.Req.HealthEnquiry.FatherAge = this.state.IsFather ? this.state.FatherAge : null;
        this.state.Req.HealthEnquiry.MotherAge = this.state.IsMother ? this.state.MotherAge : null;
        this.state.Req.HealthEnquiry.DoughterAge1 = this.state.DoughterAge1;
        this.state.Req.HealthEnquiry.DoughterAge2 = this.state.DoughterAge2
        this.state.Req.HealthEnquiry.DoughterAge3 = this.state.DoughterAge3
        this.state.Req.HealthEnquiry.DoughterAge4 = this.state.DoughterAge4
        this.state.Req.HealthEnquiry.SonAge1 = this.state.SonAge1
        this.state.Req.HealthEnquiry.SonAge2 = this.state.SonAge2
        this.state.Req.HealthEnquiry.SonAge3 = this.state.SonAge3
        this.state.Req.HealthEnquiry.SonAge4 = this.state.SonAge4
        this.state.Req.HealthEnquiry.AdultCount = adultCount;
        this.state.Req.HealthEnquiry.ChildCount = childCount;
        this.state.PolicyType = adultCount == 1 ? "Individual" : "Family Floter";
        this.setState({ sideModel: !this.state.sideModel })
        this.state.SumInsured = this.state.min;
        this.state.QuoteResponse = [];
        this.Quotes();




    }
    addMore(e) {
        switch (e.target.name) {
            case "Children":
                this.setState({
                    IsShowInsuredChildren: !this.state.IsShowInsuredChildren,
                });
                break;
            case "AddMoreChild":
                if (this.state.TblChild.length < 4) {
                    let child = {
                        index: this.state.TblChild.length,
                        ChildGender: "B",
                        Age: null,
                    }
                    this.state.TblChild.push(child);
                    this.setState({ TblChild: this.state.TblChild, IsShowInsuredChildren: true });

                } else alert("Child Can't Exceed")
                break;
        }
    }
    manageChildList(index, name, value) {
        let data = this.state.TblChild.filter((row) => row.index == index)[0];
        if (name == "Gender") {
            data.ChildGender = value;
        } else if (name == "Age") {
            data.Age = value;
        }
        this.state.TblChild[index] = data;
        this.setState({ TblChild: this.state.TblChild });
        console.log(this.state.TblChild)
    }

    Quotes() {

        if (this.state.Req.EnquiryNo == "" || this.state.Req.EnquiryNo.match("error")) {
            alert("Some Error Occured");
            return;
        }
        this.state.IsLoader = true;
        this.setState({ IsLoader: this.state.IsLoader, isLoaded: true });
        let ListOfInsurer = [];
        let Insurer = null;
        var date = new Date();
        let setDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let setMonth = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        if (this.state.Req.HealthEnquiry.UserAge != null) {
            Insurer = {
                Gender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.UserAge)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Self"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.SpouseAge != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.SpouseAge)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Spouse"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.FatherAge != null) {
            Insurer = {
                Gender: "M",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.FatherAge)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Father"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.MotherAge != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.MotherAge)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Mother"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.SonAge1 != null) {
            Insurer = {
                Gender: "M",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.SonAge1)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Son"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.SonAge2 != null) {
            Insurer = {
                Gender: "M",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.SonAge2)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Son"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.SonAge3 != null) {
            Insurer = {
                Gender: "M",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.SonAge3)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Son"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.SonAge4 != null) {
            Insurer = {
                Gender: "M",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.SonAge4)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Son"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.DoughterAge1 != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.DoughterAge1)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Daughter"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.DoughterAge2 != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.DoughterAge2)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Daughter"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.DoughterAge3 != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.DoughterAge3)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Daughter"
            }
            ListOfInsurer.push(Insurer)
        }
        if (this.state.Req.HealthEnquiry.DoughterAge4 != null) {
            Insurer = {
                Gender: "F",
                ProposarGender: this.state.Req.HealthEnquiry.Gender == 1 ? "M" : "F",
                DateOfBirth: (date.getFullYear() - parseInt(this.state.Req.HealthEnquiry.DoughterAge4)) + "-" + setMonth + "-" + setDate + " 13:20:38",
                ProposarRelationton: "Daughter"
            }
            ListOfInsurer.push(Insurer);

        }

        let QuoteReq = {
            "SumInsured": parseInt(this.state.SumInsured),
            "PolicyTerm": this.state.PolicyTerm,
            "PinCode": this.state.Req.HealthEnquiry.PinCode,
            "MobileNumber": this.state.Req.MobileNo,
            "EnquiryNo": this.state.Req.EnquiryNo,
            "PremiumOrder": this.state.PremiumOrder,
            "ClientURL": this.state.APIUrl.match("inshora") ? "inshoracover.com" : this.state.APIUrl,
            "AnyRoomUpgrade": this.state.AnyRoomUpgrade,
            "UAR": this.state.UAR,
            "NCBSuper": this.state.NCBSuper,
            "HospitalCash": this.state.HospitalCash,
            "MaternityCover": this.state.MaternityCover,
            "RestoreBenefits": this.state.RestoreBenefits,
            "CriticalIllness": this.state.CriticalIllness,
            "PersonalAccident": this.state.PersonalAccident,
            "HealthCheck": this.state.HealthCheck,
            "HomeCare": this.state.HomeCare,
            "ExpertOpinion": this.state.ExpertOpinion,
            "CBB": this.state.CBB,
            "OPWMC": this.state.OPWMC,
            "TopUpPlan": this.state.TopUpPlan,
            "DeductableSI": this.state.DeductableSI,
            "ListOfInsurer": ListOfInsurer,
            Term: this.state.PolicyTerm,

        }
        this.state.QuoteReq = QuoteReq;
        this.setState({ QuoteReq });
        console.log(JSON.stringify(QuoteReq))


        let apipath = this.state.APIUrl + "/healthapi/api/InsurerHealth/getallhealthpremium";
        let Model = {
            URL: apipath,
            PostString: JSON.stringify(QuoteReq)
        }
        axios.post("/bpi/WeatherForecast/CallCheck", Model)

            .then(Res => {
                let traversevalue = 50000;
                if (this.state.SumInsured >= 1000000) {
                    traversevalue = 100000;
                }
                if (this.state.SumInsured >= 2000000) {
                    traversevalue = 500000;
                }
                this.state.SumInsured = parseInt(this.state.SumInsured) + parseInt(traversevalue);
                try {
                    let QuoteData = Res.data.filter(row => row.TotalPremium > 0);
                    let NotResponding = Res.data.filter(row => row.TotalPremium == 0);
                    if (QuoteData.length > 0) {
                        QuoteData.forEach(row => this.state.QuoteResponse.push(row));
                        this.state.QuoteResponse = this.state.QuoteResponse.filter(row => row.SumInsured <= this.state.max && row.SumInsured >= this.state.min)
                    }
                    if (NotResponding.length > 0) {
                        NotResponding.forEach(row => this.state.NotResponding.push(row));
                        this.state.NotResponding = this.state.NotResponding.filter(row => row !== null && row.SumInsured <= this.state.max && row.SumInsured >= this.state.min)
                    }
                    console.log(QuoteData);
                } catch (err) {
                    console.log(err)
                }


                if (this.state.SumInsured > this.state.max) {
                    console.log(this.state.NotResponding)
                    this.setState({ IsLoader: false })
                    this.setState({
                        QuoteResponse: this.state.QuoteResponse.sort((a, b) => a.BasePremium - b.BasePremium),
                        NotResponding: this.state.NotResponding.length !== null && this.state.NotResponding != null && this.state.NotResponding.length > 0 ? [...new Set(this.state.NotResponding.map(item => item !== null && item != undefined && item.UrlLogo))] : [],
                        filterResponse: this.state.QuoteResponse,
                        filterKeyList: [...new Set(this.state.QuoteResponse.map(item => item.CompanyName))]
                    })
                    let HealthSaveReq = {
                        shareButton: true,
                        share: false,
                        SharedEmail: "",
                        SaveReqData: true,
                        EnquiryNo: this.state.Req.EnquiryNo,
                        ShareFor: "Health_Quote",
                        Quotedata: this.state.Req,
                        quoteRes: "",
                    }
                    new ShareButton(HealthSaveReq);
                    try {
                        window.sessionStorage.setItem('NotResponding', JSON.stringify(this.state.NotResponding));
                        window.sessionStorage.setItem('HealthQuoteReq', JSON.stringify(this.state.Req));
                        window.sessionStorage.setItem('HealthReq', JSON.stringify(QuoteReq));
                        window.localStorage.setItem("HealthQuoteRes", JSON.stringify(this.state.QuoteResponse))
                    }
                    catch (err) {
                        console.log(err)
                    }
                } else {
                    this.Quotes();
                }


            })


    }

    filterResponseData(keyValue, SearchValue) {
        if (SearchValue === "0" || SearchValue.length === 0) {
            this.setState({ filterResponse: this.state.QuoteResponse });
            return;
        }
        // console.log("searchvalue: ", SearchValue);
        let data = [];
        let found = [];
        //for each (if array type)
        if (Array.isArray(SearchValue)) {
            SearchValue.forEach((element, i) => {
                data = this.state.QuoteResponse.filter(
                    (row) => row[keyValue] === SearchValue[i]
                );
                found.push(...data);

                //  this.state.Found= [...this.state.Found, data]
            });

            this.setState({ filterResponse: found, isFilter: false });
        }

        // data = this.state.QuoteResponse.filter(
        //   (row) => row[keyValue] === SearchValue
        // );
        // this.setState({ filterResponse: data });

        // console.log(`data`, data);
    }
    Buynow(key) {
        let InsurerData = this.state.QuoteResponse.filter(row => row.Key === key)[0];
        delete InsurerData["PlanFeatures"];
        delete InsurerData["MainFeatures"];
        delete InsurerData["networkHospitals"];
        window.sessionStorage.setItem("HealthKey", InsurerData.Key)
        let Model = {
            URL: this.state.APIUrl + "/healthapi/api/HealthPolicy/AddNewHealthPolicyDetail",
            PostString: JSON.stringify(InsurerData)
        };
        axios.post("/bpi/WeatherForecast/CallCheck", Model)
            .then(Res => {
                if (Res.data === true) {
                    this.props.history.push("/HealthProposal", { data: InsurerData, ClientID: this.state.Req.ClientID, PinCode: this.state.Req.HealthEnquiry.PinCode, QuoteReq: this.state.QuoteReq, Adult: this.state.AdultCount, Child: this.state.ChildCount })
                }
            })


    }
    planDetails = (e) => {
        this.setState({ showPlanDetails: e.target.key })


    }

    showCompareBox = (e) => {
        this.setState({ showCompare: !this.state.showCompare })

    }
    showSideModel = () => {
        this.setState({ sideModel: !this.state.sideModel })

    }
    coverPopup(e) {
        this.setState({ cover: !this.state.cover })
        if (e.target.id === "coverApply") {
            this.Quotes()
        } else this.setState({ SumInsured: 500000 })

    }
    setCoverValue(e) {
        let coverValue = this.state.coverValue;
        coverValue["InsuredValue"] = e.target.value;
        this.setState({ coverValue: coverValue })

    }
    addonsDpDw() {
        this.setState({ addonsOpen: !this.state.addonsOpen })
    }
    BasePlan() {
        this.setState({ BasePlanOpen: !this.state.BasePlanOpen })
    }
    showBottomPannel(e) {

        if (e.target.checked) {
            if (this.state.compareItem.length <= 2) {
                let isValid = true;
                let v = this.state.QuoteResponse.filter((a) => a.Key == e.target.value)[0];
                this.state.compareItem.filter((row) => {
                    if (row.CompanyID == e.target.value)
                        return isValid = false;
                });

                if (isValid) {
                    this.setState({ showCompare: false })
                    this.state.compareItem.push(v);
                }
                this.setState({ showCompare: true })


            } else {
                e.target.checked = false;
                alert("You Can only select 3 Insurer")
            }
        } else {
            this.removeItem(e.target.value);
        }
        if (!this.state.showCompare) {
            this.setState({ showCompare: true })
        }
        console.log(this.state.compareItem)
    }
    removeItem(key) {
        try {
            let li = this.state.compareItem;
            li = li.filter(row => row.Key !== key);
            li.length === 0 ? this.setState({ showCompare: false, compareItem: [] }) : this.setState({ compareItem: li });
            document.getElementById(key).checked = false;
        } catch (err) {
            console.log(err);
        }
    }
    clearBottomPannel() {
        this.setState({ showCompare: false })
        this.state.compareItem = []
        console.log(this.state.compareItem)
        this.uncheckAll()
    }
    sideCompare(e) {
        let id = document.getElementById("mySidenav");
        id.classList.add("open_mySidenav");

        if (e === "CL" || e.target.id === "close123") {
            id.classList.remove("open_mySidenav")
        }

        //this.setState({sideM:!this.state.sideM})

    }
    uncheckAll() {
        var checks = document.querySelectorAll('.checkCompare');
        for (var i = 0; i < checks.length; i++) {
            var check = checks[i];

            check.checked = false;

        }
    }
    removeChild(e) {
        let id = e.target.id;
        let TblChild = this.state.TblChild;
        let index = TblChild.indexOf(id)
        if (id > -1) {
            TblChild.splice(index, 1);

        }
        this.setState({ TblChild })

    }
    mobileView(e) {
        let id;
        switch (e.target.id) {
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "block";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "block";
                break;
        }
    }

    onFilterChange(item) {
        console.log(`item`, item);
        const { filterKeyList, activeFilter } = this.state;
        console.log(filterKeyList, activeFilter)
        if (item === "ALL") {
            this.setState({ activeFilter: [] });
        } else {
            if (activeFilter.includes(item)) {
                const filterIndex = activeFilter.indexOf(item);
                const newFilter = [...activeFilter];
                newFilter.splice(filterIndex, 1);
                this.setState({ activeFilter: newFilter });
            } else {
                this.setState({ activeFilter: [...activeFilter, item] });
            }
        }
    }



    hideFilter(e) {
        let id;
        switch (e.target.id) {
            case "hidefilter_1": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "hidefilter_2": id = document.getElementById("filter_2");
                id.style.display = "none";

                break;
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "none";
                break;
        }
    }

    SetPremiumOrder(e) {
        if (e === 1)
            this.state.filterResponse.sort((a, b) => a.BasePremium - b.BasePremium);
        else this.state.filterResponse.sort((a, b) => b.BasePremium - a.BasePremium);
        this.setState({ PremiumOrder: e, IsSort: false });

    }

    //abhi
    hideQuote(id) {
        console.log(id);
        if (id === "Clear") {
            this.setState({
                filterResponse: this.state.QuoteResponse,
                IsHide: false,
            });
            window.sessionStorage.setItem(
                "QuoteResponse",
                JSON.stringify(this.state.QuoteResponse)
            );
            return;
        }
        let filternew = this.state.filterResponse.filter((row) => row.Key !== id);
        this.setState({ filterResponse: filternew, IsHide: true });
        window.sessionStorage.setItem("QuoteResponse", JSON.stringify(filternew));
    }
    async createWishlist(e, id) {
        if (e.target.style.color == "rgb(183, 180, 180)") {
            e.target.style.color = "#ffc107";
            await this.setState({ WishList: [...this.state.WishList, id] });
        } else {
            e.target.style.color = "rgb(183, 180, 180)";
            await this.setState({
                WishList: this.state.WishList.filter((row) => row !== id),
            });
        }
        window.sessionStorage.setItem(
            "wishlist",
            JSON.stringify(this.state.WishList)
        );
    }

    render() {
        const { filterKeyList, activeFilter } = this.state;
        if (!this.state.isLoaded)
            return <DataLoader />
        let LoaderData = LoaderBox;
        return (
            this.state.redirect ? <Redirect to="/" /> :
                <div className="QuotePage" id="firstDiv" style={{ pointerEvents: this.state.IsLoader ? "none" : "auto" }} >
                    {!(this.state.APIUrl.match("smc") || this.state.APIUrl.match("smc")) && < ShareButton SaveReqData={false} ShareFor="Health_Quote" SharedEmail={this.state.Req.MobileNo} share={true} shareButton={false} Quotedata={this.state.Req} quoteRes={this.state.QuoteResponse} EnquiryNo={this.state.Req.EnquiryNo} wishList={JSON.stringify(this.state.WishList)} />}
                    <ProgressBarBox Progress="40%" />
                    <div className="quotesDetails">

                        <ul className="mo_flt_main" id="mo_flt_ul">
                            <li id="opn_one"><span className="btn_filter" data-target="#filter_1" data-toggle="tab" aria-expanded="true" id="editDetails" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-edit" aria-hidden="true"></i> Edit Details</span></li>

                            <li id="opn_two"><span className="btn_filter" data-target="#filter_2" data-toggle="tab" aria-expanded="true" id="applyFilter" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-edit" aria-hidden="true"></i> Apply Filter</span></li>
                        </ul>
                        <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetailsRowHealth quotesDetailsRow003Grid m-0" : "row quotesDetailsRow quotesDetailsRowHealth m-0"} id="filter_1">
                            <button type="button" className="close btn-success" id="hidefilter_1" onClick={this.hideFilter}>×</button>
                            <div className="col-md-10 NoPadding" onClick={() => this.setState({ BasePlanOpen: false })}>
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Basic Details: </p>
                                    <span>Adult:<strong><b>{this.state.Req.HealthEnquiry.AdultCount}</b> member</strong></span>
                                    {this.state.Req.HealthEnquiry.ChildCount !== 0 && <span>Child:<strong>{this.state.Req.HealthEnquiry.ChildCount} member</strong></span>}
                                    {this.state.Req.HealthEnquiry.UserAge !== null && this.state.Req.HealthEnquiry.UserAge !== "" && <span>You:<strong> Age - {this.state.Req.HealthEnquiry.UserAge}</strong></span>}
                                    {this.state.Req.HealthEnquiry.SpouseAge !== null && this.state.Req.HealthEnquiry.SpouseAge !== "" && <span>Spouse:<strong> Age - {this.state.Req.HealthEnquiry.SpouseAge}</strong></span>}
                                    {this.state.Req.HealthEnquiry.FatherAge !== null && this.state.Req.HealthEnquiry.FatherAge !== "" && <span>Father:<strong> Age - {this.state.Req.HealthEnquiry.FatherAge}</strong></span>}
                                    {this.state.Req.HealthEnquiry.MotherAge !== null && this.state.Req.HealthEnquiry.MotherAge !== "" && <span>Mother:<strong> Age - {this.state.Req.HealthEnquiry.MotherAge}</strong></span>}
                                    {this.state.Req.HealthEnquiry.SonAge1 !== null && this.state.Req.HealthEnquiry.SonAge1 !== "" && <span>Son:<strong> Age - {this.state.Req.HealthEnquiry.SonAge1}</strong></span>}
                                    {this.state.Req.HealthEnquiry.SonAge2 !== null && this.state.Req.HealthEnquiry.SonAge2 !== "" && <span>Son:<strong> Age - {this.state.Req.HealthEnquiry.SonAge2}</strong></span>}
                                    {this.state.Req.HealthEnquiry.SonAge3 !== null && this.state.Req.HealthEnquiry.SonAge3 !== "" && <span>Son:<strong> Age - {this.state.Req.HealthEnquiry.SonAge3}</strong></span>}
                                    {this.state.Req.HealthEnquiry.SonAge4 !== null && this.state.Req.HealthEnquiry.SonAge4 !== "" && <span>Son:<strong> Age - {this.state.Req.HealthEnquiry.SonAge4}</strong></span>}
                                    {this.state.Req.HealthEnquiry.DoughterAge1 !== null && this.state.Req.HealthEnquiry.DoughterAge1 !== "" && <span>Daughter:<strong> Age - {this.state.Req.HealthEnquiry.DoughterAge1}</strong></span>}
                                    {this.state.Req.HealthEnquiry.DoughterAge2 !== null && this.state.Req.HealthEnquiry.DoughterAge2 !== "" && <span>Daughter:<strong> Age - {this.state.Req.HealthEnquiry.DoughterAge2}</strong></span>}
                                    {this.state.Req.HealthEnquiry.DoughterAge3 !== null && this.state.Req.HealthEnquiry.DoughterAge3 !== "" && <span>Daughter:<strong> Age - {this.state.Req.HealthEnquiry.DoughterAge3}</strong></span>}
                                    {this.state.Req.HealthEnquiry.DoughterAge4 !== null && this.state.Req.HealthEnquiry.DoughterAge4 !== "" && <span>Daughter:<strong> Age - {this.state.Req.HealthEnquiry.DoughterAge4}</strong></span>}
                                    <span onClick={this.showSideModel} className="EditHealth"><i className="far fa-edit" aria-hidden="true"></i> Edit</span>
                                </div>
                            </div>
                            {!this.state.APIUrl.match("enivesh") && <div className="col-md-2 NoPadding">
                                <div className="quotesDetailsIn">
                                    <div className="BasePlan">
                                        <div onClick={this.BasePlan} className="text-right">
                                            <span><strong>Base Product <i className="fa fa-chevron-down" aria-hidden="true"></i> </strong></span>
                                        </div>
                                        {this.state.BasePlanOpen ?
                                            <div className="BasePlanBox" >
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input type="checkbox" name="BasePlane" checked /> Base Product
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input type="checkbox" name="TopUpPlan" onChange={this.handle} value={this.state.TopUpPlan} checked={this.state.TopUpPlan} /> Topup
                                                    </label>

                                                </div>
                                                {this.state.TopUpPlan && <div className="CheckBoxColor">
                                                    <select id="selectedDeductbleSI" onChange={this.handle} name="DeductableSI" value={this.state.DeductableSI} class="form-control">
                                                        <option value="0">Select Deductable SI</option>
                                                        <option value="100000">100000</option>
                                                        <option value="200000">200000</option>
                                                        <option value="300000">300000</option>
                                                        <option value="400000">400000</option>
                                                        <option value="500000">500000</option>
                                                        <option value="600000">600000</option>
                                                        <option value="700000">700000</option>
                                                        <option value="800000">800000</option>
                                                        <option value="900000">900000</option>
                                                        <option value="1000000">1000000</option>
                                                    </select>
                                                </div>}
                                                {/*<div className="CheckBoxColor">
                                                    <label>
                                                        <input type="radio" name="Base Plane" /> Sanjivani
                                                    </label>
                                                </div>*/}
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetails002Row quotesDetails002RowGrid" : "row quotesDetailsRow quotesDetails002Row m-0"} id="filter_2" onClick={() => this.setState({ BasePlanOpen: false })}>
                            <button type="button" className="close btn-success" id="hidefilter_2" onClick={this.hideFilter}>×</button>
                            <div className="col-md-2 NoPadding">
                                <div className="quotesDetailsIn ">
                                    <p className="quoteP">Sum Assured</p>
                                    <span onClick={this.coverPopup}>Cover :
                                        <strong>{this.state.coverValue}</strong> <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                    </span>
                                    {this.state.IsLoader ? null : <React.Fragment>
                                        {this.state.cover ?
                                            <div className="addONSdrop2 SumAssuredPP text-left" onClick={() => this.setState({ BasePlanOpen: false })} id="UpdateInsured">

                                                <div className="text-center IdvPopUpHead">
                                                    Cover (in Lacs)
                                                <button type="button" className="close" onClick={this.coverPopup}>×</button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="filter_mobile_drop">
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="100000" onChange={(e) => this.setState({ min: e.target.value, max: 200000, SumInsured: e.target.value, coverValue: "₹1 - ₹ 2 Lacs" })} checked={this.state.min == '100000'} />    <span>₹1 - ₹ 2 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="300000" onChange={(e) => this.setState({ min: e.target.value, max: 400000, SumInsured: e.target.value, coverValue: "₹3 - ₹ 4 Lacs" })} checked={this.state.min == '300000'} />    <span>₹3 - ₹ 4 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="500000" onChange={(e) => this.setState({ min: e.target.value, max: 600000, SumInsured: e.target.value, coverValue: "₹5 - ₹ 6 Lacs" })} checked={this.state.min == '500000'} />    <span>₹ 5 - ₹ 6 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="600000" onChange={(e) => this.setState({ min: e.target.value, max: 750000, SumInsured: e.target.value, coverValue: "₹6 - ₹ 7.5 Lacs" })} checked={this.state.min == '600000'} />    <span>₹ 6 - ₹ 7.5 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="800000" onChange={(e) => this.setState({ min: e.target.value, max: 1000000, SumInsured: e.target.value, coverValue: "₹8 - ₹ 10 Lacs" })} checked={this.state.min == '800000'} />  <span>₹ 7.5 - ₹ 10 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="1000000" onChange={(e) => this.setState({ min: e.target.value, max: 1500000, SumInsured: e.target.value, coverValue: "₹10 - ₹ 15 Lacs" })} checked={this.state.min == '1000000'} />  <span>₹ 10 - ₹ 15 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="1500000" onChange={(e) => this.setState({ min: e.target.value, max: 2500000, SumInsured: e.target.value, coverValue: "₹15 - ₹ 25 Lacs" })} checked={this.state.min == '1500000'} />  <span>₹ 15 - ₹ 25 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="2500000" onChange={(e) => this.setState({ min: e.target.value, max: 5000000, SumInsured: e.target.value, coverValue: "₹25 - ₹ 50 Lacs" })} checked={this.state.min == '2500000'} />  <span>₹ 25 - ₹ 50 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="5100000" onChange={(e) => this.setState({ min: e.target.value, max: 7500000, SumInsured: e.target.value, coverValue: "₹51 - ₹ 75 Lacs" })} checked={this.state.min == '6000000'} />  <span>₹ 50 - ₹ 75 Lacs</span></label>
                                                        <label className="span_filter_cover_min">
                                                            <input type="radio" name="InsuredValue" value="7600000" onChange={(e) => this.setState({ min: e.target.value, max: 10000000, SumInsured: e.target.value, coverValue: "₹75 - ₹ 1 Cr" })} checked={this.state.min == '10000000'} /> <span>₹ 75 - ₹ 1 Cr</span></label>

                                                    </div>
                                                </div>
                                                <div className="modal-footer text-center">
                                                    <button className={ChildBtn() + " w-auto m-auto pl-4 pr-4"} id="coverApply" onClick={this.coverPopup} >Apply</button>
                                                </div>
                                            </div> : null
                                        }</React.Fragment>
                                    }
                                </div>
                            </div>


                            <div className="col-md-3 NoPadding pl-4 pl-0Res" onClick={() => this.setState({ BasePlanOpen: false })} >
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Addons</p>
                                    <span onClick={this.addonsDpDw}><strong>Select Addons <i className="fa fa-chevron-down" aria-hidden="true"></i> </strong></span>
                                    {this.state.IsLoader ? null : <React.Fragment>{this.state.addonsOpen ?
                                        <div className="addONSdrop2 text-left" >
                                            <button type="button" className="close btn-success" onClick={this.addonsDpDw} aria-hidden="true" id="closeBtn">×</button>
                                            <div id="first2">
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="HealthCheck" name="HealthCheck" type="checkbox" value="true" checked={this.state.HealthCheck} onChange={this.handle} />

                                                        Health Check
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input
                                                            id="HomeCare" name="HomeCare" type="checkbox" value="true" checked={this.state.HomeCare} onChange={this.handle} />
                                                        Home Care
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="CriticalIllness" checked={this.state.CriticalIllness} onChange={this.handle} name="CriticalIllness" type="checkbox" value="true" />

                                                        Critical Illness
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="RestoreBenefits" checked={this.state.RestoreBenefits} name="RestoreBenefits" onChange={this.handle} type="checkbox" value="true" />

                                                        Restore Benefits
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="MaternityCover" checked={this.state.MaternityCover} onChange={this.handle} name="MaternityCover" type="checkbox" value="true" />
                                                        Maternity Cover
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="HospitalCash" checked={this.state.HospitalCash} onChange={this.handle} name="HospitalCash" type="checkbox" value="true" />
                                                        Hospital Cash
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="NCBSuper" checked={this.state.NCBSuper} onChange={this.handle} name="NCBSuper" type="checkbox" value="true" />
                                                        NCB-Super
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="UAR" checked={this.state.UAR} onChange={this.handle} name="UAR" type="checkbox" value="true" />
                                                        Unlimited Auto.Recharge
                                                    </label>
                                                </div>
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input id="AnyRoomUpgrade" checked={this.state.AnyRoomUpgrade} onChange={this.handle} name="AnyRoomUpgrade" type="checkbox" value="true" />
                                                        Any Room Upgrade
                                                    </label>
                                                </div>

                                            </div>
                                            <button className={ButtonClass()} id="searchBtn" onClick={this.addonsCalculate}>Re-Calculate</button>
                                        </div>
                                        : null}</React.Fragment>
                                    }
                                </div>
                            </div>

                            <div className="col-md-3 NoPadding pl-4 pl-0Res">

                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Sort Plans</p>
                                    <span onClick={() => this.setState({ IsSort: !this.state.IsSort })}><strong>{this.state.PremiumOrder === 1 ? "Premium Ascending" : "Premium Descending"} <i class="fa fa-chevron-down" aria-hidden="true"></i></strong></span>
                                    {this.state.IsSort && <div className="InsurerDropDown text-left" > <div className="InsurerDropDownHead">
                                        <h3>SORT PLANS</h3>
                                        <p>Pick plans based on your preferred premium!</p>
                                        <button type="button" className="close btn-success" onClick={() => this.setState({ IsSort: false })} aria-hidden="true" id="closeBtn">×</button>
                                    </div>
                                        <div id="first2">
                                            <div className="CheckBoxColor ShortByPlans">


                                                <label className={this.state.PremiumOrder === 1 ? "active" : ""} >{this.state.PremiumOrder === 1 && <i class="fas fa-sort-amount-up-alt"></i>}<a value="1" onClick={() => this.SetPremiumOrder(1)}>Premium Ascending</a></label>
                                                <label className={this.state.PremiumOrder === 2 ? "active" : ""} >{this.state.PremiumOrder === 2 && <i class="fas fa-sort-amount-down-alt"></i>}<a value="2" onClick={() => this.SetPremiumOrder(2)}>Premium Descending</a></label>


                                            </div></div>
                                    </div>}
                                    {/* <select id="PremiumOrder" onChange={this.SetPremiumOrder} name="PremiumOrder" value={this.state.PremiumOrder} className="ShortBy" ng-model="PremiumOrder" ng-change="CallAgain();">
                                        <option value="0">Sort By:</option>
                                        <option value="1">Premium Ascending</option>
                                        <option value="2">Premium Descending</option>
                                    </select>*/}
                                </div>
                            </div>
                            <div className="col-md-2 NoPadding pl-4 pl-0Res">
                                <div className="quotesDetailsIn">
                                    <p className="quoteP">Insurers</p>

                                    <span
                                        onClick={() =>
                                            this.setState({ isFilter: !this.state.isFilter })
                                        }
                                    >
                                        <strong>
                                            Select Insurers{" "}
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>{" "}
                                        </strong>
                                    </span>
                                    {this.state.isFilter && (
                                        <div className="InsurerDropDown InsuranceCompany text-left" >
                                            <div className="InsurerDropDownHead">
                                                <h3>Insurance Company</h3>
                                                <p>Pick plans based on your preferred brands!</p>
                                                <button type="button" className="close btn-success" onClick={() => this.setState({ isFilter: false })} aria-hidden="true" id="closeBtn">×</button>
                                            </div>
                                            <div id="first2">
                                                <div className="CheckBoxColor">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => this.onFilterChange("ALL")}
                                                            checked={activeFilter.length === filterKeyList.length}
                                                        ></input>
                                                        Clear
                                                    </label>
                                                </div>
                                                {this.state.filterKeyList.map((item, i) => (
                                                    <div className="CheckBoxColor">
                                                        <label key={i}>
                                                            <input
                                                                id={i}
                                                                type="checkbox"
                                                                checked={activeFilter.includes(item)}
                                                                onClick={() => this.onFilterChange(item)}
                                                            ></input>
                                                            {item}
                                                        </label>
                                                    </div>
                                                ))}

                                            </div>
                                            <button
                                                className="InsuMandiSmall btn-block"
                                                onClick={() =>
                                                    this.filterResponseData("CompanyName", activeFilter)
                                                }
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-2 NoPadding pl-4 pl-0Res">
                                <div className="quotesDetailsIn border-0">
                                    <p className="quoteP">Policy Term</p>
                                    <select id="termterm" name="policyTerm" onChange={this.handle} className="ShortBy form-control" style={{ width: "100%" }} value={this.state.PolicyTerm}>
                                        <option value="1" >1 Year</option>
                                        <option value="2">2 Year</option>
                                        <option value="3">3 Year</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className={this.state.ViewOption === "2" ? "quotesDetails quotesDetailsGrid" : "quotesDetails"} id="carResult" onClick={() => this.setState({ BasePlanOpen: false })}>
                        <div className="ExclusiveGSTRow">
                            <h2>{!this.state.IsLoader ? <span>{this.state.filterResponse.length}</span> :
                                <i class="fas fa-spinner fa-spin"></i>} Plans Found</h2>
                            <div className="row">
                                <div className="PTC_BoxMarTop">
                                    {this.state.IsHide && (
                                        <small
                                            className="ClearQuote"
                                            onClick={() => this.hideQuote("Clear")}
                                        >
                                            <i className="far fa-times-circle"></i>
                                            <br />
                    Clear Selection
                                        </small>
                                    )}
                +
              </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <p>Prices Exclusive of GST</p>
                                </div>

                                <div className="PTC_BoxMarTop PTC_BoxMarTopHealth">
                                    <div className="PolicyTypeCondition PolicyTypeConditionHealth">
                                        <div className="PTC_Box d-flex">
                                            <i class="far fa-bell"></i>
                                            <p>As the pandemic <b>Covid expenses</b> are covered in <b>all plans</b> during Hospitalization</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="ncbBox EnuquryNumber">
                                        <p>Lead ID :<span className="ng-binding"> {this.state.Req.EnquiryNo}</span></p>
                                        <select name="ViewOption" onChange={this.handle} value={this.state.ViewOption} className="form-control ViewQuotes w-auto">
                                            <option value="1">Grid View</option>
                                            <option value="2">List View</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.IsLoader ? <div className={this.state.ViewOption === "2" ? "row quotesDetailsGridRow" : "row"} onClick={() => this.setState({ BasePlanOpen: false })} >
                                {this.state.LoaderValue.map((row, i) => (
                                    <HealthCard mainFeatures={row.MainFeatures} CompanyName="" PlanCode="" key={i} IsLoader={this.state.IsLoader} mainFeatures={row.MainFeatures} src={LoaderData[row - 1].src} insured="500000" />
                                ))}</div>
                                :


                                <div className={this.state.ViewOption === "2" ? "row quotesDetailsGridRow" : "row"}>
                                    {this.state.filterResponse.map((row, i) => (<React.Fragment>

                                        <HealthCard src={row.UrlLogo} keyv={i} mainFeatures={row.MainFeatures}
                                            planName={row.PlanName} IsLoader={this.state.IsLoader} Item={row.CompanyID}
                                            compare={this.showBottomPannel} insured={row.SumInsured} ID={row.Key}
                                            btnClick={(e) => this.Buynow(row.Key)} planPrice={row.BasePremium}
                                            detailsClk={(e) => this.setState({ keyid: row.Key })}
                                            hideQuote={this.hideQuote} ViewOption={this.state.ViewOption}
                                            createWishlist={this.createWishlist} CompanyName={row.CompanyName} PlanCode={row.PlanCode}
                                        />
                                        { (row.Key == this.state.keyid) ? <PlanDetails planName={row.PlanName} close={(e) => this.setState({ keyid: null })} key={i} src={row.UrlLogo} planDetails={row.PlanFeatures} mainFeatures={row.MainFeatures} networkHospitals={row.networkHospitals} brouchure={row.Brochure} sumInsured={row.SumInsured} premiumPerYear={row.TotalPremium} /> : null}

                                    </React.Fragment>

                                    ))} </div>

                        }




                        {
                            this.state.sideModel ? <SideModel
                                pincode={this.state.PinCode}
                                mobile={this.state.Req.MobileNo}
                                close={this.showSideModel}
                                Gender={this.state.Req.HealthEnquiry.Gender}
                                UserAge={this.state.YouAge}
                                SpouseAge={this.state.SpouseAge}
                                FatherAge={this.state.FatherAge}
                                MotherAge={this.state.MotherAge}
                                SonAge1={this.state.Req.HealthEnquiry.SonAge1}
                                DoughterAge1={this.state.Req.HealthEnquiry.DoughterAge1}
                                onChange={this.handle}
                                onSubmit={this.updateResult}
                                manageChildList={
                                    this.manageChildList}
                                addMore={this.addMore}
                                IsShowInsuredChildren={this.state.IsShowInsuredChildren}
                                TblChild={this.state.TblChild}
                                IsSpouse={this.state.IsSpouse}
                                IsMother={this.state.IsMother}
                                IsFather={this.state.IsFather}
                                IsYou={this.state.IsYou}
                                removeChild={this.removeChild}

                            /> : null
                        }

                    </div>
                    {this.state.NotResponding.length > 0 && <HealthQuoteFooter IsLoader={false} ListItem={this.state.NotResponding} />}
                    {
                        this.state.showCompare ? <CompareBox RemoveItem={(key) => this.removeItem(key)} sideModel={this.sideCompare} Item={this.state.compareItem} clear={this.clearBottomPannel} /> : null
                    }

                    <SideCompareModel Item={this.state.compareItem} BuyNow={(Key) => this.Buynow(Key)} PlanDetails={key => { this.sideCompare("CL"); this.clearBottomPannel(); this.setState({ keyid: key }) }} closebtn={this.sideCompare} />

                </div>
        )
    }
}