import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section className="pt-5 pb-5">
                <div class="container">
                    <div className="servicesHeading">
                        <h2>
                            This Gives Both Expertise And Dynamism.
                        </h2>
                        <p>We are a composite broking company dealing in life insurance general insurance and reinsurance.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/industry.png")} />
                                <p>Vast Industry Knowledge &amp; Technical Understanding of Insurance Products</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/Professionals.png")} />
                                <p>Highly Qualified Team of Professionals </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/Innovation.png")} />
                                <p>Clients Orientation and Product Innovation</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/Support.png")} />
                                <p>Customer Support</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/ClaimA.png")} />
                                <p>Protecting Your Interest in the Event of Claim</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/Relationship.png")} />
                                <p>Relationship Building</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/experienceB.png")} />
                                <p>One of the Oldest  &amp; Experienced Broker</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/growth.png")} />
                                <p>Optimising Risk Cost for the Benefit of Our Clients</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className=" UspBg">
                                <img src={require("../assets/img/BMB/company.png")} />
                                <p>Established in the Insurance Industry for Past 16 Years</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
