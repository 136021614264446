import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
import { Accordion, Card } from "react-bootstrap"
const MotorInsurance = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg MotorInsBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Motor Insurance</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading text-left mb-3">What is Motor Insurance?</h3>
                            <p>Your vehicle also needs best Insurance covers, call our experts<br /> to take maximum add on covers you want along with  0 dep, <br />key replacement cover, engine protection, NCB protection,<br /> road side assistance and many more. Our experts will suggest<br /> you the best two wheeler policy along with best coverage's<br /> in a competitive price. </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>



            <section class="ProductPageSectionThird">
                <div class="container">
                    <h3 class="SecondSectionHeading">Frequently Asked Questions</h3>
                    <div class="row" id="accordion-style-1">
                        <div class="col-md-12 mx-auto">
                            <Accordion className="ReligareCard">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="0">
                                        I want to opt my car Insurance policy for 5 years?
                            </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div id="faq1" aria-expanded="true">
                                                You can opt your vehicle Insurance maximum upto 3 years.
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="1">
                                        What is medical cover in motor policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Medical payments coverage is an element of auto insurance that will help pay for your medical costs if you�re in a car accident, no matter who is at fault.
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="2">
                                        Who should opt for a Zero depreciation cover?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div class="acod-content">
                                                � New car owners<br />
                                                � Luxury/Expensive car owners<br />
                                                � People living in high-risk areas<br />
                                                � New drivers<br />
                                                � Car owners who worry about small dents and bumps<br />
                                                � Car owners who worry about expensive spare parts
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="3">
                                        My vehicle is 9 years old, I want 0 dep policy?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <div class="acod-content">
                                                0 dep can�t be offer more than 5 years age of vehicle.
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="4">
                                        Do car Insurance companies check mileage?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            <div class="acod-content">
                                                Car mileage is one of the main factors that insurers use to calculate your Insurance premium.
                                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} className="ReliCardHeader" eventKey="5">
                                        I have accident in my Rental car?
                        </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                            <div class="acod-content">
                                                If the rental car�s bodywork gets damaged during your rental, your Collision Damage Waiver will cover it.
                                </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default MotorInsurance;
