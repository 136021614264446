import React from "react";
import "react-reveal";
const ExistingFeatures = (props) => {
    return (
        <div class="enquire-form pad-tb pora-bg1 text-white" id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="cta-heading text-center">
                            <span class="subhead aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">Why Choose Pora</span>
                            <h3 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">Live Your Best Life Today, Your Tomorrow Is Secured With Us</h3>
                        </div>
                        <div class="whyus mt60">
                            <div class="whyusbox aos-init aos-animate" data-aos="fade-In" data-aos-delay="100">
                                <div class="imgbdr shadows"> <img src={require("../assets/img/Vedant/student.png")} alt="icon" /> </div>
                                <p>Child's Education</p>
                            </div>
                            <div class="whyusbox aos-init aos-animate" data-aos="fade-In" data-aos-delay="200">
                                <div class="imgbdr shadows"> <img src={require("../assets/img/Vedant/oldman.png")} alt="icon" /> </div>
                                <p>Care-free Retirement</p>
                            </div>
                            <div class="whyusbox aos-init aos-animate" data-aos="fade-In" data-aos-delay="300">
                                <div class="imgbdr shadows"> <img src={require("../assets/img/Vedant/security.png")} alt="icon" /> </div>
                                <p>Financial Security</p>
                            </div>
                            <div class="whyusbox aos-init aos-animate" data-aos="fade-In" data-aos-delay="400">
                                <div class="imgbdr shadows"> <img src={require("../assets/img/Vedant/insurance.png")} alt="icon" /> </div>
                                <p>Family's Protection</p>
                            </div>
                            <div class="whyusbox aos-init aos-animate" data-aos="fade-In" data-aos-delay="500">
                                <div class="imgbdr shadows"> <img src={require("../assets/img/Vedant/wealth.png")} alt="icon" /> </div>
                                <p>Wealth Creation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ExistingFeatures;
