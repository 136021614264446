import React from "react";

const About = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <React.Fragment>
            <section class="summeryPage">
                <div class="main-counter-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="section-heading-2 section-heading-2-p">
                                    <h3>About Us</h3>
                                    <p>
                                        Kare India Insurance broker is a reputed insurance broking organisation incorporated in 2003 duly licensed by the IRDA.
                            <br />Kare was one of the players to enter the insurance sector in an organized way when it was opened to private participation. We are one of India’s leading Insurance Intermediaries with our corporate Office in Delhi NCR. We are a team of accomplished professionals and are ready to provide 24/ 7 insurance services to our valuable clients.
                                                                <br />Our area of operation includes dealing with all private and public sector insurance companies, and critically corelate the risk analysis needs best available in the products offered by them for your proposal, be they Life or General insurance.

                                        </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row counter-area-small">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/manager.png")} />
                                            <br /> <span class="counter">1800</span><span>+</span>
                                            <p>projects</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/chart.png")} />
                                            <br /><span class="counter">1000</span><span>+</span>
                                            <p>clients</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/coin.png")} />
                                            <br /> <span class="counter">150</span><span>k+</span>
                                            <p>profit</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 margin-top-sb-30">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/KareIndia/theme.png")} /> <br /> <span class="counter">500</span><span>+</span>
                                            <p>coming</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="space bg-image">
                <div class="container">
                    <p>
                            <br />As a consumer, our offer to you is professional advice consistent with your needs, very competitive premium and post sales services.You must understand the insurance product thoroughly within multiple options available to arrive at a considered decision which best serves your interest.
                            <br />Motivated by the vision to emerge as a leading insurance intermediary for retail and corporate consumers across regions & segments, we at KARE INDIA INSURANCE BROKER are committed to nurture and grow clients through a great buying experience, delivered through state of art technological interventions.
                        
                    </p>
                    {/*<div class="row">*/}
                    {/*    <div class="col-md-6 expertise-image">*/}
                    {/*        <div class="expertise-image">*/}
                    {/*            <img src={require("./assets/img/KareIndia/banner2.png")} />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="col-md-6">*/}
                    {/*        <div class="insurance-title_wrap insurance-title_wrap2">*/}
                    {/*            <div class="section-heading-2 section-heading-2-p">*/}
                    {/*                <h4>Experties</h4>*/}
                    {/*                <h3>Marketing &amp; Design</h3>*/}
                    {/*                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam placerat rutrum turpis, sit amet hendrerit metus vulputate id. Nulla laoreet maximus feugiat. Nullam rhoncus, orci vitae convallis hendrerit feugiat venenatis. Nulla laoreet maximus feugiat.</p>*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>
            
    </React.Fragment>
  );
};
export default About;
