import React from "react";
import "react-reveal";
const SectionFive = (props) => {
    return (
        <React.Fragment>
            <section className="pt-100 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="MuiBox-root">
                                <h1 className="MuiTypography-h4">Ditto Insights</h1>
                                <p>A compilation of our best articles on insurance and how to think about insurance</p>
                                <ul className="AdviceList">
                                    <li><i class="las la-check"></i> Short reads</li>
                                    <li><i class="las la-check"></i> Simple Language</li>
                                    <li><i class="las la-check"></i> Pointed Advice</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="OnLifeBox">
                                <img src={require("../assets/img/SMC/images/ditto-save4.webp")} alt="ditto-save4" />
                                <div className="MuiBox-root">
                                    <h3>On Life, Death, and Insurance</h3>
                                    <p>Anybody who has the buying power should ideally have term insurance. And yet people don't seem to think it's important. Why? Why is this the case?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </React.Fragment>
    );
};
export default SectionFive;
