import React, { useState } from "react";
import { Modal, Container, Button, Form } from "react-bootstrap";
import CheckLogin from "../Login/CheckLogin";
// function MydModalWithGrid(props) {
//   return (
//     <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           <h3>
//             <i className="fa fa-envelope"></i>&nbsp;Login Form
//           </h3>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="show-grid">
//         <Container>
//           <Form>
//             <Form.Group controlId="formBasicEmail">
//               <Form.Label>Email address</Form.Label>
//               <Form.Control type="email" placeholder="Enter email" />
//             </Form.Group>

//             <Form.Group controlId="formBasicPassword">
//               <Form.Label>Password</Form.Label>
//               <Form.Control type="password" placeholder="Password" />
//             </Form.Group>

//             <Button variant="primary" type="submit">
//               Submit
//             </Button>
//           </Form>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

function CustomerLogin() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <React.Fragment>
      <a style={{ color: "black" }} href="https://www.onlinebimakaro.com/myaccount/UserPolicy/Login">
        {CheckLogin()?"GO TO CRM":<React.Fragment><i className="fa fa-user-plus" aria-hidden="true"></i>
        &nbsp; Customer Login</React.Fragment>}
      </a>

    </React.Fragment>
  );
}
export default CustomerLogin;
