import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section>
                <div className="services-area">
                    <div className="">
                        <img src={require("../assets/img/Riskovery/TopBgRiskovery.png")} />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 d-flex align-items-center">
                                <div className="service">
                                    <h4>
                                        Our Services &nbsp;
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                    </h4>
                                    <h2>Services We Provides</h2>
                                    <p>
                                        Now you can explore ,Compare and buy Car ,Bike and Health
                                        Insurance policies on the comfort of your smart phone ,an
                                        impeccable blend of Technology and Insurance Knowledge ,Call it
                                        InsureTech!
            </p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-7">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                                        <div class="row section-heading-2Left">
                                            <div class="col-xl-12">
                                                <div class="single-service">
                                                    <div class="service-icon">
                                                        <i class="fas fa-car" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="service-text">
                                                        <h3>Four Wheeler</h3>
                                                        <p>
                                                            Vehicle insurance is insurance for cars, Its primary use is to provide financial protection against physical damage or bodily injury resulting from traffic collisions and against liability that could also arise from incidents in a vehicle
                                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12">
                                                <div class="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                                    <div class="service-icon">
                                                        <i class="fas fa-motorcycle" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="service-text">
                                                        <h3>Two Wheeler</h3>
                                                        <p>
                                                            Two-wheeler insurance is a type of insurance that is mandatory in India. Falling under the General insurance product category, it helps protect people against accidents that take place on the road.
                                            </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 margin-top-sb-30">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="single-service">
                                                    <div class="service-icon">
                                                        <i class="fas fa-heartbeat" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="service-text">
                                                        <h3>Life</h3>
                                                        <p>Life insurance is a contract between an insurance policy holder and an insurer or assurer, where the insurer promises to pay a designated beneficiary a sum of money in exchange for a premium, upon the death of an insured person</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12">
                                                <div class="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                                    <div class="service-icon">
                                                        <i class="fas fa-heart" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="service-text">
                                                        <h3>Health</h3>
                                                        <p>Health insurance is an insurance that covers the whole or a part of the risk of a person incurring medical expenses, spreading the risk over numerous persons </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-xl-12">
                                                <div class="single-service service-mt-30 aos-init aos-animate" data-aos="fadein" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos-duration="1000">
                                                    <div class="service-icon">
                                                        <i class="fas fa-truck" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="service-text">
                                                        <h3>Commercial</h3>
                                                        <p>Commercial  Vehicle insurance is insurance for  trucks, Commercial vehicles. Its primary use is to provide financial protection against physical damage or bodily injury resulting from traffic collisions and against liability that could also arise from incidents in a vehicle</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
