import React from 'react';
import Partner from './Partner';
import Zoom from 'react-reveal';
import { Carousel } from "react-bootstrap"
import { Container } from 'react-bootstrap/cjs';
const PartnerSlider = (props) => {
    return (

        <Zoom duration={1000}>
            <div className="logo-scrol">
                <h2 className="h1">OUR BUSINESS PARTNERS</h2>
                <Carousel>
                    <Carousel.Item>
                        <Container>
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/godigit.png")}
                                        alt="godigit"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/Sriram.jpg")}
                                        alt="Sriram"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/iffco.jpg")}
                                        alt="iffco"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/realince_logo.png")}
                                        alt="realince_logo"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/HdfcLifeInsurance.jpg")}
                                        alt="Second slide"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/religare_logo.png")}
                                        alt="Second slide"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/icici.jpg")}
                                        alt="icici"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/star_logo.png")}
                                        alt="star_logo"
                                    />
                                </div>
                            </div>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/maxHealth_insurance.png")}
                                        alt="godigit"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/universal_logo.png")}
                                        alt="Sriram"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/Raheja.jpg")}
                                        alt="iffco"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/IndiaFirst.jpg")}
                                        alt="IndiaFirst"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/hdfc_logo.png")}
                                        alt="hdfc_logo"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/bajaj.png")}
                                        alt="bajaj"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <img
                                        className="InsurerLogo"
                                        src={require("../Contents/img/partner/pnbmetlife.jpg")}
                                        alt="pnbmetlife"
                                    />
                                </div>
                                
                            </div>
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </div>
        </Zoom>
    )
}
export default PartnerSlider;