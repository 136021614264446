import React, { Component } from "react";
import {

    NavbarBrand,

    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomerLogin from "./CustomerLogin";
export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            Logo: require("./assets/img/SMC/images/logo.png"),
            Login: "Login",
        };
    }

    render() {
        return (
            <header className="myNav">
                <nav className="mainHeader navbar fixed-top ">
                    <div className="container">
                        <div className="row MainMenu">
                            <div className="col-sm-2">
                                <NavbarBrand tag={Link} to="/">
                                    <img
                                        src={require("./assets/img/SMC/images/logo.png")}
                                    />
                                </NavbarBrand>
                            </div>
                            <div className="col-sm-10">
                                <ul className="SubHead_Risk">
                                    <li>
                                        <a href="tel:93243 74807">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
      &nbsp; +91 9324374807
    </a>
                                    </li>
                                    <li>
                                        <CustomerLogin />
                                    </li>

                                    <li>
                                        <a href="">
                                            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
      &nbsp; My Account
    </a>
                                    </li>

                                    <li id="talkToExperts" className="talkToExperts">
                                    </li>

                                </ul>
                                <ul className="MN_Menu navMenu navbar-right">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" className="DropDown">
                                            About Us
                                            
                </NavLink>
                                        <ul className="DropDownMenu1">
                                            <NavItem>
                                                <NavLink>
                                                    <img class="default" src={require("./assets/img/SMC/images/nav/SMC-Insurance.png")} alt="" />
                                                    <img class="hover" src={require("./assets/img/SMC/images/nav/W-SMC-Insurance.png")} alt="" />
                                                    SMC Insurance
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink>
                                                    <img class="default" src={require("./assets/img/SMC/images/nav/SMC-Group.png")} alt="" />
                                                    <img class="hover" src={require("./assets/img/SMC/images/nav/W-SMC-Group.png")} alt="" />
                                                    SMC Group
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink>
                                                    <img class="default" src={require("./assets/img/SMC/images/nav/SMC-Group.png")} alt="" />
                                                    <img class="hover" src={require("./assets/img/SMC/images/nav/W-SMC-Group.png")} alt="" />
                                                    SMC News
                                                </NavLink>
                                            </NavItem>

                                        </ul>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/">
                                            Claims
                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/">
                                            ePartner
                </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-dark"
                                            to="/"
                                        >
                                            Contact Us
                </NavLink>
                                    </NavItem>

                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}
