import React, { useState } from "react";
import { Link } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import axios from "axios";
import ButtonClass from "../../ButtonClass";
class InsureBike extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            fields: { registration: "" },
            errors: {},
            isValid: false,
            registration: null,
            RTOList: [],
            ValidRTO: false,
        };
        this.handleChange = this.handleChange.bind(this);

        this.checkRto = this.checkRto.bind(this);
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
            .then(Res => {
                console.log(Res.data)
                Res.data.forEach(element => {
                    this.state.RTOList.push(element.RTOName.split(" ")[0])
                });
            })

    }
    checkRto(rto) {
        let ValidRTO = true;
        let selectedRTO = this.state.RTOList.filter(row => row.toLowerCase().includes(rto.substring(0, 4).toLowerCase()));
        if (selectedRTO.length > 0) {
            ValidRTO = true;

        } else {
            ValidRTO = false

        }
        this.setState({ ValidRTO });
        return ValidRTO;
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields, [e.target.name]: e.target.value
        });
        let errors = {};
        let isValid = true;
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] == "") {
            isValid = false
            errors["registration"] = "Please Enter Correct Number"
        }
        else if (this.state.fields["registration"] !== undefined) {
            if (!this.checkRto(e.target.value)) {
                isValid = false;
                errors["registration"] = "Please Enter Correct RTO"
            } else if (this.state.fields["registration"].length < 9) {
                isValid = false;
            }
        }
        console.log(this.state.errors)
        this.setState({ errors, isValid, fields })
    }

    submit() {
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] === "")
            return alert("Please enter Registration Number ")
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    render() {
        return (
            <div className="container-fluid ">
                <div className="row d-flex justify-content-center pt-3">
                    <div className="col-sm-12 productTabsMainCar">
                        <h3 className="heading">
                            Find better plans for your
            <strong> Two Wheeler Insurance</strong>
                        </h3>
                        <div className="ProductFormIn ProductForm01">
                            <div className="PF0012 SpaceBetweenRow002">
                                <div className="row">
                                    <div className="col-md-7 NoPaddingRight">
                                        <label>
                                            <MaskedInput onChange={this.handleChange} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder="Enter Bike Registration Number" name="registration" style={{ textTransform: "uppercase" }} value={this.state.fields["registration"]} />

                                        </label>
                                        <small className="CarRegError">{this.state.errors["registration"]}</small>

                                    </div>
                                    <div className="col-md-5">
                                        <label>
                                            {
                                                this.state.isValid ?<Link to="/Renew?type=0">
                                                    <button className="QouteMNBtnrisk" id="quotesBtn" >VIEW QUOTES</button>
                                                   </Link> : <button className="QouteMNBtnrisk diablebtn" id="quotesBtn" >VIEW QUOTES</button>
                                            }
                                        </label> </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7 text-center mt-3">

                                        <Link

                                            className="headingBt text-center"
                                            to="/Renew?type=0"
                                        >

                                            Don't know your bike number? <b>Click here</b>

                                        </Link>
                                    </div>
                                    <div className="col-md-5 text-center mt-3">

                                        <Link

                                            className="headingBt text-center"
                                            to="/New?type=0"
                                        >

                                            Bought a new bike? <b>Click here</b>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div
      //   className="packagesMainCar ml-5"
      //   style={{ width: "70%", marginLeft: "30px" }}
      // >
      //   <div className="productTabsMainCar">
      //     <h3 className="packageHeading bp">
      //       We help you find better plans for your
      //       <strong>Bike Insurance</strong>
      //     </h3>
      //     <div className="ProductFormIn">
      //       <label>
      //         <input
      //           type="text"
      //           placeholder="Enter Bike Registration Number"
      //           className="packageForm"
      //         />
      //         <button className="QouteMNBtn">VIEW QUOTES</button>
      //       </label>
      //       <label>
      //         <NavbarBrand tag={Link} to="/Renew?type=0">
      //           Don't know your bike number? <b>Click here</b>
      //         </NavbarBrand>
      //       </label>
      //       <label id="New">
      //         <NavbarBrand tag={Link} to="/New">
      //           Bought a new bike? <b>Click here</b>
      //         </NavbarBrand>
      //       </label>
      //     </div>
      //   </div>
      // </div>*/}
            </div>
        );
    }
};
export { InsureBike };
