/* eslint-disable */
import axios from 'axios';
import React, { Component } from 'react'
import ButtonClass from '../ButtonClass';
import DataLoader from '../Car/Loader/DataLoader';
import LoaderOverlay from '../Car/LoaderOverlay';
import OtpPopup from '../Car/OtpPopup';
import ShareButton from '../Car/ShareButton';
import ProgressBarBox from '../components/ProgressBarBox';
import RedirectForm from '../components/RedirectForm';

export class HealthShowProposal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsMessage: false,
            MessageBody: null,
            PropsalData: {},
            InsurerListContainer: ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh"],
            PayNow: false,
            APIUrl: null,
            CRMAPI: null,
            askOtp: false,
            OTP: null,
            Loader: false,
            receivedOTP: null,
            OpenShare: false,
            isLoaded: false,
            PaymentURL: null,
            GoToPayment: false,
            PaymentForm: null
        }

        //this.state.APIUrl = MasterGlobal.PortalAPI;
        // this.state.APIUrl=window.localStorage.getItem("healthurl")
        // this.state.CRMAPI = MasterGlobal.CRMAPI;
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm")
        // this.setState({
        //     APIUrl: this.state.APIUrl,
        //     CRMAPI: this.state.CRMAPI,
        //     PropsalData: this.state.PropsalData
        // })
        this.goBack = this.goBack.bind(this);
        this.otpConfirmation = this.otpConfirmation.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.callShare = this.callShare.bind(this);
        this.checkSharedURL = this.checkSharedURL.bind(this);
        this.GotoPaymentGetway = this.GotoPaymentGetway.bind(this);
        let url = new URLSearchParams(window.location.search);
        if (url.has("policyEnq")) {
            let enq = url.get("policyEnq");

            this.checkSharedURL(enq);


        } else {
            this.state.isLoaded = true;
            this.state.PropsalData = this.props.location.state.PropsalData;
            window.sessionStorage.setItem("HealthProReq", JSON.stringify(this.state.PropsalData.PropsalReq));
            window.sessionStorage.setItem("HealthProRes", JSON.stringify(this.state.PropsalData.PropsalRes));
        }
        console.log(this.state.PropsalData)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    htmlEncode(str) {

        return str.replace(/&/g, '&amp;')
            .replace(/"/g, "&quot;")
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')

    }

    checkSharedURL = (enq) => {
        let url = this.state.APIUrl;
        if (url.match("inshora")) {
            url = "https://inshoracover.com";
        }
        let Model = {
            URL: url + "/healthapi/api/InsurerHealth/GetShareProposalData?EnquiryNo=" + enq
        }
        console.log(Model)
        axios.post("/bpi/WeatherForecast/CallGet", Model)
            .then((Res) => {
                let data = Res.data;
                console.log(data)
                if (data != null) {
                    if (data.ProposalReq !== null && data.ProposalResponse !== null) {

                        if (data.ProposalReq.match('":"{"')) {
                            try {
                                data.ProposalReq = data.ProposalReq.replaceAll('":"{"', '":{"');
                                data.ProposalReq = data.ProposalReq.replaceAll('}","', '},"');
                            } catch (e) {
                            }
                        }
                        try {
                            window.sessionStorage.setItem("HealthProReq", data.ProposalReq);
                            window.sessionStorage.setItem("HealthProRes", data.ProposalResponse)
                            this.state.PropsalData.PropsalReq = JSON.parse(window.sessionStorage.getItem("HealthProReq"));
                            this.state.PropsalData.PropsalRes = JSON.parse(window.sessionStorage.getItem("HealthProRes"));
                            console.log(this.state.PropsalData)
                            //this.setState({ PropsalData: this.state.PropsalData })
                            this.state.isLoaded = true;
                            this.setState({ isLoaded: true });
                        } catch (e) {
                            try {
                                this.state.PropsalData.PropsalReq = JSON.parse(window.sessionStorage.getItem("HealthProReq").replaceAll(/\\/g, ""));
                                this.state.PropsalData.PropsalRes = JSON.parse(window.sessionStorage.getItem("HealthProRes").replaceAll(/\\/g, ""));
                                this.state.isLoaded = true;
                                this.setState({ isLoaded: true });
                            } catch (err) {
                                console.log(err);
                            }

                            console.log(e);
                        }// var QuotePage1 = JSON.parse(Response.QuotePage);
                        // window.sessionStorage.setItem("HealthQuotePage", JSON.stringify(QuotePage1));
                        // var QuotePage = JSON.parse(Response.quoteResponse);
                        // window.sessionStorage.setItem("HealthQuoteResponseData", JSON.stringify(QuotePage));


                        // this.state.Model.ProposalReq=JSON.parse(data.QuotePage);
                        // this.state.Model.ProposalResponse=JSON.parse(data.quoteResponse);
                        console.log(this.state.PropsalData)

                    }
                } else {
                    alert("No data found on this Enquiry Number");
                }
            })

    }
    componentDidMount() {
        this.setState({ Loader: false });
        let url = new URLSearchParams(window.location.search);
        if (url.get("policyEnq") === null) {
            let SaveReq = {
                shareButton: true,
                share: false,
                SharedEmail: this.state.PropsalData.PropsalReq.ProposerEmail,
                SaveReqData: true,
                EnquiryNo: this.state.PropsalData.PropsalReq.EnquiryNo,
                ShareFor: "Health_Proposal",
                Quotedata: this.htmlEncode(window.sessionStorage.getItem("HealthProReq")),
                quoteRes: this.htmlEncode(window.sessionStorage.getItem("HealthProRes")),
            }
            new ShareButton(SaveReq);
        }
    }
    otpConfirmation(e) {

        switch (e.target.id) {
            case "otpClose": this.setState({ askOtp: false })
                break;
            case "payNow": let Model = {
                URL: this.state.APIUrl + "/api/api/GEO/SendOTP?mobileno=" + this.state.PropsalData.PropsalReq.MobileNumber + "&_UpdatedUri=" + this.state.APIUrl + "&roleid= "
            }
                axios.post("/bpi/WeatherForecast/CallGet", Model)
                    .then(Res => {
                        console.log(Res)
                        this.state.receivedOTP = Res.data;

                    })
                this.setState({ askOtp: true })
                break;
            case "confirmOTP":
                if (this.state.OTP === null) {
                    return alert("Please Enter OTP")
                }


                if (this.state.OTP == this.state.receivedOTP || this.state.OTP == "202101") {
                    this.setState({ Loader: true })
                    this.GotoPaymentGetway()
                } else return alert("Please Input Correct OTP")
                break;
        }

    }
    handleChange(e) {

        this.setState({ [e.target.name]: e.target.value })
    }

    pay = (e) => {
        this.setState({ PayNow: e.target.checked })
    }
    callShare() {
        this.setState({ OpenShare: !this.state.OpenShare })
    }
    GotoPaymentGetway = (e) => {
        let apiurl = this.state.APIUrl;// == "" ? "https://smcinsurance.com" : this.state.APIUrl;
        let url = "";
        let returnURL = this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl;
        if (this.state.APIUrl.match("inshora")) {
            apiurl = returnURL;
        }
        switch (this.state.PropsalData.PropsalReq.HealthQuoteResponse.CompanyID) {
            case 103:
                url = apiurl + "/healthapi/api/InsurerHealth/HDFCPayment?hdfcAgentCode=" +
                    this.state.PropsalData.PropsalRes.hdfcAgentCode +
                    "&CustomerID=" + this.state.PropsalData.PropsalRes.CustomerID +
                    "&PayAmount=" + this.state.PropsalData.PropsalRes.PayAmount +
                    "&CustomerFirstName=" + this.state.PropsalData.PropsalRes.CustomerFirstName +
                    "&CustomerEmail=" + this.state.PropsalData.PropsalRes.CustomerEmail +
                    "&CustomerLastName=" + this.state.PropsalData.PropsalRes.CustomerLastName +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.EnquiryNo;
                break;
            case 114:
                url = apiurl + "/healthapi/api/InsurerHealth/ReligarePay?ProposalNo="
                    + this.state.PropsalData.PropsalRes.ProposalNumber +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo + "&uri=" + returnURL;
                break;
            case 105:
                url = apiurl + "/healthapi/api/InsurerHealth/Sompo_Payment?PosPolicyNo="
                    + this.state.PropsalData.PropsalRes.ProposalNumber +
                    "&FinalPremium=" + this.state.PropsalData.PropsalRes.TotalPremium +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo +
                    "&SubSrc=" + this.state.PropsalData.PropsalRes.ApplicationId;

                //************************** */
                // this.props.history.push("/healthapi/api/InsurerHealth/Sompo_Payment?PosPolicyNo="
                // + this.state.PropsalData.PropsalRes.ProposalNumber +
                // "&FinalPremium="+ this.state.PropsalData.PropsalRes.TotalPremium +
                // "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo+
                // "&SubSrc="+this.state.PropsalData.PropsalRes.ApplicationId;

                // window.location.reload(true;
                break;
            case 102:
                url = apiurl + "/healthapi/api/InsurerHealth/BajajPayment?TotalPremium="
                    + this.state.PropsalData.PropsalRes.TotalPremium + "&UserId=" + this.state.PropsalData.PropsalRes.CustomerID +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.EnquiryNo +
                    "&TransactionID=" + this.state.PropsalData.PropsalRes.TransactionNo +
                    "&PayURL=" + this.state.PropsalData.PropsalRes.hdfcAgentCode;


                break;
            case 132:
                url = apiurl + "/healthapi/api/InsurerHealth/ICICIPayment?TotalPremium="
                    + this.state.PropsalData.PropsalRes.TotalPremium +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo +
                    "&ApplicationId=" + this.state.PropsalData.PropsalRes.ApplicationId;


                break;
            case 108:
                url = apiurl + "/healthapi/api/InsurerHealth/FuturePay?FutureAgentCode="
                    + this.state.PropsalData.PropsalRes.hdfcAgentCode +
                    "&TransactionID=" + this.state.PropsalData.PropsalRes.CustomerID +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo +
                    "&PremiumAmount=" + this.state.PropsalData.PropsalRes.TotalPremium +
                    "&FirstName=" + this.state.PropsalData.PropsalRes.CustomerFirstName +
                    "&Email=" + this.state.PropsalData.PropsalReq.CustomerEmail +
                    "&LastName=" + this.state.PropsalData.PropsalRes.CustomerLastName +
                    "&ProposalNumber=" + this.state.PropsalData.PropsalRes.ProposalNumber +
                    "&Mobile=" + this.state.PropsalData.PropsalReq.MobileNumber +
                    "&_Uri=" + returnURL;

                break;
            case 104:
                url = apiurl + "/healthapi/api/InsurerHealth/StarHealthPayment?RedirectToken="
                    + this.state.PropsalData.PropsalRes.TransactionNo +
                    "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo;

                break;

            case 116: url = apiurl + "/healthapi/api/InsurerHealth/BirlaPayment?TotalPremium=" +
                + this.state.PropsalData.PropsalRes.TotalPremium +
                "&hdfcAgentCode=" + this.state.PropsalData.PropsalRes.hdfcAgentCode +
                "&ProposalNo=" + this.state.PropsalData.PropsalRes.ProposalNumber +
                "&EnquiryNo=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo +
                "&CustomerID=" + this.state.PropsalData.PropsalRes.CustomerID +
                "&BasePremium=" + this.state.PropsalData.PropsalReq.HealthQuoteResponse.BasePremium +
                "&_uri=" + returnURL;


                break;
            case 112: url = apiurl + "/healthapi/api/InsurerHealth/ReliancePayment?TotalPremium=" +
                this.state.PropsalData.PropsalRes.TotalPremium +
                "&UserId=" + this.state.PropsalData.PropsalRes.hdfcAgentCode.split('~')[0] +
                "&ProposalNo=" + this.state.PropsalData.PropsalRes.ProposalNumber +
                "&EnquiryNo=" + this.state.PropsalData.PropsalReq.EnquiryNo +
                "&ApplicationId=" + this.state.PropsalData.PropsalRes.ApplicationId;
                break;
            case 124: url = apiurl + "/healthapi/api/InsurerHealth/GoDigitPayment?applicationId=" +
                this.state.PropsalData.PropsalRes.ApplicationId +
                "&EnquiryNo=" + this.state.PropsalData.PropsalReq.EnquiryNo +
                "&PolicyNo=" + this.state.PropsalData.PropsalRes.PolicyNumber;
                break;
            case 120: let data = this.state.PropsalData.PropsalRes.hdfcAgentCode.split("~");
                url = apiurl + "/healthapi/api/InsurerHealth/AppoloPay?ProposalID=" +
                    this.state.PropsalData.PropsalRes.ProposalNumber +
                    "&PartnerCode=" + data[0] + "&UserName=" + data[1] + "&Password=" + data[2] + "&EnquiryNo=" +
                    this.state.PropsalData.PropsalReq.EnquiryNo;
                break;
            case 115:
                let Req = this.state.PropsalData;
                var policyNumber = Req.PropsalRes.PolicyNumber;
                var Amount = Req.PropsalRes.TotalPremium;
                var fname = Req.PropsalReq.CustomerFirstName;
                var lname = Req.PropsalReq.CustomerLastName;
                var pinCode = Req.PropsalReq.PinCode;
                var city = Req.PropsalReq.UserAddress.CityName;
                var state = Req.PropsalReq.UserAddress.StateID;
                var country = "IND";
                var phone = Req.PropsalReq.MobileNumber;
                var emailId = Req.PropsalReq.CustomerEmail;
                var ValidateResult = policyNumber + "~" + Amount + "~" + fname + "~" + lname + "~" + pinCode + "~" + city + "~" + state + "~" + country + "~" + phone + "~" + emailId;
                url = apiurl + "/healthapi/api/InsurerHealth/CignaPay?ValidateResult=" + ValidateResult + "&ProposalNo=" + this.state.PropsalData.PropsalRes.ProposalNumber
                    + "&EnquiryNo=" + this.state.PropsalData.PropsalReq.EnquiryNo + "&ApplicationID=" + this.state.PropsalData.PropsalRes.ApplicationId
                    + "&_Uri=" + returnURL;
                break;
            default: alert("We are Sorry for the inconvenience, we thank you for choosing us. Please check after Some time");
                this.setState({ Loader: false, askOtp: false, PayNow: false })
                break;
        }
        let domainURL = window.location.href;
        if (domainURL.match("www")) {
            if (url.match("www")) {
                url = url.replace("www.", "")
            }
        } else {
            if (!url.match("www")) {
                url = url.replace("https://", "https://www.")
            }
        }
        //window.location.href = url;
        //window.location.assign(window.location.href=url);
        // this.setState({PaymentURL:url,GoToPayment:true});
        let PaymentModel = {
            URL: url
        }
        console.log(url)
        axios.post("/bpi/WeatherForecast/CallGet", PaymentModel)
            .then(Res => {
                if (Res.data.match("body")) {
                    let htmlForm = Res.data.split("</head>")[1].replace("</html>", "");
                    this.setState({ PaymentURL: htmlForm, GoToPayment: true })
                }
                else window.location.href = url;
            }).catch(err => { window.location.href = url; })

    }
    dobManage(data) {
        console.log(data);
        if (data.includes("-")) {
            let element = data.split("-");
            let dd = element[2];
            let mm = element[1];
            let yy = element[0];

            return dd + "-" + mm + "-" + yy;
        }
        return data
    }

    goBack() {
        this.props.history.goBack();
    }
    render() {
        if (!this.state.isLoaded) {
            return <DataLoader />
        }
        return (
            <div className="NewProposal" ref={this.myRef}>
                <ProgressBarBox Progress="90%" />
                {this.state.GoToPayment && <RedirectForm url={this.state.PaymentURL} />}
                <div className="container">
                    <div className="proposalPageBox">
                        
                        <div className="row BackBTNRow" id="backToProposalBtn">
                            <div className="col-3 col-md-auto">
                                <a onClick={this.goBack} className="arw-btn">
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </div>
                            <div className="col-9 col-md-10 col-lg-11 align-self-center">
                                <h3 className="f-32 font-regular mb-0">Back to Proposal</h3>
                            </div>
                        </div>
                        <div className="row proposalBox03">
                            <div className="col-md-3 p-0">
                                <div className="proDetailsMM">
                                    <div className="rowDetailsMM">
                                        <img src={this.state.PropsalData.PropsalReq.HealthQuoteResponse.UrlLogo} alt="vkljbkljknll" />
                                        <span id="planname">
                                            {this.state.PropsalData.PropsalReq.HealthQuoteResponse.PlanName}
                                        </span>
                                        <p className="enqueryNumber">
                                            <b>Enquiry#</b> <span id="EnquiryNo" className="ng-binding">{this.state.PropsalData.PropsalReq.HealthQuoteResponse.EnquiryNo}</span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM rowDetailsMM01">
                                        <p>Premium<span><b>₹ {Math.round(this.state.PropsalData.PropsalReq.HealthQuoteResponse.BasePremium)}</b></span></p>
                                        <p>+ GST (18%)<span><b>₹ {Math.round(this.state.PropsalData.PropsalReq.HealthQuoteResponse.ServiceTax)}</b></span></p>
                                        <p className="totalP">Total Premium<span><b>₹ {Math.round(this.state.PropsalData.PropsalReq.HealthQuoteResponse.TotalPremium)}</b></span></p>
                                    </div>
                                    <div className="rowDetailsMM">
                                        <p>Sum Insured<span><b>₹ {this.state.PropsalData.PropsalReq.HealthQuoteResponse.SumInsured}</b></span></p>
                                        <p>Policy Term<span>{this.state.PropsalData.PropsalReq.HealthQuoteResponse.PolicyTerm} Year</span></p>
                                        {/*<p>Policy Start Date<span id="PolicyStartDateProposal" className="ng-binding">*/}
                                        {/*    {this.state.PropsalData.PropsalReq.HealthQuoteResponse.PolicyStartDate}</span></p>*/}
                                        {/*<p>Policy End Date<span id="PolicyStartDateProposal" className="ng-binding">*/}
                                        {/*    {this.state.PropsalData.PropsalReq.HealthQuoteResponse.PolicyEndDate}</span></p>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 p-0">
                                <div className="PS_Info">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="PS_Box PS_Box_PD">

                                                <h5 className="PS_Heading">
                                                    Proposer Details
                                                        <a onClick={this.goBack} className="backtoQuotes">
                                                        <i class="fas fa-edit"></i> Edit
                                                        </a>
                                                </h5>


                                                <label>
                                                    <p>Full Name : </p>
                                                    <span id="fname">{this.state.PropsalData.PropsalReq.ProposerName}</span>
                                                </label>
                                                <label>
                                                    <p> Email :</p>
                                                    <span id="email">{this.state.PropsalData.PropsalReq.ProposerEmail}</span>

                                                </label>
                                                <label>
                                                    <p>  Mobile :</p>
                                                    <span id="mobile">{this.state.PropsalData.PropsalReq.MobileNumber}</span>
                                                </label>
                                                <label>
                                                    <p>  DOB :</p>
                                                    <span id="dob">{this.dobManage(this.state.PropsalData.PropsalReq.proposerDob)}</span>
                                                </label>
                                                <label>
                                                    <p> Nominee Name: </p>
                                                    <span id="NomineeName">{this.state.PropsalData.PropsalReq.ProposerNomineeName}</span>
                                                </label>
                                                <label>
                                                    <p> Nominee Relation:  </p>
                                                    <span id="NomineeRelationType">{this.state.PropsalData.PropsalReq.ListOfInsurer[0].NomineeRelationType}</span>
                                                </label>
                                                <label>
                                                    <p> Nominee DOB: </p>
                                                    <span id="NomineeDOB">{this.dobManage(this.state.PropsalData.PropsalReq.ListOfInsurer[0].NomineeDOB)}</span>
                                                </label>
                                                <label>
                                                    <p> Address :</p>
                                                    <span id="addressLine1">{this.state.PropsalData.PropsalReq.UserAddress.AddressLine1},
                                                        {this.state.PropsalData.PropsalReq.UserAddress.AddressLine2},
                                                        {this.state.PropsalData.PropsalReq.UserAddress.AddressLine3}</span>

                                                </label>
                                                <label>
                                                    <p> City :</p>
                                                    <span id="city">{this.state.PropsalData.PropsalReq.UserAddress.CityName}</span>
                                                </label>
                                                <label>
                                                    <p> State :</p>
                                                    <span id="statename">{this.state.PropsalData.PropsalReq.UserAddress.StateName}</span>
                                                </label>
                                                <label>
                                                    <p> PIN :</p>
                                                    <span id="pin">{this.state.PropsalData.PropsalReq.PinCode}</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div className="col-md-12">
                                            <div className="PS_Box PS_Box_PD">
                                                {
                                                    this.state.PropsalData.PropsalReq.ListOfInsurer.map((row, index) =>
                                                        <div key={index}>

                                                            <h5 className="PS_Heading">{this.state.InsurerListContainer[index]}
                                                                    Insurer Details
                                                                    <a onClick={this.goBack} className="backtoQuotes">
                                                                    <i class="fas fa-edit"></i> Edit
                                                                    </a>
                                                            </h5>

                                                            <label>
                                                                <p> Full Name :</p>
                                                                <span className="ng-binding">{row.FirstName} {row.LastName}</span>
                                                            </label>
                                                            <label>
                                                                <p> Relationship :</p>
                                                                <span>{row.RelationShipType}</span>
                                                            </label>
                                                            <label>
                                                                <p> Gender :</p>
                                                                <span>{row.sex == "M" ? "Male" : "Female"}</span>
                                                            </label>
                                                            <label>
                                                                <p> Marital Status :</p>
                                                                <span>{row.MaritalStatus == "M" ? "Married" : "UnMarried"}</span>
                                                            </label>
                                                            <label>
                                                                <p> Date of Birth :</p>
                                                                <span>{(row.dob)}</span>
                                                            </label>

                                                            <label>
                                                                <p> Weight :</p>
                                                                <span>{row.weight} kg</span>
                                                            </label>
                                                            <label>
                                                                <p> Height :</p>
                                                                <span>{row.height} cm</span>
                                                            </label>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {/*  <div className="col-md-12">
                                                <div className="PS_Box PS_Box_PD">
                                                    <div>
                                                        <h5 className="PS_Heading">
                                                            Addons
                              <a onClick={this.goBack} className="backtoQuotes">
                                                                <i class="fas fa-edit"></i> Edit
                              </a>
                                                        </h5>

                                                        <label
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .CriticalIllness && "Critical Illness"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .HealthCheck && "HealthCheck"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .AnyRoomUpgrade && "AnyRoomUpgrade"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .ExpertOpinion && "ExpertOpinion"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .HealthCheck && "HealthCheck"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .HomeCare && "HomeCare"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .HospitalCash && "HospitalCash"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .MaternityCover && "MaternityCover"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .NCBSuper && "NCBSuper"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .RestoreBenefits && "RestoreBenefits"}
                                                            </span>
                                                            <span className="ng-binding">
                                                                {this.state.PropsalData.PropsalReq.QuoteReq
                                                                    .UAR && "UAR"}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>*/}


                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <label className="d-flex align-items-center w-100">
                                                    <input type="checkbox" name="PayNow"
                                                        checked={this.state.PayNow} className="mr-2"
                                                        onClick={this.pay} /> Agree on the <a href="/BajajInsurance/TermsAndCondition" className="p-0 text-secondary d-inline nav-link" target="_blank"><b>Terms &amp; Conditions</b></a>

                                                </label>
                                                <lable className="ammountTTL">
                                                    <strong>
                                                        Amount to be paid : ₹
                                                            <span id="main"> {Math.round(this.state.PropsalData.PropsalReq.HealthQuoteResponse.TotalPremium)}</span>
                                                    </strong>
                                                </lable>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-right">
                                            <div className="d-inline-block w-100">
                                                <label class="float-left">
                                                    <input type="button" onClick={this.callShare}
                                                        value="Share this Proposal" className="ShareProBtn" />
                                                </label>

                                                <label class="float-right">
                                                    <input type="button"
                                                        onClick={this.otpConfirmation}
                                                        disabled={!this.state.PayNow ? "disabled" : ""}
                                                        value="Pay Now" id="payNow" className={ButtonClass()} />
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    {this.state.askOtp &&
                        <OtpPopup handle={this.handleChange} submit={this.otpConfirmation} close={() => this.setState({ askOtp: false })} />
                    }{this.state.Loader &&
                        <LoaderOverlay />
                    }</div>


                {this.state.OpenShare && <ShareButton SaveReqData={false} ShareFor="Health_Proposal" SharedEmail={this.state.PropsalData.PropsalReq.MobileNumber} share={false} shareButton={true} ProposalReq={this.htmlEncode(window.sessionStorage.getItem("HealthProReq"))} ProposalResponse={this.htmlEncode(window.sessionStorage.getItem("HealthProRes"))} EnquiryNo={this.state.PropsalData.PropsalReq.EnquiryNo} />}

                {
                    this.IsMessage ?
                        <div className="Manualmodal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header text-center">
                                        <button type="button" className="close btn-success" onClick={this.CloseMessage}>×</button>
                                    </div>
                                    <div className="modal-body">
                                        <span>{this.state.MessageBody}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }
}