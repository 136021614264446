import React, { Component } from 'react';
import { ChildBtn } from '../ButtonClass';
const PreviousInsurerConfirmationBox = (props) => {
   
    return (
        <div id="ChkProposalModel_Bajaj" className="modal ui-draggable" style={{display: "block"}}>
                    <div className="modal-dialog newModalqt">
                        <div className="modal-content text-center">
                            <div className="modal-header ui-draggable-handle">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"  id="btnPopupHide3" onClick={props.close}>×</button>
                                <h3>Is <span id="companyname_Bajaj">{props.companyName}</span> Your Previous Insurer ?</h3>
                            </div>
                           
                                <div className="modal-body">
                                    {!props.IsPreviousInsu?
                            <div className="ChkProposalModelPopup_Bajaj" style={{ display: "block" }}>
                                <div className="row Qlogo">
                                    <div className="col-md-12">
                                        <img src={props.src} /></div>
                                </div>
                                <label className="mr-1 ml-1">
                                    <button type="button" id="Confirm" value="Yes" onClick={props.yes} className={ChildBtn() + " pl-4 pr-4"} disabled={props.confirm!=="NO" }>YES</button>
                                </label>
                                <label className="mr-1 ml-1">
                                    <button type="button" id="Confirm" value="No" placeholder="NO" onClick={props.no} className={ChildBtn() + " pl-4 pr-4"} >{props.confirm }</button>
                                </label>
                            </div>
                                    :<div className="ChkProposalModelmsg_Bajaj" >
                                        <div className="row Qlogo">
                                            <div className="col-md-12"><img src={props.src} /></div>
                                        </div>
                                        <div className="row CoNaIns_Bajaj">
                                            <div className="col-md-12 text-center">
                                                <p>Please choose any other insurer !</p>
                                                <p>Your existing policy is with <span id="companynameBajaj">{props.companyName}</span>. This insurer does not allow you to purchase a <span id="companynamesBajaj">{props.companyName}</span> policy. We're sorry for the inconvenience. </p>

                                                <p>Please choose any other insurer !</p>
                                            </div>
                                        </div>
                                <div className="row">
                                    <button type="button" className={ChildBtn() + " pl-4 pr-4 w-auto m-auto"} placeholder="Back To Quotes" onClick={props.close} id="btnPopupHideIns">Back To Quotes</button>
                                        </div>
                                    </div>
}
                                    </div>
                           
                        </div>
                    </div>
                </div>
    )
}
export default PreviousInsurerConfirmationBox