import React from "react";
import "react-reveal";
const SectionSIX = (props) => {
    return (
        <React.Fragment>
            <section class="pt-100 pb-70">
                <div className="container">
                    <div className="SectionSIX">
                        <div className="row align-items-start">
                            <div className="col-md-6">
                                <div className="MuiBox-root">
                                    <h1 className="MuiTypography-h4">More things coming soon</h1>
                                    <p>We are just getting started and we will continue to build a whole host of products to help you make better decisions.
                                    <br />
And if you want to stay updated with our progress, please make sure you subscribe.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="comparisonsMain">
                                    <div className="comparisonsBox">
                                        <img src={require("../assets/img/SMC/images/comparehealth.svg")} alt="comparehealth" />
                                        <div className="MuiBox-root">
                                            <h3>Compare Health insurance policies</h3>
                                            <p>Health Insurance comparisons have to be meaningful. So we are reimagining this classic feature and building something different from the ground up.</p>
                                        </div>
                                    </div>
                                    <div className="comparisonsBox">
                                        <img src={require("../assets/img/SMC/images/ulipcheck.svg")} alt="ulipcheck" />
                                        <div className="MuiBox-root">
                                            <h3>Term Insurance vs ULIP</h3>
                                            <p>It's a question that's bothered us for ages and we want to simplify the comparison between term insurance and ULIPs once and for all.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default SectionSIX;
