/* eslint-disable */ 
import React from "react";
const Logo = (props) => {
  return (
    <div className="logoSocial">
      <img src={props.src} alt={props.alt} />
    </div>
  );
};
export default Logo;
