import React from "react";
const Dedicated = (props) => {
    return (
        <section class="comProfile">
            <div class="container">
                <div class="row">
                    <div class="col-md-1">
                        <div class="grpelem">
                            <img src={require("../assets/img/P2C/victory icon.png")} width="42" height="60" />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="grpelem">
                            <h3>Compare Insurance From Top Insurance Companies</h3>
                            <p>Policy2cover offers you the best&nbsp; insurance product options across India's leading insurers. A side-by-side comparison will help you to determine which one will give you the best coverage for your Money!</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button class="btn askExperts">Ask an Expert</button>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
