import React from 'react';
import Partner from './Partner';
const OurPartner = () => {
    const a = Partner.map((v, i) => {
        return (
            <React.Fragment>
                <div className="col-md-2 col-sm-3 col-xs-6 insurLogo"><img src={v.src} key={i} className="partnerLogo" /></div>
            </React.Fragment>
        )
    })
    return (


            <div class="ourPartner pt-5 pb-5">
                <div class="container">
                    <h3 class="text-center titleWithFont">
                        Our Business Partners
                    </h3>
                <div className="row justify-content-center">{a}</div>
                </div>
            </div>
    )
}
export default OurPartner;