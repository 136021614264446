import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import HowWork from "./HowWork";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <Zoom>
                <HowWork/>
            </Zoom>
            <Zoom>
                <Dedicated />
            </Zoom>






			<div class="services-area in-section section-padding-lg bg-shape">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-6">
							<div class="section-title text-center">
								<span>BEST SERVICES FOR YOU</span>
								<h2>What We Provide</h2>
							</div>
						</div>
					</div>
					<div class="row">

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/LifeInsurance.png")} alt="Life Insurance"/>
								</span>
								<h5>Life Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/HomeInsurance.png")} alt="Home Insurance"/>
								</span>
								<h5>Home Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/TravelInsurance.png")} alt="Travel Insurance"/>
								</span>
								<h5>Travel Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/BusinessInsurance.png")} alt="Business Insurance"/>
								</span>
								<h5>Business Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/AgriculturalInsurance.png")} alt="Agricultural Insurance"/>
								</span>
								<h5>Agricultural Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/HealthInsurance.png")} alt="Health Insurance"/>
								</span>
								<h5>Health Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/CarInsurance.png")} alt="Car Insurance"/>
								</span>
								<h5>Car Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

						
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<img src={require("../assets/img/DemoTemp/AutoInsurance.png")} alt="Auto Insurance" />
								</span>
								<h5>Auto Insurance</h5>
								<p>adipconsequat. Duis aute irure dolor in repreheq.</p>
								
							</div>
						</div>
						

					</div>
				</div>
			</div>
        </React.Fragment>
    );
};
export default Services;
