import React from "react";
import { Carousel } from "react-bootstrap"
const VedantTestimonial = (props) => {
    return (

        <section class="reviews-section pad-tb review-bg2" id="review">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-company comon-heading">
                            <h2 class="mb20">Our <em>Happy</em> Customers</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <h5 class="mt40">Thousands of unbiased reviews. Trusted by 10 Thousand+ customers.</h5>
                        <ul class="overallrating mt20 p-0">
                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <div class="reviews-block owl-carousel m-mt60 owl-loaded owl-drag" data-0="1" data-600="1" data-1000="1" data-1400="1" data-autoplay="true" data-nav="false" data-dots="true" data-space="20" data-loop="true" data-speed="800">
                            <div class="owl-stage-outer">
                                <Carousel className="owl-stage mb-0">
                                    <Carousel.Item>
                                        <div class="reviews-card">
                                        <div class="-client-details-">
                                                <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-1.jpg")} alt="Good Review" class="img-fluid" />
                                            </div>
                                            <div class="reviewer-text">
                                                <h5>Mario Speedwagon</h5>
                                                <p>Business Owner</p>
                                                <div class="star-rate">
                                                    <ul>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                        <li> <a href="javascript:void(0)">4.2</a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="review-text pb0 pt30">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="reviews-card">
                                        <div class="-client-details-">
                                            <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-2.jpg")} alt="Good Review" class="img-fluid" />
                                            </div>
                                            <div class="reviewer-text">
                                                <h5>Mario Speedwagon</h5>
                                                <p>Business Owner</p>
                                                <div class="star-rate">
                                                    <ul>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                        <li> <a href="javascript:void(0)">4.2</a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="review-text pb0 pt30">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="reviews-card">
                                        <div class="-client-details-">
                                            <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-3.jpg")} alt="Good Review" class="img-fluid" />
                                            </div>
                                            <div class="reviewer-text">
                                                <h5>Mario Speedwagon</h5>
                                                <p>Business Owner</p>
                                                <div class="star-rate">
                                                    <ul>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                        <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                        <li> <a href="javascript:void(0)">4.2</a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="review-text pb0 pt30">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div class="reviews-card">
                                            <div class="-client-details-">
                                                <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-1.jpg")} alt="Good Review" class="img-fluid" />
                                                </div>
                                                <div class="reviewer-text">
                                                    <h5>Mario Speedwagon</h5>
                                                    <p>Business Owner</p>
                                                    <div class="star-rate">
                                                        <ul>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                            <li> <a href="javascript:void(0)">4.2</a> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="review-text pb0 pt30">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="reviews-card">
                                            <div class="-client-details-">
                                                <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-2.jpg")} alt="Good Review" class="img-fluid" />
                                                </div>
                                                <div class="reviewer-text">
                                                    <h5>Mario Speedwagon</h5>
                                                    <p>Business Owner</p>
                                                    <div class="star-rate">
                                                        <ul>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                            <li> <a href="javascript:void(0)">4.2</a> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="review-text pb0 pt30">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="reviews-card">
                                            <div class="-client-details-">
                                                <div class="-reviewr">
                                                    <img src={require("./assets/img/Vedant/review-image-3.jpg")} alt="Good Review" class="img-fluid" />
                                                </div>
                                                <div class="reviewer-text">
                                                    <h5>Mario Speedwagon</h5>
                                                    <p>Business Owner</p>
                                                    <div class="star-rate">
                                                        <ul>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                            <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star-half-alt"></i></a> </li>
                                                            <li> <a href="javascript:void(0)">4.2</a> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="review-text pb0 pt30">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default VedantTestimonial;
