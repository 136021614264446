import React from "react";
const Slides = (props) => {
  return (
    <React.Fragment>
      <div className="slides">
        <figure className="snip1390">
          <figcaption>
            <blockquote>{props.quotes}</blockquote>
          </figcaption>
          <h5 style={{paddingLeft:"50px"}}>{props.name}</h5>
        </figure>
      </div>
    </React.Fragment>
  );
};
export default Slides;
