import React from "react";
import Services from "./services/Services";
import OurPartner from "./OurPartner";
import HelpUs from "./HelpUs";
import Tab from "./HomeTab/Tab";
import Testimonial from "./Testimonial";
import SideNav from "../side/SideNav";

const PolicyLeaderMiddleSection = () => {
    return (
        <React.Fragment>
            <Tab />
            <Services />
            <Testimonial />
            <OurPartner />
            <HelpUs />
            <SideNav />
        </React.Fragment>
    );
};
export default PolicyLeaderMiddleSection;
