import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Component } from "react";

import MaskedInput from 'react-text-mask';
import axios from "axios";
import ButtonClass from "../../ButtonClass";

class InsureCommercial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HoldRequestData:{},
            errors: {},
            fields: {},
            isValid: false,
            CarRegistration: null,
            BikeRegistration:null,
            IsLoaded: false,
            VahanData: {},
            RedirectTo:false,
            TwList:[],
            FourWheelerList:[],
            RTOList:[],
            VariantList:[],
            PastYearClaimID:false,
            Organisation:false,
            ISPACOVERODSELECTED:false,
            PACOVERYEAR:0,
            MobileNo:null,
            MyPolicyexpired: false,
            BindPreviousNCB:20,
            Previouspolicydetails: false,
            CarVal:[],
            FuelData:{},
            YearVal:null,
            type:this.props.type,
            RTOVal:[],
            ManufactureDate:null,
            ClientID:null,
            APIUrl:null,
            UserID:0,
            EnquiryNo:null,
            ShowSelectedData:false,
            VariantVal:[],
            PrevPolicyType:1,
            ClaimAmount: 0,
            BtnValue:"View Quote",
            RedirectToquote:false,
            EditedVariant:null,
            Edited:false,
            TwListUpdated:[],
            FwListUpdated:[],
            RTOUpdatdList:[]
        }
        this.state.APIUrl=window.localStorage.getItem("portal")
        this.handle = this.handle.bind(this);
    }
    componentDidMount() {
        // axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=Two Wheeler")
        // .then(Res => {
        //     this.setState({TwList : Res.data });
        // });
        // axios.get("https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=Car")
        // .then(Res => {
        //     this.setState({FourWheelerList : Res.data });
        //     window.sessionStorage.setItem("fw", JSON.stringify(Res.data))
        // });
        // axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
        // .then(Res => {
        //     this.setState({RTOList : Res.data });
        // });
       let type=this.props.type==2?"PCV":"GCV";
       let url=this.state.APIUrl.match("riskovery")?"https://www.riskoveryinsurance.com":this.state.APIUrl;
        axios.get(this.state.APIUrl+"/commercial/api/api/Vehicle/GetInsurerList?Type=" + type + "&url=" +url )
        .then(Res=>{
            let data=JSON.stringify(Res.data)
            this.props.type==2?window.sessionStorage.setItem("PCVInsurerList",data):window.sessionStorage.setItem("GCVInsurerList",data)
        })
        
        this.checkRto = this.checkRto.bind(this);
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
            .then(Res => {
                console.log(Res.data)
                Res.data.forEach(element => {
                    this.state.RTOList.push(element.RTOName.split(" ")[0])
                });
            })
    }
    checkRto(rto) {
        let ValidRTO = true;
        let selectedRTO = this.state.RTOList.filter(row => row.toLowerCase().includes(rto.substring(0, 4).toLowerCase()));
        if (selectedRTO.length > 0) {
            ValidRTO = true;

        } else {
            ValidRTO = false

        }
        this.setState({ ValidRTO });
        return ValidRTO;
    }
    handle(e) {
        let fields={};
        let isValid = true;
        let errors = {}
        fields[e.target.name]=e.target.value
            this.setState({fields, [e.target.name]: e.target.value });

     
       
        if (this.props.type == 2) {
            if (fields["PCVRegistration"] == undefined || fields["PCVRegistration"] =="") {
                isValid = false;
                errors["commercialReg"] = "Please Enter Correct Vehicle No"
            }
            else if (fields["PCVRegistration"] != undefined)
                if (!this.checkRto(e.target.value)) {
                    errors["commercialReg"] = "Please Enter Correct RTO"
                    isValid = false;

                } else if (fields["PCVRegistration"].length < 9) {
                    
                    isValid = false;
                }
        } else if (this.props.type == 3) {
            if (fields["GCVRegistration"] == undefined || fields["GCVRegistration"] == "") {
                isValid = false;
                errors["commercialReg"] = "Please Enter Correct Vehicle No"

            }
            else if (fields["GCVRegistration"] != undefined) {
                if (!this.checkRto(e.target.value)) {
                    errors["commercialReg"] = "Please Enter Correct RTO"
                    isValid = false;

                } else if (fields["GCVRegistration"].length < 9) {
                    isValid = false;
                }
            }
        }
               /* if (fields["GCVRegistration"]!=undefined)
                if (fields["GCVRegistration"].length >= 8) {
                    isValid=true
                }*/
            
        this.state.isValid = isValid;
        this.setState({ isValid, errors })
        
      
    }
    DateFormatter(date){
        date=date.split("-");
        let dd=date[0];
        let mm=date[1];
        let yy=date[2];
        switch(mm){
            case "Jan":mm="01";
            break;
            case "Feb":mm="02";
            break;
            case "Mar":mm="03";
            break;
            case "Apr":mm="04";
            break;
            case "May":mm="05";
            break;
            case "Jun":mm="06";
            break;
            case "Jul":mm="07";
            break;
            case "Aug":mm="08";
            break;
            case "Sep":mm="09";
            break;
            case "Oct":mm="10";
            break;
            case "Nov":mm="11";
            break;
            case "Dec":mm="12";
            break;
            default: mm="06";
            break;
        }
        return yy+"-"+mm+"-"+dd
    }
    close=()=>{
        this.setState({ShowSelectedData:!this.state.ShowSelectedData,Edited:false});
    } 
    render() {

        return (
            <div className="shadow rounded bg-white p-5">
                <h3 className="heading">
                    Find better plans<br /> for your { this.props.Motor} Insurance
                                        </h3>
                <h6 className="subtitle">Get best offers with attractive premium</h6>
                <div className="MainProTabs">
                    <div>


                        <label for="car_reg_no">Enter { this.props.Motor} registration number</label>
                        <MaskedInput className="form-control input-lg" name={this.props.type == 2 ? "PCVRegistration" : "GCVRegistration"} onChange={this.handle} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder={"Enter " + this.props.Motor + " Number( e.g. MH01AA1234)"} style={{ textTransform: "uppercase" }} value={this.state.fields[this.props.type == 2 ? "PCVRegistration" : "GCVRegistration"]} />
        {/*<MaskedInput className="form-control input-lg" onChange={this.handleChange} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder="Enter Car Registration Number" name="registration" style={{ textTransform: "uppercase" }} value={this.state.fields["registration"]} />*/}

                        <small className="CarRegError">{this.state.errors["commercialReg"]}</small>
                        <label>
                            <Link

                                to={"/Renew?type=" + this.props.type}
                                className="headingBt text-center"
                                style={{ wordBreak: "break-word", fontSize: "12px" }}
                            >
                                Don't know your {this.props.Motor} number? <b>Click here</b>
                            </Link>
                        </label>
                    </div>
                    <div>
                        {
                            this.state.isValid ? <Link to={"/Renew?type=" + this.props.type}>
                                <button className={ButtonClass() + " w-auto"} id="quotesBtn" >VIEW QUOTES</button>
                            </Link> : <button className={ButtonClass() + " w-auto"} id="quotesBtn"  >VIEW QUOTES</button>
                        }
                    </div>
                    <label>
                        <Link className="headingBt text-center"
                            to={"/New?type=" + this.props.type}
                        > Bought a new {this.props.Motor}? <b>Click here</b></Link>
                    </label>
                </div>
            </div>


        );
    }
}
export { InsureCommercial };
