import React from "react";
const Product = [
  {
    id: 1,
    name: "Car",
    to: "/Car",
    className: "nav-item nav-link",
    src:
        require("./assets/img/KareIndia/Car.png"),
    },
  {
    id: 2,
    name: "2 Wheeler",
    to: "/Bike",
    className: "productTabsRisk",
    src:
        require("./assets/img/KareIndia/Bike.png"),
  },
  {
    id: 3,
    name: "Commercial",
    className: "productTabsRisk",
    to: "/",
    src:
        require("./assets/img/KareIndia/Commercial.png"),
  },
  {
    id: 4,
    name: "Health",
    className: "productTabsRisk",
    to: "/Health",
    src:
        require("./assets/img/KareIndia/Health.png"),
  },
  {
    id: 5,
    name: "Life",
    className: "productTabsRisk",
    to: "/",
    src:
        require("./assets/img/KareIndia/Life.png"),
  },
];
export default Product;
