import React from "react";
import "react-reveal";
const SectionThird = (props) => {
    return (
        <React.Fragment>
            <section className="pt-50 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="MuiBox-root">
                                
                                <h1 className="MuiTypography-h4">Thinking of buying health insurance?</h1>
                                <p>We know how difficult it can be to navigate through hundreds of polices. So we've designed this handy checklist to make sure you know exactly what to look for in a good policy.s</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="MuiBox-root">
                                <img src={require("../assets/img/SMC/images/help.png")} alt="help" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default SectionThird;
