import React from "react";
const Dedicated = (props) => {
    return (
        <section class="section vertical-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="section_title section_titleFeature text-left">
                            <img src={require("../assets/img/Sansom/4bs-high-performance.png")} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
