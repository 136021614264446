import React from "react";
const Dedicated = (props) => {
    return (
        <section>
            <div className="expertise-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="expertise-image">
                                <img src={require("../assets/img/KareIndia/expertise.png")} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">
                            <div className="section-heading-2 section-heading-2-p">
                                <h4>Why Use</h4>
                                <h3>Kare India Insurance?</h3>
                                <p>As direct insurance broker, we are in a strategically advantageous position of offering a totally flexible approach to your insurance portfolio. The access to all constituents of the insurance sector, both public and private, enables us to nagotiate the most competitive rates & maximize the scope of your required leveof insurance cover. We would emphasize that as a direct broker, we represent the client and not the insurer. Our services to clients are free of charge and include comprehensive insurance cover to cater to a variety of disparate needs whether corporate, institutional, group or personal lines of insurance as well as risk management analysis where we suggest ways and means of minimizing your risk exposure.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
