import React from "react";
const Dedicated = (props) => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div class="col-xl-6">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                <div class="single-count-area">
                                    <img src={require("../assets/img/icon/chart.png")} /> <br /> <span class="counter">9.3</span><span>+ Cr</span>
                                    <p>Premium</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                <div class="single-count-area">
                                    <img src={require("../assets/img/icon/manager.png")} /><br /><span class="counter">9634</span><span>+</span>
                                    <p>Lives Covered</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="single-count-area">
                                    <img src={require("../assets/img/icon/Relationship.png")} /><br /> <span class="counter">13095</span><span>+</span>
                                    <p>Policies Sold</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 order-1 d-flex align-items-center">
                        <div className="team">
                            <h4>
                                <i className="fa fa-arrow-left faIcon" aria-hidden="true"></i>
            &nbsp;Dedicated Team
          </h4>
                            <p>
                                Our team of experts are dedicated to give you best of the insurance
                                products, easy and smooth buying experience ,build algorithms which
                                helps our customers to save their hard earned money while going for
                                an insurance purchase.
          </p>
                        </div>
                    </div>
                </div>
            </div></section>
    );
};
export default Dedicated;
