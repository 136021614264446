import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section className="section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section_title text-left">
                                <div className="title text-custom text-uppercase">Our Service</div>
                                <h2 className="section_header">We Insure Peace of Mind</h2>
                                <p className="sec_subtitle">We are a Direct Insurance Broker with different verticals in general and life catering to Groups, Corporate and Individuals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-3">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <i className="fas fa-heartbeat "></i>
                                </div>
                                <div className="features_desc">
                                    <h6 className="font-weight-bold">Life Insurance</h6>
                                    <p className="text-muted">Most premium insurance plans at low prices </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <i className="fas fa-car "></i>
                                </div>
                                <div className="features_desc">
                                    <h6 className="font-weight-bold">Four Wheeler Insurance</h6>
                                    <p className="text-muted">Get your four wheeler insured in 2 minutes </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <i className="fas fa-motorcycle "></i>
                                </div>
                                <div className="features_desc">
                                    <h6 className="font-weight-bold">Two Wheeler Insurance</h6>
                                    <p className="text-muted">Compare and buy two wheeler insurance without any paperwork </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <i className="fas fa-heart "></i>
                                </div>
                                <div className="features_desc">
                                    <h6 className="font-weight-bold">Health Insurance</h6>
                                    <p className="text-muted">Invest in your health today and stay protected lifelong</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
