import React from "react";
import {Health}  from "../../Health/Health";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { InsureCar } from "./InsureCar";
import { InsureBike } from "./InsureBike";
import { InsureHealth } from "./InsureHealth";
const TabData = [
  {
    id: "1",
        tabTitle: <ProductTab src={Product[0].src} name={Product[0].name} />,
        tabContent: <InsureCar />,    
  },
  {
    id: "2",
    tabTitle: <ProductTab src={Product[1].src} name={Product[1].name} />,
      tabContent: <InsureBike />,
    
  },
  
  {
    id: "4",
    tabTitle: <ProductTab src={Product[2].src} name={Product[2].name} />,
      tabContent: <InsureHealth />,
    
  },
  
];
export default TabData;
