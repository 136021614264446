import React from "react";
const Expertise = (props) => {
    return (
        <React.Fragment>
            <section class="product01">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="product01Box product01Box01 text-center">
                                <div class="product01BoxCnt">
                                    <h3>Motor Insurance </h3>
                                    <p>It offers financial protection to your vehicles from loss due to accident, damage, theft, fire or natural calamities.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="product01Box product01Box02 text-center">
                                <div class="product01BoxCnt">
                                    <h3>Home Insurance </h3>
                                    <p>It pays or compensates you for damage to your home due to natural calamities, man-made disasters and other threats.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="product02">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="product01Box product01Box03 text-center">
                                <div class="product01BoxCnt">
                                    <h3>Travel Insurance </h3>
                                    <p>A travel insurance compensates you and pays for any financial liabilities arising out of medical expenses and non-medical emergencies like loss of your baggage etc during your travel abroad or within the country.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="product01Box product01Box04 text-center">
                                <div class="product01BoxCnt">
                                    <h3>Health Insurance </h3>
                                    <p>It Covers the cost of medical care and allows cashless treatment. It also reimburses the amount you pay towards the treatment of any injury or illness.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>

    );
};
export default Expertise;
