import React from "react";
const Expertise = (props) => {
    return (
        <section className="section bg-funfact">
            <div className="container">
                <div className="row vertical-content" id="counter">
                    <div className="col-lg-6">
                        <div className="section_title mt-3">
                            <p className="title text-custom text-uppercase">Our Expereince</p>
                            <h2 className="font-weight-bold section_header">We have been serving customers for more than 4 years now</h2>
                            <div className="section-title">
                                <div className="bar2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="project_count mt-3">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="text-center bg-gradient position-relative text-white lan_funfact p-2 mt-3">
                                        <div className="fun_effect"></div>
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="654">24</h1>
                                        <p className="lan_fun_name text-white mb-4">Working Hours</p>
                                    </div>
                                    <div className="text-center bg-white lan_funfact p-4 mt-3">
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="1849">30000 +</h1>
                                        <p className="lan_fun_name text-muted mb-4">No. of Clients</p>
                                    </div>

                                </div>
                                <div className="col-lg-6">
                                    <div className="text-center bg-white lan_funfact active p-4 mt-3">
                                        <div className="fun_effect"></div>
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="654">12</h1>
                                        <p className="lan_fun_name text-muted mb-4">Editing Hours</p>
                                    </div>
                                    <div className="text-center bg-gradient position-relative lan_funfact text-white p-4 mt-3">
                                        <div className="fun_effect2"></div>
                                        <h1 className="mb-1 font-weight-bold mt-4"><span className="features_count" data-count="124">50</span> <span>+</span> </h1>
                                        <p className="lan_fun_name text-white mb-4">Team Member</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
