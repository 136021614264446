/* eslint-disable */
import React from "react";
import MaskedInput from "react-text-mask"
class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { fields: {}, errors: {}, isValid: false };
        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    }
    handleSubmit() {
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;
        let pattern = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (
            fields["FullName"] === null ||
            fields["FullName"] === undefined ||
            fields["FullName"] === ""
        ) {
            errors["FullName"] = "Please Enter Your Last Name";
            isValid = false;
        } else if (
            fields["FullName"] !== null ||
            fields["FullName"] !== undefined ||
            fields["FullName"] !== ""
        ) {
            if (fields["FullName"].length < 4) {
                errors["FullName"] = "Please Enter Your Full Name";
                isValid = false;
            }
        }
        if (
            fields["mobileno"] === null ||
            fields["mobileno"] === undefined ||
            fields["mobileno"] === ""
        ) {
            errors["mobileno"] = "Please Enter Your Mobile Number";
            isValid = false;
        } else if (
            fields["mobileno"] !== null ||
            fields["mobileno"] !== undefined ||
            fields["mobileno"] !== ""
        ) {
            if (fields["mobileno"].length < 10) {
                errors["mobileno"] = "Please Enter Your Mobile Number";
                isValid = false;
            }
        }
        if (
            fields["email"] === null ||
            fields["email"] === undefined ||
            fields["email"] === ""
        ) {
            errors["email"] = "Please Enter Your Email";
            isValid = false;
        } else if (
            fields["email"] !== null ||
            fields["email"] !== undefined ||
            fields["email"] !== ""
        ) {
            if (fields["email"].length < 10) {
                errors["email"] = "Please Enter Your Email";
                isValid = false;
            }
        } else if (!pattern.test(fields["email"])) {
            isValid = false;
            errors["email"] = "Please Enter Correct Email";
        }


        this.setState({ errors, isValid });
        if (this.state.isValid) {
            return alert("Form Submitted Successfully");
        }

    } render() {
        return (
            <section class="text-center complaint">
                <div class="container">
                    <div class="row justify-content-center mt-4">
                        <div class="col-md-5 flex-end">
                            <img src={require("../assets/img/file-complaint.svg")} alt="" class="embed-responsive pl-5 pr-5" />
                        </div>
                        <div class="col-md-6">
                            <h2 class="text-left headingCont">File a <span>Complaint</span></h2>
                            <form name="" method="" action="" role="form" id="mycarform" novalidate="novalidate">
                                <div class="text-left">
                                    <p>
                                        <MaskedInput
                                            mask={[
                                                /[a-zA-z]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                                /[a-zA-z\s]/,
                                            ]}
                                            className="FormTagsBox"
                                            placeholder="Enter your Last Name"
                                            guide={false}
                                            name="FullName"
                                            onChange={this.handle}
                                            value={this.state.fields["FullName"]}
                                        />
                                        <span className="txtError">{this.state.errors["FullName"]}</span>
                                        <span class="focus-border"></span>
                                    </p>
                                </div>
                                <div class="text-left">
                                    <p>
                                        <input
                                            className="FormTagsBox"
                                            onblur="Bluremailcheck(this)"
                                            id="TxtEmail"
                                            name="email"
                                            placeholder="Enter Your Email Id"
                                            type="email"
                                            onChange={this.handle}
                                            value={this.state.fields["email"]}
                                        />
                                        <span className="txtError">{this.state.errors["email"]}</span>
                                    </p>
                                </div>
                                <div class="text-left">
                                    <p>
                                        <MaskedInput
                                            mask={[
                                                /[6-9]/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                            ]}
                                            className="FormTagsBox"
                                            placeholder="Enter Mobile number"
                                            guide={false}
                                            name="mobileno"
                                            onChange={this.handle}
                                            value={this.state.fields["mobileno"]}
                                        />
                                        <span className="txtError">
                                            {this.state.errors["mobileno"]}
                                        </span>
                                    </p>
                                </div>
                                <div class="text-left">
                                    <p>
                                        <textarea class="effect-1" type="text" placeholder="Enter Message" id="message" name="message"></textarea>
                                        <span class="focus-border"></span>
                                    </p>
                                </div>
                                <div class="link-btn text-left">
                                    <button type="button" id="btnSubmit" className="HeroBtnSmall" onClick={this.handleSubmit}>Submit</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
}
    export default ContactForm;
