import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import WhyChoose from "./WhyChoose";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section class="section bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section_title text-left">
                                <div class="title text-custom text-uppercase">Our Service</div>
                                <h2 class="section_header">This gives both expertise and dynamism.</h2>
                                <p class="sec_subtitle">Our team includes experienced professionals and young talent. Our team includes experienced professionals and young talent.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-4">
                            <div class="mt-6 features_box text-center">
                                <div class="features_icon">
                                    <i class="fas fa-handshake "></i>
                                </div>
                                <div class="features_desc">
                                    <h3 class="titleWithFont">General Insurance</h3>
                                    <p class="text-muted mb-0">The general insurance financially protects your property from any damage. The general insurance plans include a policy to protect the property and anything related to your business. This can save you from facing massive financial loss due to natural calamity. </p>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mt-6 features_box text-center">
                                <div class="features_icon">
                                    <i class="fas fa-heartbeat "></i>
                                </div>
                                <div class="features_desc">
                                    <h3 class="titleWithFont">Life Insurance</h3>
                                    <p class="text-muted">Life insurance provides financial protection for survivors of the insured, and may meet other financial objectives, as well (a gift to charity, for example). Families should review their life insurance program and policies regularly and make adjustments to meet changes in circumstances and needs.</p>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mt-6 features_box text-center">
                                <div class="features_icon">
                                    <i class="fas fa-heart "></i>
                                </div>
                                <div class="features_desc">
                                    <h3 class="titleWithFont">Health Insurance</h3>
                                    <p class="text-muted">Health insurance is a type of insurance coverage that typically pays for medical, surgical, prescription drug and sometimes dental expenses incurred by the insured. Health insurance can reimburse the insured for expenses incurred from illness or injury, or pay the care provider directly.</p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
