import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section class="SecondSection">
                <div class="container">
                    <h3 class="SecondSectionHeading">How it Works</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/we-dont-Spam.svg")} />
                                <p>Share your details with us - <br />Don't worry we don't spam</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/best plan for Yourself.svg")} />
                                <p>Compare and choose the <br />best plan for yourself</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("../assets/img/Religare/Pay Online.svg")} />
                                <p>Pay online and get policy<br /> instantly</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            

        </React.Fragment>
    );
};
export default Services;
