import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import LifeMain from "../../Life/LifeMain";
import BimaStreetMotorUIManager from "./BimaStreetMotorUIManager";
import { InsureHealth } from "./InsureHealth"

const ProductList = (props) => {
    const [IsProductShow, setShowProduct] = useState(false);
    const [ProductType, setProType] = useState("Car");
    /*document.getElementsByTagName("footer")[0].style.display= "none"*/
    return (
        IsProductShow ? ((ProductType === "Car" || ProductType === "Bike") ?
            <BimaStreetMotorUIManager close={() => setShowProduct(false)} type={ProductType === "Car" ? "1" : "0"} Motor={ProductType} /> :
            ProductType === "Health" ? <InsureHealth close={() => setShowProduct(false)} /> :
                <LifeMain bimaClose={() => setShowProduct(false)} close={() => setShowProduct(false)} />) :
            <section class="section wizard-section">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-3 col-md-2 col-lg-1">
                            <Link to="/" class="arw-btn">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z"
                                        fill="#FFFAFA" />
                                </svg>
                            </Link>
                        </div>
                        <div class="col-9 col-md-10 col-lg-3 position-relative">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h3 class="f-32 font-regular text-secondary text-capitalize">Select from our range of insurance products
                            </h3>
                                </div>
                            </div>
                            <div class="pageno d-none d-lg-flex"><span>01</span> &nbsp;of 07</div>
                        </div>
                        <div class="col-lg-7">
                            <div id="signin">
                                <div class="wizard-box box">

                                    <div class="row radio-style g-4">
                                        <div class="col-6 col-md-4 mt-4">
                                            <label for="car" class="box" onClick={() => { setProType("Car"); setShowProduct(true) }}>
                                                Car insurance<div class="mt-3 mt-md-4">
                                                    <img View src={require("../assets/img/BimaStreet/car.svg")} class="img-fluid" />
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-6 col-md-4 mt-4">
                                            <label for="bike" class="box" onClick={() => { setProType("Bike"); setShowProduct(true) }}>
                                                Bike insurance
                                        <div class="mt-3 mt-md-4">
                                                    <img View src={require("../assets/img/BimaStreet/bike.svg")} class="img-fluid" />
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-6 col-md-4 mt-4">
                                            <label for="health" class="box" onClick={() => { setProType("Health"); setShowProduct(true) }}>
                                                Health insurance<div class="mt-3 mt-md-4">
                                                    <img View src={require("../assets/img/BimaStreet/health.svg")} class="img-fluid" />
                                                </div>
                                            </label>
                                        </div>

                                        <div class="col-6 col-md-4 mt-4">
                                            <label for="life" class="box" onClick={() => { setProType("Life"); setShowProduct(true) }}>
                                                Life insurance<div class="mt-3 mt-md-4">
                                                    <img View src={require("../assets/img/BimaStreet/life.svg")} class="img-fluid" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>




                                </div>
                            </div>

                        </div>
                    </div>
                    <section class="f-14">
                        <div class="row mt-5">
                            <div class="col-md-11 offset-lg-1">
                                Copyright 2020 - All right Reserved
                    </div>
                        </div>

                    </section>

                </div>
            </section>

    );
};
export default ProductList;
