import React from "react";
import ButtonClass from "../ButtonClass";
const InspectionDec = (props) => {
    return (
        <div id="ChkDigitInspectionModel" className="modal ui-draggable" style={{display:" block"}}>
            <div className="modal-dialog">
                <div className="modal-content text-center">
                    <div className="modal-header ui-draggable-handle">
                        <button type="button" className="close" style={{ color: "#000" }} onClick={ props.close} id="btnPopupHide3">×</button>
                        <h3>Please Select the Inspection Type ?</h3>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="ChkProposalModelPopup">
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="button" value="Share" placeholder="NO" onClick={props.confInsp} className={ButtonClass()} style={{ display: "block", color: "#000" }} data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Please wait..." onclick="callinspection()">Surveyor Managed Inspection</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )
}
export default InspectionDec;