/* eslint-disable */
import React from "react";
const Achievements = (props) => {
    return (
        <section class="text-center achievements">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-12">
                        <h2 class="text-left headingCont">Achievements of Hero Insurance Broking India Limited</h2>
                        <div class="text-left indi-achiv">
                            <div class="topSec">
                                <img src={require("../assets/img/achiv-1.svg")} alt="image" />
                                <h3>Leading Insurance Broker</h3>
                                <div class="clear"></div>
                            </div>
                            <div class="contentSec">
                                <ul>
                                    <li>The <strong>HERO</strong> Brand is well-recognized by <strong>General Insurance</strong> customers in India</li>
                                    <li>We are market Leaders in <strong>two wheeler motor insurance</strong></li>
                                    <li>At HERO, we have an Insurance portfolio of <strong>INR 32 billion+</strong> (USD 450 million+)</li>
                                    <li>Over <strong>10 million</strong> policies are issued through HIBIPL every year</li>
                                    <li>We have a strong <strong>customer connect</strong> with a demonstrated retention track record</li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-left indi-achiv">
                            <div class="topSec">
                                <img src={require("../assets/img/achiv-2.svg")} alt="image" />
                                <h3>PAN India Presence</h3>
                                <div class="clear"></div>
                            </div>
                            <div class="contentSec">
                                <ul>
                                    <li>We manage over <strong>4000 distributors</strong> active in over <strong>1700 locations</strong></li>
                                    <li>With a corporate office in New Delhi; two Branch offices in Mumbai and Bengaluru, we have <strong>50+ touchpoints</strong></li>
                                    <li><strong>'One stop shop'</strong> is our key approach for client engagements with end to end assistance</li>
                                    <li>We aim to serve customers even at the <strong>remotest locations</strong></li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-left indi-achiv">
                            <div class="topSec">
                                <img src={require("../assets/img/achiv-3.svg")} alt="image" />
                                <h3>Digital Innovation</h3>
                                <div class="clear"></div>
                            </div>
                            <div class="contentSec">
                                <ul>
                                    <li>We have state of the art <strong>digital interface</strong> for distributors to sell multiple <strong>GI products*</strong></li>
                                    <li>The <strong>Digital Incubator Centre</strong> in New Delhi leverages <strong>big data analytics </strong>to enhance <strong>products, distribution model</strong> and <strong>customer retention strategies</strong></li>
                                    <li>We employ <strong>robust systems</strong> and <strong>procedures</strong> that are fully equipped to handle business portfolios</li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-left indi-achiv">
                            <div class="topSec">
                                <img src={require("../assets/img/achiv-4.svg")} alt="image" />
                                <h3>Deep Sector Knowledge &amp; Strong team</h3>
                                <div class="clear"></div>
                            </div>
                            <div class="contentSec">
                                <ul>
                                    <li><strong>Our in-house advisory team</strong> caters to all organization and personal insurance requirements across all asset classes</li>
                                    <li>We offer complete suite of <strong>Insurance Broking Solution</strong> for all General Insurance requirements</li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-left indi-achiv">
                            <div class="topSec">
                                <img src={require("../assets/img/achiv-5.svg")} alt="image" />
                                <h3>Geographical Presence</h3>
                                <div class="clear"></div>
                            </div>
                            <div class="contentSec">
                                <ul>
                                    <li>Number of MISPs - 2300+ (Motor Insurance Service Provider)</li>
                                    <li>Number of POS - 3800 + (Point of Sale)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Achievements;
