import React from 'react'
import "react-reveal";
import AccordianBike from './FAQAccordian/AccordianBike';
import Scroller from './Scroller';
const BikeInsurance = (props) => {
    Scroller();
    return (
        <div className="ProduactPageBima">
            <section class="landing-hero">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h2 class="text-secondary">
                                Select your policy from the most extensive range of insurance companies
                    </h2>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <p>Accidental Damages, Bike Theft and Third-Party Cover - get the best insurance protection and coverage from Bimastreet.</p>
                            <a  class="btn btn-primary mt-4">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="hero-img">
                    <img src={require("../assets/img/BimaStreet/bike-claim.svg")} class="img-fluid" />
                </div>
            </section>

            <section class="section pb-0 whatIsBike">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h4 class="text-secondary">What is bike insurance?</h4>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>According to the Federation of Automobile Dealers Associations report, two-wheelers sales jumped nearly sixfold from 159,039 in May 2020 to 898,775 units in August 2020. As the number of vehicles on the road soar, there is also a high risk of accidents and thefts. To enjoy a smooth ride and protect yourself from unnecessary expenses, it is crucial to invest in bike insurance.</p>

                                    <p>Bike insurance is an agreement between the bike owner and the insurance company. A valid bike in-surance plan covers the losses from any unforeseen incurred by your motorcycle due to accident, theft, or damage.  The insurance company pays for these losses against the premium collected over the policy time. It is important to note that many factors affect the policy and insurance premium, such as the make and model of the bike, claims history, RTO registration, among others.</p>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </section>

            <section class="section Whilebuyingbike pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h6 class="text-secondary font-regular">While buying bike insurance, there are a few basic terms that vehicle owners must be aware of. They are</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="row g-5">
                                        <div class="col-md-6">
                                            <div class="row g-0">
                                                <div class="col-4">
                                                    <img src={require("../assets/img/BimaStreet/policyholder.svg")} class="img-fluid" alt="..." />
                                                </div>
                                                <div class="col-6 text-info p-0 pt-3 f-14">
                                                    The policyholder, who owns the vehicle.
                                        </div>

                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-0">
                                                <div class="col-4">
                                                    <img src={require("../assets/img/BimaStreet/insure.svg")} class="img-fluid" alt="..." />
                                                </div>
                                                <div class="col-6 text-info p-0 pt-3 f-14">
                                                    The insurer, the company that will pay on the policyholder's behalf.
                                        </div>

                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-0">
                                                <div class="col-4">
                                                    <img src={require("../assets/img/BimaStreet/premium.svg")} class="img-fluid" alt="..." />
                                                </div>
                                                <div class="col-6 text-info p-0 pt-3 f-14">
                                                    Premium is the periodic amount payable by the policyholder.
                                        </div>

                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="row g-0">
                                                <div class="col-4">
                                                    <img src={require("../assets/img/BimaStreet/the-claim.svg")} class="img-fluid" alt="..." />
                                                </div>
                                                <div class="col-6 text-info p-0 pt-3 f-14">
                                                    The claim, which is the total amount that is liable to be paid after
                                                    deductibles, if any
                                        </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="mandatory-section mandatorySec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Is bike insurance mandatory in India?</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-9">
                                    A third-party bike insurance plan is mandatory by law under the <strong>Motor Vehicle Act, 1988.</strong> Section 196 of the Motor Vehicle Act penalizes any person found guilty of driving an uninsured vehicle, such as a bike, with imprisonment of up to three months or pay a penalty of INR 2000 for a first offense. Although mandatory, two-wheeler insurance must be looked at as a safety measure for the rider for the number of benefits and advantages it offers.
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="benfit-section benefitsSec">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-3 align-self-center">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Benefits of bike or two-wheeler insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <p>Getting bike or two-wheeler insurance online made easy with Bimastreet</p>
                            <a  class="btn btn-primary">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4 g-4 g-md-3 cont">
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Protection from unforeseen financial liability due to accident, theft, or other damages</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Covers liability arising out of accidental death or injuries to third parties</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Insurance is available for all types of two-wheelers, including moped, motorcycle, or scooter</p>
                        </div>
                        <div class="col-md-3">
                            <p>Comprehensive insurance policies include roadside assistance</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue TypesOfBike">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Types of bike insurance policy</h5>
                                    <p>Various types of insurance coverages are offered in India. The two main types of insurance that should be considered while purchasing a new bike or renewing expired insurance are listed.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-4 g-md-3">
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Third-party insurance or Act Only Policy</h6>
                                covers the rider against legal liabilities caused to third-parties such as bodily injuries/death and damages or losses to third party properties.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Comprehensive insurance Or Package Policy</h6>
                                offers enhanced insurance that covers damage to the insured vehicle (Bike) caused due to accidents, fire, theft, vandalism, natural disaster, civil disturbance, etc. perils along with third-party liability and personal accident cover.
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="row mt-5">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h6 class="text-secondary mb-3 font-semibold">Addons</h6>
                                    <p>There are various beneficial add-ons to bike insurance that can be availed based on the necessity of the policyholder. While these are not mandatory, they offer more comprehensive coverage and more benefits to the insured. Some of the add-on insurance coverages are:</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row h-100 g-4 g-md-3">
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">No claim bonus retention cover</h6>
                                Offered by the insurer is a discount that the policyholder can continue in the renewal despite making claims during the policy term.
                            </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box shadow-none h-100 pe-md-5 pb-md-5 ps-md-4">
                                        <h6 class="text-secondary mb-4">Bike breakdown/Roadside assistance cover</h6>
                                Allows the policyholder to call the insurance provider for help in case of any roadside emer-gency such as flat battery, spare keys, flat tyre, minor repairs, towing facility, medical coordi-nation, fuel assistance, taxi benefits, accommodation in case of accident, legal advice.
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section class="section-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Key terms used in bike or two-wheeler insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row g-3 g-md-5">
                                <div class="col-md-6">
                                    <p><strong>Zero depreciation</strong> cover enhances the basic bike insurance policy as it allows the policyhold-er to claim the full price of replaced parts/repair charges without the insurer deducting depreci-ation.</p>

                                    <p><strong>Return to invoice cover</strong> compensates the entire value or near equivalent without depreciation when part of comprehensive cover, in case of total loss or constructive total loss or total theft.</p>

                                    <p><strong>Consumable cover</strong> towards the replacement or replenishing costs of the consumables such as engine oil, bearings, washers, bolt, nut, among other; with new ones, in the event of a partial loss of the vehicle and/or its accessories.</p>
                                </div>
                                <div class="col-md-6">
                                    <p><strong>Engine and gear box</strong> cover protects the bike’s engine or gear box as a result of water ingres-sion, leakage of lubricating oil or coolant or damage to engine or gear box.</p>

                                    <p><strong>Daily conveyance cover</strong> compensates for transportation charges as fixed daily allowance or coupons during the time of two-wheeler repair.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue buyBike">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">How to buy bike insurance online?</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-8">
                                    <p>There is a technology-driven shift in the world of insurance purchase and compensation. As the online insurance process is instantaneous, there is a steady rise in insurance issued online. There is also more transparency as the website, or the platform will contain all the necessary information needed to make an informed choice.</p>
                                </div>
                            </div>

                            <p><strong>To buy online, simply keep the following documents ready</strong></p>
                            <div class="row g-2">
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In the case of new bike insurance</strong></p>
                                        <p>A copy of the invoice for engine number, chassis number, and bike model</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In case of insurance renewal</strong>
                                        </p>
                                        <p>A copy of the Registration Certificate (RC) to enter the right engine number, chassis number, bike model, year of purchase,</p>

                                        <p>Details of previous insurance policy, including policy number, date of policy purchase</p>

                                        <p>Details of previous claims, if any.</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="box shadow-none h-100">
                                        <p><strong class="text-secondary">In case of used-bike (second hand) insurance renewal</strong></p>
                                        <p>A copy of the Registration Certificate (RC) to enter the right engine number, chassis number, bike model, year of purchase,</p>

                                        <p>Details of previous insurance policy, including policy number, date of policy purchase</p>

                                        <p>Details of previous claims, if any.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 whyBuy">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Your quest for the best bike insurance ends here with Bimastreet.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Twin Benefits – Best policy at best premiums AND free claims assistance</p>

                                    <p>Benefit from rich industry experience and knowledge </p>

                                    <p>Free claims assistance for speedy, judicious, and hassle-free settlement</p>

                                    <p>Best insurance policy recommendation from the largest range of insurance companies</p>

                                    <p>Gain access to the widest cover with all add ons available in the industry.</p>
                                </div>
                                <div class="col-md-6">
                                    <p>The choice to choose from various add ons like Nil Depreciation Cover, Return to Invoice, Engine Protector, RSA, Windshield Cover, NCB Protector, etc.</p>

                                    <p>24/7 free claims assistance</p>

                                    <p>Legal assistance in case of Third-Party Claims (TPC)</p>

                                    <p>Access to tie up garages for Value Added Services</p>

                                    <p>Single point of contact 24/7 for all claim related assistance – Free Of Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-primary claimBike">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    Why only buy bike insurance online when you can also get free claim service online?
                        </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <a  class="btn btn-primary">Get Twin Benefits <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <AccordianBike />

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};
export default BikeInsurance;