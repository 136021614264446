import React from "react";
const Product = [
  {
    id: 1,
    name: "Car",
    to: "/Car",
    className: "productTabsRisk",
    src:
        require("./assets/img/Riskovery/Car.png"),
  },
  {
    id: 2,
    name: "2 Wheeler",
    to: "/Bike",
    className: "productTabsRisk",
    src:
        require("./assets/img/Riskovery/Bike.png"),
    },
    {
        id: 3,
        name: "PCV",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/Riskovery/PCV.png"),
    },
  {
    id: 4,
    name: "GCV",
    className: "productTabsRisk",
    to: "/",
    src:
        require("./assets/img/Riskovery/GCV.png"),
    },
   
  {
    id: 5,
    name: "Health",
    className: "productTabsRisk",
    to: "/Health",
    src:
        require("./assets/img/Riskovery/Health.png"),
  },
  {
    id: 6,
    name: "Life",
    className: "productTabsRisk",
    to: "/",
    src:
        require("./assets/img/Riskovery/Life.png"),
  },
];
export default Product;
