import React, { useState } from "react";
import { ChildBtn } from "../ButtonClass";
class CallBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isActive:false,
     }
     this.openPopup=this.openPopup.bind(this)
  }
  openPopup(){
    this.setState({isActive:!this.state.isActive})
  }
  render() { 
    return (
    <React.Fragment>
      <span  onClick={this.openPopup} style={{color:"white"}}>
        Request Call Back
      </span>
      {this.state.isActive?
      <div className="modal fade MTB-Insured-HH ui-draggable show" id="CallRequest" data-backdrop="static" role="dialog">
    <div className="modal-dialog newModalqt">
        <div className="modal-content">
            <div className="modal-header text-center ui-draggable-handle">
                Request Call Back
                <button type="button" className="close" data-dismiss="modal" onClick={this.openPopup}>×</button>
            </div>
            <div className="modal-body">
                <div className="choose-relative">
                    <div className="form-group">
                        <label className="">
                            <select id="Interested" className="FormTagsBox" name="EnquiryTypeList">
                                <option value="">Interested In</option>
                                <option value="Car Insurance">Car Insurance</option>
                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                <option value="Health Insurance">Health Insurance</option>
                                <option value="Travel Insurance">Travel Insurance</option>
                            </select>
                            <span id="VldInterested"></span>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className="">
                            <input id="mobile" type="tel" className="FormTagsBox" onkeypress="return isNumber(event)" placeholder="Mobile Number" maxlength="10" />
                            <span id="Vldmobile"></span>
                        </label>
                    </div>
                    <div className="form-group">
                        <label className="">
                            <select className="FormTagsBox" id="prefertime">
                                <option value="Preferred time">Preferred time</option>
                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                            </select>
                            <span id="Vldprefertime"></span>
                        </label>
                    </div>
                </div>
            </div>
                            <div className="modal-footer text-center">
                                <div className="col-md-6"><input type="button" id="btnReq" className={ChildBtn()} value="Send Request" /></div>
                
            </div>
        </div>
    </div>
</div>:null}

         </React.Fragment>
  );
}};

export default CallBack;
