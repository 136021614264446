const Partner = [


    {
        alt: "icici",
        src: require("../Contents/img/partner/HdfcLifeInsurance.jpg"),
    },
    {
        alt: "icici",
        src: require("../Contents/img/partner/icici.jpg"),
    },
    {
        alt: "icici",
        src: require("../Contents/img/partner/bajaj.png"),
    },
    {
        alt: "icici",
        src: require("../Contents/img/partner/sbi-gene.jpg"),
    },
  {
    alt: "cigna-ttk-health-insurance-2-150x43",
    src: require("../Contents/img/partner/cigna-ttk-health-insurance-2-150x43.png"),
  },
  
  {
    alt: "religare_logo",
    src: require("../Contents/img/partner/religare_logo.png"),
  },
  
  
  
];
export default Partner;
