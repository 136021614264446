import React from "react";
const ManageAccount = (props) => {
    let GoToCRM = "My Account";
    let url = window.location.search;
    let param = new URLSearchParams(url);
    let token = window.localStorage.getItem("token");
    if (param.has("user") || token != null) {
   
            GoToCRM= "GO TO CRM";
    }
    return GoToCRM;
       
        
}
export default ManageAccount;