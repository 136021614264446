import React from "react";
import Services from "./services/Services";
import Tab from "./HomeTab/Tab";
import Testimonial from "./Testimonial";
import OurPartner from "./OurPartner";


const ReligareMiddleSection = () => {
    return (
        <React.Fragment>
            <Tab />
            <Services />
            <Testimonial />
            <OurPartner />
        </React.Fragment>
    );
};
export default ReligareMiddleSection;
