import React from "react";

const About = () => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg AboutUsBG rocket-lazyload lazyloaded">
                    <h3>About Us</h3>
                </div>
            </section>
            <section class="SecondSection AboutSecondSection">
                <div class="container">
                    <div class="SecondSectioninner">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="AboutSecondSectionBox">
                                    <p class="pr-5">
                                        Religare Broking Ltd. (RBL) is a wholly owned subsidiary of Religare Enterprises Limited (REL), a leading diversified financial services group from India. RBL is one of the market leading securities firms in India serving over 10 lakh clients across both Offline and Online platforms. Through its extensive footprint extending to over 500 cities, the company offers broking services in Equity, Currency and Commodity as well as depository participant services. RBL is a member of the NSE/BSE and a depository participant with NSDL and CDSL. RBL also offers TIN facilitation &amp; PAN facility at select branches - a unique service to help an individual with PAN, TAN and TDS/TCS returns related requirements.

                                        Apart from above broking services RBL also offer a wide array of products and services such as distribution of Mutual Funds &amp; Insurance, PMS, Corporate FDs, NCDs structure product and financial planning.. RBL is a registered IRDA Corporate Agent for Life and Non –Life insurance Product. The IRDA Corporate Agent Composite License no is IRDAI/CA0581,which is valid till : 22/07/2021.

                                        
						</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="AboutSecondSectionBox">
                                    <img src={require("./assets/img/Religare/about-us-internal.jpg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionThird ProductPageSectionThirdTwoWH">
                <div class="container">
                    <h3 class="SecondSectionHeading mb-3">Why choose us</h3>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                <img src={require("./assets/img/Religare//Multiple partners.svg")} />
                                <p><strong>Multiple partners</strong> <br />Religare has partnered with the leading insurers in the country to cater to all your insurance needs.</p>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                    <img src={require("./assets/img/Religare//Deep understanding.svg")} />
                                <p><strong>Deep understanding</strong><br /> A legacy of nearly 25+ years into distribution of financial products, has helped us gain a deep understanding about the needs and importance of insurance in one’s life. We leverage this understanding to help you choose an insurance plan that is best suited for your needs.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="SecondSectionBox">
                                        <img src={require("./assets/img/Religare//Customer friendly.svg")} />
                                <p><strong>Customer friendly</strong><br /> You are the priority at Reliagre. Being One of India’s largest non-bank retail broking platforms with 1,000+ touch points. And a dedicated customer support team, every issue is promptly solved.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="SecondSectioninner">
                        <h3 class="SecondSectionHeading mb-3">
                            What we Offer
            </h3>
                        <p class="text-center">
                            Something as critical as insurance requires credibility. At Religare, our certified employees suggest the ideal and most suited insurance plans considering your objectives, obligations and risk profile in various categories like Life Insurance, Health Insurance and General Insurance.
            </p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default About;
