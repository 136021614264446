import React from "react";
import { Health } from "../../Health/Health";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { Route } from "react-router-dom";

import { InsureCar } from "./InsureCar";
import { InsureBike } from "./InsureBike";
import { InsureHealth } from "./InsureHealth";
import LifeDemo from "./LifeDemo";
import Commercial from "../../Commercial/Commercial";
const TabData = [
    {
        id: "1",
        tabTitle: <ProductTab src={Product[0].src} name={Product[0].name} />,
        tabContent: <LifeDemo />,
    },
    {
        id: "2",
        tabTitle: <ProductTab src={Product[1].src} name={Product[1].name} />,
        tabContent: <LifeDemo />,

    },
    
    {
        id: "3",
        tabTitle: <ProductTab src={Product[2].src} name={Product[2].name} />,
        tabContent: <InsureBike />,

    },
    {
        id: "4",
        tabTitle: <ProductTab src={Product[3].src} name={Product[3].name} />,
        tabContent: <InsureCar />,

    },
    {
        id: "5",
        tabTitle: <ProductTab src={Product[4].src} name={Product[4].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "6",
        tabTitle: <ProductTab src={Product[5].src} name={Product[5].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "7",
        tabTitle: <ProductTab src={Product[6].src} name={Product[6].name} />,
        tabContent: <InsureHealth />,

    },
    {
        id: "8",
        tabTitle: <ProductTab src={Product[7].src} name={Product[7].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "9",
        tabTitle: <ProductTab src={Product[8].src} name={Product[8].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "10",
        tabTitle: <ProductTab src={Product[9].src} name={Product[9].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "11",
        tabTitle: <ProductTab src={Product[10].src} name={Product[10].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "12",
        tabTitle: <ProductTab src={Product[11].src} name={Product[11].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "13",
        tabTitle: <ProductTab src={Product[12].src} name={Product[12].name} />,
        tabContent: <LifeDemo />,

    },
    {
        id: "14",
        tabTitle: <ProductTab src={Product[13].src} name={Product[13].name} />,
        tabContent: <Commercial Motor="GCV" type="3" />,

    },
    {
        id: "15",
        tabTitle: <ProductTab src={Product[14].src} name={Product[14].name} />,
        tabContent: <Commercial Motor="PCV" type="2" />, 

    },
];
export default TabData;
