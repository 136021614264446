import React from "react";
import Services from "./services/Services";
import OurPartner from "./OurPartner";
import SecuritySection from "./SecuritySection";
import Tab from "./HomeTab/Tab";

const BMBMiddleSection = () => {
    return (
        <React.Fragment>
            <Tab />
            <Services />
            <OurPartner />
            <SecuritySection />
        </React.Fragment>
    );
};
export default BMBMiddleSection;
