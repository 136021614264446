import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Component } from "react";

import MaskedInput from 'react-text-mask';
import axios from "axios";

class InsureCar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            fields: { registration: "" },
            errors: {},
            isValid: false,
            registration: null,
            RTOList: [],
            ValidRTO: false,
        };
        this.handleChange = this.handleChange.bind(this);

        this.checkRto = this.checkRto.bind(this);
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
            .then(Res => {
                console.log(Res.data)
                Res.data.forEach(element => {
                    this.state.RTOList.push(element.RTOName.split(" ")[0])
                });
            })
    }
    checkRto(rto) {
        let ValidRTO = true;
        let selectedRTO = this.state.RTOList.filter(row => row.toLowerCase().includes(rto.substring(0, 4).toLowerCase()));
        if (selectedRTO.length > 0) {
            ValidRTO = true;

        } else {
            ValidRTO = false

        }
        this.setState({ ValidRTO });
        return ValidRTO;
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields, [e.target.name]: e.target.value
        });
        let errors = {};
        let isValid = true;
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] == "") {
            isValid = false
            errors["registration"] = "Please Enter Correct Number"
        }
        else if (this.state.fields["registration"] !== undefined) {
            if (!this.checkRto(e.target.value)) {
                isValid = false;
                errors["registration"] = "Please Enter Correct RTO"
            } else if (this.state.fields["registration"].length < 9) {
                isValid = false;
            }
        }
        this.setState({ errors, isValid, fields })
    }





    submit() {
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] === "")
            return alert("Please enter Registration Number ")
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    render() {

        return (
            <div className="container-fluid">
                <div className="row d-flex justify-content-center pt-3">
                    <div className="col-sm-12 productTabsMainCar">
                        <h3 className="heading">
                            <span className="hiddenPart">We help you find better plans for your</span>
                            <strong>&nbsp;Car Insurance</strong>
                        </h3>
                        <h6 className="text-center">
                            <p>Get best offers with attractive premium</p>
                        </h6>

                        <div className="ProductFormIn ProductForm01">
                            <div className="PF0012 SpaceBetweenRow002">
                                <div className="row">
                                    <div className="col-md-12">


                                        <label>
                                            <MaskedInput onChange={this.handleChange} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder="Enter Car Registration Number" name="registration" style={{ textTransform: "uppercase" }} value={this.state.fields["registration"]} />
                                        </label>
                                        <small className="CarRegError">{this.state.errors["registration"]}</small>

                                    </div>
                                    <div className="col-md-12">
                                        <label>

                                        {
                                            this.state.isValid ?<Link to="/Renew?type=1">
                                            <button className="QouteMNBtnrisk" id="quotesBtn"  >VIEW QUOTES</button></Link>:
                                            <button className="QouteMNBtnrisk diablebtn" id="quotesBtn"  >VIEW QUOTES</button>}
                                        </label>
                                    </div>


                                    <div className="col-md-12">

                                        <Link

                                            to="/Renew?type=1"
                                            className="text-center headingBt"
                                        >
                                            Don't know your car number? <b>Click here</b>
                                        </Link>

                                    </div>
                                    <div className="col-md-12">
                                        <Link className="headingBt text-center"
                                            to="/New?type=1"
                                        > Bought a new car? <b>Click here</b></Link>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}
export { InsureCar };
