import React from 'react';
import "./DataLoader.css";
const DataLoader=(props)=>{
    return(
      
        <div className="spinner-box">
        <div className="loading-icon">
        </div>
        <span>Loading...</span>
      </div>
    )
}
export default DataLoader;