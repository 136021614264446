import React, { useState } from 'react';
import ButtonClass from '../ButtonClass';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';
const VahanDataSelection = (props) => {
    console.log(props)
    const [isManu, setIsManu] = useState(6);
    return (

        <section class="section wizard-section ProoductStepBima">
            <div class="container">
                <div class="row g-3">
                    <div class="col-3 col-md-2 col-lg-1">
                        <a onClick={() => isManu === 6 ? props.closeBtn() : setIsManu(  isManu - 1 )} class="arw-btn">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z"
                                    fill="#FFFAFA" />
                            </svg>
                        </a>
                    </div>
                    <div class="col-9 col-md-10 col-lg-3 position-relative">
                        <div class="row">
                            <div class="col-lg-8">
                                <h3 class="f-32 font-regular text-secondary text-capitalize">
                                    {isManu === 6 && "Confirm Your Details Before Proceeding"}
                                    {isManu === 7 && "Do We Need To Know Anything Else?"}
                                </h3>
                            </div>
                        </div>
                        <div class="pageno d-none d-lg-flex"><span>{"0" + isManu}</span> &nbsp;of 07</div>
                    </div>
                    <div class="col-lg-8">
                        <div class="wizard-box">
                            <div>

                                {isManu === 6 &&
                                    <div>
                                        <div class="readonly-form">

                                            <div class="row g-2 mb-4">
                                                <div class="col-12">
                                                    <div class="form-control" readonly aria-label="Make"><strong>Make and Modal</strong> - {props.CarVal.length > 0 ? props.CarVal[0].Manu_Vehicle : null}</div>
                                                </div>

                                            </div>
                                            <div class="row g-2 mb-4">
                                                <div class="col-12">
                                                    <div class="form-control" readonly aria-label="Modal"><strong>Fuel</strong> - {props.fuil}</div>
                                                </div>
                                                {/*<div class="col-4 col-md-3">*/}
                                                {/*    <Link to={"/Renew?type=" + props.type} class="btn btn-primary form-btn">Edit</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div class="row g-2 mb-4">
                                                <div class="col-12">
                                                    <div class="form-control" readonly aria-label="Variant"><strong>Variant</strong> -
                                                                {props.SelectedVariant.length > 0 ? props.SelectedVariant[0].VariantName : null}
                                                    </div>
                                                </div>
                                                {/*<div class="col-4 col-md-3">*/}
                                                {/*    <Link to={"/Renew?type=" + props.type} class="btn btn-primary form-btn">Edit</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div class="row g-2 mb-4">
                                                <div class="col-12">
                                                    <div class="form-control" readonly aria-label="RTO"><strong>RTO</strong> -
                                                                {props.RTOVal.length > 0 ? props.RTOVal[0].RTOName : null}
                                                    </div>
                                                </div>
                                                {/*<div class="col-4 col-md-3">*/}
                                                {/*    <Link to={"/Renew?type=" + props.type} class="btn btn-primary form-btn">Edit</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div class="row g-2 mb-4">
                                                <div class="col-12">
                                                    <div class="form-control" readonly aria-label="Year"><strong>Year</strong> - {props.YearVal}</div>
                                                </div>
                                                {/*<div class="col-4 col-md-3">*/}
                                                {/*    <Link to={"/Renew?type=" + props.type} class="btn btn-primary form-btn">Edit</Link>*/}
                                                {/*</div>*/}
                                            </div>



                                        </div>
                                        <div class="wizard-bottom d-flex">
                                            <Link to={"/Renew?type=" + props.type} class="btn btn-blue d-inline-block w-50"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z" fill="#FFFAFA"></path></svg> Edit
                                            </Link>
                                            <button class="border-left btn btn-blue w-50" onClick={() => setIsManu(isManu + 1)}>Next <svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z"
                                                    fill="#FFFAFA" />
                                            </svg>
                                            </button>

                                        </div>
                                    </div>
                                }

                                {isManu === 7 &&
                                    <div className="row">
                                        <div class="col-md-12">
                                            {props.CarVal.length > 0 ?
                                                <strong class="text-black">
                                                    <span>
                                                        {props.CarVal[0].Manu_Vehicle}
                                                    </span>
                                                    <span class="text-black">{props.fuil}</span>
                                                    <span>
                                                        {props.SelectedVariant.length > 0 ? props.SelectedVariant[0].VariantName : null}
                                                    </span>
                                                    <span>
                                                        {props.RTOVal.length > 0 ? props.RTOVal[0].RTOName : null}
                                                    </span>
                                                    <span>
                                                        {props.YearVal}
                                                    </span>
                                                </strong>
                                                : null}
                                        </div>
                                        <div class="col-md-6 mt-4">
                                            <label for="" class="form-label">Phone</label>
                                            <MaskedInput className="form-control" type="tel"
                                                mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                guide={false} showMask={false} placeholder="Mobile Number" value={props.MobileNo}
                                                name="MobileNo"
                                                onChange={props.handle} />
                                            <small className="p-0">For Policy related updates</small>
                                        </div>
                                        <div class="col-md-12 mt-4">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    className="packageFormCheck"
                                                    name="Organisation"
                                                    onChange={props.handle}
                                                    checked={props.Organisation}
                                                />
                                                <label className="form-check-label">Vehicle owned by organisation</label>
                                            </div>
                                            <React.Fragment>
                                                {!props.Previouspolicydetails ?
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                            type="checkbox"
                                                            className="packageFormCheck"
                                                            name="MyPolicyexpired"
                                                            checked={props.MyPolicyexpired}
                                                            onChange={props.handle}
                                                        />
                                                        <label className="form-check-label">My policy expired more than 90 days</label>
                                                    </div>
                                                    : null
                                                }
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        type="checkbox"
                                                        className="packageFormCheck"
                                                        name="Previouspolicydetails"
                                                        checked={props.Previouspolicydetails}
                                                        onChange={props.handle}
                                                    />
                                                    <label className="form-check-label">I don't have previous policy details</label>
                                                </div>
                                            </React.Fragment>

                                        </div>


                                        {!props.Previouspolicydetails ?

                                            <div className="col-md-6 mt-4">
                                                <label> <span >Previous policy type</span></label>
                                                <select
                                                    className="packageForm"
                                                    name="PrevPolicyType"
                                                    value={props.PrevPolicyType}
                                                    onChange={props.handle}
                                                >
                                                    <option value="1">Comprehensive</option>
                                                    <option value="2">Third Party</option>
                                                    <option value="3">OD Cover Only</option>
                                                </select>
                                            </div>
                                            : null}

                                        {!props.MyPolicyexpired ?



                                            <div className="col-md-12 mt-4 mb-5">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label>Have you made a claim in the past year?</label>
                                                        <div className="ForMobileApp">
                                                            <div className="GenderApp Male">
                                                                <input

                                                                    className="packageFormCheck pl-2"
                                                                    type="radio"
                                                                    value="N"
                                                                    name="PastYearClaimID"
                                                                    checked={!props.PastYearClaimID}
                                                                    onChange={props.handle}
                                                                />
                                                                <span><small>No</small></span>
                                                            </div>
                                                            <div className="GenderApp FeMale">
                                                                <input
                                                                    name="PastYearClaimID"
                                                                    type="radio"
                                                                    value="Y"
                                                                    className="packageFormCheck pl-2"
                                                                    checked={props.PastYearClaimID}
                                                                    onChange={props.handle}
                                                                />
                                                                <span><small>Yes</small></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {!props.PastYearClaimID ? (
                                                            <label>
                                                                <span>Previous No Claim Bonus (NCB)</span>
                                                                <select
                                                                    className="packageForm"
                                                                    name="BindPreviousNCB"
                                                                    value={props.BindPreviousNCB}
                                                                    onChange={props.handle}
                                                                >
                                                                    <option value="0">0%</option>
                                                                    <option value="20">20%</option>
                                                                    <option value="25">25%</option>
                                                                    <option value="35">35%</option>
                                                                    <option value="45">45%</option>
                                                                    <option value="50">50%</option>
                                                                </select>
                                                            </label>
                                                        ) : <div>
                                                                <label>Claim Amount</label>
                                                                <MaskedInput
                                                                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                    guide={false} showMask={false}
                                                                    value={props.ClaimAmount} className="packageForm" name="ClaimAmount"
                                                                    onChange={props.handle} onBlur={props.onBlur}

                                                                />

                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}

                                        <div class="wizard-bottom">
                                            <button class="btn btn-blue w-100"
                                                className={ButtonClass()}
                                                value={props.BtnValue}
                                                onClick={props.proceed}>Next <svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z"
                                                        fill="#FFFAFA" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>



                <section class="f-14">
                    <div class="row mt-5">
                        <div class="col-md-11 offset-lg-1">
                            Copyright 2020 - All right Reserved
                                        </div>
                    </div>

                </section>

            </div>
        </section>

    )
}
export default VahanDataSelection