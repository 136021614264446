import React from "react";
import { Carousel } from "react-bootstrap"
const Testimonial = (props) => {
    return (
        <div className="fithSection">
            <div class="ProductPageSectionSecondHead">
                <h3 class="SecondSectionHeading">Testimonial</h3>
            </div>
            <Carousel>
                
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">I really appreciate the products and services of Religare Broking, I have been taking guidance on my investments from them from last 3 years and I am very satisfied with their products, customer support and services. They have full bouquet of investment products to cater my financial planning. I would recommend Religare Broking to all my friends and relatives. Thank You RBL Team.</p>
                        <div class="star-rating"><strong>Sandeep Makol</strong> - New Delhi</div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">I have been investing in various products with Religare Broking for more than 2 years now, and the team has proven on numerous time that they always has my best interest at heart. They have consistently recommended the best solution for all my insurance needs time to time that were good for me and my financial plans. I am relaxed that my all insurance policies and investment are in safe hands.</p>
                        <div class="star-rating"><strong>Himanshu</strong> - Jaipur</div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">My relationship with RBL started when I purchased my health insurance and personal accident policy.  Their products and services as well as customer support is really great. I haven’t faced any problem in availing the services till date. I am satisfied with the support and services I get from RBL team. Thank You!</p>
                        <div class="star-rating"><strong>Jasbir Singh</strong> - New Delhi</div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>

    );
};
export default Testimonial;
