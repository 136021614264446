import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";

import "./assets/css/RFL.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import RiskFreeMiddleSection from "./RiskFreeMiddleSection";
import { NavMenu } from "./NavMenu";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import LifeProposal from "../Life/LifeProposal";
import MotorInsurance from "./FooterPages/MotorInsurance";
import HealthInsurance from "./FooterPages/HealthInsurance";
import TermLifeInsurance from "./FooterPages/TermLifeInsurance";
import HomeInsurance from "./FooterPages/HomeInsurance";
import TravelInsurance from "./FooterPages/TravelInsurance";
import TermsAndCondition from "./FooterPages/TermsAndCondition";
import PrivacyPolicy from "./FooterPages/PrivacyPolicy";
const findPath = () => {
    let url = window.location.href.toLowerCase();
    let path = "HRIKLS";
    if (url.match("proposalmotor") || url.match("proposalhealth"))
        path = window.location.pathname
    return path;
}
const RiskFreeLife = (props) => {
    let apiPath = findPath();
    console.log('home re-rending');
    return (
        <React.Fragment>
            <div className="RFLbody">
                <NavMenu />
                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />




                </Layout>

                <Switch>
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />
                    <Route exact path="/" component={RiskFreeMiddleSection} />
                    <Route exact path="/MotorInsurance" component={MotorInsurance} />
                    <Route exact path="/HealthInsurance" component={HealthInsurance} />
                    <Route exact path="/TermLifeInsurance" component={TermLifeInsurance} />
                    <Route exact path="/HomeInsurance" component={HomeInsurance} />
                    <Route exact path="/TravelInsurance" component={TravelInsurance} />
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route exact path="/LifeProposal" component={LifeProposal} />
                    <Route exact path="/GetQuoteLife" component={LifeQuote} />
                    <Route
                        exact
                        path="/HealthShowProposal"
                        component={HealthShowProposal}
                    />
                    
                    

                    <Route path="/About" component={About} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apiPath} component={PaymentSuccessOrFail} />
                    <Route exact path="/TermsAndCondition" component={TermsAndCondition} />
                    <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
                </Switch>

                <Footer />
                <SideNav />
                <SocialToggle mobile="18001232589" email="info@riskfreelife.com" whatsapp="" />
            </div>
        </React.Fragment>
    );
};
export default RiskFreeLife;
