import React from 'react';
const Mediclaim=()=>{
    return(
        <section className="productCntMain">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="productCnt">
                    <h3>Health Insurance</h3>
                    <p>
                        TYPES  OF HEALTH INSURANCE PLANS<br/>
                        <strong>HOSPITALIZATION PLANS:</strong><br/>
                        Hospitalization  plans reimburse the hospitalization and medical costs of the insured subject to  the sum insured. For this reason, the plans are also known as indemnity plans.
                    </p>
                    <p>
                        The  sum assured can be fixed -<br/>
                        For  a member of the family in case of individual health policies or<br/>
                        For  a family as a whole in case of a family health insurance policy<br/>
                        For  instance, consider a three-member family with an individual cover of Rs. 2 lakh  each. Each member can claim reimbursement for a maximum of Rs. 2 lakh as all  three policies are independent.
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <img src={require("../assets/img/Mediclaim.png")} />
            </div>
            <div className="col-md-12">
                <div className="productCnt">
                    
                    
                    <p>If  the family applies for a family health plan cover of Rs. 6 lakhs, then any  family member can claim medical benefit for more than Rs. 2 lakh so long as it  is within the overall sum assured of Rs. 6 lakhs.</p>
                    <p>
                        <strong>HOSPITAL DAILY CASH  BENEFIT PLANS:</strong><br/>
                        A  hospital cash plan pays you a specific prefixed amount for each day you are  hospitalized. The number of days for which this benefit is paid is typically  capped. This plan is useful to cover ancillary costs (medical and non-medical)  that are not covered under health insurance plan and to provide for loss of  income during hospitalization ( for self employed ). While deciding this plan  you need to look into Daily cash limit, Number of days of coverage in a policy  year and eligibility (or pre-conditions) for claiming the benefit.
                    </p>
                    <p>
                        <strong>CRITICAL ILLNESS  PLANS:</strong><br/>
                        These  are benefit-based health insurance plans which pay a lumpsum amount on  diagnosis of predefined critical illnesses and medical procedures. The  illnesses are specified at the outset. By nature, critical illnesses are high  severity and low frequency and cost of treatment is higher compared to regular  medical problems like heart attack, stroke, among others.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>
    )
}
export default Mediclaim