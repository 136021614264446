/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
const AccordianLife = (props) => {

    return (
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 class="text-secondary mb-3">Frequently asked questions</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <Accordion class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Why should I invest in life insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Everyone has different life goals. Life insurance acts as an income replacement
                                        scheme for the policyholder's dependents in case of any misfortune. Life insurance
                                        creates peace of mind as the policyholder can be guaranteed returns on regular
                                        premium payment. Life insurance also acts as a good investment as it helps cover
                                        personal loans or mortgages.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    What are the benefits of life insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <ul>
                                            <li>Life insurance secures the financial and social wellbeing of the families
                                            even when the policyholder is not around and is more an obligation to the
                                            family by everyone.</li>
                                            <li>Cover can be extended to Critical Illnesses. Suppose the life assured
                                            suffers from a listed critical illness and survives. In that case, a lump
                                            sum benefit is payable to compensate for his/her reduced income levels due
                                            to such critical illnesses.</li>
                                            <li>Insurance also acts as a savings plan giving enhanced returns over a period
                                            of time and is paid as Maturity Benefit in case the life assured survives
                                            the policy term.</li>
                                            <li>Acts as a Tax Saving instrument since the life insurance premium is
                                            deductible under Section 80C of the Income Tax Act, 1961</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    What are the different types of life insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>

                                        <ul>
                                            <li>Term insurance plan is the most popular type of life insurance policy. This
                                            plan gives protection and assurance to the family's future of the life
                                            assured in case of unfortunate death of the life assured during the policy
                                            term leaving the family an estate after his death.</li>
                                            <li>An endowment policy is a comprehensive life insurance policy providing
                                            financial cover to the family in case of death during the policy period
                                            paying the Sum Assured to the nominee. This plan gives triple benefits -
                                            life insurance protection, maturity benefit, and tax benefit.</li>
                                            <li>Whole life Policy is the best life insurance plan as this gives protection
                                            till the age of 100 years while the premium is paid only for a predetermined
                                            fixed term.</li>
                                            <li>Money back policy pays a percentage of the sum assured at predetermined
                                            intervals throughout the policy tenure besides protecting the life assured
                                            in case of death.</li>
                                            <li>Child Plan is the most needed plan for young families with children whose
                                            future can be secured in case of an unfortunate event to the life assured.
                                        </li>
                                            <li>An investment plan is a life insurance policy that will invest the premiums
                                            paid in different funds like Equity, Debt, and Balanced, thus optimizing the
                                            returns besides giving life insurance cover against death during the policy
                                            period.</li>
                                            <li>A retirement plan contributes while in service so that a Corpus is created
                                            by the time of retirement. Such Corpus can be invested in Annuities of life
                                            insurance companies.</li>
                                            <li>Group Term Life insurance plan covers a group of people falling under a
                                            Group like Employer-Employee Group under one group policy. Sometimes this
                                            policy is extended to give Critical Illness Benefit also.</li>
                                        </ul>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How much does life insurance cost?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        The actual cost of a life insurance policy is entirely dependent on
                                    <ul>
                                            <li>The type of policy taken</li>
                                            <li>Sum insured</li>
                                            <li>Age</li>
                                            <li>Health condition of the policyholder</li>
                                            <li>Benefits of the policy upon maturity</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    What if I don't pay a premium regularly?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        There is a grace period of up to 30 days to pay the overdue premium. The policy,
                                        however, stands defunct until the premium due is paid subject to certain terms and
                                        conditions as per the policy.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    When is the right time to buy life insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        The Premium amount is directly dependent on the age of the policyholder. So, it is
                                        advisable to buy as early as possible.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    What are the different payment options for premium?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        Policyholders can choose from monthly, quarterly, semi-annual or annual payment
                                        options. However, it is advisable to stick to a schedule and pay a premium on time
                                        to avoid policy lapses.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                    Is it possible to make changes to the insurance policy after purchase?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        The policyholder can change the beneficiary, sum insured, after purchasing the
                                        policy. However, it is best advised to purchase a policy after a thorough evaluation
                                        of the current financial position and future needs.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    What are the advantages of buying a life insurance policy from Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        <ul>
                                            <li>Twin Benefits – Best policy at best premiums AND Free Claims Assistance
                                            (FCA)</li>
                                            <li>Under a life insurance policy, we are committed to extending total
                                            assistance and guidance to the nominee/family of the life assured during
                                            unfortunate death, ensuring speedy and hassle-free claim settlement to the
                                            family, thus fulfilling the desire of the life assured in taking life
                                            insurance.</li>
                                            <li>Our Free Claims Assistance also extends to arranging the Maturity Benefits
                                            to the life assured if he survives the policy term under endowment plans,
                                            whole life plans, child plans, and Term with Return of Premium policies.
                                        </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="9">
                                    How to file for an insurance claim in Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        The first step in the claims process is to inform the insurance company about the
                                        claim. While filing for a claim for Maturity Benefit, Money Back or Critical Illness
                                        or Death, please keep the following details ready –

                                    <ul>
                                            <li>Name of the insured as per the policy</li>
                                            <li>Insurance Company Name</li>
                                            <li>Policy Number</li>
                                            <li>Contact details of the insured or claimant (mail id & Mobile Number)</li>
                                            <li>Policy document</li>
                                            <li>In case of Death Claim</li>
                                            <ul>
                                                <li>Date of Death</li>
                                                <li>Cause of Death</li>
                                                <li>Death Certificate</li>
                                            </ul>
                                        </ul>
                                    In case of Critical Illness Claim
                                    <ul>
                                            <li>Date of Diagnosis of Critical Illness</li>
                                            <li>Name of Critical Illness Diagnosed</li>
                                            <li>Doctor Certificate</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AccordianLife;
