import React from 'react';
const ManageUrl = (props) => {
    let Item = props;
    let Data = {
        CRMAPI: "",
        PortalAPI: ""
    }
    if (Item.match("smc")) {
        Data.CRMAPI = "https://crmapi.smcinsurance.com:8081";
        Data.PortalAPI = "https://smcinsurance.com";
    }
    else if (Item.match("riskovery")) {
        Data.CRMAPI = "https://riskoveryinsurance.com/coreapi";
        Data.PortalAPI = "https://riskoveryinsurance.com";
    }
    else if (Item.match("riskfreelife")) {
        Data.CRMAPI = "https://riskfreelife.com/coreapi";
        Data.PortalAPI = "https://riskfreelife.com";
    }
    else if (Item.match("insureasy")) {
        Data.CRMAPI = "https://insureasy.com/coreapi";
        Data.PortalAPI = "https://insureasy.com";
    }
    else if (Item.match("hero")) {
        Data.CRMAPI = "https://heroinsurance.com/coreapi";
        Data.PortalAPI = "https://heroinsurance.com";
    }
    else if (Item.match("onlinebimakaro")) {
        Data.CRMAPI = "https://onlinebimakaro.com/coreapi";
        Data.PortalAPI = "https://onlinebimakaro.com";
    }
    else if (Item.match("insurekare")) {
        Data.CRMAPI = "https://insurekare.com/coreapi";
        Data.PortalAPI = "https://insurekare.com";
    }
    else if (Item.match("myinsurancekart")) {
        Data.CRMAPI = "https://myinsurancekart.com/coreapi";
        Data.PortalAPI = "https://myinsurancekart.com";
    }
    else if (Item.match("sansominsure")) {
        Data.CRMAPI = "https://sansominsure.in/coreapi";
        Data.PortalAPI = "https://sansominsure.in";
    }
    else if (Item.match("policy2cover")) {
        Data.CRMAPI = "https://policy2cover.com/coreapi";
        Data.PortalAPI = "https://policy2cover.com";
    }
    else if (Item.match("sarsabima")) {
        Data.CRMAPI = "https://sarsabima.com/coreapi";
        Data.PortalAPI = "https://sarsabima.com";
    }
    else if (Item.match("bimaplanner")) {
        Data.CRMAPI = "https://bimaplanner.com/coreapi";
        Data.PortalAPI = "https://bimaplanner.com";
    }
    else if (Item.match("bookmybima")) {
        Data.CRMAPI = "https://bookmybima.com/coreapi";
        Data.PortalAPI = "https://bookmybima.com";
    }
    else if (Item.match("advancelifeinsurance")) {
        Data.CRMAPI = "https://advancelifeinsurance.in/coreapi";
        Data.PortalAPI = "https://advancelifeinsurance.in";
    }
    else if (Item.match("enivesh")) {
        Data.CRMAPI = "https://enivesh.co.in/coreapi";
        Data.PortalAPI = "https://enivesh.co.in";
    }
    else if (Item.match("bimastreet")) {
        Data.CRMAPI = "https://bimastreet.com/coreapi";
        Data.PortalAPI = "https://bimastreet.com";
    }
    else if (Item.match("policyleader")) {
        Data.CRMAPI = "https://policyleader.co.in/coreapi";
        Data.PortalAPI = "https://policyleader.co.in";
    }
    else if (Item.match("starinsured")) {
        Data.CRMAPI = "https://starinsured.com/coreapi";
        Data.PortalAPI = "https://starinsured.com";
    }
    else if (Item.match("inshoracover")) {
        Data.CRMAPI = "https://coreapi.stariglocal.com";
        Data.PortalAPI = "https://inshoracoverapi.stariglocal.com";
    }
    else if (Item.match("religare")) {
        Data.CRMAPI = "https://insurance.religareonline.com/coreapi";
        Data.PortalAPI = "https://insurance.religareonline.com";
    }
    else if (Item.match("insurancemandi")) {
        Data.CRMAPI = "https://insurancemandi.com/coreapi";
        Data.PortalAPI = "https://insurancemandi.com";
    }
    else if (Item.match("pratiraksha4ever")) {
        Data.CRMAPI = "https://pratiraksha4ever.com/coreapi";
        Data.PortalAPI = "https://pratiraksha4ever.com";
    }
    else if (Item.match("veinsure")) {
        Data.CRMAPI = "https://veinsure.com/coreapi";
        Data.PortalAPI = "https://veinsure.com";
    }
    else if (Item.match("localhost")) {

        Data.CRMAPI = "https://coreapi.stariglocal.com";
        Data.PortalAPI = "https://inshoracoverapi.stariglocal.com";
    }
    return Data;
}
export default ManageUrl;