import React from "react";
const Dedicated = (props) => {
    return (
        <section class="ProductPageSectionSecond">
            <div class="container">
                <div class="ProductPageSectionSecondInner">
                    <div class="ProductPageSectionSecondHead">
                        <h3 class="SecondSectionHeading">Offering a bouquet of Insurance <br />products for you</h3>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Term_Insurance.svg")} />
                                        </div>
                                        <h3 class="ts-title"> Term Insurance</h3>

                                    </div>
                                    <div class="faq-content" id="faqone-1">
                                        <p> Term insurance is a type of life insurance policy that provide coverage for a certain period of time or a specified terms of years, if the insured dies during the period of policy and policy is active and in force  ,a death benefit will be paid.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Investment Plan.svg")} />
                                        </div>
                                        <h3 class="ts-title"> Investment Plan </h3>

                                    </div>
                                    <div class="faq-content" id="faqone-2">
                                        <p>We have a wide range of investment products to take care of child education/daughter wedding/old age pension and other financial needs which arises time to time on different phases of life.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Pension Plan.svg")} />
                                        </div>
                                        <h3 class="ts-title">Pension Plan </h3>

                                    </div>
                                    <div class="faq-content" id="faqone-3">
                                        <p>With change in lifestyle, the average life expectancy has increased. So, you will need more funds for your future. We are providing consultancy to plan your old age right now by offering the best suitable pension schemes for you.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Child Plan.svg")} />
                                        </div>
                                        <h3 class="ts-title">Child Plan</h3>

                                    </div>
                                    <div class="faq-content" id="faqone-4">
                                        <p>Child plans are investment cum insurance plans that help to plan children's future financial requirements by creating funds over a period of time. A child plan ensures payment of a lump-sum amount to a child on maturity to cover child's college fees or marriage expenses.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Travel_Insurance.svg")} />
                                        </div>
                                        <h3 class="ts-title">Travel Insurance </h3>

                                    </div>
                                    <div class="faq-content" id="faqone-5">
                                        <p> Travel insurance is an insurance product for covering unforeseen losses incurred while travelling, either internationally or domestically. We are here to provide you the best solution for your all travel insurance needs on a competitive price with best coverage.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ProductPageSectionSecondBox">
                                <div class="ts-faq-single">
                                    <div class="faq-heading collapsed">
                                        <div class="faq-img">
                                            <img src={require("../assets/img/Religare/Covid Plan.svg")} />
                                        </div>
                                        <h3 class="ts-title">Covid Plan</h3>

                                    </div>
                                    <div class="faq-content" id="faqone-6">
                                        <p>While most health insurance policies in India claim to be providing cover for the coronavirus infection, there are various criteria under which policyholders are also denied a claim for its treatment. We are here to provide best solution for the need of insurance in pandemic situation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
