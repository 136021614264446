/* eslint-disable */
import React, { useState } from "react";
import { ChildBtn } from "../ButtonClass";
import CompareBox from "./CompareBox";
import Loader from "./Loader";
class HealthCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compareItem: [],
            compareBox: false,
            IsShowFeatures: false,
        };
      
    }

    render() {
        return (
            <div
                className="col-md-4 col-sm-4 col-xs-6 ng-scope mb-3"
                key={this.props.keyv}
            >
                <div className="quotesBoxesMain HealthQuoteUni text-left">
                    <div className="row">
                        <div className="IconContainer">
                            <small className="hideIcon EyeInfo">
                                {
                                    <i
                                        className="fas fa-eye-slash"
                                        style={{ color: "rgb(183, 180, 180)" }}
                                        onClick={() => this.props.hideQuote(this.props.ID)}
                                    ></i>
                                }
                                <span>Hide Quote</span>
                            </small>
                            <small className="hideIcon IconInfo">
                                <i
                                    className="fa fa-star"
                                    style={{
                                        color:
                                            this.props.WishList !== null &&
                                                this.props.WishList !== undefined &&
                                                this.props.WishList.filter(
                                                    (row) => row === this.props.ID
                                                ).length == 1
                                                ? "#ffc107"
                                                : "rgb(183, 180, 180)",
                                    }}
                                    onClick={(e) =>
                                        this.props.createWishlist(e, this.props.ID)
                                    }
                                ></i>
                                <span id="info">Add to WishList</span>
                            </small>
                        </div>
                        <div className="col-6">

                            <div className="qouteLogo HealthqouteLogo">
                                {this.props.IsLoader ? (
                                    <span class="LoaderImg"></span>
                                ) : (
                                        <img src={this.props.src} alt={this.props.alt} />
                                    )}
                            </div>
                        </div>
                        <div className="col-6">
                            <h4 className="sumInsuredTTL">
                                <strong>
                                    Sum Assured
                  <br />
                                    <b className="ng-binding">{"₹ " + this.props.insured}</b>
                                </strong>
                            </h4>
                        </div>
                        <div className="col-md-12">
                            <span className="title-idv title-idvHealth pl-3">
                                {this.props.PlanCode !== "" && <b className="ng-binding"> {this.props.planName + " " + (!(this.props.CompanyName == "Bajaj" || this.props.CompanyName == 'Apollo' || this.props.CompanyName == 'Hdfc' || this.props.CompanyName == 'Birla' || this.props.CompanyName == 'Religare' || this.props.CompanyName == 'ICICIHealth' || this.props.CompanyName == 'Sompo' || this.props.CompanyName == "NIC" || this.props.PlanCode == null) ? this.props.PlanCode : '')} </b>}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={this.props.ViewOption === "2" && this.state.IsShowFeatures ? "featurDetails mainFeatureInList" : "featurDetails"}>
                                <h3 onClick={() => this.props.ViewOption === "2" && this.setState({ IsShowFeatures: !this.state.IsShowFeatures })} >Main Features <i className={"fas " + (this.state.IsShowFeatures ? "fa-minus" : "fa-plus")}></i></h3>
                                {this.props.IsLoader ? (
                                    <ul>
                                        <li
                                            ng-repeat="Features in EachModel.MainFeatures"
                                            className="ng-scope"
                                        >
                                            <span className="ng-binding">
                                                Hospital Room Eligibility{" "}
                                            </span>
                                            <div className="QuestionPopup">
                                                <i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="QuestionPopupInner">
                                                    <div className="body">
                                                        <p className="ng-binding">
                                                            Room Boarding Nursing Expenses as provided by the
                                                            Hospital Nursing Home up to 2% of the Sum insured
                                                            subject to maximum of Rs.5000/- per day.
                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            ng-repeat="Features in EachModel.MainFeatures"
                                            className="ng-scope"
                                        >
                                            <span className="ng-binding">Restoration of Cover </span>
                                            <div className="QuestionPopup">
                                                <i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="QuestionPopupInner">
                                                    <div className="head ng-binding">
                                                        Restoration of Cover
                          </div>
                                                    <div className="body">
                                                        <p className="ng-binding">
                                                            Not Provide Restoration of Cover
                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            ng-repeat="Features in EachModel.MainFeatures"
                                            className="ng-scope"
                                        >
                                            <span className="ng-binding">Health Checkups </span>
                                            <div className="QuestionPopup">
                                                <i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="QuestionPopupInner">
                                                    <div className="head ng-binding">Health Checkups</div>
                                                    <div className="body">
                                                        <p className="ng-binding">
                                                            No cover for Health Checkups
                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            ng-repeat="Features in EachModel.MainFeatures"
                                            className="ng-scope"
                                        >
                                            <span className="ng-binding">
                                                Pre existing conditions{" "}
                                            </span>
                                            <div className="QuestionPopup">
                                                <i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="QuestionPopupInner">
                                                    <div className="head ng-binding">
                                                        Pre existing conditions
                          </div>
                                                    <div className="body">
                                                        <p className="ng-binding">
                                                            48 Months waiting period for pre existing diseases
                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            ng-repeat="Features in EachModel.MainFeatures"
                                            className="ng-scope"
                                        >
                                            <span className="ng-binding">
                                                Before Hospitalization{" "}
                                            </span>
                                            <div className="QuestionPopup">
                                                <i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                <div className="QuestionPopupInner">
                                                    <div className="head ng-binding">
                                                        Before Hospitalization
                          </div>
                                                    <div className="body">
                                                        <p className="ng-binding">
                                                            Medical expenses incurred upto 30 days prior to
                                                            the date of admission.
                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                        <ul>
                                            {this.props.mainFeatures != null
                                                ? this.props.mainFeatures.map((row, i) => {
                                                    return (
                                                        <li key={i} className="ng-scope">
                                                            <span className="ng-binding">
                                                                {row.Features}{" "}
                                                            </span>
                                                            <div className="QuestionPopup">
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                <div className="QuestionPopupInner">
                                                                    <div className="head ng-binding">
                                                                        {row.Features}
                                                                    </div>
                                                                    <div className="body">
                                                                        <p className="ng-binding">{row.Value}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                                : null}
                                        </ul>
                                    )}{" "}
                            </div>
                        </div>
                    </div>
                    <div className=" ">
                        <div className="QuotesbottomIn">
                            <div className="col-md-3 no-padding">
                                <label className="CompareCheck">
                                    <input
                                        className="checkCompare ng-pristine ng-untouched ng-valid ng-empty"
                                        value={this.props.ID}
                                        type="checkbox"
                                        onClick={this.props.compare}
                                        id={this.props.ID}
                                    />
                                    <span className="checkmarkCom"></span> <b>Compare</b>
                                </label>
                            </div>
                            <div className="col-md-5 no-padding">
                                <button
                                    type="button"
                                    className="btn seeDetails btn-block"
                                    key={this.props.key}
                                    onClick={this.props.detailsClk}
                                >
                                    View Features
                </button>
                            </div>
                            <div className="col-md-4 no-padding">
                                {this.props.IsLoader ? (
                                    <a
                                        className={ChildBtn() + " btn-block"}
                                        onClick={this.props.btnClick}
                                        disabled="disabled"
                                    >
                                        <Loader bgColor="white" />
                                    </a>
                                ) : (
                                        <a className={ChildBtn() + " btn-block"} onClick={this.props.btnClick} disabled="disabled">₹ <span className="buyme ng-binding">{Math.round(this.props.planPrice)}</span><small className="PremiumBuyNow">Buy Now</small></a>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HealthCard;
