import React from "react";
import Services from "./services/Services";
import Features from "./Features";
import OurPartner from "./OurPartner";
import Tab from "./HomeTab/Tab";
import Banner from "./Banner";

const InsureMiddleSection = () => {
  return (
      <React.Fragment>
          <Banner/>
   <Tab/>
      <Services />
      <OurPartner />
    </React.Fragment>
  );
};
export default InsureMiddleSection;
