import React from 'react';
import './Testimonial.css';
import TestimonialData from './TestimonialData';
class TestimonialSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

    }
    componentDidMount() {
        this.scroller()
    }
    scroller() {
        let id = document.getElementById("ScrollDiv");
        let width = window.innerWidth;
        let scrollValue = 200;

        const interval = setInterval(function () {
            if (scrollValue < width) {
                id.scrollTo(400, 0);
                scrollValue += 200;
                console.log("left");
            }

        }, 2000);

        // clearInterval(interval);
    }
    render() {
        return (
            <div className="container">
                <div className="TestimonialInshora" >
                    <h1>Testimonial</h1>
                    <div className="testimonialRow " id="ScrollDiv">

                        {TestimonialData.map((d) => <div className="testimonialCol" key={d.id}>
                            <div className="testimonialCard">
                                <ul class="list-unstyled">
                                    <li class="list-inline-item"><i class="fa fa-star"></i></li>
                                    <li class="list-inline-item"><i class="fa fa-star"></i></li>
                                    <li class="list-inline-item"><i class="fa fa-star"></i></li>
                                    <li class="list-inline-item"><i class="fa fa-star"></i></li>
                                    <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
                                </ul>
                                <h5>{d.name}</h5>
                                <p>{d.data}</p>
                            </div>

                        </div>
                        )}

                    </div>
                </div>
            </div>

        );
    }
}

export default TestimonialSection;