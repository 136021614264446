/* eslint-disable */
import React, { Component } from "react";
import Social from "./Social";
const Bottom = () => {
    return (
        <div id="myID" class="bottomMenu">
            <div class="buttonHolder">
                <div class="Bottomtitle text-center">Save more with Policy Leader. Request a call back.</div>
                <div class="home_dropbox">
                    <div class="input-field text-center">
                        <input type="text" name="registrationNumber" maxlength="14" autocomplete="new-password"
                            id="stickyRegNumber" inputmode="text" placeholder="Enter mobile number" />
                        <button class="getQuoteBtn">Contact Me</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Bottom;
