/* eslint-disable */
import React, { Component } from "react";

import CardQuote from "./CardQuote";
import '../Contents/css/QuoteStyle.css';
import SidePan from "./SidePan";

import GotQuotes from "./GotQuotes";
import ShareButton from "./ShareButton";
import LoaderBoxCar from './LoaderBoxCar'
import Unclickable from "./Unclickable";
import ButtonClass, { ChildBtn } from "../ButtonClass";
import MotorQuoteFooter from "./MotorQuoteFooter";
import PreviousInsurerConfirmationBox from "./PreviousInsurerConfirmationBox";
import axios from "axios";
import { Redirect } from "react-router";
import DataLoader from "./Loader/DataLoader";
import TalkExperts from "../TalkExperts/TalkExperts";
import FeatureBgClass from "../side/FeatureBgClass";
import CopyToClipboard from 'react-copy-to-clipboard';
import ShowSideError from "../components/ShowSideError";
import ProgressBarBox from "../components/ProgressBarBox";
import html2pdf from "html2pdf.js"
export class MotorQuote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Req: {},
            Response: [],
            IsPopupShow: false,
            //Popup
            BasicOD: null,
            BasicTP: null,
            IDV: null,
            NCB: null,
            GST: null,
            TotalPrimium: null,
            IMG: null,
            IsApplyFilterShow: false,
            IsAddons: false,
            IsAdditionalCover: false,
            IsMydiscount: false,
            IsLowestIDV: false,
            //Addons
            chkPaidDriver: false,
            papaiddriverAmt: 0,
            chkDepreciation: false,
            ZeroDepreciationAmt: 0,
            chkReturnToInvoice: false,
            ReturnToInvoiceAmt: 0,

            chkNCBProtection: false,
            NCBProtectionAmt: 0,
            chkInconvenienceAllowance: false,
            InconvenienceAllowanceAmt: 0,
            chkPassengerCover: false,
            PassengerCoverAmt: 0,
            chkEngineProtector: false,
            EngineProtectorAmt: 0,
            chkRoadSideAssistance: false,
            RoadSideAssistanceAmt: 0,
            chkPassangerAssistance: false,
            PassangerAssistanceAmt: 0,
            chkConsumables: false,
            ConsumablesAmt: 0,
            chkKeyReplacement: false,
            KeyReplacementAmt: 0,
            chkTyreSecure: false,
            TyreSecureAmt: 0,
            PassengerCoverSelectedIDV: 0,
            AddinalOption: null,
            IsLoader: true,
            LoaderList: [],
            chkAutoMobileMembership: false,
            AutoMobileMembershipName: "",
            AutoMobileMembershipNumber: "",
            AutoMobileMembershipDate: "",
            VoluntaryAmount: 0,
            IsAntiTheft: false,
            IsCNG_LPG: "N",
            ClaimAmount: 0,
            IDVLpgCng: 0,
            IDVElectrical: 0,
            IDVNonElectrical: 0,
            MinIDV: 0,
            MaxIDV: 0,
            IDVShow: 0,
            idvfilter: "BestDeal",
            FilterIDV: null,
            IsMessage: false,
            MessageBody: null,
            AllCompIDVs: [],
            ThirdPartyCover: false,
            APIUrl: null,
            notForm: false,
            lowestPopup: false,
            discountPopup: false,
            CoverShow: false,
            isToggleOn: false,
            addonsToggle: false,
            gotQuotePOPUP: false,
            fields: {},
            loadCount: 0,
            object: null,
            key: null,
            /* -------------------- update form ----------------------*/
            CarVal: null,
            FuelVal: null,
            VariantVal: null,
            RTOVal: null,
            YearVal: null,
            Vehicle: null,
            variant: null,
            RTOName: null,
            MobileNo: null,
            ManufacturerID: null,
            updatedResult: null,
            CarList: [],
            FuelList: [],
            VariantList: [],
            RTOList: [],
            YearList: [],
            type: null,
            showCar: false,
            showFuel: false,
            showRTO: false,
            showVariant: false,
            ncb: null,
            expdate: null,
            SelectedMotor: null,
            carName: "",
            Model: "",
            RTO: "",
            fuel: "",
            MotorType: null,

            /*-----------------------End--------------------------- */
            /* --------------------Got Quotes Form --------------------*/
            MyPolicyexpired: false,
            PreviousPolicyExpiredDate: null,
            Previouspolicydetails: false,
            TypeOfPolicy: 1,
            PastYearClaimID: false,
            BindPreviousNCB: 0,
            ISPACOVERODSELECTED: null,
            PACOVERYEAR: 0,
            RegistrationDate: null,
            BindPreviousNCB: "20",
            lowestTab: true,
            odOnly: false,
            ManufactureDate: null,
            PrevPolicyType: 1,
            Manu_Month: null,
            Manu_Year: null,
            /* --------------------End --------------------*/
            tppon: true,
            popupKey: null,
            IsPreviousInsu: false,
            Organisation: false,
            Previouspolicydetails: false,
            NotRespond: [],
            redirectToHome: false,
            isLoaded: false,
            CashlessGarages: [],
            vEncryptedPassword: null,
            vRanKey: null,
            vEncryptedLogin: null,
            MainReq: null,
            TalkToExpert: false,
            pointerEvent: "auto",
            disableEvent: null,
            diffDate: null,
            ViewOption: "1",
            CarListMaster: [],
            VariantListMaster: [],
            RTOListMaster: [],
            YearListMaster: [],
            Index: 0,
            FilterResponse: [],
            IsHide: false,
            WishList: [],
            MonthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            IsShowSideError: false,
            IsSuccess: true,
            errorMSG: "",
            prvConf: "NO"
        };
        this.privacyPolicy = this.privacyPolicy.bind(this);
        this.addonsClick = this.addonsClick.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.showLowest = this.showLowest.bind(this)
        this.additionalDiscount = this.additionalDiscount.bind(this)
        this.handle = this.handle.bind(this)
        this.ShowAddOnsOrOther = this.ShowAddOnsOrOther.bind(this)
        this.additionalPopup = this.additionalPopup.bind(this)
        this.addonsopen = this.addonsopen.bind(this)
        this.updateResult = this.updateResult.bind(this)
        this.showPopup = this.showPopup.bind(this)
        this.gotQuotesUpdate = this.gotQuotesUpdate.bind(this)
        this.CallGetMethod = this.CallGetMethod.bind(this)
        this.GetQuote = this.GetQuote.bind(this);
        this.GetQuoteMotor = this.GetQuoteMotor.bind(this)
        this.KotakKeyGen = this.KotakKeyGen.bind(this);
        this.insurerConfirmation = this.insurerConfirmation.bind(this)
        this.checkSharedURL = this.checkSharedURL.bind(this);
        this.getCashlessGarages = this.getCashlessGarages.bind(this);
        this.updateResult = this.updateResult.bind(this);
        //this.state.Req = this.props.location.state.Req;
        this.manageSearch = this.manageSearch.bind(this);
        this.manageClick = this.manageClick.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.policyTypeChecker = this.policyTypeChecker.bind(this);
        this.hideQuote = this.hideQuote.bind(this);
        this.createWishlist = this.createWishlist.bind(this);
        this.KotakKeyGen();


        this.state.APIUrl = window.localStorage.getItem("portal");

        let url = new URLSearchParams(window.location.search);
        if (url.has("policyEnq")) {
            let enq = url.get("policyEnq");
            this.checkSharedURL(enq);


        } else {
            if (this.props.location.state !== undefined)
                this.state.Req = this.props.location.state.Req;
            let QuoteResponse = window.sessionStorage.getItem("QuoteResponse");
            let QuoteReq = window.sessionStorage.getItem("QuoteRequest");
            if (QuoteResponse == null) {
                //this.getCashlessGarages(this.state.Req.QuoteReq.RtoCode);
                this.GetQuote(this.state.Req.QuoteReq)
            } else {
                this.state.Req = JSON.parse(QuoteReq);
                this.state.Response = JSON.parse(QuoteResponse);
                this.state.FilterResponse = JSON.parse(QuoteResponse);
                this.state.IsLoader = false;
                this.state.isLoaded = true;
            }
            if (window.sessionStorage.getItem("AllCompIDV") != null) {
                let idv = JSON.parse(window.sessionStorage.getItem("AllCompIDV"));
                this.state.MaxIDV = idv.MaxIDV;
                this.state.MinIDV = idv.MinIDV;
                this.state.IDVShow = idv.MinIDV;
                this.state.AllCompIDVs = idv.AllCompIDV;
            }
        }


    }
    dobManage(data) {
        if (data !== null && data.includes("-")) {
            let element = data.split("-");
            let dd = element[2];
            let mm = element[1];
            let yy = element[0]

            return dd + "-" + mm + "-" + yy;
        } else return data
    }
    checkSharedURL = (enq) => {
        let url = this.state.APIUrl;
        if (url.match("inshora")) {
            url = "https://inshoracover.com"
        }
        let Model = {
            URL: url + "/api/api/InsurerMotor/GetShareQuotes?EnquiryNo=" + enq
        }

        axios.post("/bpi/WeatherForecast/CallPOST", Model)
            .then((Res) => {
                let data = Res.data;

                let wiL = [];
                if (data.QuotePage !== null && data.quoteResponse !== null) {
                    try {
                        if (data.QuotePage.charAt(0) == "{") {
                            this.state.Req = JSON.parse(data.QuotePage);
                            window.sessionStorage.setItem("QuoteRequest", data.QuotePage);
                        } else {
                            this.state.Response = JSON.parse(data.quoteResponse.substring(1, data.quoteResponse.length - 1));
                            window.sessionStorage.setItem("QuoteResponse", data.quoteResponse.substring(1, data.quoteResponse.length - 1));
                            window.sessionStorage.setItem("QuoteRequest", data.QuotePage.substring(1, data.QuotePage.length - 1));
                            this.state.Req = JSON.parse(data.QuotePage.substring(1, data.QuotePage.length - 1));
                            this.state.FilterResponse = this.state.Response;
                            if (data.GenerateQuote === "null") {
                                this.state.WishList = wiL;

                            } else {
                                window.sessionStorage.setItem("wishlist", data.GenerateQuote.substring(1, data.GenerateQuote.length - 1));
                                this.state.WishList = JSON.parse(data.GenerateQuote.substring(1, data.GenerateQuote.length - 1))
                            }
                        }

                        this.state.IsLoader = false;
                    } catch (err) {
                        this.state.IsLoader = true;
                        alert("some error occured");
                        //window.location.href = "/"
                        //this.GetQuote(this.state.Req.QuoteReq);
                    }
                    this.state.isLoaded = true;
                    //this.GetQuote(this.state.Req.QuoteReq);
                    this.setStateValue();
                    this.setState({ isLoaded: true });
                } else {

                    this.state.redirectToHome = true;
                    return alert("No Data is Found on this Enquiry Number");
                }
            })

    }
    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        this.setState({ ViewOption: window.localStorage.getItem("ViewOption") });
        let wl = window.sessionStorage.getItem("wishlist");
        if (wl !== null) {
            this.setState({ WishList: JSON.parse(wl) });
        }
        if (this.state.isLoaded) {
            this.setState({ Req: this.state.Req });
            for (let i = 0; i < 5; i++) {
                this.state.LoaderList.push(i);

            }
            let motor = this.state.Req.QuoteReq.Type == 0 ? "Two Wheeler" : "Car";
            this.CallGetMethod(
                "https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=" + motor,
                "CarList"
            );
            this.CallGetMethod(
                "https://centralapi.stariglocal.com/api/RTO/GetRtos",
                "RTOList"
            );
            this.CallGetMethod(

                "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" +
                this.state.Req.QuoteReq.VehicleID,
                "FuelList"
            );
            this.CallGetMethod(

                "https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" +
                this.state.Req.QuoteReq.VehicleID +
                "&FuelID=" +
                this.state.Req.QuoteReq.FUELID +
                "",
                "VariantList"
            );
            this.setStateValue()
        }

    }
    setStateValue() {
        let R = this.state.Req.QuoteReq;

        this.state.RegistrationDate = R.RegistrationYear;
        this.state.Manu_Month = R.Manu_Month;
        this.state.Manu_Year = R.Manu_Year;
        this.state.ManufactureDate = R.ManufactureDate
        this.state.Previouspolicydetails = R.IsPrevPolicyDetails;
        this.state.PreviousPolicyExpiredDate = R.PreviousPolicyExpiredDate;
        this.state.TypeOfPolicy = R.TypeOfPolicy;
        this.state.PrevPolicyType = R.PrevPolicyType;
        this.state.BindPreviousNCB = R.BindPreviousNCB;
        this.state.PastYearClaimID = R.PastYearClaimID;
        this.state.ISPACOVERODSELECTED = R.ISPACOVERODSELECTED;
        this.state.PACOVERYEAR = R.PACOVERYEAR;
        this.state.BindPreviousNCB = R.BindPreviousNCB;
        this.state.Organisation = R.Organisation;
        this.state.MyPolicyexpired = R.MyPolicyexpired;
        this.state.Previouspolicydetails = R.IsPrevPolicyDetails
        this.state.ncb = R.BindPreviousNCB;
        this.state.expdate = R.PreviousPolicyExpiredDate;
        this.state.CarVal = this.state.Req.QuoteReq.VehicleID;
        this.state.FuelVal = this.state.Req.QuoteReq.FUELID;
        this.state.VariantVal = this.state.Req.QuoteReq.VariantID;
        this.state.RTOVal = this.state.Req.QuoteReq.RtoID;
        this.state.YearVal = this.state.Req.QuoteReq.YearVal;
        this.state.MobileNo = this.state.Req.MobileNo;
        this.state.chkPaidDriver = this.state.Req.QuoteReq.chkPaidDriver;
        this.state.papaiddriverAmt = this.state.Req.QuoteReq.papaiddriverAmt;
        this.state.chkDepreciation = this.state.Req.QuoteReq.chkDepreciation;
        this.state.ZeroDepreciationAmt = this.state.Req.QuoteReq.ZeroDepreciationAmt;
        this.state.chkReturnToInvoice = this.state.Req.QuoteReq.chkReturnToInvoice;
        this.state.ReturnToInvoiceAmt = this.state.Req.QuoteReq.ReturnToInvoiceAmt;
        this.state.chkNCBProtection = this.state.Req.QuoteReq.chkNCBProtection;
        this.state.NCBProtectionAmt = this.state.Req.QuoteReq.NCBProtectionAmt;
        this.state.chkInconvenienceAllowance = this.state.Req.QuoteReq.chkInconvenienceAllowance;
        this.state.InconvenienceAllowanceAmt = this.state.Req.QuoteReq.InconvenienceAllowanceAmt;
        this.state.chkPassengerCover = this.state.Req.QuoteReq.chkPassengerCover;
        this.state.PassengerCoverAmt = this.state.Req.QuoteReq.PassengerCoverAmt;
        this.state.chkEngineProtector = this.state.Req.QuoteReq.chkEngineProtector;
        this.state.EngineProtectorAmt = this.state.Req.QuoteReq.EngineProtectorAmt;
        this.state.chkRoadSideAssistance = this.state.Req.QuoteReq.chkRoadSideAssistance;
        this.state.RoadSideAssistanceAmt = this.state.Req.QuoteReq.RoadSideAssistanceAmt;
        this.state.chkPassangerAssistance = this.state.Req.QuoteReq.chkPassangerAssistance;
        this.state.PassangerAssistanceAmt = this.state.Req.QuoteReq.PassangerAssistanceAmt;
        this.state.chkConsumables = this.state.Req.QuoteReq.chkConsumables;
        this.state.ConsumablesAmt = this.state.Req.QuoteReq.ConsumablesAmt;
        this.state.chkKeyReplacement = this.state.Req.QuoteReq.chkKeyReplacement;
        this.state.KeyReplacementAmt = this.state.Req.QuoteReq.KeyReplacementAmt;
        this.state.chkTyreSecure = this.state.Req.QuoteReq.chkTyreSecure;
        this.state.carName = this.state.Req.CompModel.Vehicle;
        this.state.variant = this.state.Req.CompModel.variant;
        this.state.RTOName = this.state.Req.CompModel.RTOName;
        this.makeYearList()
    }
    strToIntDateFormat(date) {
        let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (date !== null && date !== undefined && date.includes("-")) {
            let dd = date.split("-")[0];
            let mm = date.split("-")[1];
            let yy = date.split("-")[2]
            let newmm = month.indexOf(mm) < 10 ? "0" + (month.indexOf(mm) + 1) : month.indexOf(mm)
            return yy + "-" + parseInt(newmm) < 1 ? mm : newmm + "-" + dd
        }
        return date

    }
    CallGetMethod(Url, prp) {
        axios.get(Url)
            .then(Res => {
                this.setState({ [prp]: Res.data });
            });
    }
    KotakKeyGen() {
        let vRanKey = Math.floor((Math.random() * 8080808080808080) + 1);
        if (vRanKey.length <= 15)
            vRanKey = Math.floor((Math.random() * 8080808080808080) + 1);
        var key = CryptoJS.enc.Utf8.parse(vRanKey);
        var iv = CryptoJS.enc.Utf8.parse(vRanKey);
        var KotakUserID = window.sessionStorage.getItem("userID");
        var KortakUserPassword = window.sessionStorage.getItem("password");
        var vEncryptedLogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(KotakUserID), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        var vEncryptedPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(KortakUserPassword), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        vEncryptedLogin = btoa(vEncryptedLogin)
        vEncryptedPassword = btoa(vEncryptedPassword);
        this.state.vRanKey = vRanKey;
        this.state.vEncryptedLogin = vEncryptedLogin;
        this.state.vEncryptedPassword = vEncryptedPassword;
        this.setState({ vRanKey, vEncryptedLogin, vEncryptedPassword })

    }
    DateFormat(date, prv) {

        let newDate
        if (date != null && date != undefined && date != "") {
            let s = date.split("-");
            let mm;
            let prvMonth = s[1];
            if (prv !== undefined) {
                prvMonth = s[0];
            }
            switch (parseInt(prvMonth)) {
                case 1: mm = "Jan"
                    break;
                case 2: mm = "Feb"
                    break;
                case 3: mm = "Mar"
                    break;
                case 4: mm = "Apr"
                    break;
                case 5: mm = "May"
                    break;
                case 6: mm = "Jun"
                    break;
                case 7: mm = "Jul"
                    break;
                case 8: mm = "Aug"
                    break;
                case 9: mm = "Sep"
                    break;
                case 10: mm = "Oct"
                    break;
                case 11: mm = "Nov"
                    break;
                case 12: mm = "Dec"
                    break;

            }


            newDate = s[2] + "-" + mm + "-" + s[0];
            if (prv !== undefined) {
                newDate = s[1] + "-" + mm + "-" + s[2];
            }
            return newDate

        } else return date

    }
    GetQuote(Req) {
        let MainReq = Req;

        this.setState({ pointerEvent: "none" });
        // MainReq.RegistrationYear=this.DateFormat(MainReq.RegistrationYear);
        // MainReq.ManufactureDate=this.DateFormat(MainReq.ManufactureDate);
        MainReq.vRanKey = this.state.vRanKey;
        MainReq.vEncryptedLogin = this.state.vEncryptedLogin;
        MainReq.vEncryptedPassword = this.state.vEncryptedPassword;
        window.sessionStorage.setItem("MotorReq", JSON.stringify(MainReq));
        this.GetQuoteMotor(MainReq);
    }
    GetQuoteMotor = (Req) => {
        this.state.IsLoader = true;
        this.state.isLoaded = true;
        let RegDate = this.DateFormat(Req.RegistrationYear);
        let ManDate = this.DateFormat(Req.ManufactureDate);
        let CallReq = JSON.parse(window.sessionStorage.getItem("MotorReq"));
        CallReq.RegistrationYear = RegDate;
        CallReq.ManufactureDate = ManDate;

        if (CallReq.Type >= 2 && !CallReq.MyPolicyexpired) {
            let expDate = CallReq.PreviousPolicyExpiredDate.split("-");
            //CallReq.PreviousPolicyExpiredDate = expDate[1] + "-" + expDate[2] + "-" + expDate[0]
        }
        this.state.MainReq = CallReq;

        let apipath = null;
        if (Req.Type < 2) {
            apipath = this.state.APIUrl + "/api/api/InsurerMotor/GetALLQuotationV2";
        } else {
            apipath = this.state.APIUrl + "/commercial/api/api/InsurerCommercial/GetALLQuotationV2";
        }
        let Model = {
            URL: apipath,
            PostString: JSON.stringify(this.state.MainReq)
        }
        axios.post("/bpi/WeatherForecast/CallCheck", Model)
            .then((data) => {
                data = data.data;

                let lstQuotes = data !== null && data !== "" ? data.filter((row) => row.TotalPremium > 0 && row.PolicyStatus.match("Success")) : [];
                let NotRespond = data !== null && data !== "" ? data.filter((row) => row.TotalPremium < 1 || row.TotalPremium == null) : [];
                this.setState({ Response: lstQuotes, NotRespond, pointerEvent: "auto", FilterResponse: lstQuotes });
                window.sessionStorage.removeItem("MotorReq");
                window.sessionStorage.setItem("MainQuoteReq", JSON.stringify(CallReq))
                window.sessionStorage.setItem("QuoteResponse", JSON.stringify(lstQuotes));
                window.sessionStorage.setItem("QuoteRequest", JSON.stringify(this.state.Req));
                let PaReqRes = {
                    Req: this.state.Req,
                    Res: lstQuotes
                }
                let DepriReqRes = {
                    Req: this.state.Req,
                    Res: lstQuotes
                }
                if (this.state.ISPACOVERODSELECTED == "NO") {
                    window.sessionStorage.setItem("ISPAFalse", JSON.stringify(PaReqRes));
                } else {
                    window.sessionStorage.setItem("ISPATrue", JSON.stringify(PaReqRes));
                }
                if (this.state.chkDepreciation) {
                    window.sessionStorage.setItem("DepTrue", JSON.stringify(DepriReqRes));
                } else {
                    window.sessionStorage.setItem("DepFalse", JSON.stringify(DepriReqRes));
                }
                let QuoteShareData = {
                    shareButton: true,
                    share: false,
                    SharedEmail: "",
                    SaveReqData: true,
                    EnquiryNo: this.state.Req.QuoteReq.EnquiryNo,
                    ShareFor: "Motor_Quote",
                    Quotedata: this.state.Req,
                    quoteRes: window.sessionStorage.getItem("QuoteResponse"),
                };
                new ShareButton(QuoteShareData);

                this.setState({ IsLoader: false });
                this.dateDiff();
                if (lstQuotes.length > 0) {
                    let IDV = []
                    for (let i = 0; i < lstQuotes.length; i++) {
                        let AllCompIDV = {
                            IDV: lstQuotes[i].IDV,
                            CompanyID: lstQuotes[i].CompanyID,
                            MaxIDV: lstQuotes[i].MaxIDV,
                            MinIDV: lstQuotes[i].MinIDV,
                        }
                        //this.state.AllCompIDVs.push(AllCompIDV);
                        IDV.push(AllCompIDV);
                    }

                    var maxIDV = Math.max.apply(Math, IDV.map((row) => row.MaxIDV));
                    var minIDV = Math.min.apply(Math, IDV.filter((d) => d.MinIDV > 0).map((row) => row.MinIDV));
                    minIDV = Math.round(minIDV);
                    maxIDV = Math.round(maxIDV);
                    if (minIDV < 0) {
                        minIDV = 0;
                    }

                    this.state.MaxIDV = maxIDV;
                    this.state.MinIDV = minIDV;

                    this.setState({ IDVShow: this.state.idvfilter == "OwnIDV" ? this.state.FilterIDV : minIDV, MaxIDV: maxIDV, MinIDV: minIDV, AllCompIDV: IDV })
                    let IDVContainer = {
                        MaxIDV: maxIDV,
                        MinIDV: minIDV,
                        AllCompIDV: IDV
                    }
                    /*  let DepriciationPercentage = 0;
                       lstQuotes.forEach(value => {
                           if (value.CompanyID === 126) {
                               DepriciationPercentage = value.discount_perc;
                               window.sessionStorage.setItem("DepriciationPercentage", DepriciationPercentage);
                           }
                       });*/
                    window.sessionStorage.setItem("AllCompIDV", JSON.stringify(IDVContainer));
                }

            });
        let ThirdPartyCover = this.state.Req.QuoteReq.ThirdPartyCover;
        this.setState({ ThirdPartyCover })
        this.setState({ type: this.state.Req.MotorEnquiry.MotorType })

    }
    prevPolicyDateFormatter(date) {

        let newDate
        if (date != null || date != undefined) {
            let s = date.split("-");
            let mm;

            switch (parseInt(s[0])) {
                case 1: mm = "Jan"
                    break;
                case 2: mm = "Feb"
                    break;
                case 3: mm = "March"
                    break;
                case 4: mm = "Apr"
                    break;
                case 5: mm = "May"
                    break;
                case 6: mm = "Jun"
                    break;
                case 7: mm = "Jul"
                    break;
                case 8: mm = "Aug"
                    break;
                case 9: mm = "Sep"
                    break;
                case 10: mm = "Oct"
                    break;
                case 11: mm = "Nov"
                    break;
                case 12: mm = "Dec"
                    break;
            }


            newDate = s[1] + "-" + mm + "-" + s[2];

            return newDate

        } else return date
    }
    showPopup = (e) => {
        this.setState({ key: null })
    };
    popupData(obj) {

        if (!this.state.IsPopupShow) {
            this.setState({
                IsPopupShow: true,
                BasicOD: obj.BasicOD,
                BasicTP: obj.BasicTP,
                IDV: obj.IDV,
                NCB: obj.CurrentNCB,
                GST: obj.serviceTax,
                TotalPrimium: obj.TotalPremium,
                IMG: obj.CompanyLogo,
            });
            // let buyData=this.state.Response.filter((row)=>{
            // return row.CompanyLogo=this.state.IMG}
            // )

        }
        let object = this.state.Response.filter((r) => {
            return r.CompanyLogo == obj.CompanyLogo
        })
        this.setState({ object })

    }
    insurerConfirmation(buyObj) {
        if (this.state.Req.QuoteReq.IsReNew) {
            this.setState({ popupKey: buyObj.CompanyID })
        }
        else {
            this.Buynow(buyObj);
        }

    }
    Buynow(buyObj) {
        let PrvPolicyExDate = this.state.MyPolicyexpired ? this.prevPolicyDateFormatter(this.state.Req.QuoteReq.PreviousPolicyExpiredDate) : this.DateFormat(this.state.Req.QuoteReq.PreviousPolicyExpiredDate);
        this.setState({ prvConf: "Wait..." });
        let man_date = new Date(this.state.ManufactureDate)

        let SaveNdGo = {
            src: buyObj.CompanyLogo,
            UserID: 0,
            TypeOfMotor: buyObj.TypeOfMotor,
            EnquiryNo: buyObj.EnquiryNo,
            TypeOfPolicy: buyObj.TypeOfPolicy,
            BasicOD: buyObj.BasicOD,
            BasicTP: buyObj.BasicTP,
            BasePremium: 0,
            GrossPremium: 0,
            NetPremium: buyObj.NetPremium,
            TotalPremium: buyObj.TotalPremium,
            ServiceTax: buyObj.serviceTax,
            QuoteNo: buyObj.QuoteNo,
            CompanyID: buyObj.CompanyID,
            CompanyName: buyObj.CompanyName,
            PolicyStatus: buyObj.PolicyStatus,
            RequestQuoteXml: buyObj.QuotationRequest,
            ResponseQuoteXml: buyObj.QuotationResponse,
            Period: buyObj.Period,
            AlreadyExpired: buyObj.AlreadyExpired,
            IDV: buyObj.IDV,
            CurrentNCB: buyObj.CurrentNCB,
            PreviousNCB: buyObj.PreviousNCB,
            ManufacturerID: buyObj.ManufactureID,
            VehicleID: buyObj.VehicleID,
            VariantID: buyObj.VariantID,
            FuelID: buyObj.FuelID,
            SeatingCapacity: buyObj.SeatingCapacity,
            CC: buyObj.CC,
            RTO_ID: buyObj.RTO_ID,
            VoluntaryDiscountAmt: buyObj.VoluntaryDiscountAmt,
            //new key
            RegDate: this.DateFormat(this.state.Req.QuoteReq.RegistrationYear),
            PrvPolicyExDate: PrvPolicyExDate,
            vehicleManufactureYear: man_date.getFullYear() + "",
            vehicleManufactureMonth: this.state.MonthList[man_date.getMonth()],
        };

        this.state.Req.SelectedInsurer = SaveNdGo;
        window.sessionStorage.setItem("CompID", buyObj.CompanyID)
        let Model = {
            URL: this.state.APIUrl + "/api/api/NewEnquiry/GotoProposal",
            PostString: JSON.stringify(SaveNdGo)
        }
        fetch("/bpi/WeatherForecast/CallCheck", {
            method: "POST",
            body: JSON.stringify(Model),
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.text())
            .then((data) => {

                this.state.Req.buyObj = buyObj;
                this.setState({ Req: this.state.Req, prvConf: "OK" });

                this.props.history.push("/Proposal", { Model: this.state.Req });
            });
    }
    dateDiff() {
        let flag = true;
        let expDate = this.state.PreviousPolicyExpiredDate;
        let dd = expDate.split("-")[2];
        let mm = expDate.split("-")[1];
        let yy = expDate.split("-")[0];
        let date1 = new Date(mm + "/" + dd + "/" + yy);
        let d = new Date();
        let d1 = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        let d2 = (d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
        let d3 = d.getFullYear();
        let date2 = new Date(d2 + "/" + d1 + "/" + d3);
        if (date1 < date2) {
            flag = false
        }
        let diff = Math.abs(date2 - date1);
        let diffDate = Math.ceil(diff / (1000 * 60 * 60 * 24));
        this.setState({ diffDate: flag ? diffDate : "-" + diffDate });
    }
    ShowAddOnsOrOther(option) {
        this.setState({ AddinalOption: option });
        switch (option) {
            case "ApplyFilter":
                this.setState({
                    IsApplyFilterShow: !this.state.IsApplyFilterShow,
                });
                break;
            case "Addons":
                this.setState({
                    IsAddons: !this.state.IsAddons,
                });
                break;
            case "AdditionalCover":
                this.setState({
                    IsAdditionalCover: !this.state.IsAdditionalCover,
                });
                break;
            case "AdditionalCover":
                this.setState({
                    IsAdditionalCover: !this.state.IsAdditionalCover,
                });
                break;
            case "Mydiscount":
                this.setState({
                    IsMydiscount: !this.state.IsMydiscount,
                });
                break;
            case "LowestIDV":
                ;

                this.setState({
                    IsLowestIDV: !this.state.IsLowestIDV,
                    idvfilter: "BestDeal"
                });
                break;
        }
    }
    handle = (e) => {

        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        if (e.target.type == "checkbox") {

            this.setState({
                [e.target.name]: e.target.checked ? true : false,
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }

        switch (e.target.name) {
            case "ViewOption": window.localStorage.setItem("ViewOption", e.target.value);
                break;
            case "RegistrationDate":
                this.state.RegistrationDate = e.target.value;
                //this.state.Req.QuoteReq.RegistrationYear=e.target.value;
                break;
            case "ManufactureDate":
                this.state.ManufactureDate = e.target.value;

                break;
            case "Manu_Year": this.state.Req.QuoteReq.Manu_Year = e.target.value;
                break;
            case "Manu_Month": this.state.Req.QuoteReq.Manu_Month = e.target.value;
                break;
            case "PreviousPolicyExpiredDate":
                this.state.PreviousPolicyExpiredDate = e.target.value;
                //this.state.Req.QuoteReq.PreviousPolicyExpiredDate=e.target.value;
                break;
            case "MyPolicyexpired":

                if (e.target.checked) {
                    this.state.BindPreviousNCB = 0;
                } else {
                    let date = new Date();
                    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    let mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                    let yy = date.getFullYear();
                    this.state.PreviousPolicyExpiredDate = yy + "-" + mm + "-" + dd;
                    this.setState({ PreviousPolicyExpiredDate: this.state.PreviousPolicyExpiredDate })
                    this.setState({ BindPreviousNCB: this.state.ncb, MyPolicyexpired: false })
                }

                // this.state.Req.QuoteReq.PreviousPolicyExpiredDate=null;
                // this.state.Req.QuoteReq.BindPreviousNCB=0;
                break;

            case "TypeOfPolicy":
                if (e.target.value == 1) {
                    this.state.ThirdPartyCover = false;
                    this.state.odOnly = false;

                    this.setState({ policy: 1 })
                } else if (e.target.value == 2) {

                    this.state.ThirdPartyCover = true;

                    this.setState({ lowestTab: false })

                } else {
                    this.state.ThirdPartyCover = false;
                    this.state.odOnly = true;
                    // this.state.Req.QuoteReq.TypeOfPolicy=3;
                    // this.state.Req.QuoteReq.odOnly=true
                    this.setState({ lowestTab: true })

                }

                break;
            case "BindPreviousNCB":
                this.state.BindPreviousNCB = e.target.value;
                // this.state.Req.QuoteReq.BindPreviousNCB=e.target.value;
                break;
            case "ISPACOVERODSELECTED":

                this.state.ISPACOVERODSELECTED = e.target.value;
                this.state.Req.QuoteReq.ISPACOVERODSELECTED = e.target.value;
                if (e.target.value === "YES") {
                    this.state.PACOVERYEAR = 1;
                    this.state.Req.QuoteReq.PACOVERYEAR = 1;
                }
                else {

                    this.state.Req.QuoteReq.PACOVERYEAR = 0;
                    this.state.PACOVERYEAR = 0;
                }
                let ISPATrueReqRes = window.sessionStorage.getItem("ISPATrue");
                let ISPAFalseReqRes = window.sessionStorage.getItem("ISPAFalse");
                if (e.target.value === "YES") {
                    if (ISPATrueReqRes !== null) {
                        ISPATrueReqRes = JSON.parse(ISPATrueReqRes);
                        if (JSON.stringify(ISPATrueReqRes.Req) == JSON.stringify(this.state.Req)) {
                            this.setState({ Response: ISPATrueReqRes.Res, FilterResponse: ISPATrueReqRes.Res })

                        } else {
                            this.setState({ Req: this.state.Req });
                            this.GetQuote(this.state.Req.QuoteReq);
                        }
                    } else {
                        this.setState({ Req: this.state.Req });
                        this.GetQuote(this.state.Req.QuoteReq);
                    }
                } else if (e.target.value === "NO") {
                    if (ISPAFalseReqRes !== null) {
                        ISPAFalseReqRes = JSON.parse(ISPAFalseReqRes);
                        if (JSON.stringify(ISPAFalseReqRes.Req) == JSON.stringify(this.state.Req)) {
                            this.setState({ Response: ISPAFalseReqRes.Res, FilterResponse: ISPAFalseReqRes.Res })

                        } else {
                            this.setState({ Req: this.state.Req });
                            this.GetQuote(this.state.Req.QuoteReq);
                        }
                    } else {
                        this.setState({ Req: this.state.Req });
                        this.GetQuote(this.state.Req.QuoteReq);
                    }
                }


                break;
            case "PACOVERYEAR":
                if (e.target.value !== "select") {
                    this.state.Req.QuoteReq.PACOVERYEAR = e.target.value;
                    this.setState({ Req: this.state.Req });
                    this.GetQuote(this.state.Req.QuoteReq);
                } else {
                    alert("Select PA cover Year")
                }
                break;
            case "Organisation":
                this.state.Organisation = e.target.value;
                this.state.Req.QuoteReq.Organisation = e.target.value;
                break;
            case "FuelVal":
                this.state.VariantVal = null;
                this.setState({ showVariant: false, VariantVal: null, notForm: this.state.notForm })
                this.CallGetMethod(

                    "https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" +
                    this.state.CarVal +
                    "&FuelID=" +
                    e.target.value +
                    "",
                    "VariantList"
                );



                break;
            //  case "MobileNo" :this.state.MobileNo=this.state.fields["MobileNo"];
            //  break;
            case "Previouspolicydetails":

                this.setState({ MyPolicyexpired: e.target.checked, Previouspolicydetails: !this.state.Previouspolicydetails });
                if (e.target.checked) {

                    this.state.Previouspolicydetails = true;
                    this.state.PreviousPolicyExpiredDate = null;
                    this.state.BindPreviousNCB = 0



                    //this.state.Req.QuoteReq.IsPrevPolicyDetails=true;
                    //this.state.Req.QuoteReq.TypeOfPolicy=0;
                    //this.state.Req.QuoteReq.PreviousPolicyExpiredDate=null;
                    //this.state.Req.QuoteReq.BindPreviousNCB=0
                } else {

                    this.state.Previouspolicydetails = false
                    this.state.BindPreviousNCB = 25
                    let date = new Date();
                    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    let mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                    let yy = date.getFullYear();
                    this.state.PreviousPolicyExpiredDate = yy + "-" + mm + "-" + dd;
                    this.setState({ PreviousPolicyExpiredDate: this.state.PreviousPolicyExpiredDate })
                }

                break;
            case "PastYearClaimID":

                if (e.target.value == "Y") {
                    this.state.BindPreviousNCB = 0
                } else this.state.BindPreviousNCB = 20
                this.setState({ PastYearClaimID: e.target.value });
                break;
            case "IsAntiTheft": this.setState({ IsAntiTheft: e.target.value === "yes" ? true : false });
                break;
            case "chkAutoMobileMembership": this.setState({ chkAutoMobileMembership: e.target.value === "yes" ? true : false });
                break;
        }


        /* this.setState({
             [e.target.name]: e.target.type == "checkbox" ? e.target.checked : e.target.value
         })
 
         if (e.target.type == "checkbox") {
             if (e.target.checked) {
                 switch (e.target.name) {
                     case "chkDepreciation": this.state.chkDepreciation = true; break;
                     case "chkReturnToInvoice": this.state.chkReturnToInvoice = true; break;
                     case "chkNCBProtection": this.state.chkNCBProtection = true; break;
                     case "chkInconvenienceAllowance": this.state.chkInconvenienceAllowance = true; break;
                     case "chkPassengerCover": this.state.chkPassengerCover = true; break;
                     case "PassengerCoverSelectedIDV": this.state.PassengerCoverSelectedIDV = e.target.value; break;
                     case "chkEngineProtector": this.state.chkEngineProtector = true; break;
                     case "chkRoadSideAssistance": this.state.chkRoadSideAssistance = true; break;
                     case "chkPassangerAssistance": this.state.chkPassangerAssistance = true; break;
                     case "chkConsumables": this.state.chkConsumables = true; break;
                     case "chkKeyReplacement": this.state.chkKeyReplacement = true; break;
                     case "chkTyreSecure": this.state.chkTyreSecure = true; break;
                 }
             }
         }*/


    };
    Calculate = (e) => {
        let Req = this.state.Req.QuoteReq;
        if (e.target.name === "addonsCover") {
            if (Req.chkPaidDriver === this.state.chkPaidDriver &&
                Req.chkPassengerCover === this.state.chkPassengerCover &&
                Req.PassengerCoverSelectedIDV === this.state.PassengerCoverSelectedIDV &&
                Req.chkDepreciation === this.state.chkDepreciation &&
                Req.chkReturnToInvoice === this.state.chkReturnToInvoice &&
                Req.chkConsumables === this.state.chkConsumables &&
                Req.chkRoadSideAssistance === this.state.chkRoadSideAssistance &&
                Req.chkNCBProtection === this.state.chkNCBProtection &&
                Req.chkInconvenienceAllowance === this.state.chkInconvenienceAllowance &&
                Req.chkEngineProtector === this.state.chkEngineProtector &&
                Req.chkPassangerAssistance === this.state.chkPassangerAssistance &&
                Req.chkKeyReplacement === this.state.chkKeyReplacement &&
                Req.chkTyreSecure === this.state.chkTyreSecure) {
                this.setState({ errorMSG: "Please make some changes", IsSuccess: false, IsShowSideError: true });
                setTimeout(() => this.setState({ errorMSG: "", IsSuccess: false, IsShowSideError: false }), 2000);
                return;
            }

        }
        else if (e.target.name === "additionalDiscount") {
            if (Req.VoluntaryAmount === this.state.VoluntaryAmount &&
                Req.IsAntiTheft === this.state.IsAntiTheft &&
                Req.AutoMobileMembership === (this.state.chkAutoMobileMembership ? "True" : "False") &&
                Req.AutoMobileMembershipName === this.state.AutoMobileMembershipName &&
                Req.AutoMobileMembershipNumber === this.state.AutoMobileMembershipNumber &&
                Req.AutoMobileMembershipDate === this.state.AutoMobileMembershipDate) {
                this.setState({ errorMSG: "Please make some changes", IsSuccess: false, IsShowSideError: true });
                setTimeout(() => this.setState({ errorMSG: "", IsSuccess: false, IsShowSideError: false }), 2000)
                return;
            }
        }
        else if (e.target.name === "additionalCover") {
            if (Req.IsCNG_LPG === this.state.IsCNG_LPG &&
                Req.IDVLpgCng === this.state.IDVLpgCng &&
                Req.IDVElectrical === this.state.IDVElectrical &&
                Req.IDVNonElectrical === this.state.IDVNonElectrical) {
                this.setState({ errorMSG: "Please make some changes", IsSuccess: false, IsShowSideError: true });
                setTimeout(() => this.setState({ errorMSG: "", IsSuccess: false, IsShowSideError: false }), 2000)
                return;
            }
        }
        else if (e.target.name === "chkDepreciation") {
            let depTrue = window.sessionStorage.getItem("DepTrue");
            let depFalse = window.sessionStorage.getItem("DepFalse");
            Req.chkDepreciation = this.state.chkDepreciation;
            if (this.state.chkDepreciation) {

                if (depTrue !== null) {
                    depTrue = JSON.parse(depTrue);
                    if (JSON.stringify(depTrue.Req) == JSON.stringify(this.state.Req)) {
                        this.setState({ Response: depTrue.Res, FilterResponse: depTrue.Res });
                        return;
                    }
                }
            } else if (depFalse !== null) {
                depFalse = JSON.parse(depFalse);
                if (JSON.stringify(depFalse.Req) == JSON.stringify(this.state.Req)) {
                    this.setState({ Response: depFalse.Res, FilterResponse: depFalse.Res });
                    return;
                }
            }
        }
        if (this.state.IsCNG_LPG == "Y") {
            if (this.state.IDVLpgCng == 0 || this.state.IDVLpgCng == "") {
                return alert("Please Provide LPG/CNG")
            }
        }

        //Addonse
        Req.chkPaidDriver = this.state.chkPaidDriver;
        Req.papaiddriverAmt = this.state.papaiddriverAmt;
        Req.chkDepreciation = this.state.chkDepreciation;
        Req.ZeroDepreciationAmt = this.state.ZeroDepreciationAmt;
        Req.chkReturnToInvoice = this.state.chkReturnToInvoice;
        Req.ReturnToInvoiceAmt = this.state.ReturnToInvoiceAmt;
        Req.chkNCBProtection = this.state.chkNCBProtection;
        Req.NCBProtectionAmt = this.state.NCBProtectionAmt;
        Req.chkInconvenienceAllowance = this.state.chkInconvenienceAllowance;
        Req.InconvenienceAllowanceAmt = this.state.InconvenienceAllowanceAmt;
        Req.chkPassengerCover = this.state.chkPassengerCover;
        Req.PassengerCoverAmt = this.state.PassengerCoverAmt;
        Req.chkEngineProtector = this.state.chkEngineProtector;
        Req.EngineProtectorAmt = this.state.EngineProtectorAmt;
        Req.chkRoadSideAssistance = this.state.chkRoadSideAssistance;
        Req.RoadSideAssistanceAmt = this.state.RoadSideAssistanceAmt;
        Req.chkPassangerAssistance = this.state.chkPassangerAssistance;
        Req.PassangerAssistanceAmt = this.state.PassangerAssistanceAmt;
        Req.chkConsumables = this.state.chkConsumables;
        Req.ConsumablesAmt = this.state.ConsumablesAmt;
        Req.chkKeyReplacement = this.state.chkKeyReplacement;
        Req.KeyReplacementAmt = this.state.KeyReplacementAmt;
        Req.chkTyreSecure = this.state.chkTyreSecure;
        Req.TyreSecureAmt = this.state.TyreSecureAmt;
        Req.PassengerCoverSelectedIDV = this.state.chkPassengerCover
            ? this.state.PassengerCoverSelectedIDV
            : 0;

        //EndAddons
        //Additional Discount
        Req.VoluntaryAmount = this.state.VoluntaryAmount;
        Req.IsAntiTheft = this.state.IsAntiTheft;
        Req.AutoMobileMembership = this.state.chkAutoMobileMembership
            ? "True"
            : "False";
        Req.AutoMobileMembershipName = this.state.chkAutoMobileMembership
            ? this.state.AutoMobileMembershipName
            : "";
        Req.AutoMobileMembershipNumber = this.state.chkAutoMobileMembership
            ? this.state.AutoMobileMembershipNumber
            : "";
        Req.AutoMobileMembershipDate = this.state.chkAutoMobileMembership
            ? this.state.AutoMobileMembershipDate
            : "";
        //Additional Discount
        //Additional Cover
        Req.IsCNG_LPG = this.state.IsCNG_LPG;
        Req.IDVLpgCng = this.state.IsCNG_LPG == "Y" ? this.state.IDVLpgCng : 0;
        Req.IDVElectrical = this.state.IDVElectrical === "" ? 0 : parseInt(this.state.IDVElectrical);
        Req.IDVNonElectrical = this.state.IDVNonElectrical === "" ? 0 : parseInt(this.state.IDVNonElectrical);
        this.setState({
            IDVLpgCng: this.state.IDVLpgCng === "" ? 0 : parseInt(this.state.IDVLpgCng),
            IDVElectrical: this.state.IDVElectrical === "" ? 0 : parseInt(this.state.IDVElectrical),
            IDVNonElectrical: this.state.IDVNonElectrical === "" ? 0 : parseInt(this.state.IDVNonElectrical)
        })
        //End Additional Cover
        if (this.state.idvfilter == "OwnIDV") {
            if (this.state.FilterIDV == null) {
                this.setState({
                    IsMessage: true,
                    MessageBody: "Own IDV is required.",
                });
                return;
            }
            if (this.state.FilterIDV > this.state.MaxIDV || this.state.FilterIDV < this.state.MinIDV) {
                return alert("Please Input correct range of IDV");
            }
        }
        Req.IDV = this.state.idvfilter == "OwnIDV" ? this.state.FilterIDV : 0;
        Req.AllCompIDVs = this.state.idvfilter == "OwnIDV" ? this.state.AllCompIDVs : null;
        //Lowest Possible IDV
        //end
        switch (e.target.name) {
            case "LowestIDV": this.setState({ IsLowestIDV: !this.state.IsLowestIDV, IDVShow: this.state.idvfilter == "OwnIDV" ? this.state.FilterIDV : 0 });

                break;
            case "additionalDiscount": this.setState({ IsMydiscount: !this.state.IsMydiscount })

                break;
            case "additionalCover": this.setState({ IsAdditionalCover: !this.state.IsAdditionalCover })

                break;
            case "IsThirdParty": this.setState({ isToggleOn: !this.state.isToggleOn })

                break;
            case "addonsCover": this.setState({ addonsToggle: !this.state.addonsToggle })

                break;
        }
        this.state.Req.QuoteReq = Req;
        this.setState({ Req: this.state.Req });
        this.GetQuote(Req);
        this.ShowAddOnsOrOther(this.state.AddinalOption);


    };
    CloseMessage = (e) => {
        this.setState({ IsMessage: !this.state.IsMessage });
    };
    openSidepan = (e) => {
        this.setState({ notForm: true });
    }
    closeSidepan = (e) => {

        this.setState({
            carName: this.state.Req.CompModel.Vehicle,
            varName: this.state.Req.CompModel.variant,
            RTOName: this.state.Req.CompModel.RTOName,
            CarVal: this.state.Req.QuoteReq.VehicleID,
            FuelVal: this.state.Req.QuoteReq.FUELID,
            VariantVal: this.state.Req.QuoteReq.VariantID,
            RTOVal: this.state.Req.CompModel.RTOName,
            YearVal: this.state.Req.MotorEnquiry.RegistartionYear,
        });

        this.setState({ notForm: !this.state.notForm });
    };
    showLowest = () => {
        this.setState({ IsLowestIDV: !this.state.IsLowestIDV });

    }
    additionalDiscount() {
        this.setState({ IsMydiscount: !this.state.IsMydiscount });
    }
    additionalPopup() {

        this.setState({ IsAdditionalCover: true });
    }
    privacyPolicy() {
        this.setState({
            isToggleOn: !this.state.isToggleOn
        });

    }
    addonsClick() {
        this.setState({ addonsToggle: true })
    }
    addonsopen() {
        this.setState({ addonsToggle: false })

    }
    openPopup() {
        this.setState({ gotQuotePOPUP: !this.state.gotQuotePOPUP })

    }
    rel() {
        window.location.reload()
    }
    policyTypeChecker(e) {
        if (e.target.value === "Comprehensive" && this.state.Req.QuoteReq.odOnly === false && this.state.Req.QuoteReq.ThirdPartyCover === false) {
            return;
        }
        else if (e.target.value === "ThirdParty" && this.state.Req.QuoteReq.ThirdPartyCover === true)
            return;
        else if (e.target.value === "odOnly" && this.state.Req.QuoteReq.odOnly === true)
            return
        this.setState({ disableEvent: e.target.value === "ThirdParty" ? "disableEvent" : null })
        //e.target.name=="IsThirdParty"?this.state.isToggleOn=!this.state.isToggleOn:null; 
        switch (e.target.value) {
            case "Comprehensive":
                this.setState({ policy: 1 })
                this.setState({ lowestTab: true, isToggleOn: !this.state.isToggleOn })
                this.state.Req.QuoteReq.odOnly = false
                this.state.Req.QuoteReq.ThirdPartyCover = false;


                break;
            case "ThirdParty":
                this.setState({ lowestTab: false })
                this.state.Req.QuoteReq.odOnly = false
                this.state.Req.QuoteReq.ThirdPartyCover = true;

                this.setState({ policy: 2, isToggleOn: !this.state.isToggleOn, ThirdPartyCover: true })
                break;
            case "odOnly":


                this.state.Req.QuoteReq.odOnly = true
                this.state.Req.QuoteReq.ThirdPartyCover = false;
                this.setState({ lowestTab: true })
                this.setState({ policy: 3, isToggleOn: !this.state.isToggleOn })
                break;
            case "tppdRestricted":
                e.target.checked ? this.state.Req.QuoteReq.chkTPPDcover = true : this.state.Req.QuoteReq.chkTPPDcover = false
                this.setState({ tppon: !this.state.tppon })
                break;

        }
        if (e.target.name === "Period") {
            this.state.Req.QuoteReq.Period = e.target.value;
        }
        this.GetQuote(this.state.Req.QuoteReq)

    }
    updateResult() {
        if (this.state.CarVal !== null) {
            this.state.Req.QuoteReq.VehicleID = this.state.CarVal;
            let manufactureData = this.state.CarList.filter((row) => row.VehicleID == this.state.CarVal)[0]
            this.state.Req.QuoteReq.ManufacturerID = manufactureData.ManufacturerID;
            this.state.Req.CompModel.Vehicle = manufactureData.Manu_Vehicle;
        }
        if (this.state.VariantVal !== null) {
            let variant = this.state.VariantList.filter((row) => row.VariantID == this.state.VariantVal)[0];
            this.state.Req.QuoteReq.VariantID = this.state.VariantVal;
            this.state.Req.CompModel.variant = variant.VariantName;
        }
        if (this.state.FuelVal !== null) {
            this.state.Req.QuoteReq.FUELID = this.state.FuelVal;
        }
        if (this.state.RTOVal !== null) {
            let RTOName = this.state.RTOList.filter((row) => row.RTOID == this.state.RTOVal)
            this.state.Req.CompModel.RTOName = RTOName[0].RTOName;
            this.state.Req.QuoteReq.RtoID = this.state.RTOVal;
            this.state.Req.QuoteReq.RtoCode = RTOName[0].RTOCode;
        }

        this.state.Req.QuoteReq.YearVal = this.state.YearVal;
        let date = new Date();

        let month =
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let RegistrationDate = this.state.YearVal + "-" + month + "-01";
        this.state.Req.QuoteReq.RegistrationYear = this.state.Req.QuoteReq.ManufactureDate = this.state.RegistrationDate = this.state.ManufactureDate = RegistrationDate;
        this.state.Req.MobileNo = this.state.MobileNo
        this.setState({ notForm: !this.state.notForm })
        this.GetQuote(this.state.Req.QuoteReq)


    }
    gotQuotesUpdate() {
        if (this.state.Req.QuoteReq.IsReNew) {
            if (parseInt(this.state.Manu_Year) > parseInt(this.state.RegistrationDate.split("-")[0])) {
                return alert("Manufacture Year Error");
            } else if (parseInt(this.state.Manu_Year) == parseInt(this.state.RegistrationDate.split("-")[0])) {
                if (parseInt(this.state.Manu_Month) > parseInt(this.state.RegistrationDate.split("-")[1])) {
                    return alert("Manufacture Moth Error");
                }
            }
            else if (Math.abs(parseInt(this.state.Manu_Year) - parseInt(this.state.RegistrationDate.split("-")[0])) > 3) {
                return alert("Registration date must be within 3 year of Manufacturing date")
            }
        }
        let R = this.state.Req.QuoteReq;
        let expirydate = this.state.PreviousPolicyExpiredDate;


        let fullDate = null;
        if (this.state.MyPolicyexpired) {

            let date = new Date();
            let newDate = new Date(date.setDate(date.getDate() - 91))
            date = newDate
            let month = date.getMonth() + 1 <= 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            fullDate = month + "-" + day + "-" + date.getFullYear();
            this.state.PreviousPolicyExpiredDate = fullDate;
        }
        this.state.ManufactureDate = this.state.Req.QuoteReq.IsReNew ? R.Manu_Year + "-" + R.Manu_Month + "-" + "01" : this.state.ManufactureDate;
        R.ManufactureDate = this.state.Req.QuoteReq.IsReNew ? R.Manu_Year + "-" + R.Manu_Month + "-" + "01" : this.state.ManufactureDate;
        R.RegistrationYear = this.state.RegistrationDate;
        R.MyPolicyexpired = this.state.MyPolicyexpired;
        R.ExpMoreThan90Days = this.state.MyPolicyexpired;
        this.state.YearVal = R.YearVal = this.state.Req.MotorEnquiry.RegistartionYear = this.state.RegistrationDate.slice(0, 4);
        R.IsPrevPolicyDetails = this.state.Previouspolicydetails;
        R.PreviousPolicyExpiredDate = this.state.PreviousPolicyExpiredDate;
        R.TypeOfPolicy = this.state.TypeOfPolicy;
        R.PrevPolicyType = this.state.PrevPolicyType;
        R.BindPreviousNCB = this.state.BindPreviousNCB;
        R.PACOVERYEAR = this.state.ISPACOVERODSELECTED ? this.state.PACOVERYEAR : 0;
        R.PastYearClaimID = this.state.PastYearClaimID;
        R.ISPACOVERODSELECTED = this.state.ISPACOVERODSELECTED;
        R.BindPreviousNCB = this.state.BindPreviousNCB;
        R.Organisation = this.state.Organisation;
        //this.state.Previouspolicydetails=R.IsPrevPolicyDetails
        this.GetQuote(this.state.Req.QuoteReq)
        this.setState({ gotQuotePOPUP: false });
    }

    mobileView(e) {
        let id;
        switch (e.target.id) {
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "block";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "block";
                break;
        }
    }
    hideFilter(e) {
        let id;
        switch (e.target.id) {
            case "hidefilter_1": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "hidefilter_2": id = document.getElementById("filter_2");
                id.style.display = "none";
                break;
            case "editDetails": id = document.getElementById("filter_1");
                id.style.display = "none";
                break;
            case "applyFilter": id = document.getElementById("filter_2");
                id.style.display = "none";
                break;
        }
    }

    manageSearch(e) {

        this.state.CarListMaster = this.state.CarList;
        this.state.VariantListMaster = this.state.VariantList;
        this.state.RTOListMaster = this.state.RTOList;
        this.state.YearListMaster = this.state.YearList;
        this.setState({ IsShowList: !this.state.IsShowList });
        switch (e.target.id) {
            case "carname":
                this.setState({ showCar: !this.state.showCar });
                break;
            case "variantname":
                this.setState({ showCar: false, showVariant: !this.state.showVariant });
                break;
            case "rtoname":
                this.setState({ showVariant: false, showRTO: !this.state.showRTO });
                this.state.YearListMaster = this.state.YearList;
                break;
            case "year":
                this.setState({ showRTO: false, showYear: !this.state.showYear, });

                break;
        }
    }

    searchFilter = (e) => {

        let currenttxtID = e.target.id;
        let filterList = [];

        this.setState({ Index: 0 });
        switch (currenttxtID) {
            case "searchtext":

                filterList = this.state.CarList.filter((row) =>
                    row.Manu_Vehicle.toLowerCase().includes(
                        document.getElementById("searchtext").value.toLowerCase()
                    )
                );

                this.setState({ CarListMaster: filterList, FuelVal: null });
                break;

            case "searchvariant":
                filterList = this.state.VariantList.filter((row) =>
                    row.VariantName.toLowerCase().includes(
                        document.getElementById("searchvariant").value.toLowerCase()
                    )
                );
                this.setState({ VariantListMaster: filterList });
                break;
            case "searchrto":
                filterList = this.state.RTOList.filter((row) =>
                    row.RTOName.toLowerCase().includes(
                        document.getElementById("searchrto").value.toLowerCase()
                    )
                );
                this.setState({ RTOListMaster: filterList });
                break;
            case "searchyear":
                filterList = this.state.YearList.filter((row) =>
                    row
                        .toString()
                        .includes(document.getElementById("searchyear").value.toLowerCase())
                );
                this.setState({ YearListMaster: filterList });
                break;
            case "FuelVal":
                let apipath;
                if (this.state.MotorType > 1)
                    apipath =
                        this.state.APIUrl +
                        "/commercial/api/api/Vehicle/GetVariantsByVehicleAndFuelAndCompany?VehicleID=" +
                        this.state.CarVal +
                        "&FuelID=" +
                        e.target.value;
                else
                    apipath =
                        "https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" +
                        this.state.CarVal +
                        "&FuelID=" +
                        e.target.value;
                this.CallGetMethod(
                    apipath,

                    "VariantList"
                );
                // this.tabChange("tabProfile", "tabModel", "profile", "Model");
                this.setState({ FuelVal: e.target.value });

                if (e.target.value != 0) {
                    this.setState({ fuil: e.target.value.split("~")[1] });

                    break;
                }
        }
    };

    searchdown = (e) => {

        let currenttxtID = e.target.id;
        let UlID = "";
        switch (currenttxtID) {
            case "searchtext":
                UlID = "searchul";
                break;
            case "searchvariant":
                UlID = "searchulvariant";
                break;
            case "searchrto":
                UlID = "searchulrto";
                break;
            case "searchyear":
                UlID = "searchulyear";
                break;
        }

        try {
            let selectList = document.getElementById(UlID);
            let Options = selectList.getElementsByTagName("li");

            switch (e.keyCode) {
                case 40:

                    Options[this.state.Index].className = "activeopt";
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    this.state.Index += 1;
                    this.setState({ Index: this.state.Index });
                    break;
                case 38:
                    if (this.state.Index > 0) this.state.Index -= 1;
                    Options[this.state.Index].className = "activeopt";
                    Options[this.state.Index + 1].removeAttribute("class");
                    this.setState({ Index: this.state.Index });
                    break;
                case 13:
                    this.setState({ Index: 0, IsShowList: false });
                    let sectedOption = document.getElementsByClassName("activeopt");

                    let filterList = [];
                    let Text = "";
                    switch (currenttxtID) {
                        case "searchtext":
                            if (this.state.MotorType < 2)
                                filterList = this.state.CarList.filter(
                                    (row) => row.VehicleID == sectedOption[0].value
                                );
                            else
                                filterList = this.state.CarList.filter(
                                    (row) => row.CombindID == sectedOption[0].id
                                );

                            if (filterList.length > 0) {
                                this.state.SelectedMotor = filterList[0];
                                Text = this.state.SelectedMotor.Manu_Vehicle;
                                let apipath;
                                this.state.FuelVal = null;
                                if (this.state.MotorType < 2) {
                                    apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" + sectedOption[0].value;
                                    this.setState({ CarVal: sectedOption[0].value, FuelVal: "0" });
                                } else {
                                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetFuelByVehicleIDAndCompany?id=" + sectedOption[0].id;
                                    this.setState({ CarVal: sectedOption[0].id, FuelVal: "0" });
                                }

                                //**********Binding Fuel******************
                                this.CallGetMethod(apipath, "FuelList");

                                //**********End Binding Fuel******************
                                this.setState({ SelectedMotor: this.state.SelectedMotor });
                                // this.tabChange("tabHome", "tabProfile", "home", "profile");

                                this.setState({ showCar: false, carName: Text });
                            }
                            break;

                        case "searchvariant":
                            if (this.state.MotorType < 2)
                                filterList = this.state.VariantList.filter((row) => row.VariantID == sectedOption[0].value);
                            else
                                filterList = this.state.VariantList.filter((row) => row.CombindID == sectedOption[0].id);
                            if (filterList.length > 0) {
                                this.state.SelectedVariant = filterList[0];
                                Text = this.state.SelectedVariant.VariantName;

                                this.setState({ SelectedVariant: this.state.SelectedVariant });
                                // this.tabChange("tabModel", "tabRto", "Model", "Rto");
                                //**********************Bind RTO List******************
                                this.CallGetMethod(
                                    "https://centralapi.stariglocal.com/api/RTO/GetRtos",
                                    "RTOList"
                                );
                                this.setState({
                                    VariantVal:
                                        this.state.MotorType < 2
                                            ? sectedOption[0].value
                                            : sectedOption[0].id,
                                    variant: Text,
                                    showVariant: false,
                                });
                                //**********************END Bind RTO List******************
                            }
                            break;
                        case "searchrto":
                            filterList = this.state.RTOList.filter((row) => row.RTOID == sectedOption[0].value);
                            if (filterList.length > 0) {
                                this.state.SelectedRTO = filterList[0];
                                Text = this.state.SelectedRTO.RTOName;
                                this.setState({ SelectedRTO: this.state.SelectedRTO });
                                if (this.state.IsReNew)
                                    this.makeYearList();

                                //****************END*********************
                                this.setState({ RTOVal: sectedOption[0].value, RTOName: Text, showRTO: false });
                            }
                            break;
                        case "searchyear":
                            filterList = this.state.YearListMaster.filter(
                                (row) => row == sectedOption[0].value
                            );
                            if (filterList.length > 0) {
                                this.state.SelectedYear = filterList[0];
                                Text = this.state.SelectedYear;
                                this.setState({ SelectedYear: this.state.SelectedYear });
                                this.state.YearVal = sectedOption[0].value;
                                this.setState({
                                    YearVal: sectedOption[0].value,
                                    BindPreviousNCB: NcbManage(value),
                                    showYear: false
                                });
                            }
                            break;
                    }
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    document.getElementById(currenttxtID).value = Text;
                    break;
            }
        } catch (ex) { }
    };
    makeYearList() {
        let date = new Date();
        let Currentyear = date.getFullYear();
        let lstYear = [];
        for (let i = Currentyear; i > Currentyear - 16; i--) {
            lstYear.push(i);
        }
        this.setState({ YearList: lstYear });
        this.state.YearList = lstYear;
    }


    manageClick(value, state) {
        this.setState({ [state]: value });
        this.makeYearList();
        switch (state) {
            case "CarVal":
                let apipath;
                if (this.state.MotorType < 2) {
                    apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" + value;
                } else {
                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetFuelByVehicleIDAndCompany?id=" + value;
                }
                this.CallGetMethod(apipath, "FuelList");
                // this.tabChange("tabHome", "tabProfile", "home", "profile");
                let carName = this.state.CarList.filter((row) => row.VehicleID == value)[0];
                this.state.FuelVal = null;
                if (this.state.MotorType < 2) {
                    this.setState({
                        carName: carName.Manu_Vehicle,
                        FuelVal: "0",
                        showCar: false,
                    });
                } else
                    this.setState({
                        carName: value.split("~")[1],
                        FuelVal: "0",
                        showCar: false,
                    });
                this.setState({ FuelVal: null, VariantVal: null, RTOVal: null });
                break;
            case "RTOVal":
                let RTO = this.state.RTOList.filter((row) => row.RTOID == value)[0];
                this.setState({ RTO: RTO.RTOName, RTOName: RTO.RTOName, showRTO: false });
                break;
            case "VariantVal":
                this.CallGetMethod("https://centralapi.stariglocal.com/api/RTO/GetRtos", "RTOList");
                let Model = this.state.VariantList.filter((row) => row.VariantID == value)[0];

                this.setState({
                    Model: this.state.MotorType < 2 ? Model.VariantName : value.split("~")[1], showVariant: false,
                });
                break;
            case "YearVal":
                this.state.YearVal = value;
                this.setState({
                    YearVal: this.state.YearVal,
                    showYear: false,
                });
                break;
        }
    }

    getCashlessGarages(rto) {
        var stateid = rto.split('-')[0];
        var url = this.state.APIUrl + "/api/api/RTO/GetCashlessGarages?stateid=" + stateid + "";
        let Model = {
            URL: url,
        }
        axios.post("/bpi/WeatherForecast/CallGet", Model).
            then((Response) => {

                if (Response.data !== null && Response.data !== "" && Response.data !== undefined) {
                    // this.setState({CashlessGarages: Response.data});
                    this.state.Req.push(Response.data);

                }

            })
    }
    hideQuote(id) {

        if (id === "Clear") {
            this.setState({ FilterResponse: this.state.Response, IsHide: false });
            window.sessionStorage.setItem("QuoteResponse", JSON.stringify(this.state.Response));
            return;
        }
        let filter = this.state.FilterResponse.filter(row => row.CompanyID !== id);
        this.setState({ FilterResponse: filter, IsHide: true });
        window.sessionStorage.setItem("QuoteResponse", JSON.stringify(filter));
    }
    async createWishlist(e, id) {
        if (e.target.style.color == "rgb(183, 180, 180)") {
            e.target.style.color = "#ffc107";
            await this.setState({ WishList: [...this.state.WishList, id] });
        }
        else {
            e.target.style.color = "rgb(183, 180, 180)";
            await this.setState({ WishList: this.state.WishList.filter(row => row !== id) });
        }
        window.sessionStorage.setItem("wishlist", JSON.stringify(this.state.WishList));
    }
    async generatePDF() {


        let element = document.getElementById('CaptureProposal');

        let opt = {
            margin: 0,
            filename: this.state.Model.ProposalReq.CompanyName + '_Summary.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 8, letterRendering: true, useCORS: true, dpi: 192 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'l', putOnlyUsedFonts: true },
            enableLinks: true,
            //pagebreak: { mode: 'avoid-all', before: '#part2break' }
        };
        try {
            await html2pdf().set(opt).from(element).save();
        } catch (err) { }


    }
    render() {
        if (!this.state.isLoaded) {
            return <DataLoader />
        }
        if (this.state.redirectToHome)
            return <Redirect to="/" />
        return (

            <div className="QuotePage" style={{ pointerEvents: this.state.IsLoader ? "none" : "auto" }}>
                <ShowSideError errorMSG={this.state.errorMSG} position="right" Isleft={false} IsSuccess={this.state.IsSuccess} show={this.state.IsShowSideError} />

                <div className="packagesMainCar">

                    <div className="productTabsMainCar">
                        { /*<a
                            className="applyFilter"
                            onClick={(e) => this.ShowAddOnsOrOther("ApplyFilter")}
                        >
                            <i className="fa fa-filter"></i>
                       </a>*/}
                        {this.state.IsApplyFilterShow ? (
                            <div className="quotesBoxesHead" id="applyFilterShow">
                                <div className="divOF50">
                                    <p>
                                        Lowest Possible IDV:{" "}
                                        <span onClick={(e) => this.ShowAddOnsOrOther("LowestIDV")}>
                                            Edit
                                        </span>
                                    </p>
                                    <p>
                                        Additional Discount:
                                        <span onClick={(e) => this.ShowAddOnsOrOther("Mydiscount")}>
                                            Edit
                                        </span>
                                    </p>
                                </div>
                                <div className="divOF50">
                                    <p>
                                        Additional Cover:{" "}
                                        <span onClick={(e) => this.ShowAddOnsOrOther("AdditionalCover")} >
                                            Edit
                                        </span>
                                    </p>
                                    <p>
                                        Addons Cover:{" "}
                                        <span onClick={(e) => this.ShowAddOnsOrOther("Addons")}>
                                            Edit
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ) : null}

                        <section>
                            {(!this.state.APIUrl.match("hero")) && this.state.Response.length > 0 &&
                                <ShareButton SaveReqData={false} wishList={JSON.stringify(this.state.WishList)} ShareFor="Motor_Quote" SharedEmail={this.state.Req.QuoteReq.MobileNo} share={true} shareButton={false} Quotedata={window.sessionStorage.getItem("QuoteRequest")} quoteRes={window.sessionStorage.getItem("QuoteResponse")} EnquiryNo={this.state.Req.QuoteReq.EnquiryNo} />
                            }
                            <ProgressBarBox Progress="40%" />
                            <div className="quotesDetails test-heading2">
                               
                                <div>
                                    <ul className="mo_flt_main" id="mo_flt_ul">
                                        <li id="opn_one"><span className="btn_filter" data-target="#filter_1" data-toggle="tab" aria-expanded="true" id="editDetails" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-edit" aria-hidden="true"></i> Edit Details</span></li>
                                        <li id="opn_two"><span className="btn_filter" data-target="#filter_2" data-toggle="tab" aria-expanded="true" id="applyFilter" onBlur={() => this.hideFilter} onClick={this.mobileView}><i className="fa fa-filter" aria-hidden="true"></i> Apply Filters</span></li>
                                    </ul>
                                </div>


                                <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetailsRow003Grid" : "row quotesDetailsRow"} id="filter_1">
                                    <button type="button" className="close btn-success" id="hidefilter_1" onClick={this.hideFilter}>×</button>
                                    <div className="col-md-5 NoPadding">
                                        <div className="quotesDetailsIn">

                                            <p className="quoteP">Your Vehicle</p>
                                            <h3 id="manufacturevehiclevariant">{this.state.Req.CompModel.Vehicle},</h3>
                                            <span>{" " + this.state.Req.CompModel.variant}, {this.state.Req.CompModel.RTOName}</span>
                                            <span data-target="#myModalEdit" onClick={this.openSidepan} className="EditVehicleDet">
                                                Not your  {this.state.Req.QuoteReq.VehicleType == 1 ? "Car" : this.state.Req.QuoteReq.VehicleType == 2 ? "PCV" : this.state.Req.QuoteReq.VehicleType == 3 ? "GCV" : "Bike"}?
                                                </span>

                                            {this.state.notForm ?
                                                <SidePan
                                                    handle={this.handle}
                                                    modelValue={this.state.CarVal}
                                                    type={this.state.Req.MotorEnquiry.MotorType}
                                                    model={this.state.Req.CompModel.Vehicle}
                                                    fuelID={this.state.FuelVal}
                                                    fuelName={this.state.Req.QuoteReq.FuelName}
                                                    varientId={this.state.VariantVal}
                                                    varient={this.state.Req.CompModel.variant}
                                                    rtovalue={this.state.RTOVal}
                                                    RTO={this.state.RTOName}
                                                    yearValue={this.state.YearVal}
                                                    year={this.state.Req.MotorEnquiry.RegistartionYear}
                                                    mobile={this.state.MobileNo}
                                                    CarList={this.state.CarList}
                                                    FuelList={this.state.FuelList}
                                                    VariantList={this.state.VariantList}
                                                    RTOList={this.state.RTOList}
                                                    YearList={this.state.Req.QuoteReq.YearList}
                                                    IsReNew={this.state.Req.QuoteReq.IsReNew}
                                                    close={this.closeSidepan}
                                                    updateResult={this.updateResult}
                                                    manageSearch={this.manageSearch}
                                                    searchFilter={this.searchFilter}
                                                    searchDown={this.searchdown}
                                                    manageClick={this.manageClick}
                                                    carName={this.state.carName}
                                                    RTOname={this.state.RTOName}
                                                    showCar={this.state.showCar}
                                                    showFuel={this.state.showFuel}
                                                    showVariant={this.state.showVariant}
                                                    showRTO={this.state.showRTO}
                                                    MotorType={this.state.MotorType}
                                                    CarListMaster={this.state.CarListMaster}
                                                    VariantListMaster={this.state.VariantListMaster}
                                                    RTOListMaster={this.state.RTOListMaster}
                                                    YearListMaster={this.state.YearListMaster}
                                                    varName={this.state.variant}
                                                    showYear={this.state.showYear}
                                                /> : null}
                                            <span>Registered Date: <strong onClick={this.openPopup}>{this.dobManage(this.state.Req.QuoteReq.RegistrationYear)} <i className="fa fa-chevron-down" aria-hidden="true"></i></strong></span>

                                        </div>
                                    </div>
                                    <div className="col-md-3 NoPadding pl-5 pl-0Res">
                                        <div className="quotesDetailsIn">
                                            <p className="quoteP">Previous Policy</p>
                                            <span>Previous NCB:
                                                    {this.state.Req.QuoteReq.odOnly == true || this.state.Req.QuoteReq.ThirdPartyCover == true ||
                                                    this.state.Previouspolicydetails == true || this.state.MyPolicyexpired == true ? "N/A" :
                                                    <strong onClick={this.openPopup}>{" " + this.state.Req.QuoteReq.BindPreviousNCB + "% "}
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i></strong>}</span>
                                            {this.state.Req.QuoteReq.IsReNew &&
                                                <span>Expiry: <strong onClick={this.openPopup}>{this.state.Previouspolicydetails ? "N/A" : this.dobManage(this.state.Req.QuoteReq.PreviousPolicyExpiredDate)} <i className="fa fa-chevron-down" aria-hidden="true"></i></strong></span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4 NoPadding pl-5 pl-0Res">
                                        <div className="quotesDetailsIn">
                                            <p className="quoteP">Additional and Claim Features</p>
                                            <span className="mr-5">Additional: <strong className="qoutePopup" onClick={this.additionalDiscount}>Discount <i className="fa fa-chevron-down" aria-hidden="true" ></i></strong></span>
                                            {this.state.IsMydiscount ? (
                                                <div id="myDiscount" className="Manualmodal modal">
                                                    <div className="modal-dialog newModalqt">
                                                        <div className="modal-content">
                                                            <div className="modal-header text-center">
                                                                <button
                                                                    type="button"
                                                                    className="close btn-success"
                                                                    onClick={(e) => this.setState({ IsMydiscount: false })}
                                                                >
                                                                    ×
                                                                    </button>
                                                                <h3>Get Additional Discounts.</h3>
                                                                <h2>
                                                                    Some insurance companies offer discounts based on these
                                                                    criteria..
                                                                    </h2>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="newModalqtPopUp">
                                                                    <div className="row">
                                                                        <div className="col-md-9">
                                                                            <label className="LableFont">
                                                                                Choose a voluntary deductible IDV
                                                                                </label>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <select
                                                                                className="packageForm"
                                                                                name="VoluntaryAmount"
                                                                                value={this.state.VoluntaryAmount}
                                                                                onChange={this.handle}
                                                                            >
                                                                                <option selected="selected" value="0">-- Select --   </option>
                                                                                {this.state.Req.QuoteReq.Type == 1 ? <React.Fragment>
                                                                                    <option value="2500">2,500</option>
                                                                                    <option value="5000">5,000</option>
                                                                                    <option value="7500">7,500</option>
                                                                                    <option value="15000">1,5000</option>
                                                                                </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        <option value="500">500</option>
                                                                                        <option value="750">750</option>
                                                                                        <option value="1000">1,000</option>
                                                                                        <option value="1500">1,500</option>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-9">
                                                                            <label className="LableFont">
                                                                                Is your vehicle fitted with ARAI approved anti-theft
                                                                                device?
                                                                                </label>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <select name="IsAntiTheft" className="packageForm" value={this.state.IsAntiTheft ? "yes" : "no"} onChange={this.handle}>
                                                                                <option value="yes">Yes</option>
                                                                                <option value="no" selected>No</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-9">
                                                                            <label className="LableFont">
                                                                                Are you a member of Automobile Association of India?
                                                                                </label>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <select name="chkAutoMobileMembership" value={this.state.chkAutoMobileMembership ? "yes" : "no"} className="packageForm" onChange={this.handle}>
                                                                                <option value="yes">Yes</option>
                                                                                <option value="no" selected>No</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                    {this.state.chkAutoMobileMembership ? (
                                                                        <div>
                                                                            <div className="row ">
                                                                                <div className="col-md-6">
                                                                                    <label className="LableFont">
                                                                                        Name of Association
                                                                                        </label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input
                                                                                        className="packageForm"
                                                                                        placeholder="Name of Association"
                                                                                        type="text"
                                                                                        name="AutoMobileMembershipName"
                                                                                        value={this.state.AutoMobileMembershipName}
                                                                                        onChange={this.handle}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-6">
                                                                                    <label className="LableFont">
                                                                                        Please Enter AAI NO:
                                                                                        </label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input
                                                                                        maxLength="10"
                                                                                        className="packageForm"
                                                                                        min="0"
                                                                                        placeholder="AAI NO"
                                                                                        type="tel"
                                                                                        name="AutoMobileMembershipNumber"
                                                                                        value={this.state.AutoMobileMembershipNumber}
                                                                                        onChange={this.handle}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-6">
                                                                                    <label className="LableFont">AAI DATE:</label>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <span className="Zebra_DatePicker_Icon_Wrapper">
                                                                                        <input
                                                                                            className="packageForm"
                                                                                            type="date"
                                                                                            name="AutoMobileMembershipDate"
                                                                                            value={this.state.AutoMobileMembershipDate}
                                                                                            onChange={this.handle}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    <div className="modal-footer text-center">
                                                                        <label>
                                                                            <button
                                                                                type="submit"
                                                                                name="additionalDiscount"
                                                                                className={ButtonClass()}
                                                                                onClick={this.Calculate}
                                                                            >
                                                                                Apply Discount
                                                                                </button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}


                                            {this.state.Req.QuoteReq.IsReNew &&
                                                <div className="w-auto d-inline">
                                                    {this.state.Req.QuoteReq.Type != "0" &&
                                                        <span className="mr-5" onClick={this.additionalPopup}>Additional: <strong className="qoutePopup">Cover <i className="fa fa-chevron-down" aria-hidden="true"></i></strong></span>
                                                    }

                                                </div>
                                            }
                                            <span>Claim:
                                                            {this.state.Req.QuoteReq.odOnly == true || this.state.Req.QuoteReq.
                                                    ThirdPartyCover == true || this.state.Previouspolicydetails == true ||
                                                    this.state.MyPolicyexpired == true ? " N/A " : <strong onClick={this.openPopup}>
                                                        {this.state.Req.QuoteReq.PastYearClaimID === "N" ? " No " : " Yes "}
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i></strong>}
                                            </span>
                                            {this.state.Req.QuoteReq.Type == 0 &&
                                                <React.Fragment>
                                                    <span>Policy Term: </span> <select className="TermPolicy selectpicker" name="Period" defaultValue={this.state.Req.QuoteReq.Period} onChange={this.policyTypeChecker}>
                                                        <option value="1">1 Year </option>
                                                        <option value="2">2 Year </option>
                                                        <option value="3">3 Year </option>
                                                    </select> <i className="fa fa-chevron-down TermPolicyArrow" aria-hidden="true"></i>
                                                </React.Fragment>
                                            }

                                            {this.state.IsAdditionalCover ? (
                                                <div className="Manualmodal modal">
                                                    <div className="modal-dialog newModalqt">
                                                        <div className="modal-content">
                                                            <div className="modal-header text-center">
                                                                <button
                                                                    type="button"
                                                                    className="close btn-success"
                                                                    onClick={(e) => this.setState({ IsAdditionalCover: false })}
                                                                >
                                                                    ×
                                                                    </button>
                                                                <h3>Get Additional Covers</h3>
                                                                <h2>
                                                                    Some insurance companies offer additional cover based on
                                                                    these criteria..
                                                                    </h2>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="newModalqtPopUp">
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <label className="LableFont">CNG/LPG Kit</label>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <input
                                                                                checked={this.state.IsCNG_LPG == "N" ? true : false}
                                                                                name="IsCNG_LPG"
                                                                                type="radio"
                                                                                value="N"
                                                                                onClick={this.handle}

                                                                            />{" "}
                                                                            <span className="mr-1">No</span>
                                                                            <input
                                                                                name="IsCNG_LPG"
                                                                                type="radio"
                                                                                value="Y"
                                                                                checked={this.state.IsCNG_LPG == "Y" ? true : false}
                                                                                onClick={this.handle}
                                                                            />{" "}
                                                                            <span>Yes</span>

                                                                        </div>
                                                                        {this.state.IsCNG_LPG == "Y" ? (

                                                                            <React.Fragment>
                                                                                <div className="col-md-8">
                                                                                    <label className="LableFont">Externally fitted CNG/LPG kit </label>
                                                                                </div><div className="col-md-4"> <div id="IDVLpgCngDIV">
                                                                                    <input
                                                                                        className="packageForm"
                                                                                        type="tel"
                                                                                        name="IDVLpgCng"
                                                                                        value={this.state.IDVLpgCng}
                                                                                        onChange={this.handle}
                                                                                        onBlur={this.handle}
                                                                                    />
                                                                                </div></div></React.Fragment>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <label className="LableFont">
                                                                                Electrical Accessories
                                                                                </label>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <input
                                                                                className="packageForm"
                                                                                type="tel"
                                                                                name="IDVElectrical"


                                                                                value={this.state.IDVElectrical}
                                                                                onChange={this.handle}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <label className="LableFont">
                                                                                Non Electrical Accessories
                                                                                </label>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <input
                                                                                className="packageForm"
                                                                                type="tel"
                                                                                name="IDVNonElectrical"
                                                                                placeholder="0"
                                                                                value={this.state.IDVNonElectrical}
                                                                                onChange={this.handle}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <label>
                                                                            <button
                                                                                type="button"
                                                                                className={ButtonClass()}
                                                                                onClick={this.Calculate}
                                                                                name="additionalCover"
                                                                            >
                                                                                Apply Covers
                                                                                </button>
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                    </div>
                                </div>
                                <div className={this.state.ViewOption === "2" ? "row quotesDetailsRow quotesDetails002Row quotesDetails002RowGrid" : "row quotesDetailsRow quotesDetails002Row "} id="filter_2" onClick={() => this.state.isToggleOn && this.setState({ isToggleOn: false })} >
                                    <button type="button" className="close btn-success" id="hidefilter_2" onClick={this.hideFilter}>×</button>
                                    <div className="col-md-2 NoPadding">
                                        <div className="quotesDetailsIn">

                                            <p className="quoteP">Policy Type </p>
                                            <div onClick={this.privacyPolicy}  >
                                                <span><strong>{this.state.Req.QuoteReq.ThirdPartyCover ? "Third Party Cover" : this.state.Req.QuoteReq.odOnly ? "OD Cover" : "Comprehensive"} <i className="fa fa-chevron-down" aria-hidden="true"></i> </strong></span>
                                            </div>
                                            {this.state.isToggleOn ?
                                                <div id="TPfirst2" className="TPfirstCom" >

                                                    <div className="CheckBoxColor">
                                                        <button className="policyTypeChecker" value="Comprehensive" onClick={this.policyTypeChecker}>
                                                            <input type="radio" name="IsThirdParty" onClick={this.policyTypeChecker} checked={this.state.Req.QuoteReq.odOnly == false && this.state.Req.QuoteReq.ThirdPartyCover == false} className="Compare ng-pristine ng-untouched ng-valid ng-not-empty" value="Comprehensive" />
                                                                Comprehensive <br /> {!this.state.Req.QuoteReq.IsReNew && <small>1 year OD+ {this.state.Req.QuoteReq.Type === 0 ? "5" : "3"} year TP</small>}
                                                        </button>
                                                    </div>
                                                    {this.state.Req.QuoteReq.IsReNew && <React.Fragment><div className="CheckBoxColor">
                                                        <button className="policyTypeChecker" value="ThirdParty" onClick={this.policyTypeChecker}>
                                                            <input type="radio" checked={this.state.Req.QuoteReq.ThirdPartyCover} name="IsThirdParty" className="Compare ng-pristine ng-untouched ng-valid ng-not-empty"
                                                                value="ThirdParty" onClick={this.policyTypeChecker} />
                                                                Third Party Cover Only
                                                            </button>
                                                    </div>
                                                        <div className="CheckBoxColor">
                                                            <button className="policyTypeChecker" value="odOnly" onClick={this.policyTypeChecker}>
                                                                <input type="radio" onClick={this.policyTypeChecker} checked={this.state.Req.QuoteReq.odOnly == true} name="IsThirdParty" className="Compare ng-pristine ng-untouched ng-valid ng-not-empty" value="odOnly" />
                                                                    OD Cover Only
                                                                </button>
                                                        </div></React.Fragment>}
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    {!this.state.Req.QuoteReq.ThirdPartyCover &&
                                        <div className="col-md-3 NoPadding pl-4 pl-0Res">
                                            <div className="quotesDetailsIn">

                                                <div>
                                                    <div className="quoteP">Cover Value IDV
                                                            {!this.state.Req.QuoteReq.ThirdPartyCover &&
                                                            <div className="QuestionPopupPage">
                                                                <span>What's This?</span>
                                                                <div className="QuestionPopupInnerPage">
                                                                    <div className="body">
                                                                        The maximum value you will receive in case of total damage or
                                                                        theft.
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <span onClick={this.showLowest}>
                                                        <strong>Lowest IDV <span>(From {this.state.IDVShow}) </span> <i className="fa fa-chevron-down" aria-hidden="true"></i></strong>
                                                    </span>
                                                </div>

                                                {this.state.IsLowestIDV ? (
                                                    <div className="addONSdrop2 IdvPopUp text-left">
                                                        <div className="text-center IdvPopUpHead">
                                                            <button type="button" className="close btn-success" onClick={(e) => this.setState({ IsLowestIDV: false })}>
                                                                ×
                                                                        </button>
                                                            <h3>We have got the Quotes on best deal!</h3>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="newModalqtPopUp">
                                                                <div className="row" id="rwNCB">
                                                                    <div className="col-md-12">
                                                                        <div className="col-md-12">
                                                                            <div className="chosIDV">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="idvfilter"
                                                                                    value="BestDeal"
                                                                                    checked={
                                                                                        this.state.idvfilter == "BestDeal"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={this.handle}
                                                                                />
                                                                                <label className="LableFont">For best deal</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="chosIDV">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="idvfilter"
                                                                                    value="OwnIDV"
                                                                                    checked={
                                                                                        this.state.idvfilter == "OwnIDV"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={this.handle}
                                                                                />
                                                                                <label className="LableFont">
                                                                                    Set your own IDV
                                                                                            </label>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.idvfilter == "OwnIDV" ? (
                                                                            <div className="col-md-12 idvFontSz" id="divCostom">
                                                                                <input
                                                                                    type="tel"
                                                                                    className="packageForm"
                                                                                    placeholder="Enter your IDV"

                                                                                    name="FilterIDV"
                                                                                    value={this.state.FilterIDV}
                                                                                    onChange={this.handle}
                                                                                />
                                                                                <p>
                                                                                    Please enter an IDV between
                                                                                                <label id="lblMinIDV" className="idvRange">
                                                                                        {this.state.MinIDV}
                                                                                    </label>
                                                                                                -
                                                                                                <label id="lblMaxIDV" className="idvRange">
                                                                                        {this.state.MaxIDV}
                                                                                    </label>
                                                                                </p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <div className="col-md-12">
                                                                            <input
                                                                                type="button"
                                                                                value="Update Result"
                                                                                name="LowestIDV"
                                                                                className={ChildBtn()}
                                                                                onClick={this.Calculate}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <p className="chosIDVCnt idvFontSz">
                                                                                <span>What is IDV ?</span>
                                                                                <br />
                                                                                            IDV is Maximum Value you receive in case of full
                                                                                            damage or theft of your car
                                                                                        </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ) : null}



                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-7 NoPadding">
                                        <div className="d-flex flex-wrap">
                                            <div className="col-md-4 NoPadding pl-4 pl-0Res">
                                                <div className="quotesDetailsIn">
                                                    {!this.state.Organisation &&
                                                        <div>
                                                            <div className="quoteP">
                                                                PA cover OD
                                                                    <div className="QuestionPopupPage">
                                                                    <span>What's This?</span>
                                                                    <div className="QuestionPopupInnerPage QuestionPopupInnerPagePA_Cover_OD">
                                                                        <div className="body">
                                                                            <b>Personal Accident (PA) Cover</b>
                                                                            <p>As per Insurance Regulatory and Development Authority of India notice. Personal Accident (PA) Cover is mandatory, if the car is owned by an individual not having Personal Accident cover of 15 Lakhs, please opt for 'Personal Acccident (PA) cover'</p>
                                                                            <div><b>You can opt out if...</b>
                                                                                <ul>
                                                                                    <li>The car is registered in a company's name</li>
                                                                                    <li>You already have a PA cover of 15 lakhs. (from any other vehicle owned by you or from a separate standalone PA Cover Policy)</li>
                                                                                </ul>
                                                                            </div>
                                                                            <b>What is PA Cover?</b>
                                                                            <p>This policy covers the owner for death or disability due to an accident. Owner (in case of disability) or nominee (in case of death) will get 15 lakhs.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label className="paCoverOd">
                                                                <input
                                                                    className="packageFormCheck pl-2"
                                                                    name="ISPACOVERODSELECTED"
                                                                    type="radio"
                                                                    value="NO"
                                                                    onChange={this.handle}
                                                                    checked={this.state.ISPACOVERODSELECTED == "NO"}
                                                                />

                                                                <span  > No</span>
                                                            </label>
                                                            <label className="paCoverOd">
                                                                <input
                                                                    className="packageFormCheck pl-2"
                                                                    name="ISPACOVERODSELECTED"
                                                                    type="radio"
                                                                    value="YES"
                                                                    onChange={this.handle}
                                                                    checked={this.state.ISPACOVERODSELECTED == "YES"}
                                                                />
                                                                <span > Yes</span>

                                                            </label>



                                                            {this.state.ISPACOVERODSELECTED == "YES" ? (
                                                                <label className="paCoverOd paCoverOnChange">
                                                                    <span>
                                                                        {this.state.Req.QuoteReq.IsReNew ? this.state.PACOVERYEAR + " Year" : <select
                                                                            className="packageForm"
                                                                            name="PACOVERYEAR"
                                                                            defaultValue={this.state.PACOVERYEAR}
                                                                            onChange={this.handle}
                                                                        >
                                                                            <option value="select">Select PA Cover Year</option>
                                                                            <option value="1">1 Year</option>
                                                                            {!this.state.Req.QuoteReq.IsReNew && this.state.Req.QuoteReq.Type == 1 && <option value="3">3 Year</option>}
                                                                            {!this.state.Req.QuoteReq.IsReNew && this.state.Req.QuoteReq.Type == 0 && <option value="5">5 Year</option>}
                                                                        </select>}
                                                                    </span></label>
                                                            ) :

                                                                null}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-5 NoPadding pl-4 pl-0Res">
                                                <div className="quotesDetailsIn">
                                                    <div className={"ZeroDeppBox " + this.state.disableEvent}>
                                                        <input id="chkDepreciation" name="chkDepreciation" type="checkbox"
                                                            checked={this.state.chkDepreciation}
                                                            onChange={(e) => {
                                                                this.state[e.target.name] = e.target.checked;
                                                                this.setState({ [e.target.name]: e.target.checked });
                                                                this.Calculate(e);
                                                            }} value="true" />
                                                        <span className="ml-2"><strong>Zero Dep</strong>

                                                        </span>

                                                    </div>
                                                    <div className="QuestionPopupPage">
                                                        <span>What's This?</span>
                                                        <div className="QuestionPopupInnerPage">
                                                            <div className="body">
                                                                <strong>Zero Depreciation</strong> or <strong>Nil Depreciation</strong> or <strong>Bumper to Bumper</strong> cover means if your {this.state.Req.QuoteReq.Type == 1 ? "Car" : "2 Wheeler"} gets damaged following a collision, you will receive the entire cost of the {this.state.Req.QuoteReq.Type == 1 ? "Car" : "2 Wheeler"} parts from the insurer excluding consumables such as Lubricants, Retainers, Brackets, Gaskets, Accessories and Wear &amp; Tear parts

                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div className="ToggleSwitch">
                                                        <span><strong>TPPD Restricted to 6000</strong></span>
                                                        {this.state.tppon ? <label className="switch" >
                                                            <input type="checkbox" id="ChkTPPDRest" value="tppdRestricted" onChange={this.policyTypeChecker} />
                                                            <span className="sliderToggle round"></span>
                                                        </label> : <label className="switch" >
                                                                <input type="checkbox" id="ChkTPPDRest" value="tppdRestricted" onChange={this.policyTypeChecker} checked />
                                                                <span className={FeatureBgClass() + " sliderToggle round"}></span>
                                                            </label>}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3 NoPadding pl-4 pl-0Res">
                                                <div className={"quotesDetailsIn "}>
                                                    <p className="quoteP">Addons</p>
                                                    <div onClick={this.addonsClick}>
                                                        <span><strong>Select Addons <i className="fa fa-chevron-down" aria-hidden="true"></i> </strong></span>
                                                    </div>
                                                    {this.state.addonsToggle ?
                                                        <div className="addONSdrop2  text-left">
                                                            <button type="button" className="close btn-success" id="closeBtn" onClick={this.addonsopen}>×</button>
                                                            <div id="first2">

                                                                <div className="CheckBoxColor">
                                                                    <div className="QuestionPopupPage QuestionPopupAddons">
                                                                        <label>
                                                                            <input data-val="true" id="chkPaidDriver" name="chkPaidDriver" type="checkbox"
                                                                                checked={this.state.chkPaidDriver}
                                                                                onChange={this.handle} value="true" />
                                                                                Paid Driver
                                                                            </label>
                                                                    </div>
                                                                </div>
                                                                <div className="CheckBoxColor">
                                                                    <label id="lbl-PC">
                                                                        <input data-val="true" id="chkPassengerCover" name="chkPassengerCover" type="checkbox"
                                                                            checked={this.state.chkPassengerCover}
                                                                            onChange={this.handle} value="true" /><input name="chkPassengerCover" type="hidden" value="false" autocomplete="off" />
                                                                            Passenger Cover
                                                                        </label>
                                                                </div>
                                                                <div className="CheckBoxColor PC_D" >
                                                                    {
                                                                        this.state.chkPassengerCover ?
                                                                            <div className="CheckBoxColor PC_D">
                                                                                <select name="PassengerCoverSelectedIDV" className="packageForm"
                                                                                    value={this.state.PassengerCoverSelectedIDV}
                                                                                    onChange={this.handle}
                                                                                >
                                                                                    <option value="0">0</option>
                                                                                    <option value="10000">10000</option>
                                                                                    <option value="50000">50000</option>
                                                                                    <option value="100000">100000</option>
                                                                                    <option value="200000">200000</option>
                                                                                </select>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                {!this.state.Req.QuoteReq.ThirdPartyCover && <React.Fragment>
                                                                    <div className="CheckBoxColor" style={{ display: "none" }}>
                                                                        <div className="QuestionPopupPage QuestionPopupAddons">
                                                                            <label>
                                                                                <input id="chkDepreciation" name="chkDepreciation" type="checkbox"
                                                                                    checked={this.state.chkDepreciation}
                                                                                    onChange={this.handle} value="true" />
                                                                                Zero Depreciation
                                                                            </label>
                                                                            <div className="QuestionPopupInnerPage">
                                                                                <div className="body">
                                                                                    <strong>Zero Depreciation</strong> or <strong>Nil Depreciation</strong> or <strong>Bumper to Bumper</strong> cover means if your {this.state.Req.QuoteReq.Type == 1 ? "Car" : "2 Wheeler"} gets damaged following a collision, you will receive the entire cost of the {this.state.Req.QuoteReq.Type == 1 ? "Car" : "2 Wheeler"} parts from the insurer excluding consumables such as Lubricants, Retainers, Brackets, Gaskets, Accessories and Wear &amp; Tear parts
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="CheckBoxColor">
                                                                        <div className="QuestionPopupPage QuestionPopupAddons">
                                                                            <label>
                                                                                <input data-val="true" id="chkReturnToInvoice" name="chkReturnToInvoice" type="checkbox"
                                                                                    checked={this.state.chkReturnToInvoice}
                                                                                    onChange={this.handle} value="true" />
                                                                                <input name="chkReturnToInvoice" type="hidden" value="false" autocomplete="off" />
                                                                                Invoice Cover
                                                                            </label>
                                                                            <div className="QuestionPopupInnerPage">
                                                                                <div className="body">
                                                                                    In case of theft or total damage to your  {this.state.Req.QuoteReq.Type == 1 ? "Car" : "2 Wheeler"}, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="CheckBoxColor">
                                                                        <div className="QuestionPopupPage QuestionPopupAddons">
                                                                            <label>
                                                                                <input data-val="true" id="chkRoadSideAssistance" name="chkRoadSideAssistance" type="checkbox"
                                                                                    checked={this.state.chkRoadSideAssistance}
                                                                                    onChange={this.handle} value="true" /><input name="chkRoadSideAssistance" type="hidden" value="false" autocomplete="off" />
                                                                                Roadside Assistance
                                                                            </label>
                                                                            <div className="QuestionPopupInnerPage">
                                                                                <div className="body">
                                                                                    Road side assistance provides support for basic on-road breakdown situations like tyre change, battery jump start, emergency fuel, medical assistance etc which are not covered under Insurance. As the price is very nominal, it is a good to have add-on.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="CheckBoxColor">
                                                                        <div className="QuestionPopupPage QuestionPopupAddons">
                                                                            <label>
                                                                                <input data-val="true" id="chkConsumables" name="chkConsumables" type="checkbox"
                                                                                    checked={this.state.chkConsumables}
                                                                                    onChange={this.handle} value="true" /><input name="chkConsumables" type="hidden" value="false" autocomplete="off" />
                                                                                Consumables Cover
                                                                            </label>
                                                                            <div className="QuestionPopupInnerPage">
                                                                                <div className="body">
                                                                                    Covers expenses incurred towards "Consumables" (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.Req.QuoteReq.Type == 1 &&
                                                                        <React.Fragment>
                                                                            <div className="CheckBoxColor">
                                                                                <div className="QuestionPopupPage QuestionPopupAddons">
                                                                                    <label>
                                                                                        <input data-val="true" id="chkNCBProtection" name="chkNCBProtection" type="checkbox"
                                                                                            checked={this.state.chkNCBProtection}
                                                                                            onChange={this.handle} value="true" /><input name="chkNCBProtection" type="hidden" value="false" autocomplete="off" />
                                                                                        NCB Protection
                                                                                    </label>
                                                                                    <div className="QuestionPopupInnerPage">
                                                                                        <div className="body">
                                                                                            No Claim Bonus or NCB is the discount you get on your premium for every consecutive claim free year. This can go up to 50% (or even higher) for 5 or more claim free years. This can also get down to zero with even a single claim, so if you have a good NCB then opt for NCB protection to preserve your NCB even after you make a claim.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="CheckBoxColor">
                                                                                <label>
                                                                                    <input data-val="true" id="chkInconvenienceAllowance" name="chkInconvenienceAllowance" type="checkbox"
                                                                                        checked={this.state.chkInconvenienceAllowance}
                                                                                        onChange={this.handle} value="true" /><input name="chkInconvenienceAllowance" type="hidden" value="false" autocomplete="off" />
                                                                                    Inconvenience Allowance
                                                                                </label>

                                                                            </div>

                                                                            <div className="CheckBoxColor">
                                                                                <div className="QuestionPopupPage QuestionPopupAddons">
                                                                                    <label>
                                                                                        <input data-val="true" id="chkEngineProtector" name="chkEngineProtector" type="checkbox"
                                                                                            checked={this.state.chkEngineProtector}
                                                                                            onChange={this.handle} value="true" /><input name="chkEngineProtector" type="hidden" value="false" autocomplete="off" />
                                                                                        Engine Protector Cover
                                                                                    </label>
                                                                                    <div className="QuestionPopupInnerPage">
                                                                                        <div className="body">
                                                                                            When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly &amp; is placed at low ground clearance.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="CheckBoxColor">
                                                                                <label>
                                                                                    <input data-val="true" id="chkPassangerAssistance" name="chkPassangerAssistance" type="checkbox"
                                                                                        checked={this.state.chkPassangerAssistance}
                                                                                        onChange={this.handle} value="true" /><input name="chkPassangerAssistance" type="hidden" value="false" autocomplete="off" />
                                                                                    Passenger Assistance
                                                                                </label>

                                                                            </div>
                                                                            <div className="CheckBoxColor">
                                                                                <div className="QuestionPopupPage QuestionPopupAddons">
                                                                                    <label>
                                                                                        <input data-val="true" id="chkKeyReplacement" name="chkKeyReplacement" type="checkbox"
                                                                                            checked={this.state.chkKeyReplacement}
                                                                                            onChange={this.handle} value="true" /><input name="chkKeyReplacement" type="hidden" value="false" autocomplete="off" />
                                                                                        Key and lock Replacement
                                                                                    </label>
                                                                                    <div className="QuestionPopupInnerPage">
                                                                                        <div className="body">
                                                                                            In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="CheckBoxColor">
                                                                                <label>
                                                                                    <input data-val="true" id="chkTyreSecure" name="chkTyreSecure" type="checkbox"
                                                                                        checked={this.state.chkTyreSecure}
                                                                                        onChange={this.handle} value="true" /><input name="chkTyreSecure" type="hidden" value="false" autocomplete="off" />
                                                                                    Tyre Secure
                                                                                </label>

                                                                            </div>
                                                                        </React.Fragment>
                                                                    }
                                                                </React.Fragment>}
                                                                <button onClick={this.Calculate} className={ButtonClass() + " text-center"} name="addonsCover">Re-Calculate</button>
                                                            </div>

                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {this.state.IsLoader
                            ? <div className={this.state.ViewOption === "2" ? "quotesDetails quotesDetailsGrid" : "quotesDetails"}> <div className={this.state.ViewOption === "2" ? "row quotesDetailsGridRow" : "row"}>{LoaderBoxCar.map((row, i) => (
                                <CardQuote IsLoader={this.state.IsLoader} src={row.src} idv={row.IDV} key={i} />
                            ))}</div></div>
                            : <div className={this.state.ViewOption === "2" ? "quotesDetails quotesDetailsGrid" : "quotesDetails"} onClick={() => this.state.isToggleOn && this.setState({ isToggleOn: false })}>
                                <div className="HeadOverApp">
                                    <div className="ExclusiveGSTRow">
                                        <h3>{this.state.FilterResponse.length} Plans Found</h3>
                                        <div className="row">
                                            <div className="col-6">
                                                <p>Prices Exclusive of GST</p>
                                            </div>
                                            <div className="PTC_BoxMarTop">
                                                {!this.state.MyPolicyexpired &&
                                                    <div className="PolicyTypeCondition">
                                                        <div className="PTC_Box d-flex">
                                                            <i class="far fa-bell"></i>
                                                            <p> {this.state.diffDate === 0 ? "Your Policy will expire today" : this.state.diffDate < 0 ? "Your Policy has Expired" : `Your Policy Expired in next ${Math.abs(this.state.diffDate)} days`}</p>
                                                        </div>
                                                        <div className="PTC_Box">
                                                            <button className="btn PTC_Btn" onClick={this.openPopup}>
                                                                Modify
                                                        </button>
                                                        </div>

                                                    </div>
                                                }
                                                {this.state.IsHide && <small className="ClearQuote" onClick={() => this.hideQuote("Clear")}><i className="far fa-times-circle"></i><br />Clear Selection</small>}
                                            </div>
                                            <div className="col-6 text-right">

                                                <div className="ncbBox EnuquryNumber">
                                                    <select name="ViewOption" onChange={this.handle} value={this.state.ViewOption} className="form-control ViewQuotes w-auto">
                                                        <option value="1">Grid View</option>
                                                        <option value="2">List View</option>
                                                    </select>
                                                    <p className="LeadIdHover">Lead ID: <span className="ng-binding">{this.state.Req.QuoteReq.EnquiryNo}<CopyToClipboard text={this.state.Req.QuoteReq.EnquiryNo} onCopy={() => { this.setState({ IsShowSideError: true, IsSuccess: true, errorMSG: "Success" }); setTimeout(() => this.setState({ IsShowSideError: false, errorMSG: "" }), 2000) }}><i className="fas fa-copy"></i></CopyToClipboard></span></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.ViewOption === "2" ? "row quotesDetailsGridRow" : "row"}>

                                    {this.state.FilterResponse.map((row, i) => (

                                        <React.Fragment key={i}>

                                            <CardQuote key={i} src={row.CompanyLogo} paOwnerDriver={row.PAOwnerCoverDriver} IsLoader={this.state.IsLoader} idv={row.IDV} quote={row.NetPremium}
                                                btnClick={(e) => this.insurerConfirmation(row)} detailsClk={(e) => this.setState({ key: row.CompanyID })} currentNCB={row.CurrentNCB}
                                                PAPaidDriver={row.PAPaidDriver} ZeroDepreciationAmt={row.ZeroDepreciationAmt}
                                                ReturnToInvoiceAmt={row.ReturnToInvoiceAmt} NCBProtectionAmt={row.NCBProtectionAmt}
                                                InconvenienceAllowanceAmt={row.InconvenienceAllowanceAmt} ThirdPartyCover={row.ThirdPartyCover}
                                                PassengerCoverAmt={row.PassengerCoverAmt} EngineProtectorAmt={row.EngineProtectorAmt}
                                                RoadSideAssistanceAmt={row.RoadSideAssistanceAmt} PassangerAssistanceAmt={row.PassangerAssistanceAmt}
                                                ConsumablesAmt={row.ConsumablesAmt} KeyReplacementAmt={row.KeyReplacementAmt}
                                                TyreSecureAmt={row.TyreSecureAmt}
                                                chkPaidDriver={row.chkPaidDriver} chkDepreciation={row.chkDepreciation}
                                                chkReturnToInvoice={row.chkReturnToInvoice} chkNCBProtection={row.chkNCBProtection}
                                                chkInconvenienceAllowance={row.chkInconvenienceAllowance} SecureplusAmt={row.SecureplusAmt} SecurePremiumAmt={row.SecurePremiumAmt}
                                                chkPassengerCover={row.chkPassengerCover} chkEngineProtector={row.chkEngineProtector}
                                                chkRoadSideAssistance={row.chkRoadSideAssistance} chkPassangerAssistance={row.chkPassangerAssistance}
                                                chkConsumables={row.chkConsumables} chkKeyReplacement={row.chkKeyReplacement} WishList={this.state.WishList}
                                                ISPACOVERODSELECTED={row.ISPACOVERODSELECTED == "YES" ? true : false} hideQuote={this.hideQuote} createWishlist={this.createWishlist}
                                                chkTyreSecure={row.chkTyreSecure} PersonalBelongingsAmt={row.PersonalBelongingsAmt} companyId={row.CompanyID} Type={row.TypeOfMotor}
                                            />

                                            {this.state.key === row.CompanyID ? <div className="Manualmodal modal" id="PlaneDetails" role="dialog">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-body premiumBrekpBody">
                                                            <h3>Premium Breakup</h3>
                                                            <button type="button" className="close" data-dismiss="modal" onClick={this.showPopup}>×</button>

                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="premiumBrekp01">
                                                                        <img src={row.CompanyLogo} />
                                                                        <div className="premiumBrekp01IN">
                                                                            <p>
                                                                                IDV :
                                                                                <span>
                                                                                    <b>₹ </b>
                                                                                    <b id="netPremium">{Math.round(row.IDV)}</b>
                                                                                </span>
                                                                            </p>
                                                                            <p>
                                                                                Current NCB : <span>{row.CurrentNCB}%</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="premiumBrekp02IN">
                                                                            <p ><button className={ChildBtn()} onClick={(e) => this.Buynow(row)}>Buy Me</button></p>
                                                                            <a href={row.PolicyWording} className="policyWordingBtn">
                                                                                <i className="fas fa-file-pdf"></i> Policy Wording
                                                                            </a>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="premiumBrekp02">
                                                                        <h5>Basic Covers</h5>
                                                                        <p>
                                                                            <span>Basic OD Premium :</span>
                                                                            <span className="spanRight">₹ {Math.round(row.BasicOD)}</span>
                                                                        </p>
                                                                        <p>
                                                                            <span>Basic TP Premium :</span>
                                                                            <span className="spanRight">₹ {Math.round(row.BasicTP)}</span>
                                                                        </p>
                                                                        {row.chkPaidDriver &&  <p>
                                                                            <span>Paid Driver :</span>
                                                                            <span className="spanRight"> {row.PAPaidDriver > 0 ? "₹ " + Math.round(row.PAPaidDriver) : "NA"}</span>
                                                                        </p>}
                                                                        {row.ISPACOVERODSELECTED == "YES" && <p>
                                                                            <span>PA Owner Driver: :</span>
                                                                            <span className="spanRight"> {row.PAOwnerCoverDriver > 0 ? "₹ " + Math.round(row.PAOwnerCoverDriver) : "NA"}</span>
                                                                        </p>}

                                                                        <div className="texArea">
                                                                            <h5>ADDONS COVER</h5>
                                                                            {row.IDVElectrical > 0 && <p>
                                                                                <span>Electrical Accessories Premium :</span>
                                                                                <span>{"₹ " + Math.round(row.ElectricalAccessories)}</span>
                                                                            </p>}
                                                                            {row.IDVNonElectrical > 0 && <p>
                                                                                <span>Non-Electrical Accessories Premium :</span>
                                                                                <span>{"₹ " + Math.round(row.NonElectricalAccessories)}</span>
                                                                            </p>}
                                                                            {(row.IsCNG_LPG == "Y" || row.CNGLPGODAmt > 0 || row.CNGLPGTPAmt > 0) && <React.Fragment>
                                                                                <p>
                                                                                    <span>CNG/LPG IMT-25 Premium :</span>
                                                                                    <span>{"₹ " + Math.round(row.CNGLPGODAmt)}</span>
                                                                                </p>
                                                                                <p>
                                                                                    <span>CNG/LPG TP Premium :</span>
                                                                                    <span>{"₹ " + Math.round(row.CNGLPGTPAmt)}</span>
                                                                                </p>
                                                                            </React.Fragment>}
                                                                            {(row.chkDepreciation || ((row.chkEngineProtector || row.chkConsumables || row.chkKeyReplacement) && row.CompanyName === 'Reliance') ) && !row.ThirdPartyCover && <p>
                                                                                <span>Zero Dept :</span>
                                                                                <span className="spanRight">{(row.SecureplusAmt > 0 || row.SecurePremiumAmt > 0) && row.CompanyName === 'Reliance'?'Included' :row.ZeroDepreciationAmt<=0?'NA':'₹' + Math.round(row.ZeroDepreciationAmt)}</span>
                                                                                {row.CompanyName === 'Reliance' && <ul className="AddonsInList">
                                                                                    {(row.SecureplusAmt > 0 || row.SecurePremiumAmt > 0) && row.CompanyName === 'Reliance' && <li>
                                                                                        <span>Engine Protector</span>
                                                                                        <span className="spanRight">
                                                                                            <img src={require("../Contents/img/RightCheck.png")} />
                                                                                        </span>
                                                                                    </li>}
                                                                                    {(row.SecureplusAmt > 0 || row.SecurePremiumAmt > 0) && row.CompanyName === 'Reliance' && <li>
                                                                                        <span>Consumable</span>
                                                                                        <span className="spanRight">
                                                                                            <img src={require("../Contents/img/RightCheck.png")} />
                                                                                        </span>
                                                                                    </li>}
                                                                                    {(row.SecurePremiumAmt > 0 && row.CompanyName === 'Reliance') && <li>
                                                                                        <span>Key and Lock Replacement</span>
                                                                                        <span className="spanRight">
                                                                                            <img src={require("../Contents/img/RightCheck.png")} />
                                                                                        </span>
                                                                                    </li>}
                                                                                </ul>}
                                                                            </p>}
                                                                            {row.chkEngineProtector && !row.ThirdPartyCover && row.CompanyName !== 'Reliance' && <p>
                                                                                <span>Engine And GearBox ProtectionCover :</span>
                                                                                <span className="spanRight"> {row.EngineProtectorAmt > 0 ? "₹ " + Math.round(row.EngineProtectorAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkConsumables && !row.ThirdPartyCover && row.CompanyName !== 'Reliance' && <p>
                                                                                <span>Consumable Cover :</span>
                                                                                <span className="spanRight"> {row.ConsumablesAmt > 0 ? ("₹ " + Math.round(row.ConsumablesAmt)) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkKeyReplacement && !row.ThirdPartyCover && row.CompanyName !== 'Reliance' &&  <p>
                                                                                <span>Key and lock Replacement Cover :</span>
                                                                                <span className="spanRight"> {row.KeyReplacementAmt > 0 ? "₹ " + Math.round(row.KeyReplacementAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkNCBProtection && !row.ThirdPartyCover && <p>
                                                                                <span>NCB ProtectionCover :</span>
                                                                                <span className="spanRight"> {row.NCBProtectionAmt > 0 ? "₹ " + Math.round(row.NCBProtectionAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkReturnToInvoice && !row.ThirdPartyCover && <p>
                                                                                <span>Return To Invoice Cover :</span>
                                                                                <span className="spanRight"> {row.ReturnToInvoiceAmt > 0 ? "₹ " + Math.round(row.ReturnToInvoiceAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkRoadSideAssistance && !row.ThirdPartyCover && <p>
                                                                                <span>Road Side Cover :</span>
                                                                                <span className="spanRight"> {row.RoadSideAssistanceAmt > 0 ? "₹ " + Math.round(row.RoadSideAssistanceAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkPassangerAssistance && !row.ThirdPartyCover && <p>
                                                                                <span>Passanger Assistance Cover :</span>
                                                                                <span className="spanRight"> {row.PassangerAssistanceAmt > 0 ? "₹ " + Math.round(row.PassangerAssistanceAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkPassengerCover && <p>
                                                                                <span>Passanger Cover :</span>
                                                                                <span className="spanRight"> {row.PassengerCoverAmt ? "₹ " + Math.round(row.PassengerCoverAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkTyreSecure && !row.ThirdPartyCover && <p>
                                                                                <span>Tyre Secure Cover :</span>
                                                                                <span className="spanRight"> {row.TyreSecureAmt > 0 ? "₹ " + Math.round(row.TyreSecureAmt) : "NA"}</span>
                                                                            </p>}
                                                                            {row.chkInconvenienceAllowance && !row.ThirdPartyCover && <p>
                                                                                <span>Inconvenience Allowance Cover :</span>
                                                                                <span className="spanRight"> {row.InconvenienceAllowanceAmt ? "₹ " + Math.round(row.InconvenienceAllowanceAmt) : "NA"}</span>
                                                                            </p>}
                                                                            <h5>Discount</h5>
                                                                            <p>
                                                                                <span>NCB Discount :</span>
                                                                                <span className="spanRight">₹ {Math.abs(Math.round(row.NCBAmount))}</span>
                                                                            </p>
                                                                            <p>
                                                                                <span>OD Discount :</span>
                                                                                <span className="spanRight">₹ {Math.abs((Math.round(row.ODDiscount)))}</span>
                                                                            </p>
                                                                            {row.chkTPPDcover && <p>
                                                                                <span>TPPD Discount :</span>
                                                                                <span className="spanRight"> {row.TPPDcoverAmt > 0 ? "₹ " + Math.round(row.TPPDcoverAmt) : "NA"}</span>
                                                                            </p>}
                                                                            <h5>Tax</h5>
                                                                            <p>
                                                                                <span>GST :</span>
                                                                                <span className="spanRight">₹ {Math.round(row.serviceTax)}</span>
                                                                            </p>

                                                                        </div>
                                                                        <h4>
                                                                            <span>Total Premium :</span>
                                                                            <span className="spanRight">₹ {Math.round(row.TotalPremium)}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                : null}
                                            {
                                                this.state.popupKey == row.CompanyID ? <PreviousInsurerConfirmationBox confirm={this.state.prvConf} src={row.CompanyLogo} companyName={row.CompanyName} no={() => this.Buynow(row)} yes={() => this.setState({ IsPreviousInsu: true })} IsPreviousInsu={this.state.IsPreviousInsu} close={() => this.setState({ popupKey: null, IsPreviousInsu: false })} />
                                                    : null}
                                        </React.Fragment>
                                    ))}

                                </div></div>}
                        {this.state.NotRespond.length > 0 && <MotorQuoteFooter ListItem={this.state.IsLoader ? LoaderBoxCar : this.state.NotRespond} IsLoader={this.state.IsLoader} />}

                    </div>
                    {this.state.gotQuotePOPUP ?
                        <React.Fragment><GotQuotes
                            handle={this.handle}
                            RegistrationDate={this.state.RegistrationDate}
                            PreviousPolicyExpiredDate={this.state.PreviousPolicyExpiredDate}
                            TypeOfPolicy={this.state.TypeOfPolicy}
                            PastYearClaimID={this.state.PastYearClaimID == "N" ? false : true}
                            BindPreviousNCB={this.state.BindPreviousNCB}
                            ISPACOVERODSELECTED={this.state.ISPACOVERODSELECTED == "YES" ? true : false}
                            MyPolicyexpired={this.state.MyPolicyexpired}
                            Previouspolicydetails={this.state.Previouspolicydetails}
                            motorType={this.state.Req.QuoteReq.Type}
                            ManufactureDate={this.state.ManufactureDate}
                            Manu_Month={this.state.Manu_Month}
                            Manu_Year={this.state.Manu_Year}
                            IsReNew={this.state.Req.QuoteReq.IsReNew}
                            PACOVERYEAR={this.state.PACOVERYEAR}
                            Organisation={this.state.Organisation}
                            ClaimAmount={this.state.ClaimAmount}
                            CallGetQuote={this.gotQuotesUpdate}
                            close={this.openPopup}
                            PrevPolicyType={this.state.PrevPolicyType}
                        /></React.Fragment> : null}
                    {this.state.IsMessage ? (
                        <div className="Manualmodal modal">
                            <div className="modal-dialog newModalqt">
                                <div className="modal-content">
                                    <div className="modal-header text-center ui-draggable-handle">
                                        <span>Message</span>
                                        <button
                                            type="button"
                                            className="close btn-success"
                                            onClick={this.CloseMessage}
                                        >
                                            ×
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <span>{this.state.MessageBody}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                </div>
                {this.state.TalkToExpert && < TalkExperts close={() => this.setState({ TalkToExpert: false })} />}
            </div>
        );
    }
}
