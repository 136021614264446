/* eslint-disable */
import React, { useState } from 'react';
import ButtonClass from '../ButtonClass';
import '../Contents/css/QuoteStyle.css'
const SideModel = (props) => {
    return (

        <div className="modal fade MTB-Insured-HH editModal ui-draggable show" id="MTB-Insured" role="dialog" style={{ paddingRight: "17px", display: "block" }}>
            <div className="modal-dialog newModalqt modal-lg editModal001">
                <div className="modal-content">
                    <div className="modal-header text-center ui-draggable-handle">
                        WHOM DO YOU WANT TO INSURE?
                    <button type="button" className="close" data-dismiss="modal" onClick={props.close}>×</button>
                    </div>
                    <div className="modal-body">
                        <div className="new genderRDDD_Edit" id="bike-Neww" style={{ display: "block" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label> 
                                        <span>Gender</span>
                                    </label>
                                </div>
                                <div className="genderRDDD col-md-6" >
                                    <label><input type="radio" name="Gender" value='M' key={props.Gender} checked={props.Gender === "M"} onChange={ props.handle} /> Male</label>
                                    <label><input type="radio" name="Gender" value="F" key={props.Gender} checked={props.Gender === "F"} onChange={ props.handle} /> Female</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="inptBoxRDDD gender-dd col-md-6">
                                    <label className="styleForm">
                                        <small>Full Name</small>
                                        <input className="form-control style-inpt" name="Name" onChange={props.handle} value={ props.Name} type="text" placeholder="" />
                                    </label>
                                </div>

                                <div className="inptBoxRDDD gender-dd col-md-6">
                                    <label className="styleForm">
                                        <small>DOB</small>
                                        <input className="form-control style-inpt" name="DOB" onChange={props.handle} onBlur={props.handle} value={ props.DOB} type="date" />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="inptBoxRDDD gender-dd col-md-6">
                                    <label className="styleForm">
                                        <small>Do you smoke or chew tobacco?</small>
                                        <select className="form-control style-inpt" name="SmokeStatus" onChange={props.handle} value={props.SmokeStatus}>
                                            <option value="N">No</option>
                                            <option value="Y">Yes</option>
                                        </select>
                                    </label>
                                </div>

                                <div className="inptBoxRDDD gender-dd col-md-6">
                                    <label className="styleForm">
                                        <small>What's your annual income?</small>
                                        <select className="form-control style-inpt" name="AnnualIncome" onChange={props.handle} value={ props.AnnualIncome} id="drpIncome">
                                            <option value="200000">2 Lakhs</option>
                                            <option value="300000">3 Lakhs</option>
                                            <option value="400000">4 Lakhs</option>
                                            <option value="500000">5 Lakhs</option>
                                            <option value="600000">6 Lakhs</option>
                                            <option value="700000">7 Lakhs</option>
                                            <option value="800000">8 Lakhs</option>
                                            <option value="900000">9 Lakhs</option>
                                            <option value="1000000">10 Lakhs</option>
                                            <option value="1100000">11 Lakhs</option>
                                            <option value="1200000">12 Lakhs</option>
                                            <option value="1300000">13 Lakhs</option>
                                            <option value="1400000">14 Lakhs</option>
                                            <option value="1500000">15 Lakhs</option>
                                            <option value="1600000">16 Lakhs</option>
                                            <option value="1700000">17 Lakhs</option>
                                            <option value="1800000">18 Lakhs</option>
                                            <option value="1900000">19 Lakhs</option>
                                            <option value="2000000">20 Lakhs</option>
                                            <option value="2100000">21 Lakhs</option>
                                            <option value="2200000">22 Lakhs</option>
                                            <option value="2300000">23 Lakhs</option>
                                            <option value="2400000">24 Lakhs</option>
                                            <option value="2500000">25 Lakhs</option>
                                            <option value="2600000">26 Lakhs</option>
                                            <option value="2700000">27 Lakhs</option>
                                            <option value="2800000">28 Lakhs</option>
                                            <option value="2900000">29 Lakhs</option>
                                            <option value="3000000">30 Lakhs</option>
                                            <option value="3100000">31 Lakhs</option>
                                            <option value="3200000">32 Lakhs</option>
                                            <option value="3300000">33 Lakhs</option>
                                            <option value="3400000">34 Lakhs</option>
                                            <option value="3500000">35 Lakhs</option>
                                            <option value="3600000">36 Lakhs</option>
                                            <option value="3700000">37 Lakhs</option>
                                            <option value="3800000">38 Lakhs</option>
                                            <option value="3900000">39 Lakhs</option>
                                            <option value="4000000">40 Lakhs</option>
                                            <option value="4100000">41 Lakhs</option>
                                            <option value="4200000">42 Lakhs</option>
                                            <option value="4300000">43 Lakhs</option>
                                            <option value="4400000">44 Lakhs</option>
                                            <option value="4500000">45 Lakhs</option>
                                            <option value="4600000">46 Lakhs</option>
                                            <option value="4700000">47 Lakhs</option>
                                            <option value="4800000">48 Lakhs</option>
                                            <option value="4900000">49 Lakhs</option>
                                            <option value="5000000">50 Lakhs</option>
                                            <option value="5100000">51 Lakhs</option>
                                            <option value="5200000">52 Lakhs</option>
                                            <option value="5300000">53 Lakhs</option>
                                            <option value="5400000">54 Lakhs</option>
                                            <option value="5500000">55 Lakhs</option>
                                            <option value="5600000">56 Lakhs</option>
                                            <option value="5700000">57 Lakhs</option>
                                            <option value="5800000">58 Lakhs</option>
                                            <option value="5900000">59 Lakhs</option>
                                            <option value="6000000">60 Lakhs</option>
                                            <option value="6100000">61 Lakhs</option>
                                            <option value="6200000">62 Lakhs</option>
                                            <option value="6300000">63 Lakhs</option>
                                            <option value="6400000">64 Lakhs</option>
                                            <option value="6500000">65 Lakhs</option>
                                            <option value="6600000">66 Lakhs</option>
                                            <option value="6700000">67 Lakhs</option>
                                            <option value="6800000">68 Lakhs</option>
                                            <option value="6900000">69 Lakhs</option>
                                            <option value="7000000">70 Lakhs</option>
                                            <option value="7100000">71 Lakhs</option>
                                            <option value="7200000">72 Lakhs</option>
                                            <option value="7300000">73 Lakhs</option>
                                            <option value="7400000">74 Lakhs</option>
                                            <option value="7500000">75 Lakhs</option>
                                            <option value="7600000">76 Lakhs</option>
                                            <option value="7700000">77 Lakhs</option>
                                            <option value="7800000">78 Lakhs</option>
                                            <option value="7900000">79 Lakhs</option>
                                            <option value="8000000">80 Lakhs</option>
                                            <option value="8100000">81 Lakhs</option>
                                            <option value="8200000">82 Lakhs</option>
                                            <option value="8300000">83 Lakhs</option>
                                            <option value="8400000">84 Lakhs</option>
                                            <option value="8500000">85 Lakhs</option>
                                            <option value="8600000">86 Lakhs</option>
                                            <option value="8700000">87 Lakhs</option>
                                            <option value="8800000">88 Lakhs</option>
                                            <option value="8900000">89 Lakhs</option>
                                            <option value="9000000">90 Lakhs</option>
                                            <option value="9100000">91 Lakhs</option>
                                            <option value="9200000">92 Lakhs</option>
                                            <option value="9300000">93 Lakhs</option>
                                            <option value="9400000">94 Lakhs</option>
                                            <option value="9500000">95 Lakhs</option>
                                            <option value="9600000">96 Lakhs</option>
                                            <option value="9700000">97 Lakhs</option>
                                            <option value="9800000">98 Lakhs</option>
                                            <option value="9900000">99 Lakhs</option>
                                        </select>

                                    </label>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <button type="button" id="btnHealthSubmit" className={ButtonClass()} onClick={ props.updateResult} >Update Result</button>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>

            </div>
        </div>
    )
}
export default SideModel;