import React from 'react';
const MotorInsurance = () => {
    return (
        <section class="productCntMain">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="productCnt">
                            <h3>Motor Insurance</h3>
                            <p>Fundamentally, motor insurance means an agreement between a vehicle owner and an insurance company in which policyholder pays a certain amount i.e. premium for a policy which gives economic coverage to policyholder against losses in case the insured vehicle is damaged or stolen. Auto insurance policy covers loss of vehicle, damage to automobile, damage which your vehicle causes, and protection against liability if you will be sued after an accident. The premium amount is based on the make and value of the car, car registration state and manufacturing year. Insurance buyers can make use of car insurance premium calculator to buy car insurance online and save some money on premium. Online car insurance calculator does not charge you anything for access.</p>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/RFL/CarIns.png")} />
                    </div>
                    <div class="col-md-12">
                        <div class="productCnt">
                            <p>
                                <strong>Natural Calamities </strong>Fire, explosion,  self-ignition or lightning, flood, typhoon, hurricane, storm, tempest,  inundation, cyclone, hailstorm, landslide, rockslide, earthquake (fire and  shock damage). <br />
                                <br />
                                <strong>Man-made Calamities</strong> Burglary, housebreaking or theft, riot, strike,  accidental external means, malicious act, terrorist activity, whilst in transit  by road, train, inland waterway, elevator, lift or air<br />
                                <br />
                                <strong>Third Party Legal Liability </strong>It means that policyholder is insured against  injury or death resulted by your vehicle to pedestrians, passengers and  occupant of other vehicles. <br />
                                <br />
                                <strong>The policy does not cover</strong><br />
                                                            Pace making<br />
                                                            Organized racing<br />
                                                            Speed testing<br />
                                <br />
                                <strong>Sum Insured</strong><br />
                                <br />
                                                                                    Vehicles are covered at a specific value called as the 'Insured's Declared  Value&rsquo; and it is calculated on the basis of the manufactures listed selling  cost of the vehicles along with the listed cost of any accessories after deducting  the depreciation for every year according to the schedule offered by the Indian  Motor Tariff. If the cost of any electrical and / or electronic item installed  in the vehicle is not included in the manufacturer&rsquo;s listed selling price, then  the actual value (after depreciation) of this item can be added to the sum  insured over and above the IDV.
                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default MotorInsurance