import React from "react";
import TabData from "./TabData";
const data = TabData;
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <a
            onClick={props.onClick}
            className="nav-link mb-3 p-3 shadow productTabsRisk--active"
            data-tab={props.dataTab}
        >
            {props.title}
        </a>

    ) : (
        <a
            onClick={props.onClick}
            className="nav-link mb-3 p-3 shadow"
            data-tab={props.dataTab}
        >
            {props.title}
        </a>
    );
}

function TabContent(props) {
    return (
        <div style={props.style} data-tab={props.dataTab}>
            {props.content}
        </div>
    );
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isActive: "1", data: data };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.getAttribute("data-tab") });
        console.log(ev.target);
    }

    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                dataTab={item.id}
                title={item.tabTitle}
                key={item.id}
                to={item.to}
            />
        ));
        var listContent = this.state.data.map((item,i) =>
            this.state.isActive === item.id ? (
                <TabContent dataTab={item.id} content={item.tabContent} key={i} />
            ) : (
                <TabContent
                    style={{ display: "none" }}
                    dataTab={item.id}
                    content={item.tabContent}
                    key={i}
                />
            )
        );
        return (
            <section className="MainIndex plan-insurane-block">
                <div className="container">
                    <div className="">
                        <h3 className="h1-orange">Let's Plan Your Insurance Online</h3>
                        <div className="row">
                            <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="nav border-bottom-0 nav-tabs nav flex-column nav-pills nav-pills-custom">
                                                {listTitle}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="tab-content">
                                                {listContent}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-3 SideCNTData text-center">
                                <img src={require("../assets/images/icons_Marketing-guy.png")} alt="Marketing Guy" /><br />
                                <span class="caption-name">PRINCIPAL OFFICER</span><br />
                                <h4>K.S.MURARY</h4>
                                <br />
                                <span class="caption-name">CONTACT</span><br />
                                <h4>+91 - 8106602266</h4>
                                <br />
                                <img src={require("../assets/images/icons_Google Rating.png")} alt="Google Rating" />
                            </div>
                        </div>
                    </div>                    
                </div>
            </section>
        );
    }
}
export default Tab;
