/* eslint-disable */
import React from 'react';
const LifeIns = () => {
    return (
        <section class="summeryPage">
            <div class="container">
                <div class="main-counter-area">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <h3>LIFE Insurance</h3>
                                <p>
                                    <b>Term Life Insurance Plan</b><br />
                                        This is the most sought after Life Insurance Plan today  for those who understand the value of Life. The cost of living attaches from  the moment we are born maybe even from the time that you are in the womb.  As we grow so do our responsibilities and  thereby the costs of very existence not only for ourselves but for others –  your family.<br />
                                            Life is very fragile and we are exposed to all kinds&rsquo;  risks like natural calamities, diseases, accidents even our habits. Such  incidents do not come announced yet are very real.  If a life is lost the entire family who you  were taking care of is left without any resource to fall back upon for  existence and stand a very real danger of death themselves.<br />

                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/KareIndia/Life_Insurance.jpg")} />
                        </div>
                        <div class="col-md-12">
                            <div class="ClaimsPageSecCNTBox border-0">
                                <p>        Life Insurance policy is an agreement between the  Insurance Company and the insured under which company agrees to pay pre-defined  amount upon the death of the insured to the nominee. Towards this you paya  small premium to ensure the financial protection to the family.  <br />
                                                    This Insurance Policy is called Term Life Insurance  Policy.<br />
                                                        A term insurance plan helps you to provide  cover against the risk of untimely death. It is the most affordable insurance  you can buy with a large cover. Being cheap on pocket has made this form of  insurance immensely popular among the people. Life insurance works on a  straightforward principle if you die, your family receives the life cover  amount. Thereby, they can live a comfortable life even when you are not around.  If you survive, nothing gets paid back. That's not all. Have you taken any loan  like Home Loan, Car Loan, Personal Loan, Outstanding Credit Card dues? You  obviously wouldn't want your family members burdened with your outstanding  loans and debts.<br />
                                                            At Kare, a leading Insurance Broker of India duly  qualified and certified by the Insurance Regulator, we explain the features and  the need of this policy to all client as our interest is in safeguarding Life  itself.  We carefully take you through  various plans available and customize the same as per your needs and  affordability<br />
                                                                So, if you want your family to live a  comfortable life, buy a term insurance plan. Ensure that you have a large cover  amount, which would take care of your family as you would have done until you  retire.</p>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
export default LifeIns