import React from "react";
const Expertise = (props) => {
  return (
    <section>
    <div className="expertise-area">
    <div className="container">
    <div className="row">
      <div className="col-sm-6 d-flex align-items-center">
        <div className="row ">
          <div className="col-sm-12 ">
            <div className="team ">
              <h4>
                InsureTech Expertise &nbsp;
                <i className="fa fa-arrow-right faIcon" aria-hidden="true"></i>
              </h4>
              <p className="start">
                A host of Health, Motor and Life Insurance products now
                available with 100% online journey, no pesky calls, no
                aggressive follow-ups. Enjoy a smooth online experience even if
                your Car Insurance is discontinued, from <br />
                Quote <i className="fa fa-angle-double-right" aria-hidden="true"></i> Proposal <i className="fa fa-angle-double-right" aria-hidden="true"></i> Pre-inspection to Payment a seamless
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 ">
        <img
          src={require("../assets/img/expertise.png")}
          alt="Expertise"
          className="exp"
        />
      </div>
    </div>
    </div>
    </div></section>
    );
};
export default Expertise;
