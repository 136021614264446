/* eslint-disable */
import React from "react";
import WhoWeAre from "./WhoWeAre";
import OurMission from "./OurMission";
import Achievements from "./Achievements";
import EssenceOfHero from "./EssenceOfHero";
import "./AboutUs.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const AboutUs = (props) => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (
        <React.Fragment>
            <div className="MainAbout">
                <Zoom>
                    <WhoWeAre />
                </Zoom>
                <Zoom>
                    <OurMission />
                </Zoom>
                <Zoom>
                    <Achievements />
                </Zoom>
                <Zoom>
                    <EssenceOfHero />
                </Zoom>
            </div>
        </React.Fragment>
    );
};
export default AboutUs;
