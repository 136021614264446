/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link, NavLink } from "react-router-dom";
import { NavItem } from "reactstrap";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div class="container bottom_border">
                    <div class="row">
                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Motor Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/MotorInsurance">Motor Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/MotorInsurance">Private Car Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/MotorInsurance">Two Wheeler Insurance</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Health Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Mediclaim Policy</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Senior Citizen Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Family Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Parents Insurance</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Life Insurance</h5>
                    <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/TermLifeInsurance">Term Plans</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermLifeInsurance">Money Back Policy</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermLifeInsurance">Pension Plans</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermLifeInsurance">Type of Life Insurance</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Other Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/HomeInsurance">Home Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TravelInsurance">Travel Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/PrivacyPolicy">Privacy Policy</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermsAndCondition">Terms And Condition</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="container">
                    <div class="footerBottom">


                        <p class="text-center">
                            <strong>RFL Insurance Broking Pvt. Ltd.<br />
                                Registration Number - 567, Direct Broker: General and Life,<br /> Certificate Valid Upto: 25 May 2022,<br />
                    Member: Insurance Brokers Association of India, CIN - U66000MH2016PTC273494
                </strong><br />

                Registered & Corporate Office Address : 801, Atlanta  Centre, Sonawala  Road  ,Goregaon East,Mumbai :400063
            </p>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <li><a href="/RFLInsurance/Contact">Connect With Us</a></li>
                                <li><a href="https://www.facebook.com/riskfreelife/?ref=br_rs"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/7939558/admin/"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>

        </React.Fragment>
    );
};
export default Footer;
