import React from 'react';
import { Link } from 'react-router-dom';
const ProgressBarBox = (props) => {
    return (
        <div class="progress progressManual" className={props.className}>
            <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" style={{ width: props.Progress }}>
                {props.Progress}
            </div>
            <div className="PaymentJourney">
                You have completed {props.Progress} of Payment Pourney.
                </div>
        </div>
    )
}
export default ProgressBarBox;