/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./car.css";

import { Component } from "react";

import MaskedInput from 'react-text-mask';
import ButtonClass from "../ButtonClass";

class Car extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            fields: {},
            errors: {},
            isValid: true,
            registration: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
            this
        );
        this.submit = this.submit.bind(this)
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });
        let errors = {};

        let isValid = true;
        if (this.state.fields["registration"] === undefined) {
            isValid = false
            errors["registration"] = "Please Enter Number Correct Number"
        }
        else if (this.state.fields["registration"] !== undefined) {
            if (this.state.fields["registration"].length < 10) {
                isValid = false;
            }
        }

        this.setState({ errors, isValid, fields })

    }

    submituserRegistrationForm(e) {
        console.log(this.validateForm());

        e.preventDefault();
        if (this.validateForm()) {
            console.log(this.state);
            let fields = {};
            // fields["username"] = "";
            // fields["emailid"] = "";
            // fields["mobileno"] = "";
            // fields["password"] = "";
            this.setState({ fields: fields });
            console.log(this.state);
            alert("Form submitted");
        }
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["username"]) {
            formIsValid = false;
            errors["username"] = "*Please enter your username.";
        }

        if (typeof fields["username"] !== "undefined") {
            if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["username"] = "*Please enter alphabet characters only.";
            }
        }

        if (!fields["emailid"]) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }

        if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(fields["emailid"])) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }

        if (!fields["mobileno"]) {
            formIsValid = false;
            errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof fields["mobileno"] !== "undefined") {
            if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["mobileno"] = "*Please enter valid mobile no.";
            }
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
        }

        if (typeof fields["password"] !== "undefined") {
            if (
                !fields["password"].match(
                    /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
                )
            ) {
                formIsValid = false;
                errors["password"] = "*Please enter secure and strong password.";
            }
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    }

    registrationValidation(e) {
        let fields = this.state.fields;
        let errors = {}
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
        let v = this.state.fields.registration.slice(0, 2);
    }
    submit() {
        let regPattern = RegExp(/^[A-Za-z]{2}[0-9]{1,2}[-][A-Z]{2,3}[0-9]{4,5}$/)
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] === "")
            return alert("Please enter Registration Number ")
        else if (!regPattern.test(this.state.fields["registration"].toUpperCase()))
            return alert("Please Provide Correct Value")
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    render() {

        return (
            <div className="container-fluid">
                <div className="row d-flex justify-content-center pt-3">
                    <div className="col-sm-12 productTabsMainCar">
                        <h3 className="heading text-center">
                            Find better plans for your
                            <strong>&nbsp;Car Insurance</strong>
                        </h3>
                        <h6 className="text-center">
                            <p>Get best offers with attractive premium</p>
                        </h6>

                        <div className="ProductFormIn ProductForm01">
                            <div className="PF0012 SpaceBetweenRow002">
                                <div className="row">
                                    <div className="col-md-7 NoPaddingRight">
                                        <label>
                                            {/*<input id="Vehicleno" maxlength="12" type="text" placeholder="Enter Car Registration Number" name="registration"
                                                        value={this.state.fields.registration}
    onChange={this.handleChange} onInput={()=>Inputmask}/>*/}
                                            <MaskedInput onChange={this.handleChange} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder="Enter Car Registration Number" name="registration" style={{ textTransform: "uppercase" }} value={this.state.fields["registration"]} />
                                        </label>
                                    </div>
                                    <div className="col-md-5">
                                        <label>

                                            {this.state.isValid ? <Link to="/Renew?type=1">
                                                <button className={ButtonClass()} id="quotesBtn"  >VIEW QUOTES</button> </Link> :
                                                <button className={ButtonClass()} id="quotesBtn"  >VIEW QUOTES</button>

                                            } </label>
                                    </div>
                                    <div className="OtherOptions">
                                        <h5>Other options </h5>
                                    </div>

                                    <div className="col-md-7 mt-3 ">

                                        <Link

                                            to="/Renew?type=1"
                                            className=" headingBt"
                                            style={{ wordBreak: "break-word", fontSize: "12px" }}
                                        >
                                            Don't know your car number? <b>Click here</b>
                                        </Link>

                                    </div>
                                    <div className="col-md-5 mt-3">
                                        <Link className="headingBt"
                                            to="/New?type=1"
                                        > Bought a new car? <b>Click here</b></Link>


                                    </div>
                                </div>

                            </div>
                        </div>
                        { /*  <div className="row d-flex justify-content-center">
                <div className="col-sm-6 ">
                  <div className="ProductFormIn pt-3 pl-3">
                    <input
                      type="text"
                      placeholder="Enter Car Registration Number"
                      className="inputBoxCar"
                      name="registration"
                      value={this.state.fields.registration}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.registration}
                    </div>
                  </div>
                  <p><label>
                  <NavbarBrand
                    tag={Link}
                    to="/Renew?type=1"
                    className="text-center headingBt ml-3"
                    style={{ wordBreak: "break-word", fontSize: "12px" }}
                  >
                    Don't know your car number? <b>Click here</b>
                  </NavbarBrand>
                </label></p>
                  
                </div>
                <div className="col-sm-4 pt-3">
                  <button className="btn QouteMNBtnnew" id="quotesBtn">VIEW QUOTES</button>
                  <label id="New">
                  <NavbarBrand
                    tag={Link}
                    className="headingBt text-center"
                    to="/New"
                  >
                    Bought a new car? <b>Click here</b>
                  </NavbarBrand>
                </label>
                </div>
              </div>
    */ }

                    </div>
                </div>

            </div>
        );
    }
}
export { Car };
