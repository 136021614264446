import React from 'react';
import Partner from './SecuritySection';
const SecuritySection = () => {
    return (


        <section class="SecuritySection pt-5 pb-5">
            <div class="container">
                <div className="row">
                    <div class="col-md-6 col-sm-6">
                        <div className="">
                            <h2>Safe And Secure</h2>
                            <p>
                                Entire  site is encrypted through SSL technology, which means your personal details and your activities on our site  are completely safe. Your transactions are completely secured. We use the most secured payment gateways that don't store any of your credit or debit card information.
                        </p>
                        </div>
                        <div className="row">
                            <div class="col-md-3">
                                <img src={require("./assets/img/BMB/dmcaprotected.png")} className="SecuImg" />
                            </div>
                            <div class="col-md-3">
                                Licensed By :
                                <img src={require("./assets/img/BMB/irdai-dark.png")} className="SecuImg" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 text-right">
                        <img src={require("./assets/img/BMB/SafeImg.jpg")} className="SecuImgLeft"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SecuritySection;