/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div class="container bottom_border">
                    <div class="row">
                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Motor Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="/RFLInsurance/MotorInsurance">Private Car Insurance</a></li>
                                    <li><a href="/RFLInsurance/MotorInsurance">Two Wheeler Insurance</a></li>
                                    <li><a href="#">Goods carrying vehicle</a></li>
                                    <li><a href="#">Passenger carrying vehicle</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Health Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="/RFLInsurance/HealthInsurance">Mediclaim Policy</a></li>
                                    <li><a href="#">Senior Citizen Insurance</a></li>
                                    <li><a href="#">Family Insurance</a></li>
                                    <li><a href="#">Parents Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Life Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="/RFLInsurance/TermLifeInsurance">Term Plans</a></li>
                                    <li><a href="#">Money Back Policy</a></li>
                                    <li><a href="#">Pension Plans</a></li>
                                    <li><a href="#">Type of Life Insurance</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <h5 class="headin5_amrc col_white_amrc pt2">Other Insurance</h5>
                                <ul class="footer_ul_amrc">
                                    <li><a href="/RFLInsurance/HomeInsurance">Home Insurance</a></li>
                                    <li><a href="/RFLInsurance/TravelInsurance">Travel Insurance</a></li>
                                    <li><a href="#">Taxi Insurance</a></li>
                                    <li><a href="#">School Bus Insurance</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="container">
                    <div class="footerBottom">


                        <p class="text-center">
                            <strong>
                                SBM Insurance Brokers Pvt.Ltd.<br />License No.:626, Category : Direct Broker General<br /> License Validity: 05.11.2023<br />
                    Member: Insurance Brokers Association of India, CIN - U67110DL2016PTC299094<br />
                    Principal Officer: Mamta Bansal, 8882573728
                </strong>
                Registered Office Address: E-2/20, Behind G3S Complex, Sector-11, Rohini, Delhi-110085<br />Phone: 011-27570050, 42659333
            </p>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <li><a href="/RFLInsurance/Contact">Connect With Us</a></li>
                                <li><a href="https://www.facebook.com/riskfreelife/?ref=br_rs"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/7939558/admin/"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>

        </React.Fragment>
    );
};
export default Footer;
