/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
const AccordianBike = (props) => {

    return (
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 class="text-secondary mb-3">Frequently asked questions</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <Accordion class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Why do I need to insure my vehicle?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Having a bike insurance policy offers more than one benefit including —
                                    <ul>
                                            <li>Protection from unforeseen financial liability due to accident, theft, or other damages</li>
                                            <li>Covers liability arising out of accidental death or injuries to third parties</li>
                                            <li>Insurance is available for all types of two-wheelers, including moped, motorcycle, or scooter </li>
                                            <li>Comprehensive insurance policies include roadside assistance </li>
                                            <li>Insurance claims help recover the cost of repairs/replacements due to accidents/other perils</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    What is IDV?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        Insured Declared Value or IDV is the sum assured value in the vehicle insurance policy. It is calculated based on the manufacturer's listed price of a particular vehicle and adjusted against the current selling price of the bike after calculating the depreciation percentage as per the rate grid. Simply put, it is the maximum sum insured that can be compensated in case of loss, theft, or complete damage to the vehicle.
<br /><br />
                                            IDV of the vehicle is dependent on various factors, including age, vehicle brand. The IDV value decreases each year at the time of renewal of the vehicle insurance. With an increase in the age of the bike, the depreciation also increases. IDV of the new vehicle will be high, and as the bike gets older, IDV will go down.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    What are the factors affecting bike insurance policy premium?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        The premium payable depends on the below factors:
                                    <ul>
                                            <li>Cubic capacity of the engine</li>
                                            <li>Year of purchase to determine age</li>
                                            <li>City of registration</li>
                                            <li>Insured Declared Value (IDV)</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    What is a third-party liability in bike insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <strong>Third-party insurance or Act Only Policy</strong> covers the rider against legal liabilities caused to third-parties such as bodily injuries/death and damages or losses to third party properties.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    What is No Claim Bonus (NCB) and why is it important?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        No Claim Bonus (NCB) is a discount offered to the policyholder for not making a claim during the last policy period. It is usually deducted from the Own Damage (OD) premium during policy renewal, which reduces the premium payable. The NCB can be accumulated up to a maximum limit of 50% on the OD premium.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    Is it possible to transfer accumulated NCB from one insurer to another?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        Yes, NCB is transferrable from one insurer to another. In order to do so, please submit the following documents —
                                    <ul>
                                            <li>Renewal notice</li>
                                            <li>Declaration of NCB</li>
                                            <li>Other policy documents</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    What is comprehensive insurance policy in bike insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        <strong>Comprehensive insurance Or Package Policy</strong> offers enhanced insurance that covers damage to the insured vehicle (Bike) caused due to accidents, fire, theft, vandalism, natural disaster, civil disturbance, etc. perils along with third-party liability and personal accident cover.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                    Is it mandatory to file FIR in case of partial theft?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        Yes. Bimastreet recommends FIR or police intimation in case of partial theft.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    Will the bike insurance policy cover accidents that occurred outside the city or state from where the policy was issued?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        All motor insurance policies are serviceable Pan India. An external surveyor is appointed to do a thorough assessment of loss.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="9">
                                    Can I renew an expired bike insurance policy?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        An expired insurance policy can be renewed but it will lose certain benefits.
                                    <ul>
                                            <li>Bike Inspection/verification is not mandatory if renewed online but Risk Inception Date (RID) under policy will commence after 48 hrs i.e 2 days of premium payment</li>
                                            <li>NCB will be lost of policy renewed after 90 days of expiry</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="10">
                                    What are the mandatory documents to carry while driving a bike?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="10">
                                    <Card.Body>
                                        Under the Motor Vehicle Act, 1988, a bike rider must carry the following documents at all times
                                    <ul>
                                            <li>A valid driving license</li>
                                            <li>Vehicle registration certificate (RC)</li>
                                            <li>Copy of insurance policy </li>
                                            <li>Valid pollution certificate</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="11">
                                    What is Zero depreciation cover or bumper-to-bumper coverage?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="11">
                                    <Card.Body>
                                        Zero depreciation cover is a type of add-ons to bike insurance that can be availed based on the necessity of the policyholder. While these are not mandatory, they offer more comprehensive coverage and more benefits to the insured. This coverage enhances the basic bike insurance policy as it allows the policyholder to claim the full price of replaced parts/repair charges without the insurer deducting depreciation.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="12">
                                    Is two wheeler insurance required for second-hand purchase?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="12">
                                    <Card.Body>
                                        Yes, it is mandatory to purchase two-wheeler insurance for second-hand purchase. In India, driving a motor vehicle such as a bike without insurance in public places is a punishable offense per the Motor Vehicles Act, 1988. Under the law, an individual found driving a vehicle without valid insurance can be fined Rs 2,000 for their first offense and Rs 4,000 for a future offense.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="13">
                                    How to file for an insurance claim in Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="13">
                                    <Card.Body>
                                        The first step in the claims process is to inform the insurance company about the claim using the toll-free number mentioned in the policy. While filing for a claim, please keep the following details ready –
                                    <ul>
                                            <li>Name of the insured as per the policy</li>
                                            <li>Contact details of the insured or claimant</li>
                                            <li>Policy document, RC, and driving license</li>
                                            <li>In case of an accident, details of the parties and vehicles</li>
                                            <li>Date, venue, and time of the accident</li>
                                            <li>Brief description about the accident</li>
                                            <li>Extent of loss</li>
                                        </ul>

Once the claim is filed with the insurer, a claims registration number is issued. Visit www.bimastreet.com/free-claims and enter the number and other details relating to your claim in the form, for follow up by Bimastreet to ensure speedy and hassle free claims settlement.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="14">
                                    Can I renew my motor policy online?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="14">
                                    <Card.Body>
                                        With Bimastreet, it is easy to renew the bike policy online. You can renew the policy as early as 60 days before the expiry of the existing policy. Our authorized surveyor is responsible for inspection of the vehicle before policy issuance if the vehicle insurance policy has already expired. The policy will be issued subject to a satisfactory inspection and submission of required documents.
                                                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>









                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AccordianBike;
