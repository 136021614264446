import React from "react";
const Product = [
  {
    id: 1,
    name: "Car Insurance",
    to: "/Car",
    className: "nav-item nav-link",
    src: "flaticon-car"
    },
  {
    id: 2,
      name: "Bike Insurance",
    to: "/Bike",
    className: "productTabsRisk",
    src: "flaticon-motocross"
  },
  
  {
    id: 3,
    name: "Health Insurance",
    className: "productTabsRisk",
    to: "/Health",
    src: "flaticon-life-insurance"
  },
  {
    id: 4,
    name: "Life Insurance",
    className: "productTabsRisk",
    to: "/",
    src: "flaticon-terms"
    },
  {
    id: 5,
    name: "Travel Insurance",
    className: "productTabsRisk",
    to: "/",
    src: "flaticon-around"
  },
];
export default Product;
