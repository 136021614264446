import React from 'react';
const OurPartner = () => {

    return (

        <section class="about-bg pad-tb" id="partners">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-6 v-center">
                        <div class="about-company">
                            <h2 class="mb20 aos-init" data-aos="fade-up" data-aos-delay="100">Our <em>Partners</em></h2>
                            <p data-aos="fade-up" data-aos-delay="100" class="aos-init">We collaborate with the best and biggest in the banking &amp; financial Lorem Ipsum has been the industry's standard dummy text.</p>
                        </div>
                        <div class="partnerlogo mt40 aos-init" data-aos="fade-In" data-aos-delay="500">
                            <a href="#"><img src={require("../Contents/img/partner/HdfcLifeInsurance.jpg")} alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/bajaj.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/birlaHealthInsurance.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/cigna-ttk-health-insurance-2-150x43.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/EdelweissTokioLifeInsurance.jpg")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/future_logo.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/godigit.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/hdfc_logo.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/icici.jpg")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/Icici_lom_Logo.png")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/iffco.jpg")}  alt="brand logo" /> </a>
                            <a href="#"><img src={require("../Contents/img/partner/kotak_logo.png")}  alt="brand logo" /> </a>
                        </div>
                    </div>
                    <div class="col-lg-5 v-center">
                        <div class="img-box1 m-mt60 aos-init aos-animate" data-aos="fade-In" data-aos-delay="100">
                            <img src={require("./assets/img/Vedant/parntership.png")} alt="image" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurPartner;