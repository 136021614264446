import React from 'react';
import MaskedInput from 'react-text-mask'
class InsureLife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="row  pt-3 d-flex justify-content-center">
                <div className="col-sm-9">
                    <div className="packagesMainHealth text-center">
                        <div className="productTabsMainHealth productTabsMainHelath text-center">
                            <h3 className="heading">Term Life Plans at Lowest Rates</h3>
                            <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                                <div className="PF0012">
                                    <form className="ng-pristine ng-valid">
                                        <div className="row">
                                            <div className="col-md-3 NoPaddingRight">
                                                <label>
                                                    <select name="Gender" id="drpGender">
                                                        <option value="Select Gender">Select Gender</option>
                                                        <option value="M">Male</option>
                                                        <option value="F">Female</option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    <MaskedInput id="txtFirstName" name="FullName" placeholder="Enter Full Name"
                                                        mask={[/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, , /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, , /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                                    <label id="txtFullnameSpesfic-error" className="error"></label>
                                                </label>
                                            </div>
                                            <div className="col-md-3 NoPaddingLeft">
                                                <label>



                                                    <input type="date" id="textDOB" required="required" placeholder="DD/MM/YYYY" className="hasDatepicker" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 offset-md-4">
                                                <label>
                                                    <MaskedInput mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" id="txtMobile" required="required" placeholder="+91"
                                                        guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                    <label id="txtMobileSpesfic-error" className="error"></label>
                                                </label>
                                            </div>
                                            <div className="col-md-4 offset-md-4">
                                                <button type="button" className="btn btn-block QouteMNBtn" id="FirstForm">Next</button>
                                                <p style={{ color: "#142963" }}>I Agree to<a target="_blank"> <span style={{ color: "#F58634" }}>T&amp;C</span></a></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InsureLife;