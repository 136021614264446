import React from "react";
const Dedicated = (props) => {
    return (
        <section class="aboutCntBoxMain">
            <div class="container">
                <div class="row ">

                    <div class="col-md-6">
                        <div class="zn-bgSource ">
                            <img src={require("../assets/img/Advance/aboutImg.png")} />
                        </div>
                    </div>

                    <div class="col-md-6 wow slideInUp">
                        <div class="aboutCntBox">
                            <h5>WHO WE ARE</h5>
                            <h2>Advance Life Insurance Brokers Pvt. Ltd.</h2>
                            <p>Advance Life Insurance Brokers Pvt. Ltd. is an insurance broking firm licensed by IRDA, the insurance broker is an independent intermediary to both client and insurance companies and represent to all the insurance companies working in India as compared to Insurance Agent who represent only to a single insurance company.</p>
                            <p>We assist the clients through various processes and services which may be fruitful to them while negotiating the rates and terms and condition of the policy. As a Direct insurance broker that partners with top-rated insurance companies we offer multiple insurance options for every customer's needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
