import React from "react"

const AppComponentManager = (props) => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    return (<React.Fragment>
        <div className="AppNextBox" role="dialog">
            <button type="button" className="close" onClick={props.close}>&times; </button>
            <div className="NewProModel00">
                {props.children}
            </div>
        </div>
    </React.Fragment>
    )
}
export default AppComponentManager