import React from "react";
import TrustUs from "./TrustUs";
const Policies = (props) => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center text-center m-2">
          <div className="col-sm-8 ">
            <p>
              <h2 className="heading-trust">
                Different Policies + Different Brands = More Options
              </h2>
            </p>
            <p>
              <h4>Compare, to select the one you need!</h4>
            </p>
            <p>
              Allow us to help you make the right decision! with us, you can
              compare insurance policies from various brands , and choose the
              plan that caters to your requirements in the best possible way.
              Our state-of-the-art technology makes purchasing insurance a
              smooth and hassle-free process.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="row container d-flex justify-content-center">
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="member-container ">
                <TrustUs
                  name="Decide-which-insurance-02.svg"
                  alt="member"
                  className="trust-img"
                />
                <span style={{ whiteSpace: "nowrap", fontSize: "12px" ,fontWeight:"400" }}>
                  Decide which Insurance
                </span>
                <br />
                <span style={{ whiteSpace: "nowrap",  fontSize: "12px" ,fontWeight:"400" }}>
                  Policy you want
                </span>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="policy-container">
                <TrustUs
                  name="Choose-the-one-with-best-02.svg"
                  alt="member"
                  className="trust-img"
                />

                <span style={{ whiteSpace: "nowrap",  fontSize: "12px" ,fontWeight:"400" }}>
                  Choose the one with best
                </span>
                <br />
                <span style={{ whiteSpace: "nowrap",  fontSize: "12px" ,fontWeight:"400" }}>
                  coverage and affordable premium
                </span>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-center">
              <div className="member-container">
                <TrustUs
                  name="Pick-and-compare-02.svg"
                  alt="member"
                  className="trust-img"
                />
                <span style={{ whiteSpace: "nowrap",  fontSize: "12px" ,fontWeight:"400" }}>
                  Pick and Compare
                </span>
                <br />
                <span style={{ whiteSpace: "nowrap",  fontSize: "12px" ,fontWeight:"400" }}>
                  Insurance Plan online
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center text-center m-5 ">
          <div className="col-sm-3">
            <button className="btn btn-danger quotesBtn">Compare Now &gt;</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Policies;
