/* eslint-disable */
import React from "react";
const OurMission = (props) => {
    return (
        <section class="text-center our-mission">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-5">
                        <h2 class="text-left headingCont"><span>Our Mission</span> and Vision</h2>
                        <p class="text-left">We, at Hero Insurance Broking India Private Limited, are committed to Total Customer Satisfaction by addressing all insurance requirements effectively, combined with timely delivery of reliable and customized insurance products.</p>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/mission-vision.svg")} alt="" class="embed-responsive pl-5 pr-5" />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default OurMission;
