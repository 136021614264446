import React from "react";
const TestimonialData = [
  {
        name: "SPML Team",
    quotes:
            "I would like to thank you for helping me chose the correct insurance plan for my vehicle. Your team is an expert in giving insurance advise.",
  },
  {
      name: "Delhi waste management",
    quotes:
          "When my vehicle met with an accident , your claims team helped me to coordinate and submit the required paperwork. This helped me get cashless facility and a Hassel free claim experience.",
  },
  {
      name: "Kamal Agrawal",
    quotes:
          "I bought the cheapest and most suitable insurance policy with the advice I recieved through you.",
  },
  {
      name: "Santosh Kataria",
    quotes:
          "The health plan that I was advised was the most suitable to my family needs.",
  },
  {
      name: "K Trans",
    quotes:
          "The tailor made plan was extremely useful for protecting my vehicles and suitable to our business needs",
    },
];
export default TestimonialData;









