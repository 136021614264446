/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>
            <footer class="footer-risk bottom_border text-left">
                <div class="container">
                    <div className="row text-center footer-risk">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footerBox">

                                        <ul className="footer_ul_amrc">
                                            <h4>Motor Insurance</h4>
                                            <li><Link to="/FourWheelerInsurance"> Private Car Insurance</Link></li>
                                            <li><a href="#">Two Wheeler Insurance</a></li>
                                            <li><a href="#">Goods carrying vehicle</a></li>
                                            <li><Link to="/TwoWheelerInsurance">Passenger carrying vehicle</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="footerBox">

                                        <ul className="footer_ul_amrc">
                                            <h4>Health Insurance</h4>
                                            <li><Link to="/HealthInsurance">Mediclaim Policy</Link></li>
                                            <li><a href="#">Senior Citizen Insurance</a></li>
                                            <li><a href="#">Family Insurance</a></li>
                                            <li><a href="#">Parents Insurance</a></li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footerBox">

                                        <ul className="footer_ul_amrc">
                                            <h4>Life Insurance</h4>
                                            <li><Link to="/TermLifeInsurance">Term Plans</Link></li>
                                            <li><a href="#">Money Back Policy</a></li>
                                            <li><a href="#">Pension Plans</a></li>
                                            <li><a href="#">Type of Life Insurance</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="footerBox">

                                        <ul className="footer_ul_amrc">
                                            <h4>Other Insurance</h4>
                                            <li><a href="#">Private Car Insurance</a></li>
                                            <li><a href="#">Two Wheeler Insurance</a></li>
                                            <li><a href="#">Taxi Insurance</a></li>
                                            <li><a href="#">School Bus Insurance</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="container">
                        <div class="footerBottom">


                            <p class="text-center">
                                RISKOVERY INSURANCE WEB AGGREGATOR PVT LTD. LICENSE NO: IRDAI/003/27.
        <br />
        IRDA Web Aggregator Code: IRDAI/INT/WBA/44/2017 ,{" "}
                                <a href="/Content/PolicyMart/images/Riskovery/Renewal Certificate.pdf">
                                    IRDAI Certificate Valid till: 03/12/2023
        </a>
        , CIN: U74999DL2016PTC309772
        <br />
        Principal Officer: Mohinder Pal Singh. (Contact: +91 - 9987105551)
        <br />
        REGISTERED OFFICE: 154 Second Floor,Pratap Nagar New Delhi-110064.
        <br />
        CORPORATE OFFICE: HL-06,Second Floor, Jail Road, Next to Muthoot Fincorp
        ,Hari Nagar, New Delhi-110064
        <br />
                            </p>
                            <p className="text-center size">
                                The Information that is available on this portal is of the Insurance
                                company with whom www.riskoveryinsurance.com has a legal contract,
          <br />
          The prospects details can be shared with the Insurance companies for
          which we have a lead sharing arrangement
          <br />
          The discounts on Insurance Cover are being provided by the insurance
          Companies and are applicable on OD premium. <br />
                                <strong>
                                    <a href="http://www.riskoveryinsurance.com">
                                        www.riskoveryinsurance.com
            </a>
                                </strong>{" "}
          is an online initiative of Riskovery Insurance Web Aggregator Pvt Ltd
          to facilitate Knowledge,Comparision and Buying of Online Insurance in
          an easy way.
          <br />
          For more details on risk factors, terms and conditions, please read
          the sales brochure carefully before concluding a sale
        </p>


                        </div>
                        <div class="row">

                            <div class="col-md-12 text-center">

                                <ul class="social_footer_ul">
                                    <li><a href="/RFLInsurance/Contact">Connect With Us</a></li>
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </React.Fragment>
    );
};
export default Footer;
