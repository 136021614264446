import React from "react";
const Dedicated = (props) => {
    return (
        <section class="benefits">
            <div class="container">
                <div class="howItWork01 wow fadeInUp" data-wow-delay="0.18s">
                    <h3 class="my_titleAllHead my_titleAllHead02">About Us</h3>
                </div>
                <div class="row align-items-center justify-content-center benefitsRow benefitsRow02 wow fadeInUp" data-wow-delay="0.18s">
                    <div class="col-md-3">
                        <div class="howItWork02">
                            <img src={require("../assets/img/Bimaplanner/about2.jpg")} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="howItWork03">
                            <p>Sarsa is a leading insurance broker in Jaipur, Rajasthan having experience of more than 25 years in insurance sector and working as an independent insurance broker since 24 December, 2014 licensed by IRDA. We have a team of more well qualified, experienced & dedicated staff. Our aim is to provide upgraded services not only during the selling process but also after the sell has been processed to the consumers, and offer the best and reliable risk coverage in the insurance market according to the need of the consumer.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
