import React from 'react'
import "react-reveal";
import AccordianLife from './FAQAccordian/AccordianLife';
import Scroller from './Scroller';
const LifeInsurance = (props) => {
    Scroller();
    return (
        <div className="ProduactPageBima">
            <section class="landing-hero">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h2 class="text-secondary">
                                Enjoy twin benefits of policy at right premium & free claims assistance
                    </h2>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <p>Give your family an estate through life insurance with Bimastreet. Now and forever.</p>
                            <a class="btn btn-primary mt-4">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="hero-img">
                    <img src={require("../assets/img/BimaStreet/life-claim.svg")} class="img-fluid" />
                </div>
            </section>

            <section class="section WhatIsLife">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h4 class="text-secondary">What is life insurance?</h4>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>According to the data collected from the S&P Global Market Intelligence report, India has
                                    the second-largest insurance technology market in Asia-Pacific. The Indian insurance
                                    industry consists of 57 in-surance companies. Reportedly, 24 companies are in the life
                                    insurance business. The primary reason life insurance is prevalent is for the financial
                                    protection it offers the insured members. To aid the population's interest in insurance
                                    investment, The Government of India has taken several initiatives. Some of them are —
                            </p>
                                    <p><strong>During the 2021 Union Budget, the FDI limit in insurance was increased from 49%
                                    to 74%.</strong></p>

                                    <p><strong>As part of the Union Budget 2021, Finance Minister Nirmala Sitharaman announced
                                    that the IPO of LIC would be implemented in FY22.</strong></p>

                                    <p><strong>In February 2021, the Finance Ministry of India announced to infuse INR 3,000
                                    crore into state-owned general insurance companies to support the overall financial
                                    health of companies.</strong></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="benfit-section benefitsOfLife">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-3 align-self-center">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Benefits of life insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <p>Getting life insurance online made easy with Bimastreet</p>
                            <a class="btn btn-primary">Get Quotes <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4 g-4 g-md-3 cont">
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Life insurance secures the financial and social wellbeing of the families even when the
                        policy-holder is not around and is more an obligation to the family by everyone.</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Cover can be extended to Critical Illnesses. Suppose the life assured suffers from a listed
                            criti-cal illness and survives. In that case, a lump sum benefit is payable to compensate for
                        his/her reduced income levels due to such critical illnesses.</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Insurance also acts as a savings plan giving enhanced returns over a period of time and is paid
                        as Maturity Benefit in case the life assured survives the policy term.</p>
                        </div>
                        <div class="col-md-3">
                            <p>Acts as a Tax Saving instrument since the life insurance premium is deductible under Section 80C
                        of the Income Tax Act, 1961</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-grey WhyBima">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Why Bimastreet:</h5>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <p>Twin Benefits – Best policy at best premiums AND Free Claims Assistance (FCA)</p>

                            <p>Under a life insurance policy, we are committed to extending total assistance and guidance to the
                            nominee/family of the life assured during unfortunate death, ensuring speedy and hassle-free
                            claim settlement to the family, thus fulfilling the desire of the life assured in taking life
                        in-surance.</p>

                            <p>Our Free Claims Assistance also extends to arranging the Maturity Benefits to the life assured if
                            he survives the policy term under endowment plans, whole life plans, child plans, and Term with
                        Return of Premium policies.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue TypesOfLife">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Types of life insurance policy</h5>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">

                            <div class="box shadow-none">


                                <h6 class="text-secondary">Term insurance plan</h6>
                                <p class="mb-4">Term insurance plan is the most popular type of life insurance policy. This plan
                                gives pro-tection and assurance to the family's future of the life assured in case of
                                unfortunate death of the life assured during the policy term leaving the family an estate
                                after his death. One can opt for a shorter payment term and longer policy term or pay as you
                                go throughout the policy term. The beauty of term policy lies in its lowest premium for
                                young people, and the same premium continues throughout Premium Payment Term. Term Policies
                                also come with Return Of Premium Option under which the premium paid throughout the policy
                            term will be paid back to the life assured in case he/she survives.</p>


                                <h6 class="text-secondary">An endowment policy</h6>
                                <p class="mb-4">An endowment policy is a comprehensive life insurance policy providing financial
                                cover to the family in case of death during the policy period paying the Sum Assured to the
                                nominee. It acts as a saving plan, which grows wealth if the life assured is alive to whom
                                the maturity benefit shall be paid. This gives triple benefits - life insurance protection,
                            maturity benefit, and tax benefit.</p>

                                <h6 class="text-secondary">Whole life Policy</h6>
                                <p class="mb-4">Whole life Policy is the best life insurance plan as this gives protection till
                                the age of 100 years while the premium is paid only for a predetermined fixed term. In case
                                of death of the life assured before 100 years, the Sum Assured is paid to the
                                nominee/family, thus leaving the family an estate after his death. Some whole life plans
                            provide for Maturity Benefit if the life assured lives more than 100 years.</p>

                                <h6 class="text-secondary">Money back policy</h6>
                                <p class="mb-4">Money back policy pays a percentage of the sum assured at predetermined
                                intervals through-out the policy tenure besides protecting the life assured in case of
                                death. Unlike other life in-surance policies that pay at maturity, this policy helps create
                            wealth over an extended period, keeping funds flow to the life assured.</p>

                                <h6 class="text-secondary">Child Plan</h6>
                                <p class="mb-4">Child Plan is the most needed plan for young families with children whose future
                                can be se-cured in case of an unfortunate event to the life assured. Under Child Plan, the
                                Sum Assured is paid in case of unfortunate death of the life assured during the policy term.
                                In case the life assured survives, the policy will yield good maturity benefit with returns
                                depending on the plan chosen, which will be handy for the needs of the grownup children
                            either for their higher education or for marriage.</p>

                                <h6 class="text-secondary">An investment plan</h6>
                                <p class="mb-4">An investment plan is a life insurance policy that will invest the premiums paid
                                in different funds like Equity, Debt, and Balanced, thus optimizing the returns besides
                            giving life insur-ance cover against death during the policy period.</p>

                                <h6 class="text-secondary">A retirement plan</h6>
                                <p class="mb-4">A retirement plan contributes while in service so that a Corpus is created by
                                the time of re-tirement. Such Corpus can be invested in Annuities of life insurance
                                companies. These Annui-ties release sums of money as pension throughout the vesting period.
                                The Annuities have dif-ferent options upon death, thus protecting the family members of the
                            insured.</p>

                                <h6 class="text-secondary">Group Term Life insurance plan</h6>
                                <p class="">Group Term Life insurance plan covers a group of people falling under a Group like
                                Em-ployer-Employee Group under one group policy. Sometimes this policy is extended to give
                                Critical Illness Benefit also. This is one of the popular types of insurance policies
                            employers offer to protect the employee and their family members.</p>




                            </div>

                        </div>
                    </div>






                </div>
            </section>

            <section class="section-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3 KeyTermsLife">Key terms used in life insurance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row g-3 g-md-5">
                                <div class="col-md-6">
                                    <p><strong>Sum Assured -</strong> It is the total value of the insurance policy at the time
                                of purchase. The insur-ance company pays this amount to the policyholder's family at the
                                time of death of the in-sured.</p>

                                    <p><strong>Maturity Benefit -</strong> The sum assured plus bonuses issued by the insurance
                                provider is called maturity benefit. It is paid when the insured survives the policy
                                tenure. As money is received by the insured during his/her lifetime, it is also
                                considered a savings instrument.</p>

                                    <p><strong>Surrender value -</strong> The amount the policyholder will receive from the
                                insurance company if he/she decides to terminate the policy before maturity.</p>
                                </div>
                                <div class="col-md-6">
                                    <p><strong>Paid-up value -</strong> The life insurance company pays a reduced sum assured if
                                the policyholder fails to uphold premium payment after a stipulated period. The value
                                increases if the insured continues to pay the premium regularly.</p>

                                    <p><strong>Annuity -</strong> The insurance company issues a long-term investment by nature,
                                the annuity, to protect the insured from the risk of outliving income inflow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-blue BuyLife">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">How to buy life insurance online?</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-8">
                                    <p>There is a technology-driven shift in the world of insurance purchase and compensation.
                                    As the online insurance process is instantaneous, there is a steady rise in insurance
                                    issued online. There is also more transparency as the website, or the platform will
                                contain all the necessary information to make an in-formed choice.</p>
                                </div>
                            </div>

                            <p><strong>To buy online, simply keep the following documents ready</strong></p>

                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary mb-3">Your quest for the best car insurance in India ends here
                                with Bimastreet.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Twin Benefits – Best policy at best premiums AND free claims assistance</p>

                                    <p>Benefit from rich industry experience and knowledge</p>

                                    <p>Free claims assistance for speedy, judicious, and hassle-free settlement</p>
                                </div>
                                <div class="col-md-6">
                                    <p>Best insurance policy recommendation from the most extensive range of insurance companies
                            </p>

                                    <p>24/7 free claims assistance</p>

                                    <p>Single point of contact 24/7 for all claim related assistance – Free Of Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 bg-light-primary">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-10">
                                    Why only buy car insurance online when you can also get free claim service online?
                        </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <a class="btn btn-primary">Get Twin Benefits <svg width="14"
                                height="10" viewBox="0 0 14 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <AccordianLife />

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};
export default LifeInsurance;