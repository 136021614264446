/* eslint-disable */
import React from "react";
import { Car } from "../../Car/Car";
import { Health } from "../../Health/Health";
import { Bike } from "../../TW/Bike";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { Route } from "react-router-dom";
import LifeDemo from "./LifeDemo";
const TabData = [
    {
        id: "1",
        tabTitle: <ProductTab src={Product[0].src} id="1" name={Product[0].name} onMouseOver={Product[0].onMouseOver} />,
        tabContent: <Health />,
        activeSrc: require("../assets/img/icon/HealthHover.png")


    },
    {
        id: "2",
        tabTitle: <ProductTab src={Product[1].src} id="2" name={Product[1].name} onMouseOver={Product[1].onMouseOver} />,
        tabContent: <Car />,
        activeSrc: require("../assets/img/icon/HealthHover.png")

    },
    {
        id: "3",
        tabTitle: <ProductTab src={Product[2].src} id="3" name={Product[2].name} onMouseOver={Product[2].onMouseOver} />,
        tabContent: <Bike />,
        activeSrc: require("../assets/img/icon/HealthHover.png")
    },
    {
        id: "4",
        tabTitle: <ProductTab src={Product[3].src} id="4" name={Product[3].name} onMouseOver={Product[3].onMouseOver} />,
        tabContent: <LifeDemo />,
        activeSrc: require("../assets/img/icon/HealthHover.png")

    },
    {
        id: "5",
        tabTitle: <ProductTab id="5" src={Product[4].src} name={Product[4].name} onMouseOver={Product[4].onMouseOver} />,
        tabContent: <LifeDemo />,
        activeSrc: require("../assets/img/icon/HealthHover.png")
    },
];
export default TabData;
