import React from 'react';
import Loader from '../Health/Loader';
class PaymenResultHealth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    
    render() { 
        return ( 
            <section className="thanksPageMain" className="QuotePage">
            
    <div className="container">
        <div className="tablePrchsDetailsMain">
            {this.props.IsLoader?<Loader bgColor="blue" />:!this.props.PaymentStatus=="Payment Fail"?
            <React.Fragment><div className="text-center"><img src={require("../Contents/img/right.jpg")} /></div>
            <h3>Thanks you for your Purchase!</h3></React.Fragment>
            :
            <React.Fragment><div className="text-center"><img src={require("../Contents/img/wrong.jpg")} />
            </div><h3>We Regret that your Purchase is Failed..!</h3></React.Fragment>
           }
            
            <h2>We checked and it seems that your policy document is taking a tad bit longer than it usally does. But worry not! We've got this covered and are doing every bit to ensure you get your policy in the next 24 hours.</h2>
            
            <h2>Welcomes you!. You will be receiving softcopy of your policy on your registered email shortly.</h2>
           
            <div className="tablePrchsDetails">
                <h4>Your Policy Details</h4>
                <div className="cnt">
                    <p id="iscmpname">Company Name :<span id="compname">{this.props.IsLoader?<Loader bgColor="blue" />:this.props.Companyname}</span></p>

                   
                    <p>Policy No <span id="policyno">{this.props.IsLoader?<Loader bgColor="blue" />:this.props.PolicyNo}</span></p>
                   
                    <p>Enquiry No <span id="policyno">{this.props.IsLoader?<Loader bgColor="blue" />:this.props.EnquiryNo}</span></p>
                    <p>Proposal No <span>{this.props.IsLoader?<Loader bgColor="blue" />:this.props.ProposalNo}</span></p>
                   
                    <p>Amount<span>{this.props.IsLoader?<Loader bgColor="blue" />:this.props.PayAmount}</span></p>
                   
                    <p>Email ID <span>{this.props.IsLoader?<Loader bgColor="blue" />:this.props.Email}</span></p>
                   
                    <p>Download URL <span><a id="">Not Avaliable</a></span></p>
                    


                </div>
            </div>
        </div>
        <h4 style={{textAlign:"center"}}>We look forward to a long relationship!.</h4>
    </div>
</section>
         );
    }
}
 
export default PaymenResultHealth;