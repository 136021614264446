import React from 'react';
import Partner from './Partner';
const HelpUs = (props) => {
    return (
        <section class="help helpBox">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                        <div class="heading">
                            <div class="h2">
                                Have a query?<br />
                                    Happy to help.
                        <span></span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-5 pull-left">
                        <div class="help-text">
                            <p>
                                We are here to resolve your concerns with undivided attention.
                                Give us a call, request a callback or drop us an email, we will be happy to help.
                    </p>

                            <a href="mailto:support@policyleader.co.in" class="btn">
                                <i></i>
                                <span>General Queries:</span>
                                <span>support@policyleader.co.in </span>
                            </a>
                            <a href="tel:+91-7069955555" class="btn no-margin">
                                <i class="support"></i>
                                <span>Customer Care:</span>
                                <span>+91-7069955555</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7 pull-right">
                        <div class="help-pic">
                            <img src={require("./assets/img/PolicyLeader/help.jpg")} class="lazy" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default HelpUs;