/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <section className="contacUS_page" style={{ marginTop: "130px" }}>
                <div className="container">
                    <h5 className="titleWithFont text-center">Let's Start a Conversation</h5>
                    <p className="text-center">
                        You'll be talking to a Human. You wont hit a ridiculously long phone
                        menu. You emails will be promptly responded. We at LifeMart provide an
                        exceptional customer service we'd expect ourselves.
      </p>
                    <div role="form">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="contacUS_page_Form ">
                                    <h2>Let Us Help</h2>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" id="Interested" name="typeList" onChange={this.handle}>
                                                <option value="">Interested In</option>
                                                <option value="Car Insurance">Car Insurance</option>
                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Travel Insurance">Travel Insurance</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["typeList"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["MobileNo"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" name="time" onChange={this.handle}>
                                                <option value="Preferred time">Preferred time</option>
                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["time"]}</span>
                                    <div className="form-group text-left">
                                        <input type="button" id="btnReq" className="cl_btn" value="Send Request" onClick={this.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contacUS_page_Info">
                                    <h3 className="orDivider">OR</h3>
                                    <div className="Spearhead">

                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>

                                            <div class="col-md-10">
                                                <p><b>Contact Us</b></p>
                                                <p>
                                                    Lifemart Insurance Brokers Pvt Ltd<br />

                                              Registered &amp; Corporate Office Address :<br />
                                                  Flat No:5, Floor No:1, Indramohini, Linking Road, Santacruz (West) MUMBAI Mumbai City MH 400054 IN<br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>

                                            <div className="col-md-10">
                                                <p>

                                                    <b title="Phone">Call Us </b>: +91-022-67208094
                                    </p>
                                            </div>
                                        </div>
                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-10">
                                                <p>
                                                    <b title="Email">Email</b>: <a href="mailto:support@lifemartinsurancebrokers.com">support@lifemartinsurancebrokers.com</a>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15082.2045676731!2d72.8239642263331!3d19.08346274333649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9a0f9c50ddb%3A0xcad60125a5e4548c!2sMumbai%2C%20Maharashtra%20400054%2C%20India!5e0!3m2!1sen!2sus!4v1599049603128!5m2!1sen!2sus"
                    className="map"
                    frameborder="0"
                    allowfullscreen=""
                ></iframe>
            </section>
        );
    };
}
export default Contact;
