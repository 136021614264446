import React from "react";
import TabData from "./TabData";
const data = TabData;
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <div
            onClick={props.onClick}
            className="productTabsRisk productTabsRisk--active"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>

    ) : (
        <div
            onClick={props.onClick}
            className="productTabsRisk"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>
    );
}

function TabContent(props) {
    return (
        <div style={props.style} data-tab={props.dataTab}>
            {props.content}
        </div>
    );
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isActive: "1", data: data };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.getAttribute("data-tab") });
        console.log(ev.target);
    }

    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                dataTab={item.id}
                title={item.tabTitle}
                key={item.id}
                to={item.to}
            />
        ));
        var listContent = this.state.data.map((item, i) =>
            this.state.isActive === item.id ? (
                <TabContent dataTab={item.id} content={item.tabContent} key={i} />
            ) : (
                <TabContent
                    style={{ display: "none" }}
                    dataTab={item.id}
                    content={item.tabContent}
                    key={i}
                />
            )
        );
        return (
            <section className="homeSection">
                <div className="tabs">
                    <div className="packagesMainRisk">
                        <div className="container">
                            <div className="productTabsMainRisk" id="mainTab">
                                {listTitle}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div class="services__nav-content pt-90 pb-90">
                            <div class="row">
                                <div class="col-xl-5 col-lg-6">
                                    <div class="services__thumb text-lg-right m-img">
                                        <img src={require("../assets/img/DemoTemp/services-1.png")} alt="Services" />
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-6">
                                    <div className="tab-content">{listContent}</div>
                                </div>
                            </div>
                        </div>
                        <div className="DetailsBox">
                            <div className="DetailsBoxIn">
                                <div className="">
                                    <img src={require("../assets/img/DemoTemp/TimeSave.png")} alt="Time Save" />
                                </div>
                                <div className="">
                                    <h3>Save up to 80% *</h3>
                                    <p>Lowest Car Premiums</p>
                                </div>
                            </div>
                            <div className="DetailsBoxIn">
                                <div className="">
                                    <img src={require("../assets/img/DemoTemp/Money.png")} alt="Money" />
                                </div>
                                <div className="">
                                    <h3>20+ Insurances</h3>
                                    <p>To Choose From</p>
                                </div>
                            </div>
                            <div className="DetailsBoxIn">
                                <div className="">
                                    <img src={require("../assets/img/DemoTemp/CarSave.png")} alt="Car Save" />
                                </div>
                                <div className="">
                                    <h3>25 Lakh +</h3>
                                    <p>Vehicles Insured</p>
                                </div>
                            </div>
                            <p className="DetailsBoxCont">*TP price for less than 1000 CC cars. All savings are provided by insurers as per Gov. approved insurance plan. Standard T&C apply.</p>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
export default Tab;
