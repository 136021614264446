/* eslint-disable */
import React from "react";
const ContactInfo = (props) => {
    return (
        <section class="text-center ways-contact">
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-12">
                        <h2 class="text-center headingCont">Ways to <span>Contact Us</span></h2>
                        <div class="indi-ways">
                            <p>Feel free to call us on<br /> <a href="tel:1800000000" class="lrg">1800 000 000</a> <br />Monday - Friday, 10 am to 6.30 pm</p>
                            <div class="img">
                                <img src={require("../assets/img/call.svg")} alt="" />
                            </div>
                        </div>
                        <div class="indi-ways">
                            <p>Reach us via email on<br /> <a href="mailto:wecare@heroinsurance.com">wecare@heroinsurance.com</a></p>
                            <div class="img">
                                <img src={require("../assets/img/mail.svg")} alt="" />
                            </div>
                        </div>
                        <div class="indi-ways">
                            <p>264, Okhla Phase III, Okhla Industrial Area, New Delhi, Delhi 110020</p>
                            <div class="img">
                                <img src={require("../assets/img/location.svg")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactInfo;
