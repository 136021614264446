import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const MoneyBackPolicy = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg MoneyBackBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Money Back Policy</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading mb-3 text-left">What is Money Back Policy?</h3>
                            <p class="padR_160">
                                Money back policies provide policyholders with the option to add cover that is not included in the original policy document in the form of riders.
                    <br />The riders provided along with a money back policy differ from insurer to insurer and also depend on other variables such as the policy term.
                    <br />General lists of riders that can be purchased along with a money back policy are given below:
                </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>




            <section class="ProductPageSectionThird">
                <div class="container TypeofCover">
                    <div class="ProductPageLife">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Accidental Death Rider.svg")} />
                                    <p><strong>Accidental Death Rider</strong><br />This rider provides coverage in case the policyholder meets with an accidental death as outlines in the rider guidelines. In such a scenario, the policyholder's beneficiaries/nominees will receive a lump sum as additional benefit.</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Term Rider.svg")} />

                                    <p><strong>Term Rider</strong><br />This rider provides the policyholder with a waiver from paying the premium amount under certain circumstances but still provides coverage to the policyholder. </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Critical Illness Rider.svg")} />

                                    <p><strong>Critical Illness Rider </strong><br />This rider provides the policyholder with financial assistance in the event he/she contracts a critical illness as defined by the rider.</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="SecondSectionBox">
                                    <img src={require("../assets/img/Religare/lifeinsurance/Hospitalization Rider.svg")} />

                                    <p><strong>Hospitalization Rider </strong><br />This rider provides the policyholder with assistance in paying hospital bills in the event the policyholder is hospitalized. A daily allowance is issued to the policyholder to cover expenses related to treatment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="SecondSectionHeading SecondSectionHeadingZeroDep">Features of money back</h3>
                        </div>
                        <div class="col-md-6">
                            <ul class="DoNotNeed">
                                <li>Provides  insurance cover during the policy term.</li>
                                <li>Pays out  regular benefits throughout the term.</li>
                                <li>Works as  an insurance policy as well as a long-term investment with good returns.</li>
                                <li>Provides  tax benefits.</li>
                                <li>Less  risky than other investments offering similar returns like mutual funds.</li>
                                <li>Enables  long-term savings and regular income.</li>
                                <li>Ensures  that amounts are disbursed regularly.</li>
                                <li>Some  plans extend the insurance coverage guaranteed death benefits even after the  maturity date and the last survival period, up to when the life insured attains  the age of 100.</li>
                                <li>There are  optional riders that cover things like specific illnesses, critical illnesses,  disabilities, etc.</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/Religare/lifeinsurance/Features of money back.jpg")} />
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
};
export default MoneyBackPolicy;
