const ButtonClass = () => {
    let url = window.location.href;
    let classes;
    if (url.match("hero"))
        return classes = "HeroBtnLarg";

    else if (url.match("insureasy"))
        return classes = "InEasyBtnlarg";

    else if (url.match("riskoveryinsurance"))
        return classes = "RisKovLarg";

    else if (url.match("insurekare"))
        return classes = "InsKareLarg";

    else if (url.match("myinsurancekart"))
        return classes = "LifeMartBtnLarg"

    else if (url.match("onlinebimakaro"))
        return classes = "SbmBtnLarg"

    else if (url.match("sansominsure"))
        return classes = "SansomBtnLarg"

    else if (url.match("riskfreelife"))
        return classes = "RflBtnLarg"

    else if (url.match("policy2cover"))
        return classes = "P2CLarg"

    else if (url.match("bimaplanner"))
        return classes = "BPlanerLarg"

    else if (url.match("sarsabima"))
        return classes = "SarsaLarg"

    else if (url.match("bookmybima"))
        return classes = "BMBLarg"

    else if (url.match("advancelifeinsurance"))
        return classes = "AdvanceLarg"

    else if (url.match("enivesh"))
        return classes = "EniveshLarg"

    else if (url.match("bimastreet"))
        return classes = "BstreetLarg"

    else if (url.match("policyleader"))
        return classes = "PleaderLarg"

    else if (url.match("star"))
        return classes = "StarLarg"

    else if (url.match("Pratiraksha"))
        return classes = "PratirakshaLarg"

    else if (url.match("smcinsurance"))
        return classes = "SMCLarg"
    else if (url.match("inshoracover"))
        return classes = "InshoraLarg"

    else if (url.match("religare"))
        return classes = "ReligareLarg"

    else return classes = "InsuMandiLarg"
}
export default ButtonClass
const ChildBtn = () => {
    let url = window.location.href;
    let classes;
    if (url.match("hero"))
        return classes = "HeroBtnSmall";

    else if (url.match("insureasy"))
        return classes = "InEasyBtnSmall";

    else if (url.match("riskoveryinsurance"))
        return classes = "RisKovSmall";

    else if (url.match("insurekare"))
        return classes = "InsKareSmall";

    else if (url.match("myinsurancekart"))
        return classes = "LifeMartBtnSmall"

    else if (url.match("onlinebimakaro"))
        return classes = "SbmBtnSmall"

    else if (url.match("sansominsure"))
        return classes = "SansomBtnSmall"

    else if (url.match("riskfreelife"))
        return classes = "RflBtnSmall"

    else if (url.match("policy2cover"))
        return classes = "P2CSmall"

    else if (url.match("bimaplanner"))
        return classes = "BPlanerSmall"

    else if (url.match("sarsabima"))
        return classes = "SarsaSmall"

    else if (url.match("bookmybima"))
        return classes = "BMBSmall"

    else if (url.match("advancelifeinsurance"))
        return classes = "AdvanceSmall"

    else if (url.match("enivesh"))
        return classes = "EniveshSmall"

    else if (url.match("bimastreet"))
        return classes = "BstreetSmall"

    else if (url.match("policyleader"))
        return classes = "PleaderSmall"

    else if (url.match("star"))
        return classes = "StarSmall";

    else if (url.match("Pratiraksha"))
        return classes = "PratirakshaSmall";

    else if (url.match("smcinsurance"))
        return classes = "SMCSmall";

    else if (url.match("inshoracover"))
        return classes = "InshoraSmall"
    else if (url.match("religare"))
        return classes = "ReligareSmall"

    else return classes = "InsuMandiSmall";
}
export { ChildBtn }