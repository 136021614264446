import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ButtonClass from "../ButtonClass";
import ProgressBarBox from "../components/ProgressBarBox";
import LifePayment from "./LifePayment";
class LifeFormModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Req: {},
            FatherName: null,
            MotherName: null,
            Email: null,
            Pincode: null,
            StateId: null,
            State: null,
            City: null,
            StateList: [],
            CityList: [],
            Loading: "Please Wait...",
            StateLoading: "Please Wait...",
            Redirect: false,
            errors: {},
            //changes by abhishek
            IsShowList: false,
            stateValue: null,
            stateName: "",
            showState: false,
            cityValue: null,
            cityName: "",
            showCity: false,
            CommunicationStateListMaster: [],
            CommunicationCityListMaster: [],
            Index: 0,
        };
        this.state.Req = this.props.Req;
        this.handleData = this.handleData.bind(this);
        this.ProceedToNext = this.ProceedToNext.bind(this);
        // changes by abhishek
        this.manageSearch = this.manageSearch.bind(this);
        this.manageClick = this.manageClick.bind(this);
        this.searchdown = this.searchdown.bind(this);
        this.state.CommunicationStateListMaster = this.state.StateList;
        this.state.CommunicationCityListMaster = this.state.CityList;

        axios
            .get(
                "https://centralapi.stariglocal.com/api/RTO/bindstate?companytype=112&stateid="
            )
            .then((Res) => {
                this.setState({ StateList: Res.data, StateLoading: "Select State" });
                console.log(Res.data);
            });
    }
    handleData = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        console.log(e.target.value);

        if (e.target.name === "StateId") {
            let stateid = e.target.value;
            axios
                .get(
                    "https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=112&StateID=" +
                    stateid
                )
                .then((Res) => {
                    this.setState({
                        CityList: Res.data,
                        Loading: "Select City",
                        State: this.state.StateList.filter(
                            (row) => row.CombindID == stateid
                        )[0].StateName,
                    });
                });
        }
    };
    ProceedToNext() {
        let errors = {};
        let isValid = true;
        if (this.state.FatherName === null || this.state.FatherName === "") {
            errors["FatherName"] = "Please Enter Father Name";
            isValid = false;
        }
        if (this.state.MotherName === null || this.state.MotherName === "") {
            errors["MotherName"] = "Please Enter Mother Name";
            isValid = false;
        }
        if (this.state.Email === null || this.state.Email === "") {
            errors["Email"] = "Please Enter Email";
            isValid = false;
        }
        if (this.state.Pincode === null || this.state.Pincode === "") {
            errors["Pincode"] = "Please Enter Pincode Number";
            isValid = false;
        }
        if (this.state.stateName === null || this.state.stateName === "") {
            errors["State"] = "Please select a state";
            isValid = false;
        }
        if (this.state.cityName === null || this.state.cityName === "") {
            errors["City"] = "Please select a city";
            isValid = false;
        }

        if (!isValid) {
            this.setState({ errors });
            return;
        }
        let Req = this.state.Req;
        Req.FatherName = this.state.FatherName;
        Req.MotherName = this.state.MotherName;
        Req.Email = this.state.Email;
        Req.Pincode = this.state.Pincode;
        Req.State = this.state.State;
        Req.City = this.state.City;
        if (Req.CompanyName != "AegonLife" && Req.CompanyName != "EdelweissLife") {
            return alert("Some Error Occured");
        }

        this.setState({ Req, Redirect: true });
    }
    //changes by abhishek
    manageSearch(e) {
        this.state.CommunicationStateListMaster = this.state.StateList;
        this.state.CommunicationCityListMaster = this.state.CityList;

        switch (e.target.id) {
            case "state":
                console.log("inside case state");
                this.setState({ showState: !this.state.showState });
                break;
            case "city":
                console.log("inside case city");
                this.setState({ showCity: !this.state.showCity });
                break;
        }
    }
    manageClick(value, state) {
        console.log("inside manageClick");
        switch (state) {
            case "state":
                console.log("inside state");
                this.setState({ [state]: value });

                let stateid = value;
                axios
                    .get(
                        "https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=112&StateID=" +
                        stateid
                    )
                    .then((Res) => {
                        this.setState({
                            CityList: Res.data,
                            Loading: "Select City",
                            stateName: this.state.StateList.filter(
                                (row) => row.CombindID == stateid
                            )[0].StateName,
                            showState: !this.state.showState,
                        });
                    });

                break;
            case "city":
                let CITY = this.state.CityList.filter(
                    (row) => row.CombindID == value
                )[0];
                console.log(`CITY`, CITY);
                this.setState({
                    cityName: CITY.CityName,
                    cityValue: CITY.CityID,
                    showCity: false,
                });
                break;
        }
    }
    searchFilter = (e) => {
        let currenttxtID = e.target.id;
        let filterList = [];
        console.log(`currenttxtID: `, currenttxtID);
        this.setState({ Index: 0 });
        switch (currenttxtID) {
            case "searchState":
                filterList = this.state.StateList.filter((row) =>
                    row.StateName.toLowerCase().includes(
                        document.getElementById("searchState").value.toLowerCase()
                    )
                );
                this.setState({ CommunicationStateListMaster: filterList });
                break;

            case "searchCity":
                filterList = this.state.CityList.filter((row) =>
                    row.CityName.toLowerCase().includes(
                        document.getElementById("searchCity").value.toLowerCase()
                    )
                );
                this.setState({ CommunicationCityListMaster: filterList });
                break;
        }
    };
    searchdown = (e) => {
        console.log("searchdown called");
        let currenttxtID = e.target.id;
        let UlID = "";

        switch (currenttxtID) {
            case "searchState":
                UlID = "searchUlState";
                break;
            case "searchCity":
                UlID = "searchUlCity";
                break;
        }
        console.log(`currenttxtID :`, currenttxtID);
        console.log(`UlID`, UlID);
        try {
            let selectList = document.getElementById(UlID);
            let Options = selectList.getElementsByTagName("li");
            console.log(`Options`, Options);

            switch (e.keyCode) {
                case 40:
                    console.log("arrowdown clicked");
                    Options[this.state.Index].className = "activeopt";
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    this.state.Index += 1;
                    this.setState({ Index: this.state.Index });
                    break;
                case 38:
                    if (this.state.Index > 0) this.state.Index -= 1;
                    Options[this.state.Index].className = "activeopt";
                    Options[this.state.Index + 1].removeAttribute("class");
                    this.setState({ Index: this.state.Index });
                    break;
                case 13:
                    console.log("enter clicked");
                    this.setState({ Index: 0 });
                    let selectedOption = document.getElementsByClassName("activeopt");
                    let filterList = [];
                    let Text = "";
                    console.log(`selectedOption`, selectedOption);
                    switch (currenttxtID) {
                        case "searchState":
                            filterList = this.state.StateList.filter(
                                (row) => row.CombindID == selectedOption[0].value
                            );
                            // let filterList = this.state.CommunicationStateList.filter(
                            //   (row) => row.CombindID == value
                            // )[0];
                            let value = filterList[0].CombindID;
                            this.setState({
                                stateName: filterList[0].StateName,
                                stateValue: filterList[0].StateID,
                                showState: !this.state.showState,
                            });
                            console.log("filterlist: ", filterList);
                            if (filterList.length > 0) {
                                console.log("inside if leangth>0");
                                // this.state.CommunicationStateListMaster = filterList[0];
                                let stateid = value;
                                axios
                                    .get(
                                        "https://centralapi.stariglocal.com/api/RTO/BindCity?CompanyType=112&StateID=" +
                                        stateid
                                    )
                                    .then((Res) => {
                                        this.setState({
                                            CityList: Res.data,
                                            Loading: "Select City",
                                            State: this.state.StateList.filter(
                                                (row) => row.CombindID == stateid
                                            )[0].StateName,
                                        });
                                    });
                            }
                            break;

                        case "searchCity":
                            console.log("inside city case");
                            console.log(this.state.CityList, "list");
                            filterList = this.state.CityList.filter(
                                (row) =>
                                    row.CombindID == selectedOption[0].attributes.value.value
                            );
                            console.log(selectedOption[0].value, "selected[0]");
                            console.log(`filterlist`, filterList);
                            this.setState({
                                cityName: filterList[0].CityName,
                                cityValue: filterList[0].CityID,
                                showCity: false,
                            });
                            break;
                    }
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    document.getElementById(currenttxtID).value = Text;
                    break;
            }
        } catch (ex) { }
    };
    render() {
        console.log(`this.state: `, this.state);
        return (
            <div className="NewProposal">
                <div className="container">
                    <div className="proposalPageBox">
                        <ProgressBarBox Progress="90%" />
                        <div className="row BackBTNRow">
                            <div className="col-3 col-md-auto">
                                <a className="arw-btn" onClick={this.props.closeModel}>
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </div>
                            <div className="col-9 col-md-10 col-lg-11 align-self-center">
                                <h3 className="f-32 font-regular mb-0">Back to Quotation</h3>
                            </div>
                        </div>
                        <div className="row proposalBox03">
                            <div className="col-sm-3 p-0">
                                <div className="proDetailsMM">
                                    <div className="rowDetailsMM">
                                        <img id="companylogo" src="https://riskoveryinsurance.com/insurer%20logo/go%20digit.png" />
                                        <span id="manufacturevehiclevariant" className="ng-binding">iTerm Insurance Plan-Life Protect</span>
                                        <p className="LeadIdHover enqueryNumber">
                                            <b>Enquiry#</b>
                                            <span id="EnquiryNo" className="ng-binding">
                                                LEAD ID : BMS/ENQ/606348
                                            </span>
                                        </p>
                                    </div>
                                    <div className="rowDetailsMM rowDetailsMM01">
                                        <p>
                                            Cover Value
                    <span><b>₹ </b> <b id="netPremium" className="ng-binding">₹10001000</b></span>
                                        </p>
                                        <p>
                                            + GST (18%)
                    <span><b>₹ </b> <b id="serviceTax" className="ng-binding"></b></span>
                                        </p>
                                        <p className="totalP">
                                            Total Premium
                    <span><b>₹ </b> <b id="TotalPremium" className="ng-binding">₹5583</b></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 p-0">
                                <div className="ProposalTabBox">
                                    <div className="ProposalTabsLeft">
                                        <ul>
                                            <li>
                                                Name <span><strong>Pankaj Kumar</strong></span>
                                            </li>
                                            <li>
                                                Gender <span><strong>Male</strong></span>
                                            </li>
                                            <li>
                                                Age <span><strong>18 Smoker</strong></span>
                                            </li>
                                            <li>
                                                Smoker <span><strong>Non-Smoker</strong></span>
                                            </li>
                                            <li>
                                                Annual Income <span><strong>15 Lakhs</strong></span>
                                            </li>
                                        </ul>
                                        <div className="BimaPayProcess" style={{ display: "none" }}>
                                            <ul class="info-text-ul">
                                                <li>
                                                    <span class="icon"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.5 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605357 15.0196 0.5 14.7652 0.5 14.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5H12.5C12.7652 6.5 13.0196 6.60536 13.2071 6.79289C13.3946 6.98043 13.5 7.23478 13.5 7.5V14.5C13.5 14.7652 13.3946 15.0196 13.2071 15.2071C13.0196 15.3946 12.7652 15.5 12.5 15.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M3.5 6.5V4C3.5 3.07174 3.86875 2.1815 4.52513 1.52513C5.1815 0.868749 6.07174 0.5 7 0.5V0.5C7.92826 0.5 8.8185 0.868749 9.47487 1.52513C10.1313 2.1815 10.5 3.07174 10.5 4V6.5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 12.5C7.82843 12.5 8.5 11.8284 8.5 11C8.5 10.1716 7.82843 9.5 7 9.5C6.17157 9.5 5.5 10.1716 5.5 11C5.5 11.8284 6.17157 12.5 7 12.5Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    </span>
                                            Secure transaction protected by EVSSL Certificate
                                    </li>
                                                <li><span class="icon">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                            <path d="M0.5 8H15.5L12.5 5H3.5L0.5 8Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.5 5L4.5 1H11.5L12.5 5" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M12 16C13.3807 16 14.5 14.8807 14.5 13.5C14.5 12.1193 13.3807 11 12 11C10.6193 11 9.5 12.1193 9.5 13.5C9.5 14.8807 10.6193 16 12 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M4 16C5.38071 16 6.5 14.8807 6.5 13.5C6.5 12.1193 5.38071 11 4 11C2.61929 11 1.5 12.1193 1.5 13.5C1.5 14.8807 2.61929 16 4 16Z" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M9.66009 12.644C9.20655 12.2296 8.61443 11.9999 8.00009 11.9999C7.38575 11.9999 6.79363 12.2296 6.34009 12.644" stroke="#4DAAE8" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </span>
                                        Data Security and Privacy Guaranteed</li>
                                                <li><span class="icon"><svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.2029 7.23333C13.5858 6.6469 13.7897 5.96169 13.7897 5.26132C13.7897 4.56095 13.5858 3.87573 13.2029 3.28931" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M11.3804 6.22103C11.6011 5.9499 11.7216 5.61099 11.7216 5.26139C11.7216 4.9118 11.6011 4.57289 11.3804 4.30176" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M0.86377 11.4659V2.15909C0.86377 1.88483 0.972718 1.62181 1.16665 1.42788C1.36058 1.23395 1.6236 1.125 1.89786 1.125H15.341C15.6153 1.125 15.8783 1.23395 16.0723 1.42788C16.2662 1.62181 16.3751 1.88483 16.3751 2.15909V11.4659C16.3751 11.7402 16.2662 12.0032 16.0723 12.1971C15.8783 12.3911 15.6153 12.5 15.341 12.5H1.89786C1.6236 12.5 1.36058 12.3911 1.16665 12.1971C0.972718 12.0032 0.86377 11.7402 0.86377 11.4659Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M3.44897 9.91479H9.65352" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M11.7217 9.91479H13.7899" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M6.0342 3.71021H3.44897V5.77839H6.0342V3.71021Z" stroke="#4DAAE8" stroke-miterlimit="10" stroke-linecap="square" />
                                                    <path d="M9.65352 5.77835C9.93908 5.77835 10.1706 5.54686 10.1706 5.26131C10.1706 4.97575 9.93908 4.74426 9.65352 4.74426C9.36796 4.74426 9.13647 4.97575 9.13647 5.26131C9.13647 5.54686 9.36796 5.77835 9.65352 5.77835Z" fill="#4DAAE8" />
                                                </svg>
                                                </span>
                                        Robust Payment Gateways
                                    </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="ProposalTabsRight">
                                        {this.state.Redirect ? (
                                            <LifePayment Req={this.state.Req} />
                                        ) : (
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <input
                                                            type="text"
                                                            name="FatherName"
                                                            value={this.state.FatherName}
                                                            onChange={this.handleData}
                                                            id="txtHDFCFName"
                                                            className="form-control hd-input-clear"
                                                            required="required"
                                                            placeholder="Father Name"
                                                        />
                                                        <p>
                                                            <small style={{ color: "red" }}>
                                                                {this.state.errors["FatherName"]}
                                                            </small>
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input
                                                            type="text"
                                                            id="txtHDFCLName"
                                                            name="MotherName"
                                                            value={this.state.MotherName}
                                                            onChange={this.handleData}
                                                            className="form-control hd-input-clear"
                                                            placeholder="Mother Name"
                                                        />
                                                        <p>
                                                            <small style={{ color: "red" }}>
                                                                {this.state.errors["MotherName"]}
                                                            </small>
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <input
                                                            type="email"
                                                            id="txtHDFCEmail"
                                                            name="Email"
                                                            value={this.state.Email}
                                                            onChange={this.handleData}
                                                            className="form-control hd-input-clear"
                                                            required="required"
                                                            placeholder="Email ID"
                                                        />
                                                        <p>
                                                            <small style={{ color: "red" }}>
                                                                {this.state.errors["Email"]}
                                                            </small>
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input
                                                            type="tel"
                                                            name="Pincode"
                                                            value={this.state.Pincode}
                                                            onChange={this.handleData}
                                                            maxlength="10"
                                                            id="txtHDFCMobile"
                                                            className="form-control hd-input-clear"
                                                            required="required"
                                                            placeholder="PinCode"
                                                        />
                                                        <p>
                                                            <small style={{ color: "red" }}>
                                                                {this.state.errors["Pincode"]}
                                                            </small>
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="vehicleContainer">
                                                            <button
                                                                onClick={this.manageSearch}
                                                                className="searchButtonCar form-control"
                                                                id="state"
                                                            >
                                                                {this.state.stateName === ""
                                                                    ? "Select State"
                                                                    : this.state.stateName}
                                                            </button>
                                                            {this.state.showState && (
                                                                <div className="manageDrpDwn">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search State"
                                                                        onChange={this.searchFilter}
                                                                        autoComplete="off"
                                                                        onKeyDown={this.searchdown}
                                                                        id="searchState"
                                                                        autoFocus
                                                                    />
                                                                    <ul id="searchUlState">
                                                                        {this.state.CommunicationStateListMaster.map(
                                                                            (row, i) => (
                                                                                <li
                                                                                    key={row.CombindID}
                                                                                    value={row.CombindID}
                                                                                    onClick={(e) =>
                                                                                        this.manageClick(row.CombindID, "state")
                                                                                    }
                                                                                >
                                                                                    {row.StateName}
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <span className="txtError">
                                                            {this.state.errors["State"]}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="vehicleContainer">
                                                            <button
                                                                onClick={this.manageSearch}
                                                                className="searchButtonCar form-control"
                                                                id="city"
                                                                name="CommunicationCity"
                                                            >
                                                                {this.state.cityName === ""
                                                                    ? "Select City"
                                                                    : this.state.cityName}
                                                            </button>
                                                            {this.state.showCity && (
                                                                <div className="manageDrpDwn">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search State"
                                                                        onChange={this.searchFilter}
                                                                        autoComplete="off"
                                                                        onKeyDown={this.searchdown}
                                                                        id="searchCity"
                                                                        autoFocus
                                                                    />
                                                                    <ul id="searchUlCity">
                                                                        {this.state.CommunicationCityListMaster.map(
                                                                            (row) => (
                                                                                <li
                                                                                    key={row.CombindID}
                                                                                    value={row.CombindID}
                                                                                    onClick={(e) =>
                                                                                        this.manageClick(row.CombindID, "city")
                                                                                    }
                                                                                >
                                                                                    {row.CityName}
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                    <span className="txtError">
                                                                        {this.state.errors["CommunicationState"]}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <span className="txtError">
                                                            {this.state.errors["City"]}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <button
                                                    type="button"
                                                    className={ButtonClass()}
                                                    id="btnHDFCPraposal"
                                                    onClick={this.ProceedToNext}
                                                >Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LifeFormModel;
