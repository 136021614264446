/* eslint-disable */ 
import React from 'react';
const LoaderBoxCar=[{
    id:1,
    src:"https://riskoveryinsurance.com/insurer%20logo/realince.png",
    IDV:"207629"
},
{
    id:2,
    src:"https://riskoveryinsurance.com/insurer%20logo/kotak.png",
    IDV:"440212"
},{
    id:3,
    src:"https://riskoveryinsurance.com/insurer%20logo/hdfc.png",
    IDV:"440919"
},{
    id:4,
    src:"https://smcinsurance.com/insurer%20logo/future.png",
    IDV:"326767"
},{
    id:5,
    src:"https://riskoveryinsurance.com/insurer%20logo/bajaj.png",
    IDV:"331857"
},{
    id:6,
    src:"https://i.ibb.co/Yd4bTHS/iffco.jpg",
    IDV:"435500"
},
]
export default LoaderBoxCar;