/* eslint-disable */
import React from 'react';
import { ChildBtn } from '../ButtonClass';
import RightCheck from "../Contents/img/check.png";
import WrongCheck from "../Contents/img/close.png";
const SideCompareModel = (props) => {
    return (
        <div id="mySidenav" className="Comparesidenav">
            <span className="ComBack"><a className="closebtn" id="close123" onClick={props.closebtn}><i className="fas fa-arrow-left"></i></a> Back to Quotetion</span>

            <div className="comparePage">
                <div className="CompRow">
                    <div className="CompCol CompColTitle">
                        <a className="CompDetails">View All Plans</a>
                    </div>
                    {props.Item.map((row) => <div className="CompCol">
                        <img src={row.UrlLogo} />
                        <p className="ng-binding">{row.PlanName}</p>
                        <button className={ChildBtn() + " CompBuy"} onClick={() => props.BuyNow(row.Key)}>Buy Now</button>
                        <a className="CompDetails" onClick={() => props.PlanDetails(row.Key)}>Plan Details</a>
                    </div>)}
                </div>







                {props.Item.length > 0 && props.Item[0].PlanFeatures.map((row) => <div className="CompRow">
                    <div className="CompCol CompColTitle">
                        <span>{row.Features}</span>
                    </div>
                    {props.Item.map((comp) =>
                        <div className="CompCol">
                            {comp.PlanFeatures.map((compFeat) => compFeat.Features.includes(row.Features) &&
                                <div>
                                    <span><img src={compFeat.Covered ? RightCheck : WrongCheck} alt="Covered" /> {compFeat.Covered ? "Covered" : "Not Covered"}</span>
                                    <p>{compFeat.Value}</p>
                                </div>
                            )}
                        </div>)}
                </div>)}
            </div>
        </div>)
}
export default SideCompareModel