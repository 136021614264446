import React from "react";

const About = () => {
  return (
    <React.Fragment>
    <section className="aboutUs">
    <div className="container">
        <div className="aboutContent">
            <div className="col-md-12 text-center">
                <h1 className="bp">We are Insurance Web Aggregators</h1>
                <p>
                    Riskovery insurance Web Aggregator Pvt. Ltd is an Insuretech startup with state of the art platform which will help customers to compare, buy and save on their health and car insurance policies.
                </p>
                <p>
                    We believe that insurance buying &amp; servicing journey is broken and it is full of friction, grey areas, poor awareness and rampant misselling. We trust it's an issue worth handling and in the Process we’ll work to build a world-className, customer-centric platform to help consumers buy &amp; manage their insurance policies seamlessly
                </p>
                <p>
                    We are now authorized by IRDAI to act as an Insurance web aggregator to take this agenda forward we are aggressively looking at Insurance Providers who wish to display their Insurance offerings and a Strategic tie up to take this initiative to next level.
                </p>
                <p>
                    Riskovery Insurance is your one-stop solution for insurance to do with life insurance, health insurance, car insurance, and bike insurance.
                </p>
            </div>

        </div>
    </div>
</section>

      <div className="about-section">
        <div className="row">
          <div className="col-sm-4 text-center">
            <div className="policyInfo">
              <h3>Total Premium</h3>
              <h1>
                23<sub>cr.</sub>+
              </h1>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            <div className="policyInfo">
              <h3>Lives Covered</h3>
              <h1>56456</h1>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            <div className="policyInfo">
              <h3>Policies Sold</h3>
              <h1>535236</h1>
            </div>
          </div>
        </div>
          </div>
          <section>
              <div className="container">
                  <div className="row">
                      <div className="col-sm-3">
                          <div className="about-card ">
                              <h4>Happy Customers</h4>
                              <p>
                                  Sam Walton of Wal-Mart very rightly said, “The Goal as a company
                                  is to have customer service that is not just the best but
                                  legendary.” We at Riskovery put our customers first not only
                                  during selling the policies but even while taking care of
                                  providing the services attached.
            </p>
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="about-card">
                              <h4>Best Expert Advice</h4>
                              <p>
                                  It’s not only about the pricing and simplicity of buying the
                                  products, but hard work and efforts of our team which includes
                                  skillful risk managers and underwriters equipped with in-depth
                                  industry knowledge, so that you make the informed decision.
            </p>
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="about-card">
                              <h4>Support 24x7</h4>
                              <p>Riskovery is glad to help you out at any given point of time.</p>
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="about-card">
                              <h4>Exclusive offers & rates</h4>
                              <p>
                                  Along with convenience and ease, we provide investors with best
                                  and exclusive rates. Our highly competitive rates offer amazing
                                  value to our esteemed customers.
            </p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      
      <div className="about-section">
        <div className="row  ">
          <div className="col-md-4 d-flex justify-content-center">
            <div className="our">
              <h1>Our Vision</h1>
              <p>
                “ To constantly deliver improved insurance solutions to our
                clients en route our journey to being the country’s most revered
                insurance web aggregators. ”
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <img
              src={require("./assets/img/handshake.png")}
              className="about-img"
            />
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="our">
              <h1>Our Mission</h1>
              <p>
                “ To offer our clients the best consulting and insurance web
                aggregators services endowed with an umbrella of risk management
                and advisory services. ”
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default About;
