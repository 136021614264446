import React, { useState } from "react";
import { ChildBtn } from "../ButtonClass";
class Call extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isActive:false,
     }
     this.openCall=this.openCall.bind(this)
  }
  openCall(){
    this.setState({isActive:!this.state.isActive})
  }
  render() { 

  return (
    <>
      <span onClick={this.openCall} style={{color:"white"}}>
        Click to Call
      </span>

     {this.state.isActive?
       <div className="modal fade MTB-Insured-HH ui-draggable show" id="ClickToCall" data-backdrop="static" role="dialog">
     <div className="modal-dialog newModalqt">
        
         <div className="modal-content">
             <div className="modal-header text-center ui-draggable-handle">
                 Click To Call
                 <button type="button" className="close" data-dismiss="modal" onClick={this.openCall}>×</button>
             </div>
             <div className="modal-body">
                 <div className="choose-relative">
                     <div className="form-group">
                         <label className="">
                             <select id="clcInsuranceName" className="FormTagsBox" name="EnquiryTypeList">
                                 <option value="">Interested In</option>
                                 <option value="Car Insurance">Car Insurance</option>
                                 <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                 <option value="Health Insurance">Health Insurance</option>
                                 <option value="Travel Insurance">Travel Insurance</option>
                             </select>
                             <span className="VldclcInsuranceName"></span>
                         </label>
                     </div>
                     <div className="form-group">
                         <label className="">
                             <input type="tel" id="clcMobile" className="FormTagsBox" onkeypress="return isNumber(event)" placeholder="Mobile Number" maxlength="10" />
                             <span id="VldclcMobile"></span>
                         </label>
                     </div>
                 </div>
             </div>
                          <div className="modal-footer text-center">
                              <div className="col-md-4"><input type="button" className={ChildBtn()} id="clickToCall" value="Call" /></div>
                 
             </div>
         </div>
     </div>
 </div>:null}
    </>
  );
}};
export default Call;
