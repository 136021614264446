import React from "react";
const Dedicated = (props) => {
  return (
      <section className="safeSecure pt-5 pb-5 bg-light">
          <div className="container">
              <h3 className="my_titleAllHead __orange">The STARiNSURED Way</h3>
              <p>
                  STARiNSURED is one-stop-solution for comparing Insurance and selecting right policy plan through its easy-to-use features. Calculate competitive quote; compare among top insurers along with several products and buy policy in just a few clicks. Simple designed platform, cutting-edge technology and a supportive advisory team help customers in understanding what insurance is best for them. Being a cluster of tech folk, STARiNSURED believes that buying insurance is not all about avoiding risk, it is also about getting best premium deal on their policy. So, the company brings easy and transparent platform to solve problem of buying insurance quickly and less painful. No false promise, no spam or insistent deal, customer centric approach, strong long-standing and respected relationships make the company most reliable sought out Insurance company across the realm. A right place to invest an individual�s valuable time and money in terms of high satisfactory services. Best coverage, assured services, easy and complete claim settlement. Get best insurance services for Car Insurance, Travel Insurance, Health Insurance and Two Wheeler Insurance!
          </p>
          </div>
      </section>
    );
};
export default Dedicated;
