/* eslint-disable */
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "../Contents/css/select2.min.css";
import { Redirect } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import GotQuotes from "./GotQuotes";
import ButtonClass from "../ButtonClass";
import NcbManage from "./NcbManage";
import axios from "axios";
import { MiddleSection } from "../ComponentManager";
import Brand from "../components/Brand/Brand";
export class Renew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CarList: [],
            ManufactureList: [],
            FilterManufactureList: [],
            ManufactureID: null,
            ManuVehicleList: [],
            FilterManuVehicleList: [],
            ManuVehicleID: null,
            manuVehicleText: null,
            isManu: 3,
            ManufactureName: null,
            ManufactureModelName: null,
            CarListMaster: [],
            FuelList: [],
            FuelListMaster: [],
            VariantList: [],
            VariantListMaster: [],
            RTOList: [],
            RTOListMaster: [],
            YearList: [],
            YearListMaster: [],
            CarVal: null,
            FuelVal: null,
            VariantVal: null,
            RTOVal: null,
            YearVal: null,
            MobileNo: null,
            IsReNew: true,
            PolicyType: "R",
            RegistrationDate: null,
            PreviousPolicyExpiredDate: null,
            ManufactureDate: null,
            PastYearClaimID: false,
            //PastYearClaimID: "N",
            Organisation: false,
            TypeOfPolicy: 1,
            PACOVERYEAR: 1,
            BindPreviousNCB: "20",
            Organisation: false,
            MyPolicyexpired: false,
            Previouspolicydetails: false,
            ManufacturerID: 0,
            EnquiryNo: null,
            ISPACOVERODSELECTED: false,
            IsGetQuote: false,
            HoldRequestData: null,
            APIUrl: null,
            UserID: 0,
            ClientID: null,
            CRMAPI: null,
            MotorType: null,
            SelectMotor: null,
            fillRenew: false,
            ClaimAmount: 0,
            carName: "",
            Model: "",
            RTO: "",
            fuil: "",
            newMotor: true,
            CarNameList: [],
            CarValList: [],
            FuelNameList: [],
            FuelValList: [],
            VarientNameList: [],
            VariantIDList: [],
            RTONameList: [],
            RTOIDList: [],
            isOpen: false,
            abc: null,
            filteredData: [],
            Previouspolicydetails: false,
            PrevPolicyType: 1,
            Index: 0,
            IsShowList: false,
            SelectedMotor: null,
            SelectedVariant: null,
            SelectedRTO: null,
            SelectedYear: null,
            showCar: false,
            showVariant: false,
            showRTO: false,
            showYear: false,
            minYearNew: null,
            maxYearNew: null,
            BtnValue: "Get Quote",
            Manu_Month: 0,
            Manu_Year: 0,
            CommercialInsurerList: [],
            CommercialInsurer: null,
            vehicleName:null,
        };
        // this.state.APIUrl = MasterGlobal.PortalAPI;
        // this.state.CRMAPI = MasterGlobal.CRMAPI;
        // 

        this.ClickNew = this.ClickNew.bind(this)
        this.state.APIUrl = window.localStorage.getItem("portal");
        this.state.CRMAPI = window.localStorage.getItem("crm");
        this.setState({
            APIUrl: this.state.PortalAPI,
            CRMAPI: this.state.CRMAPI,
        });
        this.pageLoad = this.pageLoad.bind(this)
        this.pageLoad();
        this.setList = this.setList.bind(this)
        this.handle = this.handle.bind(this)
        this.manageSearch = this.manageSearch.bind(this)
        this.manageClick = this.manageClick.bind(this)
        //  window.localStorage.setItem("url",this.state.APIUrl);
        //  if(this.state.APIUrl===null || this.state.APIUrl==="")
        //  this.state.APIUrl=window.localStorage.getItem("url");
    }




    getDomain(hostname) {
        return hostname.substr(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1) + 1)
    }
    componentDidMount() {
        let tabID;
        let contentID;
        if (!(window.location.href.match("bimastreet") /*|| window.location.href.match("localhost")*/)) {
            if (this.state.MotorType < 2) {
                tabID = document.getElementById("tabHome");
                contentID = document.getElementById("home");
                tabID.classList.add("active");
                contentID.classList.add("active");
            }
            else {
                tabID = document.getElementById("tabComm");
                contentID = document.getElementById("Comm");
                tabID.classList.add("active");
                contentID.classList.add("active");
            }
        }
    }
    pageLoad() {
        let querystring = new URLSearchParams(window.location.search);
        let boughtNew = window.location.pathname

        this.ClickNew(boughtNew)



        this.state.MotorType = querystring.get("type");
        let type = this.state.MotorType == 1 ? "Car" : this.state.MotorType == 2 ? "PCV" : this.state.MotorType == 3 ? "GCV" : "Two Wheeler";
        this.state.SelectMotor = type;

        this.setState({
            MotorType: this.state.MotorType,
            SelectMotor: type,

        });
        if (this.state.MotorType == 2) {
            this.state.CommercialInsurerList = JSON.parse(window.sessionStorage.getItem("PCVInsurerList"));
            this.setState({ CommercialInsurerList: this.state.CommercialInsurerList });
        } else if (this.state.MotorType == 3) {
            this.state.CommercialInsurerList = JSON.parse(window.sessionStorage.getItem("GCVInsurerList"));
            this.setState({ CommercialInsurerList: this.state.CommercialInsurerList });
        }
        let model = {
            CurrentUrl: window.location.hostname,
        };
        fetch("/bpi/WeatherForecast/GetAPIUrls", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(model),
        })
            .then((response) => response.json())
            .then((data) => {

                window.sessionStorage.setItem("userID", data.userIDKotak)
                window.sessionStorage.setItem("password", data.passWordKotak)
            });
        if (this.state.MotorType < 2) {
            this.CallGetMethod(
                "https://centralapi.stariglocal.com/api/Vehicle/GetVehiclesByType?type=" + type,
                "CarList"
            );
        }




        let urllist = window.location.hostname.match("localhost") ? this.state.APIUrl : window.location.hostname;
        if (urllist.match("inshora")) {
            urllist = "inshoracover.com"
        }
        if (urllist.match("www")) {
            urllist = urllist.replace("www.", "");
        }
        urllist = urllist.replace("https://", "")
        let rq = {
            URL: urllist

        }

        let Model = {
            URL: this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq)
        }
        try {
            fetch("/bpi/WeatherForecast/CallCheck", {
                method: "POST",
                body: JSON.stringify(Model),
                headers: { "Content-Type": "application/json" },
            })
                .then((Response) => Response.json())
                .then((Res) => {
                    this.setState({ ClientID: Res.id })
                    this.state.ClientID = Res.id;
                    window.sessionStorage.setItem("logo", Res.companyLogo);
                    if (Res.id === 35) {
                        let newType = this.state.MotorType == 1 ? "Car" : this.state.MotorType == 2 ? "PCV" : this.state.MotorType == 3 ? "GCV" : "Bike";
                        let manModel = {
                            URL: "http://centralapi.stariengineering.com/api/Vehicle/GetManufactureByVehicle?Type=" + newType
                        }

                        axios.post("/bpi/WeatherForecast/CallGet", manModel)
                            .then(manufacture => this.setState({ ManufactureList: manufacture.data, FilterManufactureList: manufacture.data }))
                    }
                });
        } catch (err) {
            console.log(err)
        }

        let qr = window.sessionStorage.getItem("QuoteResponse");
        let qreq = window.sessionStorage.getItem("QuoteRequest")
        if (qr !== null && qreq !== null) {
            window.sessionStorage.removeItem("QuoteResponse");
            window.sessionStorage.removeItem("QuoteRequest");
        }
        let data = window.sessionStorage.getItem("MotorproposalForm");
        if (data != null) {
            window.sessionStorage.removeItem("MotorproposalForm")
        }
        window.sessionStorage.removeItem("wishlist")

    }

    setList() {
        this.setState({ isOpen: !this.state.isOpen })
        this.setState({ filteredData: this.state.CarList })
    }
    filterMethod(list, query) {
        let vall = list.filter(option => option.Manu_Vehicle.toLowerCase().includes(query.toLowerCase()));
        return vall
    }
    handle = (e) => {



        if (
            e.target.name == "Organisation" ||
            e.target.name == "MyPolicyexpired" ||
            e.target.name == "Previouspolicydetails"
        ) {
            this.setState({
                [e.target.name]: e.target.checked,
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        switch (e.target.name) {
            case "CommercialInsurer":
                let type = this.state.MotorType == 2 ? "PCV" : "GCV";
                this.CallGetMethod(this.state.APIUrl + "/commercial/api/api/Vehicle/GetVehiclesByTypeAndCompany?type=" + type + "&Compid=" + e.target.value,
                    "CarList");
                this.tabChange("tabComm", "tabHome", "Comm", "home");
                break;
            case "Organisation": this.state.Organisation = e.target.value == "on" ? true : false;
                break;
            case "abc":

                let val = this.filterMethod(this.state.CarList, e.target.value);
                this.setState({ filteredData: val })


                break;
            case "CarVal":
                this.CallGetMethod(

                    "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" +
                    e.target.value,
                    "FuelList"
                );
                if (e.target.value > 0) {
                    let carName = this.state.CarList.filter(
                        (row) => row.VehicleID == e.target.value
                    )[0];
                    this.setState({ carName: carName.Manu_Vehicle })
                } else { return alert("Please Select a vehicle") }

                let side1 = document.getElementById("tabHome")
                side1.classList.replace("active", "enable")
                let nextSide1 = document.getElementById("tabProfile")
                nextSide1.classList.add("active")
                let Tab1 = document.getElementById("home");
                Tab1.classList.remove("active");
                let nextTab1 = document.getElementById("profile");
                nextTab1.classList.add("active");



                break;
            case "FuelVal": let apipath;
                if (this.state.MotorType > 1)
                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetVariantsByVehicleAndFuelAndCompany?VehicleID=" + this.state.CarVal + "&FuelID=" + e.target.value;
                else apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" + this.state.CarVal + "&FuelID=" + e.target.value
                this.CallGetMethod(

                    apipath,

                    "VariantList"
                );

                if (e.target.value != 0) {
                    let fuil = this.state.FuelList.filter(
                        (row) => row.FuelID == e.target.value
                    )[0];

                    this.setState({ fuil: fuil.FuelName })


                } else { return alert("Please Select Fuel") }


                //this.setState({fuil:carName.Manu_Vehicle})
                let side2 = document.getElementById("tabProfile")
                side2.classList.replace("active", "enable")
                let nextSide2 = document.getElementById("tabModel")
                nextSide2.classList.add("active")
                let Tab2 = document.getElementById("profile");
                Tab2.classList.remove("active");
                let nextTab2 = document.getElementById("Model");
                nextTab2.classList.add("active");
                break;
            case "VariantVal":
                this.CallGetMethod(
                    "https://centralapi.stariglocal.com/api/api/RTO/GetRtos",
                    "RTOList"
                );

                if (e.target.value > 0) {
                    let Model = this.state.VariantList.filter(
                        (row) => row.VariantID == e.target.value
                    )[0];
                    this.setState({ Model: Model.VariantName })
                }
                else return alert("Please Select")
                let side3 = document.getElementById("tabModel")
                side3.classList.replace("active", "enable")
                let nextSide3 = document.getElementById("tabRto")
                nextSide3.classList.add("active")
                let Tab3 = document.getElementById("Model");
                Tab3.classList.remove("active");
                let nextTab3 = document.getElementById("Rto");
                nextTab3.classList.add("active");
                break;
            case "RTOVal":

                if (e.target.value > 0) {
                    let RTO = this.state.RTOList.filter(
                        (row) => row.RTOID == e.target.value
                    )[0];
                    this.setState({ RTO: RTO.RTOName })
                } else return alert("please select")
                let side4 = document.getElementById("tabRto")
                side4.classList.replace("active", "enable")

                if (this.state.IsReNew) {

                    this.makeYearList();
                    let nextSide4 = document.getElementById("tabMessages")
                    nextSide4.classList.add("active")
                    let nextTab4 = document.getElementById("messages");
                    nextTab4.classList.add("active");
                } else {
                    let nextSide5 = document.getElementById("tabMyNumberpop")
                    nextSide5.classList.add("active")
                    let nextTab5 = document.getElementById("myNumberpop");
                    nextTab5.classList.add("active");
                }
                let Tab4 = document.getElementById("Rto");
                Tab4.classList.remove("active");

                break;
            case "YearVal":
                if (e.target.value <= 0) return alert("Please Select");
                let side5 = document.getElementById("tabMessages")
                side5.classList.replace("active", "enable")
                let nextSide5 = document.getElementById("tabMyNumberpop")
                nextSide5.classList.add("active")
                let Tab5 = document.getElementById("messages");
                Tab5.classList.remove("active");
                let nextTab5 = document.getElementById("myNumberpop");
                nextTab5.classList.add("active");
                break;
            case "Previouspolicydetails":
                this.setState({ MyPolicyexpired: e.target.checked, Previouspolicydetails: !this.state.Previouspolicydetails });
                break;
            case "PastYearClaimID":
                this.setState({ PastYearClaimID: !this.state.PastYearClaimID });
                break;
            case "ISPACOVERODSELECTED":
                this.setState({
                    ISPACOVERODSELECTED: !this.state.ISPACOVERODSELECTED,

                });
                break;

        }

    };
    makeYearList() {
        let date = new Date();
        let Currentyear = date.getFullYear();
        let lstYear = [];
        for (let i = Currentyear; i > Currentyear - 16; i--) {
            lstYear.push(i);
        }
        this.setState({ YearList: lstYear });
    }
    CallGetMethod(Url, prp) {
        let model = {
            URL: Url
        }
        axios.post("/bpi/WeatherForecast/CallGet", model)
            .then(Res => {
                this.setState({ [prp]: Res.data });
                console.log(Res.data)
            });

    }
    ClickNew(param) {

        if (param == "/New") {
            this.state.IsReNew = false;
            this.state.PolicyType = "N"


        }
        else
            this.setState({
                IsReNew: true,
                PolicyType: "R",
            });
    }
    checkForm = () => {

    }
    getQuote = (e) => {
        if (this.state.MobileNo == null || this.state.MobileNo.length < 10)
            return alert("Enter Mobile Number")
        this.setState({ BtnValue: "Wait..." })
        var pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

        if (this.state.CarVal === null)
            alert("please fill car value")
        else if (this.state.FuelVal === null)
            alert("please fill fuel value")
        else if (this.state.VariantVal === null)
            alert("please fill variant value")
        else if (this.state.RTOVal === null)
            alert("please fill rto value")
        if (this.state.IsReNew) {
            if (this.state.YearVal === null)
                alert("please fill year value")
        }


        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            // Token = JSON.parse(Token);
            let parseToken = false;
            if (Token.charAt(0) === "{")
                parseToken = true;

            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                PostString: JSON.stringify(data)
            }

            axios.post("/bpi/WeatherForecast/CallCheck", md)
                .then((Res) => {
                    this.state.UserID = Res.data.userID;
                    this.setState({ UserID: Res.data.userID });
                    window.sessionStorage.setItem("userData", JSON.stringify(Res))
                    this.QuoteFirst();

                });

        } else {
            this.QuoteFirst();
        }


    };
    QuoteFirst() {

        let date = new Date();
        let manufactureData = this.state.MotorType < 2 ? (this.state.ClientID === 35 ? this.state.ManuVehicleList.filter(
            (row) => row.VehicleID == this.state.CarVal
        )[0] : this.state.CarList.filter(
            (row) => row.VehicleID == this.state.CarVal
        )[0]) : this.state.CarList.filter(
            (row) => row.CombindID == this.state.CarVal
        )[0];
        let YearVal = this.state.YearVal;
        if (!this.state.IsReNew) {
            let dd = new Date();
            YearVal = dd.getFullYear()
        }
        let manufacture = "";
        try {
            manufacture = manufactureData.ManufacturerID;
        } catch (err) { }
        let Req = {
            MobileNo: this.state.MobileNo,
            EnquiryDate: date,
            EnquiryType: (this.state.MotorType == 0 || this.state.MotorType == 1) ? "MOT" : "Commercial",
            MotorEnquiry: {
                ManufactureID: manufacture,
                VehicleID: this.state.CarVal,
                VariantID: this.state.VariantVal,
                FuelID: this.state.FuelVal,
                RegistartionYear: YearVal,
                RTOID: this.state.RTOVal,
                PolicyType: this.state.PolicyType,
                MotorType: this.state.MotorType == 1 ? "Car" : this.state.MotorType == 2 ? "PCV" : this.state.MotorType == 3 ? "GCV" : "Two",
            },
            UserID: this.state.UserID,
            LeadSource: "online",
            ClientID: this.state.ClientID,
        };
        //Car.CompData = [];
        //Car.CompData.push(Req);
        let CompModel = {
            Vehicle: this.state.ClientID === 35 ? (this.state.ManufactureName + " " + this.state.ManufactureModelName) : manufactureData.Manu_Vehicle,
            variant: this.state.MotorType < 2 ? this.state.VariantList.filter((row) => row.VariantID == this.state.VariantVal)[0].VariantName :
                this.state.VariantList.filter((row) => row.CombindID == this.state.VariantVal )[0].VariantName,
            RTOName: this.state.RTOList.filter(
                (row) => row.RTOID == this.state.RTOVal
            )[0].RTOName,
            NewOrRenew: !this.state.IsReNew ? "New" : "Renew",
        };

        //Car.CompData.push(CompModel);
        this.state.HoldRequestData = Req;
        this.state.HoldRequestData.CompModel = CompModel;
        this.setState({ HoldRequestData: this.state.HoldRequestData });
        let apipath;
        if (this.state.MotorType < 2) {

            apipath = this.state.APIUrl + "/api/api/Enquiry/AddEnquiry";

        } else {
            apipath = this.state.APIUrl + "/commercial/api/api/Enquiry/AddEnquiry";
        }
        console.log(JSON.stringify(Req))
        let Model = {
            URL: apipath,
            PostString: JSON.stringify(Req)
        }
        fetch("/bpi/WeatherForecast/CallCheck", {
            method: "POST",
            body: JSON.stringify(Model),
            headers: { "Content-Type": "application/json" },
        })
            .then((Response) => Response.text())
            .then((Res) => {

                let date = new Date();
                let yy = date.getFullYear()
                let month =
                    date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1;
                let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                let RegistrationDate = YearVal + "-" + month + "-01";
                let PreviousPolicyExpiredDate =
                    date.getFullYear() + "-" + month + "-" + day;
                //Res.split("~")[0].replace('\"', "")
                this.setState({
                    ManufacturerID: manufacture,
                    EnquiryNo: Res.split("~")[0].replace('\"', ""),
                    RegistrationDate: RegistrationDate,
                    ManufactureDate: RegistrationDate,
                    PreviousPolicyExpiredDate: PreviousPolicyExpiredDate,
                    BtnValue: "Get Quote"
                });

                //this.state.IsGetQuote = true;
                this.CallGetQuote();
            });




    }
    CallGetQuote = (e) => {

        this.GetQuotation(this.state.ManufacturerID, this.state.EnquiryNo);
    };
    GetQuotation(manufactureID, EnquiryNo) {
        if (EnquiryNo == null || EnquiryNo == "" || EnquiryNo.match("error") || EnquiryNo.length<5) {
            return;
        }
        let expirydate = this.state.PreviousPolicyExpiredDate;


        let fullDate = null;
        if (this.state.MyPolicyexpired) {
            let date = new Date(expirydate);
            let newDate = new Date(date.setDate(date.getDate() - 91))
            date = newDate
            let month = date.getMonth() + 1 <= 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            let day = date.getDate() <= 10 ? "0" + date.getDate() : date.getDate();
            fullDate = month + "-" + day + "-" + date.getFullYear();

        } else fullDate = expirydate;
        let Req;
        if (this.state.IsReNew) {
            Req = {
                IsReNew: this.state.IsReNew,
                VehicleID: this.state.MotorType < 2 ? this.state.CarVal : this.state.CarList.filter(row => row.CombindID == this.state.CarVal)[0].VehicleID+"",
                VariantID: this.state.MotorType < 2 ? this.state.VariantVal : this.state.VariantList.filter(row => row.CombindID == this.state.VariantVal)[0].VariantID+"",
                FUELID: this.state.MotorType < 2 ? this.state.FuelVal : this.state.FuelList.filter(row => row.CombindID == this.state.FuelVal)[0].FuelID+"",
                YearVal: this.state.YearVal,
                RtoID: this.state.RTOVal+"",
                MyPolicyexpired: this.state.MyPolicyexpired,
                ExpMoreThan90Days: this.state.MyPolicyexpired,
                RegistrationYear: this.state.RegistrationDate,
                Manu_Month: this.state.RegistrationDate.split("-")[1],
                Manu_Year: this.state.YearVal,
                Clientid: this.state.ClientID,
                ClientURL: this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl,
                CityID: "0",
                StateID: "0",
                RtoCode: this.state.RTOList.filter(
                    (row) => row.RTOID == this.state.RTOVal
                )[0].RTOCode,
                NomineeRelationID: "0",
                OccupationID: "0",
                PreviousInsurerCompanyID: "",
                Type: (this.state.MotorType == 1 ? 1 : this.state.MotorType == 2 ? 3 : this.state.MotorType == 3 ? 4 : 0)+"",
                VehicleType: this.state.MotorType,
                ManufacturerID: manufactureID+"",
                RtoLocation: "",
                FuelName: this.state.FuelList.filter(
                    (row) => row.FuelID == parseInt(this.state.FuelVal)
                )[0].FuelName,
                IsUat_Production: "",
                PreviousPolicyExpiredDate: fullDate,
                ManufactureDate: this.state.RegistrationDate,
                PastYearClaimID: this.state.PastYearClaimID ? "Y" : "N",
                TypeOfPolicy: 1+"",
                IDVLpgCng: 0,
                IDVElectrical: 0,
                IDVNonElectrical: 0,
                //Addons
                chkConsumables: false,
                ConsumablesAmt: 0,
                chkEngineProtector: false,
                EngineProtectorAmt: 0,
                chkDepreciation: false,
                ZeroDepreciationAmt: 0,
                chkRoadSideAssistance: false,
                RoadSideAssistanceAmt: 0,
                Organisation: this.state.Organisation,
                chkReturnToInvoice: false,
                ReturnToInvoiceAmt: 0,
                chkPaidDriver: false,
                papaiddriverAmt: 0,
                chkTyreSecure: false,
                TyreSecureAmt: 0,
                chkKeyReplacement: false,
                KeyReplacementAmt: 0,
                chkNCBProtection: false,
                NCBProtectionAmt: 0,
                chkInconvenienceAllowance: false,
                InconvenienceAllowanceAmt: 0,
                chkTPPDcover: false,
                TPPDcoverAmt: 0,
                chkPassangerAssistance: false,
                PassangerAssistanceAmt: 0,
                ClaimAmount: 0,
                chkPassengerCover: false,
                PassengerCoverAmt: 0,
                PassengerCoverSelectedIDV: 0,
                //*******END*******************
                ISPACOVERODSELECTED: this.state.ISPACOVERODSELECTED ? "YES" : "NO",
                PACOVERYEAR: (this.state.ISPACOVERODSELECTED ? this.state.PACOVERYEAR : 0)+"",
                IsAntiTheft: false,
                ThirdPartyCover: this.state.PrevPolicyType == 2 ? true : false,
                IDV: 0,
                MobileNo: this.state.MobileNo,
                BindPreviousNCB:
                    this.state.MyPolicyexpired || this.state.PastYearClaimID
                        ? 0
                        : this.state.BindPreviousNCB,
                PreviousNCB: this.state.MyPolicyexpired || this.state.PastYearClaimID
                    ? 0
                    : this.state.BindPreviousNCB,
                IsPreviousZeroDepthTaken: false,
                ComprehensiveforThree_FiveYear: false,
                IsCNG_LPG: "N",
                VoluntaryAmount: 0,
                AutoMobileMembership: "false",
                AutoMobileMembershipName: "",
                AutoMobileMembershipNumber: "",
                AutoMobileMembershipDate: "",
                EnquiryNo: EnquiryNo,
                PrevPolicyType: this.state.PrevPolicyType+"",
                vRanKey: this.state.vRanKey,
                vEncryptedLogin: this.state.vEncryptedLogin,
                vEncryptedPassword: this.state.vEncryptedPassword,
                AllCompIDVs: null,
                Period: 1,
                DepriciationPercentage: 0,
                odOnly: this.state.PrevPolicyType == 3 ? true : false,
                DetariffRate: "",
                IsCorporate: this.state.Organisation ? true : false,
                IsPrevPolicyDetails: this.state.Previouspolicydetails,
            }
        }
        else {
            let date = new Date();
            let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            let mm = date.getMonth() + 1 < 10 ? "0" + date.getMonth() : date.getMonth()
            let yy = date.getFullYear();
            date = yy + "-" + mm + "-" + "01"
            Req = {

                IsReNew: this.state.IsReNew,
                VehicleID: this.state.MotorType < 2 ? this.state.CarVal : this.state.CarList.filter(row => row.CombindID == this.state.CarVal)[0].VehicleID,
                VariantID: this.state.MotorType < 2 ? this.state.VariantVal : this.state.VariantList.filter(row => row.CombindID == this.state.VariantVal)[0].VariantID,
                FUELID: this.state.MotorType < 2 ? this.state.FuelVal : this.state.FuelList.filter(row => row.CombindID == this.state.FuelVal)[0].FuelID,
                RtoID: this.state.RTOVal,
                MyPolicyexpired: "",
                RegistrationYear: this.state.ManufactureDate == null ? date : this.state.ManufactureDate,
                Clientid: "",
                ClientURL: this.state.APIUrl.match("inshora") ? "https://inshoracover.com" : this.state.APIUrl,
                CityID: "0",
                StateID: "0",
                RtoCode: this.state.RTOList.filter(
                    (row) => row.RTOID == this.state.RTOVal
                )[0].RTOCode,
                NomineeRelationID: "0",
                OccupationID: "0",
                PreviousInsurerCompanyID: "",
                Type: this.state.MotorType < 2 ? this.state.MotorType : "3",
                VehicleType: this.state.MotorType,
                ManufacturerID: manufactureID,
                RtoLocation: "",
                FuelName: this.state.FuelList.filter(
                    (row) => row.FuelID == parseInt(this.state.FuelVal)
                )[0].FuelName,
                IsUat_Production: "",
                PreviousPolicyExpiredDate: "",
                ManufactureDate: this.state.ManufactureDate == null ? date : this.state.ManufactureDate,
                PastYearClaimID: "",
                TypeOfPolicy: 0,
                IDVLpgCng: 0,
                IDVElectrical: 0,
                IDVNonElectrical: 0,
                //Addons
                chkConsumables: false,
                ConsumablesAmt: 0,
                chkEngineProtector: false,
                EngineProtectorAmt: 0,
                chkDepreciation: false,
                ZeroDepreciationAmt: 0,
                chkRoadSideAssistance: false,
                RoadSideAssistanceAmt: 0,
                Organisation: this.state.Organisation,
                chkReturnToInvoice: false,
                ReturnToInvoiceAmt: 0,
                chkPaidDriver: false,
                papaiddriverAmt: 0,
                chkTyreSecure: false,
                TyreSecureAmt: 0,
                chkKeyReplacement: false,
                KeyReplacementAmt: 0,
                chkNCBProtection: false,
                NCBProtectionAmt: 0,
                chkInconvenienceAllowance: false,
                InconvenienceAllowanceAmt: 0,
                chkTPPDcover: false,
                TPPDcoverAmt: 0,
                chkPassangerAssistance: false,
                PassangerAssistanceAmt: 0,
                ClaimAmount: 0,
                chkPassengerCover: false,
                PassengerCoverAmt: 0,
                PassengerCoverSelectedIDV: 0,
                //*******END*******************
                ISPACOVERODSELECTED: this.state.ISPACOVERODSELECTED ? "YES" : "NO",
                PACOVERYEAR: this.state.ISPACOVERODSELECTED ? this.state.PACOVERYEAR : 0,
                IsAntiTheft: false,
                ThirdPartyCover: false,
                IDV: 0,
                MobileNo: this.state.MobileNo,
                BindPreviousNCB: "",
                IsPreviousZeroDepthTaken: false,
                ComprehensiveforThree_FiveYear: false,
                IsCNG_LPG: "N",
                VoluntaryAmount: 0,
                AutoMobileMembership: "false",
                AutoMobileMembershipName: "",
                AutoMobileMembershipNumber: "",
                AutoMobileMembershipDate: "",
                EnquiryNo: EnquiryNo,
                PrevPolicyType: "",
                vRanKey: this.state.vRanKey,
                vEncryptedLogin: this.state.vEncryptedLogin,
                vEncryptedPassword: this.state.vEncryptedPassword,
                AllCompIDVs: null,
                Period: 1,
                DepriciationPercentage: 0,
                odOnly: false,
                DetariffRate: "",
                IsCorporate: this.state.Organisation ? true : false,
                IsPrevPolicyDetails: false,
            }
        }
        this.state.HoldRequestData.QuoteReq = Req;

        window.localStorage.setItem("ProceedWithSame", JSON.stringify(this.state.HoldRequestData));

        this.props.history.push("/GetQuote", { Req: this.state.HoldRequestData });

        window.sessionStorage.setItem("QuoteRequest", JSON.stringify(this.state.HoldRequestData))
        /*fetch("https://smc.smcinsurance.com/api/api/InsurerMotor/GetALLQuotationV2", {
                method: "POST",
                body: JSON.stringify(Req),
                headers: { "Content-Type": "application/json" }
            }).then(response => response.json()).then(data => {
                console.log(data)
            })*/
    }
    close = (e) => {
        this.setState({ IsGetQuote: false });
    };
    closeBtn = (e) => {
        const id = document.getElementById("renewProduct");
        id.style.display = "none";
        this.setState({ fillRenew: true })

    };
    manageTab(e) {
        let removeClass = Array.from(document.querySelectorAll(".tab-pane"))
        removeClass.forEach((node) => {
            node.classList.remove("active")
        })

        switch (e.target.id) {
            case "tabComm": let tab0 = document.getElementById("Comm");
                tab0.classList.add("active")
                break;
            case "tabHome": let tab1 = document.getElementById("home");
                tab1.classList.add("active")
                break;
            case "tabProfile": let tab2 = document.getElementById("profile");
                tab2.classList.add("active")
                break;
            case "tabModel": let tab3 = document.getElementById("Model");
                tab3.classList.add("active")
                break;
            case "tabRto": let tab4 = document.getElementById("Rto");
                tab4.classList.add("active")
                break;
            case "tabMessages": let tab5 = document.getElementById("messages");
                tab5.classList.add("active")
                break;
            case "tabMyNumberpop": let tab6 = document.getElementById("myNumberpop");
                tab6.classList.add("active")
                let id = document.getElementById("leftTab");
                id.style.display = "none";
                let anotherId = document.getElementById("rightTab");
                anotherId.classList.replace("col-sm-9", "col-sm-12");
                break;
            default: let dtab5 = document.getElementById("messages");
                dtab5.classList.add("active")
        }
    }
    // tabMan(){
    //   let id=document.getElementById("myDiv");
    //   id.classList.add("productHero--active")
    // }
    /*------------------------------------------*/
    searchFilter = (e) => {

        let currenttxtID = e.target.id;
        let filterList = [];
        this.setState({ Index: 0 });
        switch (currenttxtID) {
            case "searchManuVehicle":
                filterList = this.state.ManuVehicleList.filter(row => row.VehicleName.toLowerCase().includes(document.getElementById("searchManuVehicle").value.toLowerCase()));
                this.setState({ FilterManuVehicleList: filterList, manuVehicleText: e.target.value })
                break;
            case "searchManufacture":
                filterList = this.state.ManufactureList.filter(row => row.ManufacturerName.toLowerCase().includes(document.getElementById("searchManufacture").value.toLowerCase()));
                this.setState({ FilterManufactureList: filterList })
                break;
            case "searchtext":

                filterList = this.state.CarList.filter(row => row.Manu_Vehicle.toLowerCase().includes(document.getElementById("searchtext").value.toLowerCase()));
                this.setState({ CarListMaster: filterList, FuelVal: null })
                break;

            case "searchvariant":

                filterList = this.state.VariantList.filter(row => row.VariantName.toLowerCase().includes(document.getElementById("searchvariant").value.toLowerCase()));
                this.setState({ VariantListMaster: filterList })
                break;
            case "searchrto":

                filterList = this.state.RTOList.filter(row => row.RTOName.toLowerCase().includes(document.getElementById("searchrto").value.toLowerCase()));
                this.setState({ RTOListMaster: filterList })
                break;
            case "searchyear":

                filterList = this.state.YearList.filter(row => row.toString().includes(document.getElementById("searchyear").value.toLowerCase()));
                this.setState({ YearListMaster: filterList })
                break;
            case "FuelVal":
                let apipath;
                if (this.state.MotorType > 1)
                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetVariantsByVehicleAndFuelAndCompany?VehicleID=" + this.state.CarVal + "&FuelID=" + e.target.value;
                else apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetVariantsByVehicleAndFuel?VehicleID=" + this.state.CarVal + "&FuelID=" + e.target.value
                this.CallGetMethod(

                    apipath,

                    "VariantList"
                );
                this.state.ClientID !== 35 && this.tabChange("tabProfile", "tabModel", "profile", "Model");
                this.setState({ FuelVal: e.target.value, VariantVal:null })

                if (e.target.value != 0) {


                    this.setState({ fuil: e.target.value.split("~")[1] })

                    break;

                }
        }



    }
    searchdown = (e) => {
        let currenttxtID = e.target.id;
        let UlID = "";
        switch (currenttxtID) {
            case "searchtext":
                UlID = "searchul";
                break;
            case "searchvariant":
                UlID = "searchulvariant";
                break;
            case "searchrto":
                UlID = "searchulrto";
                break;
            case "searchyear":
                UlID = "searchulyear";
                break;
        }
        try {
            let selectList = document.getElementById(UlID)
            let Options = selectList.getElementsByTagName("li");

            switch (e.keyCode) {
                case 40:
                    Options[this.state.Index].className = "activeopt";
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    this.state.Index += 1;
                    this.setState({ Index: this.state.Index })
                    break;
                case 38:
                    if (this.state.Index > 0)
                        this.state.Index -= 1;
                    Options[this.state.Index].className = "activeopt";
                    Options[this.state.Index + 1].removeAttribute("class");
                    this.setState({ Index: this.state.Index })
                    break;
                case 13:
                    this.setState({ Index: 0, IsShowList: false })
                    let sectedOption = document.getElementsByClassName("activeopt");

                    let filterList = [];
                    let Text = "";
                    switch (currenttxtID) {
                        case "searchtext":
                            if (this.state.MotorType < 2)
                                filterList = this.state.CarList.filter(row => row.VehicleID == sectedOption[0].value);
                            else filterList = this.state.CarList.filter(row => row.CombindID == sectedOption[0].id);

                            if (filterList.length > 0) {
                                this.state.SelectedMotor = filterList[0];
                                Text = this.state.SelectedMotor.Manu_Vehicle;
                                let apipath;
                                this.state.FuelVal = null;
                                if (this.state.MotorType < 2) {
                                    apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" + sectedOption[0].value;
                                    this.setState({ CarVal: sectedOption[0].value, FuelVal: "0" });
                                }
                                else {
                                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetFuelByVehicleIDAndCompany?id=" + sectedOption[0].id;
                                    this.setState({ CarVal: sectedOption[0].id, FuelVal: "0" })
                                }

                                //**********Binding Fuel******************
                                this.CallGetMethod(apipath,
                                    "FuelList");


                                //**********End Binding Fuel******************
                                this.setState({ SelectedMotor: this.state.SelectedMotor })
                                this.tabChange("tabHome", "tabProfile", "home", "profile");
                            }
                            break;

                        case "searchvariant":
                            if (this.state.MotorType < 2)
                                filterList = this.state.VariantList.filter(row => row.VariantID == sectedOption[0].value);
                            else filterList = this.state.VariantList.filter(row => row.CombindID == sectedOption[0].id);
                            if (filterList.length > 0) {
                                this.state.SelectedVariant = filterList[0];
                                Text = this.state.SelectedVariant.VariantName;

                                this.setState({ SelectedVariant: this.state.SelectedVariant })
                                this.tabChange("tabModel", "tabRto", "Model", "Rto");
                                //**********************Bind RTO List******************
                                this.CallGetMethod(
                                    "https://centralapi.stariglocal.com/api/RTO/GetRtos",
                                    "RTOList"
                                );
                                this.setState({ VariantVal: this.state.MotorType < 2 ? sectedOption[0].value : sectedOption[0].id })
                                //**********************END Bind RTO List******************
                            }
                            break;
                        case "searchrto":
                            filterList = this.state.RTOList.filter(row => row.RTOID == sectedOption[0].value);
                            if (filterList.length > 0) {
                                this.state.SelectedRTO = filterList[0];
                                Text = this.state.SelectedRTO.RTOName;
                                this.setState({ SelectedRTO: this.state.SelectedRTO })
                                if (this.state.IsReNew)
                                    this.tabChange("tabRto", "tabMessages", "Rto", "messages");
                                else this.tabChange("tabRto", "tabMyNumberpop", "Rto", "myNumberpop");
                                //****************Bind Year**************
                                this.makeYearList();

                                //****************END*********************
                                this.setState({ RTOVal: sectedOption[0].value })
                            }
                            break;
                        case "searchyear":
                            filterList = this.state.YearListMaster.filter(row => row == sectedOption[0].value);
                            if (filterList.length > 0) {
                                this.state.SelectedYear = filterList[0];
                                Text = this.state.SelectedYear;
                                this.setState({ SelectedYear: this.state.SelectedYear })
                                this.state.YearVal = sectedOption[0].value
                                this.tabChange("tabMessages", "tabMyNumberpop", "messages", "myNumberpop");
                                this.setState({ YearVal: sectedOption[0].value, BindPreviousNCB: NcbManage(value) })
                            }
                            break;
                    }
                    if (this.state.Index > 0)
                        Options[this.state.Index - 1].removeAttribute("class");
                    document.getElementById(currenttxtID).value = Text;
                    break;
            }

        }

        catch (ex) { }

    }
    tabChange(tabHome, tabProfile, home, profile) {
        let side1 = document.getElementById(tabHome)
        side1.classList.replace("active", "enable")
        let nextSide1 = document.getElementById(tabProfile)
        nextSide1.classList.add("active")
        let Tab1 = document.getElementById(home);
        Tab1.classList.remove("active");
        let nextTab1 = document.getElementById(profile);
        nextTab1.classList.add("active");
    }
    manageSearch(e) {
        this.state.CarListMaster = this.state.CarList;
        this.state.VariantListMaster = this.state.VariantList;
        this.state.RTOListMaster = this.state.RTOList;
        this.state.YearListMaster = this.state.YearList;
        this.setState({ IsShowList: !this.state.IsShowList });
        switch (e.target.id) {
            case "carname": this.setState({ showCar: !this.state.showCar });
                break;
            case "variantname":
                this.setState({ showCar: false, showVariant: !this.state.showVariant })
                break;
            case "rtoname": this.setState({ showVariant: false, showRTO: !this.state.showRTO })
                this.state.YearListMaster = this.state.YearList
                break;
            case "year": this.setState({ showRTO: false, showYear: !this.state.showYear })

                break;

        }
    }
    /*------------------------------------------*/
    manageClick(value, state) {
        this.setState({ [state]: value })
        this.makeYearList()
        let YearListMaster = this.state.YearList

        switch (state) {
            case "CarVal":
                let apipath;
                if (this.state.MotorType < 2) {
                    apipath = "https://centralapi.stariglocal.com/api/Vehicle/GetFuelByVehicleID/" + value;

                }
                else {
                    apipath = this.state.APIUrl + "/commercial/api/api/Vehicle/GetFuelByVehicleIDAndCompany?id=" + value;

                }

                this.CallGetMethod(apipath,
                    "FuelList");

                if (this.state.ClientID !== 35) {
                    this.tabChange("tabHome", "tabProfile", "home", "profile")
                    let carName = this.state.CarList.filter(
                        (row) => row.VehicleID == value
                    )[0];
                    this.state.FuelVal = null;
                    if (this.state.MotorType < 2)
                        this.setState({ carName: carName.Manu_Vehicle, FuelVal: "0" })
                    else this.setState({ carName: value.split("~")[1], FuelVal: "0" })
                }
                else this.setState({ ManufactureModelName: this.state.ManuVehicleList.filter(r => r.VehicleID == value)[0].VehicleName });
                this.setState({ isManu: 5 })
                break;
            case "RTOVal":
                let RTO = this.state.RTOList.filter(
                    (row) => row.RTOID == value)[0];
                this.setState({ RTO: RTO.RTOName, isManu: this.state.VariantVal !== null && this.state.YearVal !== null ? 6 : 5 })
                if (!this.state.IsReNew && this.state.VariantVal !== null) {
                    this.setState({ isManu:6})
                }
                if (this.state.ClientID !== 35) {
                    if (this.state.IsReNew)
                        this.tabChange("tabRto", "tabMessages", "Rto", "messages");
                    else {
                        let id = document.getElementById("leftTab");
                        id.style.display = "none";
                        let anotherId = document.getElementById("rightTab");
                        anotherId.classList.replace("col-sm-9", "col-sm-12");
                        this.tabChange("tabRto", "tabMyNumberpop", "Rto", "myNumberpop");
                    }
                }
                this.setState({ showRTO: !this.state.showRTO })
                break;
            case "VariantVal":
                this.CallGetMethod(
                    "https://centralapi.stariglocal.com/api/RTO/GetRtos",
                    "RTOList"
                );
                let Model = this.state.VariantList.filter(
                    (row) => row.VariantID == value
                )[0];

                this.setState({ Model: this.state.MotorType < 2 ? Model.VariantName : value.split("~")[1] })
                this.state.ClientID !== 35 && this.tabChange("tabModel", "tabRto", "Model", "Rto");

                this.setState({ showVariant: !this.state.showVariant })
                break;
            case "YearVal":
                this.state.YearVal = value;
                this.setState({ YearVal: this.state.YearVal, BindPreviousNCB: NcbManage(value), isManu: this.state.VariantVal !== null && this.state.RTOVal!==null? 6:5 })
                if (this.state.ClientID !== 35) {
                    this.tabChange("tabMessages", "tabMyNumberpop", "messages", "myNumberpop");
                    let id = document.getElementById("leftTab");
                    id.style.display = "none";
                    let anotherId = document.getElementById("rightTab");
                    anotherId.classList.replace("col-sm-9", "col-sm-12");
                }
                this.setState({ showYear: !this.state.showYear })
                break;
            case "ManufactureID":
                this.setState({ isManu: 4, ManufactureName: this.state.ManufactureList.filter(r => r.ManufacturerID === value)[0].ManufacturerName })
                let vehModel = {
                    URL: "https://centralapi.stariglocal.com/api/Vehicle/GetVehicles/" + value + "?OnlyActive=true"
                }
                
                axios.post("/bpi/WeatherForecast/CallGet", vehModel)
                    .then(manuVehicle => {
                        let newType = this.state.MotorType == 1 ? "PrivateCar" : this.state.MotorType == 2 ? "PCV" : this.state.MotorType == 3 ? "GCV" : "TwoWheeler";
                        let manuData = manuVehicle.data.filter(row => row.VehicleType === newType)
                        this.setState({ ManuVehicleList: manuData, FilterManuVehicleList: manuData }) 
                            });
                break;

        }

    }
    render() {
        let motorType = this.state.MotorType;
        let wUrl = window.location.href;
        return (
            this.state.fillRenew ? <Redirect to="/" /> :
                <div className=" " id="renewProduct" style={{ display: "unset" }}>


                    {(wUrl.match("bimastreet") /*|| wUrl.match("localhost")*/) ?
                        <section class="section wizard-section ProoductStepBima">
                            <div class="container">
                                <div class="row g-3">
                                    <div class="col-3 col-md-2 col-lg-1">
                                        <a onClick={() => this.setState({ isManu: this.state.isManu === 3 ? this.props.history.goBack() : this.state.isManu - 1 })} class="arw-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M10.05 18.45L4.35 12.75L22.5 12.75L22.5 11.25L4.35 11.25L10.05 5.55L9 4.5L1.5 12L9 19.5L10.05 18.45Z"
                                                    fill="#FFFAFA" />
                                            </svg>
                                        </a>
                                    </div>
                                    <div class="col-9 col-md-10 col-lg-3 position-relative">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <h3 class="f-32 font-regular text-secondary text-capitalize">
                                                    {this.state.isManu === 3 && `Select your ${this.state.SelectMotor} Brand`}
                                                    {this.state.isManu === 4 && `Select Your${this.state.SelectMotor} Model`}
                                                    {this.state.isManu === 5 && `Enter Your ${this.state.SelectMotor} Details`}
                                                    {this.state.isManu === 6 && "Confirm Your Details Before Proceeding"}
                                                    {this.state.isManu === 7 && "Do We Need To Know Anything Else?"}
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="pageno d-none d-lg-flex"><span>{"0" + this.state.isManu}</span> &nbsp;of 07</div>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="wizard-box">
                                            <div>
                                                {this.state.isManu === 3 && < React.Fragment >
                                                    <div>
                                                        <div class="input-group mb-4">
                                                            <span class="input-group-text" id="basic-addon1"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8901 16.0431L11.6831 10.8361C12.7928 9.4812 13.3257 7.74469 13.1671 6.00061C13.0084 4.25652 12.171 2.64451 10.8351 1.51208C9.48017 0.402101 7.74335 -0.130922 5.99902 0.0277048C4.2547 0.186331 2.64254 1.02405 1.51007 2.36022C0.40065 3.71518 -0.132124 5.45166 0.0264904 7.19567C0.185105 8.93968 1.02244 10.5516 2.35809 11.6842C3.54532 12.6814 5.0461 13.2279 6.59656 13.2279C8.14702 13.2279 9.64786 12.6814 10.8351 11.6842L16.0421 16.8912L16.8901 16.0431ZM1.1471 6.59704C1.14419 5.88065 1.2831 5.17083 1.55591 4.50842C1.82872 3.846 2.23001 3.24423 2.73657 2.73767C3.24314 2.2311 3.84497 1.82981 4.50739 1.557C5.1698 1.28419 5.87968 1.14516 6.59607 1.14807C7.31246 1.14516 8.02234 1.28419 8.68475 1.557C9.34717 1.82981 9.949 2.2311 10.4556 2.73767C10.9621 3.24423 11.3634 3.846 11.6362 4.50842C11.909 5.17083 12.048 5.88065 12.0451 6.59704C12.048 7.31343 11.909 8.02349 11.6362 8.68591C11.3634 9.34833 10.9621 9.95009 10.4556 10.4567C9.949 10.9632 9.34717 11.3645 8.68475 11.6373C8.02234 11.9101 7.31246 12.0489 6.59607 12.046C5.87968 12.0489 5.1698 11.9101 4.50739 11.6373C3.84497 11.3645 3.24314 10.9632 2.73657 10.4567C2.23001 9.95009 1.82872 9.34833 1.55591 8.68591C1.2831 8.02349 1.14419 7.31343 1.1471 6.59704Z" fill="#525252"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0">
                                                                        <rect width="16.89" height="16.89" fill="white"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            </span>
                                                            <input type="text" name="searchManufacture" id="searchManufacture" className="form-control" placeholder="Search Brand" onChange={this.searchFilter} autoFocus />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul className="manufactureList">
                                                            {this.state.FilterManufactureList.map((row, i) => {
                                                                return <li key={i} onClick={() => this.manageClick(row.ManufacturerID, "ManufactureID")}>
                                                                    <Brand ManufacturerName={row.ManufacturerName} />

                                                                </li>
                                                            })}
                                                        </ul>
                                                    </div></React.Fragment>}
                                                {this.state.isManu === 4 && < React.Fragment >
                                                    <div className="input-group mb-4">
                                                        <input type="text" name="searchManuVehicle" className="form-control" placeholder={`Search ${this.state.SelectMotor} Model`} value={this.state.manuVehicleText} id="searchManuVehicle" onChange={this.searchFilter} autoComplete="off" autoFocus /></div>
                                                    <div>
                                                        <ul className="vehicleList">
                                                            {this.state.FilterManuVehicleList.map((row, i) => {
                                                                return <li key={i} onClick={() => this.manageClick(row.VehicleID, "CarVal")}>
                                                                    {row.VehicleName}

                                                                </li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </React.Fragment>}
                                                {this.state.isManu === 5 &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="yrVehicle">
                                                                <label for="" class="form-label">Fuel type</label>
                                                                <select
                                                                    name="FuelVal"
                                                                    id="FuelVal"
                                                                    value={this.state.FuelVal}
                                                                    className="form-control"
                                                                    onChange={this.searchFilter}>
                                                                    <option value="0">Select Fuel</option>
                                                                    {this.state.FuelList.map((row) => (
                                                                        <option key={row.FuelID} value={this.state.MotorType < 2 ? row.FuelID : row.CombindID}>
                                                                            {row.FuelName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="yrVehicle">
                                                                <label for="" class="form-label">Variant</label>
                                                                <button onClick={this.manageSearch} className="searchButtonCar form-control" id="variantname">{this.state.VariantVal == null ? "--Select Variant--" : this.state.SelectedVariant != null ? this.state.SelectedVariant.VariantName : this.state.Model}</button>
                                                                {this.state.showVariant &&
                                                                    <div className="manageDrpDwn">
                                                                        <input type="text" placeholder="Search Variant" onChange={this.searchFilter} autoComplete="off"
                                                                            onKeyDown={this.searchdown}
                                                                            id="searchvariant" autoFocus />
                                                                        <ul id="searchulvariant">
                                                                            {this.state.MotorType < 2 && this.state.VariantListMaster.length > 0 && this.state.VariantListMaster.map((row) => (
                                                                                <li key={row.VariantID} value={row.VariantID} onClick={(e) => this.manageClick(row.VariantID, "VariantVal")}>
                                                                                    {row.VariantName}
                                                                                </li>
                                                                            ))}
                                                                            {this.state.MotorType > 1 && this.state.VariantListMaster.length > 0 && this.state.VariantListMaster.map((row) => (
                                                                                <li key={row.VariantID} id={row.CombindID} value={row.CombindID} onClick={(e) => this.manageClick(row.CombindID, "VariantVal")}>
                                                                                    {row.VariantName}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="yrVehicle">
                                                                <label for="" class="form-label">RTO</label>
                                                                <button onClick={this.manageSearch} className="searchButtonCar form-control" id="rtoname">{this.state.RTOVal == null ? "--Select RTO--" : this.state.SelectedRTO != null ? this.state.SelectedRTO.RTOName : this.state.RTO}</button>
                                                                {this.state.showRTO &&
                                                                    <div className="manageDrpDwn">
                                                                        <input type="text" placeholder="Search RTO" onChange={this.searchFilter} autoComplete="off"
                                                                            onKeyDown={this.searchdown}
                                                                            id="searchrto" autoFocus />
                                                                        <ul id="searchulrto">
                                                                            {this.state.RTOListMaster.map((row) => (
                                                                                <li key={row.RTOID} value={row.RTOID} onClick={(e) => this.manageClick(row.RTOID, "RTOVal")}>
                                                                                    {row.RTOName}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>

                                                                }
                                                            </div>
                                                    </div>
                                                    {this.state.IsReNew && < div className="col-md-6">
                                                        <div className="yrVehicle">
                                                            <label for="" class="form-label">Car Registration Year</label>
                                                            <button onClick={this.manageSearch} className="searchButtonCar form-control" id="year">{this.state.YearVal == null ? "--Select Year--" : this.state.YearVal}</button>
                                                            {this.state.showYear &&
                                                                <div className="manageDrpDwn">
                                                                    <input type="tel" placeholder="Search Year" onChange={this.searchFilter} autoComplete="off"
                                                                        onKeyDown={this.searchdown}
                                                                        id="searchyear" autoFocus />
                                                                    <ul id="searchulyear">
                                                                        {this.state.YearListMaster.map((row) => (
                                                                            <li key={row} value={row} onClick={(e) => this.manageClick(row, "YearVal")}>
                                                                                {row}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>}
                                                    </div>

                                                }

                                                {this.state.isManu === 6 &&
                                                    <div>
                                                        <div class="readonly-form">

                                                            <div class="row g-2 mb-4">
                                                                <div class="col-8">
                                                                    <div class="form-control" readonly aria-label="Make"><strong>Make</strong> - {this.state.ManufactureName}</div>
                                                                </div>
                                                                <div class="col-4 col-md-3">
                                                                    <a onClick={() => this.setState({ isManu: 3 })} class="btn btn-primary form-btn">Edit</a>
                                                                </div>
                                                            </div>
                                                            <div class="row g-2 mb-4">
                                                                <div class="col-8">
                                                                    <div class="form-control" readonly aria-label="Modal"><strong>Modal</strong> - {this.state.ManufactureModelName}</div>
                                                                </div>
                                                                <div class="col-4 col-md-3">
                                                                    <a onClick={() => this.setState({ isManu: 4 })} class="btn btn-primary form-btn">Edit</a>
                                                                </div>
                                                            </div>
                                                            <div class="row g-2 mb-4">
                                                                <div class="col-8">
                                                                    <div class="form-control" readonly aria-label="Variant"><strong>Variant</strong> -
                                                                {this.state.Model}</div>
                                                                </div>
                                                                <div class="col-4 col-md-3">
                                                                    <a onClick={() => this.setState({ isManu: 5 })} class="btn btn-primary form-btn">Edit</a>
                                                                </div>
                                                            </div>
                                                            <div class="row g-2 mb-4">
                                                                <div class="col-8">
                                                                    <div class="form-control" readonly aria-label="RTO"><strong>RTO</strong> -
                                                                {this.state.RTO}</div>
                                                                </div>
                                                                <div class="col-4 col-md-3">
                                                                    <a onClick={() => this.setState({ isManu: 5 })} class="btn btn-primary form-btn">Edit</a>
                                                                </div>
                                                            </div>
                                                            <div class="row g-2 mb-4">
                                                                <div class="col-8">
                                                                    <div class="form-control" readonly aria-label="Year"><strong>Year</strong> - {this.state.YearVal}</div>
                                                                </div>
                                                                <div class="col-4 col-md-3">
                                                                    <a onClick={() => this.setState({ isManu: 5 })} class="btn btn-primary form-btn">Edit</a>
                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="wizard-bottom">
                                                            <button class="btn btn-blue w-100" onClick={() => this.setState({ isManu: 7 })}>Next <svg width="16" height="16" viewBox="0 0 16 16"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z"
                                                                    fill="#FFFAFA" />
                                                            </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.isManu === 7 &&
                                                    <div className="row">
                                                        <div class="col-md-12">
                                                            {this.state.RTOVal !== null &&
                                                                <strong class="text-black">
                                                                    <span>
                                                                        {this.state.ManufactureName}, {this.state.ManufactureModelName}
                                                                    </span>

                                                                    <span>
                                                                        {this.state.SelectedVariant != null ? this.state.SelectedVariant.VariantName : this.state.Model}
                                                                    </span>
                                                                    <span>
                                                                        {this.state.SelectedRTO != null ? this.state.SelectedRTO.RTOName : this.state.RTO}
                                                                    </span>
                                                                    <span>
                                                                        {this.state.YearVal}
                                                                    </span>
                                                                </strong>
                                                            }
                                                        </div>
                                                        <div class="col-md-6 mt-4">
                                                            <label for="" class="form-label">Phone</label>
                                                            <MaskedInput className="ProductFormInput" type="tel"
                                                                mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                guide={false} showMask={false} placeholder="Mobile Number" value={this.state.MobileNo}
                                                                style={{ textTransform: "uppercase" }} name="MobileNo"
                                                                className="form-control"
                                                                onChange={this.handle} />
                                                            <small className="p-0">For Policy related updates</small>
                                                        </div>
                                                        <div class="col-md-12 mt-4">
                                                            <div class="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input" name="Organisation"
                                                                    onChange={this.handle} checked={this.state.Organisation} />
                                                                <label className="form-check-label">Vehicle owned by organisation</label>
                                                            </div>
                                                            {this.state.IsReNew && <React.Fragment>
                                                                {!this.state.Previouspolicydetails ?
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="checkbox" className="form-check-input" name="MyPolicyexpired"
                                                                            checked={this.state.MyPolicyexpired} onChange={this.handle} />
                                                                        <label className="form-check-label">My policy expired more than 90 days</label>
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div class="form-check form-check-inline">
                                                                    <input type="checkbox" className="form-check-input" name="Previouspolicydetails"
                                                                        checked={this.state.Previouspolicydetails} onChange={this.handle} />
                                                                    <label className="form-check-label">I don't have previous policy details</label>
                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                        </div>


                                                        {this.state.IsReNew && !this.state.Previouspolicydetails ?

                                                            <div className="col-md-6 mt-4">
                                                                <label> <span >Previous policy type</span></label>
                                                                <select
                                                                    className="form-control"
                                                                    name="PrevPolicyType"
                                                                    value={this.state.PrevPolicyType}
                                                                    onChange={this.handle}
                                                                >
                                                                    <option value="1">Comprehensive</option>
                                                                    <option value="2">Third Party</option>
                                                                    <option value="3">OD Cover Only</option>
                                                                </select>
                                                            </div>
                                                            : null}

                                                        {this.state.IsReNew && !this.state.MyPolicyexpired ?



                                                            <div className="col-md-12 mt-4 mb-5">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label>Have you made a claim in the past year?</label>
                                                                        <div className="ForMobileApp">
                                                                            <div className="GenderApp Male">
                                                                                <input className={!this.state.PastYearClaimID ? "active" : ""} type="radio" value="N"
                                                                                    name="PastYearClaimID" checked={!this.state.PastYearClaimID} onChange={this.handle} />
                                                                                <span><small>No</small></span>
                                                                            </div>
                                                                            <div className="GenderApp FeMale">
                                                                                <input name="PastYearClaimID" type="radio" value="Y" className={this.state.PastYearClaimID ? "active" : ""}
                                                                                    checked={this.state.PastYearClaimID} onChange={this.handle} />
                                                                                <span><small>Yes</small></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {!this.state.PastYearClaimID ? (
                                                                            <div>
                                                                                <label>Previous No Claim Bonus (NCB)</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="BindPreviousNCB"

                                                                                    value={this.state.BindPreviousNCB}
                                                                                    onChange={this.handle}
                                                                                >
                                                                                    <option value="0">0%</option>
                                                                                    <option value="20">20%</option>
                                                                                    <option value="25">25%</option>
                                                                                    <option value="35">35%</option>
                                                                                    <option value="45">45%</option>
                                                                                    <option value="50">50%</option>
                                                                                </select>
                                                                            </div>
                                                                        ) : <div>
                                                                                <label>Claim Amount</label>
                                                                                <MaskedInput
                                                                                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                                    guide={false} showMask={false}
                                                                                    value={this.state.ClaimAmount} className="form-control" name="ClaimAmount"
                                                                                    onChange={this.handle} onBlur={this.state.onBlur}

                                                                                />

                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}

                                                        <div class="wizard-bottom">
                                                            <button class="btn btn-blue w-100"
                                                               
                                                            onClick={() => this.getQuote()}>{this.state.BtnValue} <svg width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M9.3 3.7L13.1 7.5H1V8.5H13.1L9.3 12.3L10 13L15 8L10 3L9.3 3.7Z"
                                                                        fill="#FFFAFA" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <section class="f-14">
                                    <div class="row mt-5">
                                        <div class="col-md-11 offset-lg-1">
                                            Copyright 2020 - All right Reserved
                                        </div>
                                    </div>

                                </section>

                            </div>
                        </section>
                        :
                        <React.Fragment>
                            <MiddleSection />
                            <div className="LifePopUpBgCustom MotorPopUpBgCustom modal" role="dialog">
                                <div className="modal-dialog  modal-lg NewProModel0011">
                                    <div className="modal-content">
                                        <button type="button" onClick={this.closeBtn} className="close dismissPop">&times;</button>
                                        <button type="button" class="close closeBack" onClick={this.closeBtn} ><i class="fas fa-arrow-left"></i></button>
                                        <div className="card NewProModel01">

                                            <div className="row flexAuto">
                                                <div className="col-sm-3" id="leftTab">
                                                    <ul className="nav nav-tabs" id="lifetabs">
                                                        {this.state.MotorType > 1 && <li role="presentation"><a className="tab1" aria-controls="browseTab" role="tab" id="tabComm" data-toggle="tab" onClick={this.manageTab}>Insurer </a></li>}
                                                        <li role="presentation"><a className="tab2" aria-controls="browseTab" role="tab" id="tabHome" data-toggle="tab" onClick={this.manageTab}>Brand </a></li>
                                                        <li role="presentation"><a className="tab3" aria-controls="browseTab" role="tab" id="tabProfile" data-toggle="tab" onClick={this.manageTab}>Fuel</a></li>
                                                        <li role="presentation"><a className="tab4" aria-controls="browseTab" role="tab" id="tabModel" data-toggle="tab" onClick={this.manageTab}>Variant</a></li>
                                                        <li role="presentation"><a className="tab5" aria-controls="browseTab" role="tab" id="tabRto" data-toggle="tab" onClick={this.manageTab}>Rto</a></li>
                                                        {this.state.IsReNew ? (
                                                            <li role="presentation">
                                                                <a className="tab6" aria-controls="browseTab" id="tabMessages" role="tab" data-toggle="tab" onClick={this.manageTab}><span>Year</span></a></li>
                                                        ) : null}
                                                        <li role="presentation"><a className="tab7" aria-controls="browseTab" role="tab" id="tabMyNumberpop" data-toggle="tab" onClick={this.manageTab}><span className="hidden-xs hidden-sm"> <span style={{ whiteSpace: "nowrap" }}>
                                                            YOUR&nbsp;
                                <span style={{ textTransform: "uppercase" }}>
                                                                {this.state.SelectMotor}
                                                            </span>
                                                        </span></span></a></li>
                                                    </ul>
                                                    <div className="LifeCustomLeftCNT">
                                                        <h3>Why buy {this.state.SelectMotor} Insurance ?</h3>
                                                        <p>Motor Insurance products now available with<br />
                                                    100% online journey, no pesky calls, <br />
                                                    no aggressive follow-ups. <br />Enjoy a smooth online experience</p>
                                                        <img src={require("../Contents/img/" + (motorType == 1 ? "CarCommen" : motorType == 2 ? "PCVCommen" : motorType == 3 ? "GCVCommen" : "BikeCommen") + ".png")} alt="Life Insurance" />
                                                    </div>

                                                </div>

                                                <div className="col-sm-9 productValueAlign" id="rightTab" >
                                                    <div className="LifeCustomAgeBox">
                                                        <div className="tab-content">
                                                            {this.state.MotorType > 1 && <div role="tabpanel" className="tab-pane " id="Comm">
                                                                <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="VehicleCnt01">
                                                                            <h4>Select Your Insurer</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="yrVehicle">
                                                                            <select className="ProductFormInput" onChange={this.handle} name="CommercialInsurer" >
                                                                                <option>--Select Insurer--</option>
                                                                                {
                                                                                    this.state.CommercialInsurerList.length > 0 && this.state.CommercialInsurerList.map(row => {
                                                                                        return <option value={row.CompanyID}>{row.InsurerName}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.CarVal !== null && <button id="VariantNext" className="NextBTN" onClick={() => this.tabChange("tabHome", "tabProfile", "home", "profile")}>Next</button>}
                                                                    <span className="field-validation-valid" data-valmsg-for="ManufactureID" data-valmsg-replace="true"></span>
                                                                </div>
                                                            </div>}
                                                            <div role="tabpanel" className="tab-pane" id="home">
                                                                <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="VehicleCnt01">
                                                                            <h4>Which {this.state.SelectMotor} do you drive?</h4>
                                                                            <span>
                                                                                Search for your {this.state.SelectMotor}{" "}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="yrVehicle">
                                                                            <button onClick={this.manageSearch} className="searchButtonCar ProductFormInput" id="carname">{this.state.CarVal == null ? `--Make and Model--` : this.state.SelectedMotor != null ? this.state.SelectedMotor.Manu_Vehicle : this.state.carName}</button>
                                                                            {this.state.showCar &&
                                                                                <div className="manageDrpDwn">
                                                                                    <input type="text" className=" " placeholder="Search..." onChange={this.searchFilter} autoComplete="off"
                                                                                        onKeyDown={this.searchdown}
                                                                                        id="searchtext" autoFocus />
                                                                                    <ul id="searchul" >

                                                                                        {this.state.MotorType < 2 && this.state.CarListMaster.length > 0 && this.state.CarListMaster.map((row, i) => (
                                                                                            <li key={i} value={row.VehicleID} onClick={(e) => this.manageClick(row.VehicleID, "CarVal")}>
                                                                                                {row.Manu_Vehicle}
                                                                                            </li>
                                                                                        ))}
                                                                                        {this.state.MotorType > 1 && this.state.CarListMaster.length > 0 && this.state.CarListMaster.map((row, i) => {
                                                                                            return <li key={i} id={row.CombindID} value={row.CombindID} onClick={(e) => this.manageClick(row.CombindID, "CarVal")}>
                                                                                                {row.Manu_Vehicle}
                                                                                            </li>
                                                                                        })}
                                                                                    </ul></div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {this.state.CarVal !== null &&
                                                                        <button id="VariantNext" className="NextBTN" onClick={() => this.tabChange("tabHome", "tabProfile", "home", "profile")}>Next</button>
                                                                    }
                                                                    <span className="field-validation-valid" data-valmsg-for="ManufactureID" data-valmsg-replace="true"></span>
                                                                </div>
                                                            </div>
                                                            <div role="tabpanel" className="tab-pane" id="profile">
                                                                <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="VehicleCnt01">
                                                                            <h4>What is the fuel type?</h4>
                                                                            <span>Filter by Fuel type as shown below</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="yrVehicle">
                                                                            <select
                                                                                name="FuelVal"
                                                                                id="FuelVal"
                                                                                value={this.state.FuelVal}
                                                                                className="ProductFormInput"
                                                                                onChange={this.searchFilter} onKeyDown={this.searchdown}>
                                                                                <option value="0">Select Fuel</option>
                                                                                {this.state.FuelList.map((row) => (
                                                                                    <option key={row.FuelID} value={this.state.MotorType < 2 ? row.FuelID : row.CombindID}>
                                                                                        {row.FuelName}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>

                                                                    </div>
                                                                    <button className="PrevBTN" onClick={() => this.tabChange("tabProfile", "tabHome", "profile", "home")}>Prev</button>
                                                                    {this.state.FuelVal !== null && this.state.FuelVal !== "0" &&
                                                                        <button className="NextBTN" id="VariantNext" onClick={() => this.tabChange("tabProfile", "tabModel", "profile", "Model")}>Next</button>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div role="tabpanel" className="tab-pane" id="Model">
                                                                <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="VehicleCnt01">
                                                                            <h4>Which  model do you drive?</h4>
                                                                            <span> Select the model of your {this.state.SelectMotor}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="yrVehicle">
                                                                            <button onClick={this.manageSearch} className="searchButtonCar ProductFormInput" id="variantname">{this.state.VariantVal == null ? "--Select Variant--" : this.state.SelectedVariant != null ? this.state.SelectedVariant.VariantName : this.state.Model}</button>
                                                                            {this.state.showVariant &&
                                                                                <div className="manageDrpDwn">
                                                                                    <input type="text" placeholder="Search Variant" onChange={this.searchFilter} autoComplete="off"
                                                                                        onKeyDown={this.searchdown}
                                                                                        id="searchvariant" autoFocus />
                                                                                    <ul id="searchulvariant">
                                                                                        {this.state.MotorType < 2 && this.state.VariantListMaster.length > 0 && this.state.VariantListMaster.map((row) => (
                                                                                            <li key={row.VariantID} value={row.VariantID} onClick={(e) => this.manageClick(row.VariantID, "VariantVal")}>
                                                                                                {row.VariantName}
                                                                                            </li>
                                                                                        ))}
                                                                                        {this.state.MotorType > 1 && this.state.VariantListMaster.length > 0 && this.state.VariantListMaster.map((row) => (
                                                                                            <li key={row.VariantID} id={row.CombindID} value={row.CombindID} onClick={(e) => this.manageClick(row.CombindID, "VariantVal")}>
                                                                                                {row.VariantName}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <button className="PrevBTN" onClick={() => this.tabChange("tabModel", "tabProfile", "Model", "profile")} >Prev</button>
                                                                    {this.state.VariantVal !== null &&
                                                                        <button className="NextBTN" id="VariantNext" onClick={() => this.tabChange("tabModel", "tabRto", "Model", "Rto")}>Next</button>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div role="tabpanel" className="tab-pane" id="Rto">
                                                                <div className="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="VehicleCnt01">
                                                                            <h4>Where was your {this.state.SelectMotor} registered?</h4>
                                                                            <span>Search for your vehicle</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <div className="yrVehicle">
                                                                            <button onClick={this.manageSearch} className="searchButtonCar ProductFormInput" id="rtoname">{this.state.RTOVal == null ? "--Select RTO--" : this.state.SelectedRTO != null ? this.state.SelectedRTO.RTOName : this.state.RTO}</button>
                                                                            {this.state.showRTO &&
                                                                                <div className="manageDrpDwn">
                                                                                    <input type="text" placeholder="Search RTO" onChange={this.searchFilter} autoComplete="off"
                                                                                        onKeyDown={this.searchdown}
                                                                                        id="searchrto" autoFocus />
                                                                                    <ul id="searchulrto">
                                                                                        {this.state.RTOListMaster.map((row) => (
                                                                                            <li key={row.RTOID} value={row.RTOID} onClick={(e) => this.manageClick(row.RTOID, "RTOVal")}>
                                                                                                {row.RTOName}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <button className="PrevBTN" onClick={() => this.tabChange("tabRto", "tabModel", "Rto", "Model")}>Prev</button>
                                                                    {this.state.RTOVal !== null &&
                                                                        <button className="NextBTN" id="VariantNext" onClick={() => this.state.IsReNew ? this.tabChange("tabRto", "tabMessages", "Rto", "messages") : this.tabChange("tabRto", "tabMyNumberpop", "Rto", "myNumberpop")}>Next</button>
                                                                    }
                                                                </div>
                                                            </div>

                                                            {this.state.IsReNew ? (
                                                                <div role="tabpanel" className="tab-pane" id="messages">
                                                                    <div className="wow fadeInUp rgstYear" data-wow-delay="0.10s">
                                                                        <div className="col-md-12 text-center">
                                                                            <div className="VehicleCnt01">
                                                                                <h4>Which year was it registered?</h4>
                                                                                <span>Please select the year of Registration of your {this.state.SelectMotor}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 text-center">
                                                                            <div className="yrVehicle">
                                                                                <button onClick={this.manageSearch} className="searchButtonCar ProductFormInput" id="year">{this.state.YearVal == null ? "--Select Year--" : this.state.YearVal}</button>
                                                                                {this.state.showYear &&
                                                                                    <div className="manageDrpDwn">
                                                                                        <input type="tel" placeholder="Search Year" onChange={this.searchFilter} autoComplete="off"
                                                                                            onKeyDown={this.searchdown}
                                                                                            id="searchyear" autoFocus />
                                                                                        <ul id="searchulyear">
                                                                                            {this.state.YearListMaster.map((row) => (
                                                                                                <li key={row} value={row} onClick={(e) => this.manageClick(row, "YearVal")}>
                                                                                                    {row}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <button className="PrevBTN" onClick={() => this.tabChange("tabMessages", "tabRto", "messages", "Rto")}>Prev</button>
                                                                        {this.state.YearVal !== null &&
                                                                            <button className="NextBTN" id="VariantNext" onClick={() => this.tabChange("tabMessages", "tabMyNumberpop", "messages", "myNumberpop")}>Next</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div role="tabpanel" className="tab-pane" id="myNumberpop" >
                                                                <div className="side-view side-viewLastStep">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <span className="BackToDate" onClick={() => {
                                                                                let id = document.getElementById("leftTab");
                                                                                id.style.display = "unset";
                                                                                let anotherId = document.getElementById("rightTab");
                                                                                anotherId.classList.replace("col-sm-12", "col-sm-9")
                                                                                this.state.IsReNew ? this.tabChange("tabMyNumberpop", "tabMessages", "myNumberpop", "messages") : this.tabChange("tabRto", "tabModel", "myNumberpop", "Rto")
                                                                            }}><i class="fas fa-long-arrow-alt-left"></i> Back</span>
                                                                            <div className="FillingDetails">
                                                                                <div className="FDImg">
                                                                                    {this.state.SelectMotor === "Car" ?
                                                                                        <img src={require("../Contents/img/ProCar.png")} /> : this.state.SelectMotor === "PCV" ? <img src={require("../Contents/img/PCV.png")} /> : this.state.SelectMotor === "GCV" ? <img src={require("../Contents/img/GCV.png")} /> : <img src={require("../Contents/img/ProBike.png")} />
                                                                                    } </div>
                                                                                {this.state.RTOVal !== null && <div className="FDetails">
                                                                                    <span id="pop-car" >{this.state.SelectedMotor != null ? this.state.SelectedMotor.Manu_Vehicle : this.state.carName}, {this.state.fuil}
                                                                                        <span id="pop-year" className="slectData">{this.state.YearVal}</span>
                                                                                    </span>
                                                                                    <span id="pop-variant" className="slectData">{this.state.SelectedVariant != null ? this.state.SelectedVariant.VariantName : this.state.Model}, </span> <span id="pop-rto" className="slectData">{this.state.SelectedRTO != null ? this.state.SelectedRTO.RTOName : this.state.RTO}</span><br />
                                                                                 </div>}

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8 border-left">
                                                                            <div className="col-md-12">
                                                                                <div className="inx-wrapper text-center">
                                                                                    <div className="inx-text"> One last Step to view the quotes<br />
                                                                                        <small>Before you go ahead, make sure the info is correct.</small> </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="PX_Day-BX">
                                                                                <div className="row">
                                                                                    <div className="col-md-7">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="packageFormCheck"
                                                                                                    name="Organisation"
                                                                                                    onChange={this.handle}
                                                                                                    checked={this.state.Organisation}
                                                                                                />
                                                                                                <label><span className="pl-2" >
                                                                                                    Vehicle owned by organisation
                                  </span></label>
                                                                                            </div>
                                                                                            {this.state.IsReNew && <React.Fragment>
                                                                                                {!this.state.Previouspolicydetails ? <div className="col-md-12" id="myPolicyExpired">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="packageFormCheck"
                                                                                                        name="MyPolicyexpired"
                                                                                                        checked={this.state.MyPolicyexpired}
                                                                                                        onChange={this.handle}
                                                                                                    />
                                                                                                    <label><span className="pl-2">
                                                                                                        My policy expired more than 90 days
                                  </span></label>

                                                                                                </div> : null}
                                                                                                <div className="col-md-12">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="packageFormCheck"
                                                                                                        name="Previouspolicydetails"
                                                                                                        checked={this.state.Previouspolicydetails}
                                                                                                        onChange={this.handle}
                                                                                                    />
                                                                                                    <label><span className="pl-2">
                                                                                                        I don't have previous policy details
                                  </span></label>
                                                                                                </div></React.Fragment>}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.IsReNew && !this.state.Previouspolicydetails ?

                                                                                        <div className="col-md-5">
                                                                                            <label> <span >Previous policy type</span></label>
                                                                                            <select
                                                                                                className="packageForm"
                                                                                                name="PrevPolicyType"
                                                                                                value={this.state.PrevPolicyType}
                                                                                                onChange={this.handle}
                                                                                            >
                                                                                                <option value="1">Comprehensive</option>
                                                                                                <option value="2">Third Party</option>
                                                                                                <option value="3">OD Cover Only</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        : null}
                                                                                </div>

                                                                            </div>
                                                                            {this.state.IsReNew && !this.state.MyPolicyexpired ?



                                                                                <div id="alreadyExpiry" className="row">
                                                                                    <div className="col-md-7">
                                                                                        <label>
                                                                                            <span>Have you made a claim in the past year?</span>
                                                                                            <div className="HM_Box">
                                                                                                <input

                                                                                                    className="packageFormCheck pl-2"
                                                                                                    type="radio"
                                                                                                    value="N"
                                                                                                    name="PastYearClaimID"
                                                                                                    checked={!this.state.PastYearClaimID}
                                                                                                    onChange={this.handle}
                                                                                                />
                                                                                                <span className="CheckSpan"> No</span>

                                                                                                <input
                                                                                                    name="PastYearClaimID"
                                                                                                    type="radio"
                                                                                                    value="Y"
                                                                                                    className="packageFormCheck pl-2"
                                                                                                    checked={this.state.PastYearClaimID}
                                                                                                    onChange={this.handle}
                                                                                                />
                                                                                                <span className="CheckSpan "> Yes</span>
                                                                                            </div>
                                                                                        </label></div>
                                                                                    <div className="col-md-5">
                                                                                        {!this.state.PastYearClaimID ? (
                                                                                            <label>
                                                                                                <span>Previous No Claim Bonus (NCB)</span>
                                                                                                <select
                                                                                                    className="packageForm"
                                                                                                    name="BindPreviousNCB"

                                                                                                    value={this.state.BindPreviousNCB}
                                                                                                    onChange={this.handle}
                                                                                                >
                                                                                                    <option value="0">0%</option>
                                                                                                    <option value="20">20%</option>
                                                                                                    <option value="25">25%</option>
                                                                                                    <option value="35">35%</option>
                                                                                                    <option value="45">45%</option>
                                                                                                    <option value="50">50%</option>
                                                                                                </select>
                                                                                            </label>
                                                                                        ) : <label>
                                                                                                <span>Claim Amount</span>
                                                                                                <MaskedInput
                                                                                                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                                                    guide={false} showMask={false}
                                                                                                    value={this.state.ClaimAmount} className="packageForm" name="ClaimAmount"
                                                                                                    onChange={this.handle} onBlur={this.state.onBlur}

                                                                                                />

                                                                                            </label>}
                                                                                    </div>
                                                                                </div>
                                                                                : null}

                                                                            <div className="mobileBox">
                                                                                <div className="PF0012">
                                                                                    <div className="row">
                                                                                        <div className="col-md-7">
                                                                                            <label>
                                                                                                <MaskedInput className="ProductFormInput" type="tel"
                                                                                                    mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                                                    guide={false} showMask={false} placeholder="Mobile Number" value={this.state.MobileNo}
                                                                                                    style={{ textTransform: "uppercase" }} name="MobileNo"
                                                                                                    onChange={this.handle} />
                                                                                                <span>For Policy related updates</span>
                                                                                            </label> </div>
                                                                                        <div className="col-md-5">
                                                                                            <input
                                                                                                type="button"
                                                                                                className={ButtonClass()}
                                                                                                value={this.state.BtnValue}
                                                                                                onClick={() => this.getQuote()}
                                                                                            /> </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        <button className="PrevBTN" onClick={() => this.state.IsReNew ? this.tabChange("tabMyNumberpop", "tabMessages", "myNumberpop", "messages") : this.tabChange("tabRto", "tabModel", "myNumberpop", "Rto")} >Prev</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>

                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }


                    {this.state.IsGetQuote ?
                        <GotQuotes IsReNew={this.state.IsReNew} motorType={this.state.MotorType}
                            RegistrationDate={this.state.RegistrationDate}
                            handle={this.handle} MyPolicyexpired={this.state.MyPolicyexpired}
                            PreviousPolicyExpiredDate={this.state.PreviousPolicyExpiredDate}
                            MyPolicyexpired={this.state.MyPolicyexpired}
                            Previouspolicydetails={this.state.Previouspolicydetails}
                            TypeOfPolicy={this.state.TypeOfPolicy}
                            PastYearClaimID={this.state.PastYearClaimID}
                            BindPreviousNCB={this.state.BindPreviousNCB}
                            ISPACOVERODSELECTED={this.state.ISPACOVERODSELECTED}
                            PACOVERYEAR={this.state.PACOVERYEAR}
                            ManufactureDate={this.state.ManufactureDate}
                            Previouspolicydetails={this.state.Previouspolicydetails}
                            Organisation={this.state.Organisation}
                            ClaimAmount={this.state.ClaimAmount}
                            CallGetQuote={this.CallGetQuote}
                            close={this.close}

                        />
                        : null}
                </div>
        );
    }
}
