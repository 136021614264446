import React from "react";
import "react-reveal";
const SectionFour = (props) => {
    return (
        <React.Fragment>
            <section class="services-area ContactOurTeamSec pt-100 pb-70">
                <div className="container">
                    <div className="sectionFour">
                        <div className="howitworkCNT">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="ContactOurTeam">
                                        <div className="MuiBox-root">
                                            <h6>Ditto Hotline</h6>
                                            <h1 className="MuiTypography-h4">Need a human touch?</h1>
                                            <img src={require("../assets/img/SMC/images/dittoagent.svg")} alt="dittoagent" />
                                            <p><strong>Talk to us</strong> and we will navigate the complex world of insurance together. It's free and we will never spam you. Pinky Promise!!!</p>
                                        </div>
                                        <div className="ContactOurTeamFooter">
                                            <button className="btn">Find a time to talk</button>
                                            <a href="" className="whatsApp"><span>or Whatsapp us</span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="MuiBox-root">
                                        <h1 className="MuiTypography-h4">Advice you can count on</h1>
                                        <p>Ditto is from the people that brought you Finshots. And while we don't claim to be perfect, we have a pretty good track record when it comes to simplifying complicated financial mumbo jumbo.</p>
                                        <ul className="AdviceList">
                                            <li><i class="las la-check"></i> No Spam ever</li>
                                            <li><i class="las la-check"></i> Unbiased Advice</li>
                                            <li><i class="las la-check"></i> Personalized</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default SectionFour;
