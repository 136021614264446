/* eslint-disable */

import React, { useState } from 'react';
import ButtonClass, { ChildBtn } from '../ButtonClass';
const TabActive = (leftTab, leftNextTab, rightTab, rightNextTab) => {
    let left = document.getElementById(leftTab);
    left.classList.replace("active", "enable");
    let leftNext = document.getElementById(leftNextTab);
    leftNext.classList.add("active")
    let right = document.getElementById(rightTab);
    right.classList.remove("active")
    let rightNext = document.getElementById(rightNextTab);
    rightNext.classList.add("active")

}
const CustomFact = (props) => {

    return (

        <div class="modal LifePopUpBgCustom">
            <div class="modal-dialog  modal-lg NewProModel0011">
                <div class="modal-content">
                    <button type="button" class="close dismissPop " onClick={props.close} >&times;</button>
                    <button type="button" class="close closeBack" onClick={props.close} ><i class="fas fa-arrow-left"></i></button>
                    <div class="card NewProModel01">
                        <div class="row flexAuto">
                            <div class="col-md-3">
                                <ul class="nav nav-tabs" id="lifetabs">
                                    <li><a class="active" href="#StepOne" id="SmokeTab" data-toggle="tab">Smoke Status</a></li>
                                    <li><a href="#StepTwo" id="IncomeTab" data-toggle="tab">Annual Income</a></li>
                                    <li><a href="#StepThree" id="CoverTab" data-toggle="tab">Cover Value and Age</a></li>
                                </ul>
                                <div className="LifeCustomLeftCNT">
                                    <h3>Why buy Life Insurance ?</h3>
                                    <p>All Covid-19 Treatments covered<br />
                                                    Instant Purchase, No Medicals Required<br />
                                                    Tax Benefits upto ₹ 75,000 under Sec. 80D<br />
                                                    24x7 Claims Assistance *</p>
                                    <img src={require("../Contents/img/LifeCommen.png")} alt="Life Insurance" />
                                </div>
                            </div>
                            <div class="col-md-9 productValueAlign">

                                <div className="LifeCustomAgeBox">
                                    <div class="tab-content ProductFormIn">
                                        <div role="tabpanel" class="tab-pane active" id="StepOne">
                                            <div class="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                <div class="col-md-12 text-center">
                                                    <div class="VehicleCnt01">
                                                        <h4>We suggest best plans as per your life stage</h4>
                                                        <span>Do you smoke or chew tobacco?</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <div class="Step1smoke001CK">
                                                        <label>
                                                            <select class="LifeFormBox" value={props.SmokeStatus} onChange={props.handle} name="SmokeStatus" id="drpSmokeStatus">
                                                                <option value="N">No</option>
                                                                <option value="Y">Yes</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <small class="smallCnt">Choose <b>'Yes'</b> ONLY if you have smoked or chewed tobacco in last 12 months.</small>
                                                </div>

                                                <div class="col-md-12">



                                                    <div className="row">
                                                        <div className="col-md-6 text-left">
                                                            <button className={ButtonClass() + " dismissPop w-auto"} onClick={props.close}>Back</button>
                                                            <button className="close closeBack" onClick={props.close}><i class="fas fa-arrow-left"></i></button>
                                                        </div>
                                                        <div className="col-md-6 text-right">
                                                            <button id="next2" className={ButtonClass() + " dismissPop w-auto"} onClick={() => TabActive("SmokeTab", "IncomeTab", "StepOne", "StepTwo")}>Next</button>
                                                            <button id="next2" className="close closeBack" onClick={() => TabActive("SmokeTab", "IncomeTab", "StepOne", "StepTwo")}><i class="fas fa-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane" id="StepTwo">
                                            <div class="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                <div class="col-md-12 text-center">
                                                    <div class="VehicleCnt01">
                                                        <h4>We suggest best plans as per your life stage</h4>
                                                        <span>What's your annual income?</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <div class="Step1smoke001CK">
                                                        <label>
                                                            <select class="LifeFormBox" value={props.AnnualIncome} onChange={props.handle} name="AnnualIncome" id="drpIncome">
                                                                {props.IncomeList.map((r, i) => <option value={r}>{i + 2} Lakhs</option>)}


                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <small class="smallCnt">Enter your approximate annual income (CTC). We need this to determine your maximum cover eligibility which is 10 times of your annual income</small>
                                                </div>

                                                <div class="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 text-left">
                                                            <button id="previous1" className={ButtonClass() + " dismissPop w-auto"} onClick={() => TabActive("IncomeTab", "SmokeTab", "StepTwo", "StepOne")}>Back</button>
                                                            <button className="close closeBack" onClick={() => TabActive("IncomeTab", "SmokeTab", "StepTwo", "StepOne")}><i class="fas fa-arrow-left"></i></button>
                                                        </div>
                                                        <div className="col-md-6 text-right">
                                                            <button id="next3" className={ButtonClass() + " dismissPop w-auto"} onClick={() => TabActive("IncomeTab", "CoverTab", "StepTwo", "StepThree")}>Next</button>
                                                            <button id="next2" className="close closeBack" onClick={() => TabActive("IncomeTab", "CoverTab", "StepTwo", "StepThree")}><i class="fas fa-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane" id="StepThree">
                                            <div class="wow fadeInUp ItemVCenter" data-wow-delay="0.10s">
                                                <div class="col-md-12 text-center">
                                                    <div class="VehicleCnt01">
                                                        <h4>We suggest best plans as per your life stage</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <div class="Step1smoke001CK">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <span>Cover Value?</span>
                                                                <label>
                                                                    <select class="LifeFormBox LifeFormBoxCustom" name="SumInsured" onChange={props.handle} value={props.SumInsured} id="drpCoverAmount">
                                                                        <option value="300000000">30 Crores</option>
                                                                        <option value="290000000">29 Crores</option>
                                                                        <option value="280000000">28 Crores</option>
                                                                        <option value="270000000">27 Crores</option>
                                                                        <option value="260000000">26 Crores</option>
                                                                        <option value="250000000">25 Crores</option>
                                                                        <option value="240000000">24 Crores</option>
                                                                        <option value="230000000">23 Crores</option>
                                                                        <option value="220000000">22 Crores</option>
                                                                        <option value="210000000">21 Crores</option>
                                                                        <option value="200000000">20 Crores</option>
                                                                        <option value="190000000">19 Crores</option>
                                                                        <option value="180000000">18 Crores</option>
                                                                        <option value="170000000">17 Crores</option>
                                                                        <option value="160000000">16 Crores</option>
                                                                        <option value="150000000">15 Crores</option>
                                                                        <option value="140000000">14 Crores</option>
                                                                        <option value="130000000">13 Crores</option>
                                                                        <option value="120000000">12 Crores</option>
                                                                        <option value="110000000">11 Crores</option>
                                                                        <option value="100000000">10 Crores</option>
                                                                        <option value="90000000">9 Crores</option>
                                                                        <option value="80000000">8 Crores</option>
                                                                        <option value="70000000">7 Crores</option>
                                                                        <option value="60000000">6 Crores</option>
                                                                        <option value="50000000">5 Crores</option>
                                                                        <option value="40000000">4 Crores</option>
                                                                        <option value="30000000">3 Crores</option>
                                                                        <option value="20000000">2 Crores</option>
                                                                        <option value="10000000">1 Crore</option>
                                                                        <option value="9000000">90 Lakhs</option>
                                                                        <option value="8000000">80 Lakhs</option>
                                                                        <option value="7000000">70 Lakhs</option>
                                                                        <option value="6000000">60 Lakhs</option>
                                                                        <option value="5000000">50 Lakhs</option>
                                                                        <option value="4000000">40 Lakhs</option>
                                                                        <option value="3000000">30 Lakhs</option>
                                                                        <option value="2500000">25 Lakhs</option>
                                                                        <option value="2000000">20 Lakhs</option>
                                                                        <option value="1000000">10 Lakhs</option>

                                                                    </select>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span>Cover Age?</span>
                                                                <label>
                                                                    <select class="LifeFormBox LifeFormBoxCustom" name="Coverage" onChange={props.handle} value={props.Coverage} id="drpCoverageAge">
                                                                        {props.CoverList.map((r) => <option value={r}>{r} Years</option>)}


                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <small class="smallCnt">Enter your approximate annual income (CTC). We need this to determine your maximum cover eligibility which is 10 times of your annual income</small>
                                                </div>

                                                <div class="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 text-left">
                                                            <button id="previous2" className={ButtonClass() + " dismissPop w-auto"} onClick={() => TabActive("CoverTab", "IncomeTab", "StepThree", "StepTwo")}>Back</button>
                                                            <button className="close closeBack" onClick={() => TabActive("CoverTab", "IncomeTab", "StepThree", "StepTwo")}><i class="fas fa-arrow-left"></i></button>
                                                        </div>
                                                        <div className="col-md-6 text-right">
                                                            <button type="button" className={ButtonClass() + " w-auto dismissPop"} id="btnGetQuote" onClick={props.callGetQuote}>{props.btnValue}</button>
                                                            <button type="button" className={ChildBtn() + " w-auto slideBtnCompare"} id="btnGetQuote" onClick={props.callGetQuote}>{props.btnValue} <i class="fas fa-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}
export default CustomFact