/* eslint-disable */
import React from "react";
import TrustUs from "./TrustUs";
const ChooseUs = (props) => {
    return (
        <div className="chooseUs">
            <div className="container">
                <div className="row ">
                    <div className="col-12 ">
                        <h1>Choose Us because...</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <TrustUs name="Illustration51.svg" alt="choose us" className="choose-img" />
                    </div>
                    <div className="col-sm-7">
                        <p className="textChose">
                            Over the years, the name "HERO" has become synonymous to the everlasting commitment and unwavering dedication. We have always believed in forming a long term bond with our customers and nurturing it over time. Hero Insurance Broking India Limited, a part of Hero Enterprise, is India's leading Insurance broker that aims at safeguarding you, your loved ones and your prized possessions by helping you get the most comprehensive insurance plans.
          </p>

                        <p className="textChose">
                            Earning your trust by offering you quality service is our prime motive. We, at HERO IBIL put our best efforts to ensure your seamless insurance journey with us by offering a wide range of insurance products such as Health Insurance, Life Insurance, Motor Insurance, Travel Insurance. We look forward to providing you with the finest insurance deals at the most competitive premium.
          </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChooseUs;
