import React from "react";
const GetStarted = (props) => {
    return (
        <div class="section-space80">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6 col-md-12 col-12">
                        <div class="text-center">
                            <h1 class="text-white">Get started - it's FREE!</h1>
                            <p class="text-white">
                                Receive &amp; understand your credit score and access relevant
                                financial information &amp; offers.
              </p>
                            <a href="#!" class="btn btn-secondary">Get Your Score Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default GetStarted;
