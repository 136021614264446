import React from "react";
import Services from "./services/Services";
import FAQs from "./FAQs";
import Tab from "./HomeTab/Tab";

const SMCinsuranceSection = () => {
    return (
        <React.Fragment>
            <Tab />
            <Services />
        </React.Fragment>
    )
};
export default SMCinsuranceSection;
