import React from 'react';
import MaskedInput from 'react-text-mask'
class InsurePersAccident extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="row  pt-3 d-flex justify-content-center">
                <div className="col-sm-9">
                    <div className="packagesMainHealth text-center">
                        <div class="ProductFormIn ProductForm01">
                            <h3>Find better plans for your <br /><strong>Personal Accident Insurance</strong><br /><br />Coming Soon</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InsurePersAccident;