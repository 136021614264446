import React from "react";
import "react-reveal";
import RequestForm from "./RequestForm";
const PrivateCarInsurance = (props) => {
    return (
        <React.Fragment>
            <section class="xs-breadcrumb ProductPageMain breadcrumb-height">
                <div class="breadcrumb-bg banner-area banner-bg MotorInsBG rocket-lazyload lazyloaded" data-ll-status="loaded">
                    <h3>Motor Insurance</h3>
                </div>
            </section>
            <section class="ProductPageSectionFirst">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <h3 class="SecondSectionHeading text-left mb-3">What is Motor Insurance?</h3>
                            <p>Your vehicle also needs best Insurance covers, call our experts<br /> to take maximum add on covers you want along with  0 dep, <br />key replacement cover, engine protection, NCB protection,<br /> road side assistance and many more. Our experts will suggest<br /> you the best two wheeler policy along with best coverage's<br /> in a competitive price. </p>
                        </div>
                        <div class="col-md-5">
                            <RequestForm />
                        </div>
                    </div>
                </div>
            </section>

            <section class="ProductPageSectionThird ProductPageSectionThirdP_Car">
                <div class="container">
                    <h3 class="SecondSectionHeading">Types of Motor Insurance Policies</h3>

                    <div class="row">

                        <div class="col-md-12">
                            <h2><span>01</span> Comprehensive Coverage</h2>
                            <p>The Comprehensive Insurance, which includes own damage and third-party liability plan, is considered a robust cover for your personal car. The comprehensive coverage policy covers the natural calamities as well like- weather damage, vehicle missing in Flood, vehicle theft and fire damage</p>
                        </div>
                        <div class="col-md-12">
                            <p>A comprehensive insurance coverage covers your vehicle, driver, passengers, third-party vehicle, third-party driver, third-party vehicle passengers and third-party property. By installing anti-theft devices and other security-enhancement gadgets, you can decrease the insurance premium quoted by the insurance carrier.</p>
                        </div>
                    </div>
                    <p class="m-0"><strong>Add on covers: available</strong></p>
                    <ul class="DoNotNeed">

                        <li>
                            <strong>Roadside Assistance:</strong><br />
                                            Motor insurance providers have roadside assistance facility as an add-on cover that can be attached to the basic policy for a more comprehensive coverage. The premium involved is very low and so it also takes care of the affordability factor. This is the most basic level of breakdown cover, and means you get help by the side of the road if you break down. Roadside assistance only covers you if you break down a certain distance from your home, usually at least a quarter of a mile. If your car cannot be fixed there and then, it will be towed to
            </li>
                        <li>
                            <strong>Engine Protection cover</strong>:<br />
                                                The engine protection cover is usually not a part of your comprehensive car insurance policy; it is an add-on cover. Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle's engine. The add-on compensates you for the replacement or repair of your car's engine or parts.
            </li>
                        <li>
                            <strong>Zero Depreciation Cover </strong>:<br />
                                                    The official term for the Add-on cover is referred to as Zero Depreciation in car insurance. It is popularly called or referred to as Nil Depreciation cover or Bumper-to-Bumper cover. They all mean the same and its main objective is to cover the depreciation of the car parts while settling claims. While you need to pay an additional premium to purchase this Add-on, this can eliminate the possibility of paying from your pocket during claim settlements

            </li>
                        <li><strong>Return to Invoice (RTI) cover</strong>:<br /> This add-on plan protects your vehicle from total loss expenses. In the event of a total loss scenario such as a car theft, it provides you the actual invoice value of the vehicle, without accounting for its depreciation with age.</li>
                        <li>
                            <strong> Loss of personal belongings cover</strong>:<br /> Loss of expensive electronic equipments, laptops, etc. kept in the insured vehicle is offered coverage under this add-on insurance plan.
            </li>
                        <li><strong>Personal accident cover for the passengers</strong>:<br /> The comprehensive car insurance policy can be enhanced to offer protection for the passengers by opting for this rider.</li>
                        <li>
                            <strong>Keys Replacement</strong>:<br />
                                                                    The <strong>coverage for your car keys</strong> is known as Key replacement cover in car insurance. Key replacement cover is an Add-on to your car's comprehensive insurance policy and not a standalone cover. With an additional premium, the Key replacement Add-on will cover the cost arising out of the replacement of the insured vehicle's key.
            </li>
                        <li>
                            <strong>NCB protection</strong>:<br />
                                                                        NCB <strong>(No Claim Bonus)</strong> protection is a feature available in car insurance policies that offers a discount on the premium of the policy at the time of renewal. This discount is given as a reward for prudent and cautious driving, as it is offered only in cases where no claim has been raised during the policy coverage tenure.
            </li>
                    </ul>

                    <hr />

                    <div class="row">
                        <div class="col-md-12">
                            <h2><span>02</span>Third Party Cover</h2>
                            <p>The cover other than own damage is known as Third party insurance cover</p>
                        </div>
                    </div>
                    <ol class="DoNotNeed" type="1">
                        <li><strong>Damaged property - repair or replacement cost</strong></li>
                        <li><strong>Medical bills and treatment of third party injured.</strong></li>
                        <ul class="DoNotNeed">
                            <li>You should choose liability insurance premium higher than the mandatory requirement as per the law as the maximum risk coverage will be done by the policy and you can reduce your out of pocket expenses</li>
                            <li>If you opt for a higher 'sum assured', you will not want to pay a large amount when the policy's limit has exhausted</li>
                            <li>The Third party cover will include the third-party injury, death or damage to the third party property.</li>
                            <li>Third party cover is mandatory as per the motor vehicle Act 1988.</li>
                        </ul>
                        <li><strong>Personal Injury Coverage</strong></li>
                        <ul class="DoNotNeed">
                            <li>
                                In addition to the mandatory liability insurance, you can include certain coverage to overcome various risk factors.
                </li>
                            <li>
                                Personal injury protection will cover all the costs associated with the accident.
                </li>
                            <li>
                                The medical bills of the driver and other passengers will be covered by the personal injury protection.
                </li>
                            <li>
                                Regardless of whose fault, the insurance company will pay the medical bills.
                </li>
                        </ul>
                    </ol>

                </div>
            </section>
            <section class="ProductPageSectionFirst ">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="SecondSectionHeading SecondSectionHeadingZeroDep">Exclusions- in Zero Depreciation</h3>
                        </div>
                        <div class="col-md-6">
                            <p class="pb-3">Below are the exclusions on the Zero Depreciation Add-on in car Insurance cover:</p>

                            <ul class="DoNotNeed">
                                <li>Cars more Than 5 Years Old are not offered Nil depreciation cover</li>
                                <li>Replacement of certain car parts </li>
                                <li>
                                    Replacement of tyres and tubes, unless specified in the terms and conditions of the policy, is not covered under the Zero Depreciation cover.
                    </li>
                                <li>
                                    In case of an unfortunate accident which renders the car a total loss, the Nil or Zero Depreciation Add-on will not be covered while settling the claim. The current market value of the car is considered while settling claims towards a car which has undergone a total loss.
                    </li>

                            </ul>
                        </div>
                        <div class="col-md-6">
                            <img src={require("../assets/img/Religare/Zero-Deprication.jpg")} />
                        </div>
                        <div class="col-md-12">
                            <ul class="DoNotNeed">
                                <li>
                                    If you are driving the car without a valid driving licence and you meet with an unfortunate accident, then the insurance company will not honour the claim. In this case, even the Zero Depreciation Add-on will not be considered. Your claim towards the repair bill of the damages to your car will be dishonoured if the driver is found driving under the influence of alcohol or intoxicating substance.
                    </li>
                            </ul>
                            <p class="pb-3">
                                <strong>Factors to consider before opting for a Zero depreciation cover</strong><br />
                                                                                        The following are important factors to consider before choosing to buy the Zero depreciation Add-on cover:

                    <br />
                            </p><ul class="DoNotNeed">
                                <li>
                                    <strong>Age of the Car:</strong> The Zero depreciation car policy is applicable for cars under 5 years old only. However, some insurers offer the cover for cars above 5 years old. Ensure you check with the insurance company before opting for the Add-on.
                        </li>
                                <li>
                                    <strong>Premium of the policy:</strong>	With Zero depreciation offered as an Add-on to the comprehensive car insurance policy, the premium is bound to increase. Consider the increase in the premium before choosing the Add-on. However, if you have a luxury car or live in a high-risk area, you should consider adding Zero depreciation cover to your car insurance policy. This is because the premium for the Add-on is based on the age and model of the car, and the location of the vehicle.
                        </li>
                                <li>
                                    <strong>Limit of claims:</strong> You can only raise a limited number of claims during the policy period. Hence, check with the insurance company about the limit of claims before choosing the Add-on.
                        </li>
                                <li>
                                    <strong>Compulsory deductibles:</strong> While settling claims, the Add-on covers only the cost of the car part's depreciation and does not cover compulsory deductibles.
                        </li>
                            </ul>
                            <p></p>
                        </div>
                    </div>
                </div>
            </section>


            
        </React.Fragment>
    );
};
export default PrivateCarInsurance;
