/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import {
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div className="FooterRiskTop">
                    <div class="container">
                        <div class="row">
                            <div class=" col-sm-4 col-md  col-6 col">
                                <div class="footerBox">
                                    <h5 class="headin5_amrc col_white_amrc pt2">Motor Insurance</h5>
                                    <ul class="footer_ul_amrc">
                                        <NavItem><NavLink className="p-0" tag={Link} to="/MotorInsurance">Motor Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} >Private Car Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} >Two Wheeler Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} >Goods Carrying Vehicle</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} >Passenger Carrying Vehicle</NavLink></NavItem>
                                    </ul>
                                </div>
                            </div>


                            <div class=" col-sm-4 col-md  col-6 col">
                                <div class="footerBox">
                                    <h5 class="headin5_amrc col_white_amrc pt2">Health Insurance</h5>
                                    <ul class="footer_ul_amrc">
                                        <NavItem><NavLink className="p-0" to="/HealthInsurance" tag={Link}>Mediclaim Policy</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Senior Citizen Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Family Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Parents Insurance</NavLink></NavItem>
                                    </ul>
                                </div>
                            </div>


                            <div class=" col-sm-4 col-md  col-6 col">
                                <div class="footerBox">
                                    <h5 class="headin5_amrc col_white_amrc pt2">Life Insurance</h5>
                                    <ul class="footer_ul_amrc">
                                        <NavItem><NavLink className="p-0" tag={Link} to="/LifeInsurance">Term Plans</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Money Back Policy</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Pension Plans</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link}>Type of Life Insurance</NavLink></NavItem>
                                    </ul>
                                </div>
                            </div>


                            <div class=" col-sm-4 col-md  col-6 col">
                                <div class="footerBox">
                                    <h5 class="headin5_amrc col_white_amrc pt2">Quick Links</h5>
                                    <ul class="footer_ul_amrc">
                                        <NavItem><NavLink className="p-0" tag={Link} to="/TravelInsurance">Travel Insurance</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} to="/PrivacyPolicy">Privacy Policy</NavLink></NavItem>
                                        <NavItem><NavLink className="p-0" tag={Link} to="/TermsAndCondition">Terms and Condition</NavLink></NavItem>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="footerBottom">


                        <p class="text-center">
                            Kare India Insurance Broker Private Limited.<br />
                            <strong>
                                Registration Number - 567, Direct Broker: General and Life, Certificate Valid Upto: 16 Oct 2021, Member: Insurance Brokers Association of India, CIN - U66000DL2003PTC118256
                                Mr. Lalit Dhingra - 9811862140
                </strong><br />
                Registered & Corporate Office Address : 706, 8th Floor, K. M. Trade Tower, Hotel Radisson Blu, Kaushambi,Ghaziabad 201010
            </p>


                    </div>
                    <div class="row">

                        <div class="col-md-12 text-center">

                            <ul class="social_footer_ul">
                                <p>Connect With Us</p>
                                <NavItem><NavLink className="p-0" tag={Link}><i class="fab fa-facebook-f"></i></NavLink></NavItem>
                                <NavItem><NavLink className="p-0" tag={Link}><i class="fab fa-twitter"></i></NavLink></NavItem>
                                <NavItem><NavLink className="p-0" tag={Link}><i class="fab fa-linkedin"></i></NavLink></NavItem>
                                <NavItem><NavLink className="p-0" tag={Link}><i class="fab fa-instagram"></i></NavLink></NavItem>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>

        </React.Fragment>
    );
};
export default Footer;
