/* eslint-disable */
import React from 'react';
const TravelInsurance = () => {
    return (
        <section class="productCntMain">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="productCnt">
                            <h3>Travel Insurance</h3>
                            <p>Before setting off on a trip, you take painstaking efforts to make sure everything goes exactly the way you plan. Too bad, even circumspect travelers become callous when it comes to buying travel insurance. But buying a travel cover is imperative; it is after all your safety net against unforeseen circumstances and crippling costs following them. Do not let anything interfere with your perfect itinerary, get comprehensive travel coverage instead. </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={require("../assets/img/RFL/LifeIns.png")} />
                    </div>
                    <div class="col-md-12">
                        <div class="productCnt">
                            <p>
                                <strong>Types of Travel  Insurance Coverage: </strong>There  are different types of coverage available, and you can compare travel insurance  and choose the one that suits best for your specific needs. <br />
                                <br />
                                <strong>Domestic travel insurance</strong><br />
                                <br />
                                                Domestic travel insurance provides coverage for medical emergencies, permanent  disability and death, checked-in lost/stolen baggage, travel delay and personal  liability. <br />
                                <br />
                                <strong>International travel insurance</strong><br />
                                <br />
                                                                International travel insurance gives a comprehensive coverage for medical  expenses overseas, hijack, baggage and travel delays, repatriations/evacuation  to India, and loss of travel documents/passport besides the usual coverage. <br />
                                <br />
                                <strong>Corporate travel insurance</strong><br />
                                <br />
                                                                                Under corporate travel insurance India, employees of an organization can get  coverage for both international and domestic trips. <br />
                                <br />
                                <strong>Student travel insurance</strong><br />
                                <br />
                                                                                                There is minimum paperwork involved in student plans. The coverage is  comprehensive and provides for expenses incurred on medical treatment, passport  loss, and study interruptions.<br />
                                <br />
                                <strong>Senior citizen travel insurance</strong><br />
                                <br />
                                                                                                                Senior citizen insurance is for people who belong to the age group 61-70. It  includes coverage for dental treatments and cashless hospitalization besides  the usual benefits associated with travel insurance.<br />
                                <br />
                                <strong>Family travel insurance</strong><br />
                                <br />
                                                                                                                                Family travel insurance covers hospitalization, baggage loss, and other  incidental expenses. The claim disbursement is easy with minimal paperwork  involved.<br />
                                <br />
                                <strong>Individual travel insurance</strong><br />
                                <br />
                                                                                                                                                Through individual insurance, you can get coverage against cancellation of  trip, home burglary and trip curtailment.
                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TravelInsurance