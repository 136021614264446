import React from 'react';
import MaskedInput from 'react-text-mask'
import ButtonClass from '../../ButtonClass';
import CustomFact from '../../Life/CustomFact';
const RiskoveryLife = (props) => {
    let errors = props.errors
    let date = new Date();
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let yy = date.getFullYear();
    let MaxDate = yy - 18 + "-" + mm + "-" + dd;
    let currDate = yy + "-" + mm + "-" + dd;
    return (
        <React.Fragment>
            <div className="NewHealthUI">
                <div className="packagesMainHealth text-center">
                    <div className="productTabsMainHealth HealthPopUpTabCustom text-center">

                        <h3 className="heading text-left mb-3">Term Life Plans at Lowest Rates</h3>

                        <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                            <div className="PF0012">
                                <form>
                                    <div className="row align-items-end justify-content-left mb-3">
                                        <div>
                                            <label className="ForDesktop">
                                                <select name="Gender" id="drpGender" value={props.Gender} onChange={props.handle}>
                                                    <option value="Select">Select Gender</option>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                </select>
                                            </label>
                                            <span className="error">{errors.Gender}</span>
                                            <label className="ForMobileApp">
                                                <div className="GenderApp Male">
                                                    <input type="radio" id="rblMale" value="M" className={props.Gender == "M" ? "active" : ""} name="Gender" onChange={props.handle} />
                                                    <span><img src={require("../../Contents/img/manLine.png")} /> <small>Male</small></span>
                                                </div>
                                                <div className="GenderApp FeMale">
                                                    <input type="radio" id="rblFemale" value="F" className={props.Gender == "F" ? "active" : ""} name="Gender" onChange={props.handle} />
                                                    <span><img src={require("../../Contents/img/womanLine.png")} /> <small>Female</small></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>
                                                <MaskedInput id="txtFirstName" value={props.FullName} onChange={props.handle} name="FullName" placeholder="Enter Full Name"
                                                    mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} />

                                            </label>
                                            <span className="error">{errors.FullName}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label>
                                                <input type="date" max={MaxDate} defaultValue={MaxDate} name="DOB" onChange={props.handle} value={props.DOB} id="textDOB" required="required" placeholder="DD/MM/YYYY" className="hasDatepicker" />
                                            </label>
                                            <span className="error">{errors.DOB}</span>

                                        </div>
                                        <div className="col-md-4">
                                            <label>
                                                <MaskedInput name="Mobile" value={props.Mobile} onChange={props.handle} mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" required="required" placeholder="+91"
                                                    guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                            </label>
                                            <span className="error">{errors.Mobile}</span>

                                        </div>

                                        <div className="col-md-4">
                                            <label className="text-left">
                                                <button type="button" className="RoundArrowBTN" id="FirstForm" onClick={props.CallPopUp}><i class="fas fa-arrow-right"></i></button>
                                            </label>
                                            <p className="Term_CNT"><input type="checkbox" /> I Agree to<a target="_blank"> <span>Terms and Conditions</span></a></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                props.showPopUp && <CustomFact handle={props.handle}
                    SmokeStatus={props.SmokeStatus} close={props.close}
                    AnnualIncome={props.AnnualIncome} SumInsured={props.SumInsured} btnValue={props.btnValue}
                    Coverage={props.Coverage} callGetQuote={props.callGetQuote} IncomeList={props.IncomeList} CoverList={props.CoverList} />
            }
        </React.Fragment>
    );
}


export default RiskoveryLife;