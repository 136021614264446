import React from "react";
const Product = [
    {
        id: 1,
        to: "/Car",
        className: "nav-item nav-link",
        src:
            require("./assets/img/P2C/Car.png"),
    },
    {
        id: 2,
        to: "/Bike",
        className: "productTabsRisk",
        src:
            require("./assets/img/P2C/Bike.png"),
    },
    {
        id: 3,
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/P2C/PCV.png"),
    },
    {
        id: 4,
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/P2C/GCV.png"),
    },
    {
        id: 5,
        className: "productTabsRisk",
        to: "/Health",
        src:
            require("./assets/img/P2C/Health.png"),
    },
    {
        id: 6,
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/P2C/life.png"),
    },
];
export default Product;
