/* eslint-disable */ 
import axios from 'axios';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import MaskedInput from 'react-text-mask'
import { ChildBtn } from '../ButtonClass';
import FeatureBgClass from '../side/FeatureBgClass';
import './car.css'
class ShareButton extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            shareButton:props.shareButton,
            email:null,
            txtSms:null,
            copySuccess:"Copy",
            share:this.props.share,
            url:window.localStorage.getItem("portal"),
            CRMAPI:null,
            Logo:null,
            SharedEmail:this.props.SharedEmail,
            ShareBtnValue:"Share",
            SaveReqData:this.props.SaveReqData,
            MobileShare:"Share"

         }
         this.state.CRMAPI=window.localStorage.getItem("crm")
         this.openShare=this.openShare.bind(this)
         this.handleChange=this.handleChange.bind(this);
        this.ShareQuotes=this.ShareQuotes.bind(this);
         if(this.state.url.match("inshora")){
             this.state.url="https://inshoracover.com";
         }
         this.loadLogo();
         if(this.props.SaveReqData)
         this.ShareQuotes("CopyCall")
      
    }
    loadLogo(ss) {
        let url = this.state.url;
        if (url.match("https")) {
            if (url.match("https://www.")) {
                url = url.replace("https://www.", "")
            } else {
                url = url.replace("https://", "")
            }
        }
        var Body = {
            URL: url
        };
        
        let Model={
            URL:this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString:JSON.stringify(Body)
        }
        fetch("/bpi/WeatherForecast/CallCheck", {
            method: "POST",
            body: JSON.stringify(Model),
         headers: { "Content-Type": "application/json" }
        }).then(response => response.json()).then(data => {
          
            this.setState({
                Logo: data.companyLogo
            });
        });
    }
    openShare(){
        this.setState({shareButton:!this.state.shareButton});
        if(this.state.copySuccess==="Copied!")
        this.setState({copySuccess:"Copy"})
    }
    handleChange(e){
        this.setState({[e.target.name]:e.target.value})
        
    }
     ShareQuotes(param) {
        var email = "";
        let ViaCopy=false;
        if(param!="CopyCall"){
            if (param === "email") {
            email=document.getElementById("txtEmail").value;
            let testEmail=new RegExp(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
           
            if (!testEmail.test(email)) {
               
               return alert("Enter Valid Email ID.")
            }
            this.state.ShareBtnValue="Please Wait";
        }
        else {
            email=this.state.txtSms;
            if (email.length < 10 || email.length > 10) {
                
                return alert("Enter Valid Number");
            }
            this.state.MobileShare="Please Wait";
        }
        
    }else{
            param="email";
            email ="stariglobaltech@gmail.com";
            ViaCopy=true;
          
        }
        
        this.setState({ShareBtnValue:this.state.ShareBtnValue})
       // var QuoteData = JSON.parse(window.sessionStorage.getItem("QuotePage"));
       let userData=window.sessionStorage.getItem("userData");
            userData=JSON.parse(userData);
        var EnquiryNo = this.props.EnquiryNo
        var ClientURL = window.localStorage.getItem("portal");
        if(ClientURL.match("inshora")){
            ClientURL="https://inshoracover.com";
        }
        //***************************Generate Share API path */
        let ShareFor=this.props.ShareFor;
        let assignpath=null;
        if(ShareFor=="Motor_Quote"){
            assignpath=ClientURL+'/api/api/InsurerMotor/ShareQuotes';
        }else if(ShareFor=="Health_Quote"){
            assignpath=ClientURL+"/healthapi/api/InsurerHealth/ShareEmail";
        }else if(ShareFor=="Motor_Proposal"){
            assignpath=ClientURL+"/api/api/InsurerMotor/ShareEmail"
        }else if(ShareFor=="Health_Proposal"){
            assignpath=ClientURL+"/healthapi/api/InsurerHealth/ShareProposerEmail";
        }
        //*********************End**************************** */
        var Url = this.state.url + window.location.pathname + "?policyEnq=" + EnquiryNo;
        ClientURL = ClientURL;
        var imageurl = this.state.Logo;
         var QuotePage = this.props.Quotedata;
         let GenerateQuote = this.props.wishList;
        var quoteResponse = this.props.quoteRes;
        var Role = window.sessionStorage.getItem("Role");
         var userid = userData!==null?userData.userID:"0";
        if (userid !== null && userid !== "") {
            Role = Role===null?'~' + userid:Role + '~' + userid;
        }
        if(ShareFor=="Motor_Proposal"){
            QuotePage=JSON.stringify(QuotePage);
            quoteResponse= JSON.stringify(quoteResponse);
        }
        //********************************Shared Data */
        var dataModel = {
            EnqueryNo: EnquiryNo,
            ClientURL: this.state.url,
            ToEmail: email,
            Url: Url,
            TypeOfMoter:this.props.type==0?"Two":"Car",
            QuotePage: QuotePage,
            GenerateQuote: (GenerateQuote === null || GenerateQuote === undefined) ? null : GenerateQuote,
            quoteResponse:quoteResponse,
            Via: param,
            Imageurl: imageurl,
            Role: "",
            Identity: this.state.SaveReqData ? "GoToPayment" : "",
         };
        /* let ShareProposalReq = {
             ClientURL: this.state.url,
             ToEmail: email,
             TypeOfMoter: this.props.type == 0 ? "Two Wheeler" : "Car",
             Url: Url,
             ProposalReq: this.props.ProposalReq ,
             ProposalResponse: this.props.ProposalResponse ,
             ProposalAdditionalData:this.props.AdditionalData ,
             QuotePage: QuotePage,
             quoteResponse: quoteResponse,//htmlEncode(quoteR),
             Role: "",
             Identity: this.state.SaveReqData ? "GoToPayment" : "",
         }*/
        let healthModel={
            FirstInput: JSON.stringify(QuotePage),
            HealthRequest: JSON.stringify(QuotePage),
            EnquiryNo: EnquiryNo,
            email: email,
            param: param,
            Identity: this.state.SaveReqData ? "GoToPayment" : "",
            Url: this.state.url + window.location.pathname + "?policyEnq=" + EnquiryNo + ""
        };
        let HealthProposalModel={
            ClientURL: this.state.url,
            EnquiryNo: EnquiryNo,
            ToEmail: email,
            Url: Url,
            ProposalReq: this.props.ProposalReq,
            ProposalResponse: this.props.ProposalResponse,
            QuotePage: "",
            quoteResponse: "",
            Identity: this.state.SaveReqData ? "GoToPayment" : ""
        }
        if(ShareFor=="Health_Quote"){
            dataModel=healthModel
        }
        if(ShareFor=="Health_Proposal"){
            dataModel=HealthProposalModel;
         }
        /* if (ShareFor == "Motor_Proposal")
             dataModel = ShareProposalReq*/
         //*******************************************End */
        
        let Model={
            URL:assignpath,
            PostString:JSON.stringify(dataModel)
        }
        
        axios.post("/bpi/WeatherForecast/CallCheck",Model)
            .then((data) => {
               if(!this.state.SaveReqData){
                    if(!ViaCopy)
              alert(data.data);
              else {
                  console.log(data.data)
                  if(data.data.match("Success")){
                    this.setState({ copySuccess: 'Copied!' });
                    if(!this.props.SaveReqData)
                  alert("Copied")}
                
                  else {
                        alert(data.data)
                  }}}
                  this.state.ShareBtnValue="Share";
                  this.state.MobileShare="Share"
                  this.setState({ShareBtnValue:this.state.ShareBtnValue})
            });
         


        // $.ajax({
        //     url: ClientURL + '/api/api/InsurerMotor/ShareQuotes',
        //     dataType: "JSON",
        //     data: dataModel,
        //     async: true,
        //     type: "POST",
        //     success: function (data) {
        //         $("#btnPopupHide3").click()
        //         alert(data)
        //         $("#txtEmail").val("");
        //         $("#txtSms").val("");
        //     },
        //     error: function (e) {
        //         $("#btnPopupHide3").click()
        //         alert(e)
        //         $("#txtEmail").val("");
        //         $("#txtSms").val("");
        //     }
        // });
    }
    escapeSpecialChars = ()=> {
        return this.replace(/\\n/g, "\\n")
                   .replace(/\\'/g, "\\'")
                   .replace(/\\"/g, '\\"')
                   .replace(/\\&/g, "\\&")
                   .replace(/\\r/g, "\\r")
                   .replace(/\\t/g, "\\t")
                   .replace(/\\b/g, "\\b")
                   .replace(/\\f/g, "\\f");
    };
    htmlEncode(str) {
        try {
            if (str !== null || str !== undefined) {
                str = str.replace(/&/g, '&amp;');
                str = str.replace(/"/g, "&quot;");
                str = str.replace(/</g, '&lt;');
                str = str.replace(/>/g, '&gt;');
            }
        }
        catch (ex) { }
        return str;
    }
    copyToClipboard = (e) => {
       
        // textArea.select();
        // textArea.focus();
        this.ShareQuotes("CopyCall");
    };
    // keydownHandler(e){
    //     if(e.keyCode===67 && e.ctrlKey){
    //     //this.copyToClipboard(e);
    //     console.log(typeof this.copyToClipboard());
    // };
    //   };
    // componentDidMount(){
    //     document.addEventListener('keydown',this.keydownHandler);
    // }
    // componentWillUnmount(){
    //     document.removeEventListener('keydown',this.keydownHandler);
    // }
    render() { 
       
    return(
        <React.Fragment>
            {this.state.share && <button className={FeatureBgClass() + " shareButton main"} data-target="#shareModel" data-toggle="modal" value="Share Quotes" onClick={this.openShare}>
            <i className="fa share fa-share-alt"></i>
            <i className="fa close clsIcon">X</i>
        </button>}
        {this.state.shareButton?
        <div id="shareModel" className="modal ui-draggable show" style={{display: "block", paddingRight: "17px"}}>
        <div className="modal-dialog SharePrpslDialogue newModalqt">
            <div className="modal-content SharePrpsl">
                <div className="modal-header text-center ui-draggable-handle">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true" id="btnPopupHide3" onClick={this.openShare}>×</button>
                    <h2>Share this Quotes</h2>
                </div>
     
                    <div className="modal-body">
                        <div className="shareBySMS newModalqtPopUp">
                            <div className="row">
                                <div className="col-md-12"><label>Email</label></div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <input type="email" required="required" onChange={this.handleChange} id="txtEmail" value={this.state.email} name="email" className="FormSelect TextBox input-sm" placeholder="Enter Email" />
                                </div>
                                <div className="col-md-4">
                                    <input type="button" value={this.state.ShareBtnValue} className={ChildBtn()} style={{display:"block"}} onClick={(e)=>this.ShareQuotes("email")}/>
                                </div>
                            </div>
                        </div>
                        <div className="shareBySMS">
                            <div className="row">
                                <div className="col-md-12"><label>SMS</label></div>
                            </div>
                            <div className="row">
                                            <div className="col-md-8">
                                                <MaskedInput className="FormSelect TextBox input-sm"
                                                    mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                    guide={false} showMask={false} placeholder="Mobile Number"
                                                    name="txtSms" value={this.state.txtSms}
                                                    onChange={this.handleChange} />
                                </div>
                                <div className="col-md-4">
                                    <input type="button" value={this.state.MobileShare} className={ChildBtn()} style={{display:"block"}} onClick={()=>this.ShareQuotes("sms")} />
                                </div>
                            </div>
                        </div>
                        <div className="shareBySMS">
                            <div className="row">
                                <div className="col-md-12"><label>Link</label></div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                                <input type="text"  ref={(textarea) => this.textArea = textarea} value={this.state.url +window.location.pathname+ "?policyEnq=" + this.props.EnquiryNo} required="required" id="link" name="link" className="form-control TextBox input-sm" placeholder="Link" />
                                </div>
                                <div className="col-md-4">
                                <CopyToClipboard text={this.state.url +window.location.pathname+ "?policyEnq=" + this.props.EnquiryNo} onCopy={this.copyToClipboard} >
          <input type="button" value={this.state.copySuccess} className={ChildBtn()} style={{ display: "block" }}  />
        </CopyToClipboard>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
        </div>
    </div>
       :null }    
    </React.Fragment>
    )
}}
export default ShareButton;