import React from "react";
const Product = [
    {
        id: 1,
        name: "Car",
        to: "/Car",
        className: "productTabsRisk",
        src:
            require("./assets/img/SMC/images/icon/Car.png"),
    },
    {
        id: 2,
        name: "2 Wheeler",
        to: "/Bike",
        className: "productTabsRisk",
        src:
            require("./assets/img/SMC/images/icon/Bike.png"),
    },
    {
        id: 3,
        name: "Health",
        className: "productTabsRisk",
        to: "/Health",
        src:
            require("./assets/img/SMC/images/icon/Health.png"),
    },
    {
        id: 4,
        name: "Life",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/SMC/images/icon/Life.png"),
    },
    {
        id: 5,
        name: "GCV",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/SMC/images/icon/Car.png"),
    },
    {
        id: 6,
        name: "PCV",
        className: "productTabsRisk",
        to: "/",
        src:
            require("./assets/img/SMC/images/icon/Car.png"),
    },
    
];
export default Product;
