import React from 'react';
const TestimonialData=[
    {
        id:0,
        name:"Maya",
        data:"My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family."

    },
    {
        id:1,
        name:"Gulshan",
        data:"It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge."
    },
    {
        id:2,
        name:"Radha",
        data:"My first experience buying term insurance with the help of the inshoracover has been really good. They discussed each and every aspect of term insurance and made me understand . Thank you inshore team. Keep up the good work."
    },
    {
        id:3,
        name:"Rahul",
        data:"You all are doing a good job making insurance easy and affordable when times are tough. Thanks a lot."
    },
    // {
    //     id:4,
    //     name:"Karan",
    //     data:"My motor insurance was due in 3 days. Someone told me about your company and I must say you guys are the best. Within few hours of getting in touch with your representative, I got the competitive quotes from different insurance companies and within next few minutes I got my motor insurance issued."
    // },
    // {
    //     id:5,
    //     name:"Maya",
    //     data:"My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family."
    // },
    // {
    //     id:6,
    //     name:"Gulshan",
    //     data:"It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge."
    
    // },
    // {
    //     id:7,
    //     name:"Radha",
    //     data:"My first experience buying term insurance with the help of the inshoracover has been really good. They discussed each and every aspect of term insurance and made me understand . Thank you inshore team. Keep up the good work."
    
    // },
    // {
    //     id:8,
    //     name:"Rahul",
    //     data:"You all are doing a good job making insurance easy and affordable when times are tough. Thanks a lot."
    // },
    // {
    //     id:9,
    //     name:"Karan",
    //     data:"My motor insurance was due in 3 days. Someone told me about your company and I must say you guys are the best. Within few hours of getting in touch with your representative, I got the competitive quotes from different insurance companies and within next few minutes I got my motor insurance issued."
    // },
    // {
    //     id:10,
    //     name:"Maya",
    //     data:"My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family."
    // },
    // {
    //     id:11,
    //     name:"Maya",
    //     data:"My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family."
    // },
    // {
    //     id:12,
    //     name:"Gulshan",
    //     data:"It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge."
    
    // },
]
export default TestimonialData;

// <div class="testimonials slick-initialized slick-slider slick-dotted"><button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;">Previous</button>
                            
                            
                            
                            
                            
//                         <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 3120px; transform: translate3d(-960px, 0px, 0px); transition: transform 3000ms ease 0s;"><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="-3" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Maya</h5>
//                                 <p>My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family.</p>
//                             </div>
//                             <div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="-2" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Gulshan</h5>
//                                 <p>It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge.   </p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="-1" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Radha</h5>
//                                 <p>My first experience buying term insurance with the help of the inshoracover has been really good. They discussed each and every aspect of term insurance and made me understand . Thank you inshore team. Keep up the good work.</p>
//                             </div>
//                             <div class="t-slider slick-slide" style="width: 230px;" tabindex="0" data-slick-index="0" aria-hidden="true" role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                 </ul>
//                                 <h5>Rahul</h5>
//                                 <p>You all are doing a good job making insurance easy and affordable when times are tough. Thanks a lot.</p>
//                             </div><div class="t-slider slick-slide slick-current slick-active" style="width: 230px;" tabindex="0" data-slick-index="1" aria-hidden="false" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                 </ul>
//                                 <h5>Karan</h5>
//                                 <p>My motor insurance was due in 3 days. Someone told me about your company and I must say you guys are the best. Within few hours of getting in touch with your representative, I got the competitive quotes from different insurance companies and within next few minutes I got my motor insurance issued.</p>
//                             </div>
//                             <div class="t-slider slick-slide slick-active" style="width: 230px;" tabindex="0" data-slick-index="2" aria-hidden="false" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Maya</h5>
//                                 <p>My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family.</p>
//                             </div><div class="t-slider slick-slide slick-active" style="width: 230px;" tabindex="-1" data-slick-index="3" aria-hidden="false" role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Gulshan</h5>
//                                 <p>It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge.   </p>
//                             </div><div class="t-slider slick-slide" style="width: 230px;" tabindex="-1" data-slick-index="4" aria-hidden="true" role="tabpanel" id="slick-slide04" aria-describedby="slick-slide-control04">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Radha</h5>
//                                 <p>My first experience buying term insurance with the help of the inshoracover has been really good. They discussed each and every aspect of term insurance and made me understand . Thank you inshore team. Keep up the good work.</p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="5" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                 </ul>
//                                 <h5>Rahul</h5>
//                                 <p>You all are doing a good job making insurance easy and affordable when times are tough. Thanks a lot.</p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="6" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                 </ul>
//                                 <h5>Karan</h5>
//                                 <p>My motor insurance was due in 3 days. Someone told me about your company and I must say you guys are the best. Within few hours of getting in touch with your representative, I got the competitive quotes from different insurance companies and within next few minutes I got my motor insurance issued.</p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="7" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Maya</h5>
//                                 <p>My health insurance was due for renewal. I contacted Inshora cover. I was surprised to see their promptness in determining the best health insurance policy for me and my family. Moreover they provided the insurance with best of services and less premium. I will certainly keep coming back and recommend you to all my friends and family.</p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="8" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Gulshan</h5>
//                                 <p>It was really a good experience approaching Inshoracover for our travel insurance policy. They made this very easy for us presenting policies from multiple     insurance companies . They also  explained the pros and cons of different policies and helped us to choose the best one. Indeed , they have very good product knowledge.   </p>
//                             </div><div class="t-slider slick-slide slick-cloned" style="width: 230px;" tabindex="-1" data-slick-index="9" aria-hidden="true">
//                                 <ul class="list-unstyled">
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star"></i></li>
//                                     <li class="list-inline-item"><i class="fa fa-star-half"></i></li>
//                                 </ul>
//                                 <h5>Radha</h5>
//                                 <p>My first experience buying term insurance with the help of the inshoracover has been really good. They discussed each and every aspect of term insurance and made me understand . Thank you inshore team. Keep up the good work.</p>
//                             </div></div></div><button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;">Next</button><ul class="slick-dots" role="tablist" style="display: block;"><li class="" role="presentation"><button type="button" role="tab" id="slick-slide-control00" aria-controls="slick-slide00" aria-label="1 of 2" tabindex="0" aria-selected="true">1</button></li><li role="presentation" class="slick-active"><button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide01" aria-label="2 of 2" tabindex="-1">2</button></li><li class="" role="presentation"><button type="button" role="tab" id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 2" tabindex="-1">3</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control03" aria-controls="slick-slide03" aria-label="4 of 2" tabindex="-1">4</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control04" aria-controls="slick-slide04" aria-label="5 of 2" tabindex="-1">5</button></li></ul></div>