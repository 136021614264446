import React from 'react';
import TNC_Enivesh from './TNC_Enivesh';
import TNC_SMC from './TNC_SMC';
const TermsAndConditionLink = () => {
    let url = window.location.origin;
    if (url.match("hero")) {

        return
    } else if (url.match("insureasy")) {
        return 
    }
    else if (url.match("enivesh")) {
        return <TNC_Enivesh />
    } else return null
   
}

export default TermsAndConditionLink