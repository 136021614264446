import React from "react";
import "react-reveal";
const WorkEveryWhere = (props) => {
    return (
        <section class="about-bg-2 pad-tb" id="about">
            <div class="container">
                <div class="row m-text-c">
                    <div class="col-lg-6 v-center">
                        <div class="about-company">
                            <h2 class="mb20 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">Save <em>Upto 90%</em> with Best <span class="fw3">Insurance Plans offered by insurers</span></h2>
                            <p data-aos="fade-up" data-aos-delay="300" class="aos-init">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <a href="#form" class="btnpora btn-rd2 mt40 aos-init" data-aos="fade-up" data-aos-delay="600">Get your Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 v-center">
                        <div class="img-box1 m-mt60 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                            <img src={require("../assets/img/Vedant/agent.png")} alt="feature-image" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default WorkEveryWhere;
