/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
const AccordianHealth = (props) => {

    return (
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 class="text-secondary mb-3">Frequently asked questions</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <Accordion class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Why should I buy health insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Health insurance policies protects the policyholder from unexpected expenditure that can be incurred due to hospitalization. As healthcare becomes increasingly expensive, there is a surge in medical treatment costs that affects the financial planning of a household. Health insurance policy acts as a safety net at the time of emergency.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What are the benefits of health insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul>
                                            <li>Offers financial security against sudden medical expenditure for treatment of illnesses</li>
                                            <li>Health Card acts like a debit card avoiding any payment to the hospital during admission.</li>
                                            <li>Cashless hospitalization treatment through the network hospitals of insurance companies/TPA</li>
                                            <li>In case of treatment in a non-network hospital, the hospital bills are reimbursed by the insurance company.</li>
                                            <li>Pre and post-hospitalization expenses are also paid.</li>
                                            <li>As per the directives of IRDAI, Health Insurance Policies offer lifetime coverage with lifetime renewability once the policy is taken within the age of entry.</li>
                                            <li>The premium paid towards health insurance policy is exempt from Income Tax under section 80D of the Income Tax Act 1961</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What are the different types of health insurance?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        There are different types of health insurance plans. They are —
                                    <ul>
                                            <li>Individual Health Insurance Policy covers hospitalization expenses and other expenses like pre and post-hospitalization expenses for the insured members for the sum insured selected per individual.</li>
                                            <li>Family floater Health Insurance Policy covers hospitalization expenses and other expenses like pre and post-hospitalization expenses for the insured members for the sum insured floating on the entire family, which means the sum Insured can be utilized by family member or more than one.</li>
                                            <li>Top Up Health Insurance Policy is a special type of insurance policy that covers hospitalization expenses over a threshold limit like Rs. 3.00 lakhs or 5.00 Lakhs.</li>
                                            <li>Critical Illness Insurance Policy covers listed Critical Illnesses or Major Diseases, and the premiums are very reasonable.</li>
                                            <li>Corona Kavach or Corona Insurance Policy: The hospitalization treatment for Corona is very much covered under the normal health insurance policies.</li>
                                            <li>Health Insurance Policy for Senior Citizens: These are specially designed plans for Senior Citizens as the entry age is restricted under regular health insurance plans.</li>
                                        </ul>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What are the factors that affect premium?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul>
                                            <li>Age</li>
                                            <li>Medical history</li>
                                            <li>Claim free years</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Is there any Waiting Period for claims under a policy?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Yes, there is a 30 days waiting period starting from the policy inception date. During this period, any hospitalization charges incurred will not be payable by the insurer. However,  emergency hospitalization due to any unforeseen event such as accident, is excepted from this rule.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What is pre-existing condition in health insurance policy?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Any medical condition/disease that existed before the policyholder purchased the health insurance policy is considered pre-existing. These conditions are not covered by the insurance company within 48 or 36 or 24 months (depending on the insurer) from the inception date of the 1st year policy. However, the preexisting diseases are covered after the waiting period. Sometimes insurers decline offering coverage to individuals with certain serious preexisting conditions.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What is 'Any one illness’?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        'Any one illness' refers continuous period of illness, upto 45 days, including relapse.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What is the maximum number of claims allowed over a year?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        There is no upper limit to the number of claims during the policy period, unless explicitly specified. However, the maximum limit for coverage does not exceed the sum insured under the policy.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What are the advantages of buying a health insurance policy from Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul>
                                            <li>Twin Benefits – Best policy at best premiums AND Free Claims Assistance (FCA)</li>
                                            <li>Under a health insurance policy, we are committed to extending total assistance and guidance to the clients during hospitalization and post hospitalization, ensuring timely cashless approvals for the treatment in network hospitals and speedy and hassle-free claim settlement under Reimbursement claims – both for admission in non network hospitals as well as for pre and post hospitalization expenses.</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card class="accordion-item">
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    How to file for an insurance claim in Bimastreet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        The procedure to be adopted by the insured person or his representative under cashless claims for planned admission cases and Emergency admissions under Health Insurance claims:
<br /><br /><strong>Cashless Claims:</strong>
                                        <ul>
                                            <li>Planned Admission</li>
                                            <li>Emergency Admission</li>
                                        </ul>

                                        <strong>Planned Admission:</strong><br />
                                                When the insured person is getting admitted to a network hospital for cashless treatment under planned admission (i.e., at a later date), please inform Bimastreet about such planned admission and follow the procedure given below.
<ul>
                                            <li>Give immediate intimation to Bimastreet for total guidance and assistance.</li>
                                            <li>Under planned admission, when the insured person visits the hospital to complete the formalities of hospital procedure, the insured person or his representative shall ensure proper filling of pre-authorization form by the hospital and ensure that the hospital sends the form to TPA also to Bimastreet. The insured person or his representative is requested to be in touch with Bimastreet during sending Pre Auth to provide required details if any.</li>
                                            <li>Upon receiving Pre Auth, Bimastreet will follow up with TPA and arrange the authorization well before the admission date for smooth admission into the hospital without any hassles.</li>
                                            <li>At the time of discharge, the insured person or his representative shall contact Bimastreet and ensure quick sending of the final bill/authorization report by the hospital to TPA. </li>
                                            <li>Upon receiving the final bill, Bimastreet will follow up with the TPA/insurance company to release final approval, ensuring quick discharge by the hospital.</li>
                                        </ul>

                                        <strong>Emergency Admission:</strong><br />
                                        <ul>
                                            <li>When admission is required on an emergency basis in a network hospital, the insured person shall be rushed first to the hospital. Simultaneously, the information, including the hospital name and other details, shall be given to Bimastreet on its helpline for total assistance.</li>
                                            <li>The insured person or his representative, upon reaching the hospital, will present the Health ID Card to the Hospital, who will admit the patient immediately, and in case of any delay/difficulty employee may call Bimastreet for necessary assistance.</li>
                                            <li>Bimastreet, upon receiving the information, shall liaison/coordinate with the hospital and TPA for smooth admission.</li>
                                            <li>The hospital shall immediately send the Pre Auth request to TPA under copy to Bimasreet, who will coordinate for quick release of authorization.</li>
                                            <li>At the time of discharge, the insured person or his representative shall contact Bimastreet and ensure quick sending of the hospital's final bill/authorization report to TPA.</li>
                                            <li>Upon receiving the final bill, Bimastreet will follow up with the TPA to release final approval ensuring quick discharge by the hospital.</li>
                                        </ul>

                                        <strong>Reimbursement Claims:</strong><br />
                                                        Insured person/policyholder preferring reimbursement claim or for claiming pre/post hospitalization expenses shall inform Bimastreet who will assist in arranging and filling the claim form and in submission of the documents (in original) including
<ul>
                                            <li>Bills</li>
                                            <li>Lab reports</li>
                                            <li>X-rays</li>
                                            <li>Discharge summary and any other documents along with claim form</li>
                                        </ul>

                                        <p>All of these documents must be submitted within 7 days from the date of discharge or after incurring the expenses under pre/post hospitalization.</p>

Please submit the above documents to Bimastreet for further process and recommending the same to insurer/TPA for settlement of claim at the earliest.
                                 </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AccordianHealth;
