import React from "react";
import { Carousel } from "react-bootstrap"
const Testimonial = (props) => {
    return (
        <div className="fithSection">
            <div class="ProductPageSectionSecondHead">
                <h3 class="SecondSectionHeading">Testimonial</h3>
            </div>
            <Carousel>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">Great Job! Got the best deal because of InsuranceMandi!</p>
                        <div class="star-rating"><strong>Rahul</strong></div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">User friendly website! Easy to get quotes which match our needs. Thank you!</p>
                        <div class="star-rating"><strong>Priyanka</strong></div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">Great service! Agent contacted me as soon as I mailed them.</p>
                        <div class="star-rating"><strong>Subhash</strong></div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">Best claim support. It's been an amazing experience with InsuranceMandi.</p>
                        <div class="star-rating"><strong>Purva</strong></div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div class="carousel-itemInner">
                        <p class="testimonial">Thanks for endorsment request done in time. Really appreciate the service.</p>
                        <div class="star-rating"><strong>Mansi</strong></div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>

    );
};
export default Testimonial;
