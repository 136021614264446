import axios from "axios";
import React from "react";
import Loader from "../../components/Loader";
import TabData from "./TabData";
const data = TabData;
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <div
            onClick={props.onClick}
            className="productTabsRisk productTabsRisk--active"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>

    ) : (
        <div
            onClick={props.onClick}
            className="productTabsRisk"
            data-tab={props.dataTab}
        >
            {props.title}
        </div>
    );
}

function TabContent(props) {
    return (
        <div style={props.style} data-tab={props.dataTab}>
            {props.content}
        </div>
    );
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: "1", data: data,
            CRMAPI: window.localStorage.getItem("crm"),
            isLoader:false
        };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.getAttribute("data-tab") });
        console.log(ev.target);
    }
    async componentDidMount() {
        
        let Token = window.localStorage.getItem("token");
        let url = new URLSearchParams(window.location.search);
       
        if (url.get("user")) {
            Token = url.get("user");
        }
        if (Token != null) {
            // Token = JSON.parse(Token);
            this.setState({ isLoader: true })
            let parseToken = false;
            if (Token.charAt(0) === "{")
                parseToken = true;
           
            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/PriviligeList",
                PostString: JSON.stringify(data)
            }

            axios.post("/bpi/WeatherForecast/CallCheck", md)
                .then((Res) => {
                    if (Res.data.length === 1) {
                        let data = Res.data[0];
                        let isActive="1"
                        if (data.privilegeName === "Car")
                            isActive = "1";
                        else if (data.privilegeName === "TwoWheeler")
                            isActive = "2";
                        else if (data.privilegeName === "Taxi")
                            isActive = "3";
                        else if (data.privilegeName === "GCV")
                            isActive = "4";
                        else if (data.privilegeName === "Health")
                            isActive = "5";
                        else if (data.privilegeName === "Term Life")
                            isActive = "6";
                        this.setState({ isActive });
                        let hideTab = document.querySelectorAll("div.productTabsRisk");
                        hideTab.forEach((value, i) => {
                            let dataTab = value.getAttribute("data-tab");
                           
                            if (dataTab != isActive) {
                                value.style.display = "none";
                            }
                        })
                       
                    }
                    this.setState({ isLoader:false })
                });

        }
    }
    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                dataTab={item.id}
                title={item.tabTitle}
                key={item.id}
                to={item.to}
            />
        ));
        var listContent = this.state.data.map((item,i) =>
            this.state.isActive === item.id ? (
                <TabContent dataTab={item.id} content={item.tabContent} key={i} />
            ) : (
                <TabContent
                    style={{ display: "none" }}
                    dataTab={item.id}
                    content={item.tabContent}
                    key={i}
                />
            )
        );
       
        return (
            <section className="homeSection">
                <div className="container">
                    <div className="row">

                        <div className="BoxCertificate">
                            <div className="text-center">
                                <img
                                    src={require("../assets/img/license-by.png")}
                                    alt="rating" />
                            </div>

                        </div>

                        <div className="col-md-10 offset-md-1">
                           
                            < div className="tabs">
                                <div className="packagesMainRisk">
                                    <div className="productTabsMainRisk productTabsMainLifeMart" id="mainTab">
                                        <h3 className="productTabsHeading">Let's plan your insurance online</h3>
                                        {this.state.isLoader && <Loader />}
                                        {listTitle}
                                    </div>
                                </div>
                                <div className="tab-content tab-contentLifeMart">{listContent}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
export default Tab;
