import React from "react";
import "react-reveal";
const WhyChoose = (props) => {
    return (
        <section class="summeryPage">
            <div class="main-counter-area">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="section-heading-2 section-heading-2-p">
                                <h4>Summary</h4>
                                <h3>Achivement we have</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit eos iste accusamus ipsum temporibus officia assumenda, in tempora expedita aperiam magnam voluptatum culpa hic dolore molestiae eius magni! Accusantium optio impedit neque assumenda autem exercitationem aliquam cupiditate, quaerat iusto vero, necessitatibus sapiente accusamus blanditiis voluptas aperiam deleniti alias.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row counter-area-small">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                    <div class="single-count-area">
                                        <img src={require("../assets/img/KareIndia/manager.png")} />
                                        <br /> <span class="counter">1800</span><span>+</span>
                                        <p>projects</p>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 single-count-item-mb">
                                    <div class="single-count-area">
                                        <img src={require("../assets/img/KareIndia/chart.png")} />
                                        <br /><span class="counter">1000</span><span>+</span>
                                        <p>clients</p>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div class="single-count-area">
                                        <img src={require("../assets/img/KareIndia/coin.png")} />
                                        <br /> <span class="counter">150</span><span>k+</span>
                                        <p>profit</p>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 margin-top-sb-30">
                                    <div class="single-count-area">
                                        <img src={require("../assets/img/KareIndia/theme.png")} /> <br /> <span class="counter">500</span><span>+</span>
                                        <p>coming</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};
export default WhyChoose;
