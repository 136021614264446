import React from "react";
import {Health}  from "../../Health/Health";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { InsureHealth } from "./InsureHealth";
import LifeMain from "../../Life/LifeMain";
import BimaStreetMotorUIManager from "./BimaStreetMotorUIManager";
import LifeDemo from "./LifeDemo";
import BimaStreetGCVPCV from "./BimaStreetGCVPCV";
const TabData = [
    {
        id: "1",
        tabTitle: <ProductTab src={Product[2].src} name={Product[2].name} />,
        tabContent: <InsureHealth />,

    },
  {
    id: "2",
        tabTitle: <ProductTab src={Product[0].src} name={Product[0].name} />,
        tabContent: <BimaStreetMotorUIManager Motor="Car" type="1"/>,    
  },
  {
    id: "3",
    tabTitle: <ProductTab src={Product[1].src} name={Product[1].name} />,
      tabContent: <BimaStreetMotorUIManager Motor="Bike" type="0"/>,
    
  },  
  {
    id: "4",
    tabTitle: <ProductTab src={Product[3].src} name={Product[3].name} />,
      tabContent: <LifeMain />,
    
    },
  {
    id: "5",
      tabTitle: <ProductTab src={Product[4].src} name={Product[4].name} />,
      tabContent: <BimaStreetGCVPCV Motor="GCV" type="3" />,
    
    },
  {
    id: "6",
    tabTitle: <ProductTab src={Product[5].src} name={Product[5].name} />,
      tabContent: <BimaStreetGCVPCV Motor="PCV" type="2" />,
    
  },
];
export default TabData;
