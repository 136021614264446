import React from "react";
import { Carousel } from "react-bootstrap"
const AwordSection = (props) => {
    return (
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-9">
                                <h6 class="text-secondary font-regular">Recognised for experience, service, and reliability</h6>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">

                                <h5>Bimastreet has won several accolades over the years.</h5>
                            </div>
                        </div>
                        <div class="row g-4 mt-2">

                            <div class="col-6 col-md-4">
                                <div class="overbox">
                                    <div class="row align-self-center">
                                        <div class="col-md-4">
                                            <img src={require("../assets/img/BimaStreet/awards/2021_Best_of_5_Insurance_Advisors.png")} class="img-fluid" />
                                        </div>
                                        <div class="col-md-8 align-self-center">
                                            2021<br />
                                                                                Best of 5 Insurance Advisors
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-md-4">
                                <div class="overbox">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img src={require("../assets/img/BimaStreet/awards/2020_Quality_Leader_Award.png")} class="img-fluid" />
                                        </div>
                                        <div class="col-md-8 align-self-center">
                                            2020<br />
                                                                                        India 500 Quality Leader Awards
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-md-4">
                                <div class="overbox">
                                    <div class="row align-self-center">
                                        <div class="col-md-4">
                                            <img src={require("../assets/img/BimaStreet/awards/2018_Insurance_Broker_of_the_Year.png")} class="img-fluid" />
                                        </div>
                                        <div class="col-md-8 align-self-center">
                                            2018<br />
                                                                                                Insurance Broker of the Year
                                    </div>
                                    </div>
                                </div>
                            </div>





                            <div class="col-6 col-md-4">
                                <div class="overbox">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img src={require("../assets/img/BimaStreet/awards/Telangana_Best_Employer_Brand_Award.png")} class="img-fluid" />
                                        </div>
                                        <div class="col-md-8 align-self-center">
                                            2016<br />
                                                                                                        Telangana Best Employer Brand Award
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-md-4">
                                <div class="overbox">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img src={require("../assets/img/BimaStreet/awards/SKOTCH_Order_of_Merit_Award.png")} class="img-fluid" />
                                        </div>
                                        <div class="col-md-8 align-self-center">
                                            2020<br />
                                                                                                                SKOTCH Order of Merit Award
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 text-secondary pt-3">
                                <ul class="list-unstyled award-list">
                                    <li>Stukent Certified</li>

                                    <li>CBS Digital Marketing</li>
                                </ul>


                            </div>

                        </div>
                        <hr class="my-5" />
                        <div class="testimonials-section">
                            <div class="row">
                                <div class="col-md-12">
                                    <div>

                                        <Carousel>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    I feel I am totally insured as I take insurance through Amaze insurance brokers
                                                    for all my insurance needs.The claim that was settled when my car met with an accident by amaze
                                                    is really amazing as my involvement was almost negligible and their performance and speed was
                                                    extraordinary.
                                            </h6>
                                                <strong>Padma Rao</strong><br />Public Prosecutor, All India Vice-president
                                        </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    Amaze is one of the best insurance brokers in the market. Dr. Muralidhara Reddy
                                                    is known to me since 2009 and he has been servicing our Hotel Insurance requirements as well as
                                                    my Family Medical Insurance since then. He has personally helped with my cashless Mediclaim and
                                                    got a 100%
                                            </h6>
                                                <strong>Ms. Avanija Reddy</strong><br />MD, Fortune Hotels.
                                        </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    Amaze insurance Pvt LTD is good company and noted for trust and good quality of
                                                    services .It renders amazing service in insurance issues.My four wheeler and healthcare policies
                                                    are with Amaze insurance Pvt LTD only.
                                            </h6>
                                                <strong>
                                                    Dr.P.Murali Krishna Reddy, D<br />
                                                                                                                                        Assistant Director.
                                            </strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    We would like to express our appreciation for all the services provided from day
                                                    one. Your valuable support has really made us comfortable and also made us to bank on Amaze on
                                                    all fronts.
                                            </h6>
                                                <strong>AXIS Energy Ventures</strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    We hereby wish to congratulate Amaze on the wonderful service rendered to us for
                                                    the last 3 years, we have received positive feedback from multiple stakeholders internally on
                                                    the initiatives taken by you have had tremendous impact on associates in terms of awareness on
                                                    insurance and overall satisfactions level on the service has been consistently delightful.
                                            </h6>
                                                <strong>CYIENT Technologies</strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">We're utilizing the services of Amaze Insurance Brokers and all our insurance portfolio is with Amaze and we�re satisfied with their handling of our portfolio and Claim Settlements. Amaze has excellent supporting team having in-depth knowledge in insurance. They always guide us in taking suitable polices covering all our business needs.</h6>
                                                <strong>GreenPark Hotels and Resorts Ltd.</strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">We're pleased to place on record our appreciation to Amaze Insurance Brokers in guiding us while finalizing our policies, in settling claims and in recommending right insurance policies. We appreciate your team contributions in collecting the data, suggesting the suitable coverages and obtaining competitive quotes from various insurers thus reduced the amount of premium outgo.</h6>
                                                <strong>Musaddilal Jewellers</strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">I have been dealing with Amaze Insurance Brokers since day one of their Start. The Best Customer Care Services that I can Expect. Best Team, who has always Guided me Right from which policy to buy with least quote and their Recommendation. In the Event of Claim, Amaze Insurance Brokers has been very kind enough to co-ordinate with Respective company and see that I get whatever best is possible.</h6>
                                                <strong>
                                                    Dr.Taufiq Munshi<br />
                                                                                                                                            Super Specialist Dentist
                                            </strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">
                                                    Amaze is one of the best insurance brokers in the market. Dr. Muralidhara Reddy
                                                    is known to me since 2009 and he has been servicing our Hotel Insurance requirements as well as
                                                    my Family Medical Insurance since then. He has personally helped with my cashless Mediclaim and
                                                    got a 100% claim. I am extremely happy with their service and would certainly refer to anyone.
                                            </h6>
                                                <strong>
                                                    Ms. Avanija Reddy<br />
                                                                                                                                                MD, Fortune Hotels.
                                            </strong>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6 class="mb-3">Thank you very much for providing the insurance for the medical needs for the corona covid-19 pandemic. Our family very much appreciate the fast and safe insurance and claiming procedure. We are very grateful to you. We will definitely recommend the AMAZE Insurance Brokers Pvt. Ltd to my friends, colleagues and others.</h6>
                                                <strong>
                                                    S. G. Krishnamraju<br />
                                                                                                                                                    Indian Immunologicals Ltd
                                            </strong>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>

                                    <a href="#" class="btn btn-primary mt-5">
                                        Get Quotes <svg width="14" height="10" viewBox="0 0 14 10"
                                            fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA" />
                                        </svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AwordSection;
