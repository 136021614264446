import React from "react";

const About = () => {
    return (
        <React.Fragment>

            <section class="FirstSection childePage section pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="FirstInfoInsured p-0">
                                <h3 class="titleWithFont">
                                    About Us<br /><small>About Lifemart Insurance Brokers Private Limited</small>
                                </h3>
                                <p>
                                    Lifemart Insurance Brokers is a New Age Direct Insurance Broker (Category: Life & General) which received the Certificate of Registration from the Insurance Regulatory and Development Authority of India in August 2019 to commence operations. Lifemart is founded by a team of professionals having combined experience of more than 75 years in the financial services industry.
                        Lifemart has two Promoter - Directors namely<br />
                        1) Neeraj Vanjani &<br />
                        2) Shalaka Wadhavkar<br />
                        Lifemart aims at offering a pool of Policies to minimize the risk and select insurance plans as per the needs of the client and offering the best solutions with comparison of both prices and features of the plans offered by different companies.
                        We promote Healthy Underwriting practices and In-house Claims Management expertise of insurance policies.
                    </p>
                            </div>
                        </div>
                        <div class="col-md-5 pt-5">
                            <img src={require("./assets/img/LifeMart/Home1.png")} />
                        </div>
                    </div>
                </div>
            </section>


            <section class="FirstSection childePage section pt-0 mt-0">
                <div class="container">
                    <div class="FirstInfoInsured p-0">
                        <div class="CallRequestBackHead">
                            <h3 class="titleWithFont mb-3">
                                Profile of Promoters
                </h3>
                        </div>
                        <ul class="p-0 pl-3">
                            <li><strong>Neeraj Vanjani </strong>-19 years of overall work experience in the BFSI sector having  worked for leading Insurance companies like Birla SunLife Insurance, Reliance  Life and IndiaFirst Life Insurance</li>
                            <li>Integral part of fastest  growing vertical (Alternate Channels team) <strong>Birla Sun Life Insurance</strong> contributing maximum to the company's growth</li>
                            <li>Was heading Business  Development in <strong>Reliance Life</strong> and was instrumental in getting key  relationships on board </li>
                            <li>Youngest Country Head in a  Sales role at <strong>IndiaFirst Life</strong> Insurance to build and manage à sizeable portfolio.  Corporate business was built from scratch with a three-member team and the team  contributed significantly to the overall sales volume</li>
                            <li>Proactive, self-motivated and  dynamic professional with expertise in channel sales, business development,  relationship management, Strategic Alliances and Implementation in reputed  companies. </li>
                            <li>Leverage in-depth knowledge in  the related field with high receptiveness and ability to understand client&rsquo;s  viewpoints and requirements. Sound domain knowledge of the Insurance sector.</li>
                            <li><strong>Shalaka Wadhavkar </strong>– 18 years of work experience in the BFSI sector working for reputed  organisations like Kotak Life Insurance, HDFC Life &amp; IndiaFirst Life  Insurance. </li>
                            <li>Top Performer Pan India in the Agency  channel in <strong>HDFC Life</strong>  </li>
                            <li><strong>Canara HSBC OBC Life</strong>: Formulate and design strategies to get the mind share of  Commercial     Banking and Increase the  sales in co-ordination with the Private Banking Channel</li>
                            <li>Managed Sales &amp; Business Development Role at  IndiaFirst Life Insurance to build and manage à sizeable portfolio in West  India </li>
                            <li>Analytics:&nbsp;Solutions-oriented  and proactive at analyzing processes and customer feedback to identify  opportunities to improve service standards and resolve complex problems. Able  to leverage strong logical and creative thinking skills to predict project  outcomes, control risk, and lay groundwork for success.</li>
                            <li>Leadership and Teamwork:&nbsp;Willingly assumes  leadership of team projects, promoting collective success through open  dialogue, goal-setting, informed decision-making, providing constructive  criticism, and immediately addressing potential conflicts to prevent  escalation.</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="FirstSection childePage section pt-5 pb-5">
                <div class="container">
                    <div class="FirstInfoInsuredKK">
                        <div class="CallRequestBackHead">
                            <h3 class="titleWithFont mb-3">
                                Why Choose Us?
                </h3>
                        </div>
                        <p>
                            <strong>A  thorough analysis of your insurance needs</strong><br />
                              We respect that  no two clients are the same. Our insurance expertise and available policies run  the full spectrum of&nbsp;auto, home, life and commercial insurance from a  broad range of insurers.

                <strong>We work for  you!</strong><br />
                                  Don&rsquo;t just settle for an off-the-shelf insurance policy that may not be in your  best interests. Our insurance experts dig deeper to develop a comprehensive  insurance plan that gets you the most suitable coverage at the best possible  rates.&nbsp;And, all employees work with the highest ethical and professional  standards based on our guiding principles <br />
                            <strong>There when you need us…</strong><br />
                                          When things go wrong it&rsquo;s important to know your insurance brokerage has your  back. Our service experts are your advocates and advisors should you have an  accident or need to make a claim<br />
                            <strong>The selection of the company or  companies best suited to your situation: </strong>Experience  the convenience and savings of bringing your coverage under one roof&nbsp;as we  shop the market for you!&nbsp;And best of all, <strong></strong><br />
                            <strong>Professional  assistance in designing a protection program for you: </strong><br />
                            <strong>Efficiency in obtaining fair,  prompt settlement of your claims: </strong>Take care of  dealing with your Insurer should any claims arise – from your first phone call  to final resolution
                <strong>Periodic reviews of your insurance  program to check for coverage gaps</strong><strong>: </strong>as your situation changes, we&rsquo;re there to advise you on changes to  coverage and policies.<strong></strong>
                        </p>

                    </div>
                </div>
            </section>


        </React.Fragment>
    );
};
export default About;
