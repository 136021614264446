/* eslint-disable */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import Bottom from "./Bottom";
import { NavbarBrand, NavItem, NavLink, } from "reactstrap";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <React.Fragment>

            <footer class="footer-risk text-left">
                <div class="container bottom_border">
                    <div class="row">
                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <a class="navbar-brand" href="https://insurance.religareonline.com/">
                                    <img src={require("../assets/img/Religare/Whitelogo.svg")} />
                                </a>
                                <p>2nd Floor, Tower A, Prius Global, <br />A-3, 4, 5, Sec - 125, Noida - 201301,<br /> Uttar Pradesh</p>
                                <p><a href="tel:18602588888"><i class="fas fa-phone-alt"></i> 1860-25-88888 </a></p>
                                <ul class="social_footer_ul">
                                    <li><a href="javascript:void(0)">Connect with us</a></li>
                                    <li><a href="https://www.facebook.com/religarebrokingindia" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/religareonline" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/authwall?trk=gf&amp;trkInfo=AQHvSaGMjwad8QAAAXdwl-ngbKHgMXWJRX74SiO-5wznXqm2LC-C1PFcKzJLhhS5bGRY0UjqEYsMbK3kEl_0HnUeRBsdV_nOSkCeU_Q8RTk3mUr_MzjqXENL4YR3z9tKGh-zV4c=&amp;originalReferer=&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Freligare-broking-ltd" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/religarebrokingltd/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCdTDJ8pkvtPdPlP4zb59W5w" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/MotorInsurance">Motor Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/PrivateCarInsurance">Private Car Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TwoWheelerInsurance">Two Wheeler Insurance</NavLink></NavItem>

                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/HealthInsurance">Health Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/FamilyFloater">Family Floater </NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/Individualcoverforfamily">Individual Plan</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/HealthInsuranceforSeniorCitizens">Senior Citizen Health Plans</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>


                        <div class=" col-sm-4 col-md  col-6 col">
                            <div class="footerBox">
                                <ul class="footer_ul_amrc">
                                    <NavItem><NavLink tag={Link} to="/LifeInsurance">Life Insurance</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/TermPlans">Term Plans</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/EndowmentPlans">Endowment Plans</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/UnitLinkedPlans">Unit Linked Plans (ULIPs)</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/MoneyBackPolicy">Money Back Policy</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/PensionPlan">Pension Plan</NavLink></NavItem>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="container">
                    <div class="footerBottom">


                        <p class="text-center">
                            Religare Broking Limited LICENSE NO: IRDAI-CA0581<br />
                                IRDA Corporate Agent Code: IRDAI/CA0581, Valid till: 22/07/2021, CIN: U65999DL2016PLC314319<br />
                                    Principal Officer: Ms. Deepti Joshi (Contact: +91-120-4866641 Extn.: 6641, <a href="mailto:joshi@religare.com">deepti.joshi@religare.com</a>)<br />
                                        2nd Floor, Tower - A, Prius Global, A-3, 4, 5, Sec - 125, Noida - 201301, Uttar Pradesh
                <br />
            </p>

                    </div>
                </div>

            </footer>

        </React.Fragment>
    );
};
export default Footer;
