import React from "react";
const ClaimAssistance = (props) => {
    return (
        <section class="section-2 bg-primary text-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h3 class="font-semibold">Get free claim assistance!</h3>
                        <h4 class="font-regular">
                            Already have insurance but need help with your claim or reimbursement?<br /> Bimastreet
                                                is
                                                here to assist
                    </h4>

                        <a href="#" class="text-white mt-5 d-inline-block">
                            Get your free claim <svg class="ms-3" width="24"
                                height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M13.95 5.55L19.65 11.25H1.5V12.75H19.65L13.95 18.45L15 19.5L22.5 12L15 4.5L13.95 5.55Z"
                                    fill="white" />
                            </svg>
                        </a>
                    </div>
                    <div class="col-md-7 text-right text-md-end mt-5 mt-md-0">
                        <img src={require("../assets/img/BimaStreet/claim.svg")} class="img-fluid w-auto" />
                    </div>
                </div>

            </div>
        </section>
    );
};
export default ClaimAssistance;
