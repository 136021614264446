/* eslint-disable */
import React from "react";
import ContactAbout from "./ContactAbout";
import ContactInfo from "./ContactInfo";
import ContactCertificate from "./ContactCertificate";
import ContactForm from "./ContactForm";
import ContactMape from "./ContactMape";
import "./Contact.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const HeroContact = (props) => {
  
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
  
    return (
        <React.Fragment>
            <div className="MainContact">
                <Zoom>
                    <ContactAbout />
                </Zoom>
                <Zoom>
                    <ContactInfo />
                </Zoom>
                <Zoom>
                    <ContactCertificate />
                </Zoom>
                <Zoom>
                    <ContactForm />
                </Zoom>
                <Zoom>
                    <ContactMape />
                </Zoom>
            </div>
        </React.Fragment>
    );
};
export default HeroContact;
