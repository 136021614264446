import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>
            <section className="pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section_title text-center">
                                <div className="title text-custom text-uppercase">Our Service</div>
                                <hr />
                                <h2 className="font-weight-bold mx-auto section_header">This gives both expertise and dynamism.</h2>
                                <div className="bar"></div>
                                <p className="sec_subtitle text-muted mx-auto">We are a composite broking company dealing in life insurance general insurance and reinsurance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/General.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Genral Insurance</h6>
                                    <p className="text-muted mt-3 mb-0">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/Life.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Life Insurance</h6>
                                    <p className="text-muted mt-3">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/Car.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Four Wheeler Insurance</h6>
                                    <p className="text-muted mt-3">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/Bike.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Two Wheeler Insurance</h6>
                                    <p className="text-muted mt-3">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/Health.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Health Insurance</h6>
                                    <p className="text-muted mt-3">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mt-3 features_box p-3 text-center">
                                <div className="features_icon">
                                    <img className="avatar-small" src={require("../assets/img/LifeMart/Commercial.svg")} />
                                </div>
                                <div className="features_desc mt-3 pt-3">
                                    <h6 className="font-weight-bold">Commercial</h6>
                                    <p className="text-muted mt-3">Our team includes experienced professionals and young talent. This gives both expertise and dynamism.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>

        </React.Fragment>
    );
};
export default Services;
