/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <section style={{ marginTop: "100px" }}>
                <div className="container">
                    <h5 className="titleWithFont text-center">Let's Start a Conversation</h5>
                    <p className="text-center">
                        You'll be talking to a Human. You wont hit a ridiculously long phone
                        menu. You emails will be promptly responded. We at RFL provide an
                        exceptional customer service we'd expect ourselves.
      </p>
                    <div role="form">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="contacUS_page_Form ">
                                    <h2>Let Us Help</h2>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" id="Interested" name="typeList" onChange={this.handle}>
                                                <option value="">Interested In</option>
                                                <option value="Car Insurance">Car Insurance</option>
                                                <option value="Two Wheeler Insurance">Two Wheeler Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Travel Insurance">Travel Insurance</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["typeList"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <MaskedInput onChange={this.handle} className="form-control" name="MobileNo" value={this.state.fields["MobileNo"]} placeholder="+91 " mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />


                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["MobileNo"]}</span>
                                    <div className="form-group">
                                        <label className="">
                                            <select className="form-control" name="time" onChange={this.handle}>
                                                <option value="Preferred time">Preferred time</option>
                                                <option value="Morning(9:30am - 12pm)">Morning(9:30am - 12pm)</option>
                                                <option value="Afternoon(12pm - 3pm)">Afternoon(12pm - 3pm)</option>
                                                <option value="Late Afternoon(3pm - 5pm)">Late Afternoon(3pm - 5pm)</option>
                                                <option value="Evening(5pm - 8pm)">Evening(5pm - 8pm)</option>
                                            </select>

                                        </label>
                                    </div>
                                    <span className="txtError">{this.state.errors["time"]}</span>
                                    <div className="form-group text-left">
                                        <input type="button" id="btnReq" className="cl_btn" value="Send Request" onClick={this.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contacUS_page_Info">
                                    <h3 className="orDivider">OR</h3>
                                    <div className="Spearhead">

                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>

                                            <div className="col-md-10">
                                                <p><b>Contact Us</b></p>
                                                <p>
                                                    RFL Insurance Broking Private Limited<br />
                                        506-B,Cello Triumph, IB Patel Rd, Goregaon, Mumbai, Maharashtra 4000639<br />
                                                    <br />
                                                    <strong>CORPORATE OFFICE:</strong><br />
                                       801, Atlanta  Centre, Sonawala  Road  ,Goregaon East,Mumbai :400063
                                    </p>
                                            </div>
                                        </div>
                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>

                                            <div className="col-md-10">
                                                <p>

                                                    <b title="Phone">Call Us </b>: 18001232589
                                    </p>
                                            </div>
                                        </div>
                                        <div className="row flex">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-10">
                                                <p>
                                                    <b title="Email">Email</b>: <a href="mailto:satindar.info@riskfreelife.com">info@riskfreelife.com</a>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.765581081919!2d72.85386251485272!3d19.16173588703878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b64d29155953%3A0x732349c69ab89675!2sRFL%20Insurance%20Broking%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1626083093819!5m2!1sen!2sin" width="100%" height="450" style={{ border: "none" }} allowfullscreen="" loading="lazy"></iframe>
                
            </section>
        );
    };
}
export default Contact;
