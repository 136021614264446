/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import AccordionData from "./AccordionData";
const Accordian = (props) => {
    const cd = AccordionData.map((data) => {
        return (
            <React.Fragment>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey={data.id} key={data.id}>
                        {data.title}
                        <span className={data.class}>
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                        <Card.Body>{data.body}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </React.Fragment>
        );
    });
    return (
        <div className="services-areaSeven pt-100 pb-70">
            <div className="container">
                <div className="SectionSeven">
                    <div className="row align-items-start">
                        <div className="col-md-4">
                            <div className="MuiBox-root">
                                <h1 className="MuiTypography-h4 mb-100">Have questions about Ditto?</h1>

                                <div className="ContactEmail">
                                    <small>Contact us anytime</small>
                                    <h3>hi@finshots.in</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Accordion>{cd} </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Accordian;
