/* eslint-disable */
import React from "react";
import Loader from "../Health/Loader";
import './car.css';
const CardQuote = (props) => {
    return (
        <React.Fragment>

            <div className="col-md-3 mb-3">
                <div className="quotesBoxesMain motorQuoteUI">
                    <div className="IconContainer">
                        <small className="hideIcon EyeInfo">{<i className="fas fa-eye-slash" style={{ color: "rgb(183, 180, 180)" }} onClick={() => props.hideQuote(props.companyId)}></i>}
                            <span>Hide Quote</span>
                        </small>
                        <small className="hideIcon IconInfo"  ><i className="fa fa-star" style={{ color: (props.WishList !== null && props.WishList !== undefined) && props.WishList.filter(row => row === props.companyId).length == 1 ? "#ffc107" : "rgb(183, 180, 180)" }} onClick={(e) => props.createWishlist(e, props.companyId)} ></i>
                            <span id="info">Add to WishList</span>
                        </small>

                    </div>
                    <div className="quotesBoxes">
                        <div className="Qlogo">
                            {props.IsLoader ?
                                <span class="LoaderImg"></span>
                                :
                                <img src={props.src} alt={props.alt} />
                            }    </div>
                        <div className="Qncb">
                            <p ng-show="IsRenew"><span className="title-idv" >  <b>Current NCB: {props.currentNCB}%</b></span></p>
                        </div>
                        <div className="Qidv">
                            {props.IsLoader ? <p>Cover IDV: ₹ {props.idv}</p> : <p>Cover IDV: ₹ {props.idv}</p>}
                        </div>
                        <div className="QPremium">
                            {props.IsLoader ? <a className="btn QuoteBtn btn-block">

                                <Loader bgColor="#1a29e3" />

                            </a> : <a>
                                    <button className="btn QuoteBtn btn-block" onClick={props.btnClick}>
                                        ₹ <span className="buyme">{Math.round(props.quote)}</span>
                                    </button>
                                </a>}
                        </div>
                    </div>
                    <div className="quotesBoxesFooter">
                        <div className="QOtherFeat">
                            <a data-toggle="modal" className="detailsRow" onClick={props.detailsClk}>Plan Details</a>

                            <a> Cashless Garages</a>
                        </div>
                        <div className="QuoteAddons" style={{ textAlign: "left" }}>
                            <ul className="chkAddons">
                                <li>
                                    <span>PA Owner Driver </span><span className="spanRight">{props.ISPACOVERODSELECTED && !props.odOnly ?
                                        <img src={require("../Contents/img/RightCheck.png")} /> :
                                        <img src={require("../Contents/img/WrongCross.png")} />
                                    }</span>


                                </li>
                                {/*========================================================================================= */}
                                {props.chkPaidDriver ? <li>

                                    <span>Paid Driver </span><span className="spanRight">
                                        {props.PAPaidDriver > 0 ?
                                            "₹" + Math.round(props.PAPaidDriver) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        } </span>
                                </li>
                                    : null}
                                {(props.chkDepreciation || ((props.chkEngineProtector || props.chkConsumables || props.chkKeyReplacement) && props.companyId === 112)) && !props.ThirdPartyCover ?
                                    <li>
                                        <span>Zero Dept </span><span className="spanRight">
                                            {(props.SecureplusAmt > 0 || props.SecurePremiumAmt > 0) && props.companyId === 112 ? 'Included' : props.ZeroDepreciationAmt <= 0 ? <img src={require("../Contents/img/WrongCross.png")} /> : '₹' + Math.round(props.ZeroDepreciationAmt)}
                                           {/* {props.ZeroDepreciationAmt > 0 ?
                                                "₹" + Math.round(props.ZeroDepreciationAmt) : (props.chkDepreciation && props.chkConsumables && props.companyId === 112 && (props.SecureplusAmt > 0 || props.SecurePremiumAmt > 0) ? "included" :
                                                    )
                                            }*/}
                                        </span>
                                        {props.companyId === 112 && <ul className="AddonsInList">
                                            {(props.SecureplusAmt > 0 || props.SecurePremiumAmt > 0) && props.companyId === 112 && <li>
                                                <span>Engine Protector</span>
                                                <span className="spanRight">
                                                    <img src={require("../Contents/img/RightCheck.png")} />
                                                </span>
                                            </li>}
                                            {(props.SecureplusAmt > 0 || props.SecurePremiumAmt > 0) && props.companyId === 112 && <li>
                                                <span>Consumable</span>
                                                <span className="spanRight">
                                                    <img src={require("../Contents/img/RightCheck.png")} />
                                                </span>
                                            </li>}
                                            {(props.SecurePremiumAmt > 0 && props.companyId === 112) && <li>
                                                <span>Key and Lock Replacement</span>
                                                <span className="spanRight">
                                                    <img src={require("../Contents/img/RightCheck.png")} />
                                                </span>
                                            </li>}
                                        </ul>}
                                    </li>
                                    : null}
                                {props.chkReturnToInvoice && !props.ThirdPartyCover ? <li>
                                    <span> Return To Invoice Cover</span><span className="spanRight">
                                        {props.ReturnToInvoiceAmt > 0 ?
                                            "₹" + Math.round(props.ReturnToInvoiceAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        } </span>
                                </li> : null}
                                {props.chkNCBProtection && !props.ThirdPartyCover ? <li>
                                    <span>NCB Protection Cover</span><span className="spanRight">
                                        {props.NCBProtectionAmt > 0 ?
                                            "₹" + Math.round(props.NCBProtectionAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        }</span>
                                </li> : null}
                                {props.chkInconvenienceAllowance && !props.ThirdPartyCover ? <li>
                                    <span> Inconvenience Allowance Cover</span><span className="spanRight">
                                        {props.InconvenienceAllowanceAmt > 0 ?
                                            "₹" + Math.round(props.InconvenienceAllowanceAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        }</span>
                                </li> : null}
                                {props.chkPassengerCover ? <li>
                                    <span>Passanger Cover </span><span className="spanRight">
                                        {props.PassengerCoverAmt > 0 ?
                                            "₹" + Math.round(props.PassengerCoverAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        }</span>
                                </li> : null}
                                {props.chkEngineProtector && props.companyId !== 112 && !props.ThirdPartyCover ? <li>
                                    <span>Engine And GearBox </span><span className="spanRight">
                                        {props.EngineProtectorAmt > 0 ?
                                            "₹" + Math.round(props.EngineProtectorAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        }</span>
                                </li> : null}
                                {props.chkRoadSideAssistance && !props.ThirdPartyCover ? <li>
                                    <span>Road Side Cover </span><span className="spanRight">
                                        {props.RoadSideAssistanceAmt > 0 ?
                                            "₹" + Math.round(props.RoadSideAssistanceAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        }</span>
                                </li> : null}
                                {props.chkPassangerAssistance && !props.ThirdPartyCover ? <li>
                                    <span>Passanger Assistance Cover </span><span className="spanRight">
                                        {props.PassangerAssistanceAmt > 0 ?
                                            "₹" + Math.round(props.PassangerAssistanceAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        } </span>
                                </li> : null}
                                {props.chkConsumables && props.companyId !== 112 && !props.ThirdPartyCover ? <li>
                                    <span>Consumable Cover </span><span className="spanRight">
                                        {props.ConsumablesAmt > 0 ?
                                            "₹" + Math.round(props.ConsumablesAmt) : (props.chkDepreciation && props.chkConsumables && props.companyId === 112 && (props.SecureplusAmt > 0 || props.SecurePremiumAmt > 0) ? "included" :
                                                <img src={require("../Contents/img/WrongCross.png")} />)
                                        }</span>
                                </li> : null}
                                {props.chkKeyReplacement && props.companyId !== 112 && !props.ThirdPartyCover ? <li>
                                    <span>Key and lock Replacement </span><span className="spanRight">
                                        {props.KeyReplacementAmt > 0 ?
                                            "₹" + Math.round(props.KeyReplacementAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        } </span>
                                </li> : null}
                                {props.chkTyreSecure && !props.ThirdPartyCover ? <li>
                                    <span> Tyre Secure Cover </span><span className="spanRight">
                                        {props.TyreSecureAmt > 0 ?
                                            "₹" + Math.round(props.TyreSecureAmt) :
                                            <img src={require("../Contents/img/WrongCross.png")} />
                                        } </span>

                                </li> : null}
                                {
                                    (props.chkKeyReplacement === true || props.chkConsumables === true) && !props.ThirdPartyCover ?
                                        props.companyId === 108 && <li>
                                            <span> Loss of Personal Belongings </span><span className="spanRight">
                                                {props.PersonalBelongingsAmt > 0 ?
                                                    "₹" + Math.round(props.PersonalBelongingsAmt) :
                                                    <img src={require("../Contents/img/WrongCross.png")} />
                                                }
                                            </span>
                                        </li> : null


                                }
                            </ul>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
};
export default CardQuote;
