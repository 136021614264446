/* eslint-disable */
import React from "react";
const WhoWeAre = (props) => {
    return (
        <section class="text-center who-we">
            <div class="container">
                <div class="row justify-content-center mt-4 mb-4">
                    <div class="col-md-5 flex-end ">
                        <img src={require("../assets/img/who.svg")} alt = "" class= "embed-responsive pl-5 pr-5" />
                    </div>
                    <div class="col-md-6">
                        <h1 class="text-left headingCont black-color"><span>Who</span> we are?</h1>
                        <p class="text-left">From the beginning, we, at HERO, have provided a seamless experience to our clients and nurtured the bond between us for long-term trust and satisfaction. While changing times require evolved solutions, we have advanced the course of our actions where accommodating the needs of our customers is our number one priority. While giving back to our community is a crucial value that we have taken ahead with us, leading public initiatives and Make in India for products and services has been the backbone of our legacy.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default WhoWeAre;
