import React from "react";
import SimpleMasg from "./services/SimpleMasg";
import SocialConnect from "./services/SocialConnect";

const About = () => {
    return (
        <React.Fragment>
            <div class="dlab-bnr-inr dlab-bnr-inr-sm  bg-pt"></div>
            <div class="content-block">
                <div class="section-full content-inner-2">
                    <div class="container">
                        <div class="section-head text-black text-center">
                            <h4 class="text-gray-dark m-b10">About Us</h4>
                            <h2 class="box-title m-tb0">We are Inshoracover !!!!!!<span class="bg-primary"></span></h2>
                            <p>We’re on a mission to build the world’s smartest digital distribution framework in insurance space</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row ">
                            <div class="col-lg-5 col-md-4 about-img wow fadeIn">
                                <img src={require("./assets/img/inshora/about/pic1.jpg")} />
                            </div>
                            <div class="col-lg-7 col-md-8 about-new-content">
                                <h2 class="box-title">About Us<span class="bg-primary"></span></h2>
                                <h4 class="text-gray-dark we-offer-exte">We offer extensive expertise in all the major insurance disciplines.</h4>
                                <div class="mt-4">
                                    <p>We’re a close-knit startup made up of insurance specialists and tech experts; committed to simplify and bring greater ease and clarity to insurance for as many people as possible.</p>
                                    <p>With decades of professional experience between us, we have the vision – and the ambition – to make a difference and take insurance world to greater heights.</p>
                                    <p>We use data science, actuarial &amp; statistical techniques along with machine learning to power a service that gives people access to tailored advice about their life and their risks.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section-full bg-white content-inner">
                <div class="container">
                    <div class="section-content">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="col-lg-12 col-md-8 mx-auto content-right wow bounceInLeft">
                                    <p class="inshora-c">
                                        <img src={require("./assets/img/inshora/about/icon-1.png")} /> <span>Inshora Cover</span>
                                    </p>
                                    <p>
                                        Trained and highly motivated staff at IRAIB provides comprehensive, cost effective and specially crafted insurance solutions that meet the insurance and risk management requirements of our clients.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="col-lg-12 col-md-8 mx-auto content-right wow bounceInDown">
                                    <p class="inshora-c"><img src={require("./assets/img/inshora/about/icon-1.png")} /> <span>Inshora Cover</span></p>
                                    <p>We aspire to be a leading digital distributor and build the largest firm. Through spreading insurance knowledge over the period of time by providing qualitative services, implementing technological solutions and through wide-spreading insurance knowledge and awareness across the country.</p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="col-lg-12 col-md-8 mx-auto content-right wow bounceInRight">
                                    <p class="inshora-c"><img src={require("./assets/img/inshora/about/icon-1.png")} /> <span>Inshora Cover</span></p>
                                    <p>We focus on our customers relentlessly. We help compare quotes from at least 5 top insurance companies. We have tie ups with all the major insurance companies in India</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SocialConnect />
            <SimpleMasg />
        </React.Fragment >
    );
};
export default About;
