/* eslint-disable */ 
import React from 'react';
const MotorQuoteFooter=(props)=>{
    console.log(props)
    return(
        <div className="container">
        <div className="row "> <div className="col-md-12 mt-4">
        <div id="NoresultId">
            <p><strong>Currently not getting the response from following insurers</strong></p>
            {
                props.ListItem.map((row)=>
           <img src={props.IsLoader?row.src: row.CompanyLogo}  className="m-2" />
                 ) }
            <p className="Q_errMSG">Your location or vehicle model/age is not serviced by the insurer OR insurer is not reachable to provide live quotes currently.</p>
        </div>
    </div></div>
        </div>
       
   
)
}
export default MotorQuoteFooter;