import React from "react";

const About = () => {
    return (
        <React.Fragment>

            <section class="landing-hero inner-hero-section">



                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h2 class="text-secondary">
                                About us
                    </h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <p>Create a seamless insurance purchase and claims process for you — our customers</p>

                        </div>
                    </div>

                </div>
                <div class="hero-img">
                    <img src={require("./assets/img/BimaStreet/about-hero.svg")} class="img-fluid" />
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">About BIMA</h5>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>The foundation of the insurance industry is based on how effectively it provides products
                                    and solutions that keep customers protected from loss in an unfortunate event. However,
                                    since the dawn of digitalization in the insurance industry, the focus can no longer
                                    remain to service customers through traditional methods. Understanding the ever-evolving
                                    customer behavior, Amaze Insurance Brokers reinvented its policies to meet the demands
                                    of our clients. Offering tailored solutions with technical competencies and experience,
                                    Amaze Insurance Brokers facilitates its customers to reap several bene-fits. These
                                    benefits of coverage, savings in premium, and claims settlement are the driving force
                                    be-hind the firm's exceptional reputation, which are now available to individual
                                    customers through Bimastreet.
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section bg-light-primary">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">Who are we?</h5>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Bimastreet is the online insurance sales platform of Amaze Insurance Brokers licensed by
                                    the Insur-ance Regulatory & Development Authority of India (IRDAI) vide License No. 549.
                                    We meet the in-surance requirements of individuals of the society as well as all types
                                    of enterprises. Our team of pro-fessionals has proven professional skills & technical
                                    competencies in insurance and insurance broking fields with decades of experience in
                                    managing clients' insurance portfolios ranging from SMEs to big corporates, mega
                                    projects, industries — always bringing in customer delight. As a team, we are com-mitted
                                    to the objective of meeting policyholder's/client's expectations with the right policy
                                at the right price at the right time (Policy Issuance/Renewals/Claims Settlement).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">How do we do it?</h5>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>Since 2016, Amaze Insurance Brokers has had a growing team of dedicated individuals
                                    committed to offering the best insurance services in the industry for customers across
                                    India. A pioneer in insurance broking offering tailored niche solutions, Amaze Insurance
                                    Brokers blends customer interaction and experience to provide the best solutions
                                    effectively. We focus on three essential areas to provide un-paralleled service —
                                purchase, management, and insurance claims.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <h6 class="text-secondary mb-3 font-semibold">Insurance purchase</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Leveraging years of industry experience, we have created an algorithm that helps you pick
                                    the right plan. In other words, it would seem that our representative is next to you the
                                whole time.</p>
                                    <p class="ps-5">1. Fully secure purchase portal<br />
                                            2. Best insurance policy and price guarantee<br />
                                                3. One of India's largest online insurance sales portals
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-md-4">
                            <h6 class="text-secondary mb-3 font-semibold">Insurance Management</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Leveraging years of industry experience, we have created an algorithm that helps you pick
                                    the right plan. In other words, it wouManaging policies is the work of a knowledgeable
                                    team. Our customer support team is consistently available for assistance. At the click
                                    of a button, track all your policies in one place, file for a claim, or contact our team
                                for more specific needs.</p>
                                    <p class="ps-5">1. Request quick policy updates<br />
                                                    2. Track, review and maintain all policies in one account<br />
                                                        3. File claims and renew policies effortlessly
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row mt-5">
                        <div class="col-md-4">
                            <h6 class="text-secondary mb-3 font-semibold">Insurance claims and reimbursement</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>When you come to us for a claims process, we ensure that the process is smooth and
                                    hassle-free for you. With our single point of contact for 24/7 free claims assistance,
                                we guarantee a speedy, judicious, and hassle-free settlement always.</p>
                                    <p class="ps-5">1. 20+ partnered insurance companies<br />
                                                            2. Legal assistance in case of Third-Party Claims (TPC)<br />
                                                                3. Over 1000 claims settled
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section class="section AboutSecWhy bg-light-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Why Amaze Insurance Brokers?</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Understanding the ever-evolving customer behavior, Amaze Insurance Brokers reinvented its
                                    policies to meet the demands of our clients. Today, the company is well-renowned for its
                                    world-class and in-novative risk management and insurance solutions. Driving excellence
                                    and leveraging the expertise, Amaze Insurance Brokers has become one of the most
                                    preferred insurance brokers in the country. Thanks to our timely services and innovative
                                    solutions, it enjoys a good reputation. Under the leader-ship of Dr. Reddy, the firm has
                                    designed its solutions that align with its vision of being an ethical, principled, and
                                    value-based organization. By integrating technology and platforms to deliver results,
                                    Dr. Reddy is touted to have special skills in designing and implementing almost all
                                    classes of insur-ance. By doing so, he has demonstrated his skills paired with good
                                    ethics, which is why Amaze In-surance Brokers is the most preferred insurance broker.
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="text-secondary mb-3 font-semibold">Our Vision</h6>
                                    <p>Excellence, quality, and timely service delivery</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Our vision is to establish and emerge as one of the most preferred insurance brokers in
                                    the country, providing innovative solutions to ensure total and unparalleled customer
                                    satisfaction. To that end, we persevere to combine excellence, quality, and timely
                                    service delivery with professionalism in risk cov-erage, premium management, claim
                                    handling, risk management, and insurance portfolio management services. Sustainability
                                    will be the crucial factor while demonstrating and earning a reputation for good ethics
                                and trusted services to all its customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="text-secondary mb-3 font-semibold">Our Mission</h6>
                                    <p>Cost-effective and comprehensive insurance solutions</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>We aim to be ethical, principled, and a value-based organization that is setting new
                                    benchmarks in the insurance industry while catering to the insurance needs of all
                                    sections of society and all types of en-terprises. Our mission is to remain
                                    cost-effective and offer comprehensive insurance solutions to the clients, prioritizing
                                    customer delight by adhering to the TATs in underwriting and claims settlement. We are
                                    focused on designing the best-of-its-breed claims administration and management systems,
                                    ensuring speedy settlement of claims, and deploying appropriate Risk Management
                                    practices across the clients' portfolios.
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row mt-5">
                        <div class="col-md-4">

                            <h6 class="text-secondary mb-3 font-semibold">Solution-oriented services</h6>

                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <ul>
                                        <li>Designing the most suitable insurance program (MSIP) for the clients duly meeting
                                    all their insurance requirements</li>
                                        <li>Thorough analysis and evaluation of existing insurance covers and recommending
                                    concrete solutions to fill up the lacunae, if any, in coverage.</li>
                                        <li>Getting the clients the best price and terms from the insurance market since the
                                    company deals with all insurance companies.</li>
                                        <li>Ensuring prompt, timely, and effective renewals.</li>
                                        <li>Recommendations to clients on ensuring proper and adequate coverage of assets,
                                    operations, and liability areas</li>
                                        <li>Recommending and implementing the best of its breed Employee Benefits like Group
                                    Health, GPA, GTLI, etc.</li>
                                        <li>Designing systems and procedures for proper claims administration ensuring timely
                                    and judicious settlements.</li>
                                        <li>Insurance Audits enable total audit of insurance portfolio periodically with
                                    suggestions based on the audit findings.</li>
                                        <li>Providing total claims management for expeditious settlement of claims ensuring
                                    peace of mind to clients.</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row mt-5">
                        <div class="col-md-4">

                            <h6 class="text-secondary mb-3 font-semibold">Our Values</h6>

                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <ul>
                                        <li>Compliance with IRDAI regulations</li>
                                        <li>Compliance with other statutory bodies, including ROC, IT, GST</li>
                                        <li>Strict adherence to the Code of Conduct</li>
                                        <li>Committed, trusted, and dedicated service to the clients</li>
                                        <li>Process innovation to bring down TATs to minimum levels</li>
                                        <li>Keeping pace with fast-developing technology and leveraging the same</li>
                                        <li>Total transparency in the transactions</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-9">
                                    <h5 class="text-secondary mb-3">Awards and recognitions</h5>
                                    <p>For our unparalleled services, Amaze Insurance Brokers has been awarded numerous
                                accolades.</p>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">

                                    <h5>Bimastreet has won several accolades over the years.</h5>
                                </div>
                            </div>
                            <div class="row g-4 mt-2">

                                <div class="col-6 col-md-4">
                                    <div class="overbox">
                                        <div class="row align-self-center">
                                            <div class="col-md-4">
                                                <img src={require("./assets/img/BimaStreet/awards/2021_Best_of_5_Insurance_Advisors.png")} class="img-fluid" />
                                            </div>
                                            <div class="col-md-8 align-self-center">
                                                2021<br />
                                                                                Best of 5 Insurance Advisors
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-4">
                                    <div class="overbox">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <img src={require("./assets/img/BimaStreet/awards/2020_Quality_Leader_Award.png")} class="img-fluid" />
                                            </div>
                                            <div class="col-md-8 align-self-center">
                                                2020<br />
                                                                                        India 500 Quality Leader Awards
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-4">
                                    <div class="overbox">
                                        <div class="row align-self-center">
                                            <div class="col-md-4">
                                                <img src={require("./assets/img/BimaStreet/awards/2018_Insurance_Broker_of_the_Year.png")} class="img-fluid" />
                                            </div>
                                            <div class="col-md-8 align-self-center">
                                                2018<br />
                                                                                                Insurance Broker of the Year
                                    </div>
                                        </div>
                                    </div>
                                </div>





                                <div class="col-6 col-md-4">
                                    <div class="overbox">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <img src={require("./assets/img/BimaStreet/awards/Telangana_Best_Employer_Brand_Award.png")} class="img-fluid" />
                                            </div>
                                            <div class="col-md-8 align-self-center">
                                                2016<br />
                                                                                                        Telangana Best Employer Brand Award
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-4">
                                    <div class="overbox">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <img src={require("./assets/img/BimaStreet/awards/SKOTCH_Order_of_Merit_Award.png")} class="img-fluid" />
                                            </div>
                                            <div class="col-md-8 align-self-center">
                                                2020<br />
                                                                                                                SKOTCH Order of Merit Award
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-4 text-secondary pt-3">
                                    <ul class="list-unstyled award-list">
                                        <li>Stukent Certified</li>

                                        <li>CBS Digital Marketing</li>
                                    </ul>


                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section class="section aboutTeam bg-light-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="text-secondary">Team</h5>
                                    <p>The team that drives the company to excellence</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/dr-k-muralidhara-reddy.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">Dr. K Muralidhara Reddy</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    M.V.Sc, AIII,<br />
                                                    <span class="font-semibold">Managing Director & Principal Officer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div class="row g-5">
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/prasad-balijepalli.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">Prasad Balijepalli</h6>
                                            <div class="row">
                                                <div class="col-md-10">M. Sc, AIII, Dy<br />
                                                    <span class="font-semibold">Managing Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/ca-gk-rao.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">CA GK Rao</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span class="font-semibold">Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/smt-k-aruna-devi.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">Smt K. Aruna Devi</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span class="font-semibold">Vice Chairman & Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/dr-vishnu-vardhan-reddy.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">Dr. Vishnu Vardhan Reddy</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    MBBS, MS & MCh<br />
                                                    <span class="font-semibold">Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/padmaja-devi-kallur.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">Padmaja Devi Kallur</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    M. Tech, MBA (US)<br />
                                                    <span class="font-semibold">Vice President IT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-5">
                                    <div class="row">
                                        <div class="col-md-5 pe-0">
                                            <img src={require("./assets/img/BimaStreet/dv-krishna-reddy.png")} />
                                        </div>
                                        <div class="col-md-7 ps-0">
                                            <h6 class="text-secondary font-semibold">D. V. Krishna Reddy</h6>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    MA, FIII<br />
                                                    <span class="font-semibold">General Manager (Life)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">Life at Amaze</h5>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>We are in the business of arranging Financial Protection for people and loved ones. We
                                    believe in economic freedom through insurance penetration by helping more and more
                                    people take insurance policies and serving them at times of claims ensuring speedy and
                                judicious settlement.</p>

                                    <p>Our Customer Happiness is Our Happiness: We are committed to bringing happiness to our
                                    custom-ers with our service. So, when it comes to our team, we ensure the life here at
                                Amaze is just as beauti-ful, fulfilling, and enjoyable!</p>

                                    <p>The ever-expanding team has a home base at Hyderabad, Telangana. But we also have people
                                work-ing from all over the country.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a href="#" class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a href="#" class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default About;
