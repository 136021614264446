import React from "react";
import ClaimsBima from "./ClaimsBima";

const CarClaims = () => {
    return (
        <React.Fragment>
            <section class="landing-hero inner-hero-section claim-hero">



                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="text-secondary font-regular">
                                Have a claim under your Car Insurance Policy?
                    </h3>
                            <p>Bimastreet ensures speedy and hassle-free settlement through its FCA (Free Claims Assis-tance)
                    </p>
                            <h6 class="mt-4">Free. Simple. Quick.</h6>



                        </div>

                        <div class="col-md-6 mt-5 mt-md-0">
                            
                                <ClaimsBima />
                        </div>
                    </div>
                </div>
                <div class="hero-img">
                    <img src={require("../assets/img/BimaStreet/car-hero.svg")} class="img-fluid" />
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">Car Insurance Claim Process</h5>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">


                                    <p>In case of major damages and accidents involving third-party injuries spot, survey/inspection will be arranged. The vehicle can be moved to the garage only after the spot survey. Insured is advised to take the spot photos of the accident and the surroundings; maybe even a small video clip. The vehicle can be shifted to the repair workshop or garage.</p>

                                    <p> In case of minor accidents and accidents that does not involve third-party injuries, vehicles can be shifted to the workshop or garage after intimating Bimastreet or the insurance company.</p>

                                    <p> There are two types of claim processes for a car insurance policy. They are:</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-4">
                            <h6 class="text-secondary mb-3 font-semibold">Cashless claims</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>With cashless claims, the insured is protected from having to pay upfront for the repairs. The car in-surance provider settles the bill directly with the garage if the repairs were done at one of the network garages of the insurance provider. The insured must hand over documents, including policy copy, RC, driving license, and a duly filled and signed claim form to the surveyor at the garage. However, it is essential to note that the damages claimed must be covered in the insurance policy. If the policy is on Nil Depreciation Basis, the policyholder needs to pay only the compulsory excess. If the policy is a normal package, the policyholder has to pay the depreciation on the damaged parts, labor, and com-pulsory excess under the policy.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-5">
                        <div class="col-md-4">
                            <h6 class="text-secondary mb-3 font-semibold">Reimbursement claims</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>The insured already paid for the repairs and submits bills and repair receipts with the policy provider for reimbursements. In this type of insurance claim, the policyholder is getting the car repaired at a network car garage of the insurance provider. For these claims, the surveyor would be appointed by the insurance company who will come and do the survey at the garage to whom the insured needs to handover documents like policy copy, RC, driving license, and duly filled and signed claim form to the surveyor, who will then authorize the repairs. After completion of the repairs, the surveyor may do a re-inspection, and the bills and payment proofs need to be handed over to surveyor, who will submit the <span class="font-semibold">�survey report�</span> to the insurance company, which will effectively settle the claim and make an online payment to the insured�s account.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-2 pb-5 bg-light-primary">
                <div class="container">
                    <h5 class="font-regular text-secondary">Bimastreet leveraged decades of industry experience, knowledge, and expertise to build a platform that provides customer delight. When you come to us for a claims process, we ensure that the process is very smooth and hassle-free for you. </h5>
                </div>
            </section>

            <section class="benfit-section bg-light-blue" id="benefits">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-3 align-self-center">
                            <div class="row">
                                <div class="col-md-10">
                                    <h5 class="text-secondary">The Bimastreet promise</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">

                            <a href="#" class="btn btn-primary">File your claim <svg width="14" height="10" viewBox="0 0 14 10"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.3 0.7L12.1 4.5H0V5.5H12.1L8.3 9.3L9 10L14 5L9 0L8.3 0.7Z" fill="#FFFAFA">
                                </path>
                            </svg>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4 g-4 g-md-3 cont">
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Free claims assistance for speedy, judicious, and hassle-free settlement</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p class="w-100">24/7 free claims assistance</p>
                        </div>
                        <div class="col-md-3">
                            <div class="vr"></div>
                            <p>Legal assistance in case of Third-Party Claims (TPC)</p>
                        </div>
                        <div class="col-md-3">
                            <p>Single point of contact 24/7 for all claim related assistance - Free Of Cost</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="container">

                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-7">
                                    <h5 class="text-secondary">How to make a claim? </h5>
                                    <p>To make a cashless or a reimbursement claim, follow these simple steps -</p>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="font-semibold">For a hassle-free claims process, immediate intimate Bimastreet with the following details </p>
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <p>Policy number</p>
                                            <p> Name of the insurance company</p>
                                            <p> Name of the insured (owner) as per the policy</p>
                                            <p> Contact details of the insured or claimant (Mail id and mobile number)</p>
                                            <p> Place, date and time of the accident</p>
                                            <p> Brief description about the accident</p>
                                            <p> Photos of accident spot and damaged vehicle</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p>Driver at the time of the accident</p>
                                            <p> Driving license validity: Vehicles to drive and valid till date</p>
                                            <p> Driving license details: Number & RTA name</p>
                                            <p> Information to Police (required only in case of third party bodily injuries or property damage due to accident)<br />
                                                                        Name of the Police Station - FIR (if any)</p>
                                            <p>Extent of loss</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="section bg-light-primary">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">Documents required</h5>
                        </div>
                        <div class="col-md-8 font-bold">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Duly Filled in and Signed claims form</p>
                                    <p>RC Copy</p>
                                    <p>Copy of driving license with Original</p>
                                </div>
                                <div class="col-md-6">
                                    <p>Policy copy</p>
                                    <p>Original FIR copy (in case of theft or third-party injuries)</p>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>

            <section class="section bg-light-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="text-secondary">Claims Track Record</h5>
                        </div>
                        <div class="col-md-8 font-bold">
                            <p>Over 1000 claims settled</p>
                            <p>800+ happy clients</p>
                            <p>20+ partnered insurance companies</p>

                        </div>
                    </div>
                </div>
            </section>

            <section class="section bg-secondary text-white call-for-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2>Contact us</h2>
                        </div>
                        <div class="col-md-5">
                            <h6> We work round the clock to provide unparalleled customer experience. From customising new
                            insurance
                            policies to assisting with claims and reimbursements, Bimastreet is here for you - every
                            step of the way.
                    </h6>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <a href="#" class="btn btn-primary w-100">99594334673 <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M9.87231 10.4608L8.44498 11.8667C7.70072 11.4176 7.00928 10.8863 6.38365 10.2828C5.78126 9.65679 5.25023 8.96589 4.80031 8.22275L6.20698 6.79542C6.2914 6.70911 6.3484 6.59977 6.37082 6.48114C6.39324 6.36252 6.38007 6.23991 6.33298 6.12875L4.86698 2.70675C4.80904 2.57195 4.7046 2.46245 4.57268 2.39821C4.44077 2.33396 4.29017 2.31925 4.14831 2.35675L1.45898 3.06675C1.32434 3.10164 1.20557 3.18131 1.12221 3.29265C1.03886 3.404 0.995866 3.5404 1.00031 3.67942C1.17159 6.80703 2.47209 9.76621 4.66031 12.0074C6.9023 14.196 9.86252 15.4963 12.991 15.6667C13.1298 15.6711 13.266 15.6281 13.3772 15.5447C13.4883 15.4614 13.5677 15.3426 13.6023 15.2081L14.313 12.5174C14.3507 12.3756 14.3361 12.225 14.272 12.0931C14.2078 11.9612 14.0984 11.8567 13.9636 11.7988L10.5443 10.3334C10.4324 10.2852 10.3086 10.2716 10.1888 10.2943C10.0691 10.317 9.95887 10.375 9.87231 10.4608V10.4608Z"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 1C11.2583 1 12.7844 1.63214 13.9096 2.75736C15.0349 3.88258 15.667 5.4087 15.667 7"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                    <path
                                        d="M9.66699 3.66663C10.551 3.66663 11.3989 4.01782 12.024 4.64294C12.6491 5.26806 13.0003 6.1159 13.0003 6.99996"
                                        stroke="white" stroke-miterlimit="10" stroke-linecap="square"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>

                            </a>
                            <a href="#" class="btn btn-info w-100 mt-4">info@bimastreet.com <svg width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 5L8 9L15.5 5" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path
                                    d="M14 14.5H2C1.60218 14.5 1.22064 14.342 0.93934 14.0607C0.658035 13.7794 0.5 13.3978 0.5 13V3C0.5 2.60218 0.658035 2.22064 0.93934 1.93934C1.22064 1.65804 1.60218 1.5 2 1.5H14C14.3978 1.5 14.7794 1.65804 15.0607 1.93934C15.342 2.22064 15.5 2.60218 15.5 3V13C15.5 13.3978 15.342 13.7794 15.0607 14.0607C14.7794 14.342 14.3978 14.5 14 14.5V14.5Z"
                                    stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default CarClaims;
