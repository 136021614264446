import React from "react";
const Expertise = (props) => {
    return (
        <section className="section bg-funfact ServicesPageSBM">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section_title text-center">
                            <div className="title text-custom text-uppercase">Empower Your Business</div>
                            <h2 className="section_header">We Have Completed 2 Lacs + Policies Successfully.</h2>
                            <p className="sec_subtitle">Salvia esse nihil, flexitarian Truffaut synth art party deep v chillwave. Seitan High Life reprehenderit consectetur cupidatat kogi. Et leggings fanny pack. Cras chinwag brown bread.</p>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="project_count mt-3">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="text-center bg-white lan_funfact p-4 mt-3">
                                        <div className="fun_effect_light"></div>
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="1451">24</h1>
                                        <p className="lan_fun_name text-muted mb-4">Working Hours</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center bg-white lan_funfact p-4 mt-3">
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="1849">30000 +</h1>
                                        <p className="lan_fun_name text-muted mb-4">No. of Clients</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center bg-white lan_funfact p-4 mt-3">
                                        <div className="fun_effect_light"></div>
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="1451">2 Lacs</h1>
                                        <p className="lan_fun_name text-muted mb-4">Completed Policies</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center bg-white lan_funfact p-4 mt-3">
                                        <div className="fun_effect_light"></div>
                                        <h1 className="features_count mb-1 font-weight-bold mt-4" data-count="1451">50</h1>
                                        <p className="lan_fun_name text-muted mb-4">Team Member</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Expertise;
