import React from 'react';
let data = [
    {
        id: "PCV",
        src: require("../assets/img/RFL/Car.png"),
        name1: "PCV",
        name2: "Buses/Taxies",
    },
    {
        id: "GCV",
        src: require("../assets/img/RFL/Commercial.png"),
        name1: "GCV",
        name2: "Trucks/Goods",
    }
]
function TabTitle(props) {
    return props.isActive === props.dataTab ? (

        <a className="nav-item nav-link" onClick={props.onClick} id="nav-home-tab" data-toggle="tab" href={"#" + props.href} role="tab">
            <img src={props.src} />{props.name1} <span>{props.name2}</span></a>


    ) : (
        <a className="nav-item nav-link" onClick={props.onClick} id="nav-home-tab" data-toggle="tab" href={"#" + props.href} role="tab">
            <img src={props.src} />{props.name1} <span>{props.name2}</span></a>

    );
}
class CommercialInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: "PCV",
            data: data
        };
        this.changeActive = this.changeActive.bind(this);
    }

    changeActive(ev) {
        this.setState({ isActive: ev.target.href });
        console.log(ev.target.href)
    }

    render() {
        var listTitle = this.state.data.map((item) => (
            <TabTitle
                isActive={this.state.isActive}
                onClick={this.changeActive}
                href={item.id}
                src={item.src}
                name1={item.name1}
                name2={item.name2}
                key={item.id}
            />
        ));
        return (
            <div className="tab-pane fade active show" id="Commercial" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div className=" ">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="ProductFormIn ProductForm02Commercial">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="CoomercialTabs">
                                            <nav>
                                                <div className="nav nav-tabs pl-5 ml-4" id="nav-tab" role="tablist">
                                                    {listTitle}
                                                </div>
                                            </nav>
                                            <div className="tab-content" id="nav-tabContent">
                                                <div className="tab-pane fade" id="PCV" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <div className="ProductFormIn ProductForm02 ProductForm03 ProductForm03Commercial">
                                                        <div className="PF0012">
                                                            <div className="row">
                                                                <div id="" className="col-md-6 CommercialInsurer" >
                                                                    <label>
                                                                        <select id="ListInsurerPCV" className="SlcInsurer" onChange={this.props.pcvSelected}><option value="0">Select PCV Insurer</option><option value="124">DIGIT</option><option value="103">HDFC</option></select>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="row CommercialInsurerPCV SpaceBetweenRow002" >
                                                                {this.props.PCVselected ?
                                                                    <div className="row">


                                                                        <div className="col-md-6">
                                                                            <label id="">
                                                                                <input type="radio" className="warning hidden" id="rblRenewPCV" value="R" checked="checked" name="optradioPCV" data-toggle="modal" data-target="#myModalPCV" style={{ marginTop: "4px;" }} />To renew your vehicle policy <b>Click here</b>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label id="New">
                                                                                <input type="radio" id="rblInsuranceNewPCV" className="warning hidden" value="N" checked="checked" name="optradioPCV" data-toggle="modal" data-target="#myModalPCV" />Bought a new vehicle! <b>Click here</b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade " id="GCV" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <div className="ProductFormIn ProductForm02 ProductForm03 ProductForm03Commercial">
                                                        <div className="PF0012">
                                                            <div className="row">
                                                                <div id="" className="col-md-6 CommercialInsurer" style={{ display: "block" }}>
                                                                    <label>
                                                                        <select id="ListInsurerGCV" className="SlcInsurer" onChange={this.props.gcvSelected} ><option value="0">Select GCV Insurer</option><option value="124">DIGIT</option><option value="103">HDFC</option></select>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.GCVselected ?
                                                                    <div className="rowCommercialInsurerGCV SpaceBetweenRow002" style={{ display: "block" }}>
                                                                        <div className="row">


                                                                            <div className="col-md-6">
                                                                                <label id="Renew">
                                                                                    <input type="radio" className="warning hidden" id="rblRenewGCV" value="R" checked="checked" name="optradioGCV" style={{ marginTop: "4px;" }} data-toggle="modal" data-target="#myModalGCV" />To renew your vehicle policy <b>Click here</b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label id="New">
                                                                                    <input type="radio" id="rblNewGCV" className="warning hidden" value="N" checked="checked" name="optradioGCV" data-toggle="modal" data-target="#myModalGCV" />Bought a new vehicle! <b>Click here</b>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommercialInner;