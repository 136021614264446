/* eslint-disable */ 
import React from "react";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";
import { NewCar } from "../Car/NewCar";
import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";
import { Login } from "../Login/Login";
import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import "./assets/css/Dynamic.css";
import "./assets/css/hero.css";

import Claims from "./Claims";
import Epartner from "./Epartner";

import Chat, { ContactUs } from "./chat/Chat";
import "./chat/chat.css";
import Footer from "./footer/Footer";
import Error404 from "../Error/Error404";
import HeroMiddleSection from "./HeroMiddleSection";
import HeroContact from "./HeroContact/HeroContact"
import AboutUs from './AboutUs/AboutUs'
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
const findPath=()=>{
  let url=window.location.href.toLowerCase();
  let path="HRIKLS";
  if(url.match("proposalmotor") || url.match("proposalhealth"))
  path=window.location.pathname
  return path;
}
const HeroHome = (props) => {
 let path=findPath()
 
  return (
    <React.Fragment>
      <div className="heroHomeBody">
        <Layout>
       
         
        </Layout>
              
        <Route exact path="/Renew" component={Renew} />
        <Route exact path="/New" component={Renew} />
        
     
      <Switch>
     {/* <Route exact path='/myaccount' component={() => { 
        window.location.href = window.location.origin+'/myaccount'; 
        return null;
   }}/>*/}
      <Route exact path="/" component={HeroMiddleSection} />
     
        <Route exact path="/HealthQuotes" component={HealthQuotes} />
        <Route exact path="/HealthProposal" component={HealthProposal} />
        <Route
          exact
          path="/HealthShowProposal"
          component={HealthShowProposal}
        />
        <Route exact path="/counter" component={Counter} />
        <Route exact path="/fetch-data" component={FetchData} />
        <Route exact path="/GetQuote" component={MotorQuote} />
        <Route exact path="/Proposal" component={Proposal} />
        <Route exact path="/ShowProposal" component={ShowProposal} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/claim" component={Claims} />
          <Route exact path="/partner" component={Epartner} />
        <Route exact path="/contact" component={HeroContact} />
        <Route exact path={path} component={PaymentSuccessOrFail} />
       
              
         
         
        </Switch>
      <Footer />
      <Chat />
      <ContactUs />
      </div>
    </React.Fragment>
  );
};
export default HeroHome;
