import React from "react";
const OurInsurer = (props) => {
    return (
        <section class="py-5 OurInsurer">
                <div class="container all-in-one">
                    <h1 class="wc-heading">We scan <span class="span">all major insurers</span><br /><small>We will get you appropriate protection to meet your needs, not just the cheapest insurance. We are impartial and never favour one insurer over others</small></h1>

                    <div class="col-md-8 offset-md-2 partner-slider">
                        <div class="row">
                            <div class="col-sm-3">
                            <div class="col-12 partner">
                                <img src={require("../assets/img/inshora/partner/1.jpg")} />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner">
                                    <img src={require("../assets/img/inshora/partner/2.jpg")} />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner">
                                    <img src={require("../assets/img/inshora/partner/3.jpg")} />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/4.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/5.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/6.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/7.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/8.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/9.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/10.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/11.jpg")} class="img-fluid" /></div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col-12 partner"><img src={require("../assets/img/inshora/partner/12.jpg")} class="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};
export default OurInsurer;
