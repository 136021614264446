import React from "react";
import Claims from "../Claims/Claims";
const ClaimsPage = (props) => {
    return (
        <div className="ClaimsPage">
            <div className="ClaimsPageSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="ClaimsPageCnt">
                                <h1>Our team to give you best of the insurance products and Other Services</h1>
                                <div class="row counter-area-small">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/DemoTemp/CarSave.png")} alt="Premium" /> <br /> <span class="counter">9.3</span><span>+ Cr</span>
                                            <p>Premium</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/DemoTemp/TimeSave.png")} alt="Lives Covered" /><br /><span class="counter">9634</span><span>+</span>
                                            <p>Lives Covered</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 single-count-item-mb">
                                        <div class="single-count-area">
                                            <img src={require("./assets/img/DemoTemp/Money.png")} alt="Policies Sold" /><br /> <span class="counter">13095</span><span>+</span>
                                            <p>Policies Sold</p>
                                        </div>
                                    </div>
                                </div>

                                <small>
                                    *Standard Terms and Conditions Apply.<br />

                                    **Tax benefits are subject to changes in Income Tax Act.
                                </small>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <Claims />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ClaimsPageSec02 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="ClaimsPageSecCNTBox">
                                <h2>Health Insurance Claim Settlement Ratio</h2>
                                <p>Health insurance claim settlement is a procedure where a policyholder makes a request to his or her insurer in order to avail of the medical services listed under the health plan.</p>
                            </div>
                            <div className="ClaimsPageSecCNTBox">
                                <h2>Types of Insurance Claim</h2>
                                <div><h3>1. Cashless Claim Settlement Method</h3>
                                    <p>When you get medical treatment at a network hospital with your health insurance, then you can attain a cashless claim. As per the Cashless Claim Settlement Procedure, you need not pay anything to the hospital for the treatment and medical services. The insurer directly pays to the hospital subject to the amount of the claim. You will need to pay only in case of co-payments or a sub-limit clause that you have chosen during the plan purchase.</p>
                                    <h3>2. Reimbursement Claim Settlement Method</h3>
                                    <p>If you get treatment at a hospital outside the network of the insurer, you set foot in a Reimbursement Claim Settlement Procedure. As per this process, you have to pay for the treatment at the hospital and get it reimbursed later on from the insurance provider subject to the amount of the claim. The insurer is liable to pay the entire amount of the claim after considering all the voluntary or compulsory sub-limits, deductibles, etc. opted by you at the time of purchasing the policyholder.</p>
                                    <h2>5 Key Factors To Understand Claim Settlement Ratio in Health Insurance </h2>
                                    <p><strong>Calculation of the Claim Settlement Ratio</strong></p>
                                    <p>Calculating the health insurance Claim Settlement Ratio is not at all difficult. It can be found by using the simple formula given below: </p>
                                    <p><em>Claim Settlement Ratio (CSR) = (Total claims settled or paid)/(Total claims received+Outstanding claims at the beginning of the year) </em></p>
                                    <p>Some health insurance companies with impressive Claim Settlement Ratio for the Financial Year 2019-20 are IFFCO Tokio Health Insurance, Care Health Insurance, Magma HDI Health Insurance, Oriental Insurance, and so on. Customers find these insurance companies trustworthy enough due to their high Claim Settlement Ratios.</p>
                                    <p><strong>Measured For All Company Products</strong></p>
                                    <p>Not only for health, but the Claim Settlement ratio is calculated for all the company products put together as well. For instance, the Claim Settlement Ratio of a life insurance company will include the claim settlement of the plans it offers, ULIPs, etc. The CSr is not provided for a single product offered by an insurance company.</p>
                                    <p><strong>Consistency</strong></p>
                                    <p>The Claim Settlement Ratio of an insurance company should be consistent. So, when choosing an insurance company selling health insurance plans, you must check for its Claim Settlement ratio of the past 5 years. You should opt for it if it has a high Claim Settlement ratio and if it has been consistent in settling claims throughout. This shows that the insurance company is capable enough to settle claims.</p>
                                    <p><strong>Annual Report by IBAI</strong></p>
                                    <p>Every year, the Insurance Brokers Association of India (IBAI) in its General Insurance Claim Insights Handbook For Policyholders, publishes the Claim Settlement Ratio of all the public and private insurance companies. The report is available for all and can be found on the official website of IBAI. You must go through the same before shortlisting an insurance company when buying health insurance in India.</p>
                                    <p><strong>Number of Claims Received By an Insurance Company</strong></p>
                                    <p>Another factor to check is the number of claims received by an insurance company during a financial year. This gives you an estimate of how big and old the insurance company is. To find insurance companies with an impressive Claim Settlement Ratio, you can visit the Health Insurance Knowing companies with the best Claim Settlement Ratio will help you make an informed decision when buying health insurance in India.</p>
                                    <h2>Documents Required for Health Insurance Claim Settlement</h2>
                                    <p>Here are the following documents you need to have for the successful claim settlement of health insurance:</p>
                                    <ol>
                                        <li>Original investigation reports</li>
                                        <li>Pharmacy bills along with the prescription</li>
                                        <li>FIR or post-mortem report if happened</li>
                                        <li>Original bills, receipts, and discharge report</li>
                                        <li>Original hospital bills and a valid photo ID proof</li>
                                        <li>Treating doctor's report, and original consultation notes</li>
                                        <li>Final hospital discharge summary</li>
                                        <li>Indoor case papers and duly-filled claim form</li>
                                        <li>Test reports along with attending doctor�s or surgeon�s report</li>
                                        <li>Nature of operation performed and surgeon's bill and receipt</li>
                                    </ol>
                                    <h2>How to Make a Cashless Medical Insurance Claim?</h2>
                                    <p><span>Listed below is the </span><span>health insurance claim process for cashless treatment</span><span> followed by the maximum insurers. However, the procedure may differ from insurer to insurer:</span></p>
                                    <p><strong>Step 1:</strong> Find the network hospital: Firstly, you should look for a network hospital to seek a cashless treatment</p>
                                    <p><strong>Step 2:</strong> Intimate the insurer: Intimate the insurer about your hospitalization within 24 hours in the case of an emergency (subject to the policy wording) and 48 hours prior to the hospitalization in the planned admission case (subject to the policy wording).</p>
                                    <p><strong>Step 3:</strong> Complete the documentation process: Visit the network hospital with your cashless card or the member ID or the policy number. Show the policy number or the member ID or health insurance card at the hospital�s insurance desk and duly fill-up the pre-authorization form available at the hospital.</p>
                                    <p><strong>Step 4:</strong> Claim Settlement: The hospital forwards your filled pre-authorization form to the insurer. At the insurance provider�s end, the claim settlement team examines and reviews the received details of the claim and conveys the hospital and policyholder about the approval or rejection.</p>
                                    <p><strong>Step 5:</strong> Payment of bills: If the insurer approves the bills, the medical bill is directly paid to the hospital, whereas, if rejected, the hospital bill has to be borne by the policyholder.</p>
                                    <h2>Claim Settlement Process for Health Insurance Reimbursement Claims</h2>
                                    <p><span>Listed below is the</span><span> health insurance claim process for reimbursement </span><span>followed by most of the insurance companies. However, the procedure may differ from insurer to insurer:</span></p>
                                    <p><strong>Step 1:</strong> Intimate the insurer: First, intimate your insurance provider about your hospitalization within 24 hours in the case of emergency admission and 48 hours prior to the hospitalization in the planned admission case. The timeline in both the case varies from insurer to insurer.</p>
                                    <p><strong>Step 2:</strong> Complete the documentation process: Visit the hospital (network or non-network hospital) with your ID. Get the reimbursement form from the insurer or you can download it from the insurer�s website. Fill up the claim form. Annexe all the documents mentioned above. Either submit them physically to the insurer�s office or send them via courier.</p>
                                    <p><strong>Step 3:</strong> Payment of bills: After examining the documents the insurer�s claim settlement team sends the letter of approval to the policyholder. In the case of approval of the claim, the amount is reimbursed within 30 days. The reimbursement process may vary from insurer to insurer.</p>
                                    <p><strong>Step 4:</strong> Claim Settlement: If the claim is rejected, the insured person is asked to revert to the insurer�s query or is provided with the reason for the rejection.</p>
                                    <h2>Steps to File Health Insurance Claim Form</h2>
                                    <p>There are two ways of filing health insurance claims. The most convenient one is when the insurance provider directly submits the claim to the insurance company, electronically. The second way is to complete the claim form and submit the paperwork to the insurance company directly.</p>
                                    <p>To file health insurance claim form, you are required to follow the steps given below:</p>
                                    <ul>
                                        <li><strong>Obtain itemized receipts: </strong>An itemized bill consists of every service that your doctor has offered you, with the cost of the services. These include the cost of medicines as well. Your insurance company will require the bills at the time of claim settlement.</li>
                                        <li><strong>Get your claim form: </strong>To get a copy of your claim form, you may take help from your insurance company, or visit their official website to download the form from there. In the form, you will also get to know other details that you might need to provide.</li>
                                        <li><strong>Make copies: </strong>You must make copies of every document to avoid any discrepancy at the time of claim. Also, it will help you to refile the claim in case the documents get lost.</li>
                                        <li><strong>Review and send the form:</strong> Call your insurance company and inform them in advance that you are about to file a health insurance claim. Review all the paperwork and then send them to the insurance company. Ask them in case they have any further requirements. Also, know until when will you receive response on the claim filed. Also, notify the insurance company in case you do not get any response on the claim within the stipulated period.</li>
                                    </ul>
                                    <h2>How to Check Health Insurance Claim Status?</h2>
                                    <p>To check the status of your health insurance claim online, you are required to follow the steps given below:</p>
                                    <ul>
                                        <li>Visit the official website of the insurance company.</li>
                                        <li>Visit the claim page.</li>
                                        <li>Enter the required details such as policy number, customer ID, claim number, etc. and submit.</li>
                                        <li>Check the claim status.</li>
                                    </ul>
                                    <h2>Common Reasons For The Rejection of Health Insurance Claim</h2>
                                    <p>Given below are some common reasons that result in the rejection of medical insurance claims by insurance providers:</p>
                                    <ol>
                                        <li>On making a fraudulent claim by the policyholder</li>
                                        <li>If you raise a claim for an exclusion of your health plan</li>
                                        <li>Raising a claim on the basis of an expired insurance policy</li>
                                        <li>Asking for a claim for the treatment not listed under the health plan</li>
                                        <li>Getting treatment for a pre-existing illness without completing the waiting period</li>
                                    </ol>
                                    <h3>Claim Repudiation Ratio</h3>
                                    <p>A claim repudiation ratio basically gives information regarding the percentage of claims rejected by the insurance provider over the total number of claims received. For instance, a claim repudiation of 30% implies 30 cases over a total of 100 cases are rejected.</p>
                                    <h3>Claim Pending Ratio</h3>
                                    <p>A claim pending ratio basically gives information regarding the percentage of outstanding claims by the insurer over the total number of claims received. It happens due to the incomplete information provided to the insurer and the time taken by them to validate the incurred costs at the time of hospitalisation.</p>
                                    <h3>Incurred Claims Ratio</h3>
                                    <p>An incurred claim ratio gives information regarding the net claims settled over the net premium collected by the insurance company in a given year.</p>
                                    <h3>CSR or ICR, Which of the Two Should We Look at?</h3>
                                    <p>While buying a health insurance plan, you should consider a high claim settlement ratio. However, it should not be the only criteria to choose a good insurance company. Apart from this, you should consider factors like quality of service, premium amount etc while making a decision.</p>
                                    <h3>How do Claims Ratio Data Help me?</h3>
                                    <p>Claims ratio data helps in understanding the history of the claim handling procedure of the insurance provider. Also, claims data helps us in comparison and making an analysis of the insurance providers that have been operating for the same time duration. In case you find two insurance companies providing the same benefits, a comparison of the claim settlement ratio of the two companies can help in arriving at a decision.</p>
                                    <h3>Does the Claim Settlement Have a Time Limit?</h3>
                                    <p>Yes, a health insurance policy in India comes with a time limit in consideration to applying for a claim in order to mitigate the chances of fraudulent activities. An application deadline means less time to falsify the documents.</p>
                                    <h3>How to Check the Claim Settlement Ratio of Health Insurance?</h3>
                                    <p>You can check the annual report released by the Insurance Regulatory and Development Authority of India(IRDAI) to know the claim of insurance companies.</p>
                                    <h3>What is the Claim in Health Insurance?</h3>
                                    <p>A claim in health insurance is a request made by the policyholder to the insurance company so as to get the facilities that are mentioned in the health insurance plan.</p>
                                    <h2>Things to Remember for a Smooth Health Insurance Claim Process</h2>
                                    <p><span>To have a smooth claim settlement process, you must take care of the following:</span></p>
                                    <ul>
                                        <li><strong>Hospitalisation of at least 24 hours is required: </strong><span>Generally, the insured is required to get hospitalised for at least 24 hours to be able to make a claim and avail coverage benefits under a particular health insurance policy. However, there are a few daycare and other listed procedures that do not require hospitalisation of a minimum 24 hours. So, make sure that you make a claim only for those expenses which are covered under your health insurance policy.</span></li>
                                        <li aria-level="1"><strong>Sub-limits on listed coverage features:</strong><span> Some coverage benefits come with limits on them. For instance, there is a restriction on the type of room you can get admitted to, or the cover room rent expenses under your health insurance policy. In case you make a claim for the expenses that your health insurance policy do not cover, then there are high chances of it being rejected.</span></li>
                                        <li aria-level="1"><strong>Waiting period on specific diseases: </strong><span>Waiting period is the time that you have to wait for, to be eligible to make a claim under your health insurance policy. Claims during this period are not admissible and hence you must be aware of the same to have a smooth claim settlement process. Generally, there is a waiting period for pre-existing diseases, maternity expenses, To know more about waiting periods, you must read the health insurance policy document thoroughly.</span></li>
                                        <li aria-level="1"><strong>Co-payments and exclusions:</strong><span> Co-payments and exclusions are the limiting conditions that have a major impact on health insurance claims. Co-payment is when you and the insurance company pay partially for the expenses incurred during treatment. As you are required to pay too at the time of claim, you must keep yourself informed in advance to avoid any discrepancy at the time of claim. Same is the case with exclusions. Exclusions are those expenses that the insurance company does not cover you for. Common exclusions under health insurance plans include critical illnesses, ailments related to mental disorder, abuse of intoxicants like alcohol, drugs, treatments required due to participation in adventure sports, etc.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ClaimsPage;
