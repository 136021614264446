import React from "react";
const Product = [
    {
        id: 1,
        name: "Car Insurance",
        to: "/Car",
        className: "nav-item nav-link",
        src: require("./assets/img/Vedant/Car.png"),
    },
    {
        id: 2,
        name: "2 Wheeler Insurance",
        to: "/Bike",
        className: "productTabsRisk",
        src: require("./assets/img/Vedant/Bike.png"),
    },

    
    {
        id: 3,
        name: "GCV Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Vedant/GCV.png"),
    },
    {
        id: 4,
        name: "PCV Insurance",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/Vedant/PCV.png"),
    },
    {
        id: 5,
        name: "Health Insurance",
        className: "productTabsRisk",
        to: "/Health",
        src: require("./assets/img/Vedant/Health.png"),
    },
    {
        id: 6,
        name: "Life Insurance",
        className: "productTabsRisk",
        to: "/Life",
        src: require("./assets/img/Vedant/Life.png"),
    },
];
export default Product;
