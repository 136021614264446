import React from "react";
import "./services.css";
import "react-reveal";
import HowItWork from "./HowItWork";
import SectionSecond from "./SectionSecond";
import SectionThird from "./SectionThird";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSIX from "./SectionSIX";
import OurPartner from "../OurPartner";
import SectionEaight from "./SectionEaight";
import Accordian from "./FAQAccordian/Accordian";
const Services = (props) => {
    return (
        <React.Fragment>
            <HowItWork />
            <SectionSecond />
            <SectionThird />
            <SectionFour />
            <SectionFive />
            <SectionSIX />
            <Accordian />
            <OurPartner />
        </React.Fragment>
    );
};
export default Services;
