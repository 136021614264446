import React from "react";
import { Redirect, Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Layout } from "../components/Layout";



import { Car } from "../Car/Car";
import { Bike } from "../TW/Bike";
import { Renew } from "../Car/Renew";

import { MotorQuote } from "../Car/MotorQuote";
import { Proposal } from "../Car/Proposal";
import { ShowProposal } from "../Car/ShowProposal";

import { Health } from "../Health/Health";
import { HealthQuotes } from "../Health/HealthQuote";
import { HealthProposal } from "../Health/HealthProposal";
import { HealthShowProposal } from "../Health/HealthShowProposal";
import About from "./About";
import Footer from "./footer/Footer";

import "./assets/css/Religare.css";
import Claims from "./Claims";
import Epartner from "./Epartner";
import Contact from "./Contact";

import { NavMenu } from "./NavMenu";
import Tnx from "../components/Tnx";
import SideNav from "../side/SideNav";
import SocialToggle from "../side/SocialToggle";
import PaymentSuccessOrFail from "../components/PaymentSuccessOrFail";
import LifeQuote from "../Life/LifeQuote";
import ReligareMiddleSection from "./ReligareMiddleSection";
import MotorInsurance from "./FooterPages/MotorInsurance";
import PrivateCarInsurance from "./FooterPages/PrivateCarInsurance";
import TwoWheelerInsurance from "./FooterPages/TwoWheelerInsurance";
import HealthInsurance from "./FooterPages/HealthInsurance";
import FamilyFloater from "./FooterPages/FamilyFloater";
import Individualcoverforfamily from "./FooterPages/Individualcoverforfamily";
import HealthInsuranceforSeniorCitizens from "./FooterPages/HealthInsuranceforSeniorCitizens";
import LifeInsurance from "./FooterPages/LifeInsurance";
import TermPlans from "./FooterPages/TermPlans";
import EndowmentPlans from "./FooterPages/EndowmentPlans";
import UnitLinkedPlans from "./FooterPages/UnitLinkedPlans";
import MoneyBackPolicy from "./FooterPages/MoneyBackPolicy";
import PensionPlan from "./FooterPages/PensionPlan";
import LifeProposal from "../Life/LifeProposal";
const findPath=()=>{
    let url=window.location.href.toLowerCase();
    let path="HRIKLS";
    if(url.match("proposalmotor") || url.match("proposalhealth"))
    path=window.location.pathname
    return path;
  }
const Religare = (props) => {
    let apiPath = findPath()
    return (
        <React.Fragment>
            <div className="Religarebody">
                <NavMenu />


                <Layout>
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Car" component={Car} />
                    <Route exact path="/Bike" component={Bike} />
                    <Route exact path="/Renew" component={Renew} />
                    <Route exact path="/New" component={Renew} />
                </Layout>
                <Switch>
                    <Route exact path="/" component={ReligareMiddleSection} />
                    <Route exact path="/GetQuote" component={MotorQuote} />
                    <Route exact path="/Proposal" component={Proposal} />
                    <Route exact path="/ShowProposal" component={ShowProposal} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/HealthQuotes" component={HealthQuotes} />
                    <Route exact path="/HealthProposal" component={HealthProposal} />
                    <Route exact path="/GetQuoteLife" component={LifeQuote} />
                    <Route exact path="/HealthShowProposal" component={HealthShowProposal} />
                    
                    
                    <Route path="/About" component={About} />
                    <Route path="/claims" component={Claims} />
                    <Route path="/ePartner" component={Epartner} />
                    <Route path="/contact-us" component={Contact} />
                    <Route exact path="/ThankYou" component={Tnx} />
                    <Route exact path={apiPath} component={PaymentSuccessOrFail} />
                    <Route exact path="/MotorInsurance" component={MotorInsurance} />
                    <Route exact path="/PrivateCarInsurance" component={PrivateCarInsurance} />
                    <Route exact path="/TwoWheelerInsurance" component={TwoWheelerInsurance} />
                    <Route exact path="/HealthInsurance" component={HealthInsurance} />
                    <Route exact path="/FamilyFloater" component={FamilyFloater} />
                    <Route exact path="/Individualcoverforfamily" component={Individualcoverforfamily} />
                    <Route exact path="/HealthInsuranceforSeniorCitizens" component={HealthInsuranceforSeniorCitizens} />
                    <Route exact path="/LifeInsurance" component={LifeInsurance} />
                    <Route exact path="/TermPlans" component={TermPlans} />
                    <Route exact path="/EndowmentPlans" component={EndowmentPlans} />
                    <Route exact path="/UnitLinkedPlans" component={UnitLinkedPlans} />
                    <Route exact path="/MoneyBackPolicy" component={MoneyBackPolicy} />
                    <Route exact path="/PensionPlan" component={PensionPlan} />
                    <Route exact path="/LifeProposal" component={LifeProposal} />

                </Switch>
                <Footer />
                <SideNav />
                <SocialToggle mobile="9867248189" email="support@lifemartinsurancebrokers.com" whatsapp="9867248189" />
            </div>
        </React.Fragment>
    );
};
export default Religare;
