/* eslint-disable */ 
import React from 'react';
const LoaderBox=[{
    id:1,
    src:"https://riskoveryinsurance.com/insurer%20logo/universal-logo.png",
    IDV:"207629"
},
{
    id:2,
    src:"https://riskoveryinsurance.com/insurer%20logo/Birla.jpg",
    IDV:"440212"
},{
    id:3,
    src:"https://riskoveryinsurance.com/insurer%20logo/star-logo.png",
    IDV:"440919"
},{
    id:4,
    src:"https://riskoveryinsurance.com/insurer%20logo/religare-logo.png",
    IDV:"326767"
},{
    id:5,
    src:"https://riskoveryinsurance.com/insurer%20logo/appolo.png",
    IDV:"331857"
},{
    id:6,
    src:"https://riskoveryinsurance.com/insurer%20logo/hdfchealth-logo.png",
    IDV:"435500"
},
]
export default LoaderBox;