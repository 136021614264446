import React from "react";
const Product = [
    {
        id: 1,
        name: "Car",
        to: "/Car",
        className: "nav-item nav-link",
        src: require("./assets/img/PolicyLeader/Car.png"),
    },
    {
        id: 2,
        name: "2 Wheeler",
        to: "/Bike",
        className: "productTabsRisk",
        src: require("./assets/img/PolicyLeader/Bike.png"),
    },
    {
        id: 3,
        name: "Health",
        className: "productTabsRisk",
        to: "/Health",
        src: require("./assets/img/PolicyLeader/Health.png"),
    },
    {
        id: 4,
        name: "PCV",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/PolicyLeader/Commercial.png"),
    },
    {
        id: 5,
        name: "GCV",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/PolicyLeader/Commercial.png"),
    },
    {
        id: 6,
        name: "Marine",
        className: "productTabsRisk",
        to: "/LifeDemo",
        src: require("./assets/img/PolicyLeader/Marine.png"),
    },
];
export default Product;
