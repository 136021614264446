import React from "react";
import "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const MeetTeam = (props) => {
    let settings = {
        slidesToShow: 4,

        autoplay: true,
        slidesToScroll: 1,
        infinite: true,

    };
    return (
        <section class="agent-section pad-tb" id="agent">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-6">
                        <div class="about-company common-heading">
                            <h2 class="mb20 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">Meet The <em>Agents</em></h2>
                            <p data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt30">
                    <div class="col-lg-3 col-6 mt30 aos-init" data-aos="fade-In" data-aos-delay="100">
                        <div class="full-image-card hover-scale">
                            <div class="image-div"><a href="#"><img src={require("../assets/img/Vedant/team-1.jpg")} alt="team" class="img-fluid" /></a></div>
                            <div class="info-text-block">
                                <h5><a href="#">Shakita Daoust</a></h5>
                                <p>Insurance Agent</p>
                                <div class="social-links-">
                                    <a href="#" target="blank"><i class="fab fa-facebook-f"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-twitter"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-linkedin-in"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt30 aos-init" data-aos="fade-In" data-aos-delay="300">
                        <div class="full-image-card hover-scale">
                            <div class="image-div"><a href="#"><img src={require("../assets/img/Vedant/team-2.jpg")} alt="team" class="img-fluid" /></a></div>
                            <div class="info-text-block">
                                <h5><a href="#">Gerard Licari</a></h5>
                                <p>Insurance Agent</p>
                                <div class="social-links-">
                                    <a href="#" target="blank"><i class="fab fa-facebook-f"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-twitter"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-linkedin-in"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt30 aos-init" data-aos="fade-In" data-aos-delay="500">
                        <div class="full-image-card hover-scale">
                            <div class="image-div"><a href="#"><img src={require("../assets/img/Vedant/team-3.jpg")} alt="team" class="img-fluid" /></a></div>
                            <div class="info-text-block">
                                <h5><a href="#">Cary Montgomery</a></h5>
                                <p>Insurance Agent</p>
                                <div class="social-links-">
                                    <a href="#" target="blank"><i class="fab fa-facebook-f"></i> </a>
                                    <a href="#" target="blank"><i class="fas fa-twitter"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-linkedin-in"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt30 aos-init" data-aos="fade-In" data-aos-delay="700">
                        <div class="full-image-card hover-scale">
                            <div class="image-div"><a href="#"><img src={require("../assets/img/Vedant/team-4.jpg")} alt="team" class="img-fluid" /></a></div>
                            <div class="info-text-block">
                                <h5><a href="#">Herman Running</a></h5>
                                <p>Insurance Agent</p>
                                <div class="social-links-">
                                    <a href="#" target="blank"><i class="fab fa-facebook-f"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-twitter"></i> </a>
                                    <a href="#" target="blank"><i class="fab fa-linkedin-in"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default MeetTeam;
