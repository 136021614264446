import React, { Component } from 'react';
import FeatureBgClass from './FeatureBgClass';
class SocialToggle extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isToggle:false,
            changeSocial:true, 
            }
        this.setToggle=this.setToggle.bind(this)
       
    }
    setToggle(){
        this.setState({isToggle:!this.state.isToggle,changeSocial:!this.state.changeSocial})
    }
    
    render() { 
        return ( <div className="socialResource">
        <div className="row">
            <div className="col-md-4 col-md-offset-4">
                    <div className="material-button-anim">
                        <button className={"material-button material-button-toggle " + FeatureBgClass()} type="button" onClick={this.setToggle}>
                        {this.state.changeSocial?<span className="fa fa-plus" aria-hidden="true"></span> : <i className="fa fa-times" aria-hidden="true"></i>}
                    </button>
                   
                    {
                        this.state.isToggle?
                    <ul className="listInline" id="options">
                        <li className="option scale-on">
                            <a href={"tel:" + this.props.mobile}>
                                            <button className={"material-button option1 "+ FeatureBgClass()} type="button">
                                <i className="fa fa-phone" aria-hidden="true" style={{fontWeight:"900"}}></i>
                                </button>
                            </a>
                        </li>
                        <li className="option scale-on">
                            <a href={"mailto:"+this.props.email}>
                                            <button className={"material-button option2 "+ FeatureBgClass()} type="button">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                </button>
                            </a>
                        </li>
                        <li className="option scale-on">
                            <a href={"https://api.whatsapp.com/send?phone="+this.props.whatsapp+"&amp;text=&amp;source=&amp;data=&amp;app_absent="} target="_blank">
                                            <button className={"material-button option3 "+ FeatureBgClass()} type="button">
                                <i className="fab fa-whatsapp" aria-hidden="true"></i>
                                </button>
                            </a>
                        </li>
    
                    </ul>
               
                      :<ul className="listInline" id="options">
                      <li className="option">
                          <a href={"tel:" + this.props.mobile}>
                                            <button className={"material-button option1 "+ FeatureBgClass()} type="button">
                                  <span className="fas fa-phone-alt" aria-hidden="true"></span>
                              </button>
                          </a>
                      </li>
                      <li className="option">
                          <a href={"mailto:"+this.props.email}>
                                            <button className={"material-button option2 "+ FeatureBgClass()} type="button">
                                  <span className="fas fa-envelope" aria-hidden="true"></span>
                              </button>
                          </a>
                      </li>
                      <li className="option">
                          <a href={"https://api.whatsapp.com/send?phone="+this.props.whatsapp+"&amp;text=&amp;source=&amp;data=&amp;app_absent="} target="_blank">
                                            <button className={"material-button option3 "+ FeatureBgClass()} type="button">
                                  <span className="fab fa-whatsapp" aria-hidden="true"></span>
                              </button>
                          </a>
                      </li>
  
                  </ul>  }
               
                        </div>
                         
            </div>
        </div>
    </div> );
    }
}
 
export default SocialToggle;