import MasterGlobal from "../App"
const ApiUrl = () => {
   
    let model = {
        CurrentUrl: window.location.hostname,
    };
    fetch("/bpi/WeatherForecast/GetAPIUrls", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(model),
    })
        .then((response) => response.json())
        .then((data) => {
            MasterGlobal.CRMAPI = data.crmapi;
            MasterGlobal.PortalAPI = data.portalAPI;
            window.localStorage.setItem("portal", data.portalAPI)
            window.localStorage.setItem("crm", data.crmapi)
            //this.loadLogo(data.crmapi);
        });
        return null
}
export default ApiUrl