import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import CallUs from "./CallUs";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>

            <section class="AdvnFrstSec">
                <div class="container">
                    <div class="homeUserBoxMain">
                        <div class="row">
                            <div class="col-md-4 no-padding">
                                <div class="homeUserBox wow fadeInLeft" data-wow-duration="1s">
                                    <img src={require("../assets/img/Advance/hmUser01.jpg")} />
                                    <span>We give you<br /> personal attention</span>
                                </div>
                            </div>
                            <div class="col-md-4 homeUserBoxBlue no-padding">
                                <div class="homeUserBox wow zoomIn" data-wow-duration="1s">
                                    <img src={require("../assets/img/Advance/hmUser02.jpg")} />
                                    <span>We help to find the<br /> best plans</span>
                                </div>
                            </div>
                            <div class="col-md-4 no-padding">
                                <div class="homeUserBox fadeInRight" data-wow-duration="1s">
                                    <img src={require("../assets/img/Advance/hmUser03.jpg")} />
                                    <span>We assist for the <br />best!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>
            <Zoom>
                <CallUs />
            </Zoom>



        </React.Fragment>
    );
};
export default Services;
