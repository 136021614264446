/* eslint-disable */
import React from "react";
import MaskedInput from 'react-text-mask'
import SimpleMasg from "./services/SimpleMasg";
import SocialConnect from "./services/SocialConnect";
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            isValid: false,
        }
        this.handle = this.handle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handle(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields })
        console.log(this.state.fields)
    }
    handleSubmit() {

        let errors = {}
        let isValid = true;
        let field = this.state.fields
        if (field["typeList"] === null || field["typeList"] === undefined || field["typeList"] === "") {
            errors["typeList"] = "Select Your Insurer Type"
            isValid = true;
        }
        if (field["MobileNo"] === null || field["MobileNo"] === undefined || field["MobileNo"] === "") {
            errors["MobileNo"] = "Enter Your Mobile Number"
            isValid = true;
        } else if (field["MobileNo"] !== null || field["MobileNo"] !== undefined || field["MobileNo"] !== "") {
            if (field["MobileNo"].length < 10) {
                isValid = false;
                errors["MobileNo"] = "Please Enter Valid Mobile Number"
            }
            if (field["time"] === null || field["time"] === undefined || field["time"] === "") {
                errors["time"] = "Select Your Preferred Time"
                isValid = true;
            }
        }
        this.setState({ errors, isValid })

    }
    render() {

        return (
            <React.Fragment>
                <div class="dlab-bnr-inr contact-page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 offset-md-2">
                                <form class="inquiry-form wow fadeInUp dzForm contact-form" data-wow-delay="0.2s" action="#">
                                    <div class="dzFormMsg"></div>
                                    <h3 class="box-title m-t0 m-b10 text-center">We support our customers<span class="span"> 24x7.</span><span class="bg-primary"></span></h3>
                                    <div class="row py-5">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-user"></i></span>
                                                    <input name="name" type="text" required="" class="form-control" placeholder="Full Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-mobile"></i></span>
                                                    <input name="phone" type="text" required="" class="form-control" placeholder="Phone" maxlength="10" minlength="10" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-email"></i></span>
                                                    <input name="email" type="email" class="form-control" required="" placeholder="Your Email Id" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="ti-write"></i></span>
                                                    <select class="form-control" name="message" required="">
                                                        <option>I want to...</option>
                                                        <option>Buy Car Insurance</option>
                                                        <option>Buy Bike Insurance</option>
                                                        <option>Buy Health Insurance</option>
                                                        <option>Buy Term Insurance</option>
                                                        <option>Buy Travel Insurance</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                    <input class="form-control d-none" data-recaptcha="true" required="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <button name="send" type="submit" value="Submit" class="site-button button-lg cs-button"> <span>Call me</span> </button>
                                        </div>
                                    </div>
                                </form>
                                <div class="col-12">
                                    <h4 class="con-eme-heading">Is it super urgent? Call us on<span class="span"> Following numbers.</span></h4>
                                    <div class="contact-emrg-number">

                                        <a href="tel:9811994580" class="emergency-number">
                                            <i class="ti-mobile"></i>
                                            <p>9811994580/ 9811994581</p>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12 ContactAddress">
                                    <h4 class="con-eme-heading">Visit us at</h4>
                                    <div class="contact-emrg-number">
                                        <a href="tel:011-40456060" class="emergency-number">
                                            <i class="ti-location-pin m-r5"></i>
                                            <p><strong>Registered office-</strong><br />D-484, 2nd Floor, Palam Extension, Sec-7, Dwarka, New Delhi-110077</p>
                                        </a>
                                        <a href="tel:011-40456060" class="emergency-number">
                                            <i class="ti-location-pin m-r5"></i>
                                            <p><strong>Branch office-</strong><br />1st Floor, Above Oriental Bank of Commerce, Kusumkhera, Haldwani, Uttarakhand-263239</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SocialConnect />
                <SimpleMasg />
            </React.Fragment>
        );
    };
}
export default Contact;
