import React from "react";

const TNC_Enivesh = () => {
    return (
        <React.Fragment>
            <section className="TermsAndCondition">
            <div className="container">
                <p>ENIVESH TESTIMONIALS </p>
                
                <ul>
                    <li>Name - Dhiraj Sagar</li>
                    <li>Designation – Senior Producer.</li>
                    <li>Testimonial – &ldquo;Enivesh Team was very helpful throughout the  process. They updated me whenever I  asked them. The insurance company though wasn't as prompt as Enivesh at certain  stages. But they ironed out the process at regular intervals. Relationship  Manager's tone of talking too is very enterprising and tries their best to  solve the problems we face. My Overall experience was good.&rdquo;</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>
                
                <ul>
                    <li>Name - Mahesh Lekhrao.</li>
                    <li>Designation – Producer</li>
                    <li>Testimonial – &ldquo;My experience allover was very good, the claim was  settled very easily without any problem, I would like to thank the ENIVESH team  for helping me a lot in settling this claim  smoothly.&rdquo;</li>
                </ul>
                <p>Thanks.!</p>
                <ul>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>
                <ul>
                    <li>Name - Anand Moitra</li>
                    <li>Designation – Assistant General  Manager</li>
                    <li>Testimonial – &ldquo;They are superb&rdquo;!!</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>

                <ul>
                    <li>Name - Ashok Raghav</li>
                    <li>Designation – Manager</li>
                    <li>Testimonial – &ldquo;Satisfied&rdquo;</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>

                    </li>
                </ul>
               
                <ul>
                    <li>Name - Yasmin Shaikh</li>
                    <li>Designation – Accountant</li>
                    <li>Testimonial – &ldquo;Overall experience is very good&rdquo;</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>

                <ul>
                    <li>Name - Pankaj Pervi</li>
                    <li>Designation – Admin</li>
                    <li>Testimonial – &ldquo;Very helpful&rdquo;</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>
                <ul>
                    <li>Name - Saimohan Maddula</li>
                    <li>Designation – Garment Trader</li>
                    <li>Testimonial – &ldquo;Everything is fine&rdquo;</li>
                    <li>Rating –
                    <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul>
                </div>
            </section>
        </React.Fragment>
    );
};
export default TNC_Enivesh;
