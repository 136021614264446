import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { Component } from "react";

import MaskedInput from 'react-text-mask';
import axios from "axios";
import ContinueTOquote from "../../components/ContinueTOquote";

class InsureCar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            fields: { registration: "" },
            errors: {},
            isValid: false,
            registration: null,
            RTOList: [],
            ValidRTO: false,
            HoldRequest: {},
            SameReq: false,
            proceed: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.goPrev = this.goPrev.bind(this);
        let HoldRequest = window.localStorage.getItem("ProceedWithSame");
        if (HoldRequest !== null) {
            this.state.HoldRequest = JSON.parse(HoldRequest);
            this.state.SameReq = true;
            this.setState({ HoldRequest: JSON.parse(HoldRequest), SameReq: true })
        }
        this.checkRto = this.checkRto.bind(this);
        axios.get("https://centralapi.stariglocal.com/api/RTO/GetRtos")
            .then(Res => {
                console.log(Res.data)
                Res.data.forEach(element => {
                    this.state.RTOList.push(element.RTOName.split(" ")[0])
                });
            })
    }
    checkRto(rto) {
        let ValidRTO = true;
        let selectedRTO = this.state.RTOList.filter(row => row.toLowerCase().includes(rto.substring(0, 4).toLowerCase()));
        if (selectedRTO.length > 0) {
            ValidRTO = true;

        } else {
            ValidRTO = false

        }
        this.setState({ ValidRTO });
        return ValidRTO;
    }
    componentDidMount() {
        console.log(this.state.HoldRequest)
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields, [e.target.name]: e.target.value
        });
        let errors = {};
        let isValid = true;
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] == "") {
            isValid = false
            errors["registration"] = "Please Enter Correct Number"
        }
        else if (this.state.fields["registration"] !== undefined) {
            if (!this.checkRto(e.target.value)) {
                isValid = false;
                errors["registration"] = "Please Enter Correct RTO"
            } else if (this.state.fields["registration"].length < 9) {
                isValid = false;
            }
        }
        this.setState({ errors, isValid, fields })
    }


    goPrev() {
        window.sessionStorage.removeItem("QuoteResponse");
        this.setState({ proceed: !this.state.proceed })
    }


    submit() {
        if (this.state.fields["registration"] === undefined || this.state.fields["registration"] === "")
            return alert("Please enter Registration Number ")
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    render() {

        return (
            <div>
                {this.state.proceed && <Redirect to={{
                    pathname: "/GetQuote",
                    state: { Req: this.state.HoldRequest }
                }} />}
                    <div className="productTabsMainCar">
                        <h3>
                            <span className="hiddenPart">Find better plans for your</span>
                            <strong>&nbsp;{this.props.Motor} Insurance</strong>
                        </h3>
                        <h6>
                            <p>Get best offers with attractive premium</p>
                        </h6>

                        <div className="ProductFormIn ProductForm01">
                            {this.state.SameReq && this.state.HoldRequest.QuoteReq.VehicleType === this.props.type ? <ContinueTOquote Model={this.state.HoldRequest.CompModel.Vehicle} Variant={this.state.HoldRequest.CompModel.variant}
                                ncb={this.state.HoldRequest.QuoteReq.PreviousNCB} Reg={this.state.HoldRequest.QuoteReq.YearVal} Proceed={this.goPrev} closeContinue={() => this.setState({ SameReq: !this.state.SameReq })}
                            /> :
                                <div className="PF0012 SpaceBetweenRow002">
                                    <div className="row">

                                        <React.Fragment>
                                            <div className="col-md-8">
                                                <label>
                                                    <MaskedInput onChange={this.handleChange} mask={[/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/, '-', /[A-Za-z\d]/, /[A-Za-z\d]/, /[A-Za-z\d]/, /\d/, /\d/, /\d/, /\d/]} guide={false} showMask={false} placeholder={`Enter ${this.props.Motor} Number( eg. MH01AA1234)`} name="registration" style={{ textTransform: "uppercase" }} value={this.state.fields["registration"]} />
                                                </label>
                                                <small className="CarRegError">{this.state.errors["registration"]}</small>
                                            </div>
                                            <div className="col-md-5">
                                                <label>
                                                    {this.state.isValid ? <Link to={"/Renew?type=" + this.props.type}>
                                                        <button className="QouteMNBtnrisk" id="quotesBtn"  >VIEW QUOTES</button></Link>
                                                        : <button className="QouteMNBtnrisk diablebtn" id="quotesBtn"  >VIEW QUOTES</button>
                                                    }
                                                </label>
                                            </div></React.Fragment>



                                        <div className="col-md-8">

                                            <Link

                                                to={"/Renew?type=" + this.props.type}
                                                className="headingBt"
                                                style={{ wordBreak: "break-word", fontSize: "12px" }}
                                            >
                                                Don't know your {this.props.Motor} number <b>Click here</b>
                                            </Link>                                        
                                            <Link className="headingBt"
                                                to={"/New?type=" + this.props.type}
                                            > Bought a new {this.props.Motor} <b>Click here</b></Link>


                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>

            </div>


        );
    }
}
export { InsureCar };
