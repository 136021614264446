import React from 'react';
import MaskedInput from 'react-text-mask'
import CustomFact from '../../Life/CustomFact';
class InsureLife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors:{},
            maxDate:null,
            currentDate:null
        }
        this.state.errors = this.props.errors
    let date=new Date();
    let dd=date.getDate()<10?"0"+date.getDate():date.getDate();
    let mm=(date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1);
    let yy=date.getFullYear();
    this.state.maxDate=yy-18+"-"+mm+"-"+dd;
    this.state.currentDate=yy+"-"+mm+"-"+dd;
    }
    render() {
        return (
            <div className="row  pt-3 d-flex justify-content-center">
                <div className="col-sm-9">
                    <div className="packagesMainHealth text-center">
                        <div className="productTabsMainHealth productTabsMainHelath text-center">
                            <h3 className="heading">Term Life Plans at Lowest Rates</h3>
                            <div className="ProductFormIn ProductForm03 ProductForm03Commercial ProductForm03Health">
                                <div className="PF0012">
                                    <form className="ng-pristine ng-valid">
                                        <div className="row">
                                            <div className="col-md-3 NoPaddingRight">
                                                <label>
                                                    <select name="Gender" defaultValue={this.props.Gender} onChange={this.props.handle} id="drpGender">
                                                        <option value="Select Gender">Select Gender</option>
                                                        <option value="M">Male</option>
                                                        <option value="F">Female</option>
                                                    </select>
                                                </label>
                                                <span className="error">{this.state.errors.Gender}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    <MaskedInput id="txtFirstName" name="FullName" value={this.props.FullName} onChange={this.props.handle} placeholder="Enter Full Name"
                                                        mask={[/[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, , /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/, /[a-zA-Z\s]/,]} guide={false} showMask={false} style={{ textTransform: "uppercase" }} />

                                                    <label id="txtFullnameSpesfic-error" className="error"></label>
                                                </label>
                                                <span className="error">{this.state.errors.FullName}</span>
                                            </div>
                                            <div className="col-md-3 NoPaddingLeft">
                                                <label>



                                                    <input type="date" max={this.state.maxDate} defaultValue={this.state.currentDate} name="DOB" value={this.props.DOB} onChange={this.props.handle} id="textDOB" required="required" placeholder="DD/MM/YYYY" className="hasDatepicker" />
                                                </label>
                                                <span className="error">{this.state.errors.DOB}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 offset-md-4">
                                                <label>
                                                    <MaskedInput mask={[/[6-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} tabIndex="0" minLength="10" maxLength="10" id="txtMobile" required="required" placeholder="+91" name="Mobile" value={this.props.Mobile} onChange={this.props.handle}
                                                        guide={false} showMask={false} style={{ textTransform: "uppercase" }} />
                                                    <label id="txtMobileSpesfic-error" className="error"></label>
                                                </label>
                                                <span className="error">{this.state.errors.Mobile}</span>
                                            </div>
                                            <div className="col-md-4 offset-md-4">
                                                <button type="button" className="btn btn-block QouteMNBtn" id="FirstForm" onClick={this.props.CallPopUp} >Next</button>
                                                <p style={{ color: "#142963" }}>I Agree to<a target="_blank"> <span style={{ color: "#F58634" }}>T&amp;C</span></a></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.showPopUp && <CustomFact handle={this.props.handle} 
                    SmokeStatus={this.props.SmokeStatus}  close={ this.props.close}
                    AnnualIncome={this.props.AnnualIncome} SumInsured={this.props.SumInsured}
                    Coverage={ this.props.Coverage}  callGetQuote={this.props.callGetQuote}  IncomeList={this.props.IncomeList} CoverList={this.props.CoverList}  />
                }
            </div>
        );
    }
}

export default InsureLife;