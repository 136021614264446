import React from "react";
import ButtonClass from "../ButtonClass";
const ContinueTOquote = (props) => {
    return (
        <div>
            <div className="ContinueTOquote">
                <div className="row">
                    <div className="col-md-3">
                        <div className="ContinueTOquoteBox">
                            <p>Your Vehicle</p>
                            <span><strong>{props.Model}</strong></span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ContinueTOquoteBox">
                            <p>Variant</p>
                            <span><strong>{props.Variant}</strong></span>
                        </div>
                    </div>
                    <div className="col-md-3">

                        <div className="ContinueTOquoteBox">
                            <p>Previous </p>
                            <span>NCB: <strong>{props.ncb} %</strong></span> <br /> <span>Reg Year: <strong>{props.Reg}</strong></span>

                        </div>

                    </div>
                    <div className="col-md-3">

                        <button className={ButtonClass() + " ContinueBTN"} id="quotesBtn" onClick={props.Proceed} >Continue</button>
                    </div>
                </div>
                <span className="mt-4 d-inline-block NewSearch" onClick={props.closeContinue}>Start a new search <i class="fas fa-long-arrow-alt-right"></i> </span>
            </div>
        </div>
    );
}
export default ContinueTOquote;
