import React, { Component} from "react";
import { useState, useEffect } from "react";
import AppGetAlert from "../AppPreview/AppGetAlert";
class AppPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsShow:false,
        }
        this.doSomethingBeforeUnload = this.doSomethingBeforeUnload.bind(this);
    }
    // Things to do before unloading/closing the tab
    doSomethingBeforeUnload = () => {
        this.setState({ IsShow:true })
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
    };

    componentDidMount() {
        // Activate the event listener
        this.setupBeforeUnloadListener();
    }

    // Render method.
    render() {
        this.state.IsShow && alert("hii")
        return (
            <div>My component</div>
        )
    }
}
export default AppPreview;