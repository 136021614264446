/* eslint-disable */ 
import React from 'react';
const Term=()=>{
    return(
        <section className="productCntMain">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="productCnt">
                    <h3>WHAT IS TERM LIFE INSURANCE?</h3>
                    <p>
                        <strong></strong><br/>
                        Term insurance is the simplest and most  affordable form of life insurance.
                    </p>
                    <p>Term plans provide peace of mind to you by  ensuring that your family is financially secure and independent, in your  absence. If you are the person insured, you pay a specific premium amount at  fixed intervals during the policy term. In the event of your unfortunate demise  during the policy term, your nominees will receive the ‘Sum Assured’ which you  had selected while purchasing the plan.</p>

                </div>
            </div>
            <div className="col-md-6">
                <img src={require("../assets/img/Term.png")} />
            </div>
            <div className="col-md-12">
                <div className="productCnt">
                    <p>Term insurance is a form of life insurance that  is active for a fixed period of time (popularly referred to as the term). These  plans are easy to understand and provide financial protection that your family  will need if you are no longer around.</p>
                    <p>New age term plans provide additional  protection through optional benefits like Critical Illness Cover and Accidental  Death Cover.</p>
                    <p>
                        TYPES OF TERM LIFE INSURANCE PLANS<br/>
                        <strong>LEVEL TERM PLANS OR STANDARD TERM PLANS:</strong><br/>
                        This is the most basic, simple form of term  insurance where the sum assured or death benefit is constant throughout the  policy tenure, and benefits will be paid to the nominee on the death of the  life insured.
                    </p>
                    <p>
                        <strong>RETURN OF PREMIUM TERM PLANS:</strong><br/>
                        These type of Term plans come with maturity  benefit wherein the total premium paid will be returned to the life insured if  she/he survives the policy tenure.
                    </p>
                    <p>
                        <strong>INCREASING TERM PLANS:</strong><br/>
                        In these plans, the policyholder has the option  to increase the sum assured on annual basis during the policy tenure, while  maintaining the premium amount at the same value. Due to this reason, the  premium for these plans is naturally on a bit higher side as compared to level  term plans.
                    </p>
                    <p>
                        <strong>DECREASING TERM PLANS:</strong><br/>
                        In this type of term plans, the sum assured  keeps on decreasing every year in these plans to meet the decreasing insurance  requirements of the life assured. This kind of plan is helpful to get the cover  in cases where the policyholder has taken a home/personal loan and is paying an  EMI (Equated Monthly Instalment) on the same.
                    </p>
                    <p>
                        <strong>CONVERTIBLE TERM PLANS:</strong><br/>
                        These plans come with an option wherein the  policyholder can convert these plans into any other type of plan at a future  date.&nbsp; For instance, a term insurance can  be converted into an endowment plan, a whole life insurance plan, etc. after  the specified number of years.
                    </p>
                    <p>
                        <strong>TERM PLANS WITH RIDERS:</strong><br/>
                        This type of plan comes with rider options such  as accidental death cover, critical illness cover, waiver of premium etc. which  can be purchased along with the normal term plan by paying a small premium  amount.
                    </p>
                    <p>
                        <strong>REASONS TO BUY TERM LIFE INSURANCE</strong><br/>
                        Uncertainty is the second name of life.  However, in our busy life, we always live in today and don’t think about  uncertainties of tomorrow
                    </p>
                    <p>One of the biggest uncertainties is the life  itself. Nobody is sure about the continuity of life. However, the question  “What if I die tomorrow” is never asked.</p>
                    <p>In case of sudden unfortunate incident like  death, the family is faced with the following unpleasant questions:</p>
                    <p>
                        How they will be able to maintain same  lifestyle?<br/>
                        What happens to various liabilities like Car  Loan, Home Loan and other EMI?<br/>
                        Whatwill happen to education of the kids?<br/>
                        What will happen to medical bills of the  parents?<br/>
                        How will family bear the marriage expenses of  kids?<br/>
                        How long will the savings last?<br/>
                        Term Insurance provides the answers to the  above-mentioned questions.
                    </p>
                    <p>&nbsp;</p>
                    <p><strong>CRITICAL ILLNESS INSURANCE FAQ</strong></p>
                    <p>
                        <strong>What is Critical Illness Insurance?</strong><br/>
                        Critical Illness plan is a health insurance  plan that pays a lump sum amount, equal to the sum insured, to the insured on  acquiring a serious ailment such as cancer, Heart attack, stroke etc. A Crtical  Illness cover provides a lump sum benefit which can pay for the cost of care  and treatment, recuperation expense and even pay off any debt if taken.  Regardless of your hospital expenses, the insurer pays the full sum insured.  There are various pre specified plan which covers one illness and plans which  covers more than one ( Kidney stone, Heart disease, Cancer etc. ). Critical  Illness Insurance policies may also cover such conditions as Heart transplant,  Coronary bypass surgery, Angioplasty, Kidney (Renal) failure, Major organ  transplant, Paralysis etc.
                    </p>
                    <p>
                        <strong>Why should I purchase  Critical Illness Insurance?</strong><br/>
                        Critical  Illness Insurance provides you and your family, the additional financial  security on diagnosis of a critical illness. The policy provides a lump sum  amount which could be used for:<br/>
                        Costs  of the care and treatment<br/>
                        Recuperation  aids<br/>
                        Debts  pay off<br/>
                        Any  lost income due to a decreasing ability to earn<br/>
                        Fund  for a change in lifestyle.<br/>
                        What  is a benefit policy?<br/>
                        Under  a benefit policy on happening of an insured event, the insurance company pays  the policyholder a lump sum amount.
                    </p>
                    <p>
                        <strong>What are the benefits  under Critical Illness Insurance?</strong><br/>
                        Normally,  the Insurance company will pay the Sum Insured as lump sum on first diagnosis  of any one of the Critical Illness, provided that the Insured Person survives a  period of 30 days from the date of the first diagnosis.
                    </p>
                    <p>The  following are normally covered Critical Illnesses with various Insurance  Companies:-</p>
                    <p>
                        Heart  Attack (Myocardial Infarction)<br/>
                        Coronary  Artery Bypass Surgery<br/>
                        Stroke<br/>
                        Cancer<br/>
                        Kidney  Failure<br/>
                        Major  Organ Transplantation<br/>
                        Multiple  Sclerosis<br/>
                        Paralysis<br/>
                        However,  the coverage may differ from policy to policy and hence it is important to  compare the illnesses covered before buying the Critical Illness Insurance.  Also, the survival period may vary from company to company.
                    </p>
                    <p>
                        <strong>Is there any tax  benefit under Critical Illness Insurance?</strong><br/>
                        Yes,  you can avail uptoRs.15,000 as tax benefit under ‘Section 80D’. In case of  senior citizens, you can avail uptoRs.20,000 as tax benefit under 'Section  80D'.
                    </p>
                    <p>
                        <strong>What does pre-existing  disease mean?</strong><br/>
                        Any  condition, ailment or injury or related condition(s) for which insured person  had signs or symptoms and/or was diagnosed and/or received medical advice/treatment  within 48 months prior to your first policy with the Insurance Company.
                    </p>
                    <p>
                        <strong>What is meant by  disease?</strong><br/>
                        Disease  means a pathological condition of a part, organ, or system resulting from  various causes, such as infection, pathological process, or environmental  stress, and characterized by an identifiable group of signs or symptoms.
                    </p>
                    <p>
                        <strong>What alldocuments are  required at the time of the Claim?</strong><br/>
                        The  Insured shall arrange for submission of the following documents required for  processing of claim within 45 days from the date of Intimation.
                    </p>
                    <p>
                        Duly  Completed Claim Form<br/>
                        Original  Discharge Summary.<br/>
                        <strong>Consultation Note/  Relevant treatment papers.</strong><br/>
                        All  relevant medical reports along with supporting invoices and doctors requisition  advising the same.<br/>
                        Original  and Final hospitalization bills with detailed breakup.<br/>
                        Pharmacy  Bills along with prescriptions.<br/>
                        Any  other documents as may be required by the Insurance Company.<br/>
                        On  receipt of claim documents claim will be processed in accordance with the terms  and conditions of the Policy.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>
    )
}
export default Term