import React from "react";
import Dedicated from "./Dedicated";
import Expertise from "./Expertise";
import "./services.css";
import "react-reveal";
import { Zoom } from "react-reveal";
const Services = (props) => {
    return (
        <React.Fragment>

            <section className="BannerSection">
                <div className="container">
                    <div className="BannerSection2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="BS_Box">
                                    <i className="fa fa-lock"></i>
                                    <div className="BS_BoxCnt">
                                        <h3>Provides economic protections</h3>
                                        <p>Insurance provides economic and finanicial protection to the insured against the unexpected losses in consideration of nominal amount called premium.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="BS_Box">
                                    <i className="fa fa-plus"></i>
                                    <div className="BS_BoxCnt">
                                        <h3>Help to reduce inflation</h3>
                                        <p>A business gets financial compensation in case of loss or damage to the properties of the business through insurance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="BS_Box">
                                    <i className="fa fa-money"></i>
                                    <div className="BS_BoxCnt">
                                        <h3>Encourages saving</h3>
                                        <p>An insured person pays the amount of premium in time as stated in the agreement which encourages for developing a saving habit of persons.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Zoom>
                <Dedicated />
            </Zoom>
            <Zoom>
                <Expertise />
            </Zoom>
            


        </React.Fragment>
    );
};
export default Services;
