import React, { Component } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomerLogin from "./CustomerLogin";
import CheckLogin from "../Login/CheckLogin";
import ManageAccount from "../components/ManageAccount";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            NavMenuClass: "nav-xbootstrap",
            URL: null,
            Logo: require("./assets/images/Insureasy-logo.png"),
            Login: "Login",
        };
    }
    redirectTo(path){
        let url=window.location.href;
        if(url.match("www")){
            if(path.match("www.")){
                path=path.replace("www.","")
            }
        }
        window.location.assign(window.location.href=path)
    }
    render() {
        return (
            <header className="myNav">
                <div class="top-nav MN_Menu">
                    <div class="container text-right">
                        <a href="#"><i class="fas fa-phone"></i>+91 8106602266</a>
                        <a href="https://www.insureasy.com/myaccount/UserPolicy/Login"><i class="fas fa-lock"></i>Customer Login</a>
                       <a onClick={() =>this.redirectTo("https://www.insureasy.com/myaccount")}><i class="fas fa-user"></i><ManageAccount/></a>
                    </div>
                </div>

                <div class="logo-block">
                    <nav class="mainHeader mainHeaderInEasy navbar-light bg-light static-top bg-transparent">
                        <div class="container">
                            <div className="row MainMenu">
                                <div className="col-md-2">
                                    <NavbarBrand tag={Link} to="/">
                                        <img
                                            src={require("./assets/images/Insureasy-logo.png")}
                                            id="logoasdf"
                                        />
                                    </NavbarBrand>
                                </div>
                                <div className="col-md-10">
                                    <ul class="MN_Menu navMenu navbar-right">
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/">
                                                Home <span class="sr-only">(current)</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" >
                                                About
                                    </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/Contact">
                                                Contact
                                    </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/claims">
                                                Claims
                                    </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/ePartner">
                                                Partner with us!
                                    </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
