import React from "react";
const TestimonialData = [
  {
        name: "Mr.Kanwaljit Singh",
    quotes:
            "I would like to thank Hero Insurance broking for letting me avail policy in slighter time.Product offered contains sagacious benefits.I have availed the cashless service also and it was quiet superior.Thank You Hero Insurance Broking Team for your support.",
  },
  {
      name: "Mr. Shalender Singh",
    quotes:
          "Company offered wide range of health plans with pre - eminent features and suit one's needs. To select from plethora of options was not difficult as proper knowledge was provided before selecting plan. Plan was competitive in market and must recommended. Thank you Hero Insurance broking for Providing me this product.",
  },
  {
      name: "Hozefa Hakimuddin Bharmal",
    quotes:
          "Hero Marketing Officer given the entire knowledge about Manipal Cigna Pro Health Insurance plan and given good services with fast policy issuance.Hero Marketing Officer given the entire knowledge about Manipal Cigna Pro Health Insurance plan and given good services with fast policy issuance.",
  },
  {
      name: "Mr.Amit Oberai",
    quotes:
          "Thank you to the entire team of Hero Insurance Broking  for the wonderful support and personalised service in purchasing health insurance policy.Look forward to a very long term relationship.Thank you to the entire team of Hero Insurance Broking  for the wonderful support and personalised service in purchasing health insurance policy.Look forward to a very long term relationship.",
  },
  {
      name: "Mr.Satnam Singh",
    quotes:
          "We have bought health insurance policy by Hero Insurance Broking.And we have wonderful experience.Buying Process was really easy.We have bought health insurance policy by Hero Insurance Broking.And we have wonderful experience.Buying Process was really easy.",
    },
    {
        name: "Mr.Prabal Pratap Singh Bhadoriya",
        quotes:
            "I have received really good & positive experience as well as prompt response from team.I appreciate the Hero Insurance Broking team for the efforts they made in helping me to find the right Health Insurance Policy as per my needs in resonable price.",
    },
    {
        name: "Mr.Amit Srivastava",
        quotes:
            "Thank you to the entire team of Hero Insurance Broking  for the wonderful support and personalised service in purchasing health insurance policy.Look forward to a very long term relationship.Thank you to the entire team of Hero Insurance Broking  for the wonderful support and personalised service in purchasing health insurance policy.Look forward to a very long term relationship.",
    },
    {
        name: "Mr.Mukesh Kumar",
        quotes:
            "I have purchased health insurance policy from hero insurance.Team hero helped me in understanding features of policy.The online payment process was very easy and transparent, all thanks to Hero Insurance.",
    },
    {
        name: "Mr.Suneel Kumar",
        quotes:
            "I got the health insurance plan for the higher coverage as it will be helpfull for me and my family.A best way to save your health, thank you health insurance.GOOD SUPPORT TEAM HERO INSURANCE.",
    },
    {
        name: "Mr.Ajay Dixit",
        quotes:
            "Team has described health insurance Plan very well, Payment option was easy.",
    },
    {
        name: "Mr.Vikas Mishra",
        quotes:
            "Team has described Plan very well, cleared my every query with satisfactory answer  and suggested me better health insurance plans as per my needs.",
    },
];
export default TestimonialData;









