import React from "react";
import "react-reveal";
import OurPartner from "../OurPartner";
import VedantTestimonial from "../VedantTestimonial";
import Consultation from "./Consultation";
import ExistingFeatures from "./ExistingFeatures";
import Accordian from "./FAQAccordian/Accordian";
import MeetTeam from "./MeetTeam";
import OurWorks from "./OurWorks";
import WorkEveryWhere from "./WorkEveryWhere";
const Services = (props) => {
    return (
        <React.Fragment>
            <ExistingFeatures />
            <WorkEveryWhere />
            <OurPartner />
            <OurWorks />
            <Consultation />
            <MeetTeam />
            <VedantTestimonial />
            <Accordian />
        </React.Fragment>
    );
};
export default Services;
