import React from "react";
const Dedicated = (props) => {
  return (
      <section className="pt-5 pb-5 bg-light">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title mr-lg-5">
                          <p className="title_1 mb-0 text-custom text-uppercase">Our Expereince</p>
                          <h2 className="font-weight-bold mx-auto section_header mb-4">Get Notified About Importent Email</h2>
                          <p className="text-muted">This prevents repetitive patterns from impairing the overall visual impression and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is relatively realistic.</p>
                      </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                      <img src={require("../assets/img/LifeMart/laptop.png")} className="img-fluid" />
                  </div>
              </div>
          </div>
          <div className="container mt-5">
              <div id="counter" className="row justify-content-center">
                  <div className="col-md-4 mt-4 pt-2">
                      <div className="counter-box text-center px-lg-4">
                          <h2 className="ValuesTAT">
                              <span className="counter-value">97</span>%
                    </h2>
                          <h5 className="counter-head">Happy Client</h5>
                          <p className="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.</p>
                      </div>
                  </div>
                  <div className="col-md-4 mt-4 pt-2">
                      <div className="counter-box text-center px-lg-4">
                          <h2 className="ValuesTAT">
                              <span className="counter-value">15</span>+
                    </h2>
                          <h5 className="counter-head">Awards</h5>
                          <p className="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.</p>
                      </div>
                  </div>
                  <div className="col-md-4 mt-4 pt-2">
                      <div className="counter-box text-center px-lg-4">
                          <h2 className="ValuesTAT">
                              <span className="counter-value">98</span>%
                    </h2>
                          <h5 className="counter-head">Project Complete</h5>
                          <p className="text-muted mb-0">
                              The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.
                    </p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
};
export default Dedicated;
