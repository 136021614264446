import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
import ContinueToLife from "../components/ContinueToLife";
import Life from "./Life";
import LifeUIManager from "./LifeUIManager";
class LifeMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Req: {},
            showPopUp: false,
            DOB: null,
            FullName: null,
            Gender: "M",
            InsuranceType: "TermLife",
            Mobile: null,
            AnnualIncome: 1500000,
            Coverage: 30,
            SmokeStatus: "N",
            SumInsured: 10000000,
            errors: {},
            isValid: false,
            RedirectToQuote: false,
            APIUrl: window.localStorage.getItem("portal"),
            CRMAPI: window.localStorage.getItem("crm"),
            ClientID: null,
            UserID: 0,
            AnnualIncomeList: [],
            CoverDurationList: [],
            ClientName: null,
            BtnValue: "Compare Quotes",
            IsFound: false,
        };
        this.lifeHandle = this.lifeHandle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.callGetQuote = this.callGetQuote.bind(this);
        this.findClientName = this.findClientName.bind(this);
        let checkQuoteResponse = window.sessionStorage.getItem("LifeQuoteResponse");
        let checkQuoteReq = window.sessionStorage.getItem("LifeQuoteReq");
        if (checkQuoteReq !== null)
            window.sessionStorage.removeItem("LifeQuoteReq");
        if (checkQuoteResponse !== null) {
            window.sessionStorage.removeItem("LifeQuoteResponse");
        }
        this.AnnualIncomeListMaker();
        this.pageLoad();
    }
    AnnualIncomeListMaker() {
        let AnnualIncome = [];
        for (let i = 2; i <= 99; i++) {
            AnnualIncome.push(i + "00000");
        }
        this.state.AnnualIncomeList = AnnualIncome;
        this.setState({ AnnualIncomeList: AnnualIncome });
        this.CoverDurationListMaker();
    }
    componentDidMount() {
        /* let LifeReq = window.localStorage.getItem("LifeReq");
        if (LifeReq !== null) {
            LifeReq = JSON.parse(LifeReq);
            console.log(LifeReq);
            this.setState({
                Req: LifeReq,

                IsFound: true,
            });
        } */
        let date = new Date();
        let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let mm =
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
        let yy = date.getFullYear();
        let MaxDate = yy - 18 + "-" + mm + "-" + dd;
        this.setState({ DOB: MaxDate });
    }
    pageLoad() {
        let url =
            this.state.APIUrl == null ? window.location.hostname : this.state.APIUrl;
        if (url.match("www")) {
            url = url.replace("www.", "");
        }
        url = url.replace("https://", "");
        let rq = {
            URL: url,
        };
        let Model = {
            URL: this.state.CRMAPI + "/api/Account/DomainLogo",
            PostString: JSON.stringify(rq),
        };

        try {
            fetch("/bpi/WeatherForecast/CallCheck", {
                method: "POST",
                body: JSON.stringify(Model),
                headers: { "Content-Type": "application/json" },
            })
                .then((Response) => Response.json())
                .then((Res) => {
                    this.setState({ ClientID: Res.id });
                    this.findClientName(Res.id);
                });
        } catch (err) {
            console.log(err);
        }
        let Token = window.localStorage.getItem("token");
        if (Token != null) {
            //Token = JSON.parse(Token);
            let parseToken = false;
            if (Token.charAt(0) === "{") parseToken = true;
            console.log(Token);
            let data = {
                Token: parseToken ? JSON.parse(Token).token : Token,
            };
            let md = {
                URL: this.state.CRMAPI + "/api/User/GetUserInfo",
                PostString: JSON.stringify(data),
            };

            axios.post("/bpi/WeatherForecast/CallCheck", md).then((Res) => {
                this.setState({ UserID: Res.data.userID });
                this.state.UserID = Res.data.userID;
            });
        }
    }
    findClientName(id) {
        let name = "";
        switch (id) {
            case 4:
                name = "RIWALIFE";
                break;
            case 35:
                name = "BIMASTREET";
                break;
            case 19:
                name = "SarsaBima";
                break;
            case 50:
                name = "RFL";
                break;
            case 59:
                name = "RBL";
                break;
            case 65: name = "PIIB";
                break;
            case 12: name = "Advance";
                break;
           
        }
        this.state.ClientName = name;
    }
    CoverDurationListMaker() {
        let duration = [];
        for (let i = 5; i <= 85; i++) {
            duration.push(i);
        }
        this.state.CoverDurationList = duration;
    }
    lifeHandle(e) {
        let fields = {};
        fields[e.target.name] = e.target.value;
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit() {
        console.log('handlesubmit called')
        let errors = {};
        let isValid = true;
        if (this.state.Gender.match("Select")) {
            isValid = false;
            errors["Gender"] = "Please Select Gender";
        }
        if (this.state.DOB === null) {
            isValid = false;
            errors["DOB"] = "Enter DOB";
        } else if (this.state.DOB !== null) {
            let d1 = new Date();
            let d2 = new Date(this.state.DOB);
            let diff = (d1.getTime() - d2.getTime()) / 1000;
            diff /= 60 * 60 * 24;
            let r = Math.abs(Math.round(diff / 365.25));
            if (r < 18) {
                errors["DOB"] = "Only 18+ or 18 people";
                isValid = false;
            }
        }
        if (this.state.FullName === null) {
            isValid = false;
            errors["FullName"] = "Enter Name";
        } else if (this.state.FullName !== null) {
            if (!this.state.FullName.includes(" ")) {
                isValid = false;
                errors["FullName"] = "Enter Full Name";
            }
        }
        if (this.state.Mobile === null || this.state.Mobile.length !== 10) {
            isValid = false;
            errors["Mobile"] = "Enter Correct Number";
        }
        this.setState({ errors, isValid });
        if (isValid) {
            console.log('inside isvalid')
            let id = document.getElementsByTagName("body");
            id[0].classList.add("hideScroll");
            this.setState({ showPopUp: true });
        }
    }

    callGetQuote() {
        this.setState({ BtnValue: "Please Wait..." });
        let date = new Date();

        try {
            const date1 = new Date(this.state.DOB.replace("-", "/"));
            //const date2 = new Date();
            const diffTime = Date.now() - date1.getTime();
            //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            //var ageDifMs = Date.now() - birthday.getTime();
            let ageDate = new Date(diffTime);

            date = Math.abs(ageDate.getUTCFullYear() - 1970);
        } catch (e) {
            console.log(e);
        }

        if (this.state.isValid) {
            let Req = {
                DOB: this.state.DOB,
                FullName: this.state.FullName,
                Gender: this.state.Gender,
                //InsuranceType: "TermLife",
                Mobile: this.state.Mobile,
                AnnualIncome: this.state.AnnualIncome,
                Coverage: this.state.Coverage,
                SmokeStatus: this.state.SmokeStatus,
                SumInsured: this.state.SumInsured, //done
                PolicyTerm: this.state.Coverage,
                OrderByPremium: 0,
                PayoutOption: "Lump-Sum",
                PremiumFrequency: "Y",
                ClientName: this.state.ClientName,
                Premiumpayoutoption: "Regular Pay",
                ClientID: this.state.ClientID,
                Userid: this.state.UserID,
                EnquiryType: "LIF",
                EnquiryNo: "",
                InsurerAge: date,
            };

            let EnqReq = {
                EnquiryNo: "",
                MobileNo: this.state.Mobile,
                EnquiryType: "LIF",
                ClientURL: this.state.APIUrl.split("//")[1],
                ClientID: this.state.ClientID,
                Userid: this.state.UserID,
                LeadSource: "online",
                LifeEnquiry: {
                    EnquiryNo: "",
                    UserID: this.state.UserID,
                    Mobile: this.state.Mobile,
                    InsurerAge: date,
                    Gender: this.state.Gender,
                    SmokeStatus: this.state.SmokeStatus,
                    AnnualIncome: this.state.AnnualIncome,
                    SumInsured: this.state.SumInsured,
                    PolicyTerm: this.state.Coverage,
                    PolicyType: "Termlife",
                },
            };

            console.log(JSON.stringify(EnqReq));
            //this.setState({Req})
            let Model = {
                URL: this.state.APIUrl + "/lifeapi/api/NewEnquiry/AddEnquiry",
                PostString: JSON.stringify(EnqReq),
            };
            axios
                .post("/bpi/WeatherForecast/CallCheck", Model)
                .then((Res) => {
                    Req.EnquiryNo = Res.data.split("~")[0];
                    this.setState({
                        Req: Req,
                        BtnValue: "Compare Quotes",
                        RedirectToQuote: true,
                    });
                    document.getElementsByTagName("body")[0].classList = "";
                    window.localStorage.setItem("LifeReq", JSON.stringify(Req));
                })
                .catch((error) => {
                    this.setState({
                        Req: Req,
                        RedirectToQuote: true,
                    });
                });

            //this.setState({RedirectToQuote:true})
        }
    }
    render() {
        const Req = this.state.Req;
        return this.state.RedirectToQuote ? (
            <Redirect
                to={{
                    pathname: "/GetQuoteLife",
                    state: { Req: this.state.Req },
                }}
            />
        ) : this.state.IsFound ? (
            <ContinueToLife
                gender={Req.Gender}
                dob={Req.DOB}
                fullName={Req.FullName}
                mobile={Req.Mobile}
                closeContinue={() => this.setState({ IsFound: false })}
                proceed={() => this.setState({ RedirectToQuote: true })}
            />
        ) : (
                    <LifeUIManager
                        close={() => {
                            document.getElementsByTagName("body")[0].classList = "";
                            this.setState({ showPopUp: false });
                        }}
                        bimaClose={this.props.bimaClose}
                        errors={this.state.errors}
                        CallPopUp={this.handleSubmit}
                        showPopUp={this.state.showPopUp}
                        btnValue={this.state.BtnValue}
                        handle={this.lifeHandle}
                        DOB={this.state.DOB}
                        FullName={this.state.FullName}
                        Gender={this.state.Gender}
                        Mobile={this.state.Mobile}
                        AnnualIncome={this.state.AnnualIncome}
                        Coverage={this.state.Coverage}
                        SmokeStatus={this.state.SmokeStatus}
                        SumInsured={this.state.SumInsured}
                        callGetQuote={this.callGetQuote}
                        IncomeList={this.state.AnnualIncomeList}
                        CoverList={this.state.CoverDurationList}
                    />
                );
    }
}
export default LifeMain;
