import React from "react";
const Expertise = (props) => {
    return (
        <React.Fragment>
            <section class="whatWeDoBoxMain">
                <div class="container">
                    <h5 class="tbk__title" itemprop="headline">OUR PRODUCTS</h5>
                    <h2 class="tbk__subtitle" itemprop="alternativeHeadline">What we do</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/Advance/wwDo01.jpg")} />
                                <h3>
                                    <a href="life-insurance/index.html">Life Insurance</a>
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/Advance/wwDo02.jpg")} />
                                <h3>
                                    <a href="/Advance/GeneralInsurance">General Insurance</a>
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="whatWeDoBox">
                                <img src={require("../assets/img/Advance/wwDo03.jpg")} />
                                <h3>
                                    <a href="corporate-insurance/index.html">Corporate Insurance</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

    );
};
export default Expertise;
