/* eslint-disable */
import React from 'react';
const TermsAndCondition = () => {
    return (
        <section className="PolicyCnt">
            <div className="container">
                <h3>Terms And Conditions</h3>
                <p>
                    <br />
                    <strong>Your acceptance of these terms</strong> <br />
                By using this Site, you signify  your acceptance of this policy. If you do not agree to this policy, please do  not use our Site. Your continued use of the Site following the posting of  changes to this policy will be deemed your acceptance of those changes.
            </p>
                <p>
                    Kare India Insurance  Brokers Pvt Ltd (hereinafter referred to as Kare  India) operates the website <a href="https://www.insurekare.com/">https://www.insurekare.com/</a>  to provide consumers choice and  an easy way to buy Insurance products from multiple Insurance companies. Kare  India is not an insurance company. Kare India is a licensed Insurance Broking  Company holding a Broking license from the Indian Insurance Regulator –  Insurance Regulatory and Development authority.
            </p>
                <p>
                    <a href="https://www.irdai.gov.in/" target="_blank">
                        IRDA Direct Broker Code : IRDA/ DB  556/ 13 <br />
                    </a>
                Our participating insurance providers supply some of the information  available on the Website and therefore there may be inaccuracies in the Website  Information over which Kare India has limited control.<br />
                Kare India does not warrant or guarantee the Timeliness, accuracy or  completeness of the Website Information; or Quality of the results obtained  from the use of the Website.<br />
                To the maximum extent permitted by law, Kare India has no liability in  relation to or arising out of the Website Information and Website  recommendations. You are responsible for the final choice of your product and  you should take time to read through all information supplied before  proceeding. If you are in any doubt regarding a product or its terms you should  seek further advice from Kare India or the relevant participating provider  before choosing your product.<br />
                Kare India may pass on your personal information to the relevant  participating provider if you apply to purchase a product through https://www.insurekare.com/ , however, Kare India  does not guarantee when or if you will actually acquire the product that you  have chosen. Kare India does not accept any liability arising out of  circumstances where there is delay in you acquiring the product you have  chosen.<br />
                Please note that Kare India is only collecting or assisting in collecting  the premium deposit on behalf of the insurer you have chosen to buy the policy.  The acceptance of the deposit as premium and final issuance of the policy is  subject to the underwriting norms and discretion of the Insurer whose policy  you have chosen to buy on which Kare India has no control. Kare India will  ensure that the amount is refunded by the insurer in case there is no ultimate  issuance of policy.<br />
                By visiting our website and accessing the information, resources,  services, products, and tools we provide, you understand and agree to accept  and adhere to the following terms and conditions as stated in this policy  (hereafter referred to as &lsquo;User Agreement&rsquo;), along with the terms and  conditions as stated in our Privacy Policy (please refer to the Privacy Policy  section below for more information).<br />
                This agreement is in effect as of Oct 02, 2013.<br />
                We reserve the right to change  this User Agreement from time to time without notice. You acknowledge and agree  that it is your responsibility to review this User Agreement periodically to  familiarize yourself with any modifications. Your continued use of this site  after such modifications will constitute acknowledgment and agreement of the  modified terms and conditions. <br />
                    </p>

                    <p>
                        <strong>Online Payment Terms &amp; Conditions</strong><br />
                Dear Customer, <br />
                You are being re - directed to a  third - party site.By clicking on the check box, it is assumed that the & ldquo; Payment  Terms & amp; Conditions & rdquo; have been read and accepted by you.
            </p>
                    <ul>
                        <li>By accepting to Make Payment  Online, it is implied that you agree to the terms and conditions of Net Banking  System/ Credit Card Company or the respective entities.</li>
                        <li>The accuracy or completeness of  the materials or the reliability of any advice, opinion, statement or other  information displayed or distributed through it, is not warranted by Kare India  and shall solely be construed to be set forth by the third party.</li>
                        <li>The site shall be used solely for  the payment of your premium and your acknowledgment that any reliance on any  opinion, advice, statement, memorandum, or information available on the site  shall be at your sole risk and consequences.</li>
                        <li>Kare India and its affiliates,  subsidiaries, employees, officers, directors and agents, expressly disclaim any  liability for any deficiency in the services of the service provider whose site  you are about to access.</li>
                        <li>Neither Kare India nor any of its  affiliates nor their directors, officers and employees will be liable to or  have any responsibility of any kind for any loss that you may incur in the  event of any deficiency in the services of the service provider, failure or  disruption of the site of the service provider, or resulting from the act or  omission of any other party involved in making this site or the data contained  therein available to you, or from any other cause relating to your access to,  inability to access, or use of the site or these materials in accordance  thereto, Kare India and all its related parties described here in above stand  indemnified from all proceedings or matters arising thereto.</li>
                        <li>Once the payment transaction has  been submitted, any request for refund will not be entertained. If the Service,  however, causes an incorrect amount of funds to be debited from your Payment  Account or causes funds from your Payment Account to be directed to a Payee  which does not comply with your Payment Instructions, the Service provider  shall be responsible for returning the improperly transferred funds to your  Payment Account.</li>
                        <li>For all transactions before  payment, the company will display a transaction id and the user should make  note of the same and should be quoted in all future communications with  reference to this transaction.</li>
                        <li>Online Policy generation will be  done on acknowledgment of the premium is received from payment gateway as to  successful payment.</li>
                        <li>In the exigency of connection  getting timed out or user clicking to close the browser before getting payment  confirmation message or broken transaction arising out of any other cause, the  customer shall check with his bank before proceeding to make subsequent payment  for the same proposal. In respect of broken transactions, online policy  generation facility is not available. On receipt of confirmation from payment  gateway which takes about 72 hours (from the date and time of transaction),  Kare India shall update the payment status of these records. In respect of  successful transactions, the policies will be e-mailed to the customer.</li>
                        <li>The company shall not be  responsible for non-delivery of policy if the insured had provided wrong e-mail  address.</li>
                        <li>For any issue relating to online  policies, the company can be reached in the e-mail cover lalit@kareindia.com</li>
                    </ul>
                    <p>
                        Manner of premium payments<br />
                The premium to be paid by any  person proposing to take an insurance policy (hereinafter referred to as the  proposer) or by the policyholder to an insurer may be made in any one or more  of the following manners, namely:
            </p>
                    <ul>
                        <li>Cash</li>
                        <li>Any recognized banking negotiable  instrument such as cheques, including demand drafts, pay orders, banker&rsquo;s  cheques drawn on any scheduled bank in India</li>
                        <li>Postal money orders</li>
                        <li>Credit or Debit Cards held in his  name</li>
                        <li>Bank Guarantee or Cash Deposit</li>
                        <li>Internet</li>
                        <li>E-transfer</li>
                        <li>Direct credits via standing  instructions of proposer or the policyholder or the life insured through bank  transfers; and</li>
                        <li>Any other method of payment as  may be approved by the Authority from time to time.</li>
                    </ul>
                    <p>
                        Commencement of Risk<br />
                In all cases of risks covered by  the policies issued by an insurer, the attachment of risk to an insurer will be  in consonance with the terms of Section 64VB of the Act and except in the cases  where the premium has been paid in cash, in all other cases the insurer shall  be on risk only after the receipt of the premium by the insurer.<br />
                Provided that in the case of a  policy of general insurance that where the remittance made by the proposer or  the policyholder is not realized by the insurer, the policy shall be treated as  void ab-initio.<br />
                Provided further that in the case  of a policy of life insurance, the continuance of the risk or otherwise shall  depend on the terms and conditions of the policy already entered into.
            </p>

                    <p>
                        <strong>Responsible Use and Conduct</strong><br />
                By visiting our website and  accessing the information, resources, services, products, and tools we provide  for you, either directly or indirectly (hereafter referred to as &lsquo;Resources&rsquo;),  you agree to use these Resources only for the purposes intended as permitted by  (a) the terms of this User Agreement, and (b) applicable laws, regulations and  generally accepted online practices or guidelines.
            </p>
                    <p>Wherein, you understand that:</p>
                    <ul>
                        <li>In order to access our Resources,  you may be required to provide certain information about yourself (such as  identification, contact details, etc.) as part of the registration process, or  as part of your ability to use the Resources. You agree that any information  you provide will always be accurate, correct, and up to date. </li>
                        <li>You are responsible for  maintaining the confidentiality of any login information associated with any  account you use to access our Resources. Accordingly, you are responsible for  all activities that occur under your account/s. </li>
                        <li>Accessing (or attempting to  access) any of our Resources by any means other than through the means we  provide, is strictly prohibited. You specifically agree not to access (or  attempt to access) any of our Resources through any automated, unethical or  unconventional means. </li>
                        <li>Engaging in any activity that  disrupts or interferes with our Resources, including the servers and/or  networks to which our Resources are located or connected, is strictly  prohibited. </li>
                        <li>Attempting to copy, duplicate,  reproduce, sell, trade, or resell our Resources is strictly prohibited. </li>
                        <li>You are solely responsible for any  consequences, losses, or damages that we may directly or indirectly incur or  suffer due to any unauthorized activities conducted by you, as explained above,  and may incur criminal or civil liability. </li>
                    </ul>
                    <p>We may provide various open communication tools on our website, such as  blog comments, blog posts, public chat, forums, message boards, newsgroups,  product ratings and reviews, various social media services, etc. You understand  that generally we do not pre-screen or monitor the content posted by users of  these various communication tools, which means that if you choose to use these  tools to submit any type of content to our website, then it is your personal  responsibility to use these tools in a responsible and ethical manner. By  posting information or otherwise using any open communication tools as  mentioned, you agree that you will not upload, post, share, or otherwise  distribute any content that: </p>
                    <ul>
                        <li>Is illegal, threatening,  defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive,  invasive, racist, or contains any type of suggestive, inappropriate, or  explicit language; </li>
                        <li>Infringes on any trademark,  patent, trade secret, copyright, or other proprietary right of any party; </li>
                        <li>Contains any type of unauthorized  or unsolicited advertising; </li>
                        <li>Impersonates any person or  entity, including any Kareindia employees or representatives. </li>
                    </ul>
                    <p>
                        We have the right at our sole discretion to remove any content that, we  feel in our judgment does not comply with this User Agreement, along with any  content that we feel is otherwise offensive, harmful, objectionable,  inaccurate, or violates any 3rd party copyrights or trademarks. We are not  responsible for any delay or failure in removing such content. If you post  content that we choose to remove, you hereby consent to such removal, and  consent to waive any claim against us. <br />
                We do not assume any liability for any content posted by you or any other  3rd party users of our website. However, any content posted by you using any  open communication tools on our website, provided that it doesn&rsquo;t violate or  infringe on any 3rd party copyrights or trademarks, becomes the property of Kare  India Insurance Brokers Pvt. Ltd., and as such, gives us a perpetual,  irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify,  adapt, translate, publish, publicly display and/or distribute as we see fit.  This only refers and applies to content posted via open communication tools as  described, and does not refer to information that is provided as part of the  registration process, necessary in order to use our Resources. All information  provided as part of our registration process is covered by our privacy policy. <br />
                You agree to indemnify and hold harmless Kare India Insurance Brokers  Pvt. Ltd. and their directors, officers, managers, employees, donors, agents,  and licensors, from and against all losses, expenses, damages and costs,  including reasonable attorneys' fees, resulting from any violation of this User  Agreement or the failure to fulfill any obligations relating to your account  incurred by you or any other person using your account. We reserve the right to  take over the exclusive defense of any claim for which we are entitled to  indemnification under this User Agreement. In such event, you shall provide us  with such cooperation as is reasonably requested by us. <br />
                        <strong>Privacy</strong><br />
                Your privacy is very important to  us, which is why we&rsquo;ve created a separate Privacy Policy in order to explain in  detail how we collect, manage, process, secure, and store your private  information. Our privacy policy is included under the scope of this User  Agreement. To read our privacy policy in its entirety, click here.
            </p>
                    <p>
                        <strong>Limitation of Warranties</strong><br />
                By using our website, you understand and agree that all Resources we  provide are &ldquo;as is&rdquo; and &ldquo;as available&rdquo;. This means that we do not represent or  warrant to you that:
            </p>
                    <ul>
                        <li>The use of our Resources will  meet your needs or requirements. </li>
                        <li>The use of our Resources will be  uninterrupted, timely, secure or free from errors. </li>
                        <li>The information obtained by using  our Resources will be accurate or reliable, and </li>
                        <li>Any defects in the operation or  functionality of any Resources we provide will be repaired or corrected. </li>
                    </ul>
                    <p>Furthermore, you understand and agree that:</p>
                    <ul>
                        <li>Any content downloaded or  otherwise obtained through the use of our Resources is done at your own  discretion and risk, and that you are solely responsible for any damage to your  computer or other devices for any loss of data that may result from the  download of such content. </li>
                        <li>No information or advice, whether  expressed, implied, oral or written, obtained by you from Kare India Insurance  Brokers Pvt. Ltd. or through any Resources we provide shall create any  warranty, guarantee, or conditions of any kind, except for those expressly  outlined in this User Agreement. </li>
                    </ul>
                    <p>
                        <strong>Limitation of Liability</strong><br />
                In conjunction with the Limitation of Warranties as explained above, you  expressly understand and agree that any claim against us shall be limited to  the amount you paid, if any, for use of products and/or services. Kare India  Insurance Brokers Pvt. Ltd. will not be liable for any direct, indirect,  incidental, consequential or exemplary loss or damages which may be incurred by  you as a result of using our Resources, or as a result of any changes, data  loss or corruption, cancellation, loss of access, or downtime to the full extent  that applicable limitation of liability laws apply. <br />
                        <strong>Copyrights/Trademarks</strong><br />
                All content and materials available on https://www.insurekare.com/,  including but not limited to text, graphics, website name, code, images and  logos are the intellectual property of Kare India Insurance Brokers PVT. Ltd.,  and are protected by applicable copyright and trademark law. Any inappropriate  use, including but not limited to the reproduction, distribution, display or  transmission of any content on this site is strictly prohibited, unless  specifically authorized by Kare India Insurance Brokers Pvt. Ltd.<br />
                Material featured on this site may be reproduced free of charge in any  format or media without requiring specific permission. This is subject to the  material being reproduced accurately and not being used in a derogatory manner  or in a misleading context. Where the material is being published or issued to  others, the source must be prominently acknowledged. However, the permission to  reproduce this material does not extend to any material on this site, which is  explicitly identified as being the copyright of a third party. Authorization to  reproduce such material must be obtained from the copyright holders concerned.
            </p>
                    <p>
                        <strong>Termination of Use</strong><br />
                You agree that we may, at our sole discretion, suspend or terminate your  access to all or part of our website and Resources with or without notice and  for any reason, including, without limitation, breach of this User Agreement.  Any suspected illegal, fraudulent or abusive activity may be grounds for  terminating your relationship and may be referred to appropriate law  enforcement authorities. Upon suspension or termination, your right to use the  Resources we provide will immediately cease, and we reserve the right to remove  or delete any information that you may have on file with us, including any  account or login information.
            </p>
                    <p>
                        <strong>Governing Law</strong><br />
                This website is controlled by Kare India Insurance Brokers Pvt. Ltd. from  our offices located in the Delhi NCR, India. It can be accessed by most  countries around the world. As each country has laws that may differ from those  of Delhi NCR, by accessing our website, you agree that the statutes and laws of  Delhi NCR, without regard to the conflict of laws and the United Nations  Convention on the International Sales of Goods, will apply to all matters  relating to the use of this website and the purchase of any products or  services through this site. <br />
                Furthermore, any action to enforce this User Agreement shall be brought  in the federal or state courts located in India,. You hereby agree to personal  jurisdiction by such courts, and waive any jurisdictional, venue, or  inconvenient forum objections to such courts. <br />
                        <strong>Guarantee</strong><br />
                UNLESS OTHERWISE EXPRESSED, Kare India Insurance Brokers Pvt. EXPRESSLY  DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR  IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. <br />
                        <strong>Nominee Assistance Program</strong>
                    </p>
                    <ul>
                        <li>Kare India Insurance Brokers Pvt.  Ltd. has merely partnered with various third party experts in order to  facilitate extended services to the users purchasing Term Insurance. The  services provided by the third party(s) are provided by them in their capacity  as an independent service provider(s). Kare India does not provide the services  in any capacity whatsoever. Kare India does not endorse the services of the  listed partners. Kare India shall not be liable for any payment obligation in  relation to the services provided under the Nominee Assistance program. </li>
                        <li>Kare India shall not be liable  for any act(s)/omission(s) of the partners and/or any defect/deficiency in  provision of the listed services including their refusal to provide the  services. Any third-party facility/offer(s)/service(s)/product(s) (&ldquo;offer&rdquo;)  shall be subject to the terms and conditions of such third party which shall be  applicable to the user. Kare India do not endorse, make no representation and  shall have no liability or obligation whatsoever in relation to such third-party  offer. </li>
                        <li>All such third-party offer shall  be availed by user at their own risk and responsibility. Kare India shall not  be liable for any payment obligation in relation to such third-party offer,  which shall be user's responsibility. </li>
                        <li>Kare India is only an insurance  broker and can assist with any insurance claims only to the extent of  liasioning with the insurance company. Any insurance claim shall be processed  by the insurance company subject to their terms and conditions and the  insurance policy. </li>
                        <li>Kare India reserves the sole  right to modify, amend, change, refuse or revoke their respective services, any  offers including third party offer herein at any time without assigning any  reason and without any liability and notice. </li>
                        <li>These terms and conditions for  Nominee Assistance Program are in addition to all other terms and conditions  provided hereunder including the FAQs and all these terms and conditions and  FAQs are legally binding on the user. Kare India reserves the sole right to  modify, amend, change or revoke these terms and conditions including the FAQs  without any notice at any time and all such terms and conditions and FAQs as  amended from time to time shall be legally binding on the user. </li>
                        <li>Any disputes arising pursuant to  these terms and conditions are subject to the exclusive jurisdiction of courts  in Delhi NCR. </li>
                    </ul>
                    <p>
                        <strong>NDNC</strong><br />
                You are authorizing Kare India to  override the DND settings to reach out to you over calls, SMS, emails and any  other mode of communication. This authorization will override your registry  under NDNC.
            </p>
                    <p>
                        <strong>Contact Information</strong><br />
                If you have any questions or comments about these our Terms of Service as  outlined above, you can contact us at: <br />
                Kare India Insurance Brokers Pvt Ltd. <br />
                706, K M Trade Tower, Hotel Radisson Blu, <br />
                Kaushambi, Ghaziabad, U P - 201010
            </p>
                    <h2>Refund Policy</h2>
                    <p>In case of a refund due to an  erroneous transaction or cancellation, the premium paid for an insurance  product will be refunded via cheque or direct credit as per the policy of the  Insurance provider, that you have chosen to buy the product from. </p>
                    <h2>Cancellation Policy</h2>
                    <p>
                        In case of a cancellation, the  premium paid for an insurance product will be refunded via cheque or direct  credit as per the policy of the Insurance provider, that you have chosen to buy  the product from. <br />
                You do not need to return the  copy of the policy documents received by you, in order to cancel the policy.
            </p>
        </div>
    </section>
    )
}
export default TermsAndCondition