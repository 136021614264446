import React from "react";
import { Health } from "../../Health/Health";
import Product from "../Product";
import ProductTab from "./ProductTab";
import { Route } from "react-router-dom";
import CommercialInner from "./CommercialInner";
import InsureLife from './SansomLife'
import { InsureCar } from "./InsureCar";
import { InsureBike } from "./InsureBike";
import { InsureHealth } from "./InsureHealth";
import InsureTravel from "./InsureTravel";
import InsureHome from "./InsureHome";
import InsurePersAccident from "./InsurePersAccident";
import InsureCoronavirus from "./InsureCoronavirus";
import InsureFireEarthQuake from "./InsureFireEarthQuake";
import LifeMain from "../../Life/LifeMain";
const TabData = [
    {
        id: "1",
        tabTitle: <ProductTab src={Product[0].src} name={Product[0].name} />,
        tabContent: <InsureCar />,
    },
    {
        id: "2",
        tabTitle: <ProductTab src={Product[1].src} name={Product[1].name} />,
        tabContent: <InsureBike />,

    },
    {
        id: "3",
        tabTitle: <ProductTab src={Product[2].src} name={Product[2].name} />,
        tabContent: <CommercialInner />,

    },
    {
        id: "4",
        tabTitle: <ProductTab src={Product[3].src} name={Product[3].name} />,
        tabContent: <InsureHealth />,

    },
    {
        id: "5",
        tabTitle: <ProductTab src={Product[4].src} name={Product[4].name} />,
        tabContent: <LifeMain />,

    },
    {
        id: "6",
        tabTitle: <ProductTab src={Product[5].src} name={Product[5].name} />,
        tabContent: <InsureTravel />,

    },
    {
        id: "7",
        tabTitle: <ProductTab src={Product[6].src} name={Product[6].name} />,
        tabContent: <InsureHome />,

    },
    {
        id: "8",
        tabTitle: <ProductTab src={Product[7].src} name={Product[7].name} />,
        tabContent: <InsurePersAccident />,

    },
    {
        id: "9",
        tabTitle: <ProductTab src={Product[8].src} name={Product[8].name} />,
        tabContent: <InsureCoronavirus />,

    },
    {
        id: "10",
        tabTitle: <ProductTab src={Product[9].src} name={Product[9].name} />,
        tabContent: <InsureFireEarthQuake />,

    },
];
export default TabData;
