import React from "react";
const Dedicated = (props) => {
    return (
        <section className="pt-5 pb-5 bg-light">
            <div className="container">
                <div class="row">
                    <div class="col-md-3 col-xs-6">
                        <div class="comProfileBox">
                            <img src={require("../assets/img/BMB/like.png")} />
                            <h3>Best policy</h3>
                        </div>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <div class="comProfileBox">
                            <img src={require("../assets/img/BMB/asking.png")} />
                            <h3>Claim assistance</h3>

                        </div>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <div class="comProfileBox">
                            <img src={require("../assets/img/BMB/winner.png")} />
                            <h3>Recognition</h3>

                        </div>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <div class="comProfileBox">
                            <img src={require("../assets/img/BMB/favourites.png")} />
                            <h3>Customers Love us</h3>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Dedicated;
